import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import MUITable from '@material-ui/core/Table';
import * as React from 'react';
import type { ITableConfig, TableColumnSortDirection } from './config';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';

interface ITableComponentProps {
  config: ITableConfig;
  data: any[];
  columnSorted?: string;
  sortDirection?: TableColumnSortDirection;
  onSortChanged(column: string): void;
  onRowClick?(values: any): void;
  onExport?(rows: any[]): void;
}

export const TableComponent: React.FunctionComponent<ITableComponentProps> = ({
  config,
  data,
  columnSorted,
  sortDirection,
  onSortChanged,
  onRowClick,
  onExport,
}) => {
  return (
    <>
      {onExport && <input type="button" onClick={() => onExport(data)} value="Export" />}
      <Paper>
        <MUITable>
          <TableHeader
            config={config}
            columnSorted={columnSorted}
            sortDirection={sortDirection}
            onSortChanged={onSortChanged}
            rowsAreClickable={Boolean(onRowClick)}
          />
          <TableBody config={config} data={data} onRowClick={onRowClick} />
        </MUITable>
      </Paper>
    </>
  );
};
