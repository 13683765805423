import type { CategoryName } from '@provider-portal/constants/categories';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import uniqBy from 'lodash/uniqBy';
import type * as React from 'react';

export interface ICategoryOption {
  label: React.ReactNode;
  value: string;
}

const genericCategories = [
  categories.electricity,
  categories.broadband,
  categories.gas,
  categories['streaming-media'],
  categories.fitness,
  categories.mobile,
  categories['grocery-bag'],
  categories['public-transport'],
  categories['subscription-box'],
];

interface ICategory {
  key: string;
  label: JSX.Element;
}

const categoryToCategoryOption = (c: ICategory) => ({ label: c.label, value: c.key });

const genericCategoryOptions: ICategoryOption[] = genericCategories.map(categoryToCategoryOption);

export const getIntegratedCategories = (market: Market) => {
  switch (market) {
    case Market.Sweden:
      return [categories.mobile, categories.electricity, categories.fitness];
    case Market.Finland:
      return [categories.electricity];
    case Market.Belgium:
      return [categories.electricity, categories.gas, categories.mobile];
    case Market.Denmark:
      return [categories.mobile, categories.electricity];
    case Market.Spain:
      return [categories.electricity];
    default:
      return [];
  }
};

export const availableMarketCategories = (market: Market): ICategoryOption[] => {
  return getIntegratedCategories(market).map(categoryToCategoryOption);
};

export function availableCategories(market: Market): ICategoryOption[] {
  const result = availableMarketCategories(market).concat(genericCategoryOptions);
  const uniqueCategories = uniqBy(result, (c) => c.value);

  return uniqueCategories.sort((a, b) => a.value.localeCompare(b.value));
}

/**
 * We should only show campaigns link in markets where
 * a category has been fully integrated.
 */
export const showCampaignsMenuItem = (category: CategoryName, market: Market) => {
  const results = availableMarketCategories(market).map((c) => c.value);

  return results.indexOf(category) !== -1;
};
