import type { Locale } from '@provider-portal/internationalization/locale';
import type { ITooltipProps } from '@provider-portal/libs/uiFramework/components/tooltip';
import type * as React from 'react';
import type { IInputField } from '../configs';
import { fieldConstructor, FieldType } from '../configs';
import type { NumberType } from './';

export interface INumberInput extends IInputField, ITooltipProps {
  type: FieldType.Number;
  numberType: NumberType;
  defaultValue?: string | number;
  helperText?: React.ReactNode;
  locale: Locale;
}

export const NumberInput = fieldConstructor<INumberInput>(FieldType.Number);
