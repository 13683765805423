import { defaultFeatures } from '@provider-portal/feature-toggling';
import { disabledEmbeddedView } from '@provider-portal/app-context';
import { AppType } from '@provider-portal/app-context/constants';
import { AppContextActions } from '@provider-portal/app-context/duck';
import { blueBaseTheme } from '@provider-portal/themes/blueBaseTheme';
import { SupplierPortalRoute } from '@provider-portal/routes';
import { MuiThemeProvider } from '@material-ui/core/styles';
import type { History } from 'history';
// @ts-ignore js import, remove this when the import is typed
import { MuiThemeProvider as OldMuiThemeProvider } from 'material-ui/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import URI from 'urijs';
import { LocalizationProvider } from '@provider-portal/internationalization/LocalizationProvider';
import { FetchLoggedIn } from './login/FetchLoggedIn';
import { TokenRoute } from './routes/token-route';
import { store } from './store';
import { supplierPortalDefaultTheme } from './themes/default';

declare global {
  interface Window {
    app: AppType;
    lang: string;
    authorizationToken?: string;
  }
}

const displayNameServiceProviderPortal = 'Service Provider Portal';

store.dispatch(
  AppContextActions.initialize({
    appType: AppType.SupplierPortal,
    displayName: displayNameServiceProviderPortal,
    features: defaultFeatures,
    embeddedView: disabledEmbeddedView,
  })
);

interface SupplierPortalProps {
  history: History;
}

const SupplierPortal: React.FunctionComponent<SupplierPortalProps> = ({ history }) => (
  <Provider store={store}>
    <OldMuiThemeProvider muiTheme={blueBaseTheme}>
      <MuiThemeProvider theme={supplierPortalDefaultTheme}>
        <Router history={history}>
          <Switch>
            <Route
              path={'/token'}
              render={(props) => {
                const uri = new URI(props.location.search);

                return <TokenRoute {...props} token={uri.search(true).token as string} />;
              }}
            />
            <Route
              render={() => (
                <FetchLoggedIn>
                  <LocalizationProvider>
                    <SupplierPortalRoute />
                  </LocalizationProvider>
                </FetchLoggedIn>
              )}
            />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </OldMuiThemeProvider>
  </Provider>
);

export const SupplierPortalApp = SupplierPortal;
