import type { IProductDetails } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { ISwitchSupplierResponseCard } from '@provider-portal/orders/switch/SwitchOrderDetails/SwitchSupplierResponseCard/config';
import type { ISaveDeskRequestedDetailsCard } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskRequestedDetailsCard/config';
import type { ISaveDeskRequestCard } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskRequestCard/config';
import type { ISaveDeskResponseCard } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskResponseCard/config';
import type { ISaveDeskResponseCardOld } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskResponseCardOld/config';
import type { IDetailsCard } from '../panels/DetailsCard/config';
import type { IInfoPapers } from '../panels/InfoPaper/config';
import type { IOrderStatusCard } from '../panels/OrderStatusCard/config';

export enum PageComponentType {
  SAVEDESK_REQUESTED_DETAILS_CARD = 'SAVEDESK_REQUESTED_DETAILS_CARD',
  SAVEDESK_RESPONSE_CARD = 'SAVEDESK_RESPONSE_CARD',
  SAVEDESK_REQUEST_CARD = 'SAVEDESK_REQUEST_CARD',
  SAVEDESK_RESPONSE_CARD_OLD = 'SAVEDESK_RESPONSE_CARD_OLD',
  CHANGEPLAN_REQUESTED_DETAILS_CARD = 'CHANGEPLAN_REQUESTED_DETAILS_CARD',
  CHANGEPLAN_RESPONSE_CARD = 'CHANGEPLAN_RESPONSE_CARD',
  CHANGEPLAN_REQUEST_CARD = 'CHANGEPLAN_REQUEST_CARD',
  CHANGEPLAN_RESPONSE_CARD_OLD = 'CHANGEPLAN_RESPONSE_CARD_OLD',
  DETAILS_CARD = 'DETAILS_CARD',
  SWITCH_ORDER_DETAILS_CARD = 'SWITCH_ORDER_DETAILS_CARD',
  SWITCH_SUPPLIER_RESPONSE_CARD = 'SWITCH_SUPPLIER_RESPONSE_CARD',
  ORDER_STATUS_CARD = 'ORDER_STATUS_CARD',
  SWITCH_CUSTOMER_DETAILS_CARD = 'SWITCH_CUSTOMER_DETAILS_CARD',
  INFO_PAPERS = 'INFO_PAPERS',
  PRODUCT_DETAILS = 'PRODUCT_DETAILS',
}

export type PageComponent =
  | ISaveDeskRequestedDetailsCard
  | ISaveDeskResponseCard
  | ISaveDeskRequestCard
  | ISaveDeskResponseCardOld
  | IDetailsCard
  | ISwitchSupplierResponseCard
  | IOrderStatusCard
  | IInfoPapers
  | IProductDetails;

export interface IPageComponent {
  type: PageComponentType;
  column: 1 | 2;
}

export interface IPageConfig {
  components: PageComponent[];
}

export const PageConfig = (components: PageComponent[]) => {
  return {
    components,
  };
};
