import { LocalizedMessage } from '@provider-portal/internationalization';
import { Checkbox } from '@provider-portal/libs/uiFramework/components/fields/Checkbox/config';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { WidgetType } from '@provider-portal/libs/uiFramework/components/widgets/config';
import * as React from 'react';
import { campaignPaper, internalPaper, mobileNamingSection, termsPaper } from '../common';
import type { ProductFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createProductFormLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { Currency } from '@provider-portal/models/subscription';
import { Locale } from '@provider-portal/internationalization/locale';

enum SwedenMobileProductFieldNames {
  MONTHLY_COST = 'monthlyCost',
  STARTUP_FEE = 'startupFee',
  DATA_CAPACITY_IN_GB = 'dataCapacityInGb',
  MESSAGES_LIMITATION = 'messagesLimitation',
  SAVE_UNUSED_CELLULAR_DATA = 'saveUnusedCellularData',
  ONLY_FOR_STUDENTS = 'onlyForStudents',
  FORM_TYPE = 'type',
}

const currency = Currency.SEK;
const locale = Locale.svSE;

const swedenMobilePapers = (hasAdminAccess: boolean): IFormPagePaper[] => [
  {
    sections: [mobileNamingSection, ...swedenMobileSections],
  },
  termsPaper(locale),
  campaignPaper,
  internalPaper(hasAdminAccess, currency, locale),
];

export const swedenMobileLayoutMap: ProductFormLayoutMap = createProductFormLayout(
  categories.mobile,
  Market.Sweden,
  swedenMobilePapers
);

const swedenMobileSections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="pricingSectionHeader" />,
    columns: 4,
    components: [
      CostInput({
        name: SwedenMobileProductFieldNames.MONTHLY_COST,
        label: <LocalizedMessage id="monthlyCostHeader" />,
        required: true,
        tooltipTitle: <LocalizedMessage id="monthlyCostHeader" />,
        tooltipText: <LocalizedMessage id="monthlyCostTooltipText" />,
        currency: currency,
        locale: locale,
      }),
      CostInput({
        name: SwedenMobileProductFieldNames.STARTUP_FEE,
        label: <LocalizedMessage id="startupFeeLabel2" />,
        tooltipTitle: <LocalizedMessage id="startupFeeLabel2" />,
        tooltipText: <LocalizedMessage id="startupFeeTooltipText" />,
        currency: currency,
        locale: locale,
      }),
      Checkbox({
        name: SwedenMobileProductFieldNames.ONLY_FOR_STUDENTS,
        label: <LocalizedMessage id="onlyForStudents" />,
      }),
      HiddenInput({
        name: SwedenMobileProductFieldNames.FORM_TYPE,
        defaultValue: 'SwedenMobileProductIncomingRequest',
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="phoneCallsHeader" />,
    columns: 1,
    divider: 'top',
    components: [
      {
        type: WidgetType.SWEDEN_PHONE_CALLS_LIMITATION_INPUT,
      },
    ],
  },
  {
    caption: <LocalizedMessage id="dataPlanHeader" />,
    captionText: <LocalizedMessage id="dataPlanCaptionText" />,
    columns: 4,
    divider: 'top',
    components: [
      NumberInput({
        name: SwedenMobileProductFieldNames.DATA_CAPACITY_IN_GB,
        label: <LocalizedMessage id="dataLabel" />,
        numberType: NumberType.FloatZeroOrGreater,
        helperText: <LocalizedMessage id="dataCapacityHelper" />,
        locale: locale,
      }),
      Checkbox({
        name: SwedenMobileProductFieldNames.SAVE_UNUSED_CELLULAR_DATA,
        label: <LocalizedMessage id="savedUnusedCellularData" />,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="textMessagesHeader" />,
    captionText: <LocalizedMessage id="textMessagesCaptionText" />,
    divider: 'top',
    columns: 4,
    components: [
      NumberInput({
        name: SwedenMobileProductFieldNames.MESSAGES_LIMITATION,
        label: <LocalizedMessage id="messagesPerMonthLabel" />,
        numberType: NumberType.IntegerZeroOrGreater,
        helperText: <LocalizedMessage id="messagesPerMonthHelper" />,
        locale: locale,
      }),
    ],
  },
];
