import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import * as React from 'react';
import { DividerSection } from '../../dividers/DividerSection';
import type { Columns } from '../../fields/configs/columns';
import type { IInfoItem } from '../../InfoItem';
import { InfoItem } from '../../InfoItem';
import { ComponentsLayout } from '../../layouts/ComponentsLayout';
import { Panel1 } from '../Panel1';
import styles from './styles.scss';

interface IItemsGroup {
  items: IInfoItem[];
}

export interface IInfoPanel {
  title: React.ReactNode;
  columns: Columns;
  itemsGroups: IItemsGroup[];
}

export const InfoPanel: React.FunctionComponent<IInfoPanel> = ({ title, itemsGroups, columns }) => {
  return (
    <Panel1 title={<Subtitle1>{title}</Subtitle1>} variant="flat">
      {itemsGroups.map((group, index) => (
        <InfoItems key={index} items={group.items} columns={columns} divider={index !== 0} />
      ))}
    </Panel1>
  );
};

interface IInfoItemsProps {
  divider: boolean;
  items: IInfoItem[];
  columns: Columns;
}

export const InfoItems: React.FunctionComponent<IInfoItemsProps> = ({ divider, items, columns }) => {
  const infoItems = items.map((item, index) => <InfoItem key={index} {...item} />);

  return (
    <>
      {divider && (
        <div className={styles.paddingTopBottom}>
          <DividerSection />
        </div>
      )}
      <ComponentsLayout components={infoItems} columns={columns} />
    </>
  );
};
