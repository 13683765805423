import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { colors } from '@provider-portal/styles/colors';
import * as React from 'react';
import { ChevronRightIcon } from '@provider-portal/libs/uiFramework/components/deprecated/ChevronRight';
import { DocumentIcon } from '@provider-portal/assets/icons/DocumentIcon';

interface IProps {
  linkText: React.ReactNode;
  linkUrl: string;
}

const styles = {
  marginTop: '16px',
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#f8f8f8',
  padding: '14px',
  borderRadius: '8px',
};

const linkTextStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const bodyStyles: React.CSSProperties = {
  maxWidth: '400px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginLeft: '8px',
};

export const TooltipLink: React.FunctionComponent<IProps> = ({ linkText, linkUrl }) => {
  return (
    <a style={styles} href={linkUrl}>
      <div style={linkTextStyles}>
        <DocumentIcon />
        <Body style={bodyStyles}>{linkText}</Body>
      </div>
      <ChevronRightIcon nativeColor={colors.primary} />
    </a>
  );
};
