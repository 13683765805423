import Grid from '@material-ui/core/Grid';
import { ResponsiveBar } from '@nivo/bar';
import LoadingPage from '@provider-portal/fetching/LoadingPage';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import type { Fetchable } from '@provider-portal/models/Fetchable';
import { FetchableFailure, FetchableLoading, FetchableSuccess } from '@provider-portal/models/Fetchable';
import { LoadingState } from '@provider-portal/models/LoadingState';
import { selectorMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';
import { colors } from '@provider-portal/styles/colors';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.scss';

const ExplainerText = () => (
  <Paper>
    <div style={{ padding: 16, display: 'flex', flexDirection: 'column' }}>
      <Headline5 colorVariant="darker">
        <LocalizedMessage id="rightHeadline1" />
      </Headline5>
      <Body style={{ marginTop: 8, marginBottom: 16 }} colorVariant="lighter">
        <LocalizedMessage id="rightParagraph1" />
      </Body>

      <Subtitle1 colorVariant="darker">
        <LocalizedMessage id="rightHeadline2" />
      </Subtitle1>
      <Body style={{ marginBottom: 16 }} colorVariant="lighter">
        <LocalizedMessage id="rightParagraph2" />
      </Body>

      <Subtitle1 colorVariant="darker">
        <LocalizedMessage id="rightHeadline3" />
      </Subtitle1>
      <Body style={{ marginBottom: 16 }} colorVariant="lighter">
        <LocalizedMessage id="rightParagraph3" />
      </Body>

      <div style={{ alignSelf: 'end' }}>
        <Button color="primary" label={<LocalizedMessage id="ctaButton" />} />
      </div>
    </div>
  </Paper>
);

interface NivoModel {
  name: string;
  blocks: number;
}

const Statistics = ({ supplierName, stats }: { supplierName: string; stats: NivoModel[] }) => (
  <Paper className={styles.fullheight}>
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <Subtitle1 style={{ padding: 16 }}>
          <LocalizedMessage id="statsTitle" values={{ supplierName }} />
        </Subtitle1>
        <ResponsiveBar
          colors={() => colors.primary}
          margin={{ top: 16, right: 32, bottom: 96, left: 64 }}
          indexBy="month"
          padding={0.7}
          keys={['blocks']}
          data={stats.map((month) => ({
            month: month.name,
            blocks: month.blocks,
          }))}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          theme={{
            fontFamily: 'Roboto',
            fontSize: 13,
            textColor: colors.grey2,
            tooltip: { basic: { fontFamily: 'Roboto' } },
          }}
        />
      </div>
    </div>
  </Paper>
);

export interface CancellationStatisticsApi {
  supplierId: string;
  cancellations: { [key: string]: number };
}

export async function getCancellationStatistics(supplierId: string): Promise<CancellationStatisticsApi> {
  return axios.get(`/api/v1/suppliers/${supplierId}/statistics/cancellations`).then((response) => response.data);
}

function convertApiToNivo(apiModel: CancellationStatisticsApi): NivoModel[] {
  if (apiModel.cancellations.length === 0) {
    return [];
  }

  // We need to set all months to 0, otherwise we might miss months in the graph.
  // e.g. if there are only cancellations in May and July we still should show June in the graph
  const monthlyCancellations = {};

  const days = Object.keys(apiModel.cancellations).sort();
  const lastDay = moment(days[days.length - 1]); // Should this just be current date instead?

  for (const i = moment(days[0]); lastDay.format('YYYY-MM') >= i.format('YYYY-MM'); i.add(1, 'month')) {
    const month = i.format('YYYY-MM');
    // @ts-ignore
    monthlyCancellations[month] = 0;
  }

  Object.keys(apiModel.cancellations).map((day) => {
    const month = moment(day).format('YYYY-MM');
    // @ts-ignore
    monthlyCancellations[month] += apiModel.cancellations[day];
  });

  return Object.keys(monthlyCancellations).map((month) => ({
    name: month,
    // @ts-ignore
    blocks: monthlyCancellations[month],
  }));
}

export const CancellationStatistics = withLocalization('supplier-portal-cancellation-statistics')(
  (props: { supplierId: string }) => {
    const [statistics, setStatistics] = useState<Fetchable<CancellationStatisticsApi>>(FetchableLoading);
    useEffect(() => {
      getCancellationStatistics(props.supplierId).then(
        (stats) => setStatistics(FetchableSuccess(stats)),
        (error) => setStatistics(FetchableFailure(error))
      );
    }, [props.supplierId]);
    const supplierName = useSelector(selectorMaybePartnerSupplier)?.name;
    if (!supplierName) {
      throw new Error('No merchant name available');
    }

    return (
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={6}>
          {statistics.type === LoadingState.Loading && <LoadingPage />}
          {statistics.type === LoadingState.Failure && (
            <Paper>
              <div style={{ padding: 16 }}>
                <Body>
                  <LocalizedMessage id="statsNotLoaded" />
                </Body>
              </div>
            </Paper>
          )}
          {statistics.type === LoadingState.Success && (
            <Statistics supplierName={supplierName} stats={convertApiToNivo(statistics.data)} />
          )}
        </Grid>
        <Grid item xs={6}>
          <ExplainerText />
        </Grid>
      </Grid>
    );
  }
);
