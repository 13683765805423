import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { CheckCircleIcon } from '@minna-technologies/minna-ui/icons/CheckCircle';
import { MinnaIcon } from '@minna-technologies/minna-ui/icons/Minna';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  flexGap: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  pageContent: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  readyToGoLiveButtons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  link: {
    color: theme.colors.action.primary,
    fontWeight: 'bold',
  },
}));

const ContactUsPageInner = () => {
  const { colors } = useTheme();
  const classes = useStyles();

  const onContactSupportClick = () => {
    window.open('mailto:team-merchant-features@minna.tech', '_blank', 'noopener noreferrer');
  };

  const onContactSalesClick = () => {
    window.open('mailto:erica.katsambis@minna.tech', '_blank', 'noopener noreferrer');
  };

  return (
    <Page
      appBarText={
        <div className={classes.flexGap}>
          <MinnaIcon nativeColor={colors.base.surface} />
          <Headline5 textColor="alternative">
            <LocalizedMessage id="title" />
          </Headline5>
        </div>
      }
      variant="details"
      showMenuForSmallerScreen
      showBackButton={false}
    >
      <div className={classes.pageContent}>
        <header>
          <Headline1>
            <LocalizedMessage id="header" />
          </Headline1>
        </header>
        <Card className={classes.card}>
          <Headline3>
            <LocalizedMessage id="needSupportTitle" />
          </Headline3>
          <Body>
            <LocalizedMessage id="needSupportMessage" />
          </Body>
          <div>
            <Button
              color="primary"
              label={<LocalizedMessage id="needSupportButton" />}
              onClick={onContactSupportClick}
            />
          </div>
        </Card>
        <Card className={classes.card}>
          <Headline3>
            <LocalizedMessage id="contactSalesTitle" />
          </Headline3>
          <Body>
            <LocalizedMessage id="contactSalesMessage" />
          </Body>
          <div>
            <Button
              color="primary"
              label={<LocalizedMessage id="contactSalesButton" />}
              onClick={onContactSalesClick}
            />
          </div>
        </Card>
        <Card className={classes.card}>
          <Headline3>
            <LocalizedMessage id="readyToGoLiveTitle" />
          </Headline3>
          <Body>
            <LocalizedMessage id="readyToGoLiveMessage" />
          </Body>
          <div className={classes.flexGap}>
            <CheckCircleIcon nativeColor={colors.action.primary} bold />
            <Body>
              <LocalizedMessage id="readyToGoLiveStep1" />
            </Body>
          </div>
          <div className={classes.flexGap}>
            <CheckCircleIcon nativeColor={colors.action.primary} bold />
            <Body>
              <LocalizedMessage id="readyToGoLiveStep2" />
            </Body>
          </div>
          <div className={classes.flexGap}>
            <CheckCircleIcon nativeColor={colors.action.primary} bold />
            <Body>
              <LocalizedMessage id="readyToGoLiveStep3" />
            </Body>
          </div>
          <div className={classes.readyToGoLiveButtons}>
            <Button color="secondary" label={<LocalizedMessage id="readyToGoLiveContractTemplateButton" />} />
            <Button
              color="primary"
              label={<LocalizedMessage id="readyToGoLiveContactUsButton" />}
              onClick={onContactSalesClick}
            />
          </div>
        </Card>
      </div>
    </Page>
  );
};

export const ContactUsPage = withLocalization('ContactUsPage')(ContactUsPageInner);
