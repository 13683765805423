import { belgiumElectricityCampaignLayoutMap } from '@provider-portal/campaigns/CampaignForm/config/belgium/electricity';
import { belgiumGasCampaignLayoutMap } from '@provider-portal/campaigns/CampaignForm/config/belgium/gas';
import { denmarkElectricityCampaignLayoutMap } from '@provider-portal/campaigns/CampaignForm/config/denmark/electricity';
import { finlandElectricityCampaignLayoutMap } from '@provider-portal/campaigns/CampaignForm/config/finland/electricity';
import { spainElectricityCampaignLayoutMap } from '@provider-portal/campaigns/CampaignForm/config/spain/electricity';
import {
  belgiumMobileCampaignLayoutMap,
  denmarkMobileCampaignLayoutMap,
  swedenMobileCampaignLayoutMap,
} from '@provider-portal/campaigns/CampaignForm/config/mobile';
import { swedenElectricityCampaignLayoutMap } from '@provider-portal/campaigns/CampaignForm/config/sweden/electricity';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { belgiumElectricityLayoutMap } from '@provider-portal/products/ProductForm/config/belgium/electricity';
import { belgiumGasLayoutMap } from '@provider-portal/products/ProductForm/config/belgium/gas';
import { belgiumMobileLayoutMap } from '@provider-portal/products/ProductForm/config/belgium/mobile';
import { denmarkElectricityLayoutMap } from '@provider-portal/products/ProductForm/config/denmark/electricity';
import { denmarkMobileLayoutMap } from '@provider-portal/products/ProductForm/config/denmark/mobile';
import { finlandElectricityLayoutMap } from '@provider-portal/products/ProductForm/config/finland/electricity';
import { swedenElectricityLayoutMap } from '@provider-portal/products/ProductForm/config/sweden/electricity';
import { swedenMobileLayoutMap } from '@provider-portal/products/ProductForm/config/sweden/mobile';
import { spainElectricityLayoutMap } from '@provider-portal/products/ProductForm/config/spain/electricity';
import type { VerticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';
import type { HasAdminAccessFunc } from '../layouts/productFormLayouts';
import {
  swedenMobileCategoryLayoutMap,
  swedenElectricityCategoryLayoutMap,
} from '@provider-portal/provider/ProviderForm/CategoriesPaper/CategorySubForm/config/sweden';
import { denmarkMobileCategoryLayoutMap } from '@provider-portal/provider/ProviderForm/CategoriesPaper/CategorySubForm/config/denmark';
import {
  belgiumElectricityCategoryLayoutMap,
  belgiumGasCategoryLayoutMap,
  belgiumMobileCategoryLayoutMap,
} from '@provider-portal/provider/ProviderForm/CategoriesPaper/CategorySubForm/config/belgium';
import {
  belgiumElectricityFormDataDetailsTypeMap,
  belgiumGasFormDataDetailsTypeMap,
  belgiumMobileFormDataDetailsTypeMap,
  denmarkMobileFormDataDetailsTypeMap,
  swedenElectricityFormDataDetailsTypeMap,
  swedenMobileFormDataDetailTypeMap,
} from '@provider-portal/provider/ProviderForm/config';
import type { CategoryDetailsFormDataType } from '@provider-portal/provider/models';
import { genericProductFormLayoutMaps } from '@provider-portal/products/ProductForm/config/common/genericProduct';
import type { WidgetType } from '../components/widgets/config';
import { campaignIdSelectEnricherMap } from '../components/widgets/CampaignIdSelect/config';
import { swedenPhoneCallsLimitationInputWidgetEnricherMap } from '../components/widgets/SwedenPhoneCallsLimitationInput/config';
import { termsAndConditionsUploadEnricherMap } from '../components/widgets/TermsAndConditionsUpload/config';
import { monthlyDiscountEnricherMap } from '../components/widgets/MonthlyDiscount/config';
import { monthlyDataCampaignEnricherMap } from '../components/widgets/MonthlyDataCampaign/config';
import type { EnrichWithFormData } from '../enrichRequest';
import { translateableFieldEnricherMap } from '../components/widgets/TranslatableField/config';

/**
 * This is where we add all market specific modules.
 * The intention of this file is to create a central point where
 * all modules of a market needs to be added.
 *
 * Once a market modules is added to this file the ui-framework
 * should be able to take care of running the provided module.
 */
interface ILayouts {
  productFormLayouts: Map<VerticalMarketKey, HasAdminAccessFunc>;
  campaignFormLayouts: Map<VerticalMarketKey, IFormPagePaper[]>;
  marketCategorySectionLayouts: Map<VerticalMarketKey, IFieldSectionConfig>;
}

interface IDetails {
  categoryDetailsTypes: Map<VerticalMarketKey, CategoryDetailsFormDataType>;
}

interface IForms {
  enrichFuncs: Map<WidgetType, EnrichWithFormData>;
}

interface IUiFramework {
  forms: IForms;
  layouts: ILayouts;
  details: IDetails;
}

const campaignFormLayouts = new Map<VerticalMarketKey, IFormPagePaper[]>([
  swedenElectricityCampaignLayoutMap,
  swedenMobileCampaignLayoutMap,
  denmarkMobileCampaignLayoutMap,
  denmarkElectricityCampaignLayoutMap,
  finlandElectricityCampaignLayoutMap,
  belgiumElectricityCampaignLayoutMap,
  belgiumGasCampaignLayoutMap,
  belgiumMobileCampaignLayoutMap,
  spainElectricityCampaignLayoutMap,
]);

const productFormLayouts = new Map<VerticalMarketKey, HasAdminAccessFunc>([
  ...genericProductFormLayoutMaps,
  swedenElectricityLayoutMap,
  swedenMobileLayoutMap,
  denmarkMobileLayoutMap,
  denmarkElectricityLayoutMap,
  finlandElectricityLayoutMap,
  belgiumElectricityLayoutMap,
  belgiumGasLayoutMap,
  belgiumMobileLayoutMap,
  spainElectricityLayoutMap,
]);

const enrichFuncs: Map<WidgetType, EnrichWithFormData> = new Map([
  campaignIdSelectEnricherMap,
  swedenPhoneCallsLimitationInputWidgetEnricherMap,
  termsAndConditionsUploadEnricherMap,
  monthlyDiscountEnricherMap,
  monthlyDataCampaignEnricherMap,
  translateableFieldEnricherMap,
]);

const marketCategorySectionLayouts = new Map<VerticalMarketKey, IFieldSectionConfig>([
  swedenMobileCategoryLayoutMap,
  swedenElectricityCategoryLayoutMap,
  denmarkMobileCategoryLayoutMap,
  belgiumElectricityCategoryLayoutMap,
  belgiumGasCategoryLayoutMap,
  belgiumMobileCategoryLayoutMap,
]);

const categoryDetailsTypes = new Map<VerticalMarketKey, CategoryDetailsFormDataType>([
  swedenMobileFormDataDetailTypeMap,
  swedenElectricityFormDataDetailsTypeMap,
  denmarkMobileFormDataDetailsTypeMap,
  belgiumElectricityFormDataDetailsTypeMap,
  belgiumGasFormDataDetailsTypeMap,
  belgiumMobileFormDataDetailsTypeMap,
]);

export const uiFramework: IUiFramework = {
  forms: {
    enrichFuncs,
  },
  layouts: {
    productFormLayouts,
    campaignFormLayouts,
    marketCategorySectionLayouts,
  },
  details: {
    categoryDetailsTypes,
  },
};
