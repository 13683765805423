import SvgIcon from '@material-ui/core/SvgIcon';
import type { CSSProperties, ReactNode } from 'react';
import * as React from 'react';

export interface IconProps {
  children?: ReactNode;
  nativeColor?: string;
  viewBox?: string;
  size?: number;
  className?: string;
  style?: CSSProperties;
  onClick?: React.ReactEventHandler<any>;
  'data-test'?: string;
}

export const BookIcon: React.ComponentType<IconProps> = ({ nativeColor = '#4A4B4B', ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.819337 1.08048C0.978196 1.02091 1.14742 0.993928 1.31693 1.00114C5.08368 1.16574 8.7515 2.24881 12.0003 4.15291C15.2491 2.24881 18.916 1.16577 22.6828 1.00118C22.8523 0.993964 23.0224 1.02091 23.1812 1.08048C23.3397 1.13989 23.4846 1.2305 23.6073 1.34694C23.7314 1.46434 23.8303 1.60581 23.898 1.76272C23.9657 1.9199 24.0006 2.08926 24.0006 2.26041C24.0006 2.26055 24.0006 2.26026 24.0006 2.26041V18.9986C24.0002 19.3237 23.8745 19.6369 23.6494 19.8715C23.4244 20.1061 23.1176 20.2449 22.7928 20.2589C22.7467 20.2609 22.7005 20.2586 22.6548 20.2521C21.0348 20.0213 19.3851 20.1158 17.8021 20.5299C16.2191 20.9441 14.7345 21.6696 13.4352 22.664C13.3834 22.7037 13.3266 22.7364 13.2664 22.7613C12.8826 22.9203 12.468 22.9885 12.0547 22.9615C12.0367 22.9628 12.0186 22.9634 12.0003 22.9634C11.982 22.9634 11.9638 22.9628 11.9458 22.9615C11.5326 22.9885 11.1179 22.9203 10.7342 22.7613C10.6739 22.7364 10.6172 22.7037 10.5654 22.664C9.26603 21.6696 7.78145 20.9441 6.19844 20.5299C4.61543 20.1158 2.96574 20.0213 1.3458 20.2521C1.30011 20.2586 1.25391 20.2609 1.2078 20.2589C0.883004 20.2449 0.576125 20.1061 0.351119 19.8715C0.126112 19.6369 0.000335105 19.3245 4.17242e-07 18.9994L0 2.25339C0.000910363 2.08335 0.0363279 1.91526 0.104088 1.75929C0.171669 1.60374 0.270031 1.46347 0.393248 1.34694C0.516007 1.2305 0.660899 1.13989 0.819337 1.08048ZM12.7503 21.303C14.153 20.2744 15.738 19.5195 17.4225 19.0788C19.0793 18.6454 20.8013 18.5241 22.5005 18.72V2.51193C19.0619 2.70172 15.7192 3.71007 12.7503 5.45193V21.303ZM11.2503 5.45193C8.2814 3.71007 4.93862 2.70172 1.50004 2.51193V18.72C3.19927 18.5241 4.92125 18.6454 6.57811 19.0788C8.26253 19.5195 9.8476 20.2744 11.2503 21.303V5.45193ZM23.6073 1.34694C23.6076 1.34725 23.608 1.34756 23.6083 1.34787L23.0915 1.89142L23.6063 1.34602C23.6067 1.34633 23.607 1.34664 23.6073 1.34694Z"
      fill={nativeColor}
    />
  </SvgIcon>
);
