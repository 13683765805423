import Grid from '@material-ui/core/Grid';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
// @ts-ignore
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { TextField } from '@provider-portal/libs/uiFramework/components/deprecated/TextField';
import { LocalizedMessage } from '@provider-portal/internationalization';
import type { PartnerSupplier } from '@provider-portal/PartnerSupplier/models';
import PropTypes from 'prop-types';
import * as React from 'react';
import type { SupplierPortalUserRequest } from '../models';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Section } from '@provider-portal/libs/uiFramework/components/sections/Section';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select';
import { fetchSupportedLocalesByProvider } from '@provider-portal/internationalization/api';
import type { Locale } from '@provider-portal/internationalization/locale';

export interface AddUserComponentProps {
  partnerSuppliers: PartnerSupplier[];
  onCreateUser(incomingUser: SupplierPortalUserRequest): void;
}

interface UserFormData {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
  partnerSupplierId: string;
}

const spacing = 2;

export const AddUserComponent: React.FunctionComponent<AddUserComponentProps> = (
  { partnerSuppliers, onCreateUser },
  { localizeMessage }
) => {
  function createUser(values: UserFormData) {
    onCreateUser({
      username: values.username,
      emailAddress: values.email,
      name: { first: values.firstName, last: values.lastName },
      locale: values.locale,
      partnerSupplierId: values.partnerSupplierId,
    });
  }

  const [selectedProviderId, setSelectedProviderId] = React.useState<string | undefined>();
  const [supportedLocales, setSupportedLocales] = React.useState<Locale[]>([]);

  const onProviderIdChanged = async (providerId: string) => {
    setSelectedProviderId(providerId);
    const fetchedSupportedLocales = await fetchSupportedLocalesByProvider(providerId);
    setSupportedLocales(fetchedSupportedLocales);
  };

  return (
    <Page appBarText={<LocalizedMessage id="addUserTitleBar" />} variant="details">
      <Paper>
        <Section>
          <Form onValidSubmit={createUser}>
            <Grid container spacing={spacing}>
              <Grid item xs={4}>
                <TextField
                  name="username"
                  placeholder={localizeMessage('username')}
                  requiredErrorText={<LocalizedMessage id="usernameIsRequired" />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="email"
                  placeholder={localizeMessage('email')}
                  requiredErrorText={<LocalizedMessage id="emailIsRequired" />}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={spacing}>
              <Grid item xs={4}>
                <TextField
                  name="firstName"
                  placeholder={localizeMessage('firstName')}
                  requiredErrorText={<LocalizedMessage id="firstNameIsRequired" />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="lastName"
                  placeholder={localizeMessage('lastName')}
                  requiredErrorText={<LocalizedMessage id="lastNameIsRequired" />}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={spacing}>
              <Grid item xs={4}>
                <Select
                  name="partnerSupplierId"
                  options={partnerSuppliers.map((supplier) => ({ label: supplier.name, value: supplier.id }))}
                  value={selectedProviderId}
                  onChange={async (e) => onProviderIdChanged(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  name="locale"
                  options={supportedLocales.map((locale) => ({ label: locale, value: locale }))}
                  required
                  disabled={supportedLocales.length === 0}
                />
              </Grid>
            </Grid>
            <Grid container spacing={spacing}>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button label={<LocalizedMessage id="saveUser" />} color="positive" variant="contained" type="submit" />
              </Grid>
            </Grid>
          </Form>
        </Section>
      </Paper>
    </Page>
  );
};

AddUserComponent.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
