import { LocalizedMessage } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import {
  approveSaveDeskOffer,
  archiveSaveDeskOffer,
  unpublishSaveDeskOffer,
  updateSaveDeskOffer,
} from '@provider-portal/saveDesk/api';
import { SaveDeskOfferSnackbarState } from '@provider-portal/saveDesk/snackbar-state';
import { Sentry } from '@provider-portal/sentry';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import { debouncePromise, debouncePromiseWithDelay } from '@provider-portal/utils/debounce-promise';
import compact from 'lodash/compact';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import type {
  ISaveDeskOffer2,
  ISaveDeskOffer2FormData,
  ISaveDeskOffer2FormDataInner,
  ISaveDeskOffer2FormViewModel,
} from '../models';
import { isOfferActive, isOfferDraft, SaveDeskOffer2FormAction, SaveDeskOffer2State } from '../models';
import { SaveDeskOfferForm } from '../SaveDeskOfferForm';
import { PublishButton } from './PublishButton';

interface IEditSaveDeskOfferComponentProps {
  providerId: string;
  saveDeskOfferId: string;
  viewModel: ISaveDeskOffer2FormViewModel;
  setViewModel(viewModel: ISaveDeskOffer2FormViewModel): void;
  setSnackbarState(state: SaveDeskOfferSnackbarState): void;
  setLoadingState(state: PortalLoadingState): void;
  hasAdminAccess: boolean;
  actionType: SaveDeskOffer2FormAction;
  setActionType(actionType: SaveDeskOffer2FormAction): void;
}

export const EditSaveDeskOfferComponent: React.FunctionComponent<IEditSaveDeskOfferComponentProps> = ({
  providerId,
  saveDeskOfferId,
  viewModel,
  setViewModel,
  setSnackbarState,
  setLoadingState,
  hasAdminAccess,
  actionType,
  setActionType,
}) => {
  const history = useHistory();
  const offerIsDraft = isOfferDraft(viewModel.offerState);
  const offerIsPublished = isOfferActive(viewModel.offerState);
  const showSaveDraftButton =
    viewModel.offerState === SaveDeskOffer2State.Draft ||
    (viewModel.offerState === SaveDeskOffer2State.PendingApproval && hasAdminAccess);

  const editSaveDeskOfferUrl = (saveDeskOfferIdToNavigateTo?: string) => {
    return URI(history.location.pathname)
      .segment(-1, saveDeskOfferIdToNavigateTo || '')
      .toString();
  };

  return (
    <SaveDeskOfferForm
      appBarTitle={<LocalizedMessage id="editSaveDeskOfferTitleBar" />}
      leftSidedButtons={
        offerIsDraft && (
          <Button
            key="delete"
            label={<LocalizedMessage id="archiveButton" />}
            color="negative"
            onClick={debouncePromise(onArchive)}
          />
        )
      }
      rightSidedButtons={compact([
        showSaveDraftButton && (
          <Button
            key="save-draft"
            label={<LocalizedMessage id="saveDraftButton" />}
            color="secondary"
            type="submit"
            onClick={() => setActionType(SaveDeskOffer2FormAction.SAVE)}
          />
        ),
        offerIsPublished && (
          <Button
            key="unpublish"
            label={<LocalizedMessage id="unpublishButton" />}
            color="negative"
            onClick={debouncePromise(onUnpublish)}
          />
        ),
        <PublishButton
          key="publish"
          onClick={setActionType}
          hasAdminAccess={hasAdminAccess}
          offerState={viewModel.offerState}
        />,
      ])}
      onSubmit={debouncePromiseWithDelay(2000, onSave)}
      providerId={providerId}
      viewModel={viewModel}
    />
  );

  async function onSave(vm: ISaveDeskOffer2FormDataInner) {
    const disclaimerWithTranslationsOrUndefined =
      !!vm.disclaimer && Object.keys(vm.disclaimer).length > 0 ? vm.disclaimer : undefined;
    const formData = {
      cancellationReasons: vm.cancellationReasons,
      name: vm.name,
      description: vm.description,
      disclaimer: disclaimerWithTranslationsOrUndefined,
      action: actionType,
      externalOfferId: vm.externalOfferId,
    } as ISaveDeskOffer2FormData;

    try {
      let updatedOffer = await updateSaveDeskOffer(providerId, saveDeskOfferId, formData);
      if (actionType === SaveDeskOffer2FormAction.SAVE_AND_REQUEST_APPROVAL && hasAdminAccess) {
        updatedOffer = await approveSaveDeskOffer(providerId, updatedOffer.id);
      }
      if (updatedOffer.id !== saveDeskOfferId) {
        history.replace(editSaveDeskOfferUrl(updatedOffer.id));
      }

      setViewModel(createViewModelWithNewFormData(viewModel, updatedOffer));

      setSnackbarState(SaveDeskOfferSnackbarState.OFFER_UPDATED);
    } catch (error) {
      setLoadingState(PortalLoadingState.Failure);
      Sentry.captureExceptionWithMessage(error, 'Failed to update saveDeskOffer');
    }
  }

  async function onUnpublish() {
    unpublishSaveDeskOffer(providerId, saveDeskOfferId)
      .then((updatedOffer) => {
        history.replace(editSaveDeskOfferUrl(updatedOffer.id));

        setViewModel(createViewModelWithNewFormData(viewModel, updatedOffer));

        setSnackbarState(SaveDeskOfferSnackbarState.OFFER_UNPUBLISHED);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to unpublish saveDeskOffer');
      });
  }

  async function onArchive() {
    archiveSaveDeskOffer(providerId, saveDeskOfferId)
      .then(() => {
        const listSaveDeskOffersUrl = URI(history.location.pathname).segment(-1, '').toString();
        history.replace(listSaveDeskOffersUrl);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to delete saveDeskOffer');
      });
  }
};

function createViewModelWithNewFormData(currentViewModel: ISaveDeskOffer2FormViewModel, newOfferData: ISaveDeskOffer2) {
  const previouslySelectedCancellationReasons = currentViewModel.cancellationReasons.filter(
    (reason) => !newOfferData.cancellationReasons.includes(reason)
  );
  const availableCancellationReasons = currentViewModel.cancellationReasonsNotAttachedToAnOffer.filter(
    (reason) => !newOfferData.cancellationReasons.includes(reason)
  );
  return {
    id: newOfferData.id,
    name: newOfferData.name,
    description: newOfferData.description,
    cancellationReasons: newOfferData.cancellationReasons,
    cancellationReasonsNotAttachedToAnOffer: previouslySelectedCancellationReasons.concat(availableCancellationReasons),
    disclaimer: newOfferData.disclaimer,
    offerState: newOfferData.offerState,
  };
}
