import React from 'react';
import type { Omit, TypographyProps } from '../Typography';
import { Typography } from '../Typography';

export const ButtonText: React.FunctionComponent<ButtonProps> = ({ style, ...otherProps }) => {
  /**
   * 0.875rem corresponds to 14px in Invision
   */
  const baseStyle: React.CSSProperties = {
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.75px',
    lineHeight: '1.75em',
    textTransform: 'none',
    ...style,
  };

  return <Typography {...otherProps} variant="body1" style={baseStyle} />;
};

type ButtonProps = Omit<TypographyProps, 'variant'>;
