import type { EnrichMap, EnrichWithFormData, IEnrichProps } from '@provider-portal/libs/uiFramework';
import type { IUploadFile } from '../../fields/file/models';
import { WidgetType } from '../config';

export interface ITermsAndConditionsUploadWidget {
  type: WidgetType.TERMS_AND_CONDITIONS_UPLOAD;
  name: string;
  providerId: string;
  termsAndConditions?: IUploadFile[];
}

const termsAndConditionsUploadEnricher: EnrichWithFormData = (props: IEnrichProps<any>) => {
  return {
    ...props.config,
    providerId: props.providerId,
    termsAndConditions: props.formData.termsAndConditions,
  };
};

export const termsAndConditionsUploadEnricherMap: EnrichMap = [
  WidgetType.TERMS_AND_CONDITIONS_UPLOAD,
  termsAndConditionsUploadEnricher,
];
