import React from 'react';
import type { IChangePlan2FormDataInner, PaymentInterval, IChangePlan2FormViewModelDefault } from '../models';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExampleChangePlanView from '@provider-portal/assets/illustrations/ExampleChangePlanView.png';
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { HorizontalButtonGroup } from '@provider-portal/libs/uiFramework/components/layouts/HorizontalButtonGroup';
import { parseForm } from '@provider-portal/libs/uiFramework/components/fields/configs/parse-fields';
import { TranslatableField } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField';
import type { Market } from '@provider-portal/app-context/constants';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { ImageField } from '@provider-portal/libs/uiFramework/components/fields/Image';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import { Checkbox } from '@provider-portal/libs/uiFramework/components/fields/Checkbox';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import { getDefaultCurrency } from '@provider-portal/app-context/state';
import { PaymentIntervalSelect } from '../components/PaymentIntervalSelect';
import PropTypes from 'prop-types';
import { parseAmountWithDecimals } from '../utils';
import { useSelector } from 'react-redux';
import { selectorMarketFromMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';

interface IAddChangePlanProps {
  viewModel: IChangePlan2FormViewModelDefault;
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
  onSubmit(formData: IChangePlan2FormDataInner): Promise<void>;
  providerId: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  marginTop4: {
    marginTop: theme.spacing(4),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  columnOne: {
    flex: '2',
  },
  columnTwo: {
    flex: '1',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  planIdTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  priceInputsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  priceInput: {
    width: '100%',
  },
}));

const ChangePlanFormInner: React.FunctionComponent<IAddChangePlanProps> = (
  { viewModel, leftSidedButtons, rightSidedButtons, onSubmit, providerId },
  { localizeMessage, localizeCurrency }
) => {
  const classes = useStyles();
  const market: Market | undefined = useSelector(selectorMarketFromMaybePartnerSupplier);
  const currency = getDefaultCurrency(market);
  const [currentPriceInterval, setCurrentPriceInterval] = React.useState<PaymentInterval | undefined>(
    viewModel.paymentInterval
  );
  const [currentPrice, setCurrentPrice] = React.useState(viewModel.price?.amount ?? 0);

  const handlePlanPriceInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      // @ts-ignore
      onChangeCost(e?.target?.value ?? 0);
    }
  };

  const onChangeCost = (e: React.KeyboardEvent<HTMLInputElement>) =>
    // @ts-ignore
    setCurrentPrice(parseAmountWithDecimals(e.target.value));
  const onChangePaymentInterval = (interval: any) => setCurrentPriceInterval(interval);

  return (
    <div className={classes.marginTop4}>
      <Form onValidSubmitWithEvent={async (event: any) => onSubmit(parseForm(event.currentTarget))}>
        <div className={classes.container}>
          <div className={classes.columnOne}>
            <Card className={classes.cardContainer}>
              <div>
                <Subtitle1>
                  <LocalizedMessage id="planNameTitle" />
                </Subtitle1>
              </div>
              <TranslatableField
                providerId={providerId}
                market={market}
                name={ChangePlanOfferFieldNames.NAME}
                title={<LocalizedMessage id="planNameInputTitle" />}
                defaultValue={viewModel.name}
                caption={<LocalizedMessage id="characterLimit" values={{ characterLimit: 40 }} />}
                maxlength={40}
                required
              />
              <TranslatableField
                providerId={providerId}
                market={market}
                name={ChangePlanOfferFieldNames.DESCRIPTION}
                title={<LocalizedMessage id="planDescriptionInputLabel" />}
                description={<LocalizedMessage id="planDescriptionInputDescription" />}
                defaultValue={viewModel.description}
                caption={<LocalizedMessage id="characterLimit" values={{ characterLimit: 40 }} />}
                maxlength={40}
              />
              <div>
                <Panel1
                  title={
                    <Subtitle1>
                      <LocalizedMessage id="planPriceInputTitle" />
                    </Subtitle1>
                  }
                  variant="flat"
                >
                  <Body1>
                    <LocalizedMessage id="planPriceInputDescription" />
                  </Body1>
                  <div>
                    <Checkbox
                      name={ChangePlanOfferFieldNames.PRICE}
                      label={<LocalizedMessage id="planPriceInputFree" />}
                      onChange={() => setCurrentPrice(0)}
                    />
                  </div>
                  <div className={classes.priceInputsContainer}>
                    <div className={classes.priceInput}>
                      <RHTextInput
                        type="number"
                        name={ChangePlanOfferFieldNames.PRICE}
                        validation={{ required: localizeMessage('planPriceInputrequired') }}
                        label={localizeMessage('planPriceInputLabel')}
                        defaultValue={currentPrice}
                        fullWidth
                        suffix={localizeCurrency(currency)}
                        inputProps={{
                          onBlur: (e) => setCurrentPrice(Number(e.target.value)),
                          /* @ts-ignore */
                          onKeyPress: (e) => handlePlanPriceInputKeyPress(e),
                          /* @ts-ignore */
                          step: '0.01',
                        }}
                      />
                    </div>
                    <div className={classes.priceInput}>
                      <PaymentIntervalSelect
                        defaultValue={currentPriceInterval}
                        name={ChangePlanOfferFieldNames.PAYMENT_INTERVAL}
                        label={localizeMessage('planPriceFrequencyInputLabel')}
                        onChange={onChangePaymentInterval}
                        fullWidth
                      />
                    </div>
                  </div>
                </Panel1>
              </div>
              <div>
                <div className={classes.marginBottom}>
                  <div className={classes.marginBottom}>
                    <Subtitle1>
                      <LocalizedMessage id="planIdInputTitle" />
                    </Subtitle1>
                  </div>
                  <Body1>
                    <LocalizedMessage id="planIdInputDescription" />
                  </Body1>
                </div>
                <TextInput
                  name={ChangePlanOfferFieldNames.PLAN_ID}
                  label={<LocalizedMessage id="planIdInputPlaceholder" />}
                  defaultValue={viewModel.planId}
                  required
                />
              </div>
              <div className={classes.marginTop4}>
                <HorizontalButtonGroup leftSidedButtons={leftSidedButtons} rightSidedButtons={rightSidedButtons} />
              </div>
            </Card>
          </div>
          <div className={classes.columnTwo}>
            <Card className={classes.cardContainer}>
              <div className={classes.marginBottom}>
                <div className={classes.marginBottom}>
                  <Subtitle1>
                    <LocalizedMessage id="changePlanExampleTitle" />
                  </Subtitle1>
                </div>
                <Body1>
                  <LocalizedMessage id="changePlanExampleText" />
                </Body1>
              </div>
              <ImageField
                name={ChangePlanOfferFieldNames.IMAGE}
                src={ExampleChangePlanView}
                alt={localizeMessage('expamplePlanImageAltText')}
              />
            </Card>
          </div>
        </div>
      </Form>
    </div>
  );
};

ChangePlanFormInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
  localizeCurrency: PropTypes.func.isRequired,
};

export const ChangePlanForm = withLocalization('ChangePlan')(ChangePlanFormInner);

enum ChangePlanOfferFieldNames {
  NAME = 'name',
  DESCRIPTION = 'description',
  PRICE = 'price',
  PAYMENT_INTERVAL = 'paymentInterval',
  PLAN_ID = 'planId',
  IMAGE = 'image',
}
