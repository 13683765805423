import React from 'react';
import { FieldMessage } from './FieldMessage';

export enum MeasurementUnit {
  KWH = 'kWh',
  YEAR = 'year',
  MINUTE = 'minute',
  PERCENT = '%',
  MEGABYTE = 'MB',
}

export const localizeCostUnit = (costUnit: MeasurementUnit) => {
  switch (costUnit) {
    case MeasurementUnit.YEAR:
      return <FieldMessage id="year" />;
    case MeasurementUnit.MINUTE:
      return <FieldMessage id="minute" />;
    case MeasurementUnit.KWH:
      return costUnit;
    case MeasurementUnit.PERCENT:
      return costUnit;
    case MeasurementUnit.MEGABYTE:
      return costUnit;
  }

  throw new Error(`No localized label specified for unit ${costUnit}`);
};
