import { DetailsPageAppBar } from '@provider-portal/libs/uiFramework/components/headers/DetailsPageAppBar';
import * as React from 'react';
import { OverviewPageAppBar } from '../../headers/OverviewPageAppBar';
import styles from './styles.scss';

export interface IPageProps {
  appBarText: string | React.ReactNode;
  appBarRightElement?: React.ReactNode;
  variant: 'overview' | 'details' | 'details-grey';
  showMenuForSmallerScreen?: boolean;
  showBackButton?: boolean;
  children?: React.ReactNode;
}

export const Page: React.FunctionComponent<IPageProps> = ({
  children,
  appBarText,
  appBarRightElement,
  variant,
  showMenuForSmallerScreen,
  showBackButton,
}) => {
  return (
    <>
      {['details', 'details-grey'].includes(variant) ? (
        <DetailsPageAppBar
          colorVariant={variant === 'details' ? 'primary' : 'grey'}
          showMenuForSmallerScreen={showMenuForSmallerScreen}
          showBackButton={showBackButton}
        >
          {appBarText}
        </DetailsPageAppBar>
      ) : (
        <OverviewPageAppBar rightElement={appBarRightElement}>{appBarText}</OverviewPageAppBar>
      )}
      <div className={styles.page}>{children}</div>
    </>
  );
};
