import Card from '@material-ui/core/Card';
import { LocalizedMessage } from '@provider-portal/internationalization';
import type { Locale } from '@provider-portal/internationalization/locale';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import type { TextByLanguage } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/config';
import type { PortalLoadingState } from '@provider-portal/types/loading-state';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import * as React from 'react';
import type {
  CancellationReason,
  ISaveDeskOffer2FormViewModel,
  ISaveDeskOfferCancellationReasonsSelectionForm,
} from '../models';
import { SaveDeskOffer2FormAction, SaveDeskOffer2State } from '../models';
import { SaveDeskOfferForm } from '../SaveDeskOfferForm';
import { PublishButton } from './PublishButton';

interface IAddSaveDeskOfferComponentProps {
  providerId: string;
  onGoBack(): void;
  loadingState: PortalLoadingState;
  formData?: ISaveDeskOfferCancellationReasonsSelectionForm;
  setLoadingState(state: PortalLoadingState): void;
  hasAdminAccess: boolean;
  setActionType(actionType: SaveDeskOffer2FormAction): void;
  onCreate(viewModel: ISaveDeskOffer2FormViewModel): Promise<void>;
}

export const AddSaveDeskOfferComponent: React.FunctionComponent<IAddSaveDeskOfferComponentProps> = ({
  providerId,
  onGoBack,
  loadingState,
  formData,
  hasAdminAccess,
  setActionType,
  onCreate,
}) => {
  return (
    <LoadingPage loadingState={loadingState}>
      {formData &&
        (formData.availableCancellationReasons.length === 0 ? (
          <Page variant="details" appBarText={<LocalizedMessage id="addSaveDeskOfferTitleBar" />}>
            <Card style={{ padding: 16 }}>
              <Body>
                <LocalizedMessage id="noCancellationReasonsAvailableHelpText" />
              </Body>
            </Card>
          </Page>
        ) : (
          <SaveDeskOfferForm
            appBarTitle={<LocalizedMessage id="addSaveDeskOfferTitleBar" />}
            leftSidedButtons={[
              <Button
                key="delete"
                label={<LocalizedMessage id="archiveButton" />}
                color="negative"
                onClick={onGoBack}
              />,
            ]}
            rightSidedButtons={[
              <Button
                key="save-draft"
                label={<LocalizedMessage id="saveDraftButton" />}
                color="secondary"
                type="submit"
                onClick={() => setActionType(SaveDeskOffer2FormAction.SAVE)}
              />,
              <PublishButton key="publish" onClick={setActionType} hasAdminAccess={hasAdminAccess} />,
            ]}
            onSubmit={debouncePromise(onCreate)}
            providerId={providerId}
            viewModel={defaultOfferFormData({
              cancellationReasonsNotAttachedToAnOffer: formData.availableCancellationReasons,
            })}
          />
        ))}
    </LoadingPage>
  );
};

interface ISaveDeskDefaults {
  id?: string;
  cancellationReasons?: CancellationReason[];
  cancellationReasonsNotAttachedToAnOffer?: CancellationReason[];
  name?: TextByLanguage;
  description?: TextByLanguage;
  disclaimer?: TextByLanguage;
}

function defaultOfferFormData(
  {
    id = undefined,
    cancellationReasons = [],
    cancellationReasonsNotAttachedToAnOffer = [],
    name = new Map<Locale, string>(),
    description = new Map<Locale, string>(),
    disclaimer = undefined,
  }: ISaveDeskDefaults = {} as ISaveDeskDefaults
): ISaveDeskOffer2FormViewModel {
  return {
    id,
    cancellationReasons,
    cancellationReasonsNotAttachedToAnOffer,
    name,
    description,
    disclaimer,
    offerState: SaveDeskOffer2State.Draft,
  };
}
