import type { AcceptationRequirement, RejectedReason } from '@provider-portal/models/order';
import type { IInfoPaper } from '@provider-portal/libs/uiFramework/components/panels/InfoPaper/config';
import type { ICategoryName } from '@provider-portal/PartnerSupplier/models';

export interface RejectionReason {
  name: RejectedReason;
  bindingPeriodEndsAt?: string;
  messageFromSupplier?: string;
}

export enum SwitchOrderState {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export interface ISwitchOrderState {
  name: SwitchOrderState;
  reason?: RejectionReason;
  portingDate?: string;
}

export enum SwitchOrderDetailsType {
  SwedenMobilePartnerSwitchOrderDetails = 'SwedenMobilePartnerSwitchOrderDetails',
  SwedenElectricityPartnerSwitchOrderDetails = 'SwedenElectricityPartnerSwitchOrderDetails',
  FinlandElectricityPartnerSwitchOrderDetails = 'FinlandElectricityPartnerSwitchOrderDetails',
}

export enum OrderType {
  MobileSwitch = 'MobileSwitch',
  MobileIndependent = 'MobileIndependent',
  MobileChangeDataPlan = 'MobileChangeDataPlan',
  BroadbandSwitch = 'BroadbandSwitch',
  BroadbandIndependent = 'BroadbandIndependent',
  ElectricitySwitch = 'ElectricitySwitch',
  GasSwitch = 'GasSwitch',
  ElectricityAndGasSwitch = 'ElectricityAndGasSwitch',
  MobileHardwareOrderToSameSupplier = 'MobileHardwareOrderToSameSupplier',
  FitnessIndependent = 'FitnessIndependent',
  FitnessSwitch = 'FitnessSwitch',
  CharityIndependent = 'CharityIndependent',
  GameIndependent = 'GameIndependent',
  MembershipIndependent = 'MembershipIndependent',
  GroceryBagIndependent = 'GroceryBagIndependent',
  MobileAppIndependent = 'MobileAppIndependent',
  NewsIndependent = 'NewsIndependent',
  SubscriptionBoxIndependent = 'SubscriptionBoxIndependent',
  TvIndependent = 'TvIndependent',
  StreamingMediaIndependent = 'StreamingMediaIndependent',
}

export interface ISwitchOrder {
  id: string;
  orderType: OrderType;
  createdAt: Date;
  sentToSupplierAt?: Date;
  state: ISwitchOrderState;
  categoryName: ICategoryName;
  personalIdentifier: string;
  productName: string;
  assignee?: string;
}

export enum ResponseType {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export type ISwitchOrderOutcome = AcceptedOrderOutcome | RejectedOrderOutcome;

export interface AcceptedOrderOutcome {
  responseType: ResponseType.Accepted;
  portingDate?: string;
}

export interface RejectedOrderOutcome {
  responseType: ResponseType.Rejected;
  rejectionReason: RejectedReason;
  reasonMessage?: string;
  bindingPeriod?: Date;
}

export interface OrderExportResult {
  fileContent: any;
  fileName: string;
  contentType: string;
}

export interface IAdminViewModel {
  name: string;
}

export interface ISwitchOrderDetails {
  firstColumnPapers: IInfoPaper[];
  secondColumnPapers: IInfoPaper[];
  rejectionReasons: RejectedReason[];
  acceptationRequirements: AcceptationRequirement[];
  state: ISwitchOrderState;
  orderType: OrderType;
  assignee?: string;
}
