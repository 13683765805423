import { LocalizedMessage } from '@provider-portal/internationalization';
import type { Market } from '@provider-portal/app-context/constants';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import * as React from 'react';
import type { IProviderCategoryFormData, IProviderFormData, ISupplierService } from '../../models';
import { ProviderFormFieldNames } from '../config';
import { availableCategories } from './available-categories';
import { CategorySubForm } from './CategorySubForm';
import { SelectCategoryDialog } from './SelectCategoryDialog';
import styles from './styles.scss';
import type { CategoryName } from '@provider-portal/constants/categories';

interface ICategoriesPaperProps {
  market: Market;
  supplierServices: Map<string, ISupplierService[]>;
  orderServices: string[];
  formData: IProviderFormData;
}

export const CategoriesPaper: React.FunctionComponent<ICategoriesPaperProps> = ({
  formData,
  market,
  supplierServices,
  orderServices,
}) => {
  const [categories, setCategories] = React.useState<Map<string, IProviderCategoryFormData>>(
    initialMapOfCategories(formData)
  );
  const existingCategoryNames = Array.from(categories.values()).map((c) => c.categoryName);
  const addNewCategories = (categoryNames: CategoryName[]) => {
    const copyOfMap = new Map(categories);
    categoryNames.forEach((categoryName) => {
      copyOfMap.set(categoryName, { categoryName });
    });
    setCategories(copyOfMap);
  };

  const upsertCategory = (categoryName: string, categoryFormData: IProviderCategoryFormData) => {
    const copyOfMap = new Map(categories);
    copyOfMap.set(categoryName, categoryFormData);
    setCategories(copyOfMap);
  };

  const removeCategory = (categoryName: string) => {
    const copyOfMap = new Map(categories);
    copyOfMap.delete(categoryName);
    setCategories(copyOfMap);
  };

  return (
    <>
      <SelectCategoryDialog
        categoryOptions={availableCategories(market)}
        previouslyAddedCategoryNames={existingCategoryNames}
        onAddCategories={addNewCategories}
      />
      {Array.from(categories.values()).map((category, index) => {
        return (
          <Paper className={styles.paper} key={category.categoryName}>
            <CategorySubForm
              index={index}
              categoryName={category.categoryName}
              categoryFormData={category}
              onCategoryChanged={upsertCategory}
              market={market}
              supplierServices={supplierServices}
              orderServices={orderServices}
            />
            <div className={styles.button}>
              <Button
                label={<LocalizedMessage id="removeCategoryButtonText" />}
                onClick={() => removeCategory(category.categoryName)}
                color="negative"
              />
            </div>
          </Paper>
        );
      })}
    </>
  );
};

function initialMapOfCategories(formData: IProviderFormData): Map<string, IProviderCategoryFormData> {
  return new Map(
    (formData[ProviderFormFieldNames.CATEGORIES] || []).map((category: IProviderCategoryFormData) => [
      category.categoryName,
      category,
    ])
  );
}
