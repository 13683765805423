import makeStyles from '@material-ui/core/styles/makeStyles';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import React from 'react';

interface IHeaderProps {
  title: React.ReactNode;
  description: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

export const Header: React.FunctionComponent<IHeaderProps> = ({ title, description }) => {
  const classes = useStyles();

  return (
    <header>
      <div className={classes.marginBottom}>
        <Headline1>{title}</Headline1>
      </div>
      <Body>{description}</Body>
    </header>
  );
};
