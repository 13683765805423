import { LocalizedMessage, LocalizedMessageWithCurrency } from '@provider-portal/internationalization';
import { FieldType } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import type { PageComponent } from '@provider-portal/libs/uiFramework/components/layouts/config';
import { PageComponentType } from '@provider-portal/libs/uiFramework/components/layouts/config';
import React from 'react';
import type { ISaveDeskState } from '../models';
import { renderSaveDeskState } from '../SaveDeskListOrdersPage/config';
import { SaveDeskActions } from './components/SaveDeskResponseCardOld/actions';
import type { ISaveDeskResponseCardOld } from './components/SaveDeskResponseCardOld/config';
import { FieldNames } from './config';

interface IResponseProps {
  userName: string;
  currency: string;
}

//tslint:disable:max-func-body-length
const getResponseCardConfig = ({ userName, currency }: IResponseProps): ISaveDeskResponseCardOld => ({
  type: PageComponentType.SAVEDESK_RESPONSE_CARD_OLD,
  column: 1,
  userName,
  currentDetailsSection: {
    caption: <LocalizedMessage id="saveDeskCurrentDetailsSectionCaption" values={{ userName }} />,
    columns: 3,
    components: [
      {
        type: FieldType.Text,
        name: FieldNames.CURRENT_PRODUCT_NAME,
        label: <LocalizedMessage id="saveDeskCurrentProductName" />,
      },
      {
        type: FieldType.Number,
        numberType: NumberType.PositiveFloat,
        name: FieldNames.CURRENT_PRICE,
        label: <LocalizedMessageWithCurrency id="saveDeskCurrentPrice" currency={currency} />,
      },
      {
        type: FieldType.Date,
        name: FieldNames.CONTRACT_BINDING_TIME,
        label: <LocalizedMessage id="saveDeskContractBindingTime" />,
      },
    ],
  },
  actionsSection: {
    caption: <LocalizedMessage id="saveDeskActionsSectionCaption" />,
    columns: 3,
    components: [
      {
        type: FieldType.Radio,
        name: 'action',
        label: <LocalizedMessage id="saveDeskSendOfferLabel" />,
        value: SaveDeskActions.SEND_OFFER,
      },
      {
        type: FieldType.Radio,
        name: 'action',
        label: <LocalizedMessage id="saveDeskCallLabel" />,
        value: SaveDeskActions.CALL,
      },
      {
        type: FieldType.Radio,
        name: 'action',
        label: <LocalizedMessage id="saveDeskNoOfferLabel" values={{ userName }} />,
        value: SaveDeskActions.NO_OFFER,
      },
    ],
  },
  offerSection: {
    columns: 3,
    caption: <LocalizedMessage id="saveDeskOfferSectionCaption" values={{ userName }} />,
    components: [
      {
        type: FieldType.Text,
        name: FieldNames.NEW_PRODUCT_NAME,
        required: true,
        label: <LocalizedMessage id="saveDeskNewProductName" />,
      },
      {
        type: FieldType.Number,
        numberType: NumberType.PositiveFloat,
        name: FieldNames.NEW_PRICE,
        required: true,
        label: <LocalizedMessageWithCurrency id="saveDeskNewPrice" currency={currency} />,
      },
      {
        type: FieldType.Number,
        numberType: NumberType.IntegerZeroOrGreater,
        name: FieldNames.NEW_BINDING_TIME,
        required: true,
        helperText: <LocalizedMessage id="zeroBindingTime" />,
        label: <LocalizedMessage id="saveDeskNewBindingTime" />,
      },
    ],
  },
  campaignSection: {
    columns: 3,
    caption: <LocalizedMessage id="saveDeskCampaignSectionCaption" values={{ userName }} />,
    components: [
      {
        type: FieldType.Text,
        name: FieldNames.CAMPAIGN_NAME,
        label: <LocalizedMessage id="saveDeskCampaignName" />,
      },
      {
        type: FieldType.Number,
        numberType: NumberType.PositiveFloat,
        name: FieldNames.PRICE_WITH_CAMPAIGN_APPLIED,
        label: <LocalizedMessage id="saveDeskPriceWithCampaignApplied" />,
      },
      {
        type: FieldType.Number,
        numberType: NumberType.PositiveInteger,
        name: FieldNames.CAMPAIGN_DURATION,
        label: <LocalizedMessage id="saveDeskCampaignDuration" />,
      },
    ],
  },
  anotherImprovementSection: {
    columns: 1,
    caption: <LocalizedMessage id="saveDeskAnotherImprovementSectionCaption" />,
    components: [
      {
        type: FieldType.Text,
        name: FieldNames.ANOTHER_IMPROVEMENT,
        label: <LocalizedMessage id="saveDeskAnotherImprovement" values={{ userName }} />,
        multiline: true,
      },
    ],
  },
});

export interface IGetComponentsProps {
  providerId: string;
  orderId: string;
  customerId: string;
  userName: string;
  createdAt: string;
  category: string;
  pains: (string | React.ReactNode)[];
  comment?: string;
  currency: string;
  email: string;
  phone: string;
  address: string;
  assignee?: string;
  state: ISaveDeskState;
}

export const getComponentsOld = ({
  providerId,
  orderId,
  customerId,
  userName,
  createdAt,
  category,
  pains,
  comment,
  currency,
  email,
  phone,
  address,
  assignee,
  state,
}: IGetComponentsProps): PageComponent[] => [
  {
    type: PageComponentType.SAVEDESK_REQUEST_CARD,
    column: 1,
    userName,
    orderDate: createdAt,
    category,
    pains,
    comment,
  },
  getResponseCardConfig({ userName, currency }),
  {
    type: PageComponentType.ORDER_STATUS_CARD,
    column: 2,
    providerId,
    orderId,
    assignee,
    orderState: renderSaveDeskState(state),
  },
  {
    type: PageComponentType.DETAILS_CARD,
    column: 2,
    caption: <LocalizedMessage id="saveDeskOrderPageCustomerDetailsTitle" />,
    details: [
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsCustomerIdLabel" />,
        value: customerId,
      },
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsNameLabel" />,
        value: userName,
      },
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsEmailLabel" />,
        value: email,
      },
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsPhoneLabel" />,
        value: phone,
      },
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsAddressLabel" />,
        value: address,
      },
    ],
  },
];
