import type { FactoryRequest, IField } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { isField } from '@provider-portal/libs/uiFramework/components/fields/configs/type-guards';
import type { WidgetType } from '@provider-portal/libs/uiFramework/components/widgets/config';
import { uiFramework } from '../init';

export interface IEnrichProps<T extends { [key: string]: any }> {
  providerId: string;
  categoryName: string;
  formData: T;
  config: FactoryRequest;
}

export type EnrichWithFormData = <T extends { [key: string]: any }>(props: IEnrichProps<T>) => FactoryRequest;

export type EnrichMap = [WidgetType, EnrichWithFormData];

const enrichField = <T extends { [key: string]: any }>(formData: T, config: IField) => {
  return {
    ...config,
    defaultValue: (formData[config.name] != null && formData[config.name]) || config.defaultValue,
  };
};

export function enrichRequestWithFormData<T extends { [key: string]: any }>(
  providerId: string,
  categoryName: string,
  formData: T
): (config: FactoryRequest) => FactoryRequest {
  return (config: FactoryRequest) => {
    if (isField(config)) return enrichField(formData, config);

    const enrichFunc = uiFramework.forms.enrichFuncs.get(config.type);

    if (!enrichFunc) throw new Error('Config is neither a field or a widget');

    return enrichFunc({ providerId, categoryName, formData, config });
  };
}
