export enum PauseMethodType {
  PauseDuration = 'PauseDuration',
}

export interface PauseDuration {
  type: PauseMethodType.PauseDuration;
  amount?: number;
  unit: PauseDurationUnit;
}

export enum PauseDurationUnit {
  Month = 'Month',
  Week = 'Week',
}

// ToDo: Update this to be a union when we support pause methods other than PauseDuration
export type PauseMethod = PauseDuration;

export enum ResumeMethod {
  Immediate = 'Immediate',
}

export interface PauseSettings {
  pauseMethods: PauseMethod[];
  resumeMethods: ResumeMethod[];
}
