// See https://minnatechnologies.atlassian.net/wiki/spaces/DEV/pages/158007323/Best+practices+for+event+naming+in+Mixpanel
// for best practices of event naming in Mixpanel

export const STAGE_STARTED = 'Started';
export const STAGE_FINISHED = 'Finished';

export const OUTCOME_SUCCESS = 'Success';
export const OUTCOME_FAILED = 'Failed';

//Also known as vertical, i.e. what type of service did we try to improve/switch/cancel
export const CATEGORY_MOBILE = 'Mobile';
export const CATEGORY_ENERGY = 'Energy';
export const CATEGORY_ELECTRICITY = 'Electricity';
export const CATEGORY_BROADBAND = 'Broadband';
export const CATEGORY_CHARITY = 'Charity';
export const CATEGORY_FITNESS = 'Fitness';
export const CATEGORY_PRIVATE_LOAN = '1000';
export const CATEGORY_SAVINGS = '5000';
export const TYPE_E_WALLET = '5100';
export const TYPE_ISK_ACCOUNT = '5300';
export const TYPE_SAVINGS_ACCOUNT = '5200';

export const FEATURE_OVERVIEW = 'Overview';
export const FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE = 'Unresolved subscription guide';
export const FEATURE_SWITCH = 'Switch';
export const FEATURE_CANCEL = 'Cancellation';
export const FEATURE_INDEPENDENT_ORDER = 'Independent';
export const FEATURE_DISCOVER = 'Discover';
export const FEATURE_SETTINGS = 'Settings';
export const FEATURE_ONBOARDING = 'Onboarding';
export const FEATURE_ONBOARDING_ACTION_DRAWER = 'Onboarding Action Drawer';
export enum EventNames {
  CSAT_SCORE = 'CSAT Score',
  CSAT_COMMENT = 'CSAT Comment',
}
