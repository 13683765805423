import React from 'react';
import type { EllipsisProps } from '../shared/ellipsis';
import { getEllipsisStyles } from '../shared/ellipsis';
import type { Omit, TypographyProps } from '../Typography';
import { Typography } from '../Typography';

/**
 * @deprecated should use Body instead, from @minna-ui/components/Typography/Body
 */
export const Body1: React.FunctionComponent<Body1Props> = ({ style, ellipsis, maxWidth, ...otherProps }) => {
  /**
   * 1 rem corresponds to 16px in Invision
   */
  const ellipsisStyles = getEllipsisStyles(maxWidth, ellipsis);
  const baseStyle = {
    fontSize: '1rem',
    letterSpacing: '0.15px',
    lineHeight: '1.375em',
    ...ellipsisStyles,
    ...style,
  };

  return <Typography {...otherProps} variant="body1" style={baseStyle} />;
};

type Body1Props = Omit<TypographyProps, 'variant'> & EllipsisProps;
