import { LocalizedMessage } from '@provider-portal/internationalization';
import { PageComponentType } from '@provider-portal/libs/uiFramework/components/layouts/config';
import { TwoColumnLayout } from '@provider-portal/libs/uiFramework/components/layouts/TwoColumnLayout';
import { OrderPage } from '@provider-portal/libs/uiFramework/components/pages/OrderPage';
import type { IInfoPapers } from '@provider-portal/libs/uiFramework/components/panels/InfoPaper/config';
import type { IOrderStatusCard } from '@provider-portal/libs/uiFramework/components/panels/OrderStatusCard/config';
import PropTypes from 'prop-types';
import * as React from 'react';
import { exportOrder } from '../api';
import type { ISwitchOrderDetails, ISwitchOrderOutcome } from '../models';
import { getOrderType, renderOrderState } from '../utils';
import type { ISwitchSupplierResponseCard } from './SwitchSupplierResponseCard/config';
import { AcceptationRequirementType } from '@provider-portal/models/order';
import { PortingDateSection } from '@provider-portal/orders/switch/portingDate/PortingDateSection';
import type { Market } from '@provider-portal/app-context/constants';

interface IProps {
  providerId: string;
  orderId: string;
  switchOrderDetails: ISwitchOrderDetails;
  onUpdateOrderOutcome(outcome: ISwitchOrderOutcome): void;
  updateInProgress: boolean;
  market: Market;
}

export const SwitchOrderDetailsComponent: React.FunctionComponent<IProps> = (props, { localizeMessage }) => {
  const firstColumnPapers: IInfoPapers = {
    column: 1,
    type: PageComponentType.INFO_PAPERS,
    papers: props.switchOrderDetails.firstColumnPapers,
  };
  const switchSupplierResponseCard: ISwitchSupplierResponseCard = {
    column: 1,
    type: PageComponentType.SWITCH_SUPPLIER_RESPONSE_CARD,
    rejectionReasons: props.switchOrderDetails.rejectionReasons,
    acceptationComponents: props.switchOrderDetails.acceptationRequirements
      .filter((requirement) => requirement.type === AcceptationRequirementType.PortingDate)
      .map((requirement) => {
        return (
          <PortingDateSection
            key={requirement.type}
            portingDate={requirement.portingDate.date}
            oldSupplierName={requirement.currentProviderName}
            market={props.market}
          />
        );
      }),
    updateInProgress: props.updateInProgress,
    orderState: props.switchOrderDetails.state,
    onUpdateOrderOutcome: props.onUpdateOrderOutcome,
  };
  const secondColumnPapers: IInfoPapers = {
    column: 2,
    type: PageComponentType.INFO_PAPERS,
    papers: props.switchOrderDetails.secondColumnPapers,
  };
  const orderStatusCard: IOrderStatusCard = {
    column: 2,
    type: PageComponentType.ORDER_STATUS_CARD,
    providerId: props.providerId,
    orderId: props.orderId,
    assignee: props.switchOrderDetails.assignee,
    exportOrderUrl: exportOrder(props.providerId, props.orderId),
    orderState: renderOrderState(props.switchOrderDetails.state),
  };

  return (
    <OrderPage
      appBarText={<LocalizedMessage id="orderDetailsAppBarTitleV2" />}
      headerText={
        <>
          <LocalizedMessage
            id="orderDetailsPageHeaderTextV2"
            values={{ orderType: localizeMessage(getOrderType(props.switchOrderDetails.orderType)) }}
          />{' '}
          {props.orderId}
        </>
      }
      leftButtonText={<LocalizedMessage id="previousOrderButtonText" />}
      rightButtonText={<LocalizedMessage id="nextOrderButtonText" />}
    >
      <TwoColumnLayout
        components={[firstColumnPapers, switchSupplierResponseCard, orderStatusCard, secondColumnPapers]}
      />
    </OrderPage>
  );
};

SwitchOrderDetailsComponent.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
