import type { IInputField } from '../configs';
import { fieldConstructor, FieldType } from '../configs';

export interface IImage extends IInputField {
  type: FieldType.Image;
  src: string;
  alt: string;
}

export const Image = fieldConstructor<IImage>(FieldType.Image);
