import type { AppType, Market } from '@provider-portal/app-context/constants';
import { getItemFromLocalStorage } from '@provider-portal/utils/local-storage';
import axios from 'axios';
import { Locale } from '@provider-portal/internationalization/locale';

// TODO: Maybe move it to developerTools namespace? Like in developer-tools.ts!
function maybeDebugPlatformLocale(): string {
  return getItemFromLocalStorage('debugPlatformLocale') || '';
}

export interface Messages {
  [key: string]: string;
}

export interface MessagesAndLocale {
  messages: Messages;
  locale: Locale;
}

export async function forceLoadMessagesAndLocale(locale: Locale, app: AppType): Promise<MessagesAndLocale> {
  const url = `/api/v1/internationalization/messages/${locale}@${app}`;

  return axios.get(url).then((response) => response.data);
}

async function loadLocalMessagesEnglish(): Promise<MessagesAndLocale> {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const messages: Messages = require('./localized-messages.ts').localizedMessagesEnglish();
  const locale = Locale.enGB;

  return Promise.resolve({ messages, locale });
}

export async function loadMessagesAndLocale(): Promise<MessagesAndLocale> {
  // @ts-ignore
  if (window.Cypress) {
    const url = `/api/v1/internationalization/messages/`;
    const localLocaleAndMessages = await loadLocalMessagesEnglish();
    try {
      // Messages endpoint was mocked
      const response = await axios.get(url);
      const localeAndMessages = response.data;
      if (Object.keys(localeAndMessages.messages).length === 0) {
        // Cypress mocking doesn't allow payloads >80kb, so we have to supply local messages manually here
        localeAndMessages.messages = localLocaleAndMessages.messages;
      }

      return localeAndMessages;
    } catch (e) {
      // If messages endpoint was not mocked, load local messages
      return localLocaleAndMessages;
    }
  } else if (process.env.NODE_ENV === 'development' && maybeDebugPlatformLocale() === '') {
    return loadLocalMessagesEnglish();
  } else {
    const url = `/api/v1/internationalization/messages/${encodeURIComponent(maybeDebugPlatformLocale())}`;

    return axios.get(url).then((response) => response.data);
  }
}

export async function loadMessagesAndLocaleProviderPortal(locale: Locale, market: Market): Promise<MessagesAndLocale> {
  if (process.env.NODE_ENV === 'development' && locale === Locale.enGB) {
    return loadLocalMessagesEnglish();
  } else {
    const url = `/api/frontend/v1/internationalization/messages/${encodeURIComponent(locale)}?market=${market}`;

    return axios.get(url).then((response) => response.data);
  }
}
