import classNames from 'classnames';
import { Paper } from 'material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';

interface BasePaperProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  mobileHeight?: 'full-with-header' | 'full-with-no-header';
  name?: string;
}

export const BasePaper: React.FunctionComponent<BasePaperProps> = ({
  children,
  className,
  style,
  mobileHeight,
  name,
}) => {
  const classes = classNames(
    className,
    styles.paper,
    { [styles['mobile-full-height-with-header']]: mobileHeight === 'full-with-header' },
    { [styles['mobile-full-height-with-no-header']]: mobileHeight === 'full-with-no-header' }
  );

  return (
    <Paper style={style} className={classes} data-test={name}>
      {children}
    </Paper>
  );
};

BasePaper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  mobileHeight: PropTypes.oneOf(['full-with-header', 'full-with-no-header']) as any,
  name: PropTypes.string,
};

// eslint-disable-next-line import/no-default-export
export default BasePaper;
