import { withLocalization } from '@provider-portal/internationalization';
import { selectorHasInternalAdminAccess } from '@provider-portal/login/duck';
import { AddPlanPage } from '@provider-portal/features/changePlan/AddPlanPage/component';
import { Sentry } from '@provider-portal/sentry';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { createChangePlanOffer, approveChangePlanOffer } from '../api';
import type { IChangePlan2FormDataInner } from '../models';
import { ChangePlanOffer2FormAction } from '../models';
import { convertFormDataToApiREquestBody } from '../utils';
import { selectorMarketFromMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';
import type { Market } from '@provider-portal/app-context/constants';
import { getDefaultCurrency } from '@provider-portal/app-context/state';

interface IAddChangePlanOfferProps {
  providerId: string;
}

const AddChangePlanPlanInner: React.FunctionComponent<IAddChangePlanOfferProps> = ({ providerId }) => {
  const history = useHistory();
  const [actionType, setActionType] = React.useState<ChangePlanOffer2FormAction>(ChangePlanOffer2FormAction.SAVE);
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Success);
  const market: Market | undefined = useSelector(selectorMarketFromMaybePartnerSupplier);
  const currency = getDefaultCurrency(market);

  const hasAdminAccess = useSelector(selectorHasInternalAdminAccess);

  const onCreate: (viewModel: IChangePlan2FormDataInner) => Promise<void> = async (
    viewModel: IChangePlan2FormDataInner
  ) => {
    const formData = convertFormDataToApiREquestBody(viewModel, actionType);

    try {
      setLoadingState(PortalLoadingState.Loading);
      const createdChangePlanOffer = await createChangePlanOffer(providerId, formData);
      setLoadingState(PortalLoadingState.Success);

      if (actionType === ChangePlanOffer2FormAction.SAVE_AND_REQUEST_APPROVAL && hasAdminAccess) {
        await approveChangePlanOffer(providerId, createdChangePlanOffer.id);
      }
      const editChangePlanOfferUrl = URI(history.location.pathname)
        .segment(-1, createdChangePlanOffer.id || '')
        .toString();
      history.replace(editChangePlanOfferUrl);
    } catch (error) {
      setLoadingState(PortalLoadingState.Failure);
      Sentry.captureExceptionWithMessage(error, 'Failed to create changePlanOffer');
    }
  };

  return (
    <AddPlanPage
      providerId={providerId}
      onGoBack={() => history.goBack()}
      setActionType={setActionType}
      loadingState={loadingState}
      hasAdminAccess={hasAdminAccess}
      onCreate={onCreate}
      currency={currency}
    />
  );
};

export const AddChangePlanPlan = withLocalization('ChangePlan')(AddChangePlanPlanInner);
