import { QuestionIcon } from '@provider-portal/assets/icons/QuestionIcon';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import ExportIcon from '@provider-portal/assets/icons/export.svg';
import { DividerSection } from '@provider-portal/libs/uiFramework/components/dividers/DividerSection';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import * as React from 'react';
import styles from './styles.scss';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { assignUserToOrder, unassignUserToOrder } from '@provider-portal/orders/switch/api';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';
import { colors } from '@provider-portal/styles/colors';
import { Tooltip } from '@provider-portal/libs/uiFramework/components/tooltip';

export interface IOrderStatusCardProps {
  exportOrderUrl?: string;
  orderState: React.ReactNode;
  providerId: string;
  orderId: string;
  assignee?: string;
}

const OrderStatusCardInner: React.FunctionComponent<IOrderStatusCardProps> = ({
  orderState,
  exportOrderUrl,
  providerId,
  orderId,
  assignee,
}) => {
  const [assigneeDisplayName, setAssigneeDisplayName] = React.useState<string | undefined>(assignee);

  const onAssign = async () => {
    if (assigneeDisplayName) {
      await unassignUserToOrder(providerId, orderId);
      setAssigneeDisplayName(undefined);
    } else {
      const adminViewModel = await assignUserToOrder(providerId, orderId);
      setAssigneeDisplayName(adminViewModel.name);
    }
  };

  return (
    <Paper title={<LocalizedMessage id="orderStatusCardTitle" />}>
      <div className={styles.top}>{orderState}</div>
      <DividerSection />
      <div style={{ padding: '16px' }}>
        <div style={{ paddingBottom: '16px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Overline style={{ marginRight: '8px' }}>
              <LocalizedMessage id="assignee" />
            </Overline>
            <Tooltip
              tooltipTitle={<LocalizedMessage id="assignee" />}
              tooltipText={<LocalizedMessage id="assigneeTooltipText" />}
            />
          </div>
          {assigneeDisplayName && <Body1 style={{ marginTop: '8px' }}>{assigneeDisplayName}</Body1>}
          {!assigneeDisplayName && (
            <Body1 style={{ marginTop: '8px', fontStyle: 'italic', color: colors.black80 }}>
              <LocalizedMessage id="unassigned" />
            </Body1>
          )}
        </div>
        <Button
          onClick={onAssign}
          variant="outlined"
          color="secondary"
          label={assigneeDisplayName ? <LocalizedMessage id="unassign" /> : <LocalizedMessage id="assignToMe" />}
        />
      </div>
      {exportOrderUrl && (
        <>
          <DividerSection />
          <div className={styles.bottom}>
            <a href={exportOrderUrl} className={styles.exportLink}>
              <img src={ExportIcon} alt="export icon" />
              <Body textColor="primary">
                <LocalizedMessage id="exportButtonText" />
              </Body>
            </a>
            <Tooltip
              tooltipTitle={<LocalizedMessage id="downloadOrder" />}
              tooltipText={<LocalizedMessage id="downloadOrderTooltipText" />}
            >
              <QuestionIcon />
            </Tooltip>
          </div>
          <Body variant="italic" colorVariant="lighter" style={{ padding: '0 14px 14px' }}>
            <LocalizedMessage id="orderNote" />
          </Body>
        </>
      )}
    </Paper>
  );
};

export const OrderStatusCard = withLocalization('SupplierPortal/orderStatusCard')(OrderStatusCardInner);
