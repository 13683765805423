import type { IInputField } from '../configs';
import { fieldConstructor, FieldType } from '../configs';

export enum HiddenInputDataFormat {
  PLAIN = 'plain',
  JSON = 'json',
  COST_WITH_CURRENCY = 'costWithCurrency',
}

export interface IHiddenInput extends IInputField {
  type: FieldType.Hidden;
  defaultValue?: string | Record<string, unknown> | any[];
  dataFormat?: HiddenInputDataFormat;
}
export const HiddenInput = fieldConstructor<IHiddenInput>(FieldType.Hidden);
