import { LocalizedMessage } from '@provider-portal/internationalization';
import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import type { ITabConfig } from '@provider-portal/libs/uiFramework/components/Tabs/config';
import * as React from 'react';

export enum ProductsListTab {
  APPROVED = 'approved',
  DRAFTS = 'drafts',
}

export const productsListTableConfig: ITableConfig = {
  cells: [
    {
      key: 'name',
      label: <LocalizedMessage id="productName" />,
    },
    {
      key: 'externalProductId',
      label: <LocalizedMessage id="productId" />,
    },
    {
      key: 'state',
      label: <LocalizedMessage id="state" />,
    },
  ],
  defaultSortColumn: 'name',
};

export const getTabsConfig = (activeProductsCount: number, savedDraftsCount: number): ITabConfig<ProductsListTab>[] => [
  {
    key: ProductsListTab.APPROVED,
    label: <LocalizedMessage id="activeProducts" values={{ activeProductsCount }} />,
  },
  {
    key: ProductsListTab.DRAFTS,
    label: <LocalizedMessage id="savedDrafts" values={{ savedDraftsCount }} />,
  },
];
