import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Headline1 } from '../../text/Headline1';
import styles from './styles.scss';

interface IAppBarOverviewPageProps {
  rightElement?: React.ReactNode;
  children?: React.ReactNode;
}

export const OverviewPageAppBar: React.FunctionComponent<IAppBarOverviewPageProps> = (
  { rightElement, children },
  { adminDrawerToggle }
) => {
  return (
    <div className={styles.titleBar}>
      {/* @ts-ignore */}
      <Hidden lgUp implementation="css">
        <div className={styles.headerWithMenuToggle}>
          <IconButton onClick={adminDrawerToggle} className={styles.menuIcon}>
            <MenuIcon />
          </IconButton>
          <Headline1 ellipsis maxWidth="800px">
            {children}
          </Headline1>
        </div>
      </Hidden>
      {/* @ts-ignore */}
      <Hidden mdDown implementation="css">
        <Headline1 ellipsis maxWidth="800px">
          {children}
        </Headline1>
      </Hidden>
      {rightElement}
    </div>
  );
};

OverviewPageAppBar.contextTypes = {
  adminDrawerToggle: PropTypes.func,
};
