import Grid from '@material-ui/core/Grid';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Subtitle2 } from '@provider-portal/libs/uiFramework/components/text/Subtitle2';
import * as React from 'react';
import type { IFieldArray, IFieldArrayItemConfig, IFieldArrayProps } from './config';
import { FieldArrayItem } from './FieldArrayItem';
import type { IFieldArrayItem } from './models';
import { fieldArrayComponent } from './resolve-config';
import {
  addFieldArrayItem,
  createMapFromInitialItems,
  fieldsWithIndexedNames,
  newItem,
  removeFieldArrayItem,
} from './utils';

export function title(index: number, itemTitle?: React.ReactNode): React.ReactNode | null {
  return (
    (itemTitle && (
      <Subtitle2>
        {itemTitle} {index + 1}
      </Subtitle2>
    )) ||
    null
  );
}

export interface IFieldArrayItemComponentProps extends Omit<IFieldArrayItemConfig, 'type'> {
  onDelete?(): void;
  item: IFieldArrayItem;
}

export const FieldArray: React.FunctionComponent<IFieldArrayProps> = (props) => {
  const initialItems = defaultToOneRowIfRequired(props.items, props.config);
  const [fieldArrayItems, setFieldArrayItems] = React.useState(createMapFromInitialItems(initialItems));
  const ItemComponent = fieldArrayComponent(props.config.itemComponent.type);

  function onDelete(key: string) {
    setFieldArrayItems((currentFieldArrayItems) => removeFieldArrayItem(currentFieldArrayItems, key));
  }

  function onAdd() {
    setFieldArrayItems(addFieldArrayItem(fieldArrayItems, props.config));
  }

  const allowDeletion = !props.config.required || fieldArrayItems.size > 1;

  return (
    <>
      {props.config.label && <Body style={{ marginBottom: '16px' }}>{props.config.label}</Body>}
      <Grid container spacing={2}>
        {Array.from(fieldArrayItems.entries()).map(([key, item], index) => {
          return (
            <FieldArrayItem
              config={props.config}
              key={key}
              item={fieldsWithIndexedNames(props.config, index, item)}
              title={title(index, props.config.itemComponent.title)}
              ItemComponent={ItemComponent}
              onDelete={allowDeletion ? () => onDelete(key) : undefined}
              columns={props.config.itemComponent.columns}
            />
          );
        })}
        <Grid item xs={12}>
          <Button label={props.config.addItemButtonLabel} variant="outlined" color="secondary" onClick={onAdd} />
        </Grid>
      </Grid>
    </>
  );
};

function defaultToOneRowIfRequired(items: IFieldArrayItem[], config: IFieldArray) {
  if (items.length) {
    return items;
  }
  if (config.required) {
    return [newItem(config)];
  } else {
    return [];
  }
}
