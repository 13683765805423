import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';
import { Subtitle2 } from '../../text/Subtitle2';
import type { ITableConfig, TableColumnSortDirection } from '../config';
import { SortArrowIcon } from './SortArrowIcon';
import styles from './styles.scss';

const StyledTableCell = withStyles({
  root: { borderBottom: 'none' },
})(TableCell);

interface ITableHeaderProps {
  config: ITableConfig;
  columnSorted?: string;
  sortDirection?: TableColumnSortDirection;
  onSortChanged(column: string): void;
  rowsAreClickable: boolean;
}

//TODO: Create Subtitle2 with ellipsis
export const TableHeader: React.FunctionComponent<ITableHeaderProps> = ({
  config,
  columnSorted,
  sortDirection,
  onSortChanged,
  rowsAreClickable,
}) => {
  return (
    <TableHead>
      <TableRow>
        {config.cells.map((cell) => (
          <StyledTableCell key={cell.key}>
            <div className={styles.column} onClick={() => onSortChanged(cell.key)} role="button">
              <Subtitle2>{cell.label}</Subtitle2>
              <SortArrowIcon isActive={cell.key === columnSorted} sortDirection={sortDirection} />
            </div>
          </StyledTableCell>
        ))}
        {/* If rows are clickable we add an extra column for all the rows with an arrow indicating that.
        We need to have equivalent amount of columns in the header therefor we add an empty one here. */}
        {rowsAreClickable && <StyledTableCell />}
      </TableRow>
    </TableHead>
  );
};
