import React from 'react';
import type { EllipsisProps } from '../shared/ellipsis';
import { getEllipsisStyles } from '../shared/ellipsis';
import type { Omit, TypographyProps } from '../Typography';
import { Typography } from '../Typography';

export const Headline5: React.FunctionComponent<Headline5Props> = ({ style, ellipsis, maxWidth, ...otherProps }) => {
  /**
   * 1.25rem corresponds to 20px in Invision
   */
  const ellipsisStyles = getEllipsisStyles(maxWidth, ellipsis);
  const baseStyle = {
    fontSize: '1.25rem',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '1.2em',
    ...ellipsisStyles,
    ...style,
  };

  return <Typography {...otherProps} variant="h5" style={baseStyle} />;
};

type Headline5Props = Omit<TypographyProps, 'variant'> & EllipsisProps;
