import { LocalizedMessage } from '@provider-portal/internationalization';
import * as React from 'react';

export enum CampaignSnackbarState {
  CLOSED = 'closed',
  CAMPAIGN_UPDATED = 'updated',
  CAMPAIGN_UNPUBLISHED = 'unpublished',
}

export function snackbarContent(snackbarState: CampaignSnackbarState) {
  switch (snackbarState) {
    case CampaignSnackbarState.CAMPAIGN_UPDATED:
      return <LocalizedMessage id="successfulSaveMessage" />;
    case CampaignSnackbarState.CAMPAIGN_UNPUBLISHED:
      return <LocalizedMessage id="successfulUnpublishMessage" />;
    default:
      return null;
  }
}
