import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import type { BaseButtonProps } from '@provider-portal/libs/uiFramework/components/deprecated/BaseButtonProps';
import { ButtonText } from '@provider-portal/libs/uiFramework/components/text/ButtonText';
import { lighter, primary } from '@provider-portal/styles/colors-standard';
import * as React from 'react';

const styles = createStyles({
  root: {
    borderRadius: '8px',
    minWidth: '112px',
    minHeight: '36px',
    '&:hover': {
      backgroundColor: primary.main,
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    },
  },
  disabled: {
    backgroundColor: lighter.main,
    '&:disabled': {
      backgroundColor: lighter.main,
    },
  },
});

export type PrimaryButtonStylesProps = PrimaryButtonProps & WithStyles<typeof styles>;

const PrimaryButtonInner: React.FunctionComponent<PrimaryButtonStylesProps> = ({
  disabled,
  loading,
  label,
  classes,
  ...props
}) => {
  return (
    <Button
      {...props}
      variant="contained"
      color="primary"
      classes={{
        root: classes.root,
        disabled: classes.disabled,
        contained: classes.root,
      }}
      disabled={disabled || loading}
    >
      {!loading && (
        <ButtonText colorVariant={disabled ? 'dark' : undefined} textColor={disabled ? undefined : 'alternative'}>
          {label}
        </ButtonText>
      )}
      {loading && <CircularProgress color="primary" size={24} thickness={6} />}
    </Button>
  );
};

interface PrimaryButtonProps extends BaseButtonProps {
  loading?: boolean;
}

export const PrimaryButton = withStyles(styles)(PrimaryButtonInner);
