import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { fetchSwitchOrder } from '../api';
import type { ISwitchOrderDetails } from '../models';

export function useFetchSwitchOrder(
  partnerSupplierId: string,
  orderId: string,
  onSetLoadingState: (loadingState: PortalLoadingState) => void,
  onSetSwitchOrderDetails: (switchOrderDetails: ISwitchOrderDetails) => void
): void {
  React.useEffect(() => {
    fetchSwitchOrder(partnerSupplierId, orderId)
      .then((order) => {
        onSetSwitchOrderDetails(order);
        onSetLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        onSetLoadingState(PortalLoadingState.Failure);
      });
  }, [partnerSupplierId, orderId, onSetLoadingState, onSetSwitchOrderDetails]);
}
