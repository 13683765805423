import { ErrorLoading } from '@provider-portal/fetching/ErrorPage/ErrorLoading';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { LoadingPage as LoadingPageComponent } from './components/LoadingPage';

interface ILoadingPageProps {
  loadingState: PortalLoadingState;
  loadingText?: React.ReactNode;
  children?: React.ReactNode;
}

export const LoadingPage: React.FunctionComponent<ILoadingPageProps> = ({ loadingState, loadingText, children }) => {
  if (loadingState === PortalLoadingState.Loading) {
    return <LoadingPageComponent loadingText={loadingText} />;
  } else if (loadingState === PortalLoadingState.Failure) {
    return <ErrorLoading />;
  } else {
    return <>{children}</>;
  }
};
