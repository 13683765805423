import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { PageConfig } from '@provider-portal/libs/uiFramework/components/layouts/config';
import type { Currency } from '@provider-portal/models/subscription';
import { SaveDeskOfferType } from '@provider-portal/saveDesk/models';
import { getComponentsOld } from '@provider-portal/saveDesk/SaveDeskOrderPage/oldConfig';
import type { ApplicationState } from '@provider-portal/store';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import type { ISaveDeskOrderPageProps } from './component';
import { SaveDeskOrderPage as Component } from './component';
import { getComponents } from './config';

const mapStateToProps = (appState: ApplicationState, { partnerSupplierId, currency }: IContainerProps) => {
  if (!appState.saveDesk.saveDeskOrder) throw new Error('No order found');

  const { customerId, userName, category, pains, email, phone, address, createdAt, assignee, state, outcome } =
    appState.saveDesk.saveDeskOrder;

  if (outcome?.type === SaveDeskOfferType.SAVE_DESK_OFFER_2) {
    const props = {
      providerId: partnerSupplierId,
      orderId: appState.saveDesk.saveDeskOrder.partnerOrderId,
      customerId,
      userName,
      category,
      currency,
      email,
      state,
      phone,
      address,
      assignee,
      createdAt,
      offerTitle: outcome?.offerTitle,
    };

    return {
      config: PageConfig(getComponents(props)),
    };
  } else {
    const oldProps = {
      providerId: partnerSupplierId,
      orderId: appState.saveDesk.saveDeskOrder.partnerOrderId,
      customerId,
      userName,
      category,
      pains: pains.filter((p) => p.name !== 'other').map((p) => <LocalizedMessage key={p.value} id={p.value} />),
      comment: pains.filter((p) => p.name === 'other').map((p) => p.value)[0],
      currency,
      name,
      email,
      state,
      phone,
      address,
      assignee,
      createdAt,
    };

    return {
      config: PageConfig(getComponentsOld(oldProps)),
    };
  }
};

interface IContainerProps {
  partnerSupplierId: string;
  currency: Currency;
}

export const SaveDeskOrderPageContainer = compose<ISaveDeskOrderPageProps, IContainerProps>(
  withLocalization('supplier-portal-save-desk'),
  connect(mapStateToProps)
)(Component);
