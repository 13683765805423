import { LocalizedMessage } from '@provider-portal/internationalization';
import { Checkbox } from '@provider-portal/libs/uiFramework/components/fields/Checkbox/config';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select/config';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import React from 'react';
import type { MarketCategoryMap } from '@provider-portal/libs/uiFramework';
import { createCategoryLayout } from '@provider-portal/libs/uiFramework';
import { Market } from '@provider-portal/app-context/constants';
import { categories } from '@provider-portal/constants/categories';

export enum DenmarkSupplierForFieldNames {
  NETWORK_OPERATOR = 'details.networkOperator',
  E_SIM_AVAILABLE = 'details.eSimAvailable',
}

const denmarkMobileCategorySection: IFieldSectionConfig = {
  columns: 3,
  components: [
    Select({
      name: DenmarkSupplierForFieldNames.NETWORK_OPERATOR,
      label: <LocalizedMessage id="networkOperator" />,
      required: true,
      options: [
        { label: 'TDC', value: 'TDC' },
        { label: 'Telia/Telenor', value: 'Telia/Telenor' },
        { label: 'Tre', value: 'Tre' },
      ],
    }),
    Checkbox({
      name: DenmarkSupplierForFieldNames.E_SIM_AVAILABLE,
      label: <LocalizedMessage id="eSim" />,
    }),
  ],
};

export const denmarkMobileCategoryLayoutMap: MarketCategoryMap = createCategoryLayout(
  categories.mobile,
  Market.Denmark,
  denmarkMobileCategorySection
);
