import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import type { Columns } from '../configs/columns';
import { getGridColumnsWidth } from '../configs/columns';
import type { IFieldArrayItemComponentProps } from './';
import type { IFieldArray } from './config';
import type { IFieldArrayItem } from './models';

export interface IFieldArrayItemProps {
  item: IFieldArrayItem;
  config: IFieldArray;
  title: React.ReactNode;
  ItemComponent: React.ComponentType<IFieldArrayItemComponentProps>;
  onDelete?(): void;
  columns: Columns;
}

export const FieldArrayItem: React.FunctionComponent<IFieldArrayItemProps> = ({
  item,
  config,
  title,
  ItemComponent,
  onDelete,
  columns,
}) => {
  return (
    <Grid item xs={getGridColumnsWidth(config.columns)}>
      <ItemComponent onDelete={onDelete} title={title} item={item} columns={columns} />
    </Grid>
  );
};
