import { PartnerSupplierRoute } from '@provider-portal/PartnerSupplier/route';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UserManagementRoute } from '../UserManagement';
import { DenmarkGridCosts } from '@provider-portal/denmark-grid-costs';

export const AdminRoutes: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/users`} render={(props) => <UserManagementRoute {...props} />} />
      <Route path={`${match.path}/suppliers`} render={(props) => <PartnerSupplierRoute {...props} hasAdminAccess />} />
      <Route path={`${match.path}/denmark-grid-costs`} render={(props) => <DenmarkGridCosts {...props} />} />
      <Route path="/" render={() => <Redirect to={`${match.path}/suppliers`} />} />
    </Switch>
  );
};
