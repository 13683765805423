import { LocalizedMessage } from '@provider-portal/internationalization';
import { CentesimalCostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CentesimalCostInput/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { RadioGroup } from '@provider-portal/libs/uiFramework/components/fields/RadioGroup/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { ElectricityPriceType } from '@provider-portal/products/models';
import React from 'react';
import { campaignPaper, internalPaper, electricityNamingSection, termsPaper } from '../common';
import type { ProductFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createProductFormLayout } from '@provider-portal/libs/uiFramework';
import { Market } from '@provider-portal/app-context/constants';
import { categories } from '@provider-portal/constants/categories';
import { Checkbox } from '@provider-portal/libs/uiFramework/components/fields/Checkbox/config';
import { MeasurementUnit } from '@provider-portal/libs/uiFramework/components/fields/MeasurementUnit';
import { Currency } from '@provider-portal/models/subscription';
import { Locale } from '@provider-portal/internationalization/locale';

enum SpainElectricityFieldNames {
  PRICE_TYPE = 'priceType',
  MONTHLY_COST = 'monthlyCost',
  FORM_TYPE = 'type',
  '2_0A_DAY' = '2_0aDayTariff',
  '2_0DHA_DAY' = '2_0dhaDayTariff',
  '2_0DHA_NIGHT' = '2_0dhaNightTariff',
  '2_1A_DAY' = '2_1aDayTariff',
  '2_1DHA_DAY' = '2_1dhaDayTariff',
  '2_1DHA_NIGHT' = '2_1dhaNightTariff',
  '3_0A_DAY' = '3_0aDayTariff',
  GREEN_ELECTRICITY = 'isGreenElectricity',
}

const currency = Currency.EUR;
const locale = Locale.esES;

const spainElectricityPapers = (hasAdminAccess: boolean): IFormPagePaper[] => [
  {
    sections: [electricityNamingSection, ...spainElectricitySections],
  },
  termsPaper(locale),
  campaignPaper,
  internalPaper(hasAdminAccess, currency, locale),
];

export const spainElectricityLayoutMap: ProductFormLayoutMap = createProductFormLayout(
  categories.electricity,
  Market.Spain,
  spainElectricityPapers
);

const spainElectricitySections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="priceTypeHeader" />,
    columns: 4,
    components: [
      RadioGroup({
        name: SpainElectricityFieldNames.PRICE_TYPE,
        options: [
          { value: ElectricityPriceType.Floating, label: <LocalizedMessage id="floatingPriceLabel" /> },
          { value: ElectricityPriceType.Fixed, label: <LocalizedMessage id="fixedPriceLabel" /> },
        ],
        required: true,
      }),
      HiddenInput({
        name: SpainElectricityFieldNames.FORM_TYPE,
        defaultValue: 'SpainElectricityProductIncomingRequest',
      }),
    ],
  },
  {
    caption: '2.0A',
    columns: 4,
    components: [
      CentesimalCostInput({
        label: <LocalizedMessage id="dayTariffLabel" />,
        name: SpainElectricityFieldNames['2_0A_DAY'],
        required: true,
        unit: MeasurementUnit.KWH,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: '2.0DHA',
    columns: 4,
    components: [
      CentesimalCostInput({
        label: <LocalizedMessage id="dayTariffLabel" />,
        name: SpainElectricityFieldNames['2_0DHA_DAY'],
        required: true,
        unit: MeasurementUnit.KWH,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        label: <LocalizedMessage id="nightTariffLabel" />,
        name: SpainElectricityFieldNames['2_0DHA_NIGHT'],
        required: true,
        unit: MeasurementUnit.KWH,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: '2.1A',
    columns: 4,
    components: [
      CentesimalCostInput({
        label: <LocalizedMessage id="dayTariffLabel" />,
        name: SpainElectricityFieldNames['2_1A_DAY'],
        required: true,
        unit: MeasurementUnit.KWH,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: '2.1DHA',
    columns: 4,
    components: [
      CentesimalCostInput({
        label: <LocalizedMessage id="dayTariffLabel" />,
        name: SpainElectricityFieldNames['2_1DHA_DAY'],
        required: true,
        unit: MeasurementUnit.KWH,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        label: <LocalizedMessage id="nightTariffLabel" />,
        name: SpainElectricityFieldNames['2_1DHA_NIGHT'],
        required: true,
        unit: MeasurementUnit.KWH,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: '3.0A',
    columns: 4,
    components: [
      CentesimalCostInput({
        label: <LocalizedMessage id="dayTariffLabel" />,
        name: SpainElectricityFieldNames['3_0A_DAY'],
        required: true,
        unit: MeasurementUnit.KWH,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="energySource" />,
    columns: 4,
    components: [
      Checkbox({
        label: <LocalizedMessage id="featureGreenElectricityLabel" />,
        name: SpainElectricityFieldNames.GREEN_ELECTRICITY,
      }),
    ],
  },
];
