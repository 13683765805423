import Chip from '@material-ui/core/Chip';
import * as React from 'react';

export type ChipColor = 'aquamarine' | 'information' | 'positive' | 'grey';

function backgroundColor(color: ChipColor) {
  switch (color) {
    case 'aquamarine':
      return '#97E9C6';
    case 'information':
      return '#7FA3FF';
    case 'positive':
      return '#68C958';
    case 'grey':
      return '#818181';
  }
}

interface ITableChipProps {
  label: React.ReactNode;
  color: ChipColor;
}

export const TableChip: React.FunctionComponent<ITableChipProps> = ({ label, color }) => {
  return <Chip label={label} color="secondary" style={{ backgroundColor: backgroundColor(color) }} />;
};
