import { extractSuccess } from '@provider-portal/models/Fetchable';
import { selectorLoggedInUsername } from '@provider-portal/login/duck';
import { selectorFetchablePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';
import type { ApplicationState } from '@provider-portal/store';
import { connect } from 'react-redux';
import type { SupplierVertical } from './component';
import { MenuComponent } from './component';
import type { CategoryName } from '@provider-portal/constants/categories';
import type { MenuModule } from '@provider-portal/libs/uiFramework/components/Menu/models';

interface StateProps {
  supplier?: { logoUrl: string; id: string };
  supplierVerticals: SupplierVertical[];
  loggedInUsername?: string;
}

function mapStateToProps(state: ApplicationState): StateProps {
  const maybePartnerSupplier = extractSuccess(selectorFetchablePartnerSupplier(state));
  const supplierVerticals: SupplierVertical[] =
    (maybePartnerSupplier &&
      Object.keys(maybePartnerSupplier.verticals).map((categoryName: string) => {
        /**
         * Vertical key is expected to be CategoryName but in order to avoid using
         * a Map<CategoryName, PartnerVertical> we typecast the looser
         * {[key: string]: PartnerVertical} to CategoryName
         */
        const categoryName2 = categoryName as CategoryName;

        return { categoryName: categoryName2, partnerSupplierId: maybePartnerSupplier.id };
      })) ||
    [];

  return {
    supplierVerticals,
    supplier: maybePartnerSupplier
      ? { logoUrl: maybePartnerSupplier.logo.resourceName, id: maybePartnerSupplier.id }
      : undefined,
    loggedInUsername: selectorLoggedInUsername(state),
  };
}

export interface MenuProps {
  hasAdminAccess: boolean;
  path: string;
  menuModules?: MenuModule[];
}

export const Menu = connect(mapStateToProps)(MenuComponent);
