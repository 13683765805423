import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import * as React from 'react';
import styles from './styles.scss';

interface IProps {
  caption: string | React.ReactNode;
  value: string | React.ReactNode;
}

export const DetailsCardItem: React.FunctionComponent<IProps> = ({ caption, value }) => {
  return (
    <li className={styles.li}>
      <Caption colorVariant="darker">{caption}</Caption>
      <Body>{value}</Body>
    </li>
  );
};
