import type { AmountWithCurrency } from '@provider-portal/models/subscription';
import type { IUploadFile } from '@provider-portal/libs/uiFramework/components/fields/file/models';

export interface IProductFormData {
  name?: string;
  externalProductId?: string;
  bindingPeriod?: number;
  noticePeriod?: number;
  campaignId?: string;
  commission?: AmountWithCurrency;
  termsAndConditions?: IUploadFile[];
  requestApproval?: boolean;
  changesApproved?: boolean;
  type?: string;
}

export interface IProductViewModel {
  name?: string;
  externalProductId?: string;
  bindingPeriod?: number;
  noticePeriod?: number;
  campaignId?: string;
  commission?: AmountWithCurrency;
  termsAndConditions?: IUploadFile[];
  id?: string;
  state?: { type: ProductState };

  [key: string]: any;
}

export enum ProductState {
  Draft = 'Draft',
  Approved = 'Approved',
}

export enum EnergySource {
  Solar = 'Solar',
  Wind = 'Wind',
  Water = 'Water',
  BioOrganic = 'BioOrganic',
  MixedRenewable = 'MixedRenewable',
  MixedNonRenewable = 'MixedNonRenewable',
  MixedUndefined = 'MixedUndefined',
  NuclearPower = 'NuclearPower',
}

export enum AccommodationType {
  Villa = 'Villa',
  FullyDetachedHouse = 'FullyDetachedHouse',
  AttachedHouse = 'AttachedHouse',
  Apartment = 'Apartment',
  All = 'All',
}

export enum CustomerSegment {
  Student = 'Student',
  Senior = 'Senior',
  All = 'All',
}

export enum ElectricityPriceType {
  Floating = 'FloatingPrice',
  Fixed = 'FixedPrice',
}

export enum GasPriceType {
  Floating = 'FloatingPrice',
  Fixed = 'FixedPrice',
}

export enum SubmitType {
  SAVE_DRAFT = 'saveDraft',
  REQUEST_APPROVAL = 'requestApproval',
  PUBLISH = 'publish',
}

export interface IPartnerProductOverViewModel {
  id: string;
  name: string;
  state: ProductState;
  externalProductId?: string;
}
