import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { ListSwitchOrdersPage } from './ListSwitchOrders';
import { SwitchOrderDetailsPage } from './SwitchOrderDetails';
import { useSelector } from 'react-redux';
import { selectorMarketFromMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';

export const SwitchOrdersRoute: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  const market = useSelector(selectorMarketFromMaybePartnerSupplier);
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={(props) => (
          <ListSwitchOrdersPage
            // @ts-ignore
            partnerSupplierId={props.match.params.providerId}
            // @ts-ignore
            categoryName={props.match.params.categoryName}
            market={market}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/:orderId`}
        render={(props) => (
          <SwitchOrderDetailsPage
            // @ts-ignore
            partnerSupplierId={props.match.params.providerId}
            orderId={props.match.params.orderId}
            market={market}
          />
        )}
      />
    </Switch>
  );
};
