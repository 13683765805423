import { parseForm } from '@provider-portal/libs/uiFramework/components/fields/configs/parse-fields';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import { FormPage } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import { enrichRequestWithFormData, uiFramework } from '@provider-portal/libs/uiFramework';
import * as React from 'react';
import type { ICampaignFormData, ICampaignViewModel } from '@provider-portal/campaigns/models';
import { verticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';
import type { Market } from '@provider-portal/app-context/constants';
import type { CategoryName } from '@provider-portal/constants/categories';

interface ICampaignFormProps {
  appBarTitle: React.ReactNode;
  formData: ICampaignViewModel;
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
  providerId: string;
  categoryName: string;
  onSubmit(formData: ICampaignFormData): Promise<void>;
  market: Market;
}

export const CampaignForm: React.FunctionComponent<ICampaignFormProps> = ({
  appBarTitle,
  formData,
  leftSidedButtons,
  rightSidedButtons,
  providerId,
  categoryName,
  onSubmit,
  market,
}) => {
  return (
    <FormPage
      appBarText={appBarTitle}
      papers={getCampaignFormLayout(market, providerId, categoryName, formData)}
      onSubmit={async (event) => onSubmit(parseForm(event.currentTarget))}
      leftSidedButtons={leftSidedButtons}
      rightSidedButtons={rightSidedButtons}
    />
  );
};

export function getCampaignFormLayout(
  market: Market,
  providerId: string,
  categoryName: string,
  formData: ICampaignViewModel
): IFormPagePaper[] {
  const papers = uiFramework.layouts.campaignFormLayouts.get(verticalMarketKey(categoryName as CategoryName, market));
  if (!papers) {
    throw new Error(`No form configuration supported for market ${market} and category ${categoryName}`);
  }

  return papers.map((paper) => ({
    sections: paper.sections.map((section) => ({
      ...section,
      components: section.components.map(enrichRequestWithFormData(providerId, categoryName, formData)),
    })),
  }));
}
