import { Currency } from '@provider-portal/models/subscription';

export function localizeCentesimal(currency: Currency) {
  switch (currency) {
    case Currency.SEK:
      return 'öre';
    case Currency.DKK:
      return 'øre';
    case Currency.EUR:
      return 'cent';
    case Currency.GBP:
      return 'cent';
    case Currency.NOK:
      return 'øre';
    case Currency.USD:
      return 'cent';
    case Currency.AUD:
      return 'cent';
  }
}
