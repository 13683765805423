import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import * as React from 'react';
import { useState } from 'react';
import { FileType } from '@provider-portal/libs/uiFramework/components/fields/file/models';
import axios from 'axios';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import { FormPage } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import { FileInput } from '@provider-portal/libs/uiFramework/components/fields/file/FileInput/config';
import { Snackbar } from '@provider-portal/libs/uiFramework/components/dialogs/Snackbar';
import { parseForm } from '@provider-portal/libs/uiFramework/components/fields/configs/parse-fields';

export const AddProviderInner: React.FunctionComponent = () => {
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  return (
    <>
      <Snackbar
        autoClose
        variant={'success'}
        open={uploadComplete}
        content={<LocalizedMessage id="gridCostsUpdated" />}
        onClose={() => setUploadComplete(false)}
      />
      <Snackbar
        variant={'error'}
        open={uploadError}
        content={<LocalizedMessage id="errorWhenUpdatingGridCosts" />}
        onClose={() => setUploadError(false)}
      />
      <FormPage
        appBarText={<LocalizedMessage id="denmarkElectricityGridCostsTitle" />}
        papers={papers()}
        onSubmit={onSubmit}
        rightSidedButtons={[
          <Button key="save-draft" label={<LocalizedMessage id="updateGridCosts" />} color="secondary" type="submit" />,
        ]}
      />
    </>
  );

  function onSubmit(event: any) {
    const { file } = parseForm(event.currentTarget);

    const formData = new FormData();
    formData.append('file', file);

    axios
      .post(`api/v1/update-denmark-electricity-grid-costs`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        setUploadComplete(true);
      })
      .catch(() => {
        setUploadError(true);
      });
  }
};

const papers = (): IFormPagePaper[] => [
  {
    sections: [
      {
        caption: <LocalizedMessage id="fileInputTitle" />,
        columns: 1,
        components: [
          FileInput({
            name: 'file',
            label: <LocalizedMessage id="csvFileInputLabel" />,
            required: true,
            allowedFileTypes: [FileType.CSV],
          }),
        ],
      },
    ],
  },
];

export const DenmarkGridCosts = withLocalization('SupplierPortal/DenmarkElectricityGridCosts')(AddProviderInner);
