import React from 'react';
import { LocalizedMessage } from '@provider-portal/internationalization';
import GasProductsIcon from '@provider-portal/assets/icons/gas-products.svg';
import BroadbandIcon from '@provider-portal/assets/icons/BroadbandIcon.svg';
import GroceryBagIcon from '@provider-portal/assets/icons/GroceryBagIcon.svg';
import GamingIcon from '@provider-portal/assets/icons/GamingIcon.svg';
import MembershipIcon from '@provider-portal/assets/icons/MembershipIcon.svg';
import ElectricityNetworkIcon from '@provider-portal/assets/icons/ElectricityNetworkIcon.svg';
import CharityIcon from '@provider-portal/assets/icons/CharityIcon.svg';
import FitnessIcon from '@provider-portal/assets/icons/FitnessIcon.svg';
import NewsMagazineIcon from '@provider-portal/assets/icons/NewsMagazineIcon.svg';
import SubscriptionBoxIcon from '@provider-portal/assets/icons/SubscriptionBoxIcon.svg';
import TelevisionIcon from '@provider-portal/assets/icons/TelevisionIcon.svg';
import MobileProductsIcon from '@provider-portal/assets/icons/mobile-products.svg';

export interface ICategoryProps {
  key: string;
  label: JSX.Element;
  icon: JSX.Element;
}

export const categories = {
  'mobile-app': {
    key: 'mobile-app',
    label: <LocalizedMessage id="mobileApp" />,
    icon: <img src={GasProductsIcon} alt="mobile-app products" />,
  },
  electricity: {
    key: 'electricity',
    label: <LocalizedMessage id="electricity" />,
    icon: <img src={ElectricityNetworkIcon} alt="electricity products" />,
  },
  'electricity-grid': {
    key: 'electricity-grid',
    label: <LocalizedMessage id="electricityGrid" />,
    icon: <img src={ElectricityNetworkIcon} alt="electricity-grid products" />,
  },
  gas: {
    key: 'gas',
    label: <LocalizedMessage id="gas" />,
    icon: <img src={GasProductsIcon} alt="gas products" />,
  },
  broadband: {
    key: 'broadband',
    label: <LocalizedMessage id="broadband" />,
    icon: <img src={BroadbandIcon} alt="broadband products" />,
  },
  charity: {
    key: 'charity',
    label: <LocalizedMessage id="charity" />,
    icon: <img src={CharityIcon} alt="charity products" />,
  },
  game: {
    key: 'game',
    label: <LocalizedMessage id="game" />,
    icon: <img src={GamingIcon} alt="game products" />,
  },
  fitness: {
    key: 'fitness',
    label: <LocalizedMessage id="fitness" />,
    icon: <img src={FitnessIcon} alt="fitness products" />,
  },
  gambling: {
    key: 'gambling',
    label: <LocalizedMessage id="gambling" />,
    icon: <img src={GasProductsIcon} alt="gambling products" />,
  },
  'grocery-bag': {
    key: 'grocery-bag',
    label: <LocalizedMessage id="groceryBag" />,
    icon: <img src={GroceryBagIcon} alt="grocery-bag products" />,
  },
  insurance: {
    key: 'insurance',
    label: <LocalizedMessage id="insurance" />,
    icon: <img src={GasProductsIcon} alt="insurance products" />,
  },
  magazine: {
    key: 'magazine',
    label: <LocalizedMessage id="magazine" />,
    icon: <img src={NewsMagazineIcon} alt="magazine products" />,
  },
  membership: {
    key: 'membership',
    label: <LocalizedMessage id="membership" />,
    icon: <img src={MembershipIcon} alt="membership products" />,
  },
  mobile: {
    key: 'mobile',
    label: <LocalizedMessage id="mobile" />,
    icon: <img src={MobileProductsIcon} alt="mobile products" />,
  },
  news: {
    key: 'news',
    label: <LocalizedMessage id="news" />,
    icon: <img src={NewsMagazineIcon} alt="news products" />,
  },
  other: {
    key: 'other',
    label: <LocalizedMessage id="other" />,
    icon: <img src={GasProductsIcon} alt="other products" />,
  },
  'public-transport': {
    key: 'public-transport',
    label: <LocalizedMessage id="publicTransport" />,
    icon: <img src={GasProductsIcon} alt="public-transport products" />,
  },
  software: {
    key: 'software',
    label: <LocalizedMessage id="software" />,
    icon: <img src={GasProductsIcon} alt="software products" />,
  },
  'streaming-media': {
    key: 'streaming-media',
    label: <LocalizedMessage id="streamingMedia" />,
    icon: <img src={GasProductsIcon} alt="streaming-media products" />,
  },
  'trade-union': {
    key: 'trade-union',
    label: <LocalizedMessage id="tradeUnion" />,
    icon: <img src={GasProductsIcon} alt="trade-union products" />,
  },
  tv: {
    key: 'tv',
    label: <LocalizedMessage id="tv" />,
    icon: <img src={TelevisionIcon} alt="tv products" />,
  },
  'subscription-box': {
    key: 'subscription-box',
    label: <LocalizedMessage id="subscriptionBox" />,
    icon: <img src={SubscriptionBoxIcon} alt="subscription-box products" />,
  },
  loan: {
    key: 'loan',
    label: <LocalizedMessage id="loan" />,
    icon: <img src={GasProductsIcon} alt="loan products" />,
  },
  'private-loan': {
    key: 'private-loan',
    label: <LocalizedMessage id="privateLoan" />,
    icon: <img src={GasProductsIcon} alt="private-loan products" />,
  },
  'mortgage-loan': {
    key: 'mortgage-loan',
    label: <LocalizedMessage id="mortgageLoan" />,
    icon: <img src={GasProductsIcon} alt="mortgage-loan products" />,
  },
  'car-loan': {
    key: 'car-loan',
    label: <LocalizedMessage id="carLoan" />,
    icon: <img src={GasProductsIcon} alt="car-loan products" />,
  },
  'other-transaction': {
    key: 'other-transaction',
    label: <LocalizedMessage id="otherTransaction" />,
    icon: <img src={GasProductsIcon} alt="other-transaction products" />,
  },
  'credit-card': {
    key: 'credit-card',
    label: <LocalizedMessage id="creditCard" />,
    icon: <img src={GasProductsIcon} alt="credit-card products" />,
  },
  savings: {
    key: 'savings',
    label: <LocalizedMessage id="savings" />,
    icon: <img src={GasProductsIcon} alt="savings products" />,
  },
  'e-wallet': {
    key: 'e-wallet',
    label: <LocalizedMessage id="eWallet" />,
    icon: <img src={GasProductsIcon} alt="e-wallet products" />,
  },
  'savings-account': {
    key: 'savings-account',
    label: <LocalizedMessage id="savingsAccount" />,
    icon: <img src={GasProductsIcon} alt="savings-account products" />,
  },
  'isk-account': {
    key: 'isk-account',
    label: <LocalizedMessage id="iskAccount" />,
    icon: <img src={GasProductsIcon} alt="isk-account products" />,
  },
  'mobile-broadband': {
    key: 'mobile-broadband',
    label: <LocalizedMessage id="mobileBroadband" />,
    icon: <img src={GasProductsIcon} alt="mobile-broadband products" />,
  },
  'land-line': {
    key: 'land-line',
    label: <LocalizedMessage id="landLine" />,
    icon: <img src={GasProductsIcon} alt="land-line products" />,
  },
  'unemployment-insurance': {
    key: 'unemployment-insurance',
    label: <LocalizedMessage id="unemploymentInsurance" />,
    icon: <img src={GasProductsIcon} alt="unemployment-insurance products" />,
  },
};

type Categories = typeof categories;

export function localizeCategoryLabel(categoryName: CategoryName) {
  const result = categories[categoryName];
  if (!result) throw new Error(`Unexpected category ${categoryName}`);

  return result.label;
}

export function categoryIcon(categoryName: CategoryName) {
  const result = categories[categoryName];
  if (!result) throw new Error(`Unexpected category ${categoryName}`);

  return result.icon;
}

export type CategoryName = keyof Categories;
