import { parseDate } from '@provider-portal/utils/date';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type {
  ISwitchOrder,
  ISwitchOrderDetails,
  ISwitchOrderState,
  OrderExportResult,
  IAdminViewModel,
} from './models';

function mapResponseWithDatesParsed(order: any): ISwitchOrder {
  return {
    ...order,
    sentToSupplierAt: (order.sentToSupplierAt && parseDate(order.sentToSupplierAt)) || undefined,
  };
}

export async function assignUserToOrder(providerId: string, orderId: string): Promise<IAdminViewModel> {
  return axios
    .post(`/api/v1/suppliers/${providerId}/orders/${orderId}/assign`)
    .then((response: AxiosResponse<IAdminViewModel>) => response.data);
}

export async function unassignUserToOrder(providerId: string, orderId: string): Promise<void> {
  return axios
    .delete(`/api/v1/suppliers/${providerId}/orders/${orderId}/assign`)
    .then((response: AxiosResponse<void>) => response.data);
}

export async function fetchSwitchOrders(partnerSupplierId: string, categoryName: string): Promise<ISwitchOrder[]> {
  return axios
    .get(`api/v1/suppliers/${partnerSupplierId}/${categoryName}/orders/switch`)
    .then((response: AxiosResponse<any[]>) => {
      return response.data.map(mapResponseWithDatesParsed);
    });
}

export async function fetchSwitchOrder(partnerSupplierId: string, orderId: string): Promise<ISwitchOrderDetails> {
  return axios
    .get(`api/v1/suppliers/${partnerSupplierId}/orders/switch/${orderId}`)
    .then((response: AxiosResponse) => response.data);
}

export async function exportOrders(
  partnerSupplierId: string,
  categoryName: string,
  orderIds: string[]
): Promise<OrderExportResult> {
  return axios
    .post(`api/v1/suppliers/${partnerSupplierId}/${categoryName}/orders/switch/export`, orderIds, {
      responseType: 'arraybuffer',
    })
    .then((response: AxiosResponse) => {
      const contentDisposition = response.headers['content-disposition'];
      const fileNameMatches = /filename="(\w*\.\w*)"/.exec(contentDisposition);
      const fileName = (fileNameMatches && fileNameMatches[1]) || 'orders';

      return {
        fileContent: response.data,
        fileName: fileName,
        contentType: response.headers['content-type'],
      };
    });
}

export const exportOrder = (partnerSupplierId: string, orderId: string) => {
  return `/api/v1/suppliers/${partnerSupplierId}/orders/switch/${orderId}/export`;
};

export async function updateOrderState(
  partnerSupplierId: string,
  orderId: string,
  orderState: ISwitchOrderState
): Promise<ISwitchOrderDetails> {
  return axios
    .post(`api/v1/suppliers/${partnerSupplierId}/orders/switch/${orderId}`, orderState)
    .then((response: AxiosResponse) => response.data);
}
