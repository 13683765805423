import type { IInputField } from '../configs';
import { fieldConstructor, FieldType } from '../configs';
import type { IOption } from '../Select/option';

export interface IRadioGroup extends Omit<IInputField, 'label'> {
  type: FieldType.RadioGroup;
  defaultValue?: string;
  options?: IOption[];
}

export const RadioGroup = fieldConstructor<IRadioGroup>(FieldType.RadioGroup);
