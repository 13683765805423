import type { PauseSettings } from '@provider-portal/features/PausePage/models';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

export async function fetchPauseSettings(merchantId: string): Promise<PauseSettings> {
  return axios
    .get(`/api/v1/suppliers/${merchantId}/pause-settings`)
    .then(({ data }: AxiosResponse<PauseSettings>) => data);
}

export async function patchPauseSettings(merchantId: string, newPauseSettings: PauseSettings): Promise<void> {
  return axios.patch(`/api/v1/suppliers/${merchantId}/pause-settings`, newPauseSettings);
}
