import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { fetchSupplier } from './api';
import { PartnerSupplierActions } from './duck';

export function useFetchSupplierOnEnter(partnerSupplierId: string): PortalLoadingState {
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);

  React.useEffect(() => {
    fetchSupplier(partnerSupplierId)
      .then((supplier) => {
        dispatch(PartnerSupplierActions.setPartnerSupplier(supplier));
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
  }, [dispatch, partnerSupplierId]);

  return loadingState;
}
