export const wait = async (milliseconds: number, fn?: () => any) =>
  new Promise((resolve: (val?: any) => any) => {
    return setTimeout(() => {
      if (fn) {
        return resolve(fn());
      } else {
        return resolve();
      }
    }, milliseconds);
  });
