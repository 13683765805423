import { QuestionIcon } from '@provider-portal/assets/icons/QuestionIcon';
import { TextField } from '@provider-portal/libs/uiFramework/components/deprecated/TextField';
import { Tooltip } from '@provider-portal/libs/uiFramework/components/tooltip';
import { isValidEmail, isValidUri } from '@provider-portal/libs/validation';
import pickBy from 'lodash/pickBy';
import * as React from 'react';
import { FieldMessage } from '../FieldMessage';
import type { ITextInput } from './config';

const DEFAULT_ROW_COUNT = 3;

export enum TextInputVariant {
  URI = 'uri',
  EMAIL = 'email',
}

const tooltipStyles: React.CSSProperties = {
  position: 'absolute',
  top: '24px',
  right: '16px',
};

export interface ITextInputProps extends Omit<ITextInput, 'type'> {
  validators?: any;
  multiline?: boolean;
  backgroundColor?: string;
  disabled?: boolean;
  variant?: TextInputVariant;
  helperText?: React.ReactNode;
  onBlur?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  attributes?: Record<string, unknown>;
  maxlength?: number;
  rowCount?: number;
}

export const TextInput: React.FunctionComponent<ITextInputProps> = (props) => {
  const inputStyles =
    (props.backgroundColor && { style: { background: props.backgroundColor, borderRadius: '8px' } }) || undefined;
  const validators = pickBy({
    ...props.validators,
    isUri: (props.variant === TextInputVariant.URI && uriValidator) || undefined,
    isEmail: (props.variant === TextInputVariant.EMAIL && emailValidator) || undefined,
  });
  const rowCount = props.rowCount || DEFAULT_ROW_COUNT;

  return (
    <span style={{ position: 'relative' }}>
      <TextField
        disabled={props.disabled}
        name={props.name}
        defaultValue={props.defaultValue || ''}
        required={props.required}
        requiredErrorText={props.required && (props.requiredErrorText || <FieldMessage id="portalRequiredField" />)}
        label={props.label}
        fullWidth
        InputLabelProps={{ required: false }}
        helperText={getHelperText(props)}
        rows={rowCount}
        multiline={props.multiline}
        inputProps={{ ...inputStyles, ...props.attributes, maxLength: props.maxlength }}
        validators={validators}
        onBlur={props.onBlur}
        onChange={props.onChange}
        value={props.value}
        onKeyDown={props.onKeyDown}
      />
      {props.tooltipTitle && props.tooltipText && (
        <span style={tooltipStyles}>
          <Tooltip
            tooltipTitle={props.tooltipTitle}
            tooltipText={props.tooltipText}
            tooltiplinkText={props.tooltiplinkText}
            tooltiplinkUrl={props.tooltiplinkUrl}
          >
            <QuestionIcon />
          </Tooltip>
        </span>
      )}
    </span>
  );
};

const getHelperText = (props: ITextInputProps): React.ReactNode => {
  if (!props.required && !props.helperText && props.disabled) {
    return undefined;
  } else if (!props.required && !props.helperText) {
    return <FieldMessage id="optional" />;
  } else {
    return props.helperText;
  }
};

const uriValidator = (val?: string) => {
  if (!val) return false;

  return !isValidUri(val) ? <FieldMessage id="portalUriRequired" /> : false;
};

const emailValidator = (val?: string) => {
  if (!val) return false;

  let validateResult: boolean | React.ReactElement = false;
  const emailAddresses = val.split(',');
  emailAddresses.forEach((address: string) => {
    if (!isValidEmail(address)) {
      validateResult = <FieldMessage id="portalEmailRequired" />;
    }
  });

  return validateResult;
};
