import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';

export const CheckedIcon: React.FunctionComponent = () => {
  return (
    <SvgIcon height={24} width={24}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4651 6.23962C18.79 6.49647 18.8452 6.96812 18.5884 7.29309L10.7493 17.2112C10.5766 17.4363 10.3532 17.6175 10.0971 17.7398C9.8386 17.8633 9.55434 17.9232 9.26797 17.9147C8.98161 17.9061 8.70143 17.8293 8.45075 17.6906C8.20304 17.5535 7.99121 17.36 7.83245 17.1257L5.38691 13.655C5.14833 13.3164 5.2294 12.8485 5.568 12.6099C5.9066 12.3713 6.3745 12.4524 6.61309 12.791L9.06309 16.268L9.07323 16.2828C9.09972 16.3224 9.13528 16.355 9.17694 16.3781C9.2186 16.4011 9.26517 16.4139 9.31277 16.4153C9.36036 16.4167 9.40761 16.4068 9.45057 16.3863C9.49354 16.3657 9.53098 16.3352 9.55979 16.2973L9.5686 16.286L17.4116 6.36296C17.6684 6.03799 18.1401 5.98277 18.4651 6.23962Z"
        fill="#68C958"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.49902C6.20101 1.49902 1.5 6.20003 1.5 11.999C1.5 17.798 6.20101 22.499 12 22.499C17.799 22.499 22.5 17.798 22.5 11.999C22.5 6.20003 17.799 1.49902 12 1.49902ZM0 11.999C0 5.37161 5.37258 -0.000976562 12 -0.000976562C18.6274 -0.000976562 24 5.37161 24 11.999C24 18.6264 18.6274 23.999 12 23.999C5.37258 23.999 0 18.6264 0 11.999Z"
        fill="#68C958"
      />
    </SvgIcon>
  );
};
