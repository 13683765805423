import { LocalizedMessage } from '@provider-portal/internationalization';
import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import type { ITabConfig } from '@provider-portal/libs/uiFramework/components/Tabs/config';
import * as React from 'react';
import type { CancellationReason, SaveDeskOffer2State } from '@provider-portal/saveDesk/models';

export enum SaveDeskOffersListTab {
  APPROVED = 'approved',
  DRAFTS = 'drafts',
}

export const saveDeskOffersListTableConfig: ITableConfig = {
  cells: [
    {
      key: 'name',
      label: <LocalizedMessage id="saveDeskOfferName" />,
    },
    {
      key: 'cancellationReasons',
      label: <LocalizedMessage id="saveDeskOfferCancellationReasons" />,
      renderCellValue(reasons: CancellationReason[]) {
        return reasons.map((reason, index, list) => (
          <span key={reason}>
            <LocalizedMessage id={reason} />
            {index < list.length - 1 && <span>, </span>}
          </span>
        ));
      },
    },
    {
      key: 'offerState',
      label: <LocalizedMessage id="state" />,
      renderCellValue(value: SaveDeskOffer2State) {
        return <LocalizedMessage id={value} />;
      },
    },
  ],
  defaultSortColumn: 'name',
};

export const getTabsConfig = (
  activeSaveDeskOffersCount: number,
  savedDraftsCount: number
): ITabConfig<SaveDeskOffersListTab>[] => [
  {
    key: SaveDeskOffersListTab.APPROVED,
    label: <LocalizedMessage id="activeSaveDeskOffers" values={{ activeSaveDeskOffersCount }} />,
  },
  {
    key: SaveDeskOffersListTab.DRAFTS,
    label: <LocalizedMessage id="savedDrafts" values={{ savedDraftsCount }} />,
  },
];
