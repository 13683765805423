import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput';
import type { CSSProperties } from 'react';
import { default as React } from 'react';
import styles from './styles.scss';

export interface ITextInputProps {
  value: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  required?: boolean;
  multiline?: boolean;
  style?: CSSProperties;
  label?: React.ReactNode;
  maxlength?: number;
}

export const TranslatableTextInput: React.FunctionComponent<ITextInputProps> = (props) => {
  return (
    <div className={props.multiline ? styles.multiline : ''} style={props.style || {}}>
      <TextInput
        name=""
        value={props.value}
        required={props.required}
        onChange={props.onChange}
        disabled={props.disabled}
        backgroundColor="white"
        multiline={props.multiline}
        label={props.label}
        maxlength={props.maxlength}
        rowCount={5}
        helperText={<></>}
      />
    </div>
  );
};
