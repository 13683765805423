// @ts-ignore js import, remove this when the import is typed
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import type { IPageComponent } from '@provider-portal/libs/uiFramework/components/layouts/config';
import { PageComponentType } from '@provider-portal/libs/uiFramework/components/layouts/config';
import { HorizontalButtonGroup } from '@provider-portal/libs/uiFramework/components/layouts/HorizontalButtonGroup';
import { TwoColumnLayout } from '@provider-portal/libs/uiFramework/components/layouts/TwoColumnLayout';
import { FieldSection } from '@provider-portal/libs/uiFramework/components/sections/FieldSection';
import * as React from 'react';
import type { IFieldSectionConfig } from '../../sections/FieldSection/fieldSectionConfig';
import { Page } from '../Page';
import styles from './styles.scss';

export interface IFormPagePaper {
  sections: IFieldSectionConfig[];
  column?: 1 | 2;
  row?: 1 | 2;
}

interface IFormPageProps {
  papers: IFormPagePaper[];
  onSubmit(event: any): void;
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
  appBarText: React.ReactNode;
  showMenuForSmallerScreen?: boolean;
  children?: React.ReactNode;
}

export const FormPage: React.FunctionComponent<IFormPageProps> = ({
  papers,
  onSubmit,
  leftSidedButtons,
  rightSidedButtons,
  appBarText,
  showMenuForSmallerScreen,
  children,
}) => {
  const firstRowPapers = papers.filter((paper) => paper.row === undefined || paper.row === 1);
  const secondRowPapers = papers.filter((paper) => paper.row === 2);

  return (
    <Page variant="details" appBarText={appBarText} showMenuForSmallerScreen={showMenuForSmallerScreen}>
      <Form onValidSubmitWithEvent={(event: any) => onSubmit(event)}>
        <TwoColumnLayout components={mapPapers(firstRowPapers)} />
        {secondRowPapers.length > 0 && (
          <div className={styles.secondRow}>
            <TwoColumnLayout components={mapPapers(secondRowPapers)} />
          </div>
        )}
        <div className={styles.paddingTop}>{children}</div>
        <div className={styles.paddingTop}>
          <HorizontalButtonGroup leftSidedButtons={leftSidedButtons} rightSidedButtons={rightSidedButtons} />
        </div>
      </Form>
    </Page>
  );
};

export interface IProductDetails extends IPageComponent {
  type: PageComponentType.PRODUCT_DETAILS;
  papers: React.ReactNode[];
  column: 1 | 2;
}

export function mapPapers(papers: IFormPagePaper[]): IProductDetails[] {
  return papers.map((paper) => ({
    type: PageComponentType.PRODUCT_DETAILS,
    column: paper.column ? paper.column : 1,
    papers: paper.sections.map((section, sectionIndex) => {
      return <FieldSection key={sectionIndex} {...section} />;
    }),
  }));
}
