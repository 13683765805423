import isString from 'lodash/isString';
export * from './email-validation-utils';

export const hidePotentialEmailAddresses = (emailString?: string) => {
  let hiddenEmailString = emailString;
  const emailRegex = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+)/g;
  if (isString(emailString) && emailRegex.test(emailString)) {
    hiddenEmailString = emailString.replace(emailRegex, 'hidden email address');
  }

  return hiddenEmailString;
};
