import { Sentry } from '@provider-portal/sentry';
import { debouncePromise, debouncePromiseWithDelay } from '@provider-portal/utils/debounce-promise';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Snackbar } from '@provider-portal/libs/uiFramework/components/dialogs/Snackbar';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import compact from 'lodash/compact';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { archiveProduct, fetchProduct, unpublishProduct, updatePartnerProduct } from '../api';
import type { IProductFormData, IProductViewModel } from '../models';
import { ProductState, SubmitType } from '../models';
import { ProductForm } from '../ProductForm';
import { ProductSnackbarState, snackbarContent } from '../snackbar-state';
import { PublishButton } from './PublishButton';
import { useSelector } from 'react-redux';
import { selectorMarketFromMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';

interface IEditProductProps {
  providerId: string;
  productId: string;
  categoryName: string;
}

const EditProductInner: React.FunctionComponent<IEditProductProps> = ({ providerId, productId, categoryName }) => {
  const [loadingState, setLoadingState] = React.useState(PortalLoadingState.Loading);
  const [productViewModel, setProductViewModel] = React.useState<IProductViewModel>({});
  const [submitType, setSubmitType] = React.useState<SubmitType | undefined>();
  const [snackbarState, setSnackbarState] = React.useState<ProductSnackbarState>(ProductSnackbarState.CLOSED);
  const history = useHistory();
  const market = useSelector(selectorMarketFromMaybePartnerSupplier);

  React.useEffect(() => {
    fetchProduct(providerId, productId)
      .then((formData) => {
        setProductViewModel(formData);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to fetch product view model');
      });
  }, [providerId, productId]);

  const editProductUrl = (productIdToNavigateTo?: string) => {
    return URI(history.location.pathname)
      .segment(-1, productIdToNavigateTo || '')
      .toString();
  };

  // Note 2021-02-08: We debounce for at least 2 seconds in an attempt to remediate https://minnatechnologies.atlassian.net/browse/WOTS-540. If the issue still comes back, then we'll have to revisit the solution and put more effort into isolating the bug.
  const updateProduct = debouncePromiseWithDelay(2000, async (formData: IProductFormData) => {
    if (submitType === SubmitType.PUBLISH) {
      formData.changesApproved = true;
    } else if (submitType === SubmitType.REQUEST_APPROVAL) {
      formData.requestApproval = true;
    }

    try {
      const updatedFormData = await updatePartnerProduct(providerId, productId, formData);
      if (updatedFormData.id !== productId) {
        history.replace(editProductUrl(updatedFormData.id));
      }
      setProductViewModel(updatedFormData);
      setSnackbarState(ProductSnackbarState.PRODUCT_UPDATED);
    } catch (error) {
      setLoadingState(PortalLoadingState.Failure);
      Sentry.captureExceptionWithMessage(error, 'Failed to update product');
    }
  });

  const unpublish = debouncePromise(async () => {
    unpublishProduct(providerId, productId)
      .then((formData) => {
        history.replace(editProductUrl(formData.id));
        setProductViewModel(formData);
        setSnackbarState(ProductSnackbarState.PRODUCT_UNPUBLISHED);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to unpublish product');
      });
  });

  const deleteProduct = debouncePromise(async () => {
    archiveProduct(providerId, productId)
      .then(() => {
        const listProductsUrl = URI(history.location.pathname).segment(-1, '').toString();
        history.replace(listProductsUrl);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to delete product');
      });
  });

  return (
    <LoadingPage loadingState={loadingState}>
      <ProductForm
        appBarTitle={productViewModel.name}
        formData={productViewModel}
        categoryName={categoryName}
        providerId={providerId}
        onSubmit={updateProduct}
        leftSidedButtons={
          (productViewModel.state?.type === ProductState.Draft && [
            <Button
              key="delete"
              label={<LocalizedMessage id="archiveButton" />}
              color="negative"
              onClick={deleteProduct}
            />,
          ]) ||
          undefined
        }
        rightSidedButtons={compact([
          productViewModel.state?.type === ProductState.Draft && (
            <Button
              key="save-draft"
              label={<LocalizedMessage id="saveDraftButton" />}
              color="secondary"
              type="submit"
              onClick={() => setSubmitType(SubmitType.SAVE_DRAFT)}
            />
          ),
          productViewModel.state?.type === ProductState.Approved && (
            <Button
              key="unpublish"
              label={<LocalizedMessage id="unpublishButton" />}
              color="negative"
              onClick={unpublish}
            />
          ),
          <PublishButton key="publish" productFormData={productViewModel} onClick={setSubmitType} />,
        ])}
        market={market}
      />
      <Snackbar
        autoClose
        variant={snackbarState === ProductSnackbarState.PRODUCT_UPDATED ? 'success' : 'info'}
        open={snackbarState !== ProductSnackbarState.CLOSED}
        content={snackbarContent(snackbarState)}
        onClose={() => setSnackbarState(ProductSnackbarState.CLOSED)}
      />
    </LoadingPage>
  );
};

export const EditProduct = withLocalization('EditProduct')(EditProductInner);
