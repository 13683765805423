import type { AppContext } from '@provider-portal/app-context';
import { withLocalization } from '@provider-portal/internationalization';
import { useTabs } from '@provider-portal/libs/hooks/use-tabs';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { TabbedTableOverviewPage } from '@provider-portal/libs/uiFramework/components/pages/TabbedTableOverviewPage';
import type { IChangePlanOffer2ListViewModel } from '@provider-portal/features/changePlan/models';
import { isOfferActive, isOfferDraft } from '@provider-portal/features/changePlan/models';
import { useFetchOffersOnEnter } from '@provider-portal/features/changePlan/ChangePlanListOffersPage/use-fetch';
import React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { getTabsConfig, ChangePlanOffersListTab, changePlanOffersListTableConfig } from './config';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import PropTypes from 'prop-types';
import { Header } from '../components/Header';
import { PageAppBar } from '../components/PageAppBar';

export interface IChangePlanListOffersPageProps {
  partnerSupplierId: string;
}

export const ChangePlanListOffersPageInner: React.FunctionComponent<
  IChangePlanListOffersPageProps & { appContext: AppContext }
> = ({ partnerSupplierId }, { localizeMessage }) => {
  const history = useHistory();
  const { loadingState, changePlanOffers } = useFetchOffersOnEnter(partnerSupplierId);
  const [approvedPublishedOffers, draftOffers] = groupApprovedPublishedAndDraftOffers(changePlanOffers);
  const tabsConfig = getTabsConfig(approvedPublishedOffers.length, draftOffers.length);
  const [selectedTab, onSwitchTab] = useTabs(tabsConfig);

  return (
    <LoadingPage loadingState={loadingState}>
      <Page appBarText={<PageAppBar />} variant="details" showMenuForSmallerScreen showBackButton={false}>
        <Header
          title={localizeMessage('changePlanOffersTitle')}
          description={localizeMessage('changePlanDesription')}
        />
        <TabbedTableOverviewPage
          appBarText={''}
          tabs={tabsConfig}
          table={changePlanOffersListTableConfig(localizeMessage)}
          data={selectedTab === ChangePlanOffersListTab.APPROVED ? approvedPublishedOffers : draftOffers}
          selectedTab={selectedTab}
          onSwitchTab={onSwitchTab}
          onRowClick={onChangePlanOfferClick}
          onAdd={onAdd}
        />
      </Page>
    </LoadingPage>
  );

  function onChangePlanOfferClick(changePlanOffer: IChangePlanOffer2ListViewModel) {
    const editOfferUrl = URI(history.location.pathname).segment(changePlanOffer.id).toString();
    history.push(editOfferUrl);
  }

  function onAdd() {
    const addOfferUrl = URI(history.location.pathname).segment('add').toString();
    history.push(addOfferUrl);
  }
};

ChangePlanListOffersPageInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const ChangePlanListOffersPage: React.ComponentType<IChangePlanListOffersPageProps> =
  withLocalization('ChangePlan')(ChangePlanListOffersPageInner);

function groupApprovedPublishedAndDraftOffers(changePlanOffers: IChangePlanOffer2ListViewModel[]) {
  return [
    changePlanOffers.filter((offer) => isOfferActive(offer.offerState)),
    changePlanOffers.filter((offer) => isOfferDraft(offer.offerState)),
  ];
}
