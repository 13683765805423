import { LocalizedMessage } from '@provider-portal/internationalization';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { MeasurementUnit } from '@provider-portal/libs/uiFramework/components/fields/MeasurementUnit';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { GasPriceType } from '@provider-portal/products/models';
import { CentesimalCostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CentesimalCostInput/config';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import { RadioGroup } from '@provider-portal/libs/uiFramework/components/fields/RadioGroup/config';
import {
  campaignPaper,
  internalPaper,
  electricityNamingSection,
  termsPaper,
} from '@provider-portal/products/ProductForm/config/common';
import React from 'react';
import type { ProductFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createProductFormLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { Currency } from '@provider-portal/models/subscription';
import { getDefaultLocale } from '@provider-portal/app-context/state';

const currency = Currency.EUR;
const locale = getDefaultLocale(Market.Belgium);

const belgiumGasPapers = (hasAdminAccess: boolean): IFormPagePaper[] => [
  {
    sections: [electricityNamingSection, ...belgiumGasSections],
  },
  termsPaper(locale),
  campaignPaper,
  internalPaper(hasAdminAccess, currency, locale),
];

export const belgiumGasLayoutMap: ProductFormLayoutMap = createProductFormLayout(
  categories.gas,
  Market.Belgium,
  belgiumGasPapers
);

enum BelgiumGasFieldNames {
  PRICE_TYPE = 'priceType',
  FIXED_COST = 'fixedCost',
  GAS_CONSUMPTION_COST = 'consumptionCost',
  FORM_TYPE = 'type',
}

const belgiumGasSections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="pricingTitle" />,
    captionText: <LocalizedMessage id="electricityPriceCaptionText" />,
    tooltipTitle: <LocalizedMessage id="pricingTitle" />,
    tooltipText: <LocalizedMessage id="consumptionHeaderTooltiptext" />,
    columns: 4,
    components: [
      RadioGroup({
        name: BelgiumGasFieldNames.PRICE_TYPE,
        options: [
          { value: GasPriceType.Floating, label: <LocalizedMessage id="floatingPriceLabel" /> },
          { value: GasPriceType.Fixed, label: <LocalizedMessage id="fixedPriceLabel" /> },
        ],
        required: true,
      }),
      HiddenInput({
        name: BelgiumGasFieldNames.FORM_TYPE,
        defaultValue: 'BelgiumGasProductIncomingRequest',
      }),
    ],
  },
  {
    columns: 4,
    components: [
      CostInput({
        label: <LocalizedMessage id="fixedCostLabel" />,
        name: BelgiumGasFieldNames.FIXED_COST,
        unit: MeasurementUnit.YEAR,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        label: <LocalizedMessage id="costLabel" />,
        name: BelgiumGasFieldNames.GAS_CONSUMPTION_COST,
        unit: MeasurementUnit.KWH,
        required: true,
        currency: currency,
        locale: locale,
      }),
    ],
  },
];
