import { LocalizedMessage } from '@provider-portal/internationalization';
import { RejectedReason } from '@provider-portal/models/order';
import { RadioButton } from '@provider-portal/libs/uiFramework/components/deprecated/RadioButton';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import * as React from 'react';
import { BindingPeriodDatePicker } from './BindingPeriodDatePicker';
import { MessageToUserInput } from './MessageToUserInput';
import styles from './styles.scss';

export interface IRejectionOptionProps {
  rejectionReason: RejectedReason;
  selected: boolean;
  onSelected(rejectionReason: RejectedReason): void;
  messageToUser: string;
  onMessageChanged(messageToUser: string): void;
  bindingPeriod?: Date;
  onBindingPeriodChanged(bindingPeriod: Date): void;
}

export const RejectionOption: React.FunctionComponent<IRejectionOptionProps> = ({
  rejectionReason,
  selected,
  onSelected,
  messageToUser,
  onMessageChanged,
  bindingPeriod,
  onBindingPeriodChanged,
}) => {
  if (selected) {
    return (
      <Panel1 variant="flat">
        <RadioButton label={label(rejectionReason)} checked onChange={() => void 0} />
        {rejectionReason === RejectedReason.HasBindingPeriod && (
          <BindingPeriodDatePicker bindingPeriod={bindingPeriod} onBindingPeriodChanged={onBindingPeriodChanged} />
        )}
        {rejectionReason === RejectedReason.Unknown && (
          <MessageToUserInput
            rejectionReason={rejectionReason}
            messageToUser={messageToUser}
            onMessageChanged={onMessageChanged}
          />
        )}
      </Panel1>
    );
  } else {
    return (
      <div className={styles.notSelected}>
        <RadioButton label={label(rejectionReason)} checked={false} onChange={() => onSelected(rejectionReason)} />
      </div>
    );
  }
};

function label(rejectionReason: RejectedReason) {
  switch (rejectionReason) {
    case RejectedReason.AlreadyCustomer:
      return <LocalizedMessage id="rejectionAlreadyCustomer" />;
    case RejectedReason.CreditCheckFailed:
      return <LocalizedMessage id="rejectionCreditCheck" />;
    case RejectedReason.HasBindingPeriod:
      return <LocalizedMessage id="rejectionBindingPeriod" />;
    case RejectedReason.Unknown:
      return <LocalizedMessage id="rejectionUnknown" />;
    case RejectedReason.UnknownAddress:
      return <LocalizedMessage id="rejectionUnknownAddress" />;
  }
}
