// eslint-disable-next-line import/no-unassigned-import,import/no-unresolved
import 'core-js/stable';
// eslint-disable-next-line import/no-unassigned-import
import 'regenerator-runtime/runtime';
import { ErrorLoading } from '@provider-portal/fetching/ErrorPage/ErrorLoading';
import Sentry from '@provider-portal/sentry';
import { SupplierPortalApp } from '@provider-portal/SupplierPortalApp';
import { initializeProviderPortalMixpanel } from '@provider-portal/tracking/mixpanel';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-unassigned-import
import './locale';
// eslint-disable-next-line import/no-unassigned-import
import './style.scss';

const history = createBrowserHistory();

Sentry.setGlobalTag('application', 'supplier-portal');

void (async () => {
  try {
    initializeProviderPortalMixpanel();
    ReactDOM.render(<SupplierPortalApp history={history} />, document.getElementById('root'));
  } catch (error) {
    Sentry.captureExceptionWithMessage(error, 'Failed to load critical resource on startup in provider portal');
    ReactDOM.render(<ErrorLoading />, document.getElementById('root'));
  }
})();
