import { withLocalization } from '@provider-portal/internationalization';
import { withAppContext } from '@provider-portal/app-context/react';
import type { ISaveDeskResponseCardOld } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskResponseCardOld/config';
import type { ApplicationState } from '@provider-portal/store';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import type { IResponseProps } from './component';
import { SaveDeskResponseCardOld as Component } from './component';

const mapStateToProps = (state: ApplicationState) => {
  return {
    saveDeskOrder: state.saveDesk.saveDeskOrder,
  };
};

export const SaveDeskResponseCardOld = compose<IResponseProps, ISaveDeskResponseCardOld>(
  withAppContext,
  withLocalization('supplier-portal-save-desk'),
  connect(mapStateToProps)
)(Component);
