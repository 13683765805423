import { LocalizedMessage } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Dialog } from '@provider-portal/libs/uiFramework/components/dialogs/Dialog';
import { Checkbox } from '@provider-portal/libs/uiFramework/components/deprecated/Checkbox';
import * as React from 'react';
import type { ICategoryOption } from '../available-categories';

interface ISelectCategoryDialogProps {
  categoryOptions: ICategoryOption[];
  previouslyAddedCategoryNames: string[];
  onAddCategories(categoryNames: string[]): void;
}

export const SelectCategoryDialog: React.FunctionComponent<ISelectCategoryDialogProps> = ({
  categoryOptions,
  previouslyAddedCategoryNames,
  onAddCategories,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedCategoriesAsSet = new Set(selectedCategories);

    if (event.target.checked) {
      selectedCategoriesAsSet.add(event.target.name);
    } else {
      selectedCategoriesAsSet.delete(event.target.name);
    }
    setSelectedCategories(Array.from(selectedCategoriesAsSet));
  }

  const addCategories = () => {
    onAddCategories(selectedCategories);
    close();
  };

  const close = () => {
    setOpen(false);
    setSelectedCategories([]);
  };

  return (
    <>
      <Button label={<LocalizedMessage id="addCategoryButtonText" />} onClick={() => setOpen(true)} color="secondary" />
      <Dialog
        title={<LocalizedMessage id="addCategoryDialogTitle" />}
        onClose={close}
        open={open}
        topButton={
          <Button
            label={<LocalizedMessage id="addCategoryConfirmButtonText" />}
            color="positive"
            fullWidth
            onClick={addCategories}
          />
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {categoryOptions.map((categoryOption) => {
            return (
              <Checkbox
                key={categoryOption.value}
                checked={selectedCategories.concat(previouslyAddedCategoryNames).includes(categoryOption.value)}
                disabled={previouslyAddedCategoryNames.includes(categoryOption.value)}
                label={categoryOption.label}
                onChange={onChange}
                name={categoryOption.value}
              />
            );
          })}
        </div>
      </Dialog>
    </>
  );
};
