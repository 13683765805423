import type { EnrichMap, IEnrichProps } from '@provider-portal/libs/uiFramework';
import { WidgetType } from '../config';

export interface ICampaignIdSelectWidget {
  type: WidgetType.CAMPAIGN_ID_SELECT;
  providerId: string;
  categoryName: string;
  campaignId?: string;
}

export enum CampaignIdSelectFieldNames {
  CAMPAIGN_ID = 'campaignId',
}

const campaignIdSelectEnricher = (props: IEnrichProps<any>) => {
  return {
    ...props.config,
    categoryName: props.categoryName,
    providerId: props.providerId,
    campaignId: props.formData[CampaignIdSelectFieldNames.CAMPAIGN_ID],
  };
};

export const campaignIdSelectEnricherMap: EnrichMap = [WidgetType.CAMPAIGN_ID_SELECT, campaignIdSelectEnricher];
