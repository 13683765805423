import { isFloatZeroOrGreater } from '@provider-portal/libs/validation';
import PropTypes from 'prop-types';
import * as React from 'react';
import { HiddenInput } from '../../HiddenInput';
import { HiddenInputDataFormat } from '../../HiddenInput/config';
import { localizeCostUnit } from '../../MeasurementUnit';
import { NumberInput, NumberType } from '../../NumberInput';
import type { ICostInput } from './config';

interface ICostInputProps extends Omit<ICostInput, 'type'> {
  backgroundColor?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

function parseValue(cost: string = ''): string {
  return cost.replace(',', '.');
}

export const CostInput: React.FunctionComponent<ICostInputProps> = (props, { localizeCurrency }) => {
  const [costValue, setCostValue] = React.useState<string>(props.defaultValue?.amount.toString() || '');
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isFloatZeroOrGreater(event.target.value)) {
      setCostValue(parseValue(event.target.value));
    } else {
      setCostValue('');
    }
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <>
      <NumberInput
        {...props}
        name=""
        label={
          <>
            {props.label} ({localizeCurrency(props.currency)} {props.unit && <>/ {localizeCostUnit(props.unit)}</>})
          </>
        }
        numberType={NumberType.FloatZeroOrGreater}
        defaultValue={costValue}
        onChange={onChange}
        locale={props.locale}
      />
      <HiddenInput
        name={props.name}
        dataFormat={HiddenInputDataFormat.COST_WITH_CURRENCY}
        value={
          (costValue && { amount: parseFloat(costValue), currency: props.currency, unit: props.unit }) || undefined
        }
      />
    </>
  );
};

CostInput.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
  localizeCurrency: PropTypes.func.isRequired,
};
