import { LocalizedMessage } from '@provider-portal/internationalization';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { MeasurementUnit } from '@provider-portal/libs/uiFramework/components/fields/MeasurementUnit';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import {
  campaignPaper,
  internalPaper,
  mobileNamingSection,
  termsPaper,
} from '@provider-portal/products/ProductForm/config/common';
import * as React from 'react';
import type { ProductFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createProductFormLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { Currency } from '@provider-portal/models/subscription';
import { getDefaultLocale } from '@provider-portal/app-context/state';

const currency = Currency.EUR;
const locale = getDefaultLocale(Market.Belgium);

export const belgiumMobilePapers = (hasAdminAccess: boolean): IFormPagePaper[] => [
  {
    sections: [mobileNamingSection, ...belgiumMobileSections],
  },
  termsPaper(locale),
  campaignPaper,
  internalPaper(hasAdminAccess, currency, locale),
];

export const belgiumMobileLayoutMap: ProductFormLayoutMap = createProductFormLayout(
  categories.mobile,
  Market.Belgium,
  belgiumMobilePapers
);

enum BelgiumMobileProductFieldNames {
  SURF_RATE = 'surfRate',
  CALL_RATE = 'callRate',
  MESSAGES_RATE = 'messagesRate',
  LIMITED_TIME_IN_MINUTES = 'limitedTimeInMinutes',
  MESSAGES_LIMITATION = 'messagesLimitation',
  DATA_CAPACITY_IN_GB = 'dataCapacityInGb',
  STARTUP_FEE = 'startupFee',
  FORM_TYPE = 'type',
  MONTHLY_COST = 'monthlyCost',
}

const belgiumMobileSections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="pricingSectionHeader" />,
    columns: 4,
    components: [
      CostInput({
        name: BelgiumMobileProductFieldNames.MONTHLY_COST,
        label: <LocalizedMessage id="monthlyCostHeader" />,
        tooltipTitle: <LocalizedMessage id="monthlyCostHeader" />,
        tooltipText: <LocalizedMessage id="monthlyCostTooltipText" />,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CostInput({
        name: BelgiumMobileProductFieldNames.STARTUP_FEE,
        label: <LocalizedMessage id="startupFeeLabel2" />,
        tooltipTitle: <LocalizedMessage id="startupFeeLabel3" />,
        tooltipText: <LocalizedMessage id="startupFeeTooltipText" />,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="phoneCallsHeader" />,
    captionText: <LocalizedMessage id="phoneCallsCaptionText" />,
    divider: 'top',
    columns: 4,
    components: [
      NumberInput({
        name: BelgiumMobileProductFieldNames.LIMITED_TIME_IN_MINUTES,
        label: <LocalizedMessage id="callMinutesPerMonthLabel" />,
        numberType: NumberType.IntegerZeroOrGreater,
        helperText: <LocalizedMessage id="callMinutesPerMonthHelper" />,
        locale: locale,
      }),
      CostInput({
        name: BelgiumMobileProductFieldNames.CALL_RATE,
        label: <LocalizedMessage id="callRateLabel" />,
        required: true,
        unit: MeasurementUnit.MINUTE,
        currency: currency,
        locale: locale,
      }),
      HiddenInput({
        name: BelgiumMobileProductFieldNames.FORM_TYPE,
        defaultValue: 'BelgiumMobileProductIncomingRequest',
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="dataPlanHeader" />,
    captionText: <LocalizedMessage id="dataCaptionText" />,
    divider: 'top',
    columns: 4,
    components: [
      CostInput({
        name: BelgiumMobileProductFieldNames.SURF_RATE,
        label: <LocalizedMessage id="surfRateLabel" />,
        required: true,
        unit: MeasurementUnit.MEGABYTE,
        currency: currency,
        locale: locale,
      }),
      NumberInput({
        name: BelgiumMobileProductFieldNames.DATA_CAPACITY_IN_GB,
        label: <LocalizedMessage id="dataLabel" />,
        numberType: NumberType.FloatZeroOrGreater,
        helperText: <LocalizedMessage id="dataCapacityHelper" />,
        locale: locale,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="textMessagesHeader" />,
    captionText: <LocalizedMessage id="textCaptionText" />,
    divider: 'top',
    columns: 4,
    components: [
      CostInput({
        name: BelgiumMobileProductFieldNames.MESSAGES_RATE,
        label: <LocalizedMessage id="messagesRateLabel" />,
        required: true,
        currency: currency,
        locale: locale,
      }),
      NumberInput({
        name: BelgiumMobileProductFieldNames.MESSAGES_LIMITATION,
        label: <LocalizedMessage id="messagesPerMonthLabel" />,
        numberType: NumberType.IntegerZeroOrGreater,
        helperText: <LocalizedMessage id="messagesPerMonthHelper" />,
        locale: locale,
      }),
    ],
  },
];
