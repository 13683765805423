import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { colors } from '@provider-portal/styles/colors';
import * as React from 'react';
import { WarningIcon } from '@provider-portal/assets/icons/WarningIcon';
import styles from './styles.scss';

export type IconVariant = 'warning';

function getColorStyle(variant: IconVariant): React.CSSProperties {
  switch (variant) {
    case 'warning':
      return { color: colors.accent.warning };
  }
}

function getIcon(variant: IconVariant) {
  switch (variant) {
    case 'warning':
      return <WarningIcon />;
  }
}

interface ITableIconColumnProps {
  value: React.ReactNode;
  variant: IconVariant;
}

export const TableIconCell: React.FunctionComponent<ITableIconColumnProps> = ({ value, variant }) => {
  return (
    <div className={styles.container}>
      {getIcon(variant)}
      <Body1 style={getColorStyle(variant)}>{value}</Body1>
    </div>
  );
};
