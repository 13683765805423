import { CampaignForm as UiFrameworkCampaignForm } from '@provider-portal/libs/uiFramework/components/CampaignForm';
import { selectorMarketFromMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';
import * as React from 'react';
import type { ICampaignFormData, ICampaignViewModel } from '../models';
import { useSelector } from 'react-redux';

/**
 * The CampaignForm is a wrapper for the ui-framework standard component.
 *
 * The CampaignForm component is used like a wrapper to provide a external
 * component where the associated config files used by the ui-framework
 * can be found.
 *
 * Changes to the CampaignForm should be put inside the config directory
 * found inside the CampaignForm directory.
 */
interface ICampaignFormProps {
  appBarTitle: React.ReactNode;
  formData: ICampaignViewModel;
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
  providerId: string;
  categoryName: string;
  onSubmit(formData: ICampaignFormData): Promise<void>;
}

export const CampaignForm: React.FunctionComponent<ICampaignFormProps> = ({
  appBarTitle,
  formData,
  leftSidedButtons,
  rightSidedButtons,
  providerId,
  categoryName,
  onSubmit,
}) => {
  const market = useSelector(selectorMarketFromMaybePartnerSupplier);
  return (
    <UiFrameworkCampaignForm
      appBarTitle={appBarTitle}
      formData={formData}
      leftSidedButtons={leftSidedButtons}
      rightSidedButtons={rightSidedButtons}
      providerId={providerId}
      categoryName={categoryName}
      onSubmit={onSubmit}
      market={market}
    />
  );
};
