import { LocalizedMessage } from '@provider-portal/internationalization';
import { CentesimalCostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CentesimalCostInput/config';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import {
  FieldArray,
  FieldArrayItemComponentType,
} from '@provider-portal/libs/uiFramework/components/fields/FieldArray/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import { RadioGroup } from '@provider-portal/libs/uiFramework/components/fields/RadioGroup/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { AccommodationType, ElectricityPriceType, EnergySource } from '@provider-portal/products/models';
import * as React from 'react';
import { campaignPaper, internalPaper, electricityNamingSection, termsPaper } from '../common';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select/config';
import type { ProductFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createProductFormLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { Currency } from '@provider-portal/models/subscription';
import { Locale } from '@provider-portal/internationalization/locale';

enum SwedenElectricityProductFieldNames {
  PRICE_TYPE = 'priceType',
  ELECTRICITY_AREA_1 = 'electricityArea1PricePerKwh',
  ELECTRICITY_AREA_2 = 'electricityArea2PricePerKwh',
  ELECTRICITY_AREA_3 = 'electricityArea3PricePerKwh',
  ELECTRICITY_AREA_4 = 'electricityArea4PricePerKwh',
  MONTHLY_COST = 'monthlyCost',
  COST = 'cost',
  LOWER_BOUND = 'lowerBound',
  UPPER_BOUND = 'upperBound',
  ENERGY_SOURCE = 'energySource',
  ACCOMMODATION_TYPE = 'accommodationType',
  FORM_TYPE = 'type',
}

const currency = Currency.SEK;
const locale = Locale.svSE;

const swedenElectricityPapers = (hasAdminAccess: boolean): IFormPagePaper[] => [
  {
    sections: [electricityNamingSection, ...swedenElectricitySections],
  },
  termsPaper(locale),
  campaignPaper,
  internalPaper(hasAdminAccess, currency, locale),
];

export const swedenElectricityLayoutMap: ProductFormLayoutMap = createProductFormLayout(
  categories.electricity,
  Market.Sweden,
  swedenElectricityPapers
);

const swedenElectricitySections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="priceTypeHeader" />,
    captionText: <LocalizedMessage id="electricityPriceCaptionText" />,
    columns: 4,
    components: [
      RadioGroup({
        name: SwedenElectricityProductFieldNames.PRICE_TYPE,
        options: [
          { value: ElectricityPriceType.Floating, label: <LocalizedMessage id="floatingPriceLabel" /> },
          { value: ElectricityPriceType.Fixed, label: <LocalizedMessage id="fixedPriceLabel" /> },
        ],
        required: true,
      }),
      HiddenInput({
        name: SwedenElectricityProductFieldNames.FORM_TYPE,
        defaultValue: 'SwedenElectricityProductIncomingRequest',
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="consumptionPriceHeader" />,
    tooltipTitle: <LocalizedMessage id="consumptionPriceHeader" />,
    tooltipText: <LocalizedMessage id="consumptionHeaderTooltiptext" />,
    columns: 4,
    components: [
      CentesimalCostInput({
        name: SwedenElectricityProductFieldNames.ELECTRICITY_AREA_1,
        label: <LocalizedMessage id="area1Label" />,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        name: SwedenElectricityProductFieldNames.ELECTRICITY_AREA_2,
        label: <LocalizedMessage id="area2Label" />,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        name: SwedenElectricityProductFieldNames.ELECTRICITY_AREA_3,
        label: <LocalizedMessage id="area3Label" />,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        name: SwedenElectricityProductFieldNames.ELECTRICITY_AREA_4,
        label: <LocalizedMessage id="area4Label" />,
        required: true,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="monthlyCostHeader" />,
    captionText: <LocalizedMessage id="monthlyCostDescription" />,
    tooltipTitle: <LocalizedMessage id="monthlyCostHeader" />,
    tooltipText: <LocalizedMessage id="electricityMonthlyCostHeaderTooltipText" />,
    columns: 1,
    components: [
      FieldArray({
        name: SwedenElectricityProductFieldNames.MONTHLY_COST,
        columns: 2,
        fields: [
          CostInput({
            name: SwedenElectricityProductFieldNames.COST,
            label: <LocalizedMessage id="monthlyCostElectricityLabelV3" />,
            required: true,
            currency: currency,
            locale: locale,
          }),
          NumberInput({
            name: SwedenElectricityProductFieldNames.LOWER_BOUND,
            label: <LocalizedMessage id="lowerBoundaryLabel" />,
            numberType: NumberType.IntegerZeroOrGreater,
            required: true,
            locale: locale,
          }),
          NumberInput({
            name: SwedenElectricityProductFieldNames.UPPER_BOUND,
            label: <LocalizedMessage id="upperBoundaryLabel" />,
            numberType: NumberType.IntegerZeroOrGreater,
            required: true,
            locale: locale,
          }),
        ],
        addItemButtonLabel: <LocalizedMessage id="addIntervalButtonLabel" />,
        itemComponent: {
          type: FieldArrayItemComponentType.FieldGroup1,
          title: <LocalizedMessage id="monthlyCostHeader" />,
          columns: 2,
        },
        required: true,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="energySourceHeader" />,
    tooltipTitle: <LocalizedMessage id="energySourceHeader" />,
    tooltipText: <LocalizedMessage id="electricitySourceTooltipText" />,
    columns: 4,
    components: [
      Select({
        name: SwedenElectricityProductFieldNames.ENERGY_SOURCE,
        options: [
          { value: EnergySource.Solar, label: <LocalizedMessage id="solarLabel" /> },
          { value: EnergySource.Water, label: <LocalizedMessage id="waterLabel" /> },
          { value: EnergySource.Wind, label: <LocalizedMessage id="windLabel" /> },
          { value: EnergySource.BioOrganic, label: <LocalizedMessage id="bioOrganicLabel" /> },
          { value: EnergySource.MixedRenewable, label: <LocalizedMessage id="mixedRenewableLabel" /> },
          { value: EnergySource.MixedNonRenewable, label: <LocalizedMessage id="mixedNonRenewableLabel" /> },
          { value: EnergySource.NuclearPower, label: <LocalizedMessage id="nuclearPowerLabel" /> },
        ],
        required: true,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="accommodationTypeHeader" />,
    captionText: <LocalizedMessage id="accommodationTooltipText" />,
    columns: 4,
    components: [
      Select({
        name: SwedenElectricityProductFieldNames.ACCOMMODATION_TYPE,
        options: [
          { value: AccommodationType.All, label: <LocalizedMessage id="accommodationTypeAllLabel" /> },
          { value: AccommodationType.Apartment, label: <LocalizedMessage id="accommodationTypeApartmentLabel" /> },
          { value: AccommodationType.Villa, label: <LocalizedMessage id="accommodationTypeVillaLabel" /> },
        ],
        required: true,
      }),
    ],
  },
];
