import AdminDrawerPage from '@provider-portal/libs/uiFramework/components/deprecated/AdminDrawerPage';
import { Menu } from '@provider-portal/libs/uiFramework/components/Menu';
import { ContactUsPage } from '@provider-portal/libs/uiFramework/components/pages/ContactUsPage';
import { DeveloperToolsMenuComponent } from '@provider-portal/menus/DeveloperToolsMenuComponent';
import { LinksMenuComponent } from '@provider-portal/menus/LinkMenuComponent';
import { WelcomeMenuComponent } from '@provider-portal/menus/WelcomeMenuComponent';
import { MatchedPartnerSupplierRoute } from '@provider-portal/PartnerSupplier/route';
import { SaveDeskMenuComponent } from '@provider-portal/saveDesk/SaveDeskMenuModule/SaveDeskMenuComponent';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AdminRoutes } from './admin-routes';
import { FeaturesMenuComponent } from '@provider-portal/menus/FeaturesMenuComponent';

interface LoggedInRoutesProps extends RouteComponentProps {
  hasAdminAccess: boolean;
  loggedInPartnerSupplierId?: string;
}

export const LoggedInRoutes: React.FunctionComponent<LoggedInRoutesProps> = ({
  hasAdminAccess,
  loggedInPartnerSupplierId,
  location,
}) => {
  return (
    <>
      {/* @ts-ignore */}
      <AdminDrawerPage
        //@ts-ignore
        menu={
          <Menu
            path={location.pathname}
            hasAdminAccess={hasAdminAccess}
            menuModules={[
              WelcomeMenuComponent,
              FeaturesMenuComponent,
              DeveloperToolsMenuComponent,
              LinksMenuComponent,
              SaveDeskMenuComponent,
            ]}
          />
        }
      >
        <Switch>
          <Route
            exact
            path={'/'}
            render={() =>
              (hasAdminAccess && <Redirect to={{ pathname: '/admin/suppliers' }} />) ||
              (loggedInPartnerSupplierId && (
                <Redirect to={{ pathname: `/suppliers/${loggedInPartnerSupplierId}` }} />
              )) || <Redirect to={{ pathname: '/login' }} />
            }
          />
          <Route path="/contact-us" render={() => <ContactUsPage />} />
          <Route
            path={'/admin'}
            render={(props) => (hasAdminAccess ? <AdminRoutes {...props} /> : <Redirect to={{ pathname: '/login' }} />)}
          />
          <Route
            path={'/suppliers/:providerId'}
            render={(props) => <MatchedPartnerSupplierRoute {...props} hasAdminAccess={hasAdminAccess} />}
          />
        </Switch>
      </AdminDrawerPage>
    </>
  );
};
