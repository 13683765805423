import { LocalizedMessage } from '@provider-portal/internationalization';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import { Image } from '@provider-portal/libs/uiFramework/components/fields/Image/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import { internalPaper, ProductFormFieldNames, termsPaper } from '@provider-portal/products/ProductForm/config/common';
import * as React from 'react';
import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { TranslatableFieldWidget } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/config';
import type { CategoryName } from '@provider-portal/constants/categories';
import { categories } from '@provider-portal/constants/categories';
import flatten from 'lodash/flatten';
import type { ProductFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createGenericProductFormLayoutMap } from '@provider-portal/libs/uiFramework/layouts/genericProductFormLayouts';
import type { Currency } from '@provider-portal/models/subscription';
import type { Locale } from '@provider-portal/internationalization/locale';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select/config';
import { CustomerSegment } from '@provider-portal/products/models';
import GenericProductExample from './genericProductExample.svg';

enum GenericProductFieldNames {
  STARTUP_COST = 'startupCost',
  SPOTLIGHT = 'spotlight',
  CUSTOMER_SEGMENT = 'customerSegment',
  SELLINGPOINT_1 = 'sellingPoint1',
  SELLINGPOINT_2 = 'sellingPoint2',
  SELLINGPOINT_3 = 'sellingPoint3',
  SELLINGPOINT_4 = 'sellingPoint4',
  SELLINGPOINT_5 = 'sellingPoint5',
  DISCLAIMER = 'disclaimer',
  FORM_TYPE = 'type',
  CATEGORY = 'category',
  MONTHLY_COST = 'monthlyCost',
  IMAGE = 'image',
}

const genericProductPapers =
  (categoryName: CategoryName, currency: Currency, locale: Locale) =>
  // eslint-disable-next-line react/display-name
  (hasAdminAccess: boolean): IFormPagePaper[] =>
    [
      {
        sections: [
          {
            caption: <LocalizedMessage id="productSectionHeader" />,
            columns: 1,
            divider: 'bottom',
            components: [
              HiddenInput({
                name: GenericProductFieldNames.FORM_TYPE,
                defaultValue: 'GenericProductIncomingRequest',
              }),
              HiddenInput({
                name: GenericProductFieldNames.CATEGORY,
                defaultValue: categoryName,
              }),
              TextInput({
                name: ProductFormFieldNames.NAME,
                label: <LocalizedMessage id="nameLabel" />,
                helperText: <LocalizedMessage id="productNameHelpText" />,
                tooltipTitle: <LocalizedMessage id="nameLabel" />,
                tooltipText: <LocalizedMessage id="genericProductTooltipText" />,
                required: true,
                maxlength: 30,
              }),
              TextInput({
                name: ProductFormFieldNames.EXTERNAL_PRODUCT_ID,
                label: <LocalizedMessage id="idLabel" />,
                tooltipTitle: <LocalizedMessage id="idLabel" />,
                tooltipText: <LocalizedMessage id="productIdTooltipText" />,
              }),
            ],
          },
          {
            caption: <LocalizedMessage id="pricingSectionHeader" />,
            columns: 2,
            components: [
              CostInput({
                name: GenericProductFieldNames.MONTHLY_COST,
                label: <LocalizedMessage id="monthlyCostHeader" />,
                tooltipTitle: <LocalizedMessage id="monthlyCostHeader" />,
                tooltipText: <LocalizedMessage id="monthlyCostTooltipText" />,
                required: true,
                currency: currency,
                locale: locale,
              }),
              CostInput({
                name: GenericProductFieldNames.STARTUP_COST,
                label: <LocalizedMessage id="startupFeeLabel2" />,
                tooltipTitle: <LocalizedMessage id="startupFeeLabel3" />,
                tooltipText: <LocalizedMessage id="startupFeeTooltipText" />,
                currency: currency,
                locale: locale,
              }),
            ],
          },
          {
            caption: <LocalizedMessage id="customerSegmentHeader" />,
            captionText: <LocalizedMessage id="customerSegmentTooltipText" />,
            columns: 4,
            components: [
              Select({
                name: GenericProductFieldNames.CUSTOMER_SEGMENT,
                options: [
                  { value: CustomerSegment.All, label: <LocalizedMessage id="customerSegmentAllLabel" /> },
                  { value: CustomerSegment.Student, label: <LocalizedMessage id="customerSegmentStudentLabel" /> },
                  { value: CustomerSegment.Senior, label: <LocalizedMessage id="customerSegmentSeniorLabel" /> },
                ],
                required: true,
                defaultValue: CustomerSegment.All,
              }),
            ],
          },
        ],
        column: 1,
      },
      {
        sections: [
          {
            caption: <LocalizedMessage id="describeProductTitle" />,
            captionText: <LocalizedMessage id="describeProductDescription" />,
            tooltipTitle: <LocalizedMessage id="describeProductTitle" />,
            tooltipText: <LocalizedMessage id="describeProductTooltipText" />,
            columns: 1,
            divider: 'bottom',
            components: [
              TranslatableFieldWidget({
                name: GenericProductFieldNames.SELLINGPOINT_1,
                title: <LocalizedMessage id="sellingPointTitle" values={{ number: 1 }} />,
                required: true,
              }),
              TranslatableFieldWidget({
                name: GenericProductFieldNames.SELLINGPOINT_2,
                title: <LocalizedMessage id="sellingPointTitle" values={{ number: 2 }} />,
              }),
              TranslatableFieldWidget({
                name: GenericProductFieldNames.SELLINGPOINT_3,
                title: <LocalizedMessage id="sellingPointTitle" values={{ number: 3 }} />,
              }),
              TranslatableFieldWidget({
                name: GenericProductFieldNames.SELLINGPOINT_4,
                title: <LocalizedMessage id="sellingPointTitle" values={{ number: 4 }} />,
              }),
              TranslatableFieldWidget({
                name: GenericProductFieldNames.SELLINGPOINT_5,
                title: <LocalizedMessage id="sellingPointTitle" values={{ number: 5 }} />,
              }),
            ],
          },
          {
            caption: <LocalizedMessage id="spotlightTitle" />,
            captionText: <LocalizedMessage id="spotlightDescription" />,
            columns: 1,
            divider: 'bottom',
            components: [
              TranslatableFieldWidget({
                name: GenericProductFieldNames.SPOTLIGHT,
                title: <LocalizedMessage id="spotlightTitle" />,
                multiline: true,
              }),
            ],
          },
          {
            caption: <LocalizedMessage id="disclaimerTitle" />,
            captionText: <LocalizedMessage id="disclaimerDescription" />,
            columns: 1,
            components: [
              TranslatableFieldWidget({
                name: GenericProductFieldNames.DISCLAIMER,
                title: <LocalizedMessage id="disclaimerTitle" />,
                multiline: true,
              }),
            ],
          },
        ],
        column: 1,
      },
      {
        sections: [
          {
            caption: <LocalizedMessage id="pageExampleTitle" />,
            captionText: <LocalizedMessage id="pageExampleDescription" />,
            columns: 1,
            divider: 'bottom',
            components: [
              Image({
                name: GenericProductFieldNames.IMAGE,
                src: GenericProductExample,
                alt: 'example',
              }),
            ],
          },
        ],
        column: 2,
      },
      termsPaper(locale, 2),
      internalPaper(hasAdminAccess, currency, locale, 2),
    ];

export const genericProductFormLayoutMaps: ProductFormLayoutMap[] = flatten(
  Object.values(categories).map((c) => {
    const categoryName = c.key as CategoryName;

    return createGenericProductFormLayoutMap(categoryName, genericProductPapers);
  })
);
