import { LocalizedMessage } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import React from 'react';
import { ChangePlanOffer2FormAction } from '../models';

interface IPublishButtonProps {
  onClick(submitType: ChangePlanOffer2FormAction): void;
  hasAdminAccess: boolean;
}

export const PublishButton: React.FunctionComponent<IPublishButtonProps> = ({ onClick, hasAdminAccess }) => {
  return (
    <Button
      label={<LocalizedMessage id={hasAdminAccess ? 'publishButton' : 'saveAndRequestApprovalButton'} />}
      color="positive"
      type="submit"
      onClick={() => onClick(ChangePlanOffer2FormAction.SAVE_AND_REQUEST_APPROVAL)}
    />
  );
};
