import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { TabbedTableOverviewPage } from '@provider-portal/libs/uiFramework/components/pages/TabbedTableOverviewPage';
import { useTabs } from '@provider-portal/libs/hooks/use-tabs';
import * as React from 'react';
import { useFetchOrdersOnEnter } from './use-fetch';
import { useMapProps, useMapStateToProps } from './use-props';

export interface ISaveDeskListOrdersPageProps {
  partnerSupplierId: string;
}

const SaveDeskListOrdersPageInner: React.FunctionComponent<ISaveDeskListOrdersPageProps> = ({ partnerSupplierId }) => {
  const stateProps = useMapStateToProps();
  const [selectedTab, onSwitchTab] = useTabs(stateProps.tabsConfig);
  const loadingState = useFetchOrdersOnEnter(partnerSupplierId);
  const mappedProps = useMapProps(stateProps, selectedTab);

  return (
    <LoadingPage loadingState={loadingState}>
      <TabbedTableOverviewPage
        appBarText={<LocalizedMessage id="saveDeskListOrdersPageTitle" />}
        tabs={stateProps.tabsConfig}
        table={mappedProps.tableConfig}
        data={mappedProps.tableData}
        selectedTab={selectedTab}
        onSwitchTab={onSwitchTab}
        onRowClick={mappedProps.onSaveDeskOrderClick}
      />
    </LoadingPage>
  );
};

export const SaveDeskListOrdersPage: React.ComponentType<ISaveDeskListOrdersPageProps> =
  withLocalization('supplier-portal-save-desk')(SaveDeskListOrdersPageInner);
