import type { Messages } from '@provider-portal/internationalization/debugLocale';
import { loadMessagesAndLocaleProviderPortal } from '@provider-portal/internationalization/debugLocale';
import type { Fetchable } from '@provider-portal/models/Fetchable';
import { fetchableHandle, FetchableNotStarted, mapSuccess } from '@provider-portal/models/Fetchable';
import type { ActionsUnion } from '@provider-portal/utils/redux';
import { createPromiseAction } from '@provider-portal/utils/redux';
import type { Locale } from '@provider-portal/internationalization/locale';
import type { Market } from '@provider-portal/app-context/constants';

export interface InternationalizationState {
  messages: Fetchable<Messages>;
  locale: Fetchable<Locale>;
}

const initialState: InternationalizationState = {
  messages: FetchableNotStarted,
  locale: FetchableNotStarted,
};

export enum ActionKeys {
  FETCH_MESSAGES_AND_LOCALE = 'INTERNATIONALIZATION/FETCH_MESSAGES_AND_LOCALE',
}

export const InternationalizationActions = {
  fetchMessagesAndLocaleProviderPortal: (locale: Locale, market: Market) =>
    createPromiseAction(ActionKeys.FETCH_MESSAGES_AND_LOCALE, loadMessagesAndLocaleProviderPortal(locale, market)),
};

type Actions = ActionsUnion<typeof InternationalizationActions>;

export function reducer(state: InternationalizationState = initialState, action: Actions): InternationalizationState {
  switch (action.type) {
    case ActionKeys.FETCH_MESSAGES_AND_LOCALE:
      return fetchableHandle(state, action, (fetchable) => ({
        ...state,
        messages: mapSuccess(fetchable, (d) => d.messages),
        locale: mapSuccess(fetchable, (d) => d.locale),
      }));

    default:
      return state;
  }
}

interface State {
  internationalization: InternationalizationState;
}

export const selectorMessagesFetchable = (state: State) => state.internationalization.messages;
export const selectorLocaleFetchable = (state: State) => state.internationalization.locale;
