import { parseForm } from '@provider-portal/libs/uiFramework/components/fields/configs/parse-fields';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import { FormPage } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import { selectorHasInternalAdminAccess } from '@provider-portal/login/duck';
import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IProductFormData, IProductViewModel } from '@provider-portal/products/models';
import { uiFramework } from '../../init';
import type { Market } from '@provider-portal/app-context/constants';
import type { CategoryName } from '@provider-portal/constants/categories';
import { verticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';
import { enrichRequestWithFormData } from '../../enrichRequest';

interface IProductFormProps {
  appBarTitle: React.ReactNode;
  formData: IProductViewModel;
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
  categoryName: string;
  onSubmit(formData: IProductFormData): Promise<void>;
  providerId: string;
  market: Market;
}

export const ProductForm: React.FunctionComponent<IProductFormProps> = ({
  appBarTitle,
  formData,
  leftSidedButtons,
  rightSidedButtons,
  categoryName,
  onSubmit,
  providerId,
  market,
}) => {
  const hasAdminAccess = useSelector(selectorHasInternalAdminAccess);

  return (
    <FormPage
      appBarText={appBarTitle}
      papers={getProductFormLayout(providerId, market, categoryName, hasAdminAccess, formData)}
      onSubmit={async (event) => onSubmit(parseForm(event.currentTarget))}
      leftSidedButtons={leftSidedButtons}
      rightSidedButtons={rightSidedButtons}
    />
  );
};

export function getProductFormLayout(
  providerId: string,
  market: Market,
  categoryName: string,
  hasAdminAccess: boolean,
  formData: IProductViewModel
): IFormPagePaper[] {
  const papersFunc = uiFramework.layouts.productFormLayouts.get(
    verticalMarketKey(categoryName as CategoryName, market)
  );
  if (!papersFunc) {
    throw new Error(`No form configuration supported for market ${market} and category ${categoryName}`);
  }

  return papersFunc(hasAdminAccess).map((paper) => ({
    sections: paper.sections.map((section) => ({
      ...section,
      components: section.components.map(enrichRequestWithFormData(providerId, categoryName, formData)),
    })),
    column: paper.column,
    row: paper.row,
  }));
}
