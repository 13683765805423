import type { AppContext } from '@provider-portal/app-context';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { useTabs } from '@provider-portal/libs/hooks/use-tabs';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { TabbedTableOverviewPage } from '@provider-portal/libs/uiFramework/components/pages/TabbedTableOverviewPage';
import type { ISaveDeskOffer2ListViewModel } from '@provider-portal/saveDesk/models';
import { isOfferActive, isOfferDraft } from '@provider-portal/saveDesk/models';
import { useFetchOffersOnEnter } from '@provider-portal/saveDesk/SaveDeskListOffersPage/use-fetch';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { getTabsConfig, SaveDeskOffersListTab, saveDeskOffersListTableConfig } from './config';

export interface ISaveDeskListOffersPageProps {
  partnerSupplierId: string;
}

export const SaveDeskListOffersPageInner: React.FunctionComponent<
  ISaveDeskListOffersPageProps & { appContext: AppContext }
> = ({ partnerSupplierId }) => {
  const history = useHistory();
  const { loadingState, saveDeskOffers } = useFetchOffersOnEnter(partnerSupplierId);
  const [approvedPublishedOffers, draftOffers] = groupApprovedPublishedAndDraftOffers(saveDeskOffers);
  const tabsConfig = getTabsConfig(approvedPublishedOffers.length, draftOffers.length);
  const [selectedTab, onSwitchTab] = useTabs(tabsConfig);

  return (
    <LoadingPage loadingState={loadingState}>
      <TabbedTableOverviewPage
        appBarText={<LocalizedMessage id="saveDeskOffersTitle" />}
        tabs={tabsConfig}
        table={saveDeskOffersListTableConfig}
        data={selectedTab === SaveDeskOffersListTab.APPROVED ? approvedPublishedOffers : draftOffers}
        selectedTab={selectedTab}
        onSwitchTab={onSwitchTab}
        onRowClick={onSaveDeskOfferClick}
        onAdd={onAdd}
      />
    </LoadingPage>
  );

  function onSaveDeskOfferClick(saveDeskOffer: ISaveDeskOffer2ListViewModel) {
    const editOfferUrl = URI(history.location.pathname).segment(saveDeskOffer.id).toString();
    history.push(editOfferUrl);
  }

  function onAdd() {
    const addOfferUrl = URI(history.location.pathname).segment('add').toString();
    history.push(addOfferUrl);
  }
};

export const SaveDeskListOffersPage: React.ComponentType<ISaveDeskListOffersPageProps> =
  withLocalization('supplier-portal-save-desk')(SaveDeskListOffersPageInner);

function groupApprovedPublishedAndDraftOffers(saveDeskOffers: ISaveDeskOffer2ListViewModel[]) {
  return [
    saveDeskOffers.filter((offer) => isOfferActive(offer.offerState)),
    saveDeskOffers.filter((offer) => isOfferDraft(offer.offerState)),
  ];
}
