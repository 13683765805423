import { AddProduct } from '@provider-portal/products/AddProduct';
import { EditProduct } from '@provider-portal/products/EditProduct';
import { ProductsList } from '@provider-portal/products/ProductsList';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

export const ProductsRoute: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/add`}
        render={(props) => (
          <AddProduct
            // @ts-ignore
            providerId={props.match.params.providerId}
            // @ts-ignore
            categoryName={props.match.params.verticalCategoryName}
          />
        )}
      />
      <Route
        path={`${match.path}/:partnerProductId`}
        render={(props) => (
          <EditProduct
            // @ts-ignore
            providerId={props.match.params.providerId}
            productId={props.match.params.partnerProductId}
            // @ts-ignore
            categoryName={props.match.params.verticalCategoryName}
          />
        )}
      />
      <Route
        path={`${match.path}`}
        render={(props) => (
          <ProductsList
            // @ts-ignore
            partnerSupplierId={props.match.params.providerId}
            // @ts-ignore
            category={props.match.params.verticalCategoryName}
          />
        )}
      />
    </Switch>
  );
};
