import { Market } from '@provider-portal/app-context/constants';
import { useLocation } from 'react-router-dom';
import { Sentry } from '@provider-portal/sentry';

/**
 * Find the current market from a given query parameter
 *
 * @param reportMissing If true, report to Sentry when the market
 * parameter is missing. Defaults to true. Set this to false if a missing
 * market parameter is not indicative of an error in your use case
 * @returns The indicated market, or undefined if none was found
 */
export function useMarketFromQueryParam(reportMissing: boolean = true): Market | undefined {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const marketQueryParam = query.get('market');
  if (
    marketQueryParam &&
    Object.values(Market)
      .map((m) => m.toString())
      .includes(marketQueryParam)
  ) {
    return marketQueryParam as Market;
  } else {
    if (reportMissing) {
      Sentry.captureMessage('Expected market query parameter not found');
    }
    return undefined;
  }
}
