import { LocalizedMessage } from '@provider-portal/internationalization';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { ResponseType } from '@provider-portal/orders/switch/models';
import Grid from '@material-ui/core/Grid';
import { RadioButton } from '@provider-portal/libs/uiFramework/components/deprecated/RadioButton';
import * as React from 'react';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput';

export interface IResponseRadioButtonsProps {
  selectedResponseType: ResponseType;
  onChange(selectedResponseType: ResponseType): void;
}

export const ResponseRadioButtons: React.FunctionComponent<IResponseRadioButtonsProps> = ({
  selectedResponseType,
  onChange,
}) => {
  const responseTypeChanged = (event: any) => onChange(event.target.value);

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <RadioButton
            checked={selectedResponseType === ResponseType.Accepted}
            value={ResponseType.Accepted}
            label={
              <Body>
                <LocalizedMessage id="acceptOrderRadioButton" />
              </Body>
            }
            onChange={responseTypeChanged}
          />
        </Grid>
        <Grid item xs={4}>
          <RadioButton
            checked={selectedResponseType === ResponseType.Rejected}
            value={ResponseType.Rejected}
            label={
              <Body>
                <LocalizedMessage id="rejectOrderRadioButton" />
              </Body>
            }
            onChange={responseTypeChanged}
          />
        </Grid>
      </Grid>
      <HiddenInput name="responseType" value={selectedResponseType} />
    </>
  );
};
