import React from 'react';
import type { IconProps } from '../icons';
import { SvgIcon } from '../icons';

export const ChevronRightIcon: React.ComponentType<IconProps> = ({ nativeColor, ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6793 12L8.97713 3.53769C8.68018 3.24892 8.67354 2.77409 8.96231 2.47713C9.25108 2.18018 9.72591 2.17354 10.0229 2.46231L18.841 11.0374L18.8418 11.0382C19.3859 11.5664 19.3857 12.433 18.8428 12.9609L10.0229 21.5377C9.72591 21.8265 9.25108 21.8198 8.96231 21.5229C8.67354 21.2259 8.68018 20.7511 8.97713 20.4623L17.6793 12Z"
      fill={nativeColor}
    />
  </SvgIcon>
);
