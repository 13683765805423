import { LocalizedMessage } from '@provider-portal/internationalization';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import * as React from 'react';
import type { ISaveDeskOrder } from '@provider-portal/saveDesk/models';
import { ConfirmOrderSection } from './components/ConfirmOrderSection';

export interface SaveDeskResponseCardProps {
  saveDeskOrder: ISaveDeskOrder;
}

export const SaveDeskResponseCardComponent: React.FunctionComponent<SaveDeskResponseCardProps> = ({
  saveDeskOrder,
}) => {
  return (
    <Paper title={<LocalizedMessage id="saveDeskOrderPageResponseTitle" />}>
      <ConfirmOrderSection saveDeskOrder={saveDeskOrder} />
    </Paper>
  );
};
