import * as React from 'react';
import styles from './styles.scss';
import { ExportButton } from '@provider-portal/orders/switch/ListSwitchOrders/ExportButton';
import type { ISwitchOrderFilter } from '@provider-portal/orders/switch/ListSwitchOrders/SwitchOrderFilter';
import { DateFilterButton } from '@provider-portal/orders/switch/ListSwitchOrders/DateFilterButton';
import { FiltersButton } from '@provider-portal/orders/switch/ListSwitchOrders/FiltersButton';
import type { ISwitchOrder } from '@provider-portal/orders/switch/models';

interface ITableControlsProps {
  onExport(): void;
  allOrders: ISwitchOrder[];
  switchOrderFilter: ISwitchOrderFilter;
}

export const TableControls: React.FunctionComponent<ITableControlsProps> = ({
  onExport,
  allOrders,
  switchOrderFilter,
}) => {
  const allOrdersCount = allOrders.length;
  const visibleOrderCount = allOrdersCount - switchOrderFilter.ordersFilteredOutCount(allOrders);

  return (
    <div className={styles.tableControls}>
      <DateFilterButton
        disabled={allOrdersCount === 0}
        activeDateFilterOption={switchOrderFilter.getActiveDateFilter()}
        onSelectDateFilterOption={switchOrderFilter.setDateFilter}
      />
      <FiltersButton
        disabled={allOrdersCount === 0}
        filterIsChangedFromDefault={switchOrderFilter.orderFilterIsChangedFromDefault()}
        selectedOrderStates={switchOrderFilter.getSelectedOrderStates()}
        onSelectedOrderStatesChange={switchOrderFilter.setStateFilter}
      />
      {visibleOrderCount > 0 && <ExportButton orderCount={visibleOrderCount} onClick={onExport} />}
    </div>
  );
};
