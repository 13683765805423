import { selectorLoggedInUserLocale } from '@provider-portal/login/duck';
import React from 'react';
import { useSelector } from 'react-redux';
import { DatePicker } from './component';

const DatePickerContainer = (props: any) => {
  const locale = useSelector(selectorLoggedInUserLocale);
  return <DatePicker {...props} locale={locale} />;
};

export { DatePickerContainer as DatePicker };
