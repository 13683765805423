import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import * as React from 'react';
import { FileType } from '../../fields/file/models';
import { MultipleFileInput } from '../../fields/file/MultipleFileInput';
import { uploadTermsAndConditions } from './api';
import type { ITermsAndConditionsUploadWidget } from './config';

type ITermsAndConditionsUploadProps = Omit<ITermsAndConditionsUploadWidget, 'type'>;

const TermsAndConditionsUploadInner: React.FunctionComponent<ITermsAndConditionsUploadProps> = ({
  providerId,
  termsAndConditions,
  name,
}) => {
  return (
    <MultipleFileInput
      name={name}
      defaultValue={termsAndConditions}
      onUpload={async (file) => uploadTermsAndConditions(providerId, file)}
      label={<LocalizedMessage id="termsAndConditionsLabel" />}
      helperText={<LocalizedMessage id="termsAndConditionsHelperText" />}
      allowedFileTypes={[FileType.PDF]}
    />
  );
};

export const TermsAndConditionsUpload = withLocalization('TermsAndConditionsUpload')(TermsAndConditionsUploadInner);
