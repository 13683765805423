import type { IOption } from './option';

export function sortLexicographically(options: IOption[]) {
  return [...options].sort((a, b) => {
    const bLabel = b.label || '';
    const aLabel = a.label || '';
    if (aLabel > bLabel) {
      return 1;
    } else if (aLabel < bLabel) {
      return -1;
    } else {
      return 0;
    }
  });
}
