import { LocalizedMessage } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import {
  approveChangePlanOffer,
  archiveChangePlanOffer,
  unpublishChangePlanOffer,
  updateChangePlanOffer,
} from '@provider-portal/features/changePlan/api';
import { ChangePlanOfferSnackbarState } from '@provider-portal/features/changePlan/snackbar-state';
import { Sentry } from '@provider-portal/sentry';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import { debouncePromise, debouncePromiseWithDelay } from '@provider-portal/utils/debounce-promise';
import compact from 'lodash/compact';
import React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import type { IChangePlan2FormDataInner, IChangePlan2FormViewModel } from '../models';
import { isOfferActive, isOfferDraft } from '../models';
import { ChangePlanOffer2FormAction, ChangePlanOffer2State } from '../models';
import { ChangePlanForm } from '../ChangePlanForm';
import { PublishButton } from './PublishButton';
import { convertFormDataToApiREquestBody } from '../utils';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { PageAppBar } from '../components/PageAppBar';

interface IEditChangePlanOfferComponentProps {
  providerId: string;
  changePlanOfferId: string;
  viewModel: IChangePlan2FormViewModel;
  setViewModel(viewModel: IChangePlan2FormViewModel): void;
  setSnackbarState(state: ChangePlanOfferSnackbarState): void;
  setLoadingState(state: PortalLoadingState): void;
  hasAdminAccess: boolean;
  actionType: ChangePlanOffer2FormAction;
  setActionType(actionType: ChangePlanOffer2FormAction): void;
}

export const EditChangePlanOfferComponent: React.FunctionComponent<IEditChangePlanOfferComponentProps> = ({
  providerId,
  changePlanOfferId,
  viewModel,
  setViewModel,
  setSnackbarState,
  setLoadingState,
  hasAdminAccess,
  actionType,
  setActionType,
}) => {
  const history = useHistory();
  const offerIsDraft = isOfferDraft(viewModel.offerState);
  const offerIsPublished = isOfferActive(viewModel.offerState);
  const showSaveDraftButton =
    viewModel.offerState === ChangePlanOffer2State.Draft ||
    (viewModel.offerState === ChangePlanOffer2State.PendingApproval && hasAdminAccess);

  const editChangePlanOfferUrl = (changePlanOfferIdToNavigateTo?: string) => {
    return URI(history.location.pathname)
      .segment(-1, changePlanOfferIdToNavigateTo || '')
      .toString();
  };

  return (
    <Page appBarText={<PageAppBar />} variant="details" showMenuForSmallerScreen showBackButton={false}>
      <ChangePlanForm
        appBarTitle={<LocalizedMessage id="editChangePlanOfferTitleBar" />}
        leftSidedButtons={
          offerIsDraft && (
            <Button
              key="delete"
              label={<LocalizedMessage id="archiveButton" />}
              color="negative"
              onClick={debouncePromise(onArchive)}
            />
          )
        }
        rightSidedButtons={compact([
          showSaveDraftButton && (
            <Button
              key="save-draft"
              label={<LocalizedMessage id="saveDraftButton" />}
              color="secondary"
              type="submit"
              onClick={() => setActionType(ChangePlanOffer2FormAction.SAVE)}
            />
          ),
          offerIsPublished && (
            <Button
              key="unpublish"
              label={<LocalizedMessage id="unpublishButton" />}
              color="negative"
              onClick={debouncePromise(onUnpublish)}
            />
          ),
          <PublishButton
            key="publish"
            onClick={setActionType}
            hasAdminAccess={hasAdminAccess}
            offerState={viewModel.offerState}
          />,
        ])}
        onSubmit={debouncePromiseWithDelay(2000, onSave)}
        providerId={providerId}
        viewModel={viewModel}
      />
    </Page>
  );

  async function onSave(viewModel: IChangePlan2FormDataInner) {
    const formData = convertFormDataToApiREquestBody(viewModel, actionType);

    try {
      let updatedOffer = await updateChangePlanOffer(providerId, changePlanOfferId, formData);
      if (actionType === ChangePlanOffer2FormAction.SAVE_AND_REQUEST_APPROVAL && hasAdminAccess) {
        updatedOffer = await approveChangePlanOffer(providerId, updatedOffer.id);
      }
      if (updatedOffer.id !== changePlanOfferId) {
        history.replace(editChangePlanOfferUrl(updatedOffer.id));
      }

      setViewModel({
        id: updatedOffer.id,
        name: updatedOffer.name,
        description: updatedOffer.description,
        price: updatedOffer.price,
        paymentInterval: updatedOffer.paymentInterval,
        planId: updatedOffer.planId,
        offerState: updatedOffer.offerState,
      });

      setSnackbarState(ChangePlanOfferSnackbarState.OFFER_UPDATED);
    } catch (error) {
      setLoadingState(PortalLoadingState.Failure);
      Sentry.captureExceptionWithMessage(error, 'Failed to update change plan offer');
    }
  }
  async function onUnpublish() {
    unpublishChangePlanOffer(providerId, changePlanOfferId)
      .then((updatedOffer) => {
        history.replace(editChangePlanOfferUrl(updatedOffer.id));

        setViewModel(updatedOffer);

        setSnackbarState(ChangePlanOfferSnackbarState.OFFER_UNPUBLISHED);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to unpublish change plan offer');
      });
  }

  async function onArchive() {
    archiveChangePlanOffer(providerId, changePlanOfferId)
      .then(() => {
        const listChangePlanOffersUrl = URI(history.location.pathname).segment(-1, '').toString();
        history.replace(listChangePlanOffersUrl);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to delete change plan offer');
      });
  }
};
