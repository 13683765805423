import type { LocalizeMessage } from '@provider-portal/internationalization';
import { LocalizedMessage } from '@provider-portal/internationalization';
import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import type { ITabConfig } from '@provider-portal/libs/uiFramework/components/Tabs/config';
import React from 'react';
import type { PaymentInterval } from '@provider-portal/features/changePlan/models';
import { intervalToString } from '@provider-portal/features/changePlan/models';
import type { CostWithCurrency } from '@provider-portal/models/currency';

export enum ChangePlanOffersListTab {
  APPROVED = 'approved',
  DRAFTS = 'drafts',
}

export const changePlanOffersListTableConfig = (localizeMessage: LocalizeMessage): ITableConfig => {
  return {
    cells: [
      {
        key: 'name',
        label: localizeMessage('planNameColumnlabel'),
      },
      {
        key: 'price',
        label: localizeMessage('planPriceColumnlabel'),
        renderCellValue(value: CostWithCurrency) {
          return <p>{value.amount}</p>;
        },
      },
      {
        key: 'paymentInterval',
        label: localizeMessage('planFrequenceColumnlabel'),
        renderCellValue(value: PaymentInterval) {
          return <p>{intervalToString(localizeMessage)(value)}</p>;
        },
      },
    ],
    defaultSortColumn: 'name',
  };
};

export const getTabsConfig = (
  activeChangePlanOffersCount: number,
  savedDraftsCount: number
): ITabConfig<ChangePlanOffersListTab>[] => [
  {
    key: ChangePlanOffersListTab.APPROVED,
    label: <LocalizedMessage id="activeChangePlanOffers" values={{ activeChangePlanOffersCount }} />,
  },
  {
    key: ChangePlanOffersListTab.DRAFTS,
    label: <LocalizedMessage id="savedDrafts" values={{ savedDraftsCount }} />,
  },
];
