import { LocalizedMessage } from '@provider-portal/internationalization';
import { Checkbox } from '@provider-portal/libs/uiFramework/components/fields/Checkbox/config';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import * as React from 'react';
import { campaignPaper, internalPaper, mobileNamingSection, termsPaper } from '../common';
import type { ProductFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createProductFormLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { Currency } from '@provider-portal/models/subscription';
import { Locale } from '@provider-portal/internationalization/locale';

enum DenmarkMobileProductFieldNames {
  MONTHLY_COST = 'monthlyCost',
  STARTUP_FEE = 'startupFee',
  DATA_CAPACITY_IN_GB = 'dataCapacityInGb',
  EU_DATA_CAPACITY_IN_GB = 'euDataCapacityInGb',
  MESSAGES_LIMITATION = 'messagesLimitation',
  LIMITED_TIME_IN_HOURS = 'limitedTimeInHours',
  EU_CALLS_INCLUDED = 'euCallsIncluded',
  FORM_TYPE = 'type',
}

const currency = Currency.DKK;
const locale = Locale.daDK;

const denmarkMobilePapers = (hasAdminAccess: boolean): IFormPagePaper[] => [
  {
    sections: [mobileNamingSection, ...denmarkMobileSections],
  },
  termsPaper(locale),
  campaignPaper,
  internalPaper(hasAdminAccess, currency, locale),
];

export const denmarkMobileLayoutMap: ProductFormLayoutMap = createProductFormLayout(
  categories.mobile,
  Market.Denmark,
  denmarkMobilePapers
);

const denmarkMobileSections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="pricingSectionHeader" />,
    columns: 4,
    components: [
      CostInput({
        name: DenmarkMobileProductFieldNames.MONTHLY_COST,
        label: <LocalizedMessage id="monthlyCostHeader" />,
        tooltipTitle: <LocalizedMessage id="monthlyCostHeader" />,
        tooltipText: <LocalizedMessage id="monthlyCostTooltipText" />,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CostInput({
        name: DenmarkMobileProductFieldNames.STARTUP_FEE,
        label: <LocalizedMessage id="startupFeeLabel3" />,
        tooltipTitle: <LocalizedMessage id="startupFeeLabel3" />,
        tooltipText: <LocalizedMessage id="startupFeeTooltipText" />,
        currency: currency,
        locale: locale,
      }),
      HiddenInput({
        name: DenmarkMobileProductFieldNames.FORM_TYPE,
        defaultValue: 'DenmarkMobileProductIncomingRequest',
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="phoneCallsHeader" />,
    captionText: <LocalizedMessage id="phoneCallsCaptionText" />,
    divider: 'top',
    columns: 4,
    components: [
      NumberInput({
        name: DenmarkMobileProductFieldNames.LIMITED_TIME_IN_HOURS,
        label: <LocalizedMessage id="callHoursPerMonthLabel" />,
        numberType: NumberType.IntegerZeroOrGreater,
        helperText: <LocalizedMessage id="callHoursPerMonthHelper" />,
        locale: locale,
      }),
      Checkbox({
        name: DenmarkMobileProductFieldNames.EU_CALLS_INCLUDED,
        label: <LocalizedMessage id="euCallsLabel" />,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="dataPlanHeader" />,
    captionText: <LocalizedMessage id="dataCaptionText" />,
    divider: 'top',
    columns: 4,
    components: [
      NumberInput({
        name: DenmarkMobileProductFieldNames.DATA_CAPACITY_IN_GB,
        label: <LocalizedMessage id="dataLabel" />,
        numberType: NumberType.FloatZeroOrGreater,
        helperText: <LocalizedMessage id="dataCapacityHelper" />,
        locale: locale,
      }),
      NumberInput({
        name: DenmarkMobileProductFieldNames.EU_DATA_CAPACITY_IN_GB,
        label: <LocalizedMessage id="euDataLabel" />,
        numberType: NumberType.FloatZeroOrGreater,
        locale: locale,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="textMessagesHeader" />,
    captionText: <LocalizedMessage id="textCaptionText" />,
    divider: 'top',
    columns: 4,
    components: [
      NumberInput({
        name: DenmarkMobileProductFieldNames.MESSAGES_LIMITATION,
        label: <LocalizedMessage id="messagesPerMonthLabel" />,
        numberType: NumberType.IntegerZeroOrGreater,
        helperText: <LocalizedMessage id="messagesPerMonthHelper" />,
        locale: locale,
      }),
    ],
  },
];
