import type { AmountWithCurrency } from '@provider-portal/models/subscription';
import type { IUploadFile } from '../../fields/file/models';
import type { SwedenPhoneCallsLimitationType } from '../SwedenPhoneCallsLimitationInput/config';
import type React from 'react';
import type { Market } from '@provider-portal/app-context/constants';

export enum WidgetType {
  TRANSLATABLE_FIELD = 'translatableField',
  CAMPAIGN_ID_SELECT = 'campaignIdSelect',
  SWEDEN_PHONE_CALLS_LIMITATION_INPUT = 'swedenPhoneCallsLimitationInput',
  TERMS_AND_CONDITIONS_UPLOAD = 'termsAndConditionsUpload',
  MONTHLY_DISCOUNT = 'monthlyDiscount',
  MONTHLY_DATA_CAMPAIGN = 'monthlyDataCampaign',
}

export interface IWidget {
  type: WidgetType;
  name?: string;
  providerId?: string;
  categoryName?: string;
  campaignId?: string;
  limitationType?: SwedenPhoneCallsLimitationType;
  limitedTimeInMinutes?: number;
  limitedAmountOfCalls?: number;
  termsAndConditions?: IUploadFile[];
  monthlyDiscountAmount?: AmountWithCurrency;
  monthlyDiscountInPercent?: number;
  monthlyDiscountLength?: number;
  monthlyDataAmountInGb?: number;
  monthlyDataLength?: number;
  title?: React.ReactNode;
  description?: React.ReactNode;
  required?: boolean;
  multiline?: boolean;
  removeButton?: React.ReactElement;
  defaultValue?: any;
  market?: Market;
}
