import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { SupplierWithService } from '../PartnerSupplier/models';
import type { IProviderFormData } from './models';

export async function createPartnerProvider(formData: IProviderFormData): Promise<void> {
  return axios.post(`/api/v1/suppliers/form`, formData);
}

export async function updatePartnerProvider(providerId: string, formData: IProviderFormData): Promise<void> {
  return axios.put(`/api/v1/suppliers/${providerId}/form`, formData);
}

export async function deletePartnerProvider(providerId: string): Promise<any> {
  return axios.delete(`/api/v1/suppliers/${providerId}`);
}

export async function fetchPartnerProviderFormData(providerId: string): Promise<IProviderFormData> {
  return axios
    .get(`/api/v1/suppliers/${providerId}/form`)
    .then((response: AxiosResponse<IProviderFormData>) => response.data);
}

export async function activateProvider(providerId: string): Promise<IProviderFormData> {
  return axios
    .put(`/api/v1/suppliers/${providerId}/activate`)
    .then((response: AxiosResponse<IProviderFormData>) => response.data);
}

export async function inactivateProvider(providerId: string): Promise<IProviderFormData> {
  return axios
    .put(`/api/v1/suppliers/${providerId}/disable`)
    .then((response: AxiosResponse<IProviderFormData>) => response.data);
}

export async function fetchSubscriptionSuppliers(): Promise<SupplierWithService[]> {
  return axios
    .get('/api/frontend/v1/suppliers-with-services')
    .then((response: AxiosResponse<SupplierWithService[]>) => response.data);
}

export async function uploadLogotypeImage(file: File): Promise<string> {
  const fileExtension = file.type.split('/', 2)[1];

  return axios({
    method: 'post',
    url: `/api/frontend/v1/logotype-images/${fileExtension}`,
    headers: {
      'Content-Type': file.type,
    },
    data: file,
  }).then((response: AxiosResponse<{ resourceName: string }>) => response.data.resourceName);
}

export async function createApiKeyForPartnerSupplier(supplierId: string): Promise<string> {
  return axios
    .post(`/api/frontend/v1/api-keys/create-for-partner-supplier/${supplierId}`)
    .then((response) => response.data.apiKey);
}
