import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import * as React from 'react';

export interface IApproveOrderProps {
  updateInProgress: boolean;
}

export const ApproveOrder: React.FunctionComponent<IApproveOrderProps> = ({ updateInProgress }) => {
  return (
    <>
      <Body1 style={{ marginBottom: '16px' }}>
        <LocalizedMessage id="submitOrderUpdateWarning" />
      </Body1>
      <Button
        label={<LocalizedMessage id="acceptOrderButtonText" />}
        color="positive"
        loading={updateInProgress}
        type="submit"
      />
    </>
  );
};
