import { LocalizedMessage } from '@provider-portal/internationalization';
import type { Locale } from '@provider-portal/internationalization/locale';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import type { PortalLoadingState } from '@provider-portal/types/loading-state';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import React from 'react';
import PropTypes from 'prop-types';
import type { IChangePlan2FormViewModelDefault, IChangePlan2FormDataInner, IntervalUnit } from '../models';
import { ChangePlanOffer2FormAction, ChangePlanOffer2State } from '../models';
import { PublishButton } from './PublishButton';
import { Header } from '../components/Header';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { ChangePlanForm } from '../ChangePlanForm';
import type { Currency } from '@provider-portal/models/currency';
import { PageAppBar } from '../components/PageAppBar';

interface IAddChangePlanOfferComponentProps {
  providerId: string;
  onGoBack(): void;
  loadingState: PortalLoadingState;
  hasAdminAccess: boolean;
  setActionType(actionType: ChangePlanOffer2FormAction): void;
  onCreate(viewModel: IChangePlan2FormDataInner): Promise<void>;
  currency: Currency;
}

export const AddPlanPage: React.FunctionComponent<IAddChangePlanOfferComponentProps> = (
  { providerId, onGoBack, loadingState, hasAdminAccess, setActionType, onCreate, currency },
  { localizeMessage }
) => {
  return (
    <LoadingPage loadingState={loadingState}>
      <Page appBarText={<PageAppBar />} variant="details" showMenuForSmallerScreen showBackButton={false}>
        <Header
          title={localizeMessage('addChangePlanPageTitle')}
          description={localizeMessage('changePlanDesription')}
        />
        <ChangePlanForm
          leftSidedButtons={[
            <Button key="delete" label={<LocalizedMessage id="archiveButton" />} color="negative" onClick={onGoBack} />,
          ]}
          rightSidedButtons={[
            <Button
              key="save-draft"
              label={<LocalizedMessage id="saveDraftButton" />}
              color="secondary"
              type="submit"
              onClick={() => setActionType(ChangePlanOffer2FormAction.SAVE)}
            />,
            <PublishButton key="publish" onClick={setActionType} hasAdminAccess={hasAdminAccess} />,
          ]}
          onSubmit={debouncePromise(onCreate)}
          providerId={providerId}
          viewModel={defaultOfferFormData(defaultChangePlan2FormViewModel, currency)}
        />
      </Page>
    </LoadingPage>
  );
};

AddPlanPage.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

const defaultChangePlan2FormViewModel = {
  id: undefined,
  name: new Map<Locale, string>(),
  description: new Map<Locale, string>(),
  price: undefined,
  paymentInterval: { amount: 1, unit: 'month' as IntervalUnit },
  planId: undefined,
};

function defaultOfferFormData(defaultViewModel: IChangePlan2FormViewModelDefault, currency: Currency) {
  return {
    id: defaultViewModel.id,
    name: defaultViewModel.name,
    description: defaultViewModel.description,
    price: { amount: 0, currency: currency },
    paymentInterval: defaultViewModel.paymentInterval,
    planId: defaultViewModel.planId,
    offerState: ChangePlanOffer2State.Draft,
  };
}
