import { Checkbox as MinnaCheckbox } from '@provider-portal/libs/uiFramework/components/deprecated/Checkbox';
import * as React from 'react';
import type { ICheckbox } from './config';

interface ICheckboxProps extends Omit<ICheckbox, 'type'> {
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export const Checkbox: React.FunctionComponent<ICheckboxProps> = (props) => {
  const [checked, setChecked] = React.useState(Boolean(props.defaultValue));
  const onCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e);
    }
    setChecked(e.target.checked);
  };

  return <MinnaCheckbox checked={checked} label={props.label} name={props.name} onChange={onCheckboxChanged} />;
};
