import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import type { Currency } from '@provider-portal/models/subscription';
import * as React from 'react';
import { SaveDeskOrderPageContainer } from './container';
import { useFetchOrderOnEnter } from './use-fetch';

export interface ISaveDeskOrderPageProps {
  partnerSupplierId: string;
  saveDeskOrderId: string;
  currency: Currency;
}

export const SaveDeskOrderPage: React.FunctionComponent<ISaveDeskOrderPageProps> = ({
  partnerSupplierId,
  saveDeskOrderId,
  currency,
}) => {
  const loadingState = useFetchOrderOnEnter(partnerSupplierId, saveDeskOrderId);

  return (
    <LoadingPage loadingState={loadingState}>
      <SaveDeskOrderPageContainer partnerSupplierId={partnerSupplierId} currency={currency} />
    </LoadingPage>
  );
};
