import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Snackbar } from '@provider-portal/libs/uiFramework/components/dialogs/Snackbar';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { ApiKeyGeneration } from '@provider-portal/provider/ApiKeyGeneration';
import { Sentry } from '@provider-portal/sentry';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { fetchPartnerProviderFormData, uploadLogotypeImage } from '../api';
import type { IProviderFormData } from '../models';
import { ProviderForm } from '../ProviderForm';
import { SnackbarState, useProps } from './use-props';

interface IEditProviderProps {
  providerId: string;
}

const EditProviderInner: React.FunctionComponent<IEditProviderProps> = ({ providerId }) => {
  const history = useHistory();
  const [loadingState, setLoadingState] = React.useState(PortalLoadingState.Loading);
  const [providerFormData, setProviderFormData] = React.useState<IProviderFormData>({});
  const [snackbarState, setSnackbarState] = React.useState<SnackbarState>(SnackbarState.PROVIDER_UPDATED);
  const [showSnackBar, seShowSnackBar] = React.useState<boolean>(false);
  const [isActiveProvider, setIsActiveProvider] = React.useState<boolean>(false);
  const { onUpdateProvider, onDeactivateProvider, onActivateProvider, onDeleteProvider } = useProps(
    providerId,
    setProviderFormData,
    setLoadingState,
    setSnackbarState,
    seShowSnackBar,
    setIsActiveProvider
  );
  const componentIsMounted = React.useRef(true);

  React.useEffect(() => {
    fetchPartnerProviderFormData(providerId)
      .then((formData) => {
        if (componentIsMounted.current) {
          setProviderFormData(formData);
          setLoadingState(PortalLoadingState.Success);
          setIsActiveProvider(Boolean(formData.isActive));
        }
      })
      .catch(() => {
        if (componentIsMounted.current) {
          setLoadingState(PortalLoadingState.Failure);
        }
      });

    return () => {
      componentIsMounted.current = false;
    };
  }, [providerId]);

  const updateProvider = debouncePromise(async (formData: IProviderFormData) => {
    if (formData.logotype) {
      const resourceName = await uploadLogotypeImage(formData.logotype);
      formData.logotypeResourceName = resourceName;
      formData.logotypeFileName = formData.logotype.name;
    } else {
      formData.logotypeResourceName = providerFormData.logotypeResourceName;
      formData.logotypeFileName = providerFormData.logotypeFileName;
    }
    await onUpdateProvider(formData);
    setProviderFormData(formData);
  });

  if (providerFormData.market) {
    return (
      <LoadingPage loadingState={loadingState}>
        <ProviderForm
          market={providerFormData.market}
          appBarTitle={providerFormData?.name}
          onSubmit={updateProvider}
          formData={providerFormData}
          leftSidedButtons={[
            <Button
              key="delete"
              label={<LocalizedMessage id="deleteProviderButtonText" />}
              color="negative"
              onClick={onDeleteProvider}
            />,
          ]}
          rightSidedButtons={[
            <ApiKeyGeneration key="apiGeneration" providerId={providerId} />,
            <Button
              key="activationToggle"
              label={
                isActiveProvider ? (
                  <LocalizedMessage id="deactivateProviderButtonText" />
                ) : (
                  <LocalizedMessage id="activateProviderButtonText" />
                )
              }
              color="secondary"
              onClick={isActiveProvider ? onDeactivateProvider : onActivateProvider}
            />,
            <Button key="save" label={<LocalizedMessage id="saveChange" />} color="positive" type="submit" />,
          ]}
        />
        <Snackbar
          autoClose
          variant={snackbarState === SnackbarState.PROVIDER_UPDATED ? 'success' : 'info'}
          open={showSnackBar}
          content={snackbarContent(snackbarState)}
          onClose={() => seShowSnackBar(false)}
        />
      </LoadingPage>
    );
  } else {
    if (loadingState === PortalLoadingState.Success) {
      Sentry.captureMessage('No market defined in form data which is required when a editing persisted provider.');
      const listProvidersUrl = URI(history.location.pathname).segment(-1, '').toString();
      history.replace(listProvidersUrl);
    }

    return null;
  }
};

export const EditProvider = withLocalization('SupplierPortal/PartnerSupplier')(EditProviderInner);

function snackbarContent(snackbarState: SnackbarState) {
  switch (snackbarState) {
    case SnackbarState.PROVIDER_UPDATED:
      return <LocalizedMessage id="providerUpdated" />;
    case SnackbarState.PROVIDER_ACTIVATED:
      return <LocalizedMessage id="providerActivated" />;
    case SnackbarState.PROVIDER_DISABLED:
      return <LocalizedMessage id="providerDeactivated" />;
    default:
      return null;
  }
}
