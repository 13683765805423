import * as React from 'react';
import { FieldMessage } from '../FieldMessage';
import type { FileType } from './models';

export function isValidFileType(fileType: FileType, fileName: string): boolean {
  const fileRegex = new RegExp(`[.]${fileType}$`);

  return fileRegex.test(fileName.toLowerCase());
}

export function fileIsValid(allowedFileTypes: FileType[], fileName: string): boolean {
  return allowedFileTypes.map((fileType) => isValidFileType(fileType, fileName)).includes(true);
}

export function fileValidator(allowedFileTypes: FileType[]) {
  // eslint-disable-next-line react/display-name
  return (fileName: string): boolean | React.ReactNode | string => {
    if (!fileName) return false;

    return fileIsValid(allowedFileTypes, fileName) ? (
      false
    ) : (
      <FieldMessage id="inputFileNowAllowedError" values={{ fileFormats: allowedFileTypes.join(', ') }} />
    );
  };
}
