import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';
import { TableColumnSortDirection } from '../config';

const iconStyles = {
  height: '24px',
  width: '24px',
  marginLeft: '4px',
};

const SortArrowDownInactive: React.FunctionComponent = () => {
  return (
    <SvgIcon style={iconStyles}>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 10L12 13L16 10H8Z" fill="#434A54" />
    </SvgIcon>
  );
};

const SortArrowUpActive: React.FunctionComponent = () => {
  return (
    <SvgIcon style={iconStyles}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22ZM12.35 9.35L15.14 12.14C15.46 12.46 15.24 13 14.79 13H9.2C8.75 13 8.53 12.46 8.85 12.15L11.64 9.36C11.84 9.16 12.16 9.16 12.35 9.35Z"
        fill="#00B5B9"
      />
    </SvgIcon>
  );
};

const SortArrowDownActive: React.FunctionComponent = () => {
  return (
    <SvgIcon style={iconStyles}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11.65 14.65L8.86 11.86C8.54 11.54 8.76 11 9.21 11H14.8C15.25 11 15.47 11.54 15.15 11.85L12.36 14.64C12.16 14.84 11.84 14.84 11.65 14.65Z"
        fill="#00B5B9"
      />
    </SvgIcon>
  );
};

export interface ISortArrowIconProps {
  sortDirection?: TableColumnSortDirection;
  isActive: boolean;
}

export const SortArrowIcon: React.FunctionComponent<ISortArrowIconProps> = ({
  isActive,
  sortDirection = TableColumnSortDirection.Ascending,
}) => {
  if (isActive) {
    return sortDirection === TableColumnSortDirection.Ascending ? <SortArrowUpActive /> : <SortArrowDownActive />;
  } else {
    return <SortArrowDownInactive />;
  }
};
