import { Market } from '@provider-portal/app-context/constants';
import { getDefaultCurrency } from '@provider-portal/app-context/state';
import type { ChangePlanOffer2FormAction, IChangePlanOffer2FormData, IChangePlan2FormDataInner } from './models';
import { paymentIntervalsPer } from './models';

export const costWithDecimalsRegex = /^[0-9]\d*(?:[.|,]\d{1,2})?$/;

export const removeSpaces = (value: string): string => value.replace(/\s+/g, '');

export const formatAmountWithDecimals = (amount: string | number): string => {
  const stringAmount = removeSpaces(amount.toString());

  return costWithDecimalsRegex.test(stringAmount) ? stringAmount.replace(',', '.') : stringAmount;
};

export const parseAmountWithDecimals = (amount: string): number => parseFloat(formatAmountWithDecimals(amount));

export const convertFormDataToApiREquestBody = (
  viewModel: IChangePlan2FormDataInner,
  actionType: ChangePlanOffer2FormAction
) => {
  const market = Market.Sweden; // TODO: fix me
  const currency = getDefaultCurrency(market);

  const costWithCurrency = { amount: viewModel.price, currency: currency };
  const paymentInterval = paymentIntervalsPer[viewModel.paymentInterval].interval;

  const formData = {
    name: viewModel.name,
    description: viewModel.description,
    price: costWithCurrency,
    paymentInterval: paymentInterval,
    planId: viewModel.planId,
    action: actionType,
  } as IChangePlanOffer2FormData;
  return formData;
};
