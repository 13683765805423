import type * as React from 'react';
import type { IWidget, WidgetType } from '../../widgets/config';
import type { IFieldArrayItemConfig } from '../FieldArray/config';
import type { FileType } from '../file/models';
import type { MeasurementUnit } from '../MeasurementUnit';
import type { NumberType } from '../NumberInput';
import type { IOption } from '../Select/option';
import type { TextInputVariant } from '../TextInput';
import type { UnitFieldFactoryType } from '../UnitFieldFactory/config';
import type { Columns } from './columns';
import { isWidget } from './type-guards';

export enum FieldType {
  Text = 'text',
  Date = 'date',
  Radio = 'radio',
  Number = 'number',
  MultiSelect = 'multiSelect',
  FieldArray = 'fieldArray',
  Select = 'select',
  Uri = 'uri',
  Email = 'email',
  File = 'file',
  Hidden = 'hidden',
  Checkbox = 'checkbox',
  RadioGroup = 'radioGroup',
  Cost = 'cost',
  UnitFieldFactory = 'unitFieldFactory',
  TextValueArray = 'text-value-array',
  CentesimalCost = 'centesimalCost',
  ValueUnit = 'valueUnit',
  Image = 'image',
}

export interface IBaseField {
  name: string;
  required?: boolean;
  requiredErrorText?: React.ReactNode;
}

export interface IInputField extends IBaseField {
  label?: React.ReactNode;
  helperText?: React.ReactNode;
}

export interface IFileValue {
  resourceName: string;
  fileName: string;
}

export interface IField extends IInputField {
  type: FieldType;
  defaultValue?: any;
  backgroundColor?: string;
  onChange?(e: any): void;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  options?: IOption[];
  onBlur?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  fileValue?: IFileValue;
  numberType?: NumberType;
  allSelectedLabel?: React.ReactNode;
  multiline?: boolean;
  allowedFileTypes?: FileType[];
  inputVariant?: TextInputVariant;
  fields?: IField[];
  columns?: Columns;
  addItemButtonLabel?: React.ReactNode;
  itemComponent?: IFieldArrayItemConfig;
  unit?: MeasurementUnit;
  units?: MeasurementUnit[];
  unitFieldFactoryType?: UnitFieldFactoryType;
  maxlength?: number;
}

export type FactoryRequest = IField | IWidget;

export function mapConfigsToFieldsOrWidgets(
  configs: FactoryRequest[],
  configToFieldFunc: (field: IField) => IField,
  configToWidgetFunc: (field: IWidget) => IWidget = (_) => _
): FactoryRequest[] {
  return configs.map((c) => {
    if (isWidget(c)) {
      return configToWidgetFunc(c);
    } else {
      return configToFieldFunc(c);
    }
  });
}

export enum AttributeNames {
  DATA_FORMAT = 'data-format',
}

export function fieldConstructor<T extends IInputField & { type: FieldType }>(
  fieldType: FieldType
): (field: Omit<T, 'type'>) => IField {
  return (field: Omit<T, 'type'>) => ({ ...field, type: fieldType });
}

export function widgetConstructor<T extends { type: WidgetType }>(
  widgetType: WidgetType
): (widget: Omit<T, 'type'>) => IWidget {
  return (field: Omit<T, 'type'>) => ({ ...field, type: widgetType });
}
