import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { NumberInput, NumberType } from '../../fields/NumberInput';
import type { IMonthlyDataCampaignWidget } from './config';

type IMonthlyDataCampaignProps = Omit<IMonthlyDataCampaignWidget, 'type'>;

export enum MonthlyDataCampaignFieldNames {
  MONTHLY_DATA_AMOUNT = 'monthlyDataAmountInGb',
  MONTHLY_DATA_LENGTH = 'monthlyDataLength',
}

const MonthlyDataCampaignInner: React.FunctionComponent<IMonthlyDataCampaignProps> = (props) => {
  const [hasMonthlyData, setHasMonthlyData] = React.useState<boolean>(Boolean(props.monthlyDataAmountInGb));
  const [hasMonthlyDataLength, setHasMonthlyDataLength] = React.useState<boolean>(Boolean(props.monthlyDataLength));

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <NumberInput
          name={MonthlyDataCampaignFieldNames.MONTHLY_DATA_AMOUNT}
          defaultValue={props.monthlyDataAmountInGb}
          numberType={NumberType.PositiveFloat}
          label={<LocalizedMessage id="additionalDataAmountLabel" />}
          helperText={<LocalizedMessage id="additionalDataHelperText" />}
          onChange={(e) => setHasMonthlyData(Boolean(e.target.value))}
          locale={props.locale}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          name={MonthlyDataCampaignFieldNames.MONTHLY_DATA_LENGTH}
          defaultValue={props.monthlyDataLength}
          numberType={NumberType.PositiveInteger}
          label={<LocalizedMessage id="numberOfMonthsLabelV2" />}
          helperText={<LocalizedMessage id="additionalDataLengthHelperText" />}
          onChange={(e) => setHasMonthlyDataLength(Boolean(e.target.value))}
          required={hasMonthlyData && !hasMonthlyDataLength}
          requiredErrorText={<LocalizedMessage id="specifiedCampaignRequiresLength" />}
          locale={props.locale}
        />
      </Grid>
    </Grid>
  );
};

export const MonthlyDataCampaign = withLocalization('SupplierPortal/PartnerCampaign')(MonthlyDataCampaignInner);
