import { ImageField } from '@provider-portal/libs/uiFramework/components/fields/Image';
import React from 'react';
import { widgetFactory } from '../../widgets/config/resolve-widget';
import { Checkbox } from '../Checkbox';
import { CentesimalCostInput } from '../cost/CentesimalCostInput';
import { CostInput } from '../cost/CostInput';
import { DateInput } from '../DateInput';
import { FieldArray } from '../FieldArray';
import type { IFieldArray } from '../FieldArray/config';
import type { IFieldArrayItem } from '../FieldArray/models';
import { FileInput } from '../file/FileInput';
import { HiddenInput } from '../HiddenInput';
import { MultiSelect } from '../MultiSelect';
import { NumberInput } from '../NumberInput';
import { RadioGroup } from '../RadioGroup';
import { RadioInput } from '../RadioInput';
import { Select } from '../Select';
import { TextInput, TextInputVariant } from '../TextInput';
import { TextValueArrayInput } from '../TextValueArrayInput';
import { UnitFieldFactory } from '../UnitFieldFactory';
import type { FactoryRequest, IField } from './';
import {
  isCentesimalCostInput,
  isCheckbox,
  isCostInput,
  isDateInput,
  isEmailInput,
  isField,
  isFieldArray,
  isFileInput,
  isHiddenInput,
  isImage,
  isMultiSelectInput,
  isNumberInput,
  isRadioGroup,
  isRadioInput,
  isSelectInput,
  isTextInput,
  isTextValueArrayInput,
  isUnitFieldFactory,
  isUriInput,
  isWidget,
} from './type-guards';

export function fieldFactory(fieldRequest: IField): React.ReactElement {
  if (isDateInput(fieldRequest)) {
    return <DateInput {...fieldRequest} />;
  } else if (isRadioInput(fieldRequest)) {
    return <RadioInput {...fieldRequest} />;
  } else if (isNumberInput(fieldRequest)) {
    return <NumberInput {...fieldRequest} />;
  } else if (isMultiSelectInput(fieldRequest)) {
    return <MultiSelect {...fieldRequest} />;
  } else if (isTextInput(fieldRequest)) {
    return <TextInput {...fieldRequest} />;
  } else if (isFieldArray(fieldRequest)) {
    return <FieldArray config={fieldRequest} items={fieldArrayItems(fieldRequest, fieldRequest.defaultValue || [])} />;
  } else if (isSelectInput(fieldRequest)) {
    return <Select {...fieldRequest} options={fieldRequest.options} />;
  } else if (isUriInput(fieldRequest)) {
    return <TextInput {...fieldRequest} variant={TextInputVariant.URI} />;
  } else if (isEmailInput(fieldRequest)) {
    return <TextInput {...fieldRequest} variant={TextInputVariant.EMAIL} />;
  } else if (isFileInput(fieldRequest)) {
    return <FileInput {...fieldRequest} />;
  } else if (isHiddenInput(fieldRequest)) {
    return <HiddenInput {...fieldRequest} />;
  } else if (isCheckbox(fieldRequest)) {
    return <Checkbox {...fieldRequest} />;
  } else if (isRadioGroup(fieldRequest)) {
    return <RadioGroup {...fieldRequest} options={fieldRequest.options || []} />;
  } else if (isCostInput(fieldRequest)) {
    return <CostInput {...fieldRequest} />;
  } else if (isTextValueArrayInput(fieldRequest)) {
    return <TextValueArrayInput {...fieldRequest} />;
  } else if (isCentesimalCostInput(fieldRequest)) {
    return <CentesimalCostInput {...fieldRequest} />;
  } else if (isUnitFieldFactory(fieldRequest)) {
    return <UnitFieldFactory {...fieldRequest} />;
  } else if (isImage(fieldRequest)) {
    return <ImageField {...fieldRequest} />;
  } else {
    throw new Error('Field configuration is missing implementation');
  }
}

function fieldArrayItems(fieldConfig: IFieldArray, fieldArrayValue: { [key: string]: any }[]): IFieldArrayItem[] {
  return fieldArrayValue.map((value) => {
    return {
      columns: fieldConfig.columns,
      fields: fieldConfig.fields.map((conf) => {
        return {
          ...conf,
          defaultValue: value[conf.name],
        };
      }),
    };
  });
}

export function componentFactory(factoryRequest: FactoryRequest): React.ReactNode {
  if (isField(factoryRequest)) {
    return fieldFactory(factoryRequest);
  } else if (isWidget(factoryRequest)) {
    return widgetFactory(factoryRequest);
  } else {
    throw new Error('Factory request is neither a field or a widget');
  }
}
