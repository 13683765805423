import type { CategoryName } from '@provider-portal/constants/categories';
import { localizeCategoryLabel } from '@provider-portal/constants/categories';
import * as React from 'react';

interface ICategoryLabelProps {
  categoryName: CategoryName;
}

export const CategoryLabel: React.FunctionComponent<ICategoryLabelProps> = ({ categoryName }) => {
  return <>{localizeCategoryLabel(categoryName)}</>;
};
