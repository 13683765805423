import * as React from 'react';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { colors } from '@provider-portal/styles/colors';

export function renderAssigned(assignee?: string) {
  return assignee ? (
    assignee
  ) : (
    <Body1 style={{ color: colors.grey3, fontStyle: 'italic' }}>
      <LocalizedMessage id="unassigned" />
    </Body1>
  );
}
