import type { Locale } from '@provider-portal/internationalization/locale';
import type { EnrichMap, EnrichWithFormData, IEnrichProps } from '@provider-portal/libs/uiFramework';
import { MonthlyDataCampaignFieldNames } from '.';
import { WidgetType } from '../config';

export interface IMonthlyDataCampaignWidget {
  type: WidgetType.MONTHLY_DATA_CAMPAIGN;
  monthlyDataAmountInGb?: number;
  monthlyDataLength?: number;
  locale: Locale;
}

const monthlyDataCampaignEnricher: EnrichWithFormData = (props: IEnrichProps<any>) => {
  return {
    ...props.config,
    monthlyDataAmountInGb: props.formData[MonthlyDataCampaignFieldNames.MONTHLY_DATA_AMOUNT],
    monthlyDataLength: props.formData[MonthlyDataCampaignFieldNames.MONTHLY_DATA_LENGTH],
  };
};

export const monthlyDataCampaignEnricherMap: EnrichMap = [
  WidgetType.MONTHLY_DATA_CAMPAIGN,
  monthlyDataCampaignEnricher,
];
