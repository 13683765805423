import { white } from '@provider-portal/styles/colors-standard';
import { DatePicker } from '@provider-portal/libs/uiFramework/components/fields/DatePicker';
import { parseDate } from '@provider-portal/utils/date';
import PropTypes from 'prop-types';
import * as React from 'react';

export interface IBindingPeriodDatePickerProps {
  bindingPeriod?: Date;
  onBindingPeriodChanged(bindingPeriod: Date): void;
}

export const BindingPeriodDatePicker: React.FunctionComponent<IBindingPeriodDatePickerProps> = (
  { bindingPeriod, onBindingPeriodChanged },
  { localizeMessage }
) => {
  const handleDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target ? event.target.value : undefined;
    if (date) onBindingPeriodChanged(parseDate(date));
  };

  return (
    <div style={{ paddingTop: '8px' }}>
      <DatePicker
        name="bindingPeriod"
        defaultValue={bindingPeriod || undefined}
        minDate={new Date()}
        onChange={handleDateChanged}
        backgroundColor={white.main}
        requiredErrorText={localizeMessage('bindingPeriodRequiredMessage')}
        label={localizeMessage('bindingPeriodInputLabel')}
      />
    </div>
  );
};

BindingPeriodDatePicker.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
