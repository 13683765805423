import { makeStyles } from '@material-ui/core/styles';
import { withAppContext } from '@provider-portal/app-context/react';
import { BookIcon } from '@provider-portal/assets/icons/BookIcon';
import { CodeIcon } from '@provider-portal/assets/icons/CodeIcon';
import HelpIcon from '@provider-portal/assets/icons/HelpIcon.svg';
import { ExternalLinks } from '@provider-portal/constants/externalLinks';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { MenuItem } from '@provider-portal/libs/uiFramework/components/Menu/components/MenuItem';
import type { MenuModuleProps } from '@provider-portal/libs/uiFramework/components/Menu/models';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';
import { useFetchProviderSettingsOnEnter } from '@provider-portal/saveDesk/SaveDeskMenuModule/use-fetch';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import startsWith from 'lodash/startsWith';
import React from 'react';

const useStyles = makeStyles(() => ({
  overline: {
    margin: '16px 0px 16px 16px',
  },
}));

const LinksMenuInnerComponent = ({ path, providerId }: Omit<MenuModuleProps, 'hasAdminAccess'>) => {
  const { loadingState } = useFetchProviderSettingsOnEnter(providerId);
  const contactUsPagePath = `/contact-us`;
  const classes = useStyles();

  if (loadingState === PortalLoadingState.Success) {
    return (
      <>
        <Overline className={classes.overline}>
          <LocalizedMessage id="linksSection" />
        </Overline>
        <MenuItem
          label={<LocalizedMessage id="docs" />}
          icon={<BookIcon />}
          toExternal={ExternalLinks.ApiDocumentation}
          subItem
        />
        <MenuItem
          label={<LocalizedMessage id="apiReference" />}
          icon={<CodeIcon />}
          toExternal={ExternalLinks.ApiReference}
          subItem
        />
        <MenuItem
          label={<LocalizedMessage id="contact" />}
          icon={<img src={HelpIcon} alt="contact" />}
          to={contactUsPagePath}
          subItem
          selected={startsWith(path, contactUsPagePath)}
        />
      </>
    );
  } else {
    return <div data-testid="linkMenuComponent-loading" />;
  }
};

export const LinksMenuComponent = withLocalization('Menu')(withAppContext(LinksMenuInnerComponent));
