import ErrorIconImageUrl from '@provider-portal/assets/icons/error-page-icon.svg';
import { BasePaper } from '@provider-portal/libs/uiFramework/components/deprecated/BasePaper';
import { PrimaryButton } from '@provider-portal/libs/uiFramework/components/deprecated/PrimaryButton';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import * as React from 'react';
import styles from './styles.scss';

const messages = {
  somethingWentWrongTitle: 'Hmm, something went wrong',
  itIsNotYouItIsUsText: "Don't worry, it's not you, it's us.",
  pleaseTryAgainText: "Please try again. If that doesn't work, try again later.",
  tryAgainButtonLabel: 'Try again',
};

export const LoginErrorPage: React.FunctionComponent = () => {
  return (
    <BasePaper className={styles.container}>
      <div className={styles.message}>
        <img src={ErrorIconImageUrl} className={styles.errorLoadingImage} alt="Error image" />
        <h3 className={styles.header}>{messages.somethingWentWrongTitle}</h3>
        <Body colorVariant="light">
          {messages.itIsNotYouItIsUsText}
          <br />
          {messages.pleaseTryAgainText}
        </Body>
      </div>
      <PrimaryButton
        label={messages.tryAgainButtonLabel}
        onClick={() => window.location.reload()}
        fullWidth
        classes={{ root: styles.reloadButton }}
      />
    </BasePaper>
  );
};
