import type { Locale } from '@provider-portal/internationalization/locale';
import type { LoggedInUser } from '@provider-portal/login/api';
import { LoggedInUserType, loginWithTokenServer, requestLoginServer } from '@provider-portal/login/api';
import type { RecaptchaToken } from '@provider-portal/login/LoginPage/recaptcha';
import { offsetDateTimeFormat } from '@provider-portal/models/OffsetDateTime';
import { Sentry } from '@provider-portal/sentry';
import type { ActionsUnion } from '@provider-portal/utils/redux';
import { createAction, createPromiseAction } from '@provider-portal/utils/redux';
import type { Moment } from 'moment';
import moment from 'moment';
import { handle } from 'redux-pack';
import type { ApplicationState } from '../store';

enum ActionKeys {
  REQUEST_LOGIN = 'SUPPLIER_PORTAL_LOGIN/REQUEST_LOGIN',
  LOGIN_WITH_TOKEN = 'SUPPLIER_PORTAL_LOGIN/LOGIN_WITH_TOKEN',
  CLEAR_LOGIN = 'SUPPLIER_PORTAL_LOGIN/CLEAR_LOGIN',
  SET_LOGIN = 'SUPPLIER_PORTAL_LOGIN/SET_LOGIN',
}

export const UserActions = {
  requestLogin: (username: string, recaptchaToken: RecaptchaToken) =>
    createPromiseAction(ActionKeys.REQUEST_LOGIN, requestLoginServer(username, recaptchaToken)),
  loginWithToken: (token: string) => createPromiseAction(ActionKeys.LOGIN_WITH_TOKEN, loginWithTokenServer(token)),
  clearLogin: () => createAction(ActionKeys.CLEAR_LOGIN),
  setLogin: (user: LoggedInUser) => createAction(ActionKeys.SET_LOGIN, user),
};

export type IUserActions = ActionsUnion<typeof UserActions>;

export interface UserState {
  readonly isLoggedIn: boolean;
  readonly loginWithTokenLoading: boolean;
  readonly loginWithTokenFailed: boolean;
  readonly loggedInUsername?: string;
  readonly hasAdminAccess: boolean;
  readonly loggedInPartnerSupplierId?: string;
  readonly csatRequestedAt: Moment[];
  readonly createdAt: Moment;
  readonly userLocale?: Locale;
}

export const initialState: UserState = {
  isLoggedIn: false,
  loginWithTokenLoading: false,
  loginWithTokenFailed: false,
  loggedInUsername: undefined,
  hasAdminAccess: false,
  csatRequestedAt: [],
  createdAt: moment.min(),
  loggedInPartnerSupplierId: undefined,
};

export function reducer(state: UserState = initialState, action: IUserActions): UserState {
  switch (action.type) {
    case ActionKeys.LOGIN_WITH_TOKEN:
      return handle(state, action, {
        start: (s) => ({
          ...s,
          isLoggedIn: false,
          loginWithTokenFailed: false,
          loginWithTokenLoading: true,
          loggedInPartnerSupplierId: undefined,
        }),
        success: (s, a) => {
          if (a.payload) {
            return {
              ...s,
              isLoggedIn: true,
              loggedInPartnerSupplierId: a.payload.loggedInPartnerSupplierId,
              loggedInUsername: a.payload.username,
              createdAt: moment(a.payload.createdAt, offsetDateTimeFormat),
              csatRequestedAt: a.payload.csatRequestedAt.map((request) => moment(request, offsetDateTimeFormat)),
              userLocale: a.payload.locale,
            };
          } else {
            return {
              ...s,
              isLoggedIn: false,
              loggedInPartnerSupplierId: undefined,
              loggedInUsername: undefined,
              csatRequestedAt: [],
            };
          }
        },
        failure: (s) => ({
          ...s,
          isLoggedIn: false,
          loginWithTokenLoading: true,
        }),
        finish: (s) => ({
          ...s,
          loginWithTokenLoading: false,
        }),
      });
    case ActionKeys.CLEAR_LOGIN:
      return {
        ...state,
        isLoggedIn: false,
        hasAdminAccess: false,
        loggedInUsername: undefined,
        createdAt: moment.min(),
        csatRequestedAt: [],
        userLocale: undefined,
      };
    case ActionKeys.SET_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        loggedInUsername: action.payload.username,
        hasAdminAccess: action.payload.type === LoggedInUserType.LoggedInInternalAdmin,
        loggedInPartnerSupplierId: action.payload.loggedInPartnerSupplierId,
        createdAt: moment(action.payload.createdAt, offsetDateTimeFormat),
        csatRequestedAt: action.payload.csatRequestedAt
          ? action.payload.csatRequestedAt.map((request) => moment(request, offsetDateTimeFormat))
          : [],
        userLocale: action.payload.locale,
      };
    default:
      return state;
  }
}

export const selectorCreatedAt = (state: ApplicationState) => state.user.createdAt;
export const selectorCsatRequestedAt = (state: ApplicationState) => state.user.csatRequestedAt;
export const selectorLoggedInUsername = (state: ApplicationState) => state.user.loggedInUsername;
export const selectorHasInternalAdminAccess = (state: ApplicationState) => state.user.hasAdminAccess;
export const selectorLoggedInUserLocale = (state: ApplicationState) => {
  const maybeUserLocale = state.user.userLocale;
  const isLoggedIn = state.user.isLoggedIn;
  if (isLoggedIn && !maybeUserLocale) {
    const e = new Error('Did not manage to access logged in user locale');
    Sentry.captureException(e);
    throw e;
  }
  return maybeUserLocale;
};
