import * as React from 'react';
import type { ISaveDeskOrder } from '../../../models';
import { SaveDeskState } from '../../../models';
import { ConfirmContractUpdateSection } from './ConfirmContractUpdateSection';
import { HeaderDescription } from './HeaderDescription';

export interface IProps {
  userName: string;
  saveDeskOrder: ISaveDeskOrder;
}

const showOutcomeSectionStates = [SaveDeskState.AcceptedByUser, SaveDeskState.RejectedByUser, SaveDeskState.Success];

export const Header: React.FunctionComponent<IProps> = ({ userName, saveDeskOrder }) => {
  const showOutcome = showOutcomeSectionStates.includes(saveDeskOrder.state.stateType);

  return (
    <>
      {!showOutcome && (
        <HeaderDescription
          orderSentAt={saveDeskOrder.outcome?.createdAt}
          state={saveDeskOrder.outcome?.type}
          userName={userName}
        />
      )}
      {showOutcome && <ConfirmContractUpdateSection saveDeskOrder={saveDeskOrder} />}
    </>
  );
};
