import { negative, white } from '@provider-portal/styles/colors-standard';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import * as React from 'react';
import type { IFieldArrayItemComponentProps } from '../..';
import type { Columns } from '../../../configs/columns';
import { FieldRows } from '../../../FieldRows';
import type { IFieldArrayItem } from '../../models';

export const FieldGroup1: React.FunctionComponent<IFieldArrayItemComponentProps> = ({
  title,
  onDelete,
  item,
  columns,
}) => {
  return (
    <Panel1
      title={title}
      icon={onDelete && <RemoveCircleOutlineIcon style={{ fill: negative.main }} />}
      onIconClick={onDelete}
      variant="flat"
    >
      <Fields item={item} columns={columns} />
    </Panel1>
  );
};

interface IProps {
  item: IFieldArrayItem;
  columns: Columns;
}

const Fields: React.FunctionComponent<IProps> = ({ item, columns }) => {
  return (
    <FieldRows fields={item.fields.map((field) => ({ ...field, backgroundColor: white.main }))} columns={columns} />
  );
};
