// @ts-ignore js import, remove this when the import is typed
import InputValidationHOC from '../InputValidationHOC';
import type { InputValidationProps } from '../InputValidationHOC/types';
import { default as MUICheckbox } from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import omit from 'lodash/omit';
import values from 'lodash/values';
import type { ReactNode } from 'react';
import React from 'react';
import { compose, defaultProps, mapProps, withProps } from 'recompose';

interface CheckboxInnerProps extends ExtendedProps {
  checked: boolean;
  label?: string | ReactNode;
  onChange?(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void;
  disabled?: boolean;
  name?: string;
}

type CheckboxProps = CheckboxInnerProps & InputValidationProps;

interface ExtendedProps {
  transformBlurValue?(value: any, valid?: boolean, errors?: Record<string, unknown> | any[]): string;

  requiredErrorText?: React.ReactNode | string;
  validators?: any;

  showErrorOnBlur?(): void | boolean;

  showErrorOnChange?(): void | boolean;

  clear?: boolean;
  errorText?: React.ReactNode | string;
}

const inputValidationProps = defaultProps({
  showErrorOnBlur: true,
});

const requiredToValidatorsProps = mapProps(({ requiredErrorText, validators, ...props }: CheckboxInnerProps) => {
  if (requiredErrorText) {
    const updatedValidators = {
      required(value: boolean) {
        return value ? false : requiredErrorText;
      },
      ...validators,
    };

    return { validators: updatedValidators, ...props };
  } else {
    return { validators, ...props };
  }
});

const CheckboxInner: React.FunctionComponent<CheckboxProps> = ({ label, errors, errorText, ...props }) => {
  const error = errors ? values(errors)[0] : errorText;
  const cleanedProps = omit(props, [
    'clear',
    'showErrorOnBlur',
    'showErrorOnChange',
    'canShowError',
    'formSubmitted',
    'focused',
    'valid',
    'value',
  ]);

  return (
    <FormControl error={Boolean(error)}>
      <FormControlLabel control={<MUICheckbox {...cleanedProps} />} label={label} />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export const Checkbox = compose<CheckboxProps, CheckboxInnerProps>(
  withProps<{ value?: boolean; clear: boolean }, { checked: boolean }>(({ checked }) => ({
    value: checked ? checked : undefined,
    clear: !checked,
  })),
  requiredToValidatorsProps,
  inputValidationProps,
  InputValidationHOC
)(CheckboxInner);
