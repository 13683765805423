import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';
import { LocalizedMessage } from '@provider-portal/internationalization';
import React from 'react';
import EnergyProducts from '@provider-portal/assets/icons/energy-products.svg';
import SupplierIcon from '@provider-portal/assets/icons/supplier.svg';
import UserIcon from '@provider-portal/assets/icons/user.svg';
import { MenuItem } from './MenuItem';

const styles = () =>
  createStyles({
    list: {
      padding: '0px 0px 8px 0px',
      margin: '0px 8px 0px 8px',
    },
  });

const inlineStyles = {
  overline: {
    margin: '16px 0px 16px 24px',
  },
};

export interface SupplierVertical {
  partnerSupplierId: string;
  categoryName: string;
}

interface MinnaAdminMenuProps {
  path: string;
}

type MenuInnerProps = MinnaAdminMenuProps & WithStyles<typeof styles>;

const MenuInner: React.FunctionComponent<MenuInnerProps> = ({ classes, path }) => {
  return (
    <div>
      <Divider />
      <Overline style={inlineStyles.overline}>
        <LocalizedMessage id="adminTools" />
      </Overline>
      <List classes={{ root: classes.list }}>
        <MenuItem
          selected={path === '/admin/suppliers'}
          label={<LocalizedMessage id="suppliers" />}
          icon={<img src={SupplierIcon} alt="suppliers" />}
          to="/admin/suppliers"
        />
        <MenuItem
          selected={path === '/admin/users'}
          label={<LocalizedMessage id="users" />}
          icon={<img src={UserIcon} alt="users" />}
          to="/admin/users"
        />
        <MenuItem
          selected={path === '/admin/denmark-grid-costs'}
          label={<LocalizedMessage id="denmarkElectricityGridCostsMenuTitle" />}
          icon={<img src={EnergyProducts} alt="denmark grid costs" />}
          to="/admin/denmark-grid-costs"
        />
      </List>
    </div>
  );
};

export const MinnaAdminMenu: React.ComponentType<MinnaAdminMenuProps> = withStyles(styles)(MenuInner);
