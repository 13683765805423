import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';

export const QuestionIcon: React.FunctionComponent = () => {
  return (
    <SvgIcon height={24} width={24}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.5C6.20121 1.5 1.5 6.20121 1.5 12C1.5 17.7988 6.20121 22.5 12 22.5C17.7988 22.5 22.5 17.7988 22.5 12C22.5 6.20121 17.7988 1.5 12 1.5ZM0 12C0 5.37279 5.37279 0 12 0C18.6272 0 24 5.37279 24 12C24 18.6272 18.6272 24 12 24C5.37279 24 0 18.6272 0 12Z"
        fill="#00B5B9"
      />
      <path
        d="M11.4074 18.8211C11.5828 18.9378 11.789 19 12 19C12.2829 19 12.5542 18.8882 12.7542 18.6891C12.9543 18.4901 13.0667 18.2202 13.0667 17.9387C13.0667 17.7288 13.0041 17.5236 12.8869 17.349C12.7697 17.1745 12.6031 17.0385 12.4082 16.9582C12.2133 16.8778 11.9988 16.8568 11.7919 16.8978C11.585 16.9387 11.3949 17.0398 11.2458 17.1882C11.0966 17.3366 10.995 17.5258 10.9538 17.7316C10.9127 17.9375 10.9338 18.1509 11.0145 18.3448C11.0952 18.5388 11.232 18.7045 11.4074 18.8211Z"
        fill="#00B5B9"
      />
      <path
        d="M13.018 13.1173C13.1601 12.9013 13.3623 12.7311 13.6 12.6276C14.2609 12.3406 14.8315 11.8812 15.2515 11.2979C15.6716 10.7147 15.9253 10.0293 15.9858 9.31439C16.0465 8.59943 15.9117 7.88147 15.5958 7.23648C15.2799 6.5915 14.7946 6.04347 14.1914 5.65039C13.5881 5.25732 12.8893 5.03382 12.1688 5.00355C11.4483 4.97327 10.733 5.13734 10.0986 5.47841C9.46416 5.81947 8.93418 6.32484 8.56478 6.94102C8.19535 7.55721 8.0002 8.26131 8 8.97881C8 9.18991 8.08428 9.39238 8.23431 9.54165C8.38434 9.69093 8.58783 9.77479 8.8 9.77479C9.01217 9.77479 9.21566 9.69093 9.36569 9.54165C9.51572 9.39238 9.6 9.18991 9.6 8.97881C9.60016 8.54832 9.71729 8.12588 9.93897 7.75619C10.1606 7.3865 10.4786 7.08331 10.8593 6.8787C11.24 6.6741 11.6691 6.57568 12.1014 6.59387C12.5337 6.61205 12.953 6.74617 13.3149 6.98203C13.6769 7.21788 13.968 7.5467 14.1575 7.9337C14.347 8.32069 14.4279 8.75145 14.3915 9.18042C14.3551 9.60939 14.2029 10.0206 13.9509 10.3705C13.6989 10.7205 13.3565 10.9961 12.96 11.1683C12.4368 11.3955 11.9916 11.7697 11.679 12.2449C11.3663 12.7202 11.1999 13.2759 11.2 13.8439V14.2854C11.2 14.4965 11.2843 14.699 11.4343 14.8482C11.5843 14.9975 11.7878 15.0814 12 15.0814C12.2122 15.0814 12.4157 14.9975 12.5657 14.8482C12.7157 14.699 12.8 14.4965 12.8 14.2854V13.8439C12.8002 13.5857 12.8759 13.3332 13.018 13.1173Z"
        fill="#00B5B9"
      />
    </SvgIcon>
  );
};
