import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Subtitle2 } from '@provider-portal/libs/uiFramework/components/text/Subtitle2';
import { primary } from '@provider-portal/styles/colors-standard';
import * as React from 'react';
import { Link } from 'react-router-dom';

const styles = () =>
  createStyles({
    listItemRoot: {
      borderRadius: '8px',
    },
    listItemSelected: {
      backgroundColor: `${alpha(primary.main, 0.25)} !important`,
      borderRadius: '8px',
    },
    gutterLeft: {
      paddingLeft: '32px',
    },
  });

type MenuItemInnerProps = {
  selected?: boolean;
  icon: React.ReactElement<Record<string, unknown>>;
  label: React.ReactNode;
  subItem?: boolean;
  chipText?: string;
} & ({ to: string; toExternal?: never } | { to?: never; toExternal: string });

type MenuItemInnerPropsStyled = MenuItemInnerProps & WithStyles<typeof styles>;

const MenuItemInner: React.FunctionComponent<MenuItemInnerPropsStyled> = ({
  selected = false,
  icon,
  label,
  to,
  toExternal,
  subItem,
  classes,
  chipText,
}) => {
  const LinkWithTo = React.forwardRef((props: any, ref: React.Ref<any>) =>
    to ? (
      <Link ref={ref} {...props} to={to} />
    ) : (
      // It's guaranteed that "toExternal" will be defined when "to" is undefined
      <Link ref={ref} {...props} to={{ pathname: toExternal }} target="_blank" rel="noreferrer" />
    )
  );
  LinkWithTo.displayName = 'LinkWithTo';
  const itemText = selected ? (
    <Subtitle2 textColor="default">{label}</Subtitle2>
  ) : (
    <Body textColor="default" colorVariant="dark">
      {label}
    </Body>
  );

  return (
    <ListItem
      selected={selected}
      component={LinkWithTo}
      button
      classes={{
        selected: classes.listItemSelected,
        button: selected ? classes.listItemSelected : classes.listItemRoot,
        gutters: subItem ? classes.gutterLeft : '',
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography primary={itemText} />
      {chipText && <Chip label={chipText} color="primary" />}
    </ListItem>
  );
};

export const MenuItem = withStyles(styles)(MenuItemInner);
