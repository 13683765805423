import { default as MUISvgIcon } from '@material-ui/core/SvgIcon';
import type { CSSProperties, ReactNode } from 'react';
import React from 'react';
import { colors } from '@provider-portal/styles/colors';

export interface IconProps {
  children?: ReactNode;
  nativeColor?: string;
  bold?: boolean;
  viewBox?: string;
  size?: number;
  className?: string;
  style?: CSSProperties;
  onClick?: React.ReactEventHandler<any>;
  'data-test'?: string;
}

export const SvgIcon: React.FunctionComponent<IconProps> = ({
  children,
  viewBox = '0 0 24 24',
  size = 24,
  nativeColor = colors.black,
  className,
  style,
  onClick,
  'data-test': dataTest,
}: IconProps) => (
  <MUISvgIcon
    style={{ fontSize: size, ...style }}
    htmlColor={nativeColor}
    onClick={onClick}
    viewBox={viewBox}
    className={className}
    data-test={dataTest}
  >
    {children}
  </MUISvgIcon>
);
