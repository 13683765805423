// NOTE: We are using reCaptcha Enterprise

import axios from 'axios';

declare global {
  interface Window {
    grecaptcha: {
      enterprise: ReCaptchaInstance;
    };
    captchaOnLoad: () => void;
  }
}

export type RecaptchaSiteKey = string;
export type RecaptchaToken = string;

export interface ReCaptchaInstance {
  ready: (cb: () => any) => void;
  execute: (sitekey: RecaptchaSiteKey, options: ReCaptchaExecuteOptions) => Promise<RecaptchaToken>;
  render: (id: string, options: ReCaptchaRenderOptions) => any;
}

export enum RecaptchaAction {
  Login = 'login',
}

export interface ReCaptchaExecuteOptions {
  action: RecaptchaAction;
}

export interface ReCaptchaRenderOptions {
  sitekey: RecaptchaToken;
  size: 'invisible';
}

export const recaptchaScriptUrl = (sitekey: RecaptchaSiteKey) =>
  `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`;

export const loadScriptByURL = (id: string, url: string, callback?: () => void) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

export async function getSitekey(): Promise<RecaptchaSiteKey> {
  return axios.get('/api/frontend/v1/recaptcha/sitekey').then(({ data }) => data);
}
