import { makeStyles } from '@material-ui/core/styles';
import { withAppContext } from '@provider-portal/app-context/react';
import LoanHouseIcon from '@provider-portal/assets/icons/LoanHouseIcon.svg';
import SaveDeskIcon from '@provider-portal/assets/icons/SaveDeskIcon.svg';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { MenuItem } from '@provider-portal/libs/uiFramework/components/Menu/components/MenuItem';
import type { MenuModuleProps } from '@provider-portal/libs/uiFramework/components/Menu/models';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';
import { useFetchProviderSettingsOnEnter } from '@provider-portal/saveDesk/SaveDeskMenuModule/use-fetch';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import startsWith from 'lodash/startsWith';
import React from 'react';

const useStyles = makeStyles(() => ({
  overline: {
    margin: '16px 0px 16px 16px',
  },
}));

const WelcomeMenuInnerComponent = ({ path, providerId }: Omit<MenuModuleProps, 'hasAdminAccess'>) => {
  const { loadingState } = useFetchProviderSettingsOnEnter(providerId);
  const pathPrefix = startsWith(path, '/admin') ? '/admin/suppliers' : '/suppliers';
  const homePagePath = `${pathPrefix}/${providerId}`;
  const retentionPagePath = `${pathPrefix}/${providerId}/retention`;
  const classes = useStyles();

  if (loadingState === PortalLoadingState.Success) {
    return (
      <>
        <Overline className={classes.overline}>
          <LocalizedMessage id="welcomeSection" />
        </Overline>
        <MenuItem
          label={<LocalizedMessage id="home" />}
          icon={<img src={LoanHouseIcon} alt="home" />}
          to={homePagePath}
          subItem
          selected={path === homePagePath}
        />
        <MenuItem
          label={<LocalizedMessage id="retention" />}
          icon={<img src={SaveDeskIcon} alt="retention" />}
          to={retentionPagePath}
          subItem
          selected={startsWith(path, retentionPagePath)}
        />
      </>
    );
  } else {
    return <div data-testid="welcomeMenuComponent-loading" />;
  }
};

export const WelcomeMenuComponent = withLocalization('Menu')(withAppContext(WelcomeMenuInnerComponent));
