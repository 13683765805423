import * as React from 'react';
import styles from './styles.scss';

interface Props {
  children?: React.ReactNode;
}

export const Surface: React.FunctionComponent<Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};
