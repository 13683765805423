import type { Location } from 'history';
import get from 'lodash/get';
import { useHistory, useLocation } from 'react-router-dom';
import URI from 'urijs';
import type { ITableConfig } from './config';
import { TableColumnSortDirection } from './config';

const SORT_DIRECTION_QUERY_KEY = 'sortDirection';
const COLUMN_SORTED_QUERY_KEY = 'sort';

function withSortDirection(sortDirection: TableColumnSortDirection, sortValue: number) {
  return sortDirection === TableColumnSortDirection.Ascending ? sortValue : sortValue * -1;
}

function defaultSort(value1: any = Number.MIN_SAFE_INTEGER, value2: any = Number.MIN_SAFE_INTEGER) {
  if (value1 < value2) {
    return -1;
  } else if (value1 > value2) {
    return 1;
  } else {
    return 0;
  }
}

function sortRow(
  config: ITableConfig,
  columnName: string,
  sortDirection: TableColumnSortDirection = TableColumnSortDirection.Ascending
): (item1: any, item2: any) => number {
  return (item1: any, item2: any) => {
    const maybeSortFromConfig = config.cells.find((c) => c.key === columnName)?.sort;

    if (maybeSortFromConfig && typeof maybeSortFromConfig === 'function') {
      return withSortDirection(sortDirection, maybeSortFromConfig(get(item1, columnName), get(item2, columnName)));
    } else if (maybeSortFromConfig) {
      return withSortDirection(
        sortDirection,
        defaultSort(get(item1, maybeSortFromConfig), get(item2, maybeSortFromConfig))
      );
    } else {
      return withSortDirection(sortDirection, defaultSort(get(item1, columnName), get(item2, columnName)));
    }
  };
}

function getQueryValues(location: Location): any {
  return URI(location.search).query(true) as any;
}

function getSortDirection(location: Location): TableColumnSortDirection | undefined {
  const sortDirectionExistsInQuery = URI(location.search).hasQuery(SORT_DIRECTION_QUERY_KEY, (value: string) =>
    Object.values(TableColumnSortDirection).find((d) => d === value)
  );

  return (sortDirectionExistsInQuery && getQueryValues(location)[SORT_DIRECTION_QUERY_KEY]) || undefined;
}

function getColumnSorted(config: ITableConfig, location: Location): string | undefined {
  const columnSortedExistsInQuery = URI(location.search).hasQuery(COLUMN_SORTED_QUERY_KEY, (value: string) =>
    config.cells.map((c) => c.key).includes(value)
  );

  return (columnSortedExistsInQuery && getQueryValues(location)[COLUMN_SORTED_QUERY_KEY]) || undefined;
}

export interface IUseSortReturnObject {
  maybeColumnSorted?: string;
  maybeSortDirection?: TableColumnSortDirection;
  dataSorted: any[];
}

export function useSort(config: ITableConfig, data: any[]): IUseSortReturnObject {
  const location = useLocation();
  const maybeSortDirection = getSortDirection(location);
  const maybeColumnSorted = getColumnSorted(config, location);

  const dataSorted = [...data].sort((item1: any, item2: any) => {
    const sortResult = sortRow(
      config,
      maybeColumnSorted || config.defaultSortColumn,
      maybeSortDirection || config.defaultSortDirection
    )(item1, item2);
    if (sortResult === 0) {
      return sortRow(config, config.defaultSortColumn)(item1, item2);
    } else {
      return sortResult;
    }
  });

  return { maybeColumnSorted, maybeSortDirection, dataSorted };
}

export function useUpdateQueryWithSortParameters() {
  const history = useHistory();

  return (
    previousColumnSorted: string | undefined,
    currentSortDirection: TableColumnSortDirection | undefined,
    nextColumnSorted: string
  ) => {
    let newSortDirection: TableColumnSortDirection;

    if (nextColumnSorted === previousColumnSorted) {
      newSortDirection =
        currentSortDirection === TableColumnSortDirection.Descending
          ? TableColumnSortDirection.Ascending
          : TableColumnSortDirection.Descending;
    } else {
      newSortDirection = TableColumnSortDirection.Ascending;
    }

    const newUrl = new URI({
      path: history.location.pathname,
      query: history.location.search,
    })
      .setQuery({ sort: nextColumnSorted, sortDirection: newSortDirection })
      .toString();

    history.replace(newUrl);
  };
}

export function useResetQuerySortParameters(): () => void {
  const history = useHistory();

  return () => {
    const newUrl = new URI({
      path: history.location.pathname,
      query: history.location.search,
    })
      .removeQuery([SORT_DIRECTION_QUERY_KEY, COLUMN_SORTED_QUERY_KEY])
      .toString();

    history.replace(newUrl);
  };
}
