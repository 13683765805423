import { LocalizedMessage } from '@provider-portal/internationalization';
import { CentesimalCostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CentesimalCostInput/config';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import {
  FieldArray,
  FieldArrayItemComponentType,
} from '@provider-portal/libs/uiFramework/components/fields/FieldArray/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { WidgetType } from '@provider-portal/libs/uiFramework/components/widgets/config';
import { AccommodationType } from '@provider-portal/products/models';
import * as React from 'react';
import { electricityCampaignSection } from '../common';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select/config';
import type { CampaignFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createCampaignFormLayout } from '@provider-portal/libs/uiFramework';
import { Market } from '@provider-portal/app-context/constants';
import { categories } from '@provider-portal/constants/categories';
import { Currency } from '@provider-portal/models/subscription';
import { Locale } from '@provider-portal/internationalization/locale';
import { MultiSelect } from '@provider-portal/libs/uiFramework/components/fields/MultiSelect/config';

enum DenmarkElectricityCampaignFieldNames {
  ONE_TIME_DISCOUNT = 'oneTimeDiscount',
  ACCOMMODATION_TYPE = 'accommodationType',
  USAGE_DISCOUNTS = 'usageDiscounts',
  LOWER_BOUND = 'lowerBound',
  UPPER_BOUND = 'upperBound',
  DISCOUNT_PER_KWH = 'discountPerKwh',
  CAMPAIGN_LENGTH = 'campaignLength',
  ELECTRICITY_AREAS = 'electricityAreas',
  FORM_TYPE = 'type',
}

enum DenmarkElectricityAreaOption {
  ElectricityAreaWest = 'DK1West',
  ElectricityAreaEast = 'DK2East',
}

const ALL_ELECTRICITY_AREAS: DenmarkElectricityAreaOption[] = Object.values(DenmarkElectricityAreaOption);

const currency = Currency.DKK;
const locale = Locale.daDK;

const denmarkElectricityCampaignSections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="monthlyDiscountsTitle" />,
    columns: 1,
    components: [
      {
        type: WidgetType.MONTHLY_DISCOUNT,
      },
      HiddenInput({
        name: DenmarkElectricityCampaignFieldNames.FORM_TYPE,
        defaultValue: 'DenmarkElectricityCampaignIncomingRequest',
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="usageDiscountsTitle" />,
    columns: 1,
    components: [
      FieldArray({
        name: DenmarkElectricityCampaignFieldNames.USAGE_DISCOUNTS,
        columns: 2,
        fields: [
          NumberInput({
            name: DenmarkElectricityCampaignFieldNames.LOWER_BOUND,
            label: <LocalizedMessage id="lowerBoundKwhLabel" />,
            required: true,
            numberType: NumberType.IntegerZeroOrGreater,
            locale: locale,
          }),
          NumberInput({
            name: DenmarkElectricityCampaignFieldNames.UPPER_BOUND,
            label: <LocalizedMessage id="upperBoundKwhLabel" />,
            required: true,
            numberType: NumberType.PositiveInteger,
            locale: locale,
          }),
          CentesimalCostInput({
            name: DenmarkElectricityCampaignFieldNames.DISCOUNT_PER_KWH,
            label: <LocalizedMessage id="discountLabelV2" />,
            required: true,
            currency: currency,
            locale: locale,
          }),
          NumberInput({
            name: DenmarkElectricityCampaignFieldNames.CAMPAIGN_LENGTH,
            label: <LocalizedMessage id="campaignDurationInMonthsLabel" />,
            required: true,
            numberType: NumberType.PositiveInteger,
            locale: locale,
          }),
          MultiSelect({
            name: DenmarkElectricityCampaignFieldNames.ELECTRICITY_AREAS,
            label: <LocalizedMessage id="electricityAreasLabel" />,
            required: true,
            allSelectedLabel: <LocalizedMessage id="allElectricityAreasSelectedLabel" />,
            options: Object.values(DenmarkElectricityAreaOption).map((value) => ({
              label: electricityAreaLabel(value),
              value,
            })),
            defaultValue: ALL_ELECTRICITY_AREAS,
          }),
        ],
        addItemButtonLabel: <LocalizedMessage id="addIntervalButtonLabel" />,
        itemComponent: {
          type: FieldArrayItemComponentType.FieldGroup1,
          title: <LocalizedMessage id="usageDiscountIntervalTitle" />,
          columns: 2,
        },
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="oneTimeCampaignsTitleV2" />,
    captionText: <LocalizedMessage id="oneTimeCampaignsCaptionText" />,
    columns: 2,
    components: [
      CostInput({
        name: DenmarkElectricityCampaignFieldNames.ONE_TIME_DISCOUNT,
        label: <LocalizedMessage id="welcomeDiscountLabelV2" />,
        currency: currency,
        locale: locale,
      }),
    ],
  },
];

const denmarkElectricityCampaignPapers: IFormPagePaper[] = [
  {
    sections: [electricityCampaignSection, ...denmarkElectricityCampaignSections],
  },
  {
    sections: [
      {
        caption: <LocalizedMessage id="accommodationTypeHeader" />,
        captionText: <LocalizedMessage id="campaignAccommodationTypeSection" />,
        columns: 4,
        components: [
          Select({
            name: DenmarkElectricityCampaignFieldNames.ACCOMMODATION_TYPE,
            defaultValue: AccommodationType.All,
            options: [
              { value: AccommodationType.All, label: <LocalizedMessage id="accommodationTypeAllLabel" /> },
              { value: AccommodationType.Apartment, label: <LocalizedMessage id="accommodationTypeApartmentLabel" /> },
              { value: AccommodationType.Villa, label: <LocalizedMessage id="accommodationTypeVillaLabel" /> },
            ],
            required: true,
          }),
        ],
      },
    ],
  },
];

export const denmarkElectricityCampaignLayoutMap: CampaignFormLayoutMap = createCampaignFormLayout(
  categories.electricity,
  Market.Denmark,
  denmarkElectricityCampaignPapers
);

function electricityAreaLabel(electricityArea: DenmarkElectricityAreaOption): React.ReactNode {
  if (electricityArea === DenmarkElectricityAreaOption.ElectricityAreaEast) {
    return 'Ost';
  } else {
    return 'Vast';
  }
}
