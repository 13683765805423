import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
// @ts-ignore js import, remove this when the import is typed
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { Snackbar } from '@provider-portal/libs/uiFramework/components/deprecated/Snackbar';
import { DividerSection } from '@provider-portal/libs/uiFramework/components/dividers/DividerSection';
import type { IField } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { FieldType } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { parseForm } from '@provider-portal/libs/uiFramework/components/fields/configs/parse-fields';
import { TextInputVariant } from '@provider-portal/libs/uiFramework/components/fields/TextInput';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { FieldSection } from '@provider-portal/libs/uiFramework/components/sections/FieldSection';
import { Section } from '@provider-portal/libs/uiFramework/components/sections/Section';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import SaveDeskIcon from '@provider-portal/saveDesk/SaveDeskOrderPage/SavedeskIcon.svg';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import * as React from 'react';
import { updateSaveDeskSettings } from '../../api';
import type { SaveDeskSettings } from '../../models';

const emailsFieldFromSettings = (saveDeskSettings?: SaveDeskSettings): IField => ({
  type: FieldType.TextValueArray,
  name: 'orderNotificationEmails',
  label: <LocalizedMessage id="saveDeskEmailFieldLabel" />,
  inputVariant: TextInputVariant.EMAIL,
  defaultValue: saveDeskSettings?.orderNotificationEmails || [],
});

interface ISaveDeskSettingsPageProps {
  providerId: string;
  saveDeskSettings?: SaveDeskSettings;
}

export const SaveDeskSettingsPage: React.FunctionComponent<ISaveDeskSettingsPageProps> = ({
  providerId,
  saveDeskSettings,
}) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const emailsField = emailsFieldFromSettings(saveDeskSettings);
  const onSubmit = debouncePromise(async (event: any) => {
    return updateSaveDeskSettings(providerId, parseForm(event.currentTarget)).then(() => setSnackbarOpen(true));
  });

  return (
    <>
      <Paper>
        <Form onValidSubmitWithEvent={async (event: any) => onSubmit(event)}>
          <Section
            caption={<LocalizedMessage id="saveDeskPageTitle" />}
            icon={<img src={SaveDeskIcon} alt="save-desk" />}
          >
            <Body1>
              <LocalizedMessage id="saveDeskSettingsPageDescription" />
            </Body1>
          </Section>
          <DividerSection />
          <FieldSection
            caption={<LocalizedMessage id="saveDeskEmailFieldSectionTitle" />}
            captionText={<LocalizedMessage id="saveDeskEmailFieldSectionDescription" />}
            columns={1}
            components={[emailsField]}
          />
          <div style={{ padding: '0 0 16px 16px' }}>
            <Button label={<LocalizedMessage id="saveDeskPageSaveButton" />} color="primary" type="submit" />
          </div>
        </Form>
      </Paper>
      <Snackbar
        autoClose
        variant="success"
        open={snackbarOpen}
        content={<LocalizedMessage id="saveDeskPageSnackbarSuccess" />}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};
