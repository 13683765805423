import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { Locale } from '@provider-portal/internationalization/locale';
import type { Market } from '@provider-portal/app-context/constants';

export async function fetchSupportedLocalesByProvider(providerId: string): Promise<Locale[]> {
  return axios
    .get(`/api/frontend/v1/internationalization/supported-locales/${providerId}`)
    .then((response: AxiosResponse<Locale[]>) => response.data);
}

export async function fetchSupportedLocalesByMarket(market: Market): Promise<Locale[]> {
  return axios
    .get(`/api/frontend/v1/internationalization/supported-locales?market=${market}`)
    .then((response: AxiosResponse<Locale[]>) => response.data);
}
