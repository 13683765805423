import type { InputValidationProps } from '@provider-portal/libs/uiFramework/components/deprecated/InputValidationHOC/types';
import { dark } from '@provider-portal/styles/colors-standard';
import type { InputBaseComponentProps } from '@material-ui/core/InputBase';
import type { WithStyles } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import type { OutlinedTextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { default as MuiTextField } from '@material-ui/core/TextField';
import omit from 'lodash/omit';
import values from 'lodash/values';
import type { ReactNode } from 'react';
import React from 'react';
import type { Omit } from 'recompose';
import { compose, defaultProps, mapProps } from 'recompose';
import InputValidationHOC from '@provider-portal/libs/uiFramework/components/deprecated/InputValidationHOC';

const styles = () =>
  createStyles({
    input: {
      margin: '8px 0',
      [`& fieldset`]: {
        borderRadius: '8px',
      },
    },
    inputWithoutBorder: { margin: '8px 0', '&::before': { borderBottom: `1px solid ${dark.main}` } },
  });

interface ExtendedProps {
  transformBlurValue?(value: any, valid?: boolean, errors?: Record<string, unknown> | any[]): string;
  requiredErrorText?: ReactNode | string;
  validators?: any;
  showErrorOnBlur?(): void | boolean;
  showErrorOnChange?(): void | boolean;
  clear?: boolean;
  errorText?: ReactNode | string;
  helperText?: ReactNode | string;
  withBorder?: boolean;
}

const TextFieldInner: React.FunctionComponent<TextFieldInnerProps> = ({
  errors,
  transformBlurValue,
  focused,
  valid,
  value,
  errorText,
  helperText,
  withBorder = true,
  classes,
  ...props
}) => {
  const outValue = transformBlurValue && !focused ? transformBlurValue(value, valid, errors) : value || '';
  const error = errors ? values(errors)[0] : errorText;
  const helper = error || helperText;

  const cleanProps: Omit<MuiTextFieldProps, 'variant'> = omit(props, [
    'showErrorOnBlur',
    'showErrorOnChange',
    'canShowError',
    'formSubmitted',
    'defaultValue',
    'clear',
  ]);

  const inputProps: InputBaseComponentProps = {
    style: { textAlign: 'center' },
  };

  return withBorder ? (
    <MuiTextField
      autoComplete="off"
      variant="outlined"
      classes={{ root: classes.input }}
      {...cleanProps}
      helperText={helper}
      error={Boolean(error)}
      value={outValue}
    />
  ) : (
    <MuiTextField
      autoComplete="off"
      classes={{ root: classes.inputWithoutBorder }}
      {...cleanProps}
      helperText={helper}
      error={Boolean(error)}
      value={outValue}
      inputProps={inputProps}
    />
  );
};

const inputValidationProps = defaultProps({
  showErrorOnBlur: true,
});

const textFieldValidatorsProps = mapProps(({ requiredErrorText, validators, ...props }: TextFieldInnerProps) => {
  const requiredValidator = (value: boolean) => {
    return requiredErrorText === undefined || value ? false : requiredErrorText;
  };

  const updatedValidators = {
    requiredValidator,
    ...validators,
  };

  return { validators: updatedValidators, ...props };
});

type TextFieldInnerProps = InputValidationProps & TextFieldProps & WithStyles<typeof styles>;
export type TextFieldProps = ExtendedProps & Omit<MuiTextFieldProps, 'variant'> & { name: string };

export const TextField = compose<TextFieldInnerProps, TextFieldProps>(
  textFieldValidatorsProps,
  inputValidationProps,
  InputValidationHOC,
  withStyles(styles)
)(TextFieldInner);
TextField.displayName = 'TextField';
