import type { IField } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { FieldType } from '@provider-portal/libs/uiFramework/components/fields/configs';
import type { IProviderCategoryFormData, ISupplierService } from '@provider-portal/provider/models';
import get from 'lodash/get';
import { ProviderFormFieldNames } from '../../config';

export function mapSectionFieldConfig(
  index: number,
  categoryName: string,
  categoryFormData: IProviderCategoryFormData,
  onCategoryChanged: (categoryName: string, formData: IProviderCategoryFormData) => void,
  supplierServices: Map<string, ISupplierService[]>,
  orderServices: string[]
): (config: IField) => IField {
  return (config: IField) => {
    const field: IField = {
      ...config,
      name: `${ProviderFormFieldNames.CATEGORIES}[${index}].${config.name}`,
      //We need to use lodash get here because we have property keys that can also be paths to object properties in the form data.
      //See for example category details that would have keys like `details.networkOperator`.
      defaultValue: get(categoryFormData, config.name),
    };

    if ([FieldType.Text, FieldType.Number].includes(config.type)) {
      field.onBlur = (e: any) => {
        onCategoryChanged(categoryName, { ...categoryFormData, [config.name]: e.target.value });
      };
    } else if (config.type === FieldType.Select) {
      field.onChange = (e: any) => {
        onCategoryChanged(categoryName, { ...categoryFormData, [config.name]: e.target.value });
      };
    } else if (config.type === FieldType.Checkbox) {
      field.onChange = (e: any) => {
        onCategoryChanged(categoryName, { ...categoryFormData, [config.name]: e.target.checked });
      };
    }

    if (config.name === ProviderFormFieldNames.SERVICE_ID) {
      field.options = (supplierServices.get(categoryName) || []).map((s) => ({ label: s.name, value: s.id }));
    }

    if (config.name === ProviderFormFieldNames.ORDER_SERVICE_IDENTIFIER) {
      field.options = orderServices.map((s) => ({ label: s, value: s }));
    }

    return field;
  };
}
