import {
  RadioButton,
  RadioGroup as MinnaRadioGroup,
} from '@provider-portal/libs/uiFramework/components/deprecated/RadioButton';
import * as React from 'react';
import { FieldMessage } from '../FieldMessage';
import type { IOption } from '../Select/option';
import type { IRadioGroup } from './config';

interface IRadioButtonGroup1Props extends Omit<IRadioGroup, 'type'> {
  options: IOption[];
  layout?: 'row' | 'column';
  onChange?(value: string): void;
}

export const RadioGroup: React.FunctionComponent<IRadioButtonGroup1Props> = ({
  name,
  defaultValue,
  options,
  requiredErrorText,
  layout = 'row',
  required,
  onChange,
}) => {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <MinnaRadioGroup
      name={name}
      value={value}
      layout={layout}
      requiredErrorText={requiredErrorText || (required && <FieldMessage id="portalRequiredField" />)}
    >
      {options.map((option) => {
        return (
          <RadioButton
            key={option.value}
            checked={value === option.value}
            value={option.value}
            label={option.label}
            onChange={(e) => handleChange(e.target.value)}
          />
        );
      })}
    </MinnaRadioGroup>
  );
};
