import { SelectField } from '@provider-portal/libs/uiFramework/components/deprecated/SelectField';
import { sortLexicographically } from '@provider-portal/libs/uiFramework/components/fields/Select/sortLexicographically';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';
import { FieldMessage } from '../FieldMessage';
import type { ISelect } from './config';
import type { IOption } from './option';
import styles from './styles.scss';

interface ISelectProps extends Omit<ISelect, 'type'> {
  defaultValue?: string;
  backgroundColor?: string;
  onChange?(e: React.ChangeEvent<{ name?: string; value: string }>): void;
  value?: string;
  disabled?: boolean;
  excludeNoneOption?: boolean;
}

export const Select: React.FunctionComponent<ISelectProps> = (props) => {
  const [valueWhenUncontrolled, setValueUncontrolled] = React.useState<string | undefined>(props.defaultValue);
  const currentValue = props.value || valueWhenUncontrolled;
  const inputStyles =
    (props.backgroundColor && { style: { background: props.backgroundColor, borderRadius: '8px' } }) || undefined;

  const handleOnChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (e.target.value === undefined || typeof e.target.value === 'string') {
      setValueUncontrolled(e.target.value);
      if (props.onChange) {
        props.onChange(e as React.ChangeEvent<{ name?: string; value: string }>);
      }
    } else {
      throw new Error(`Unknown type ${typeof e.target.value} of SelectField option value`);
    }
  };

  const sortedOptions = sortLexicographically(props.options || []);

  return (
    <div className={styles.container}>
      <SelectField
        name={props.name}
        value={currentValue}
        fullWidth
        label={(props.required && props.label && <>{props.label} *</>) || props.label}
        SelectDisplayProps={inputStyles}
        required={props.required}
        requiredErrorText={props.required && (props.requiredErrorText || <FieldMessage id="portalRequiredField" />)}
        onChange={handleOnChange}
        disabled={props.disabled}
      >
        {!props.required && !props.excludeNoneOption && (
          <MenuItem key="none" value={undefined}>
            <ListItemText primary={<FieldMessage id="noneSelected" />} />
          </MenuItem>
        )}
        {renderOptions(sortedOptions)}
      </SelectField>
    </div>
  );
};

function renderOptions(options: IOption[]) {
  return options.map((option) => {
    return (
      <MenuItem key={option.value} value={option.value}>
        <ListItemText primary={option.label} />
      </MenuItem>
    );
  });
}
