import type { Market } from '@provider-portal/app-context/constants';
import InputValidationHOC from '@provider-portal/libs/uiFramework/components/deprecated/InputValidationHOC';
import { KeyboardArrowLeftIcon } from '@provider-portal/libs/uiFramework/components/deprecated/KeyboardArrowLeftIcon';
import { KeyboardArrowRightIcon } from '@provider-portal/libs/uiFramework/components/deprecated/KeyboardArrowRightIcon';
import MomentUtils from '@date-io/moment';
import type { WithStyles } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import values from 'lodash/values';
import { default as MUIDatePicker } from 'material-ui-pickers/DatePicker';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import * as React from 'react';
import { compose, defaultProps, mapProps } from 'recompose';
import { FieldMessage } from '../FieldMessage';
import { Locale } from '@provider-portal/internationalization/locale';

const styles = () =>
  createStyles({
    root: { borderRadius: '8px' },
  });

export interface DatePickerFormatProps {
  format: string;
  mask: (string | RegExp)[] | ((value: string) => (string | RegExp)[]);
  placeholder: string;
}

export interface DatePickerProps {
  locale: Locale;
  market: Market;
  autoSelect?: boolean;
  fullWidth?: boolean;
  clear?: boolean;
  disabled?: boolean;
  DateFormatProps?: DatePickerFormatProps;
  errors?: any;
  helperText?: React.ReactNode;
  invalidDateMessage?: React.ReactNode;
  label?: React.ReactNode;
  maxDate?: Date;
  maxDateMessage?: React.ReactNode;
  minDate?: Date;
  minDateMessage?: React.ReactNode;
  name?: string;
  openToYearSelection?: boolean;
  requiredErrorText?: string | React.ReactNode;
  showClearButton?: boolean;
  showTodayButton?: boolean;
  showErrorOnChange?: boolean;
  showErrorOnBlur?: boolean;
  initialFocusedDate?: Date;
  value?: Date;
  required?: boolean;
  backgroundColor?: string;
  shouldDisableDate?(day: any, market: Market): void;
  onChange?(event: any): void;
  onBlur?(event: any): void;
  onClose?(): void;
  onOpen?(): void;
}

export interface DatePickerOuterProps extends DatePickerProps {
  defaultValue?: Date;
}

const DatePickerComponent: React.FunctionComponent<DatePickerProps & WithStyles<typeof styles>> = ({
  fullWidth,
  locale,
  market,
  autoSelect = true,
  classes,
  disabled,
  errors,
  DateFormatProps = localeAppropriateDateFormat(locale),
  invalidDateMessage = <FieldMessage id="incorrectDateFormatError" values={{ format: DateFormatProps.format }} />,
  label,
  maxDate,
  maxDateMessage = <FieldMessage id="maximumDateError" />,
  minDate,
  minDateMessage = <FieldMessage id="minimumDateError" />,
  name,
  openToYearSelection,
  showClearButton = true,
  showTodayButton,
  value = null,
  backgroundColor,
  shouldDisableDate,
  onBlur,
  initialFocusedDate,
  onChange,
  required,
}) => {
  const error: any = errors ? { error: true, helperText: values(errors)[0] } : undefined;

  function handleBlur(event: any) {
    const res = { target: { value: event.target && event.target.value ? new Date(event.target.value) : undefined } };
    if (onBlur) {
      onBlur(res);
    }
    if (onChange) {
      onChange(res);
    }
  }

  function handleChange(date: any) {
    if (onChange) {
      onChange({ target: { value: date ? date.toDate() : undefined } });
    }
  }

  const dateFormat: DatePickerFormatProps = DateFormatProps || localeAppropriateDateFormat(locale);
  // TODO: upgrade this component to a more recent MUI version

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MUIDatePicker
        style={{ borderRadius: '8px', background: backgroundColor }}
        onBlur={handleBlur}
        required={required}
        variant="outlined"
        autoOk={autoSelect}
        clearable={showClearButton}
        disabled={disabled}
        format={dateFormat.format}
        fullWidth={fullWidth}
        InputLabelProps={{ shrink: true, classes }}
        invalidDateMessage={invalidDateMessage}
        label={label}
        mask={dateFormat.mask}
        minDate={minDate}
        minDateMessage={minDateMessage}
        maxDate={maxDate}
        maxDateMessage={maxDateMessage}
        name={name}
        openToYearSelection={openToYearSelection}
        placeholder={dateFormat.placeholder}
        showTodayButton={showTodayButton}
        value={value}
        onChange={handleChange}
        leftArrowIcon={<KeyboardArrowLeftIcon />}
        rightArrowIcon={<KeyboardArrowRightIcon />}
        cancelLabel={<FieldMessage id="cancel" />}
        clearLabel={<FieldMessage id="clear" />}
        okLabel={<FieldMessage id="ok" />}
        todayLabel={<FieldMessage id="today" />}
        shouldDisableDate={(date: Date) => shouldDisableDate && shouldDisableDate(date, market)}
        initialFocusedDate={initialFocusedDate}
        {...error}
      />
    </MuiPickersUtilsProvider>
  );
};

const inputValidationProps = defaultProps({
  showErrorOnChange: true,
  showErrorOnBlur: true,
});

// The props should be typed as a union of the component properties and the InputValidationHOC properties
const requiredToValidatorsProps = mapProps(({ requiredErrorText, validators, ...props }: any) => {
  if (requiredErrorText) {
    const updatedValidators = {
      required(value: boolean) {
        return value ? false : requiredErrorText;
      },
      ...validators,
    };

    return { validators: updatedValidators, ...props };
  } else {
    return { validators, ...props };
  }
});

export const DatePicker = compose<DatePickerProps & WithStyles<typeof styles>, DatePickerOuterProps>(
  requiredToValidatorsProps,
  inputValidationProps,
  InputValidationHOC,
  withStyles(styles)
)(DatePickerComponent);

DatePicker.displayName = 'DatePicker';
const normalDateFormat = 'DD/MM/YYYY';

const localeAppropriateDateFormat = (locale: Locale): DatePickerFormatProps => {
  switch (locale) {
    case Locale.daDK:
      return {
        format: normalDateFormat,
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
        placeholder: 'DD/MM/ÅÅÅÅ',
      };
    case Locale.enGB:
      return {
        format: normalDateFormat,
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
        placeholder: normalDateFormat,
      };
    case Locale.nbNO:
      return {
        format: 'DD.MM.YYYY',
        mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
        placeholder: 'DD.MM.YYYY',
      };
    case Locale.svSE:
    default:
      return {
        format: 'YYYY-MM-DD',
        mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
        placeholder: 'ÅÅÅÅ-MM-DD',
      };
  }
};
