import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { createPartnerProvider, uploadLogotypeImage } from '../api';
import type { IProviderFormData } from '../models';
import { ProviderForm } from '../ProviderForm';
import { useMarketFromQueryParam } from '@provider-portal/utils/useMarketFromQueryParam';

export const AddProviderInner: React.FunctionComponent = () => {
  const history = useHistory();
  const maybeMarket = useMarketFromQueryParam();

  const createProvider = debouncePromise(async (formData: IProviderFormData) => {
    if (formData.logotype) {
      const resourceName = await uploadLogotypeImage(formData.logotype);
      formData.logotypeResourceName = resourceName;
      formData.logotypeFileName = formData.logotype.name;
    }
    await createPartnerProvider(formData);
    navigateToProviderList();
  });

  const navigateToProviderList = () => {
    const listProvidersUrl = URI(history.location.pathname).segment(-1, '').toString();
    history.replace(listProvidersUrl);
  };

  if (maybeMarket) {
    return (
      <ProviderForm
        market={maybeMarket}
        appBarTitle={<LocalizedMessage id="addProviderTitleBar" />}
        onSubmit={createProvider}
        rightSidedButtons={[
          <Button key="save" label={<LocalizedMessage id="saveChange" />} color="positive" type="submit" />,
        ]}
      />
    );
  } else {
    navigateToProviderList();
    return null;
  }
};

export const AddProvider = withLocalization('SupplierPortal/PartnerSupplier')(AddProviderInner);
