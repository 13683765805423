import React, { useRef, useState } from 'react';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import styles from './styles.scss';
import type { LocalizeMessage } from '@provider-portal/internationalization';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { CalendarIcon } from '@provider-portal/assets/icons/CalendarIcon';
import { colors } from '@provider-portal/styles/colors';
import { default as MuiMenu } from '@material-ui/core/Menu';
import { default as MuiMenuItem } from '@material-ui/core/MenuItem';
import { createDateLabelText } from '@provider-portal/orders/switch/ListSwitchOrders/DateFilterButton/createDateLabelText';
import { DatePicker } from '@provider-portal/orders/switch/ListSwitchOrders/DatePicker';
import PropTypes from 'prop-types';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';
import moment from 'moment';

export enum DateFilterPeriod {
  AllTime = 'allTime',
  Last10Days = 'last10Days',
  Last30Days = 'last30Days',
  Custom = 'custom',
}

export interface DateFilterSetting {
  period: DateFilterPeriod;
  startDate?: Date;
  endDate?: Date;
}

export interface IDateFilterButtonProps {
  disabled: boolean;
  activeDateFilterOption: DateFilterPeriod;
  onSelectDateFilterOption(setting: DateFilterSetting): void;
}

interface IDateFilterOption {
  text: React.ReactNode;
  value: DateFilterPeriod;
}

const DateFilterButtonInner: React.FunctionComponent<IDateFilterButtonProps> = (
  { disabled, activeDateFilterOption, onSelectDateFilterOption },
  { localizeMessage }: { localizeMessage: LocalizeMessage }
) => {
  const [menuOpen, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectDateButton = useRef(null);
  const [selectedDateFilterOption, setSelectedDateFilterOption] = useState(activeDateFilterOption);

  const customDateMenuVisible = selectedDateFilterOption === DateFilterPeriod.Custom;

  const options: IDateFilterOption[] = [
    { text: <LocalizedMessage id="selectDateOptionAllTime" />, value: DateFilterPeriod.AllTime },
    {
      text: <LocalizedMessage id="selectDatePeriodOption" values={{ days: 10 }} />,
      value: DateFilterPeriod.Last10Days,
    },
    {
      text: <LocalizedMessage id="selectDatePeriodOption" values={{ days: 30 }} />,
      value: DateFilterPeriod.Last30Days,
    },
  ];

  const buttonLabel = (
    <span data-testid="selectDateButtonLabel" className={styles.dateFilterButtonLabel}>
      <CalendarIcon nativeColor={colors.primaryDark} className={styles.dateFilterButtonCalendarIcon} />
      {createDateLabelText({
        defaultText: <LocalizedMessage id="selectDateButtonLabel" />,
        datePeriod: createDateFilterSetting(),
        now: new Date(),
      })}
    </span>
  );

  return (
    <div ref={selectDateButton} className={styles.dateFilterButtonWrapper}>
      <Button color="secondary" variant="outlined" onClick={openMenu} disabled={disabled} label={buttonLabel} />
      <MuiMenu
        open={menuOpen}
        onClose={closeMenu}
        anchorEl={selectDateButton.current}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div className={styles.menuOptions}>
          <div>
            <Overline
              style={{
                padding: '6px 0 6px 16px',
              }}
            >
              DATE
            </Overline>
            {options.map((option) => (
              <MuiMenuItem
                key={option.value}
                onClick={() => selectDateFilterOption(option.value)}
                selected={option.value === selectedDateFilterOption}
              >
                {option.text}
              </MuiMenuItem>
            ))}
            <div style={{ borderColor: colors.grey4 }} className={styles.customDateMenuButtonWrapper}>
              <MuiMenuItem
                selected={DateFilterPeriod.Custom === selectedDateFilterOption}
                onClick={() => selectCustomDateFilterOption()}
              >
                <LocalizedMessage id="customDateButtonLabel" />
              </MuiMenuItem>
            </div>
          </div>
          {customDateMenuVisible && (
            <div style={{ borderColor: colors.grey4 }} className={styles.customDateMenu}>
              <div className={styles.datePickerRow}>
                <DatePicker
                  value={startDate}
                  label={localizeMessage('customDateStartDateLabel')}
                  maxDate={endDate}
                  onChange={onStartDateChange}
                />
              </div>
              <div className={styles.datePickerRow}>
                <DatePicker
                  value={endDate}
                  label={localizeMessage('customDateEndDateLabel')}
                  minDate={startDate}
                  maxDate={new Date()}
                  onChange={onEndDateChange}
                />
              </div>
              <Button
                color="secondary"
                variant="outlined"
                label={<LocalizedMessage id="applyButtonLabel" />}
                onClick={onCustomDatesApplyClick}
              />
            </div>
          )}
        </div>
      </MuiMenu>
    </div>
  );

  function selectCustomDateFilterOption() {
    setSelectedDateFilterOption(DateFilterPeriod.Custom);

    const newStartDate = getDateRangeFromDatePeriod(activeDateFilterOption);
    const newEndDate = moment.utc().startOf('day').toDate();
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }

  function getDateRangeFromDatePeriod(period: DateFilterPeriod) {
    switch (period) {
      case DateFilterPeriod.Last10Days:
        return moment.utc().startOf('day').subtract(10, 'day').toDate();
      case DateFilterPeriod.Last30Days:
        return moment.utc().startOf('day').subtract(30, 'day').toDate();
      default:
        return moment.utc().startOf('day').toDate();
    }
  }

  function onStartDateChange(event: any) {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
  }

  function onEndDateChange(event: any) {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
  }

  function onCustomDatesApplyClick() {
    applyCustomDateFilter(startDate, endDate);
    closeMenu();
  }

  function applyCustomDateFilter(newStartDate: Date, newEndDate: Date) {
    setStartDate(newStartDate);
    setEndDate(newEndDate);

    onSelectDateFilterOption({
      period: DateFilterPeriod.Custom,
      startDate: newStartDate,
      endDate: newEndDate,
    });
  }

  function selectDateFilterOption(dateFilterPeriod: DateFilterPeriod) {
    setSelectedDateFilterOption(dateFilterPeriod);
    onSelectDateFilterOption({ period: dateFilterPeriod });
    closeMenu();
  }

  function createDateFilterSetting() {
    return { period: selectedDateFilterOption, startDate, endDate };
  }

  function closeMenu() {
    setOpen(false);
  }

  function openMenu() {
    setSelectedDateFilterOption(activeDateFilterOption);
    setOpen(true);
  }
};

DateFilterButtonInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const DateFilterButton = withLocalization('SupplierPortal/orders')(DateFilterButtonInner);
