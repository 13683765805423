import type { TextByLanguage } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/config';
import type { CategoryName } from '@provider-portal/constants/categories';
import type { Market } from '@provider-portal/app-context/constants';

export enum CategoryDetailsFormDataType {
  EmptyCategoryDetailsFormData = 'EmptyCategoryDetailsFormData',
  SwedenMobileFormData = 'SwedenMobileFormData',
  SwedenElectricityFormData = 'SwedenElectricityFormData',
  DenmarkMobileFormData = 'DenmarkMobileFormData',
  BelgiumElectricityFormData = 'BelgiumElectricityFormData',
  BelgiumGasFormData = 'BelgiumGasFormData',
  BelgiumMobileFormData = 'BelgiumMobileFormData',
}

export interface IProviderCategoryFormData {
  categoryName: CategoryName;
  termsUrl?: string;
  businessContactEmail?: string;
  orderServiceIdentifier?: string;
  serviceIdentifier?: string;
  sellingPoint1?: TextByLanguage;
  sellingPoint2?: TextByLanguage;
  sellingPoint3?: TextByLanguage;
  details?: any;
  [key: string]: any;
}

export interface IProviderFormData {
  logotype?: File;
  logotypeResourceName?: string;
  logotypeFileName?: string;
  name?: string;
  website?: string;
  description?: string;
  supplierId?: string;
  paymentOptions?: string[];
  supportChannels?: string[];
  categories?: IProviderCategoryFormData[];
  isActive?: boolean;
  market?: Market;
  [key: string]: any;
}

export interface ISupplierService {
  id: string;
  name: string;
  categoryName: string;
}

export enum PaymentOption {
  PaperInvoice = 'PaperInvoice',
  ElectronicInvoice = 'ElectronicInvoice',
  EmailInvoice = 'EmailInvoice',
  DirectDebit = 'DirectDebit',
  Kivra = 'Kivra',
}

export enum SupportChannel {
  Phone = 'Phone',
  Email = 'Email',
  Facebook = 'Facebook',
  Chat = 'Chat',
  Store = 'Store',
}
