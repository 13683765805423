import * as React from 'react';
import { InfoPaper } from './';
import type { IInfoPaper } from './config';

interface IProps {
  papers: IInfoPaper[];
}

export const InfoPapers: React.FunctionComponent<IProps> = ({ papers }) => {
  return (
    <>
      {papers.map((paper, index) => (
        <InfoPaper key={index} {...paper} />
      ))}
    </>
  );
};
