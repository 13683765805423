import { LocalizedMessage } from '@provider-portal/internationalization';
import LogoutIcon from '@provider-portal/assets/icons/logout.svg';
import * as React from 'react';
import { MenuItem } from '../MenuItem';

export const SignOutMenuItem: React.FunctionComponent = () => {
  return (
    <MenuItem label={<LocalizedMessage id="signOut" />} icon={<img src={LogoutIcon} alt="sign out" />} to="/logout" />
  );
};
