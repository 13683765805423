import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { NavigationPage } from '@provider-portal/libs/uiFramework/components/pages/NavigationPage';
import { PortalLoadingState } from '../types/loading-state';
import { fetchSettings } from './api';
import { PartnerProviderSettingsActions, selectorSettings } from './duck';
import { SaveDeskSettingsPage } from './pages/SaveDeskSettingsPage';

const SettingsPageInner: React.FunctionComponent<RouteComponentProps<any>> = (props) => {
  const providerId = props.match.params.providerId;
  const [loadingState, setLoadingState] = React.useState(PortalLoadingState.Loading);
  const dispatch = useDispatch();
  const settings = useSelector(selectorSettings);

  React.useEffect(() => {
    fetchSettings(providerId)
      .then((fetchedSettings) => {
        dispatch(PartnerProviderSettingsActions.initialize(fetchedSettings));
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
  }, [dispatch, providerId]);

  return (
    <LoadingPage loadingState={loadingState}>
      <NavigationPage
        variant="details-grey"
        appBarText={<LocalizedMessage id="settings" />}
        {...props}
        pages={[
          {
            path: 'save-desk',
            menuLabel: <LocalizedMessage id="saveDeskMenuItem" />,
            // eslint-disable-next-line react/display-name
            component: () => <SaveDeskSettingsPage providerId={providerId} saveDeskSettings={settings.saveDesk} />,
          },
        ]}
      />
    </LoadingPage>
  );
};

export const SettingsPage = withLocalization('SupplierPortal/settings')(SettingsPageInner);
