import { TextField } from '@provider-portal/libs/uiFramework/components/deprecated/TextField';
import * as React from 'react';
import { AttributeNames } from '../configs';
import type { IHiddenInput } from './config';
import { HiddenInputDataFormat } from './config';

export interface IHiddenInputProps extends Omit<IHiddenInput, 'type'> {
  value?: string | Record<string, unknown> | any[];
}

export const HiddenInput: React.FunctionComponent<IHiddenInputProps> = (props) => {
  const value = props.value || props.defaultValue;

  const dataFormat = props.dataFormat || HiddenInputDataFormat.PLAIN;
  if (dataFormat === HiddenInputDataFormat.PLAIN && value && typeof value !== 'string') {
    throw new Error('When using plain data format only string is allowed as value type');
  }

  let formattedValue: string | undefined;
  if (dataFormat === HiddenInputDataFormat.JSON && value) {
    formattedValue = JSON.stringify(value);
  } else if (dataFormat === HiddenInputDataFormat.PLAIN && value && typeof value === 'string') {
    formattedValue = value;
  } else if (dataFormat === HiddenInputDataFormat.COST_WITH_CURRENCY && value) {
    formattedValue = JSON.stringify(value);
  }

  return (
    <TextField
      name={props.name}
      value={formattedValue}
      type="hidden"
      style={{ display: 'none' }}
      inputProps={{ [AttributeNames.DATA_FORMAT]: dataFormat }}
    />
  );
};
