import * as React from 'react';
import { Panel1Icon } from './Panel1Icon';
import { colors } from '@provider-portal/styles/colors';

const styles = {
  card: (variant: Panel1Variant) => ({
    backgroundColor: colors.background,
    boxShadow:
      (variant === 'raised' &&
        '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)') ||
      undefined,
    border: variant === 'flat' ? `1px solid ${colors.grey4}` : 'none',
    borderRadius: '8px',
    height: '100%',
  }),
  header: (title?: React.ReactNode) => ({
    display: 'flex',
    justifyContent: title ? 'space-between' : 'flex-end',
    alignItems: 'flex-start',
  }),
  footer: {
    marginTop: '16px',
  },
  children: (title?: React.ReactNode) => ({ marginTop: title ? '16px' : '0px' }),
  padding: {
    padding: '16px',
  },
};

type Panel1Variant = 'flat' | 'raised';

interface IPanel1Props {
  variant?: Panel1Variant;
  title?: React.ReactNode;
  icon?: React.ReactNode;
  onIconClick?(): void;
  footer?: React.ReactNode;
  children?: React.ReactNode;
}

export const Panel1: React.FunctionComponent<IPanel1Props> = ({
  variant = 'raised',
  title,
  icon,
  onIconClick,
  children,
  footer,
}) => {
  return (
    <div style={styles.card(variant)}>
      <div style={styles.padding}>
        <div style={styles.header(title)}>
          {title}
          {icon && <Panel1Icon icon={icon} onClick={onIconClick} />}
        </div>
        <div style={styles.children(title)}>
          {children}
          {footer && <div style={styles.footer}>{footer}</div>}
        </div>
      </div>
    </div>
  );
};
