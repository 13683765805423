import * as React from 'react';
import type { PageComponent } from '../config';
import { pageConfigToComponent } from '../pageConfigToComponent';
import styles from './styles.scss';

interface IProps {
  components: PageComponent[];
}

export const TwoColumnLayout: React.FunctionComponent<IProps> = ({ components }) => {
  const first = components.filter((c) => c.column === 1).map(pageConfigToComponent);
  const second = components.filter((c) => c.column === 2).map(pageConfigToComponent);

  return (
    <div className={styles.grid}>
      <div className={styles.columnOne}>{first}</div>
      {second.length > 0 && <div className={styles.columnTwo}>{second}</div>}
    </div>
  );
};
