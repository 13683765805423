import * as React from 'react';
import { Paper } from '../Paper';
import type { IDetail } from './config';
import { DetailsCardItem } from './DetailCardItem';
import styles from './styles.scss';

interface IProps {
  title: string | React.ReactNode;
  details: IDetail[];
}

export const DetailsCard: React.FunctionComponent<IProps> = ({ details, title }) => {
  return (
    <Paper title={title}>
      <ul className={styles.list}>
        {details.map((d, i) => (
          <DetailsCardItem key={i} caption={d.caption} value={d.value} />
        ))}
      </ul>
    </Paper>
  );
};
