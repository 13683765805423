import * as React from 'react';
import type { ISaveDeskOffer2FormDataInner, ISaveDeskOffer2FormViewModel } from '../models';
import { parseForm } from '@provider-portal/libs/uiFramework/components/fields/configs/parse-fields';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import { FormPage } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { TranslatableFieldWidget } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/config';
import { enrichRequestWithFormData } from '@provider-portal/libs/uiFramework';
import { SectionNumber } from '@provider-portal/libs/uiFramework/components/SectionNumber';
import { MultiSelect } from '@provider-portal/libs/uiFramework/components/fields/MultiSelect/config';
import { Image } from '@provider-portal/libs/uiFramework/components/fields/Image/config';
import OfferExample from './OfferExample.svg';
import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput/config';

interface IAddSaveDeskProps {
  appBarTitle: React.ReactNode;
  viewModel: ISaveDeskOffer2FormViewModel;
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
  onSubmit(formData: ISaveDeskOffer2FormDataInner): Promise<void>;
  providerId: string;
}

const SaveDeskOfferFormInner: React.FunctionComponent<IAddSaveDeskProps> = ({
  appBarTitle,
  viewModel,
  leftSidedButtons,
  rightSidedButtons,
  onSubmit,
  providerId,
}) => {
  return (
    <FormPage
      appBarText={appBarTitle}
      papers={getSaveDeskOfferFormLayout(providerId, viewModel)}
      onSubmit={async (event) => onSubmit(parseForm(event.currentTarget))}
      leftSidedButtons={leftSidedButtons}
      rightSidedButtons={rightSidedButtons}
    />
  );
};

export const SaveDeskOfferForm = withLocalization('supplier-portal-save-desk')(SaveDeskOfferFormInner);

enum SaveDeskOfferFieldNames {
  CANCELLATION_REASONS = 'cancellationReasons',
  NAME = 'name',
  DESCRIPTION = 'description',
  EXTERNAL_OFFER_ID = 'externalOfferId',
  DISCLAIMER = 'disclaimer',
  IMAGE = 'image',
}

function getSaveDeskOfferFormLayout(providerId: string, viewModel: ISaveDeskOffer2FormViewModel): IFormPagePaper[] {
  const allCancellationReasons = [
    ...viewModel.cancellationReasons,
    ...viewModel.cancellationReasonsNotAttachedToAnOffer,
  ];

  return [
    {
      sections: [
        {
          caption: <LocalizedMessage id="selectCancelReasonHeader" />,
          captionText: <LocalizedMessage id="selectCancelReasonCaption" />,
          sectionNumber: <SectionNumber number={1} />,
          columns: 1,
          divider: 'bottom',
          components: [
            MultiSelect({
              name: SaveDeskOfferFieldNames.CANCELLATION_REASONS,
              label: <LocalizedMessage id="cancellationReasonsLabel" />,
              options: allCancellationReasons.map((reason) => ({
                label: <LocalizedMessage id={reason} />,
                value: reason,
              })),
              defaultValue: viewModel.cancellationReasons,
              required: true,
            }),
          ].map(enrichRequestWithFormData(providerId, '', viewModel)),
        },
        {
          caption: <LocalizedMessage id="describeTheOfferHeader" />,
          captionText: <LocalizedMessage id="describeTheOfferCaption" />,
          sectionNumber: <SectionNumber number={2} />,
          columns: 1,
          divider: 'bottom',
          components: [
            TranslatableFieldWidget({
              name: SaveDeskOfferFieldNames.NAME,
              title: <LocalizedMessage id="nameLabel" />,
              description: <LocalizedMessage id="offerNameDescription" />,
              defaultValue: viewModel.name,
              caption: <LocalizedMessage id="characterLimit" values={{ characterLimit: 40 }} />,
              maxlength: 40,
              required: true,
              tooltipTitle: <LocalizedMessage id="translationTooltipTitle" />,
              tooltipText: <LocalizedMessage id="translationTooltipText" />,
            }),
            TranslatableFieldWidget({
              name: SaveDeskOfferFieldNames.DESCRIPTION,
              title: <LocalizedMessage id="descriptionLabel" />,
              description: <LocalizedMessage id="offerDescriptionDescription" />,
              defaultValue: viewModel.description,
              caption: <LocalizedMessage id="characterLimit" values={{ characterLimit: 120 }} />,
              maxlength: 120,
              required: true,
              tooltipTitle: <LocalizedMessage id="translationTooltipTitle" />,
              tooltipText: <LocalizedMessage id="translationTooltipText" />,
            }),
            TranslatableFieldWidget({
              name: SaveDeskOfferFieldNames.DISCLAIMER,
              title: <LocalizedMessage id="disclaimerOptionalLabel" />,
              description: <LocalizedMessage id="offerDisclaimerDescription" />,
              defaultValue: viewModel.disclaimer,
              caption: <LocalizedMessage id="characterLimit" values={{ characterLimit: 120 }} />,
              maxlength: 120,
            }),
          ].map(enrichRequestWithFormData(providerId, '', viewModel)),
        },
        {
          caption: <LocalizedMessage id="externalOfferIdHeader" />,
          captionText: <LocalizedMessage id="externalOfferIdCaption" />,
          sectionNumber: <SectionNumber number={3} />,
          columns: 1,
          divider: 'bottom',
          components: [
            TextInput({
              name: SaveDeskOfferFieldNames.EXTERNAL_OFFER_ID,
              label: <LocalizedMessage id="externalOfferIdLabel" />,
            }),
          ].map(enrichRequestWithFormData(providerId, '', viewModel)),
        },
      ],
      column: 1,
    },
    {
      sections: [
        {
          caption: <LocalizedMessage id="pageExampleTitle" />,
          captionText: <LocalizedMessage id="pageExampleDescription" />,
          columns: 1,
          divider: 'bottom',
          components: [
            Image({
              name: SaveDeskOfferFieldNames.IMAGE,
              src: OfferExample,
              alt: 'example',
            }),
          ],
        },
      ],
      column: 2,
    },
  ];
}
