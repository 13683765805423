import type { IInputField } from '../configs';
import { fieldConstructor, FieldType } from '../configs';
import type { IOption } from './option';

export interface ISelect extends IInputField {
  type: FieldType.Select;
  options?: IOption[];
  defaultValue?: string;
}

export const Select = fieldConstructor<ISelect>(FieldType.Select);
