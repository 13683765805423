import { Sentry } from '@provider-portal/sentry';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { createPartnerProduct } from '../api';
import type { IProductFormData } from '../models';
import { SubmitType } from '../models';
import { ProductForm } from '../ProductForm';
import { PublishButton } from './PublishButton';
import { useSelector } from 'react-redux';
import { selectorMarketFromMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';

interface IAddProductProps {
  providerId: string;
  categoryName: string;
}

const AddProductInner: React.FunctionComponent<IAddProductProps> = ({ providerId, categoryName }) => {
  const [submitType, setSubmitType] = React.useState<SubmitType | undefined>();
  const history = useHistory();
  const [loadingState, setLoadingState] = React.useState(PortalLoadingState.Success);
  const market = useSelector(selectorMarketFromMaybePartnerSupplier);

  const createProduct = debouncePromise(async (formData: IProductFormData) => {
    if (submitType === SubmitType.PUBLISH) {
      formData.changesApproved = true;
    } else if (submitType === SubmitType.REQUEST_APPROVAL) {
      formData.requestApproval = true;
    }

    try {
      const createdProduct = await createPartnerProduct(providerId, formData);
      const editProductUrl = URI(history.location.pathname)
        .segment(-1, createdProduct.id || '')
        .toString();
      history.replace(editProductUrl);
    } catch (error) {
      setLoadingState(PortalLoadingState.Failure);
      Sentry.captureExceptionWithMessage(error, 'Failed to create product');
    }
  });

  return (
    <LoadingPage loadingState={loadingState}>
      <ProductForm
        appBarTitle={<LocalizedMessage id="addProductTitleBar" />}
        leftSidedButtons={[
          <Button
            key="delete"
            label={<LocalizedMessage id="archiveButton" />}
            color="negative"
            onClick={() => history.goBack()}
          />,
        ]}
        rightSidedButtons={[
          <Button
            key="save-draft"
            label={<LocalizedMessage id="saveDraftButton" />}
            color="secondary"
            type="submit"
            onClick={() => setSubmitType(SubmitType.SAVE_DRAFT)}
          />,
          <PublishButton key="publish" onClick={setSubmitType} />,
        ]}
        categoryName={categoryName}
        onSubmit={createProduct}
        providerId={providerId}
        formData={{}}
        market={market}
      />
    </LoadingPage>
  );
};

export const AddProduct = withLocalization('EditProduct')(AddProductInner);
