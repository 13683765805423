import * as React from 'react';
import type { Columns } from '../../fields/configs/columns';
import type { IInfoItem } from '../../InfoItem';
import { InfoItem } from '../../InfoItem';
import { ComponentsLayout } from '../../layouts/ComponentsLayout';
import { Section } from '../Section';

export interface IInfoSection {
  title: React.ReactNode;
  items: IInfoItem[];
  columns: Columns;
}

export const InfoSection: React.FunctionComponent<IInfoSection> = ({ title, items, columns }) => {
  const infoItems = items.map((item, index) => <InfoItem key={index} {...item} />);

  return (
    <Section caption={title}>
      <ComponentsLayout components={infoItems} columns={columns} />
    </Section>
  );
};
