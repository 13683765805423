import { LocalizedMessage } from '@provider-portal/internationalization';
import { electricityCampaignSection } from '@provider-portal/campaigns/CampaignForm/config/common';
import { FieldType } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { FieldArrayItemComponentType } from '@provider-portal/libs/uiFramework/components/fields/FieldArray/config';
import { MeasurementUnit } from '@provider-portal/libs/uiFramework/components/fields/MeasurementUnit';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import * as React from 'react';
import type { CampaignFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createCampaignFormLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { getDefaultLocale } from '@provider-portal/app-context/state';

enum BelgiumElectricityCampaignFieldNames {
  FORM_TYPE = 'type',
  ONE_TIME_DISCOUNT = 'oneTimeDiscount',
  USAGE_DISCOUNTS = 'usageDiscounts',
}

enum BelgiumElectricityCampaignUsageDiscountsFieldNames {
  CAMPAIGN_LENGTH = 'campaignLength',
  UPPER_BOUND = 'upperBound',
  LOWER_BOUND = 'lowerBound',
  SINGLE_METER_DISCOUNT = 'singleMeterDiscount',
  DUAL_METER_DAY_DISCOUNT = 'dualMeterDayDiscount',
  DUAL_METER_NIGHT_DISCOUNT = 'dualMeterNightDiscount',
  EXCLUSIVE_NIGHT_METER_DISCOUNT = 'exclusiveNightMeterDiscount',
}

const locale = getDefaultLocale(Market.Belgium);

const belgiumElectricityCampaignSections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="oneTimeDiscount" />,
    divider: 'top',
    columns: 4,
    components: [
      {
        name: BelgiumElectricityCampaignFieldNames.ONE_TIME_DISCOUNT,
        type: FieldType.Cost,
        label: <LocalizedMessage id="welcomeDiscountLabelV2" />,
      },
      {
        name: BelgiumElectricityCampaignFieldNames.FORM_TYPE,
        type: FieldType.Hidden,
        defaultValue: 'BelgiumElectricityCampaignIncomingRequest',
      },
    ],
  },
  {
    caption: <LocalizedMessage id="usageDiscountsTitle" />,
    divider: 'top',
    columns: 1,
    components: [
      {
        name: BelgiumElectricityCampaignFieldNames.USAGE_DISCOUNTS,
        type: FieldType.FieldArray,
        columns: 2,
        fields: [
          {
            name: BelgiumElectricityCampaignUsageDiscountsFieldNames.LOWER_BOUND,
            type: FieldType.Number,
            label: <LocalizedMessage id="lowerBoundKwhLabel" />,
            required: true,
            numberType: NumberType.IntegerZeroOrGreater,
          },
          {
            name: BelgiumElectricityCampaignUsageDiscountsFieldNames.UPPER_BOUND,
            type: FieldType.Number,
            label: <LocalizedMessage id="upperBoundKwhLabel" />,
            required: true,
            numberType: NumberType.PositiveInteger,
          },
          {
            label: <LocalizedMessage id="singleMeterDiscount" />,
            name: BelgiumElectricityCampaignUsageDiscountsFieldNames.SINGLE_METER_DISCOUNT,
            type: FieldType.UnitFieldFactory,
            unitFieldFactoryType: 'cent',
            unit: MeasurementUnit.KWH,
            units: [MeasurementUnit.KWH, MeasurementUnit.PERCENT],
            required: true,
          },
          {
            label: <LocalizedMessage id="dayNightMeterDayDiscount" />,
            name: BelgiumElectricityCampaignUsageDiscountsFieldNames.DUAL_METER_DAY_DISCOUNT,
            type: FieldType.UnitFieldFactory,
            unitFieldFactoryType: 'cent',
            unit: MeasurementUnit.KWH,
            units: [MeasurementUnit.KWH, MeasurementUnit.PERCENT],
            required: true,
          },
          {
            label: <LocalizedMessage id="dayNightMeterNightDiscount" />,
            name: BelgiumElectricityCampaignUsageDiscountsFieldNames.DUAL_METER_NIGHT_DISCOUNT,
            type: FieldType.UnitFieldFactory,
            unitFieldFactoryType: 'cent',
            unit: MeasurementUnit.KWH,
            units: [MeasurementUnit.KWH, MeasurementUnit.PERCENT],
            required: true,
          },
          {
            label: <LocalizedMessage id="nightOnlyMeterDiscount" />,
            name: BelgiumElectricityCampaignUsageDiscountsFieldNames.EXCLUSIVE_NIGHT_METER_DISCOUNT,
            type: FieldType.UnitFieldFactory,
            unitFieldFactoryType: 'cent',
            unit: MeasurementUnit.KWH,
            units: [MeasurementUnit.KWH, MeasurementUnit.PERCENT],
            required: true,
          },
          NumberInput({
            name: BelgiumElectricityCampaignUsageDiscountsFieldNames.CAMPAIGN_LENGTH,
            label: <LocalizedMessage id="campaignDurationInMonthsLabel" />,
            required: true,
            numberType: NumberType.PositiveInteger,
            locale: locale,
          }),
        ],
        addItemButtonLabel: <LocalizedMessage id="addIntervalButtonLabel" />,
        itemComponent: {
          type: FieldArrayItemComponentType.FieldGroup1,
          title: <LocalizedMessage id="usageDiscountIntervalTitle" />,
          columns: 1,
        },
      },
    ],
  },
];

const belgiumElectricityCampaignPapers: IFormPagePaper[] = [
  {
    sections: [electricityCampaignSection, ...belgiumElectricityCampaignSections],
  },
];

export const belgiumElectricityCampaignLayoutMap: CampaignFormLayoutMap = createCampaignFormLayout(
  categories.electricity,
  Market.Belgium,
  belgiumElectricityCampaignPapers
);
