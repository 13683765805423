import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { CheckCircleIcon } from '@provider-portal/libs/uiFramework/components/deprecated/CheckCircleIcon';
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { RadioGroup } from '@provider-portal/libs/uiFramework/components/fields/RadioGroup';
import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import { Section } from '@provider-portal/libs/uiFramework/components/sections/Section';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import { completeSaveDeskOrder, fetchSaveDeskOrder, rescindSaveDeskOffer } from '@provider-portal/saveDesk/api';
import { SaveDeskActions as SaveDeskActionsDispatch } from '@provider-portal/saveDesk/duck';
import type { ISaveDeskOrder } from '@provider-portal/saveDesk/models';
import { SaveDeskState } from '@provider-portal/saveDesk/models';
import { colors } from '@provider-portal/styles/colors';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch } from 'react-redux';

const styles = () =>
  createStyles({
    marginTop: {
      paddingTop: '16px',
    },
    responseCard: {
      marginTop: '-16px',
    },
  });

export interface ConfirmOrderSectionProps {
  saveDeskOrder: ISaveDeskOrder;
}

enum ResponseAction {
  Confirm = 'Confirm',
  Reject = 'Reject',
}

const ConfirmOrderSectionInner: React.FunctionComponent<ConfirmOrderSectionProps & WithStyles<typeof styles>> = (
  { saveDeskOrder, classes },
  { localizeMessage }
) => {
  const dispatch = useDispatch();
  const finished =
    saveDeskOrder.state.stateType === SaveDeskState.Success ||
    saveDeskOrder.state.stateType === SaveDeskState.RejectedByProvider;
  const [loading, setLoading] = React.useState(false);
  const [finishedOrder, setFinishedOrder] = React.useState(finished);
  const [showAcceptedButton, setShowAcceptedButton] = React.useState(true);
  const [reasonForRescinding, setReasonForRescinding] = React.useState('');
  const captionAndIcon = getCaptionAndIcon(saveDeskOrder);

  const onChangeNegativeResponseExplanation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReasonForRescinding(e.target.value);
  };

  const onSubmit = debouncePromise(async () => {
    setLoading(true);
    if (showAcceptedButton) {
      await completeSaveDeskOrder(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId);
    } else {
      await rescindSaveDeskOffer(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId, reasonForRescinding);
    }
    const newOrder = await fetchSaveDeskOrder(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId);
    dispatch(SaveDeskActionsDispatch.setSaveDeskOrder(newOrder));
    setFinishedOrder(true);
  });

  return (
    <div className={classes.responseCard}>
      <Form onValidSubmit={onSubmit}>
        <Section icon={captionAndIcon.icon} caption={captionAndIcon.caption}>
          {!finishedOrder && (
            <div id="saveDeskResponsePanel">
              <Panel1
                title={
                  <Subtitle1>
                    <LocalizedMessage id="whatToDoNow" />
                  </Subtitle1>
                }
                footer={
                  <RadioGroup
                    layout="column"
                    name="saveDeskResponse"
                    options={[
                      { label: localizeMessage('confirmSaveDeskOrderRadioButton'), value: ResponseAction.Confirm },
                      { label: localizeMessage('rejectSaveDeskOrderRadioButton'), value: ResponseAction.Reject },
                    ]}
                    defaultValue={ResponseAction.Confirm}
                    onChange={(value) => {
                      if (value === ResponseAction.Confirm) {
                        setShowAcceptedButton(true);
                      } else {
                        setShowAcceptedButton(false);
                      }
                    }}
                  />
                }
                variant="flat"
              >
                <Body1>
                  <LocalizedMessage id="updateContractDescription2" />
                </Body1>
              </Panel1>
              <div className={classes.marginTop}>
                {showAcceptedButton ? (
                  <Button
                    loading={loading}
                    label={<LocalizedMessage id="saveDeskOrderConfirmButton" />}
                    color="positive"
                    type="submit"
                  />
                ) : (
                  <div id="rejectSection">
                    <Subtitle1>
                      <LocalizedMessage id="whyRejectedOrder" />
                    </Subtitle1>
                    <TextInput
                      multiline
                      name="negativeResponseExplanation"
                      label={<LocalizedMessage id="reason" />}
                      required
                      onChange={onChangeNegativeResponseExplanation}
                    />
                    <Caption colorVariant="darker">
                      <LocalizedMessage id="noteForReason" />
                    </Caption>
                    <div className={classes.marginTop}>
                      <Button
                        loading={loading}
                        label={<LocalizedMessage id="saveDeskOrderRejectButton" />}
                        color="negative"
                        type="submit"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Section>
      </Form>
    </div>
  );
};

const getCaptionAndIcon = (saveDeskOrder: ISaveDeskOrder) => {
  const date = saveDeskOrder.dateCompleted;

  switch (saveDeskOrder.state.stateType) {
    case SaveDeskState.RejectedByProvider:
      return {
        caption: <LocalizedMessage id="rejectedOutcomeDescription" values={{ date }} />,
        icon: <CancelIcon id="cancellationIcon" style={{ color: colors.grey2 }} />,
      };

    case SaveDeskState.Success:
      return {
        caption: <LocalizedMessage id="successOutcomeDescription" values={{ date }} />,
        icon: <CheckCircleIcon id="checkIcon" htmlColor={colors.accent.positive} />,
      };

    default:
      return { caption: undefined, icon: undefined };
  }
};

ConfirmOrderSectionInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const ConfirmOrderSection = withStyles(styles)(ConfirmOrderSectionInner);
