import { Sentry } from '@provider-portal/sentry';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import { RejectedReason } from '@provider-portal/models/order';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import moment from 'moment';
import React from 'react';
import { updateOrderState } from '../api';
import type { ISwitchOrderDetails, ISwitchOrderOutcome, ISwitchOrderState } from '../models';
import { ResponseType, SwitchOrderState } from '../models';
import { formatToIsoDate } from '@provider-portal/utils/date';

interface IReturnValue {
  onUpdateOrderOutcome: any;
  updateInProgress: boolean;
}

export function useUpdateSwitchOrder(
  partnerSupplierId: string,
  orderId: string,
  onSetSwitchOrder: (switchOrderDetails: ISwitchOrderDetails) => void,
  onSetLoadingState: (loadingState: PortalLoadingState) => void
): IReturnValue {
  const [updateInProgress, setUpdateInProgress] = React.useState(false);

  return {
    onUpdateOrderOutcome: debouncePromise(async (outcome: ISwitchOrderOutcome) => {
      setUpdateInProgress(true);

      return updateOrderState(partnerSupplierId, orderId, orderOutcomeToOrderState(outcome))
        .then((order) => {
          onSetSwitchOrder(order);
          setUpdateInProgress(false);
        })
        .catch((error) => {
          Sentry.captureExceptionWithMessage(error, 'Failed to update switch order state in supplier portal', {
            extra: { orderId: orderId },
          });
          setUpdateInProgress(false);
          onSetLoadingState(PortalLoadingState.Failure);
        });
    }),
    updateInProgress,
  };
}

function orderOutcomeToOrderState(outcome: ISwitchOrderOutcome): ISwitchOrderState {
  if (outcome.responseType === ResponseType.Rejected) {
    const maybeMessageToUser = (outcome.reasonMessage?.trim() && outcome.reasonMessage.trim()) || undefined;
    const maybeBindingPeriod =
      (outcome.bindingPeriod && moment(outcome.bindingPeriod).format('YYYY-MM-DD')) || undefined;

    if (outcome.rejectionReason === RejectedReason.HasBindingPeriod && !maybeBindingPeriod) {
      throw new Error('If order is rejected with has binding period, a value for binding period is required');
    }
    if (outcome.rejectionReason === RejectedReason.Unknown && !maybeMessageToUser) {
      throw new Error('If order is rejected with unknown reason, message to user is required');
    }

    return {
      name: SwitchOrderState.Rejected,
      reason: {
        name: outcome.rejectionReason,
        bindingPeriodEndsAt: maybeBindingPeriod,
        messageFromSupplier: maybeMessageToUser,
      },
    };
  } else {
    return {
      name: SwitchOrderState.Accepted,
      portingDate:
        outcome.responseType === ResponseType.Accepted && outcome.portingDate
          ? formatToIsoDate(outcome.portingDate)
          : undefined,
    };
  }
}
