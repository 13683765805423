import * as React from 'react';
import { useResetQuerySortParameters } from '../../Table';
import type { ITabConfig } from '../../Tabs/config';
import { Tabs } from '@provider-portal/libs/uiFramework/components/Tabs';
import type { ITableOverviewPageProps } from '@provider-portal/libs/uiFramework/components/pages/TableOverviewPage';
import { TableOverviewPage } from '@provider-portal/libs/uiFramework/components/pages/TableOverviewPage';

interface ITabbedOverviewPageProps {
  tabs: ITabConfig[];
  selectedTab?: string;
  onSwitchTab?(selectedTab: string): void;
}

export const TabbedTableOverviewPage: React.FunctionComponent<ITabbedOverviewPageProps & ITableOverviewPageProps> = (
  props
) => {
  const aboveTableElement = props.tabs && <TableTabs {...props} />;

  const componentProps: ITableOverviewPageProps = {
    ...prepareTableOverviewComponentProps(props),
    aboveTableElement,
  };

  return <TableOverviewPage {...componentProps} />;
};

const TableTabs: React.FunctionComponent<ITabbedOverviewPageProps & ITableOverviewPageProps> = (props) => {
  const resetSortQuery = useResetQuerySortParameters();
  const onSwitchTab = props.onSwitchTab && onSwitchTabExtended(props.onSwitchTab, resetSortQuery);

  return (
    <div style={{ marginBottom: '16px' }}>
      <Tabs tabs={props.tabs} selectedTab={props.selectedTab} onSwitchTab={onSwitchTab} />
    </div>
  );
};

function prepareTableOverviewComponentProps(allProps: ITabbedOverviewPageProps & ITableOverviewPageProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedTab, tabs, onSwitchTab, ...tableOverviewProps } = allProps;

  return tableOverviewProps;
}

function onSwitchTabExtended(onSwitchTab: (selectedTab: string) => void, resetSortQuery: () => void) {
  return (selectedTab: string) => {
    onSwitchTab(selectedTab);
    // The order here is important, we need to first switch tabs and then reset the query.
    // This is because switching tabs also manipulates the query string and would restore the parameters if we ran it after reset.
    resetSortQuery();
  };
}
