import { AddCampaign } from '@provider-portal/campaigns/AddCampaign';
import { CampaignsList } from '@provider-portal/campaigns/CampaignsList';
import { EditCampaign } from '@provider-portal/campaigns/EditCampaign';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

export const CampaignsRoute: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/add`}
        render={(props) => (
          <AddCampaign
            // @ts-ignore
            categoryName={props.match.params.verticalCategoryName}
            // @ts-ignore
            providerId={props.match.params.providerId}
          />
        )}
      />
      <Route
        path={`${match.path}/:partnerCampaignId`}
        render={(props) => (
          <EditCampaign
            // @ts-ignore
            providerId={props.match.params.providerId}
            campaignId={props.match.params.partnerCampaignId}
            // @ts-ignore
            categoryName={props.match.params.verticalCategoryName}
          />
        )}
      />
      <Route
        path={`${match.path}`}
        render={(props) => (
          <CampaignsList
            // @ts-ignore
            partnerSupplierId={props.match.params.providerId}
            // @ts-ignore
            category={props.match.params.verticalCategoryName}
          />
        )}
      />
    </Switch>
  );
};
