import type { TextByLanguage } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/config';
import type { CostWithCurrency } from '@provider-portal/models/currency';

export interface ISaveDeskState {
  createdAt: Date;
  stateType: SaveDeskState;
}

export enum SaveDeskState {
  New = 'New',
  WaitingForUserInput = 'WaitingForUserInput',
  AcceptedByUser = 'AcceptedByUser',
  RejectedByUser = 'RejectedByUser',
  RejectedByProvider = 'RejectedByProvider',
  Success = 'Success',
}

export interface IPain {
  name: string;
  value: string;
}

export enum SaveDeskOfferType {
  SAVE_DESK_OFFER = 'SaveDeskOfferViewModel',
  NO_SAVE_DESK_OFFER = 'NoSaveDeskOfferViewModel',
  SAVE_DESK_OFFER_BY_PHONE = 'SaveDeskOfferByPhoneViewModel',
  SAVE_DESK_PREDEFINED_OFFER = 'PredefinedSaveDeskOfferViewModel',
  SAVE_DESK_OFFER_2 = 'SaveDeskOffer2OutcomeViewModel',
}

export interface SaveDeskOfferOld {
  currentProductName?: string;
  currentPrice?: CostWithCurrency;
  contractBindingTime?: string;
  reason?: string;
  createdAt: string;
}

export interface ISaveDeskOffer extends SaveDeskOfferOld {
  type: SaveDeskOfferType.SAVE_DESK_OFFER;
  currentProductName?: string;
  currentPrice?: CostWithCurrency;
  contractBindingTime?: string;
  newProductName: string;
  newPrice: CostWithCurrency;
  newBindingTimeInMonths: number;
  campaignName?: string;
  priceWithCampaignApplied?: CostWithCurrency;
  campaignDurationInMonths?: number;
  description?: string;
  createdAt: string;
}

export interface INoSaveDeskOffer extends SaveDeskOfferOld {
  type: SaveDeskOfferType.NO_SAVE_DESK_OFFER;
  currentProductName?: string;
  currentPrice?: CostWithCurrency;
  contractBindingTime?: string;
  reason?: string;
  createdAt: string;
}

export interface ISaveDeskOfferByPhone extends SaveDeskOfferOld {
  type: SaveDeskOfferType.SAVE_DESK_OFFER_BY_PHONE;
  currentProductName?: string;
  currentPrice?: CostWithCurrency;
  contractBindingTime?: string;
  createdAt: string;
}

export interface ISaveDeskPredefinedOffer extends SaveDeskOfferOld {
  type: SaveDeskOfferType.SAVE_DESK_PREDEFINED_OFFER;
  description: string;
  createdAt: string;
  currentProductName?: string;
  currentPrice?: CostWithCurrency;
  contractBindingTime?: string;
  newProductName?: string;
  newPrice?: CostWithCurrency;
  newBindingTimeInMonths?: number;
  campaignName?: string;
  priceWithCampaignApplied?: CostWithCurrency;
  campaignDurationInMonths?: number;
}

export interface ISaveDeskOffer2Outcome {
  type: SaveDeskOfferType.SAVE_DESK_OFFER_2;
  offerTitle: string;
  saveDeskOfferId: string;
  createdAt: string;
}

export type SaveDeskOutcomes =
  | ISaveDeskOffer
  | INoSaveDeskOffer
  | ISaveDeskOfferByPhone
  | ISaveDeskPredefinedOffer
  | ISaveDeskOffer2Outcome;

export interface ISaveDeskOrder {
  id: string;
  partnerSupplierId: string;
  partnerOrderId: string;
  customerId: string;
  category: string;
  supplierName: string;
  daysLeft?: number;
  dateCompleted?: string;
  state: ISaveDeskState;
  userName: string;
  email: string;
  phone: string;
  address: string;
  pains: IPain[];
  outcome?: SaveDeskOutcomes;
  assignee?: string;
  createdAt: string;
}

export enum CancellationReason {
  NotSatisfiedWithService = 'NotSatisfiedWithService',
  SwitchedToAnotherService = 'SwitchedToAnotherService',
  TechnicalDifficulties = 'TechnicalDifficulties',
  NoTimeOrNoInterest = 'NoTimeOrNoInterest',
  TooExpensive = 'TooExpensive',
  Covid19 = 'Covid19',
}

// RETURNED from API when creating, updating, publishing etc.
export interface ISaveDeskOffer2 {
  id: string;
  cancellationReasons: CancellationReason[];
  name: TextByLanguage;
  description: TextByLanguage;
  disclaimer?: TextByLanguage;
  offerState: SaveDeskOffer2State;
  externalOfferId?: string;
}

// SENT to server when creating, updating, publishing etc.
export interface ISaveDeskOffer2FormData {
  cancellationReasons: CancellationReason[];
  name: TextByLanguage;
  description: TextByLanguage;
  disclaimer?: TextByLanguage;
  action: SaveDeskOffer2FormAction;
  externalOfferId?: string;
}

// Data emitted from SaveDeskOffer form. Not sent to the server without additional fields added.
export interface ISaveDeskOffer2FormDataInner {
  cancellationReasons: CancellationReason[];
  name: TextByLanguage;
  description: TextByLanguage;
  disclaimer?: TextByLanguage;
  externalOfferId?: string;
}

// Returned from API when fetching a single offer.
export interface ISaveDeskOffer2FormViewModel {
  id?: string;
  cancellationReasons: CancellationReason[];
  cancellationReasonsNotAttachedToAnOffer: CancellationReason[];
  name: TextByLanguage;
  description: TextByLanguage;
  disclaimer?: TextByLanguage;
  offerState: SaveDeskOffer2State;
  externalOfferId?: string;
}

// Returned from API when fetching a list of offers.
export interface ISaveDeskOffer2ListViewModel {
  id: string;
  name: string;
  cancellationReasons: CancellationReason[];
  externalOfferId?: string;
  offerState: SaveDeskOffer2State;
}

export enum SaveDeskOffer2State {
  Draft = 'Draft',
  PendingApproval = 'PendingApproval',
  Approved = 'Approved',
}

export enum SaveDeskOffer2FormAction {
  SAVE = 'Save',
  SAVE_AND_REQUEST_APPROVAL = 'SaveAndRequestApproval',
}

export const isOfferDraft = (offerState: SaveDeskOffer2State) =>
  [SaveDeskOffer2State.Draft, SaveDeskOffer2State.PendingApproval].includes(offerState);
export const isOfferActive = (offerState: SaveDeskOffer2State) => offerState === SaveDeskOffer2State.Approved;

// Returned from API when fetching cancellation reasons for provider.
export interface ISaveDeskOfferCancellationReasonsSelectionForm {
  allCancellationReasons: CancellationReason[];
  availableCancellationReasons: CancellationReason[];
}
