import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { fetchSaveDeskOffers } from '@provider-portal/saveDesk/api';
import type { ISaveDeskOffer2ListViewModel } from '@provider-portal/saveDesk/models';

interface IReturnValue {
  loadingState: PortalLoadingState;
  saveDeskOffers: ISaveDeskOffer2ListViewModel[];
}

export function useFetchOffersOnEnter(partnerSupplierId: string): IReturnValue {
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);
  const [saveDeskOffers, setSaveDeskOffers] = React.useState<ISaveDeskOffer2ListViewModel[]>([]);

  React.useEffect(() => {
    fetchSaveDeskOffers(partnerSupplierId)
      .then((offers) => {
        setSaveDeskOffers(offers);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
  }, [partnerSupplierId]);

  return { loadingState, saveDeskOffers };
}
