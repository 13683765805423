import type { AppFeatureToggles } from '@provider-portal/app-context';
import {
  CancellationFeature,
  NavigationType,
  NotificationType,
  OnboardingType,
  PersonalInformationType,
  SigningFeature,
} from '@provider-portal/app-context';
import { selectorFeatures } from '@provider-portal/app-context/duck';
import { connect } from 'react-redux';

export const defaultFeatures: AppFeatureToggles = {
  logout: {
    enabled: true,
    logoutConfirmationFlow: false,
  },
  helpCenter: {
    enabled: true,
    chatEnabled: true,
    callUsEnabled: true,
    callBackEnabled: false,
    emailUsEnabled: true,
    zendeskBaseUrl: 'https://minatjanster.zendesk.com',
    supportEmail: 'kontakt@minatjanster.se',
  },
  insights: true,
  identify: {
    overview: {
      manuallyAddedSubscriptions: true,
      bankIdLogo: true,
      sslCertifiedIcon: true,
      complexContractGuide: true,
      waysToSave: false,
    },
    identify: false,
    identifyOptIn: false,
  },
  settings: {
    personalInformation: {
      personalInformationType: PersonalInformationType.STANDALONE,
      enabledAddressForm: false,
      enabledPersonalNumber: true,
      enabledNameChange: true,
    },
    profiling: true,
    notification: {
      notificationType: NotificationType.STANDALONE,
      pushEnabled: true,
    },
    changeEmailCard: true,
    deleteAccount: true,
  },
  navigation: {
    navigationType: NavigationType.FULL,
    feedback: true,
  },
  signing: SigningFeature.BANKID,
  onboarding: OnboardingType.DISABLED,
  subscriptionFeatures: {
    cancellation: CancellationFeature.ENABLED,
    deletingSubscriptions: true,
    saveDeskEnabled: false,
    subscriptionFeedback: false,
    cancellationGuide: false,
  },
};

const mapStateToProps = (state: any) => ({ features: selectorFeatures(state) });
export const withFeatures = connect(mapStateToProps);
