import Paper from '@material-ui/core/Paper';
import type { WithStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Info from '@material-ui/icons/Info';
import type { Market } from '@provider-portal/app-context/constants';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import { Subtitle2 } from '@provider-portal/libs/uiFramework/components/text/Subtitle2';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { isUnavailablePortingDateMoment } from '@provider-portal/models/order';
import { ink } from '@provider-portal/styles/colors-standard';
import { parseDate } from '@provider-portal/utils/date';
import type { Moment } from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { colors } from '@provider-portal/styles/colors';
import type { OrderType } from '../models';
import { DatePicker } from '@provider-portal/libs/uiFramework/components/fields/DatePicker';

const styles = () =>
  createStyles({
    portingDateDescription: {
      margin: '16px 0',
      backgroundColor: colors.background,
      padding: '16px',
      display: 'flex',
    },
  });

const PortingDateSectionInner: React.FunctionComponent<PortingDateSectionInnerProps & WithStyles<typeof styles>> = (
  { classes, oldSupplierName, portingDate = '', market, orderType },
  { localizeMessage }
) => {
  return (
    <>
      <Paper elevation={0} className={classes.portingDateDescription}>
        <Info htmlColor={ink.main} />
        <div style={{ marginLeft: '8px' }}>
          <Body>
            <LocalizedMessage id="descriptionPortingDate1" values={{ currentProvider: oldSupplierName }} />
          </Body>
          <br />
          <Body>
            <LocalizedMessage id="descriptionPortingDate2" values={{ currentProvider: oldSupplierName }} />
          </Body>
        </div>
      </Paper>
      <Subtitle2>
        <LocalizedMessage id="portingDateQuestion" />
      </Subtitle2>
      <div style={{ width: '50%', marginBottom: '8px' }}>
        <DatePicker
          name="portingDate"
          label={localizeMessage('portingDateLabel')}
          defaultValue={portingDate ? parseDate(portingDate) : undefined}
          forceNoKeyboard
          fullWidth
          minDate={new Date()}
          shouldDisableDate={(date: Moment) => isUnavailablePortingDateMoment(orderType)(date, market)}
          market={market}
        />
      </div>
    </>
  );
};

interface PortingDateSectionInnerProps {
  oldSupplierName: string;
  portingDate?: string;
  market: Market;
  orderType: OrderType;
}

PortingDateSectionInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const PortingDateSection = withStyles(styles)(
  withLocalization('SupplierPortal/orders')(PortingDateSectionInner)
);

const PortingDateResultSectionInner: React.FunctionComponent<PortingDateResultSectionInnerProps> = ({
  portingDate,
}) => {
  return (
    <>
      <Body style={{ margin: '16px 0 4px' }}>
        <LocalizedMessage id="portingDateLabel" />
      </Body>
      <Subtitle1>{portingDate}</Subtitle1>
    </>
  );
};

interface PortingDateResultSectionInnerProps {
  portingDate: string;
}

export const PortingDateResultSection = withLocalization('SupplierPortal/orders')(PortingDateResultSectionInner);
