import { ChangePortalRoutes } from '@provider-portal/change/portalRoutes';
import { DeveloperToolsRoute } from '@provider-portal/developerTools/route';
import { HomePage } from '@provider-portal/libs/uiFramework/components/pages/HomePage';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { RetentionSolutionPage } from '@provider-portal/libs/uiFramework/components/pages/RetentionSolutionPage';
import { SupplierListContainer } from '@provider-portal/PartnerSupplier/SupplierList';
import { CSATWidgetContainer } from '@provider-portal/rating/CSATWidgetContainer';
import { CampaignsRoute } from '@provider-portal/routes/campaign-route';
import { ProductsRoute } from '@provider-portal/routes/product-route';
import { SaveDeskPortalRoutes } from '@provider-portal/saveDesk/savedeskRoutes';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { SwitchOrdersRoute } from '../orders/switch/route';
import { AddProvider } from '../provider/AddProvider';
import { EditProvider } from '../provider/EditProvider';
import { SettingsRoute } from '../settings/route';
import { useFetchSupplierOnEnter } from './use-fetch';
import { FeaturesRoute } from '@provider-portal/features/route';

type MatchedPartnerSupplierRouteProps = RouteComponentProps<{ providerId: string }> & { hasAdminAccess: boolean };

export const MatchedPartnerSupplierRoute: React.FunctionComponent<MatchedPartnerSupplierRouteProps> = ({
  match,
  hasAdminAccess,
}) => {
  const loadingState = useFetchSupplierOnEnter(match.params.providerId);
  return (
    <LoadingPage loadingState={loadingState}>
      <Switch>
        <Route path={`${match.path}/:verticalCategoryName/products`} render={(props) => <ProductsRoute {...props} />} />
        <Route
          path={`${match.path}/:verticalCategoryName/campaigns`}
          render={(props) => <CampaignsRoute {...props} />}
        />
        <Route
          exact
          path={match.path}
          render={(props) =>
            hasAdminAccess ? (
              <EditProvider providerId={props.match.params.providerId} />
            ) : (
              <HomePage path={match.path} merchantId={props.match.params.providerId} />
            )
          }
        />
        <Route
          path={`${match.path}/:categoryName/orders/switch`}
          render={(props) => <SwitchOrdersRoute {...props} />}
        />
        <Route path={`${match.path}/save-desk`} render={(props) => <SaveDeskPortalRoutes {...props} />} />
        <Route path={`${match.path}/change`} render={(props) => <ChangePortalRoutes {...props} />} />
        <Route path={`${match.path}/settings`} render={(props) => <SettingsRoute {...props} />} />
        <Route path={`${match.path}/retention`} render={() => <RetentionSolutionPage />} />
        <Route path={`${match.path}/developer-tools`} render={(props) => <DeveloperToolsRoute {...props} />} />
        <Route path={`${match.path}/features`} render={(props) => <FeaturesRoute {...props} />} />
      </Switch>
      <CSATWidgetContainer hasAdminAccess={hasAdminAccess} />
    </LoadingPage>
  );
};

export interface PartnerSupplierRouteProps {
  hasAdminAccess: boolean;
}

export const PartnerSupplierRoute: React.FunctionComponent<RouteComponentProps & PartnerSupplierRouteProps> = ({
  match,
  hasAdminAccess,
}) => {
  return (
    <Switch>
      {hasAdminAccess && <Route exact path={`${match.path}/add`} render={() => <AddProvider />} />}
      <Route
        path={`${match.path}/:providerId`}
        render={(props) => <MatchedPartnerSupplierRoute {...props} hasAdminAccess={hasAdminAccess} />}
      />
      {hasAdminAccess && <Route exact path={`${match.path}`} render={() => <SupplierListContainer />} />}
    </Switch>
  );
};
