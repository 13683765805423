import { LocalizedMessage } from '@provider-portal/internationalization';
import { Checkbox } from '@provider-portal/libs/uiFramework/components/fields/Checkbox/config';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select/config';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import React from 'react';
import type { MarketCategoryMap as MarketCategoryLayoutMap } from '@provider-portal/libs/uiFramework';
import { createCategoryLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { Locale } from '@provider-portal/internationalization/locale';

export enum SwedenProviderFormFieldNames {
  NETWORK_OPERATOR = 'details.networkOperator',
  SKI_RATING = 'details.skiRating',
  E_SIM_AVAILABLE = 'details.eSimAvailable',
  DONATES_TO_CHARITY = 'details.donatesToCharity',
  DISCOVER_AVAILABLE = 'details.discoverAvailable',
}

const swedenMobileCategorySection: IFieldSectionConfig = {
  columns: 3,
  components: [
    Select({
      name: SwedenProviderFormFieldNames.NETWORK_OPERATOR,
      label: <LocalizedMessage id="networkOperator" />,
      required: true,
      options: [
        { label: 'Tele2', value: 'Tele2' },
        { label: 'Telia', value: 'Telia' },
        { label: 'Telenor', value: 'Telenor' },
        { label: 'Tre', value: 'Tre' },
      ],
    }),
    NumberInput({
      name: SwedenProviderFormFieldNames.SKI_RATING,
      label: <LocalizedMessage id="skiRating" />,
      numberType: NumberType.PositiveFloat,
      locale: Locale.svSE,
    }),
    Checkbox({
      name: SwedenProviderFormFieldNames.E_SIM_AVAILABLE,
      label: <LocalizedMessage id="eSim" />,
    }),
    Checkbox({
      name: SwedenProviderFormFieldNames.DISCOVER_AVAILABLE,
      label: <LocalizedMessage id="discoverAvailable" />,
    }),
  ],
};

export const swedenMobileCategoryLayoutMap: MarketCategoryLayoutMap = createCategoryLayout(
  categories.mobile,
  Market.Sweden,
  swedenMobileCategorySection
);

const swedenElectricityCategorySection: IFieldSectionConfig = {
  columns: 3,
  components: [
    NumberInput({
      name: SwedenProviderFormFieldNames.SKI_RATING,
      label: <LocalizedMessage id="skiRating" />,
      numberType: NumberType.PositiveFloat,
      locale: Locale.svSE,
    }),
    Checkbox({
      name: SwedenProviderFormFieldNames.DONATES_TO_CHARITY,
      label: <LocalizedMessage id="donatesToCharity" />,
    }),
  ],
};

export const swedenElectricityCategoryLayoutMap: MarketCategoryLayoutMap = createCategoryLayout(
  categories.electricity,
  Market.Sweden,
  swedenElectricityCategorySection
);
