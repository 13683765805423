import SvgIcon from '@material-ui/core/SvgIcon';
import type { CSSProperties, ReactNode } from 'react';
import React from 'react';

export interface IconProps {
  children?: ReactNode;
  nativeColor?: string;
  bold?: boolean;
  viewBox?: string;
  size?: number;
  className?: string;
  style?: CSSProperties;
  onClick?: React.ReactEventHandler<any>;
  'data-test'?: string;
}

export const FilterIcon: React.ComponentType<IconProps> = ({ nativeColor, ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.95844 1.82587C1.9249 1.86501 1.91344 1.90776 1.9243 1.95788C2.87008 6.32224 6.16197 10.3193 10.1233 11.0736L10.733 11.1897V21.0101C10.733 21.2156 10.9672 21.3334 11.1322 21.2107L13.1662 19.6981C13.2296 19.6509 13.267 19.5765 13.267 19.4975V11.1897L13.8767 11.0736C17.838 10.3193 21.1299 6.32224 22.0757 1.95788C22.0865 1.90776 22.0751 1.86501 22.0415 1.82587C22.0051 1.78339 21.9444 1.75 21.8665 1.75H2.13351C2.0556 1.75 1.99484 1.78339 1.95844 1.82587ZM0.458329 2.27556C0.219658 1.1742 1.10168 0.25 2.13351 0.25H21.8665C22.8983 0.25 23.7803 1.1742 23.5416 2.27556C22.5557 6.8251 19.1951 11.2457 14.767 12.4091V19.4975C14.767 20.0508 14.5053 20.5715 14.0613 20.9017L12.0273 22.4144C10.8728 23.273 9.23298 22.4489 9.23298 21.0101V12.4091C4.8049 11.2457 1.44423 6.8251 0.458329 2.27556Z"
      fill={nativeColor}
    />
  </SvgIcon>
);
