import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import moment from 'moment';
import * as React from 'react';
import userIcon from './user.svg';

const styles = () =>
  createStyles({
    container: {
      padding: '0 16px 16px',
    },
    header: {
      padding: '0 16px 16px',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: '24px',
      height: '24px',
      marginRight: '16px',
    },
    details: {
      marginTop: '24px',
    },
  });

interface SaveDeskRequestedDetailsCardProps {
  orderDate: string;
  category: string;
  offerTitle: string;
}

const SaveDeskRequestedDetailsCardInner: React.FunctionComponent<
  SaveDeskRequestedDetailsCardProps & WithStyles<typeof styles>
> = ({ orderDate, category, offerTitle, classes }) => {
  const passedDays = moment(Date.now()).diff(orderDate, 'days').toString();

  return (
    <Paper title={<LocalizedMessage id="saveDeskOrderPageRequestTitle" />}>
      <div className={classes.container}>
        <div className={classes.header}>
          <img className={classes.icon} src={userIcon} alt="user" />
          <div>
            <Body1>
              <LocalizedMessage id="saveDeskRequestedDetailsDescription" values={{ category }} />
            </Body1>
          </div>
        </div>
        <Panel1
          title={
            <Subtitle1>
              <LocalizedMessage id="offerAccepted" />
            </Subtitle1>
          }
          variant="flat"
        >
          <div className={classes.details}>
            {detailsRow(<LocalizedMessage id="offerName" />, offerTitle, 'offerName')}
            {detailsRow(
              <LocalizedMessage id="dateSent" />,
              <LocalizedMessage id="daysAgo" values={{ date: orderDate, passedDays: passedDays }} />,
              'dateSent'
            )}
          </div>
        </Panel1>
      </div>
    </Paper>
  );
};

const detailsRow = (caption: React.ReactElement, description: string | React.ReactElement, id: string) => {
  return (
    <div
      style={{
        marginTop: '16px',
      }}
    >
      <Caption>{caption}</Caption>
      <Body1 id={id}>{description}</Body1>
    </div>
  );
};

export const SaveDeskRequestedDetailsCard = withStyles(styles)(SaveDeskRequestedDetailsCardInner);
