export type Columns = 1 | 2 | 3 | 4;

export type GridColumnWidth = 12 | 6 | 4 | 3;

export function getGridColumnsWidth(columns: Columns): GridColumnWidth {
  if (columns === 1) return 12;
  else if (columns === 2) return 6;
  else if (columns === 3) return 4;
  else if (columns === 4) return 3;
  else throw new Error(`Columns are outside of range, ${columns}`);
}
