import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import type { CSATWidgetProps } from '../CSATWidget';
import { CSATWidget } from '../CSATWidget';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    padding: '0 18px 0 18px',
    bottom: '16px',
    // remove padding specified above and menu width
    width: 'calc(100% - 36px - 280px)',
  },
}));

export const CSATWidgetContainer: React.FunctionComponent<CSATWidgetProps> = (props: CSATWidgetProps) => {
  const { container } = useStyles();
  return (
    <div className={container}>
      <CSATWidget {...props} />
    </div>
  );
};
