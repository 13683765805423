import { Headline3 } from '@provider-portal/libs/uiFramework/components/text/Headline3';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import * as React from 'react';
import { ContentCricket } from '@provider-portal/libs/uiFramework/components/ContentCricket';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';

interface IStyles {
  title: React.CSSProperties;
  contentFilteredOutMessage: React.CSSProperties;
}

const styles: IStyles = {
  title: {
    marginBottom: '8px',
  },
  contentFilteredOutMessage: {
    marginBottom: '16px',
  },
};

interface IContentFilteredOutProps {
  contentFilteredOutMessage: React.ReactNode;
  onClearFilters(): void;
}

interface IClearFiltersButton {
  onClick(): void;
}

const Headline: React.FunctionComponent = () => {
  return (
    <Headline3 style={styles.title}>
      <LocalizedMessage id="contentFilteredOutTitle" />
    </Headline3>
  );
};

const ClearFiltersButton: React.FunctionComponent<IClearFiltersButton> = ({ onClick }) => {
  return (
    <Button
      color="secondary"
      variant="outlined"
      onClick={onClick}
      label={<LocalizedMessage id="clearFiltersButtonLabel" />}
    />
  );
};

const ContentFilteredOutInner: React.FunctionComponent<IContentFilteredOutProps> = ({
  contentFilteredOutMessage,
  onClearFilters,
}) => {
  return (
    <ContentCricket>
      <Headline />
      <Body style={styles.contentFilteredOutMessage}>{contentFilteredOutMessage}</Body>
      <ClearFiltersButton onClick={onClearFilters} />
    </ContentCricket>
  );
};

export const ContentFilteredOut: React.FunctionComponent<IContentFilteredOutProps> = withLocalization(
  'SupplierPortal/ContentFilteredOut'
)(ContentFilteredOutInner);
