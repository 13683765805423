import { QuestionIcon } from '@provider-portal/assets/icons/QuestionIcon';
import {
  fetchSupportedLocalesByProvider,
  fetchSupportedLocalesByMarket,
} from '@provider-portal/internationalization/api';
import type { Locale } from '@provider-portal/internationalization/locale';
import { languageNameFromLocale, languageShorthandFromLocale } from '@provider-portal/internationalization/locale';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput';
import { HiddenInputDataFormat } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { LoadingComponent } from '@provider-portal/libs/uiFramework/components/LoadingComponent';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import { Tooltip } from '@provider-portal/libs/uiFramework/components/tooltip';
import { TranslatableTextInput } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/TextField/TextField';
import { useFetchOnMount } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/useFetchOnMount';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import compact from 'lodash/compact';
import type { ChangeEvent } from 'react';
import { default as React } from 'react';
import type { ITranslatableFieldWidget, TextByLanguage } from './config';

const styles: { [key: string]: React.CSSProperties } = {
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textInputs: {
    marginTop: '8px',
  },
  description: {
    marginTop: '-8px',
  },
};

type ITranslatableFieldProps = Omit<ITranslatableFieldWidget, 'type'>;

export const TranslatableField: React.FunctionComponent<ITranslatableFieldProps> = ({
  providerId,
  market,
  defaultValue,
  title,
  description,
  multiline = false,
  caption,
  required = false,
  maxlength,
  name,
  tooltipTitle,
  tooltipText,
}) => {
  const [languageChoices, setLanguageChoices] = React.useState<Locale[]>([]);
  const [textByLanguage, setTextByLanguage] = React.useState<TextByLanguage>(new Map<Locale, string>());
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);
  useFetchOnMount(
    async () =>
      (market && fetchSupportedLocalesByMarket(market)) ||
      (providerId && fetchSupportedLocalesByProvider(providerId)) ||
      Promise.resolve([]),
    onFetchedLocales,
    onFetchedLocalesFailed
  );
  let isOneLanguageTranslated = false;
  const translations: string[] = Array.from(textByLanguage.values());
  isOneLanguageTranslated = translations.some((value: string) => value.length > 0);
  const onlyOneLanguage = languageChoices.length === 1;
  const translatableTextInputs = languageChoices.map((locale: Locale) => {
    return (
      <TranslatableTextInput
        key={locale}
        label={`${languageNameFromLocale(locale)} (${languageShorthandFromLocale(locale)})`}
        value={textByLanguage.get(locale) || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onTranslatedTextChange(locale, event)}
        multiline={multiline}
        required={required || isOneLanguageTranslated}
        maxlength={maxlength}
      />
    );
  });

  return (
    <div>
      <LoadingComponent loadingState={loadingState}>
        <HiddenInput
          name={name}
          value={prepareFieldsForFormData(textByLanguage)}
          dataFormat={HiddenInputDataFormat.JSON}
        />
        <Panel1
          title={
            <div style={styles.title}>
              <Subtitle1 style={{ marginRight: '8px' }}>{title}</Subtitle1>
              {tooltipTitle && tooltipText && !onlyOneLanguage && (
                <Tooltip tooltipTitle={tooltipTitle} tooltipText={tooltipText}>
                  <QuestionIcon />
                </Tooltip>
              )}
            </div>
          }
          variant="flat"
        >
          <div>
            <Body1>{description}</Body1>
          </div>
          <div style={styles.textInputs}>{translatableTextInputs}</div>
          {caption && <Caption colorVariant="dark">{caption}</Caption>}
        </Panel1>
      </LoadingComponent>
    </div>
  );

  function onFetchedLocales(locales: Locale[]) {
    setLanguageChoices(locales);
    const initialValues = initialTextByLanguage(locales, defaultValue);
    setTextByLanguage(initialValues);
    setLoadingState(PortalLoadingState.Success);
  }

  function onFetchedLocalesFailed() {
    setLanguageChoices([]);
    setLoadingState(PortalLoadingState.Failure);
  }

  function onTranslatedTextChange(locale: Locale, event: ChangeEvent<HTMLInputElement>) {
    const newText = event.target.value;
    const newMap = new Map<Locale, string>(textByLanguage);
    newMap.set(locale, newText);
    setTextByLanguage(newMap);
  }
};

function initialTextByLanguage(locales: Locale[], initialMapObject?: TextByLanguage) {
  if (!initialMapObject) return new Map<Locale, string>();

  const initialMapEntries: [Locale, string][] = compact(
    Object.entries(initialMapObject).map(([key, value]) => {
      return locales.includes(key as Locale) && [key as Locale, value as string];
    })
  );

  return new Map<Locale, string>(initialMapEntries);
}

function prepareFieldsForFormData(map: Map<Locale, string>) {
  const result: any = {};
  Array.from(map.entries()).forEach(([key, value]) => {
    if (value !== '') {
      result[key] = value.trim();
    }
  });

  return result;
}
