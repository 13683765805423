export function outputFileToClient(fileContent: any, fileName: string, contentType: string) {
  const file = new Blob([fileContent], { type: contentType });
  const fileUrl = window.URL.createObjectURL(file);
  const hiddenElement = document.createElement('a');
  hiddenElement.href = fileUrl;
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName;
  hiddenElement.click();
  hiddenElement.remove();
}
