import { LocalizedMessage } from '@provider-portal/internationalization';
import { FieldType } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { CentesimalCostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CentesimalCostInput/config';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { MeasurementUnit } from '@provider-portal/libs/uiFramework/components/fields/MeasurementUnit';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { ElectricityPriceType } from '@provider-portal/products/models';
import {
  campaignPaper,
  internalPaper,
  electricityNamingSection,
  termsPaper,
} from '@provider-portal/products/ProductForm/config/common';
import React from 'react';
import { RadioGroup } from '@provider-portal/libs/uiFramework/components/fields/RadioGroup/config';
import type { ProductFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createProductFormLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { Currency } from '@provider-portal/models/subscription';
import { getDefaultLocale } from '@provider-portal/app-context/state';

const currency = Currency.EUR;
const locale = getDefaultLocale(Market.Belgium);

const belgiumElectricityPapers = (hasAdminAccess: boolean): IFormPagePaper[] => [
  {
    sections: [electricityNamingSection, ...belgiumElectricitySections],
  },
  termsPaper(locale),
  campaignPaper,
  internalPaper(hasAdminAccess, currency, locale),
];

export const belgiumElectricityLayoutMap: ProductFormLayoutMap = createProductFormLayout(
  categories.electricity,
  Market.Belgium,
  belgiumElectricityPapers
);

enum BelgiumElectricityFieldNames {
  FEATURE_GREEN_ELECTRICITY = 'isGreenElectricity',
  PRICE_TYPE = 'priceType',
  SINGLE_METER_CONSUMPTION_COST = 'singleMeterConsumptionCost',
  SINGLE_METER_FIXED_COST = 'singleMeterFixedCost',
  DUAL_METER_DAY_CONSUMPTION_COST = 'dualMeterDayConsumptionCost',
  DUAL_METER_NIGHT_CONSUMPTION_COST = 'dualMeterNightConsumptionCost',
  DUAL_METER_FIXED_COST = 'dualMeterFixedCost',
  NIGHT_METER_CONSUMPTION_COST = 'exclusiveNightMeterConsumptionCost',
  NIGHT_METER_FIXED_COST = 'exclusiveNightMeterFixedCost',
  FORM_TYPE = 'type',
}

const belgiumElectricitySections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="pricingTitle" />,
    captionText: <LocalizedMessage id="electricityPriceCaptionText" />,
    tooltipTitle: <LocalizedMessage id="pricingTitle" />,
    tooltipText: <LocalizedMessage id="belgiumEnergyConsumptionHeaderTooltiptext" />,
    columns: 4,
    components: [
      RadioGroup({
        name: BelgiumElectricityFieldNames.PRICE_TYPE,
        options: [
          { value: ElectricityPriceType.Floating, label: <LocalizedMessage id="floatingPriceLabel" /> },
          { value: ElectricityPriceType.Fixed, label: <LocalizedMessage id="fixedPriceLabel" /> },
        ],
        required: true,
      }),
      HiddenInput({
        name: BelgiumElectricityFieldNames.FORM_TYPE,
        defaultValue: 'BelgiumElectricityProductIncomingRequest',
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="singleMeterTitle" />,
    columns: 4,
    components: [
      CostInput({
        label: <LocalizedMessage id="fixedCostLabel" />,
        name: BelgiumElectricityFieldNames.SINGLE_METER_FIXED_COST,
        unit: MeasurementUnit.YEAR,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        label: <LocalizedMessage id="costLabel" />,
        name: BelgiumElectricityFieldNames.SINGLE_METER_CONSUMPTION_COST,
        unit: MeasurementUnit.KWH,
        required: true,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="dualMeterTitle" />,
    columns: 4,
    components: [
      CostInput({
        label: <LocalizedMessage id="fixedCostLabel" />,
        name: BelgiumElectricityFieldNames.DUAL_METER_FIXED_COST,
        unit: MeasurementUnit.YEAR,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        label: <LocalizedMessage id="dayCostLabel" />,
        name: BelgiumElectricityFieldNames.DUAL_METER_DAY_CONSUMPTION_COST,
        unit: MeasurementUnit.KWH,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        label: <LocalizedMessage id="nightCostLabel" />,
        name: BelgiumElectricityFieldNames.DUAL_METER_NIGHT_CONSUMPTION_COST,
        unit: MeasurementUnit.KWH,
        required: true,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="exclusiveNightMeterTitle" />,
    columns: 4,
    components: [
      CostInput({
        label: <LocalizedMessage id="fixedCostLabel" />,
        name: BelgiumElectricityFieldNames.NIGHT_METER_FIXED_COST,
        unit: MeasurementUnit.YEAR,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        label: <LocalizedMessage id="costLabel" />,
        name: BelgiumElectricityFieldNames.NIGHT_METER_CONSUMPTION_COST,
        unit: MeasurementUnit.KWH,
        required: true,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="energySource" />,
    tooltipTitle: <LocalizedMessage id="energySource" />,
    tooltipText: <LocalizedMessage id="electricitySourceTooltipText" />,
    divider: 'top',
    columns: 1,
    components: [
      {
        label: <LocalizedMessage id="featureGreenElectricityLabel" />,
        name: BelgiumElectricityFieldNames.FEATURE_GREEN_ELECTRICITY,
        type: FieldType.Checkbox,
      },
    ],
  },
];
