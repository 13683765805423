import { selectorHasInternalAdminAccess } from '@provider-portal/login/duck';
import { Sentry } from '@provider-portal/sentry';
import { withLocalization } from '@provider-portal/internationalization';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { fetchSaveDeskOffer } from '../api';
import type { ISaveDeskOffer2FormViewModel } from '../models';
import { SaveDeskOffer2FormAction } from '../models';
import { SaveDeskOfferSnackbarState, snackbarContent } from '../snackbar-state';
import { Snackbar } from '@provider-portal/libs/uiFramework/components/dialogs/Snackbar';
import { EditSaveDeskOfferComponent } from '@provider-portal/saveDesk/EditSaveDeskOffer/component';

interface IEditSaveDeskOfferProps {
  providerId: string;
  saveDeskOfferId: string;
}

const EditSaveDeskOfferInner: React.FunctionComponent<IEditSaveDeskOfferProps> = ({ providerId, saveDeskOfferId }) => {
  const [loadingState, setLoadingState] = React.useState(PortalLoadingState.Loading);
  const [actionType, setActionType] = React.useState<SaveDeskOffer2FormAction>(SaveDeskOffer2FormAction.SAVE);
  const [saveDeskOfferViewModel, setSaveDeskOfferViewModel] = React.useState<
    ISaveDeskOffer2FormViewModel | undefined
  >();
  const [snackbarState, setSnackbarState] = React.useState<SaveDeskOfferSnackbarState>(
    SaveDeskOfferSnackbarState.CLOSED
  );
  const hasAdminAccess = useSelector(selectorHasInternalAdminAccess);

  React.useEffect(() => {
    fetchSaveDeskOffer(providerId, saveDeskOfferId)
      .then((formData) => {
        setSaveDeskOfferViewModel(formData);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to fetch saveDeskOffer view model');
      });
  }, [providerId, saveDeskOfferId]);

  return (
    <LoadingPage loadingState={loadingState}>
      {saveDeskOfferViewModel && (
        <EditSaveDeskOfferComponent
          providerId={providerId}
          saveDeskOfferId={saveDeskOfferId}
          viewModel={saveDeskOfferViewModel}
          setLoadingState={setLoadingState}
          setSnackbarState={setSnackbarState}
          setViewModel={setSaveDeskOfferViewModel}
          hasAdminAccess={hasAdminAccess}
          actionType={actionType}
          setActionType={setActionType}
        />
      )}
      <Snackbar
        autoClose
        variant={snackbarState === SaveDeskOfferSnackbarState.OFFER_UPDATED ? 'success' : 'info'}
        open={snackbarState !== SaveDeskOfferSnackbarState.CLOSED}
        content={snackbarContent(snackbarState)}
        onClose={() => setSnackbarState(SaveDeskOfferSnackbarState.CLOSED)}
      />
    </LoadingPage>
  );
};

export const EditSaveDeskOffer = withLocalization('supplier-portal-save-desk')(EditSaveDeskOfferInner);
