import SvgIcon from '@material-ui/core/SvgIcon';
import { colors } from '@provider-portal/styles/colors';
import * as React from 'react';

export const WarningIcon: React.FunctionComponent = () => {
  return (
    <SvgIcon style={{ color: colors.accent.warning, width: '20px', height: '18px' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.47012 18H17.5301C19.0701 18 20.0301 16.33 19.2601 15L11.7301 1.99C10.9601 0.66 9.04012 0.66 8.27012 1.99L0.740121 15C-0.0298788 16.33 0.930121 18 2.47012 18ZM10.0001 11C9.45012 11 9.00012 10.55 9.00012 10V8C9.00012 7.45 9.45012 7 10.0001 7C10.5501 7 11.0001 7.45 11.0001 8V10C11.0001 10.55 10.5501 11 10.0001 11ZM11.0001 15H9.00012V13H11.0001V15Z"
        fill="#F1924A"
      />
    </SvgIcon>
  );
};
