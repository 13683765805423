type SortFunc = (value1: any, value2: any) => number;

export interface ITableCellConfig {
  key: string;
  label: React.ReactNode;
  renderCellValue?(value: any): React.ReactNode;
  tooltip?(value: any): string | React.ReactNode;
  sort?: SortFunc | string;
}

export interface ITableConfig {
  defaultSortColumn: string;
  defaultSortDirection?: TableColumnSortDirection;
  cells: ITableCellConfig[];
}

export enum TableColumnSortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}
