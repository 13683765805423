import { Sentry } from '@provider-portal/sentry';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Snackbar } from '@provider-portal/libs/uiFramework/components/dialogs/Snackbar';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import compact from 'lodash/compact';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { archiveCampaign, fetchCampaign, unpublishCampaign, updatePartnerCampaign } from '../api';
import { CampaignForm } from '../CampaignForm';
import type { ICampaignFormData, ICampaignViewModel } from '../models';
import { CampaignState, SubmitType } from '../models';
import { CampaignSnackbarState, snackbarContent } from '../snackbar-state';

interface IEditCampaignProps {
  providerId: string;
  campaignId: string;
  categoryName: string;
}

const EditCampaignInner: React.FunctionComponent<IEditCampaignProps> = ({ providerId, campaignId, categoryName }) => {
  const [loadingState, setLoadingState] = React.useState(PortalLoadingState.Loading);
  const [campaignViewModel, setCampaignViewModel] = React.useState<ICampaignViewModel>({});
  const [submitType, setSubmitType] = React.useState<SubmitType | undefined>();
  const [snackbarState, setSnackbarState] = React.useState<CampaignSnackbarState>(CampaignSnackbarState.CLOSED);
  const history = useHistory();

  React.useEffect(() => {
    fetchCampaign(providerId, campaignId)
      .then((formData) => {
        setCampaignViewModel(formData);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to fetch campaign view model');
      });
  }, [providerId, campaignId]);

  const editCampaignUrl = (campaignIdToNavigateTo?: string) => {
    return URI(history.location.pathname)
      .segment(-1, campaignIdToNavigateTo || '')
      .toString();
  };

  const updateCampaign = debouncePromise(async (formData: ICampaignFormData) => {
    if (submitType === SubmitType.PUBLISH) {
      formData.changesApproved = true;
    }

    try {
      const updatedFormData = await updatePartnerCampaign(providerId, campaignId, formData);
      if (updatedFormData.id !== campaignId) {
        history.replace(editCampaignUrl(updatedFormData.id));
      }
      setCampaignViewModel(updatedFormData);
      setSnackbarState(CampaignSnackbarState.CAMPAIGN_UPDATED);
    } catch (error) {
      setLoadingState(PortalLoadingState.Failure);
      Sentry.captureExceptionWithMessage(error, 'Failed to update campaign');
    }
  });

  const unpublish = debouncePromise(async () => {
    unpublishCampaign(providerId, campaignId)
      .then((formData) => {
        history.replace(editCampaignUrl(formData.id));
        setCampaignViewModel(formData);
        setSnackbarState(CampaignSnackbarState.CAMPAIGN_UNPUBLISHED);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to unpublish');
      });
  });

  const deleteCampaign = debouncePromise(async () => {
    archiveCampaign(providerId, campaignId)
      .then(() => {
        const listCampaignsUrl = URI(history.location.pathname).segment(-1, '').toString();
        history.replace(listCampaignsUrl);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to delete campaign');
      });
  });

  return (
    <LoadingPage loadingState={loadingState}>
      <CampaignForm
        appBarTitle={campaignViewModel.name}
        formData={campaignViewModel}
        providerId={providerId}
        categoryName={categoryName}
        onSubmit={updateCampaign}
        leftSidedButtons={
          (campaignViewModel.state?.type === CampaignState.Draft && [
            <Button
              key="delete"
              label={<LocalizedMessage id="deleteButtonText" />}
              color="negative"
              onClick={deleteCampaign}
            />,
          ]) ||
          undefined
        }
        rightSidedButtons={compact([
          campaignViewModel.state?.type === CampaignState.Draft && (
            <Button
              key="save-draft"
              label={<LocalizedMessage id="saveDraftButtonText" />}
              color="secondary"
              type="submit"
              onClick={() => setSubmitType(SubmitType.SAVE_DRAFT)}
            />
          ),
          campaignViewModel.state?.type === CampaignState.Approved && (
            <Button
              key="unpublish"
              label={<LocalizedMessage id="unpublishButtonText" />}
              color="negative"
              onClick={unpublish}
            />
          ),
          <Button
            key="publish"
            label={
              campaignViewModel.state?.type === CampaignState.Approved ? (
                <LocalizedMessage id="saveAndApproveNewChangesButton" />
              ) : (
                <LocalizedMessage id="publishButtonText" />
              )
            }
            color="positive"
            onClick={() => setSubmitType(SubmitType.PUBLISH)}
            type="submit"
          />,
        ])}
      />
      <Snackbar
        autoClose
        variant={snackbarState === CampaignSnackbarState.CAMPAIGN_UPDATED ? 'success' : 'info'}
        open={snackbarState !== CampaignSnackbarState.CLOSED}
        content={snackbarContent(snackbarState)}
        onClose={() => setSnackbarState(CampaignSnackbarState.CLOSED)}
      />
    </LoadingPage>
  );
};

export const EditCampaign = withLocalization('SupplierPortal/PartnerCampaign')(EditCampaignInner);
