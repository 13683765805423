import type { CategoryName, ICategoryProps } from '@provider-portal/constants/categories';
import type { Market } from '@provider-portal/app-context/constants';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import type { VerticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';
import { verticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';

export type MarketCategoryMap = [VerticalMarketKey, IFieldSectionConfig];

export const createCategoryLayout = (
  categoryProps: ICategoryProps,
  market: Market,
  conf: IFieldSectionConfig
): MarketCategoryMap => {
  return [verticalMarketKey(categoryProps.key as CategoryName, market), conf];
};
