import Grid from '@material-ui/core/Grid';
import * as React from 'react';

interface Props {
  children?: React.ReactNode;
}

export const GridContainer: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
};
