//@ts-ignore, js pollyfill needed for Joi to work in IE11 and early version of Edge, remove when possible
import { TextDecoder, TextEncoder } from 'fastestsmallesttextencoderdecoder';
window.TextEncoder = TextEncoder;
window.TextDecoder = TextDecoder;

import Joi from '@hapi/joi';

const uriSchema = Joi.string().uri({
  scheme: ['http', 'https'],
});
const emailSchema = Joi.string().email({ minDomainSegments: 2, tlds: false });

export const isValidUri = (uri: string): boolean => {
  return !uriSchema.validate(uri).error;
};

export const isValidEmail = (email: string): boolean => {
  return !emailSchema.validate(email).error;
};

const integerValidator = Joi.number().integer();

function isInteger(value: string): boolean {
  //We should allow for either , or . as decimal separator.
  const valueCommaReplacedWithDot = value.replace(',', '.').trim();

  return !integerValidator.validate(valueCommaReplacedWithDot).error;
}

export const integerValidatorHelper =
  (errorMessage: React.ReactNode) =>
  (value: string = ''): boolean | React.ReactNode => {
    return !value || isInteger(value) ? false : errorMessage;
  };

export const positiveIntegerValidatorHelper =
  (errorMessage: React.ReactNode) =>
  (value: string = ''): boolean | React.ReactNode => {
    return !value || (isInteger(value) && parseInt(value, 10) > 0) ? false : errorMessage;
  };

export const integerZeroOrGreaterValidatorHelper =
  (errorMessage: React.ReactNode) =>
  (value: string = ''): boolean | React.ReactNode => {
    return !value || (isInteger(value) && parseInt(value, 10) >= 0) ? false : errorMessage;
  };

const floatValidator = Joi.number();

function isFloat(value: string): boolean {
  //We should allow for either , or . as decimal separator.
  const valueCommaReplacedWithDot = value.replace(',', '.').trim();

  return !floatValidator.validate(valueCommaReplacedWithDot).error;
}

export const floatValidatorHelper =
  (errorMessage: React.ReactNode) =>
  (value: string = ''): boolean | React.ReactNode => {
    return !value || isFloat(value) ? false : errorMessage;
  };

export function isPositiveFloat(value: string): boolean {
  return isFloat(value) && parseFloat(value.replace(',', '.')) > 0;
}

export const positiveFloatValidatorHelper =
  (errorMessage: React.ReactNode) =>
  (value: string = ''): boolean | React.ReactNode => {
    return !value || isPositiveFloat(value) ? false : errorMessage;
  };

export function isFloatZeroOrGreater(value: string): boolean {
  return isFloat(value) && parseFloat(value.replace(',', '.')) >= 0;
}

export const floatZeroOrGreaterValidatorHelper =
  (errorMessage: React.ReactNode) =>
  (value: string = ''): boolean | React.ReactNode => {
    return !value || isFloatZeroOrGreater(value) ? false : errorMessage;
  };

export const integerRangeValidatorHelper =
  (min: number, max: number, errorMessage: React.ReactNode) =>
  (value: string = ''): boolean | React.ReactNode => {
    if (value) {
      const valueAsInt = parseInt(value, 10);

      return isInteger(value) && min <= valueAsInt && valueAsInt <= max ? false : errorMessage;
    } else {
      return false;
    }
  };

export const floatRangeValidatorHelper =
  (min: number, max: number, errorMessage: React.ReactNode) =>
  (value: string = ''): boolean | React.ReactNode => {
    if (value) {
      const valueAsFloat = parseFloat(value.replace(',', '.'));

      return isFloat(value) && min <= valueAsFloat && valueAsFloat <= max ? false : errorMessage;
    } else {
      return false;
    }
  };
