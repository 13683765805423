import { dispatchBeforeConnect } from '@provider-portal/containers/container-helpers';
import { withLocalization } from '@provider-portal/internationalization';
import { handleFetchable } from '@provider-portal/fetching/handleFetchable';
import type { ApplicationState } from '@provider-portal/store';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Actions } from '../duck';
import { selectorFetchableUsers, UserManagementActions } from '../duck';
import type { UserListComponentProps } from './component';
import { UserListComponent } from './component';

function mapStateToProps(state: ApplicationState, props: RouteComponentProps) {
  return {
    users: selectorFetchableUsers(state),
    onEditUser(userId: string) {
      props.history.push(`/admin/users/${userId}`);
    },
  };
}

export const UserList = compose<UserListComponentProps, Record<string, never>>(
  dispatchBeforeConnect((state: ApplicationState, dispatch: Dispatch<Actions>) => {
    dispatch(UserManagementActions.fetchUsers());
  }),
  withRouter,
  connect(mapStateToProps),
  withLocalization('SupplierPortal/UserManagement'),
  handleFetchable('users')
)(UserListComponent);
