import { PortalLoadingState } from '@provider-portal/types/loading-state';
import React from 'react';
import { fetchChangePlanOffers } from '@provider-portal/features/changePlan/api';
import type { IChangePlanOffer2ListViewModel } from '@provider-portal/features/changePlan/models';

interface IReturnValue {
  loadingState: PortalLoadingState;
  changePlanOffers: IChangePlanOffer2ListViewModel[];
}

export function useFetchOffersOnEnter(partnerSupplierId: string): IReturnValue {
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);
  const [changePlanOffers, setChangePlanOffers] = React.useState<IChangePlanOffer2ListViewModel[]>([]);

  React.useEffect(() => {
    fetchChangePlanOffers(partnerSupplierId)
      .then((offers) => {
        setChangePlanOffers(offers);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
    // eslint-disable-next-line
  }, []);

  return { loadingState, changePlanOffers };
}
