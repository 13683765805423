import type { IncomingPartnerVertical, PartnerSupplier } from '@provider-portal/PartnerSupplier/models';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { PaymentOption, SupportChannel } from '../provider/models';

export async function fetchPartnerSuppliers(): Promise<PartnerSupplier[]> {
  return axios.get('/api/v1/suppliers').then((response: AxiosResponse<PartnerSupplier[]>) => response.data);
}

export async function fetchSupplier(partnerSupplierId: string): Promise<PartnerSupplier> {
  return axios
    .get(`/api/v1/suppliers/${partnerSupplierId}`)
    .then((response: AxiosResponse<PartnerSupplier>) => response.data);
}

export async function createPartnerSupplier(supplier: PartnerSupplier): Promise<PartnerSupplier> {
  return axios.post('/api/v1/suppliers', supplier).then((response: AxiosResponse<PartnerSupplier>) => response.data);
}

export async function updatePartnerSupplier(supplier: PartnerSupplier): Promise<PartnerSupplier> {
  return axios
    .put(`/api/v1/suppliers/${supplier.id}`, supplier)
    .then((response: AxiosResponse<PartnerSupplier>) => response.data);
}

export async function deletePartnerSupplier(supplierId: string): Promise<any> {
  return axios.delete(`/api/v1/suppliers/${supplierId}`);
}

export async function uploadLogotypeImage(file: File): Promise<string> {
  const fileExtension = file.type.split('/', 2)[1];

  return axios({
    method: 'post',
    url: `/api/frontend/v1/logotype-images/${fileExtension}`,
    headers: {
      'Content-Type': file.type,
    },
    data: file,
  }).then((response: AxiosResponse<{ resourceName: string }>) => response.data.resourceName);
}

export async function updateSupportChannels(
  supportChannels: SupportChannel[],
  supplierId: string
): Promise<PartnerSupplier> {
  return axios
    .put(`/api/v1/suppliers/${supplierId}/support-channels`, supportChannels)
    .then((response: AxiosResponse<PartnerSupplier>) => response.data);
}

export async function updatePaymentOptions(
  paymentOptions: PaymentOption[],
  supplierId: string
): Promise<PartnerSupplier> {
  return axios
    .put(`/api/v1/suppliers/${supplierId}/payment-options`, paymentOptions)
    .then((response: AxiosResponse<PartnerSupplier>) => response.data);
}

export async function fetchOrderServiceIdentifiers(): Promise<string[]> {
  return axios.get('/api/frontend/v1/order-services').then((response: AxiosResponse<string[]>) => response.data);
}

export async function createVertical(
  partnerSupplierId: string,
  vertical: IncomingPartnerVertical
): Promise<PartnerSupplier> {
  return axios
    .post(`/api/v1/suppliers/${partnerSupplierId}/verticals`, vertical)
    .then((response: AxiosResponse<PartnerSupplier>) => response.data);
}

export async function updateVertical(
  partnerSupplierId: string,
  vertical: IncomingPartnerVertical
): Promise<PartnerSupplier> {
  return axios
    .put(`/api/v1/suppliers/${partnerSupplierId}/verticals`, vertical)
    .then((response: AxiosResponse<PartnerSupplier>) => response.data);
}

export async function deleteVertical(partnerSupplierId: string, categoryName: string): Promise<PartnerSupplier> {
  return axios
    .delete(`/api/v1/suppliers/${partnerSupplierId}/verticals/${categoryName}`)
    .then((response: AxiosResponse<PartnerSupplier>) => response.data);
}

export async function activateSupplier(partnerSupplierId: string): Promise<PartnerSupplier> {
  return axios
    .put(`/api/v1/suppliers/${partnerSupplierId}/activate`)
    .then((response: AxiosResponse<PartnerSupplier>) => response.data);
}

export async function disableSupplier(partnerSupplierId: string): Promise<PartnerSupplier> {
  return axios
    .put(`/api/v1/suppliers/${partnerSupplierId}/disable`)
    .then((response: AxiosResponse<PartnerSupplier>) => response.data);
}
