import SvgIcon from '@material-ui/core/SvgIcon';
import type { CSSProperties, ReactNode } from 'react';
import * as React from 'react';

export interface IconProps {
  children?: ReactNode;
  nativeColor?: string;
  viewBox?: string;
  size?: number;
  className?: string;
  style?: CSSProperties;
  onClick?: React.ReactEventHandler<any>;
  'data-test'?: string;
}

export const CodeIcon: React.ComponentType<IconProps> = ({ nativeColor = '#4A4B4B', ...props }) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_614_3032)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.15974 3.9697C8.45262 4.2626 8.45258 4.73745 8.15966 5.03031L1.71963 11.4689C1.71959 11.4689 1.71967 11.4689 1.71963 11.4689C1.5791 11.6095 1.50004 11.8003 1.50004 11.9991C1.50004 12.1979 1.57899 12.3886 1.71955 12.5293C1.71958 12.5293 1.71952 12.5292 1.71955 12.5293L8.1597 18.969C8.4526 19.2618 8.4526 19.7367 8.1597 20.0296C7.8668 20.3225 7.39192 20.3225 7.09902 20.0296L0.658865 13.5899C0.237045 13.168 0 12.5957 0 11.9991C0 11.4025 0.236965 10.8304 0.658785 10.4084L7.09906 3.96962C7.39198 3.67676 7.86687 3.6768 8.15974 3.9697ZM15.8412 3.9697C16.1341 3.6768 16.6089 3.67676 16.9019 3.96962L23.342 10.4083C23.7638 10.8302 24.0009 11.4025 24.0009 11.9991C24.0009 12.5957 23.764 13.1679 23.3421 13.5898L16.9019 20.0296C16.609 20.3225 16.1341 20.3225 15.8412 20.0296C15.5483 19.7367 15.5483 19.2618 15.8412 18.969L22.2813 12.5293C22.2813 12.5293 22.2813 12.5294 22.2813 12.5293C22.4218 12.3887 22.5009 12.1979 22.5009 11.9991C22.5009 11.8003 22.4219 11.6095 22.2813 11.4689L15.8413 5.03031C15.5483 4.73745 15.5483 4.2626 15.8412 3.9697Z"
        fill={nativeColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_614_3032">
        <rect width="24.0006" height="23.9989" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
