import { LocalizedMessage } from '@provider-portal/internationalization';
import type { Locale } from '@provider-portal/internationalization/locale';
import type { AmountWithCurrency, Currency } from '@provider-portal/models/subscription';
import PropTypes from 'prop-types';
import * as React from 'react';
import { CostInput } from '../../fields/cost/CostInput';
import { NumberInput, NumberType } from '../../fields/NumberInput';
import { RadioGroup } from '../../fields/RadioGroup';
import { MonthlyDiscountFieldNames } from './';
import styles from './styles.scss';

enum ValueType {
  MONEY = 'money',
  PERCENTAGE = 'percentage',
}

interface IMonthlyDiscountFieldProps {
  monthlyDiscountAmount?: AmountWithCurrency;
  monthlyDiscountInPercent?: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  currency: Currency;
  locale: Locale;
}

export const MonthlyDiscountField: React.FunctionComponent<IMonthlyDiscountFieldProps> = (
  { monthlyDiscountAmount, monthlyDiscountInPercent, onChange, currency, locale },
  { localizeCurrency }
) => {
  const [valueType, setValueType] = React.useState<string>(
    (Boolean(monthlyDiscountInPercent) && ValueType.PERCENTAGE) || ValueType.MONEY
  );
  // We need to clear the default value from the inputs if we have toggled the radio buttons.
  // Otherwise the behavior could be really weird with default value from props jumping back even if the user changed it but toggled back and forth.
  const [radioIsPristine, setRadioIsPristine] = React.useState(true);

  const onRadioChange = (value: string) => {
    setRadioIsPristine(false);
    setValueType(value);
  };

  return (
    <div className={styles.monthlyDiscount}>
      <div className={styles.costField}>
        {valueType === ValueType.MONEY ? (
          <CostInput
            defaultValue={(radioIsPristine && monthlyDiscountAmount) || undefined}
            name={MonthlyDiscountFieldNames.MONTHLY_DISCOUNT_AMOUNT}
            label={<LocalizedMessage id="monthlyDiscountLabelV2" />}
            onChange={onChange}
            currency={currency}
            locale={locale}
          />
        ) : (
          <NumberInput
            defaultValue={(radioIsPristine && monthlyDiscountInPercent) || undefined}
            name={MonthlyDiscountFieldNames.MONTHLY_DISCOUNT_PERCENT}
            numberType={NumberType.FloatZeroOrGreater}
            label={<LocalizedMessage id="monthlyDiscountLabelV2" />}
            onChange={onChange}
            locale={locale}
          />
        )}
      </div>
      <div>
        <RadioGroup
          name=""
          defaultValue={valueType}
          options={[
            { label: localizeCurrency(currency), value: ValueType.MONEY },
            { label: '%', value: ValueType.PERCENTAGE },
          ]}
          onChange={onRadioChange}
        />
      </div>
    </div>
  );
};

MonthlyDiscountField.contextTypes = {
  localizeCurrency: PropTypes.func.isRequired,
};
