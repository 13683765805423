import head from 'lodash/head';
import * as React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NavigationMenu } from '../../NavigationMenu';
import type { IPageProps } from '../Page';
import { Page } from '../Page';
import styles from './styles.scss';

type INavigationExtendedPageProps = RouteComponentProps & IPageProps;

interface INavigationPage {
  path: string;
  menuLabel: React.ReactNode;
  component: React.ComponentType;
}

interface INavigationPageProps {
  pages: INavigationPage[];
}

export const NavigationPage: React.FunctionComponent<INavigationPageProps & INavigationExtendedPageProps> = (props) => {
  return (
    <Page {...props}>
      <div className={styles.grid}>
        <div className={styles.leftNavigation}>
          <NavigationMenu
            menuItems={props.pages.map((r) => ({
              path: r.path,
              menuLabel: r.menuLabel,
              active: props.location.pathname.includes(r.path),
            }))}
            baseUrl={props.match.url}
          />
        </div>
        <div className={styles.content}>
          <Switch>
            {props.pages.map((page) => {
              return <Route key={page.path} path={`${props.match.path}/${page.path}`} component={page.component} />;
            })}
            <Redirect from={`${props.match.path}`} to={`${props.match.path}/${head(props.pages)?.path}`} />
          </Switch>
        </div>
      </div>
    </Page>
  );
};
