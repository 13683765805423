import * as React from 'react';
import { DatePicker } from '../DatePicker';
import { FieldMessage } from '../FieldMessage';
import type { IDateInput } from './config';

export interface IDateInputProps extends Omit<IDateInput, 'type'> {
  disabled?: boolean;
  defaultValue?: Date;
}

export const DateInput: React.FunctionComponent<IDateInputProps> = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <DatePicker
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        name={props.name}
        required={props.required}
        requiredErrorText={props.required && (props.requiredErrorText || <FieldMessage id="portalRequiredField" />)}
        label={props.label}
        fullWidth
      />
    </div>
  );
};
