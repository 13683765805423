import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Dialog } from '@provider-portal/libs/uiFramework/components/dialogs/Dialog';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { createApiKeyForPartnerSupplier } from '@provider-portal/provider/api';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { useCallback, useState } from 'react';

export const ApiKeyGeneration = withLocalization('SupplierPortal/ApiKeyGeneration')(
  ({ providerId }: { providerId: string }) => {
    const [dialogOpen, setOpen] = useState(0);
    const openFirst = useCallback(() => {
      setOpen(1);
    }, [setOpen]);
    const onClose = useCallback(() => {
      setOpen(0);
    }, [setOpen]);

    const [loadingState, setLoadingState] = useState(PortalLoadingState.Loading);
    const [newApiKey, setNewApiKey] = useState('');
    const requestNewKey = useCallback(() => {
      setOpen(2);
      setLoadingState(PortalLoadingState.Loading);
      createApiKeyForPartnerSupplier(providerId)
        .then((key) => {
          setLoadingState(PortalLoadingState.Success);
          setNewApiKey(key);
        })
        .catch(() => {
          setLoadingState(PortalLoadingState.Failure);
        });
    }, [setOpen, setLoadingState, setNewApiKey, providerId]);

    return (
      <>
        <Button color="secondary" label={<LocalizedMessage id={'mainButton'} />} onClick={openFirst} />
        <Dialog
          title={<LocalizedMessage id={'dialogTitle'} />}
          open={dialogOpen === 1}
          onClose={onClose}
          bottomButton={
            <Button color={'positive'} label={<LocalizedMessage id={'dialogButton'} />} onClick={requestNewKey} />
          }
        >
          <Body>
            <LocalizedMessage id={'dialogExplanation'} />
          </Body>
          <br />
          <Body>
            <LocalizedMessage id={'dialogLogged'} />
          </Body>
        </Dialog>
        <Dialog title={<LocalizedMessage id={'dialogTitle'} />} open={dialogOpen === 2} onClose={onClose}>
          <LoadingPage loadingState={loadingState} loadingText={<LocalizedMessage id={'keyloading'} />}>
            <Body>
              <LocalizedMessage id={'newKeyIs'} />
            </Body>
            <Body>{newApiKey}</Body>
          </LoadingPage>
        </Dialog>
      </>
    );
  }
);
