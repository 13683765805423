import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import { Section } from '@provider-portal/libs/uiFramework/components/sections/Section';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import * as React from 'react';

interface IProps {
  postingDealMade?: boolean;
  postingNoDealMade?: boolean;
}

export const CALL_FIELD_NAME = 'call';
export const CALL_OFFER_ACCEPTED = 'accepted';
export const CALL_OFFER_REJECTED = 'rejected';

export const CallSection: React.FunctionComponent<IProps> = ({ postingDealMade, postingNoDealMade }) => {
  const [action, setAction] = React.useState<string>();

  const footer = (
    <>
      <span style={{ marginRight: '16px' }}>
        <Button
          onClick={() => setAction(CALL_OFFER_ACCEPTED)}
          loading={postingDealMade}
          disabled={postingNoDealMade}
          type="submit"
          label={<LocalizedMessage id="customerAccepted" />}
          color="positive"
        />
      </span>
      <Button
        onClick={() => setAction(CALL_OFFER_REJECTED)}
        loading={postingNoDealMade}
        disabled={postingDealMade}
        type="submit"
        label={<LocalizedMessage id="noDeal" />}
        color="secondary"
      />
    </>
  );

  return (
    <Section>
      <Panel1
        footer={footer}
        title={
          <Subtitle1>
            <LocalizedMessage id="whatToDo" />
          </Subtitle1>
        }
      >
        <Body1>
          <LocalizedMessage id="phoneDescriptionOne" />
        </Body1>
        <br />
        <Body1>
          <LocalizedMessage id="phoneDescriptionTwo" />
        </Body1>
      </Panel1>
      <HiddenInput name={CALL_FIELD_NAME} value={action} />
    </Section>
  );
};
