import * as React from 'react';
import { AddButton } from '../../buttons/AddButton';
import { Table } from '../../Table';
import type { ITableConfig } from '../../Table/config';
import { Page } from '../Page';

interface ITableOverviewPageComponentProps {
  appBarText: React.ReactNode;
  table: ITableConfig;
  data: any;
  onRowClick?(values: any): void;
  onAdd?(): void;
  aboveTableElement?: React.ReactNode;
  noContentMessage?: React.ReactNode;
}

export const TableOverviewPageComponent: React.FunctionComponent<ITableOverviewPageComponentProps> = ({
  appBarText,
  table,
  data,
  onRowClick,
  onAdd,
  aboveTableElement,
  noContentMessage,
}) => {
  const hasData = data.length > 0;
  const showInjectedNoContentMessage = noContentMessage && !hasData;
  const showTablesBuiltInNoContentMessage = !hasData && !noContentMessage;

  return (
    <Page appBarText={appBarText} variant={'overview'}>
      {aboveTableElement}
      {showInjectedNoContentMessage && noContentMessage}
      {(hasData || showTablesBuiltInNoContentMessage) && <Table config={table} data={data} onRowClick={onRowClick} />}
      {onAdd && <AddButton onClick={onAdd} />}
    </Page>
  );
};
