import React from 'react';
import type { EllipsisProps } from '../shared/ellipsis';
import { getEllipsisStyles } from '../shared/ellipsis';
import type { Omit, TypographyProps } from '../Typography';
import { Typography } from '../Typography';

export const Headline3: React.FunctionComponent<Headline3Props> = ({ style, ellipsis, maxWidth, ...otherProps }) => {
  /**
   * 1.5rem corresponds to 24px in Invision
   */
  const ellipsisStyles = getEllipsisStyles(maxWidth, ellipsis);
  const baseStyle = {
    fontSize: '1.5rem',
    fontWeight: 500,
    letterSpacing: '0.2px',
    lineHeight: '1.16em',
    ...ellipsisStyles,
    ...style,
  };

  return <Typography {...otherProps} variant="h3" style={baseStyle} />;
};

type Headline3Props = Omit<TypographyProps, 'variant'> & EllipsisProps;
