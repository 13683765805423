import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { negative } from '@provider-portal/styles/colors-standard';
import * as React from 'react';
import type { IUploadFile } from '../models';

interface IFileRowProps {
  file: IUploadFile;
  onDelete(): void;
}

export const FileRow: React.FunctionComponent<IFileRowProps> = ({ file, onDelete }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Body>{file.name}</Body>
      <IconButton style={{ marginLeft: '4px' }} onClick={onDelete}>
        <RemoveCircleOutlineIcon style={{ fill: negative.main }} />
      </IconButton>
    </div>
  );
};
