export interface IUploadFile {
  id: string;
  name: string;
  storageName: string;
}

export enum FileType {
  PNG = 'png',
  PDF = 'pdf',
  CSV = 'csv',
}
