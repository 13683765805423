import MuiButton from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { ButtonText } from '@provider-portal/libs/uiFramework/components/text/ButtonText';
import { colors } from '@provider-portal/styles/colors';
import { lighter, white } from '@provider-portal/styles/colors-standard';
import * as React from 'react';

export type ButtonVariant = 'contained' | 'outlined';
export type ButtonColor = 'primary' | 'positive' | 'negative' | 'secondary';
export type ButtonType = 'submit';

interface IButtonProps {
  label: React.ReactNode;
  variant?: ButtonVariant;
  color: ButtonColor;
  loading?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  type?: ButtonType;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
}

const styles = createStyles({
  root: {
    borderRadius: '8px',
    minWidth: '112px',
    minHeight: '36px',
    '&:hover': {
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    },
  },
});

const backgroundColor = (color: ButtonColor, disabled: boolean) => {
  return (
    (disabled && lighter.main) ||
    (color === 'secondary' && white.main) ||
    (color === 'negative' && colors.accent.danger) ||
    (color === 'positive' && colors.accent.positive) ||
    (color === 'primary' && colors.primary) ||
    undefined
  );
};

const generateColor = (color: ButtonColor) => {
  return color === 'secondary' ? 'primary' : 'alternative';
};

const ButtonInner: React.FunctionComponent<IButtonProps & WithStyles<typeof styles>> = ({
  label,
  variant = 'contained',
  color,
  loading,
  disabled,
  type,
  onClick,
  classes,
  fullWidth,
}) => {
  return (
    <MuiButton
      fullWidth={fullWidth}
      variant={variant}
      style={{
        backgroundColor: backgroundColor(color, Boolean(disabled || loading)),
        boxShadow:
          variant === 'contained'
            ? '0px 3px 10px rgba(34, 34, 34, 0.1), 0px 2px 3px rgba(34, 34, 34, 0.1), 0px 1px 1px rgba(34, 34, 34, 0.06)'
            : 'none',
      }}
      classes={{
        root: classes.root,
      }}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
    >
      {!loading && (
        <ButtonText
          colorVariant={disabled ? 'dark' : undefined}
          textColor={disabled ? undefined : generateColor(color)}
        >
          {label}
        </ButtonText>
      )}
      {loading && <CircularProgress color="primary" size={24} thickness={6} />}
    </MuiButton>
  );
};

export const Button: React.ComponentType<IButtonProps> = withStyles(styles)(ButtonInner);
