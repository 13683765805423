import { LocalizedMessage } from '@provider-portal/internationalization';
import { CentesimalCostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CentesimalCostInput/config';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import {
  FieldArray,
  FieldArrayItemComponentType,
} from '@provider-portal/libs/uiFramework/components/fields/FieldArray/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import { RadioGroup } from '@provider-portal/libs/uiFramework/components/fields/RadioGroup/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { AccommodationType, ElectricityPriceType, EnergySource } from '@provider-portal/products/models';
import * as React from 'react';
import { campaignPaper, internalPaper, electricityNamingSection, termsPaper } from '../common';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select/config';
import type { ProductFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createProductFormLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { Currency } from '@provider-portal/models/subscription';
import { Locale } from '@provider-portal/internationalization/locale';

enum DenmarkElectricityProductFieldNames {
  PRICE_TYPE = 'priceType',
  DK_WEST_PRICE = 'dkWestPricePerKwh',
  DK_EAST_PRICE = 'dkEastPricePerKwh',
  MONTHLY_COST = 'monthlyCost',
  COST = 'cost',
  LOWER_BOUND = 'lowerBound',
  UPPER_BOUND = 'upperBound',
  ENERGY_SOURCE = 'energySource',
  ACCOMMODATION_TYPE = 'accommodationType',
  FORM_TYPE = 'type',
}

const currency = Currency.DKK;
const locale = Locale.daDK;

const denmarkElectricityPapers = (hasAdminAccess: boolean): IFormPagePaper[] => [
  {
    sections: [electricityNamingSection, ...denmarkElectricitySections],
  },
  termsPaper(locale),
  campaignPaper,
  internalPaper(hasAdminAccess, currency, locale),
];

export const denmarkElectricityLayoutMap: ProductFormLayoutMap = createProductFormLayout(
  categories.electricity,
  Market.Denmark,
  denmarkElectricityPapers
);

const denmarkElectricitySections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="pricingTitle" />,
    captionText: <LocalizedMessage id="electricityPriceCaptionText" />,
    tooltipTitle: <LocalizedMessage id="pricingTitle" />,
    tooltipText: <LocalizedMessage id="consumptionHeaderTooltiptext" />,
    columns: 4,
    components: [
      RadioGroup({
        name: DenmarkElectricityProductFieldNames.PRICE_TYPE,
        options: [
          { value: ElectricityPriceType.Floating, label: <LocalizedMessage id="floatingPriceLabel" /> },
          { value: ElectricityPriceType.Fixed, label: <LocalizedMessage id="fixedPriceLabel" /> },
        ],
        required: true,
      }),
      HiddenInput({
        name: DenmarkElectricityProductFieldNames.FORM_TYPE,
        defaultValue: 'DenmarkElectricityProductIncomingRequest',
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="consumptionPriceHeader" />,
    tooltipTitle: <LocalizedMessage id="consumptionPriceHeader" />,
    tooltipText: <LocalizedMessage id="consumptionHeaderTooltiptext" />,
    columns: 4,
    components: [
      CentesimalCostInput({
        name: DenmarkElectricityProductFieldNames.DK_WEST_PRICE,
        label: <LocalizedMessage id="dkWestPriceLabel" />,
        required: true,
        currency: currency,
        locale: locale,
      }),
      CentesimalCostInput({
        name: DenmarkElectricityProductFieldNames.DK_EAST_PRICE,
        label: <LocalizedMessage id="dkEastPriceLabel" />,
        required: true,
        currency: currency,
        locale: locale,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="monthlyCostHeader" />,
    captionText: <LocalizedMessage id="monthlyCostDescription" />,
    tooltipTitle: <LocalizedMessage id="monthlyCostHeader" />,
    tooltipText: <LocalizedMessage id="montlyCostTooltipText" />,
    columns: 1,
    components: [
      FieldArray({
        name: DenmarkElectricityProductFieldNames.MONTHLY_COST,
        columns: 2,
        fields: [
          CostInput({
            name: DenmarkElectricityProductFieldNames.COST,
            label: <LocalizedMessage id="monthlyCostElectricityLabelV3" />,
            required: true,
            currency: currency,
            locale: locale,
          }),
          NumberInput({
            name: DenmarkElectricityProductFieldNames.LOWER_BOUND,
            label: <LocalizedMessage id="lowerBoundaryLabel" />,
            numberType: NumberType.IntegerZeroOrGreater,
            required: true,
            locale: locale,
          }),
          NumberInput({
            name: DenmarkElectricityProductFieldNames.UPPER_BOUND,
            label: <LocalizedMessage id="upperBoundaryLabel" />,
            numberType: NumberType.IntegerZeroOrGreater,
            required: true,
            locale: locale,
          }),
        ],
        addItemButtonLabel: <LocalizedMessage id="addIntervalButtonLabel" />,
        itemComponent: {
          type: FieldArrayItemComponentType.FieldGroup1,
          title: <LocalizedMessage id="monthlyCostHeader" />,
          columns: 2,
        },
        required: true,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="energySourceHeader" />,
    tooltipTitle: <LocalizedMessage id="energySourceHeader" />,
    tooltipText: <LocalizedMessage id="electricitySourceTooltipText" />,
    columns: 4,
    components: [
      Select({
        name: DenmarkElectricityProductFieldNames.ENERGY_SOURCE,
        options: [{ value: EnergySource.MixedUndefined, label: <LocalizedMessage id="mixedUndefinedLabel" /> }],
        required: true,
        defaultValue: EnergySource.MixedUndefined,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="accommodationTypeHeader" />,
    captionText: <LocalizedMessage id="accommodationTooltipText" />,
    columns: 4,
    components: [
      Select({
        name: DenmarkElectricityProductFieldNames.ACCOMMODATION_TYPE,
        defaultValue: AccommodationType.All,
        options: [
          { value: AccommodationType.All, label: <LocalizedMessage id="accommodationTypeAllLabel" /> },
          { value: AccommodationType.Apartment, label: <LocalizedMessage id="accommodationTypeApartmentLabel" /> },
          { value: AccommodationType.Villa, label: <LocalizedMessage id="accommodationTypeVillaLabel" /> },
        ],
        required: true,
      }),
    ],
  },
];
