import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { isSaveDeskEnabled } from './api';

interface IReturnValue {
  loadingState: PortalLoadingState;
  saveDeskEnabled: boolean;
}

export function useFetchProviderSettingsOnEnter(partnerSupplierId?: string): IReturnValue {
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);
  const [saveDeskEnabled, setSaveDeskEnabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    let mounted = true;

    if (partnerSupplierId) {
      isSaveDeskEnabled(partnerSupplierId)
        .then((enabled) => {
          if (mounted) {
            setSaveDeskEnabled(enabled);
            setLoadingState(PortalLoadingState.Success);
          }
        })
        .catch(() => {
          if (mounted) {
            setLoadingState(PortalLoadingState.Failure);
          }
        });
    }

    return () => {
      mounted = false;
    };
  }, [partnerSupplierId]);

  return { loadingState, saveDeskEnabled };
}
