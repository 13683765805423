import type { Market } from '@provider-portal/app-context/constants';
import type { EnrichMap, EnrichWithFormData, IEnrichProps } from '@provider-portal/libs/uiFramework';
import type { AmountWithCurrency } from '@provider-portal/models/subscription';
import { MonthlyDiscountFieldNames } from '.';
import { WidgetType } from '../config';

export interface IMonthlyDiscountWidget {
  type: WidgetType.MONTHLY_DISCOUNT;
  monthlyDiscountAmount?: AmountWithCurrency;
  monthlyDiscountInPercent?: number;
  monthlyDiscountLength?: number;
  market: Market;
}

const monthlyDiscountEnricher: EnrichWithFormData = (props: IEnrichProps<any>) => {
  return {
    ...props.config,
    monthlyDiscountAmount: props.formData[MonthlyDiscountFieldNames.MONTHLY_DISCOUNT_AMOUNT],
    monthlyDiscountInPercent: props.formData[MonthlyDiscountFieldNames.MONTHLY_DISCOUNT_PERCENT],
    monthlyDiscountLength: props.formData[MonthlyDiscountFieldNames.MONTHLY_DISCOUNT_LENGTH],
  };
};

export const monthlyDiscountEnricherMap: EnrichMap = [WidgetType.MONTHLY_DISCOUNT, monthlyDiscountEnricher];
