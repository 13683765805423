import { LocalizedMessage } from '@provider-portal/internationalization';
import type { RejectedReason } from '@provider-portal/models/order';
// @ts-ignore js import, remove this when the import is typed
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { DividerSubSection } from '@provider-portal/libs/uiFramework/components/dividers/DividerSubSection';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import type { ISwitchOrderOutcome } from '@provider-portal/orders/switch/models';
import { ResponseType } from '@provider-portal/orders/switch/models';
import * as React from 'react';
import { ApproveOrder } from './ApproveOrder';
import { RejectOrder } from './RejectOrder';
import { ResponseRadioButtons } from './ResponseRadioButtons';
import styles from './styles.scss';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';

export interface IActiveResponseCardProps {
  rejectionReasons: RejectedReason[];
  acceptationComponents: React.ReactNode[];
  onUpdateOrderOutcome(outcome: ISwitchOrderOutcome, portingDate?: string): void;
  updateInProgress: boolean;
}

export const ActiveResponseCard: React.FunctionComponent<IActiveResponseCardProps> = ({
  rejectionReasons,
  acceptationComponents,
  onUpdateOrderOutcome,
  updateInProgress,
}) => {
  const [selectedResponseType, setSelectedResponseType] = React.useState(ResponseType.Accepted);

  return (
    <Paper title={<LocalizedMessage id="supplierResponseCardTitle" />}>
      <Body style={{ padding: '0 16px 12px' }} colorVariant="lighter">
        <LocalizedMessage id="supplierResponseCardDescription" />
      </Body>
      <Form onValidSubmit={onUpdateOrderOutcome}>
        <div className={styles.container}>
          <ResponseRadioButtons selectedResponseType={selectedResponseType} onChange={setSelectedResponseType} />
          <DividerSubSection />
          {selectedResponseType === ResponseType.Accepted ? (
            <>
              {acceptationComponents}
              <ApproveOrder updateInProgress={updateInProgress} />
            </>
          ) : (
            <RejectOrder rejectionReasons={rejectionReasons} updateInProgress={updateInProgress} />
          )}
        </div>
      </Form>
    </Paper>
  );
};
