import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { withAppContext } from '@provider-portal/app-context/react';
import { CampaignIcon } from '@provider-portal/assets/icons/CampaignIcon';
import EnvelopeIcon from '@provider-portal/assets/icons/envelopeIcon.svg';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import type { MenuModuleProps } from '@provider-portal/libs/uiFramework/components/Menu/models';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';
import { useFetchProviderSettingsOnEnter } from '@provider-portal/saveDesk/SaveDeskMenuModule/use-fetch';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import startsWith from 'lodash/startsWith';
import React from 'react';
import { MenuItem } from '../../libs/uiFramework/components/Menu/components/MenuItem';

const styles = () =>
  createStyles({
    overline: {
      margin: '16px 0px 16px 16px',
    },
  });

const SaveDeskMenuInnerComponent: React.FC<MenuModuleProps & WithStyles<typeof styles>> = ({
  path,
  hasAdminAccess,
  providerId,
  classes,
}) => {
  const { loadingState, saveDeskEnabled } = useFetchProviderSettingsOnEnter(providerId);
  const pathPrefix = startsWith(path, '/admin') ? '/admin/suppliers' : '/suppliers';
  const saveDeskOffersPath = `${pathPrefix}/${providerId}/save-desk/offers`;
  const saveDeskOrdersPath = `${pathPrefix}/${providerId}/save-desk/orders`;

  if (loadingState === PortalLoadingState.Success) {
    if (hasAdminAccess || saveDeskEnabled) {
      return (
        <>
          <Overline className={classes.overline}>
            <LocalizedMessage id="saveDeskSection" />
          </Overline>
          <MenuItem
            label={<LocalizedMessage id="offers" />}
            icon={<CampaignIcon />}
            to={saveDeskOffersPath}
            subItem
            selected={startsWith(path, saveDeskOffersPath)}
          />
          <MenuItem
            label={<LocalizedMessage id="orders" />}
            icon={<img src={EnvelopeIcon} alt="orders" />}
            to={saveDeskOrdersPath}
            subItem
            selected={startsWith(path, saveDeskOrdersPath)}
          />
        </>
      );
    } else {
      return <></>;
    }
  } else {
    return <div data-testid="saveDeskMenuComponent-loading" />;
  }
};

// @ts-ignore
export const SaveDeskMenuComponent: React.FC<any> = withStyles(styles)(
  withLocalization('Menu')(withAppContext(SaveDeskMenuInnerComponent))
);
