import type * as React from 'react';
import type { IBaseField, IField } from '../configs';
import { fieldConstructor, FieldType } from '../configs';
import type { Columns } from '../configs/columns';
import type { IFieldArrayItem } from './models';

export enum FieldArrayItemComponentType {
  FieldGroup1 = 'FieldGroup1',
}

export interface IFieldArrayItemConfig {
  type: FieldArrayItemComponentType;
  title?: React.ReactNode;
  columns: Columns;
}

export interface IFieldArray extends IBaseField {
  type: FieldType.FieldArray;
  label?: React.ReactNode;
  fields: IField[]; //TODO: Should move fields to component like FieldItemComponent
  columns: Columns;
  addItemButtonLabel: React.ReactNode;
  itemComponent: IFieldArrayItemConfig; //TODO: Support ItemComponents that can include widgets
  defaultValue?: { [key: string]: any }[];
}

export interface IFieldArrayProps {
  config: IFieldArray;
  items: IFieldArrayItem[];
}

export const FieldArray = fieldConstructor<IFieldArray>(FieldType.FieldArray);
