import { LocalizedMessage } from '@provider-portal/internationalization';
import React from 'react';

export enum ChangePlanOfferSnackbarState {
  CLOSED = 'closed',
  OFFER_UPDATED = 'updated',
  OFFER_UNPUBLISHED = 'unpublished',
}

export function snackbarContent(snackbarState: ChangePlanOfferSnackbarState) {
  switch (snackbarState) {
    case ChangePlanOfferSnackbarState.OFFER_UPDATED:
      return <LocalizedMessage id="changePlanOfferUpdated" />;
    case ChangePlanOfferSnackbarState.OFFER_UNPUBLISHED:
      return <LocalizedMessage id="changePlanOfferUnpublished" />;
    default:
      return null;
  }
}
