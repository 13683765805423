import type { Market } from '@provider-portal/app-context/constants';
import type { IField } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { mapConfigsToFieldsOrWidgets } from '@provider-portal/libs/uiFramework/components/fields/configs';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { SupplierWithService } from '@provider-portal/PartnerSupplier/models';
import type { IProviderFormData } from '../models';
import { companyProperties1, companyProperties2, companyProperties3, ProviderFormFieldNames } from './config';

export function formPapers(
  subscriptionSuppliers: SupplierWithService[],
  formData: IProviderFormData,
  market: Market
): IFormPagePaper[] {
  return [
    {
      sections: [
        {
          ...companyProperties1,
          components: mapConfigsToFieldsOrWidgets(companyProperties1.components, (config) => {
            const field: IField = { ...config, defaultValue: formData[config.name] };
            if (
              config.name === ProviderFormFieldNames.LOGOTYPE &&
              formData.logotypeFileName &&
              formData.logotypeResourceName
            ) {
              field.fileValue = {
                resourceName: formData.logotypeResourceName,
                fileName: formData.logotypeFileName,
              };
            }

            return field;
          }),
        },
        {
          ...companyProperties2,
          components: mapConfigsToFieldsOrWidgets(companyProperties2.components, (config) => {
            const field: IField = { ...config, defaultValue: formData[config.name] };

            if (config.name === ProviderFormFieldNames.SUPPLIER_ID) {
              field.options = subscriptionSuppliers.map((s) => ({ label: s.name, value: s.id }));
            }

            return field;
          }),
        },
        {
          ...companyProperties3,
          components: mapConfigsToFieldsOrWidgets(
            companyProperties3.components,
            (config) => {
              if (config.name === ProviderFormFieldNames.MARKET) {
                return {
                  ...config,
                  defaultValue: market,
                };
              } else {
                return {
                  ...config,
                  defaultValue: formData[config.name],
                };
              }
            },
            (config) => ({
              ...config,
              defaultValue: config.name ? formData[config.name] : '',
              market,
            })
          ),
        },
      ],
    },
  ];
}
