import { alpha } from '@material-ui/core/styles/colorManipulator';
import type { TypographyProps as MaterialUITypographyProps } from '@material-ui/core/Typography';
import MaterialUiTypography from '@material-ui/core/Typography';
import { primary } from '@provider-portal/styles/colors-standard';
import PropTypes from 'prop-types';
import React from 'react';

export type TypographyProps = Omit<MaterialUITypographyProps, 'color' | 'gutterBottom'> & CustomTypographyProps;

export type Omit<T, K extends keyof any> = T extends any ? Pick<T, Exclude<keyof T, K>> : never;

export type ColorVariant = 'dark' | 'darker' | 'light' | 'lighter';
export type TextColor = 'alternative' | 'default' | 'primary';

interface CustomTypographyProps {
  textColor?: TextColor;
  colorVariant?: ColorVariant;
}

export const Typography: React.FunctionComponent<TypographyProps> = (
  { textColor, colorVariant, style, ...otherProps },
  { muiTheme }
) => {
  const color = generateTextColor(textColor, colorVariant, muiTheme);

  return <MaterialUiTypography {...otherProps} style={{ color: color, display: 'block', ...style }} />;
};

Typography.contextTypes = {
  muiTheme: PropTypes.object.isRequired,
};

function generateTextColor(textColor: any, colorVariant: any, muiTheme: any) {
  let color;
  if (textColor === 'primary') {
    color = primary.dark;
  } else if (textColor === 'alternative') {
    color = muiTheme.palette.alternateTextColor;
  } else if (!textColor || textColor === 'default') {
    color = muiTheme.palette.textColor;
  } else {
    color = muiTheme.palette.textColor;
  }

  if (colorVariant === 'dark') {
    color = alpha(color, 0.6);
  } else if (colorVariant === 'darker') {
    color = alpha(color, 0.8);
  } else if (colorVariant === 'light') {
    color = alpha(color, 0.6);
  } else if (colorVariant === 'lighter') {
    color = alpha(color, 0.75);
  }

  return color;
}
