import type { RejectedReason } from '@provider-portal/models/order';
import * as React from 'react';
import type { ISwitchOrderOutcome, ISwitchOrderState } from '../../models';
import { isClosedOrderState } from '../../utils';
import { ActiveResponseCard } from './ActiveResponseCard';
import { ClosedResponseCard } from './ClosedResponseCard';

export interface ISupplierResponseCardProps {
  rejectionReasons: RejectedReason[];
  acceptationComponents: React.ReactNode[];
  onUpdateOrderOutcome(outcome: ISwitchOrderOutcome, portingDate?: string): void;
  updateInProgress: boolean;
  orderState: ISwitchOrderState;
}

export const SwitchSupplierResponseCard: React.FunctionComponent<ISupplierResponseCardProps> = (props) => {
  if (isClosedOrderState(props.orderState)) {
    return <ClosedResponseCard orderState={props.orderState} />;
  } else {
    return <ActiveResponseCard {...props} />;
  }
};
