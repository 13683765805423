import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { info, ink, negative, positive, warning } from '@provider-portal/styles/colors-standard';
import Button from '@material-ui/core/Button/Button';
import { default as MUISnackbar } from '@material-ui/core/Snackbar/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent/SnackbarContent';
import { CheckCircleIcon } from '@provider-portal/libs/uiFramework/components/deprecated/CheckCircleIcon';
import { ErrorIcon } from '@provider-portal/libs/uiFramework/components/deprecated/ErrorIcon';
import { InfoIcon } from '@provider-portal/libs/uiFramework/components/deprecated/InfoIcon';
import { WarningIcon } from '@provider-portal/libs/uiFramework/components/deprecated/WarningIcon';
import * as React from 'react';

type SnackbarVariant = 'error' | 'info' | 'success' | 'warning';

export type SnackbarProps =
  | {
      actionLabel?: never;
      autoClose?: boolean;
      content: React.ReactNode;
      open: boolean;
      variant: SnackbarVariant;

      onAction?: never;
      onClose(): void;
    }
  | {
      /**
       * Only required if the variant is set to 'action', otherwise it should not be set
       */
      actionLabel: string;
      autoClose?: boolean;
      content: React.ReactNode;
      open: boolean;
      variant: 'action';

      /**
       * Only required if the variant is set to 'action', otherwise it should not be set
       */
      onAction(): void;
      onClose(): void;
    };

const styles: any = {
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  textAlign: 'left',
};

const variantStyles: any = {
  action: {
    backgroundColor: ink.main,
  },
  error: {
    backgroundColor: negative.main,
  },
  warning: {
    backgroundColor: warning.main,
  },
  success: {
    backgroundColor: positive.main,
  },
  info: {
    backgroundColor: info.main,
  },
};

const iconStyles: React.CSSProperties = {
  marginRight: '8px',
};

const variantIcon: any = {
  success: <CheckCircleIcon style={iconStyles} />,
  warning: <WarningIcon style={iconStyles} />,
  error: <ErrorIcon style={iconStyles} />,
  info: <InfoIcon style={iconStyles} />,
};

export const Snackbar: React.FunctionComponent<SnackbarProps> = ({
  actionLabel,
  autoClose,
  content,
  open,
  variant,
  onAction,
  onClose,
}) => {
  const displayContent =
    variant !== 'action' ? (
      <div style={styles}>
        {variantIcon[variant]}
        {content}
      </div>
    ) : (
      <React.Fragment>{content}</React.Fragment>
    );

  const actionContent =
    actionLabel && onAction ? (
      <Button
        color="primary"
        size="small"
        onClick={() => {
          onAction();
          onClose();
        }}
      >
        {actionLabel}
      </Button>
    ) : (
      <ActionButton onClose={onClose} />
    );

  return (
    <MUISnackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoClose ? 5000 : undefined}
      data-test={`snackbar-${variant}`}
    >
      <SnackbarContent style={variantStyles[variant]} action={actionContent} message={displayContent} />
    </MUISnackbar>
  );
};

const ActionButton = withLocalization('provider-portal-snackback/Snackbar')(({ onClose }: { onClose(): void }) => {
  return (
    <Button color="inherit" size="small" onClick={onClose}>
      <LocalizedMessage id="close" />
    </Button>
  );
});
