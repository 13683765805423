import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { NumberInput, NumberType } from '../../fields/NumberInput';
import type { IMonthlyDiscountWidget } from './config';
import { MonthlyDiscountField } from './MonthlyDiscountField';
import { getDefaultCurrency, getDefaultLocale } from '@provider-portal/app-context/state';

type IMonthlyDiscountProps = Omit<IMonthlyDiscountWidget, 'type'>;

export enum MonthlyDiscountFieldNames {
  MONTHLY_DISCOUNT_AMOUNT = 'monthlyDiscountAmount',
  MONTHLY_DISCOUNT_PERCENT = 'monthlyDiscountInPercent',
  MONTHLY_DISCOUNT_LENGTH = 'monthlyDiscountLength',
}

const MonthlyDiscountInner: React.FunctionComponent<IMonthlyDiscountProps> = (props) => {
  const [hasMonthlyDiscount, setHasMonthlyDiscount] = React.useState<boolean>(
    Boolean(props.monthlyDiscountAmount || props.monthlyDiscountInPercent)
  );
  const [hasMonthlyDiscountLength, setHasMonthlyDiscountLength] = React.useState<boolean>(
    Boolean(props.monthlyDiscountLength)
  );
  const locale = getDefaultLocale(props.market);
  const currency = getDefaultCurrency(props.market);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <MonthlyDiscountField
          monthlyDiscountAmount={props.monthlyDiscountAmount}
          monthlyDiscountInPercent={props.monthlyDiscountInPercent}
          onChange={(e) => setHasMonthlyDiscount(Boolean(e.target.value))}
          locale={locale}
          currency={currency}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          name={MonthlyDiscountFieldNames.MONTHLY_DISCOUNT_LENGTH}
          defaultValue={props.monthlyDiscountLength}
          numberType={NumberType.PositiveInteger}
          label={<LocalizedMessage id="numberOfMonthsLabelV2" />}
          onChange={(e) => setHasMonthlyDiscountLength(Boolean(e.target.value))}
          required={hasMonthlyDiscount && !hasMonthlyDiscountLength}
          requiredErrorText={<LocalizedMessage id="specifiedCampaignRequiresLength" />}
          locale={locale}
        />
      </Grid>
    </Grid>
  );
};

export const MonthlyDiscount = withLocalization('SupplierPortal/PartnerCampaign')(MonthlyDiscountInner);
