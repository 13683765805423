import * as React from 'react';

export function useFetchOnMount<T>(fetchData: () => Promise<T>, onSuccess: (data: T) => void, onError: () => void) {
  const [mounted, setMounted] = React.useState<boolean>(true);

  React.useEffect(() => {
    fetchData()
      .then((data: T) => {
        if (mounted) {
          onSuccess(data);
        }
      })
      .catch(() => {
        onError();
      });

    return () => {
      setMounted(false);
    };
    // eslint-disable-next-line
  }, []);
}
