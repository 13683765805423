import type * as React from 'react';
import type { IFieldArrayItemComponentProps } from './';
import { FieldArrayItemComponentType } from './config';
import { FieldGroup1 } from './item-components/FieldGroup1';

export function fieldArrayComponent(
  type: FieldArrayItemComponentType
): React.ComponentType<IFieldArrayItemComponentProps> {
  switch (type) {
    case FieldArrayItemComponentType.FieldGroup1:
      return FieldGroup1;
  }
}
