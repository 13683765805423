import CircularProgress from '@material-ui/core/CircularProgress';
import { WarningIcon } from '@provider-portal/assets/icons/WarningIcon';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { FieldMessage } from '../fields/FieldMessage';
import styles from './styles.scss';

interface ILoadingComponentProps {
  loadingState: PortalLoadingState;
  children?: React.ReactNode;
}

export const LoadingComponent: React.FunctionComponent<ILoadingComponentProps> = ({ loadingState, children }) => {
  if (loadingState === PortalLoadingState.Loading) {
    return (
      <div className={styles.center}>
        <CircularProgress />
      </div>
    );
  } else if (loadingState === PortalLoadingState.Failure) {
    return (
      <div className={styles.error}>
        <WarningIcon />
        <Body1>
          <FieldMessage id="loadingComponentErrorMessage" />
        </Body1>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};
