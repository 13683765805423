import * as React from 'react';
import { DividerSection } from '../../dividers/DividerSection';
import type { IInfoPanelsSection } from '../../sections/InfoPanelsSection';
import { InfoPanelsSection } from '../../sections/InfoPanelsSection';
import type { IInfoSection } from '../../sections/InfoSection';
import { InfoSection } from '../../sections/InfoSection';
import { Paper } from '../Paper';
import type { IInfoPaper } from './config';

export const InfoPaper: React.FunctionComponent<IInfoPaper> = ({ sections }) => {
  return <Paper>{sections.map(renderSection)}</Paper>;
};

function isInfoSection(section: IInfoSection | IInfoPanelsSection): section is IInfoSection {
  return (section as IInfoSection).items !== undefined;
}

function isInfoPanelsSection(section: IInfoSection | IInfoPanelsSection): section is IInfoPanelsSection {
  return (section as IInfoPanelsSection).panels !== undefined;
}

function renderSection(section: IInfoSection | IInfoPanelsSection, index: number): React.ReactNode {
  let sectionComponent: React.ReactNode;

  if (isInfoSection(section)) {
    sectionComponent = <InfoSection {...section} />;
  } else if (isInfoPanelsSection(section)) {
    sectionComponent = <InfoPanelsSection {...section} />;
  }

  return (
    <React.Fragment key={index}>
      {index > 0 && <DividerSection />}
      {sectionComponent}
    </React.Fragment>
  );
}
