import type { Fetchable } from '@provider-portal/models/Fetchable';
import { fetchableHandle, FetchableNotStarted } from '@provider-portal/models/Fetchable';
import type { ActionsUnion } from '@provider-portal/utils/redux';
import { createPromiseAction } from '@provider-portal/utils/redux';
import type { ApplicationState } from '../store';
import { fetchUser, fetchUsers } from './api';
import type { SupplierPortalUser } from './models';

enum ActionKeys {
  FETCH_USERS = 'USER_MANAGEMENT/FETCH_USERS',
  FETCH_USER = 'USER_MANAGEMENT/FETCH_USER',
}

export const UserManagementActions = {
  fetchUsers: () => createPromiseAction(ActionKeys.FETCH_USERS, fetchUsers()),
  fetchUser: (userId: string) => createPromiseAction(ActionKeys.FETCH_USER, fetchUser(userId)),
};

export type Actions = ActionsUnion<typeof UserManagementActions>;

export interface UserManagementState {
  users: Fetchable<SupplierPortalUser[]>;
  user: Fetchable<SupplierPortalUser>;
}

const initialState: UserManagementState = {
  users: FetchableNotStarted,
  user: FetchableNotStarted,
};

export function reducer(state: UserManagementState = initialState, action: Actions): UserManagementState {
  switch (action.type) {
    case ActionKeys.FETCH_USERS:
      return fetchableHandle(state, action, (fetchable) => ({ ...state, users: fetchable }));
    case ActionKeys.FETCH_USER:
      return fetchableHandle(state, action, (fetchable) => ({ ...state, user: fetchable }));
    default:
      return state;
  }
}

export const selectorFetchableUsers = (state: ApplicationState) => state.userManagement.users;
export const selectorFetchableUser = (state: ApplicationState) => state.userManagement.user;
