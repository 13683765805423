import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { MinnaIcon } from '@minna-technologies/minna-ui/icons/Minna';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
  },
}));

export const PageAppBar: React.FunctionComponent = withLocalization('ChangePlan')(() => {
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <div className={classes.flexRow}>
      <MinnaIcon nativeColor={colors.base.surface} />
      <Headline5 textColor="alternative">
        <LocalizedMessage id="headerTitle" />
      </Headline5>
    </div>
  );
});
