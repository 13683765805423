import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { MinnaIcon } from '@minna-technologies/minna-ui/icons/Minna';
import { useLocalization } from '@provider-portal/internationalization';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import type { ChangeEventHandler } from 'react';
import React, { useEffect, useState } from 'react';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { TextInput } from '@minna-technologies/minna-ui/components/Inputs/TextInput';
import { Select } from '@minna-technologies/minna-ui/components/Inputs/Select';
import { SelectOption } from '@minna-technologies/minna-ui/components/Inputs/Select/components/SelectOption';
import ChangePlanIllustration from '@provider-portal/assets/illustrations/ExamplePauseView.png';
import type { PauseDuration, PauseMethod } from '@provider-portal/features/PausePage/models';
import { PauseDurationUnit, PauseMethodType, ResumeMethod } from '@provider-portal/features/PausePage/models';
import { fetchPauseSettings, patchPauseSettings } from '@provider-portal/features/PausePage/api';
import { DeleteIcon } from '@minna-technologies/minna-ui/icons/Delete';
import classNames from 'classnames';
import { Sentry } from '@provider-portal/sentry';

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
  },
  // Hack margin to center align the unitOrRemove component due to an unfortunate hard-coded margin found in the TextInput component
  unitOrRemove: {
    marginTop: theme.spacing(2.5),
  },
  durationBoxes: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'start',
    width: '100%',
  },
  durationBox: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
    width: '50%',
    alignItems: 'center',
  },
  pageContent: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  introductionMessage: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  },
  illustration: {
    maxWidth: '400px',
  },
  button: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
  },
}));

export const PausePage = ({ merchantId }: { merchantId: string }) => {
  const { localizeMessage } = useLocalization('PausePage');
  const { colors } = useTheme();
  const classes = useStyles();
  /**
   * ToDo: Currently, the only supported PauseMethod is PauseDuration. In the future, this will be a union of more types,
   * and then this code would brake. Thankfully TypeScript will be there to complain about it and let us know.
   */
  const [pauseMethods, setPauseMethods] = useState<PauseMethod[]>([
    { type: PauseMethodType.PauseDuration, amount: 0, unit: PauseDurationUnit.Month },
  ]);
  useEffect(() => {
    const fetchDurationsEffect = async () => {
      const fetchedPauseSettings = await fetchPauseSettings(merchantId).catch((error) => {
        Sentry.captureExceptionWithMessage(error, 'Failed to load pause settings in PausePage');
        throw new Error(error);
      });
      if (fetchedPauseSettings.pauseMethods.length) {
        setPauseMethods(fetchedPauseSettings.pauseMethods);
      }
    };

    fetchDurationsEffect();
  }, [merchantId]);

  const updateDurationUnit: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const newPauseMethods: PauseMethod[] = pauseMethods.map((dur) => {
      return {
        type: PauseMethodType.PauseDuration,
        amount: dur.amount,
        unit: event.target.value as unknown as PauseDurationUnit,
      };
    });
    setPauseMethods(newPauseMethods);
  };
  function updateDurationValue(index: any): ChangeEventHandler<HTMLInputElement> {
    return (event) => {
      const oldDurationUnit = pauseMethods.at(index)?.unit || PauseDurationUnit.Month;
      const newDuration: PauseDuration = {
        type: PauseMethodType.PauseDuration,
        amount: parseInt(event.target.value),
        unit: oldDurationUnit,
      };
      const newDurations: PauseDuration[] = pauseMethods.map((dur, i) => (i === index ? newDuration : dur));
      setPauseMethods(newDurations);
    };
  }
  function addOption() {
    setPauseMethods([...pauseMethods, { type: PauseMethodType.PauseDuration, unit: pauseMethods[0].unit }]);
  }
  function deleteOption(index: number) {
    const newDurations = [...pauseMethods.slice(0, index), ...pauseMethods.slice(index + 1)];
    setPauseMethods(newDurations);
  }

  const onClickSaveHandler = () => {
    // ToDo: Remove the hard-coded resume methods once the UI handles them as well.
    patchPauseSettings(merchantId, { pauseMethods, resumeMethods: [ResumeMethod.Immediate] });
  };

  const durationComponents = pauseMethods.map((duration, index) => {
    const enableSelectUnit = index === 0;
    //TODO internationalise label and use models for units
    const selectUnit = (
      <Select label={localizeMessage('timePeriod')} onChange={updateDurationUnit} value={duration.unit}>
        <SelectOption label={localizeMessage('Week')} value={PauseDurationUnit.Week} />
        <SelectOption label={localizeMessage('Month')} value={PauseDurationUnit.Month} />
      </Select>
    );
    const unitOrRemove = (
      <div className={classNames(classes.unitOrRemove, classes.flexRow)}>
        <Body>{localizeMessage(duration.unit)}</Body>
        <DeleteIcon onClick={() => deleteOption(index)} nativeColor={colors.action.primary} />
      </div>
    );
    return (
      <div key={index} className={classes.durationBoxes}>
        <div className={classes.durationBox}>
          {/* @ts-ignore it says defaultValue doesn't exist :confused: */}
          <TextInput
            label={localizeMessage('pauseDuration')}
            value={duration?.amount || ''}
            onChange={updateDurationValue(index)}
          />
        </div>
        <div className={classes.durationBox}>{(enableSelectUnit && selectUnit) || unitOrRemove}</div>
      </div>
    );
  });

  return (
    <Page
      appBarText={
        <div className={classes.flexRow}>
          <MinnaIcon nativeColor={colors.base.surface} />
          <Headline5 textColor="alternative">{localizeMessage('title')}</Headline5>
        </div>
      }
      variant="details"
      showMenuForSmallerScreen
      showBackButton={false}
    >
      <div className={classes.pageContent}>
        <header>
          <Headline1>{localizeMessage('header')}</Headline1>
          <Body>{localizeMessage('headerText')}</Body>
        </header>
        <div className={classes.flexRow}>
          <div className={classes.flexColumn}>
            <Card className={classes.card}>
              <Headline3>{localizeMessage('pauseDurationTitle')}</Headline3>
              <div className={classes.introductionMessage}>
                <Body>{localizeMessage('pauseDurationText')}</Body>
              </div>
              <div className={classes.flexColumn}>{durationComponents}</div>
              <div className={classes.button}>
                <Button label={localizeMessage('addOption')} variant="outlined" color="secondary" onClick={addOption} />
              </div>
            </Card>
            <div className={classes.button}>
              <Button
                label={localizeMessage('save')}
                onClick={onClickSaveHandler}
                variant="contained"
                color="positive"
              />
            </div>
          </div>
          <Card className={classes.card}>
            <Headline3>{localizeMessage('pauseExampleTitle')}</Headline3>
            <div className={classes.introductionMessage}>
              <Body>{localizeMessage('pauseExampleText')}</Body>
            </div>
            <img className={classes.illustration} src={ChangePlanIllustration} />
          </Card>
        </div>
      </div>
    </Page>
  );
};
