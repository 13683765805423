import { LocalizedMessage } from '@provider-portal/internationalization';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select/config';
import { EmailInput, UriInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput/config';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { TranslatableFieldWidget } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/config';
import React from 'react';
import { ProviderFormFieldNames } from '../../../config';

export const categoriesSection: IFieldSectionConfig = {
  columns: 3,
  components: [
    UriInput({
      name: ProviderFormFieldNames.TERMS_URL,
      label: <LocalizedMessage id="linkToTerms" />,
    }),
    EmailInput({
      name: ProviderFormFieldNames.ORDER_NOTIFICATION_EMAIL,
      label: <LocalizedMessage id="orderNotificationEmail" />,
    }),
    EmailInput({
      name: ProviderFormFieldNames.ORDER_REGRET_EMAIL,
      label: <LocalizedMessage id="orderRegretEmail" />,
      required: true,
    }),
    Select({
      name: ProviderFormFieldNames.SERVICE_ID,
      label: <LocalizedMessage id="supplierServiceTitle" />,
      required: true,
    }),
    Select({
      name: ProviderFormFieldNames.ORDER_SERVICE_IDENTIFIER,
      label: <LocalizedMessage id="orderServiceIdentifierTitle" />,
    }),
  ],
};

export const categoriesSellingPoints: IFieldSectionConfig = {
  columns: 1,
  components: [
    TranslatableFieldWidget({
      title: <LocalizedMessage id="sellingPoint1" />,
      description: <LocalizedMessage id="sellingPointsDescription" />,
      name: ProviderFormFieldNames.SELLING_POINT_1,
    }),
    TranslatableFieldWidget({
      title: <LocalizedMessage id="sellingPoint2" />,
      description: <LocalizedMessage id="sellingPointsDescription" />,
      name: ProviderFormFieldNames.SELLING_POINT_2,
    }),
    TranslatableFieldWidget({
      title: <LocalizedMessage id="sellingPoint3" />,
      description: <LocalizedMessage id="sellingPointsDescription" />,
      name: ProviderFormFieldNames.SELLING_POINT_3,
    }),
  ],
};
