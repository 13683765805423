import { LocalizedMessage } from '@provider-portal/internationalization';
import type { RejectedReason } from '@provider-portal/models/order';
import { white } from '@provider-portal/styles/colors-standard';
import { TextField } from '@provider-portal/libs/uiFramework/components/deprecated/TextField';
import * as React from 'react';

export interface IMessageToUserInputProps {
  rejectionReason: RejectedReason;
  messageToUser: string;
  onMessageChanged(messageToUser: string): void;
}

export const MessageToUserInput: React.FunctionComponent<IMessageToUserInputProps> = ({
  messageToUser,
  onMessageChanged,
}) => {
  return (
    <TextField
      name="reasonMessage"
      fullWidth
      defaultValue={messageToUser}
      onChange={(e) => onMessageChanged(e.target.value)}
      required
      requiredErrorText={<LocalizedMessage id="rejectionMessageRequired" />}
      label={<LocalizedMessage id="rejectionMessageLabel" />}
      inputProps={{ style: { background: white.main, borderRadius: '8px' } }}
    />
  );
};
