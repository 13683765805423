import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import type { Columns } from '../../fields/configs/columns';
import { getGridColumnsWidth } from '../../fields/configs/columns';

interface IProps {
  components: React.ReactNode[];
  columns?: Columns;
}

export const ComponentsRow: React.FunctionComponent<IProps> = ({ components, columns }) => {
  return (
    <>
      {components.map((component, index) => {
        return (
          <Grid item xs={getGridColumnsWidth(columns || 2)} key={index}>
            {component}
          </Grid>
        );
      })}
    </>
  );
};
