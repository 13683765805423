import type { Locale } from '@provider-portal/internationalization/locale';
import type { ITooltipProps } from '@provider-portal/libs/uiFramework/components/tooltip';
import type { AmountWithCurrency, Currency } from '@provider-portal/models/subscription';
import type { IInputField } from '../../configs';
import { fieldConstructor, FieldType } from '../../configs';
import type { MeasurementUnit } from '../../MeasurementUnit';

export interface ICostInput extends IInputField, ITooltipProps {
  type: FieldType.Cost;
  defaultValue?: AmountWithCurrency;
  unit?: MeasurementUnit;
  currency: Currency;
  locale: Locale;
}

export const CostInput = fieldConstructor<ICostInput>(FieldType.Cost);
