import AccessTime from '@material-ui/icons/AccessTime';
import CancelIcon from '@material-ui/icons/Cancel';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { SaveDeskOfferType } from '@provider-portal/saveDesk/models';
import { colors } from '@provider-portal/styles/colors';
import * as React from 'react';
import styles from './styles.scss';

export interface IProps {
  userName: string;
  state?: SaveDeskOfferType;
  orderSentAt?: string;
}

export const HeaderDescription: React.FunctionComponent<IProps> = ({ state, userName, orderSentAt }) => {
  const icon =
    state === SaveDeskOfferType.SAVE_DESK_OFFER ? (
      <AccessTime style={{ color: colors.grey2 }} />
    ) : (
      <CancelIcon style={{ color: colors.grey2 }} />
    );

  const message =
    state === SaveDeskOfferType.SAVE_DESK_OFFER ? (
      <LocalizedMessage id="offerSentMessage" values={{ date: orderSentAt }} />
    ) : (
      <LocalizedMessage id="orderRejectedMessage" />
    );

  return (
    <>
      {!state && (
        <Body1 className={styles.headerDescription}>
          <LocalizedMessage id="saveDeskOrderPageResponseHeaderText" values={{ userName }} />
        </Body1>
      )}
      {state && (
        <div style={{ padding: '0 16px 16px', display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '8px' }}>{icon}</span>
          <Body1>{message}</Body1>
        </div>
      )}
    </>
  );
};
