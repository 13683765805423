import Fab from '@material-ui/core/Fab';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AddIcon } from '@provider-portal/libs/uiFramework/components/deprecated/AddIcon';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Subtitle2 } from '@provider-portal/libs/uiFramework/components/text/Subtitle2';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { purple, white } from '@provider-portal/styles/colors-standard';
import { parseMoment } from '@provider-portal/utils/date';
import * as React from 'react';
import { Link } from 'react-router-dom';
import type { SupplierPortalUser } from '../models';

export interface UserListComponentProps {
  users: SupplierPortalUser[];
  onEditUser(userId: string): void;
}

const styles = createStyles({
  fab: {
    position: 'fixed',
    bottom: 88,
    right: 32,
    backgroundColor: purple.main,
  },
});

const UserListComponentInner: React.FunctionComponent<UserListComponentProps & WithStyles<typeof styles>> = ({
  users,
  onEditUser,
  classes,
}) => {
  return (
    <Page appBarText={<LocalizedMessage id="usersTitle" />} variant="overview">
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Subtitle2>
                  <LocalizedMessage id="supplier" />
                </Subtitle2>
              </TableCell>
              <TableCell>
                <Subtitle2>
                  <LocalizedMessage id="username" />
                </Subtitle2>
              </TableCell>
              <TableCell>
                <Subtitle2>
                  <LocalizedMessage id="email" />
                </Subtitle2>
              </TableCell>
              <TableCell>
                <Subtitle2>
                  <LocalizedMessage id="name" />
                </Subtitle2>
              </TableCell>
              <TableCell>
                <Subtitle2>
                  <LocalizedMessage id="createdAt" />
                </Subtitle2>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              return (
                <TableRow key={user.id} onClick={() => onEditUser(user.id)} style={{ cursor: 'pointer' }} hover>
                  <TableCell>
                    <Body>{user.providerName}</Body>
                  </TableCell>
                  <TableCell>
                    <Body>{user.username}</Body>
                  </TableCell>
                  <TableCell>
                    <Body>{user.emailAddress}</Body>
                  </TableCell>
                  <TableCell>
                    <Body>{`${user.name.first} ${user.name.last}`}</Body>
                  </TableCell>
                  <TableCell>
                    <Body>{parseMoment(user.createdAt).format('YYYY-MM-DD')}</Body>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <Fab classes={{ root: classes.fab }} component={(props: any) => <Link to="/admin/users/add" {...props} />}>
        <AddIcon htmlColor={white.main} />
      </Fab>
    </Page>
  );
};

export const UserListComponent: React.ComponentType<UserListComponentProps> =
  withStyles(styles)(UserListComponentInner);
