import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { IPartnerProductOverViewModel, IProductFormData, IProductViewModel } from './models';

export async function createPartnerProduct(
  providerId: string,
  productFormData: IProductFormData
): Promise<IProductViewModel> {
  return axios
    .post(`/api/v1/suppliers/${providerId}/products`, productFormData)
    .then((response: AxiosResponse<IProductViewModel>) => response.data);
}

export async function fetchProduct(providerId: string, productId: string): Promise<IProductViewModel> {
  return axios
    .get(`/api/v1/suppliers/${providerId}/products/${productId}`)
    .then((response: AxiosResponse<IProductViewModel>) => response.data);
}

export async function updatePartnerProduct(
  providerId: string,
  productId: string,
  productFormData: IProductFormData
): Promise<IProductViewModel> {
  return axios
    .put(`/api/v1/suppliers/${providerId}/products/${productId}`, productFormData)
    .then((response: AxiosResponse<IProductViewModel>) => response.data);
}

export async function unpublishProduct(providerId: string, productId: string): Promise<IProductViewModel> {
  return axios
    .put(`api/v1/suppliers/${providerId}/products/${productId}/unpublish`)
    .then((response: AxiosResponse<IProductViewModel>) => response.data);
}

export async function archiveProduct(partnerSupplierId: string, partnerProductId: string): Promise<void> {
  return axios.delete(`api/v1/suppliers/${partnerSupplierId}/products/${partnerProductId}`).then(() => void 0);
}

export async function fetchProducts(
  partnerSupplierId: string,
  verticalCategory: string
): Promise<IPartnerProductOverViewModel[]> {
  return axios
    .get(`api/v1/suppliers/${partnerSupplierId}/products/${verticalCategory}`)
    .then((response: AxiosResponse<IPartnerProductOverViewModel[]>) => response.data);
}
