import type { Fetchable } from '@provider-portal/models/Fetchable';
import {
  extractSuccess,
  fetchableHandle,
  FetchableNotStarted,
  FetchableSuccess,
} from '@provider-portal/models/Fetchable';
import {
  activateSupplier,
  createPartnerSupplier,
  createVertical,
  deletePartnerSupplier,
  deleteVertical,
  disableSupplier,
  fetchPartnerSuppliers,
  fetchSupplier,
  updatePartnerSupplier,
  updatePaymentOptions,
  updateSupportChannels,
  updateVertical,
} from '@provider-portal/PartnerSupplier/api';
import type { IncomingPartnerVertical, PartnerSupplier } from '@provider-portal/PartnerSupplier/models';
import { Sentry } from '@provider-portal/sentry';
import type { ApplicationState } from '@provider-portal/store';
import type { ActionsUnion } from '@provider-portal/utils/redux';
import { createAction, createPromiseAction } from '@provider-portal/utils/redux';
import type { PaymentOption, SupportChannel } from '../provider/models';

// Events
enum ActionKeys {
  FETCH_PARTNER_SUPPLIERS = 'PARTNER_SUPPLIER/FETCH_PARTNER_SUPPLIERS',
  FETCH_SUPPLIER = 'PARTNER_SUPPLIER/FETCH_SUPPLIER',
  CREATE_SUPPLIER = 'PARTNER_SUPPLIER/CREATE_SUPPLIER',
  UPDATE_SUPPLIER = 'PARTNER_SUPPLIER/UPDATE_SUPPLIER',
  DELETE_SUPPLIER = 'PARTNER_SUPPLIER/DELETE_SUPPLIER',
  UPDATE_SUPPORT_CHANNELS = 'PARTNER_SUPPLIER/UPDATE_SUPPORT_CHANNELS',
  UPDATE_PAYMENT_OPTIONS = 'PARTNER_SUPPLIER/UPDATE_PAYMENT_OPTIONS',
  UPLOAD_LOGOTYPE = 'PARTNER_SUPPLIER/UPLOAD_LOGOTYPE',
  SET_PARTNER_SUPPLIER = 'PARTNER_SUPPLIER/SET_PARTNER_SUPPLIER',
  CREATE_VERTICAL = 'PARTNER_SUPPLIER/CREATE_VERTICAL',
  UPDATE_VERTICAL = 'PARTNER_SUPPLIER/UPDATE_VERTICAL',
  DELETE_VERTICAL = 'PARTNER_SUPPLIER/DELETE_VERTICAL',
  ACTIVATE_SUPPLIER = 'PARTNER_SUPPLIER/ACTIVATE_SUPPLIER',
  DISABLE_SUPPLIER = 'PARTNER_SUPPLIER/DISABLE_SUPPLIER',
}

// Actions
export const PartnerSupplierActions = {
  fetchPartnerSuppliers: () => createPromiseAction(ActionKeys.FETCH_PARTNER_SUPPLIERS, fetchPartnerSuppliers()),
  fetchSupplier: (partnerSupplierId: string) =>
    createPromiseAction(ActionKeys.FETCH_SUPPLIER, fetchSupplier(partnerSupplierId)),
  createSupplier: (supplier: PartnerSupplier) =>
    createPromiseAction(ActionKeys.CREATE_SUPPLIER, createPartnerSupplier(supplier)),
  updateSupplier: (supplier: PartnerSupplier) =>
    createPromiseAction(ActionKeys.UPDATE_SUPPLIER, updatePartnerSupplier(supplier)),
  deleteSupplier: (supplierId: string) =>
    createPromiseAction(ActionKeys.DELETE_SUPPLIER, deletePartnerSupplier(supplierId)),
  updateSupportChannels: (supportChannels: SupportChannel[], supplierId: string) =>
    createPromiseAction(ActionKeys.UPDATE_SUPPORT_CHANNELS, updateSupportChannels(supportChannels, supplierId)),
  updatePaymentOptions: (paymentOptions: PaymentOption[], supplierId: string) =>
    createPromiseAction(ActionKeys.UPDATE_PAYMENT_OPTIONS, updatePaymentOptions(paymentOptions, supplierId)),
  setPartnerSupplier: (partnerSupplier: PartnerSupplier) =>
    createAction(ActionKeys.SET_PARTNER_SUPPLIER, FetchableSuccess(partnerSupplier)),
  createVertical: (partnerSupplierId: string, incomingVertical: IncomingPartnerVertical) =>
    createPromiseAction(ActionKeys.CREATE_VERTICAL, createVertical(partnerSupplierId, incomingVertical)),
  updateVertical: (partnerSupplierId: string, incomingVertical: IncomingPartnerVertical) =>
    createPromiseAction(ActionKeys.UPDATE_VERTICAL, updateVertical(partnerSupplierId, incomingVertical)),
  deleteVertical: (partnerSupplierId: string, categoryName: string) =>
    createPromiseAction(ActionKeys.DELETE_VERTICAL, deleteVertical(partnerSupplierId, categoryName)),
  activateSupplier: (partnerSupplierId: string) =>
    createPromiseAction(ActionKeys.ACTIVATE_SUPPLIER, activateSupplier(partnerSupplierId)),
  disableSupplier: (partnerSupplierId: string) =>
    createPromiseAction(ActionKeys.DISABLE_SUPPLIER, disableSupplier(partnerSupplierId)),
};

export type Actions = ActionsUnion<typeof PartnerSupplierActions>;

// Reducer
export function reducer(state: PartnerSupplierState = initialState, action: Actions): PartnerSupplierState {
  switch (action.type) {
    case ActionKeys.FETCH_PARTNER_SUPPLIERS:
      return fetchableHandle(state, action, (fetchable) => ({
        ...state,
        partnerSuppliers: fetchable,
        partnerSupplier: FetchableNotStarted,
      }));

    case ActionKeys.FETCH_SUPPLIER:
    case ActionKeys.CREATE_SUPPLIER:
    case ActionKeys.UPDATE_SUPPLIER:
    case ActionKeys.UPDATE_PAYMENT_OPTIONS:
    case ActionKeys.UPDATE_SUPPORT_CHANNELS:
    case ActionKeys.CREATE_VERTICAL:
    case ActionKeys.UPDATE_VERTICAL:
    case ActionKeys.DELETE_VERTICAL:
    case ActionKeys.ACTIVATE_SUPPLIER:
    case ActionKeys.DISABLE_SUPPLIER:
      return fetchableHandle(state, action, (fetchable) => ({ ...state, partnerSupplier: fetchable }));

    case ActionKeys.SET_PARTNER_SUPPLIER:
      return { ...state, partnerSupplier: action.payload };

    default:
      return state;
  }
}

// Selectors
export const selectorFetchablePartnerSuppliers = (state: ApplicationState) => state.partnerSupplier.partnerSuppliers;
export const selectorFetchablePartnerSupplier = (state: ApplicationState) => state.partnerSupplier.partnerSupplier;
export const selectorMaybePartnerSupplier = (state: ApplicationState) =>
  extractSuccess(state.partnerSupplier.partnerSupplier);
export const selectorMarketFromMaybePartnerSupplier = (state: ApplicationState) => {
  const supplier = extractSuccess(state.partnerSupplier.partnerSupplier);
  if (!supplier) {
    const e = new Error('No PartnerSupplier exists to extract Market from');
    Sentry.captureException(e);
    throw e;
  }
  return supplier.market;
};

export interface PartnerSupplierState {
  partnerSuppliers: Fetchable<PartnerSupplier[]>;
  partnerSupplier: Fetchable<PartnerSupplier>;
}

export const initialState: PartnerSupplierState = {
  partnerSuppliers: FetchableNotStarted,
  partnerSupplier: FetchableNotStarted,
};
