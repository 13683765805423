import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { ChangePlanListOffersPage } from './ChangePlanListOffersPage';
import { AddChangePlanPlan } from './AddPlanPage';
import { EditChangePlanOffer } from './EditChangePlanOffer';

export const ChangePlanRoutes: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/offers/add`}
        render={(props) => (
          <AddChangePlanPlan
            // @ts-ignore
            providerId={props.match.params.providerId}
          />
        )}
      />
      <Route
        path={`${match.path}/offers/:changePlanOfferId`}
        render={(props) => (
          <EditChangePlanOffer
            // @ts-ignore
            providerId={props.match.params.providerId}
            changePlanOfferId={props.match.params.changePlanOfferId}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/offers`}
        render={(props) => (
          <ChangePlanListOffersPage
            // @ts-ignore
            partnerSupplierId={props.match.params.providerId}
          />
        )}
      />
    </Switch>
  );
};
