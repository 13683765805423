import { LocalizedMessage } from '@provider-portal/internationalization';
import * as React from 'react';

export enum ProductSnackbarState {
  CLOSED = 'closed',
  PRODUCT_UPDATED = 'updated',
  PRODUCT_UNPUBLISHED = 'unpublished',
}

export function snackbarContent(snackbarState: ProductSnackbarState) {
  switch (snackbarState) {
    case ProductSnackbarState.PRODUCT_UPDATED:
      return <LocalizedMessage id="productUpdated" />;
    case ProductSnackbarState.PRODUCT_UNPUBLISHED:
      return <LocalizedMessage id="productUnpublished" />;
    default:
      return null;
  }
}
