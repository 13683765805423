import { purple, white } from '@provider-portal/styles/colors-standard';
import Fab from '@material-ui/core/Fab';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { AddIcon } from '@provider-portal/libs/uiFramework/components/deprecated/AddIcon';
import * as React from 'react';

const styles = () =>
  createStyles({
    fab: {
      position: 'absolute',
      bottom: 88,
      right: 32,
      backgroundColor: purple.main,
    },
  });

type IAddButtonProps = { onClick(): void } & WithStyles<typeof styles>;

const AddButtonInner: React.FunctionComponent<IAddButtonProps> = ({ onClick, classes }) => {
  return (
    <Fab classes={{ root: classes.fab }} onClick={onClick}>
      <AddIcon htmlColor={white.main} />
    </Fab>
  );
};

export const AddButton = withStyles(styles)(AddButtonInner);
