import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';

export const NotCheckedIcon: React.FunctionComponent = () => {
  return (
    <SvgIcon height={24} width={24}>
      <path
        d="M17.0273 6.96964C17.3202 7.26252 17.3202 7.73739 17.0273 8.0303L13.0582 11.9999L17.0283 15.9696C17.3212 16.2625 17.3212 16.7374 17.0284 17.0303C16.7355 17.3232 16.2606 17.3232 15.9677 17.0304L11.9976 13.0606L8.02835 17.0303C7.73547 17.3232 7.26059 17.3232 6.96769 17.0304C6.67478 16.7375 6.67475 16.2626 6.96763 15.9697L10.9368 12L6.96671 8.03036C6.6738 7.73748 6.67377 7.26261 6.96665 6.9697C7.25953 6.67679 7.7344 6.67676 8.02731 6.96964L11.9974 10.9393L15.9666 6.9697C16.2595 6.67679 16.7344 6.67676 17.0273 6.96964Z"
        fill="#4A4B4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.00201416 12C-0.00201416 5.37258 5.37057 0 11.998 0C18.6254 0 23.998 5.37258 23.998 12C23.998 18.6274 18.6254 24 11.998 24C5.37057 24 -0.00201416 18.6274 -0.00201416 12ZM11.998 1.5C6.199 1.5 1.49799 6.20101 1.49799 12C1.49799 17.799 6.199 22.5 11.998 22.5C17.797 22.5 22.498 17.799 22.498 12C22.498 6.20101 17.797 1.5 11.998 1.5Z"
        fill="#4A4B4B"
      />
    </SvgIcon>
  );
};
