import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import { TableChip } from '@provider-portal/libs/uiFramework/components/Table/TableChip';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import moment from 'moment';
import React from 'react';
import type { IChangeOrderState } from '../models';
import { ChangeOrderState } from '../models';
import { renderAssigned } from '@provider-portal/utils/orderOverviewUtils';

export enum ChangeTab {
  Pending = 'pending',
  Processed = 'processed',
}

export const ordersTableConfig: ITableConfig = {
  cells: [
    {
      key: 'orderType',
      label: <LocalizedMessage id="orderType" />,
    },
    {
      key: 'customerId',
      label: <LocalizedMessage id="customerId" />,
    },
    {
      key: 'productName',
      label: <LocalizedMessage id="productName" />,
    },
    {
      key: 'createdAt',
      label: <LocalizedMessage id="received" />,
      renderCellValue(dateCompleted: Date) {
        return <Body1>{moment(dateCompleted).format('DD MMM YYYY')}</Body1>;
      },
    },
    {
      key: 'state',
      label: <LocalizedMessage id="status" />,
      renderCellValue: renderChangeOrderState,
      sort: 'state.stateType',
    },
    {
      key: 'assignee',
      label: <LocalizedMessage id="assignee" />,
      renderCellValue: renderAssigned,
      sort: 'assignee',
    },
  ],
  defaultSortColumn: 'createdAt',
};

export function renderChangeOrderState(state: IChangeOrderState) {
  switch (state.stateType) {
    case ChangeOrderState.New:
      return <TableChip label="New" color="information" />;
    case ChangeOrderState.WaitingForUserInput:
      return <TableChip label="Waiting for customer" color="aquamarine" />;
    case ChangeOrderState.AcceptedByUser:
      return <TableChip label="Accepted by customer" color="positive" />;
    case ChangeOrderState.RejectedByUser:
      return <TableChip label="Rejected by customer" color="grey" />;
    case ChangeOrderState.RejectedByProvider:
      return <TableChip label="Rejected by provider" color="grey" />;
    case ChangeOrderState.Success:
      return <TableChip label="Success" color="positive" />;
  }
}
