import type { Market } from '@provider-portal/app-context/constants';
import { mapConfigsToFieldsOrWidgets } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput';
import { FieldSection } from '@provider-portal/libs/uiFramework/components/sections/FieldSection';
import type { IProviderCategoryFormData, ISupplierService } from '@provider-portal/provider/models';
import { CategoryDetailsFormDataType } from '@provider-portal/provider/models';
import { verticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';
import * as React from 'react';
import { ProviderFormFieldNames } from '../../config';
import { CategoryLabel } from './CategoryLabel';
import { categoriesSection, categoriesSellingPoints } from './config';
import { mapSectionFieldConfig } from './map-field-config';
import { mapSectionWidgetConfig } from '@provider-portal/provider/ProviderForm/CategoriesPaper/CategorySubForm/map-widget-config';
import type { CategoryName } from '@provider-portal/constants/categories';
import { uiFramework } from '@provider-portal/libs/uiFramework';

interface ICategorySubFormProps {
  index: number;
  categoryName: CategoryName;
  categoryFormData: IProviderCategoryFormData;
  onCategoryChanged(categoryName: string, formData: IProviderCategoryFormData): void;
  market: Market;
  supplierServices: Map<string, ISupplierService[]>;
  orderServices: string[];
}

export const CategorySubForm: React.FunctionComponent<ICategorySubFormProps> = ({
  categoryName,
  categoryFormData,
  onCategoryChanged,
  index,
  market,
  supplierServices,
  orderServices,
}) => {
  const marketSpecificSection = uiFramework.layouts.marketCategorySectionLayouts.get(
    verticalMarketKey(categoryName, market)
  );
  const detailsType = uiFramework.details.categoryDetailsTypes.get(verticalMarketKey(categoryName, market));

  return (
    <div>
      <HiddenInput
        name={`${ProviderFormFieldNames.CATEGORIES}[${index}].${ProviderFormFieldNames.CATEGORY_NAME}`}
        value={categoryName}
      />
      <HiddenInput
        name={`${ProviderFormFieldNames.CATEGORIES}[${index}].${ProviderFormFieldNames.DETAILS_TYPE}`}
        value={detailsType || CategoryDetailsFormDataType.EmptyCategoryDetailsFormData}
      />
      <FieldSection
        caption={<CategoryLabel categoryName={categoryName} />}
        columns={categoriesSection.columns}
        components={mapConfigsToFieldsOrWidgets(
          categoriesSection.components,
          mapSectionFieldConfig(
            index,
            categoryName,
            categoryFormData,
            onCategoryChanged,
            supplierServices,
            orderServices
          ),
          mapSectionWidgetConfig(index, categoryFormData, market)
        )}
      />
      <FieldSection
        columns={categoriesSellingPoints.columns}
        components={mapConfigsToFieldsOrWidgets(
          categoriesSellingPoints.components,
          mapSectionFieldConfig(
            index,
            categoryName,
            categoryFormData,
            onCategoryChanged,
            supplierServices,
            orderServices
          ),
          mapSectionWidgetConfig(index, categoryFormData, market)
        )}
      />
      {marketSpecificSection && (
        <FieldSection
          columns={marketSpecificSection.columns}
          components={mapConfigsToFieldsOrWidgets(
            marketSpecificSection.components,
            mapSectionFieldConfig(
              index,
              categoryName,
              categoryFormData,
              onCategoryChanged,
              supplierServices,
              orderServices
            ),
            mapSectionWidgetConfig(index, categoryFormData, market)
          )}
        />
      )}
    </div>
  );
};
