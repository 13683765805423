import { LocalizedMessage } from '@provider-portal/internationalization';
import * as React from 'react';

export enum SaveDeskOfferSnackbarState {
  CLOSED = 'closed',
  OFFER_UPDATED = 'updated',
  OFFER_UNPUBLISHED = 'unpublished',
}

export function snackbarContent(snackbarState: SaveDeskOfferSnackbarState) {
  switch (snackbarState) {
    case SaveDeskOfferSnackbarState.OFFER_UPDATED:
      return <LocalizedMessage id="saveDeskOfferUpdated" />;
    case SaveDeskOfferSnackbarState.OFFER_UNPUBLISHED:
      return <LocalizedMessage id="saveDeskOfferUnpublished" />;
    default:
      return null;
  }
}
