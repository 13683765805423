import { primary } from '@provider-portal/styles/colors-standard';
import * as React from 'react';
import CricketImage from './images/cricket.svg';

interface IStyles {
  container: React.CSSProperties;
  image: React.CSSProperties;
}

const styles: IStyles = {
  container: {
    border: `2px dashed ${primary.main}`,
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '74px 0px',
    textAlign: 'center',
  },
  image: {
    marginBottom: '24px',
  },
};

interface Props {
  children?: React.ReactNode;
}

export const ContentCricket: React.FunctionComponent<Props> = ({ children }) => (
  <div style={styles.container}>
    <img src={CricketImage} style={styles.image} role="presentation" alt="" />
    {children}
  </div>
);
