import { ErrorLoading } from '@provider-portal/fetching/ErrorPage/ErrorLoading';
import React from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName } from 'recompose';
import { ReactReduxContext } from 'react-redux';
import { conditionalRender } from '@provider-portal/fetching/conditionalRender';

export const errorPageOnFailure = (hasFailed: any) => {
  // @ts-ignore
  return conditionalRender(hasFailed, ErrorLoading);
};

/**
 * @deprecated `useEffect` and `useDispatch` can be used instead
 */
export const dispatchBeforeConnect = (onDispatchBeforeFunc: any) => (WrappedComponent: any) => {
  // eslint-disable-next-line react/no-unsafe
  class OnDispatchBeforeConnect extends React.Component {
    public static displayName = wrapDisplayName(WrappedComponent, 'OnDispatchBeforeConnect');

    public static propTypes = {
      __store: PropTypes.object,
    };

    public UNSAFE_componentWillMount() {
      //@ts-ignore
      const { __store, ...rest } = this.props;
      onDispatchBeforeFunc(__store.getState(), __store.dispatch, rest);
    }

    public render() {
      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __store, ...rest } = this.props;
      return <WrappedComponent {...rest} />;
    }
  }

  return function withReduxStore(props: any) {
    return (
      <ReactReduxContext.Consumer>
        {({ store }) => <OnDispatchBeforeConnect {...props} __store={store} />}
      </ReactReduxContext.Consumer>
    );
  };
};
