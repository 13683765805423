import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { fetchSwitchOrders } from '../api';
import type { ISwitchOrder } from '../models';

interface IReturnValue {
  loadingState: PortalLoadingState;
  switchOrders: ISwitchOrder[];
}

export function useFetchOrdersOnEnter(partnerSupplierId: string, categoryName: string): IReturnValue {
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);
  const [switchOrders, setSwitchOrders] = React.useState<ISwitchOrder[]>([]);

  React.useEffect(() => {
    fetchSwitchOrders(partnerSupplierId, categoryName)
      .then((orders) => {
        setSwitchOrders(orders);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
  }, [partnerSupplierId, categoryName]);

  return { loadingState, switchOrders };
}
