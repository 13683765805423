import type { EnrichMap, EnrichWithFormData, IEnrichProps } from '@provider-portal/libs/uiFramework';
import get from 'lodash/get';
import { WidgetType } from '../config';

export enum SwedenPhoneCallsLimitationType {
  LIMITED_TIME_IN_MINUTES = 'LimitedTimeInMinutes',
  LIMITED_AMOUNT_OF_CALLS = 'LimitedAmountOfCalls',
  UNLIMITED_CALLS = 'UnlimitedCalls',
}

export interface ISwedenPhoneCallsLimitationInputWidget {
  type: WidgetType.SWEDEN_PHONE_CALLS_LIMITATION_INPUT;
  limitationType?: SwedenPhoneCallsLimitationType;
  limitedTimeInMinutes?: number;
  limitedAmountOfCalls?: number;
}

export enum SwedenPhoneCallsLimitationFieldNames {
  LIMITATION_TYPE = 'phoneCallsLimitation.name',
  LIMITED_TIME_IN_MINUTES = 'phoneCallsLimitation.minutes',
  LIMITED_AMOUNT_OF_CALLS = 'phoneCallsLimitation.amount',
}

const swedenPhoneCallsLimitationInputWidgetEnricher: EnrichWithFormData = (props: IEnrichProps<any>) => {
  return {
    ...props.config,
    limitationType: get(props.formData, SwedenPhoneCallsLimitationFieldNames.LIMITATION_TYPE),
    limitedAmountOfCalls: get(props.formData, SwedenPhoneCallsLimitationFieldNames.LIMITED_AMOUNT_OF_CALLS),
    limitedTimeInMinutes: get(props.formData, SwedenPhoneCallsLimitationFieldNames.LIMITED_TIME_IN_MINUTES),
  };
};

export const swedenPhoneCallsLimitationInputWidgetEnricherMap: EnrichMap = [
  WidgetType.SWEDEN_PHONE_CALLS_LIMITATION_INPUT,
  swedenPhoneCallsLimitationInputWidgetEnricher,
];
