import type { Market } from '@provider-portal/app-context/constants';
import { withLocalization } from '@provider-portal/internationalization';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import type { ISwitchOrderDetails } from '../models';
import { SwitchOrderDetailsComponent } from './component';
import { useFetchSwitchOrder } from './useFetchSwitchOrder';
import { useUpdateSwitchOrder } from './useUpdateSwitchOrder';

export interface ISwitchOrderDetailsPageProps {
  partnerSupplierId: string;
  orderId: string;
  market: Market;
}

export const SwitchOrderDetailsPageInner: React.FunctionComponent<ISwitchOrderDetailsPageProps> = ({
  partnerSupplierId,
  orderId,
  market,
}) => {
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);
  const [switchOrderDetails, setSwitchOrderDetails] = React.useState<ISwitchOrderDetails>();
  useFetchSwitchOrder(partnerSupplierId, orderId, setLoadingState, setSwitchOrderDetails);
  const { onUpdateOrderOutcome, updateInProgress } = useUpdateSwitchOrder(
    partnerSupplierId,
    orderId,
    setSwitchOrderDetails,
    setLoadingState
  );

  return (
    <LoadingPage loadingState={loadingState}>
      {switchOrderDetails && (
        <SwitchOrderDetailsComponent
          providerId={partnerSupplierId}
          orderId={orderId}
          updateInProgress={updateInProgress}
          onUpdateOrderOutcome={onUpdateOrderOutcome}
          switchOrderDetails={switchOrderDetails}
          market={market}
        />
      )}
    </LoadingPage>
  );
};

export const SwitchOrderDetailsPage: React.ComponentType<ISwitchOrderDetailsPageProps> =
  withLocalization('SupplierPortal/orders')(SwitchOrderDetailsPageInner);
