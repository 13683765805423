import { RejectedReason } from '@provider-portal/models/order';
import { TableChip } from '@provider-portal/libs/uiFramework/components/Table/TableChip';
import * as React from 'react';
import type { ISwitchOrderState } from './models';
import { OrderType, SwitchOrderState } from './models';

function readableRejectedReason(rejectedReason: RejectedReason) {
  switch (rejectedReason) {
    case RejectedReason.AlreadyCustomer:
      return ' - Already customer';
    case RejectedReason.CreditCheckFailed:
      return ' - Credit check';
    case RejectedReason.HasBindingPeriod:
      return ' - Binding period';
    case RejectedReason.UnknownAddress:
      return ' - Unknown address';
    case RejectedReason.Unknown:
      return '';
  }
}

export function orderStateReadableDisplayName(orderState: ISwitchOrderState) {
  switch (orderState.name) {
    case SwitchOrderState.New:
      return 'New';
    case SwitchOrderState.Rejected:
      if (!orderState.reason) {
        throw new Error('No rejected reason supplied in switch orders list.');
      }

      return `Rejected${readableRejectedReason(orderState.reason.name)}`;
    case SwitchOrderState.Accepted:
      return 'Accepted';
  }
}

export function renderOrderState(state: ISwitchOrderState) {
  const orderStateDisplayName = orderStateReadableDisplayName(state);

  switch (state.name) {
    case SwitchOrderState.New:
      return <TableChip label={orderStateDisplayName} color="information" />;
    case SwitchOrderState.Rejected:
      if (!state.reason) {
        throw new Error('No rejected reason supplied in switch orders list.');
      }

      return <TableChip label={orderStateDisplayName} color="grey" />;
    case SwitchOrderState.Accepted:
      return <TableChip label={orderStateDisplayName} color="positive" />;
  }
}

export function isClosedOrderState(orderState: ISwitchOrderState): boolean {
  return [SwitchOrderState.Rejected, SwitchOrderState.Accepted].includes(orderState.name);
}

export const getOrderType = (orderType: OrderType): string => {
  switch (orderType) {
    case OrderType.BroadbandSwitch:
    case OrderType.ElectricitySwitch:
    case OrderType.GasSwitch:
    case OrderType.ElectricityAndGasSwitch:
    case OrderType.MobileSwitch:
    case OrderType.FitnessSwitch:
      return 'switch';
    case OrderType.BroadbandIndependent:
    case OrderType.MobileIndependent:
    case OrderType.FitnessIndependent:
    case OrderType.CharityIndependent:
    case OrderType.GameIndependent:
    case OrderType.MembershipIndependent:
    case OrderType.GroceryBagIndependent:
    case OrderType.MobileAppIndependent:
    case OrderType.NewsIndependent:
    case OrderType.SubscriptionBoxIndependent:
    case OrderType.TvIndependent:
    case OrderType.StreamingMediaIndependent:
      return 'independent';
    case OrderType.MobileChangeDataPlan:
      return 'changeDataPlan';
  }

  throw new Error(`No orderType match found for ${orderType}`);
};
