import { KeyboardArrowLeftIcon } from '@provider-portal/libs/uiFramework/components/deprecated/KeyboardArrowLeftIcon';
import { KeyboardArrowRightIcon } from '@provider-portal/libs/uiFramework/components/deprecated/KeyboardArrowRightIcon';
import MomentUtils from '@date-io/moment';
import type { WithStyles } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { default as MUIDatePicker } from 'material-ui-pickers/DatePicker';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import * as React from 'react';
import { compose } from 'recompose';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';

const styles = () =>
  createStyles({
    root: { borderRadius: '8px' },
  });

export interface DatePickerFormatProps {
  format: string;
  mask: (string | RegExp)[] | ((value: string) => (string | RegExp)[]);
  placeholder: string;
}

export interface DatePickerProps {
  label: React.ReactNode;
  value: Date;
  errorHelpText?: string;
  minDate?: Date;
  maxDate?: Date;
  onChange?(event: any): void;
  onBlur?(event: any): void;
  onClose?(): void;
  onOpen?(): void;
}

export interface DatePickerOuterProps extends DatePickerProps {
  defaultValue?: Date;
}

const normalDateFormat = 'DD/MM/YYYY';
const dateFormat: DatePickerFormatProps = {
  format: normalDateFormat,
  mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  placeholder: normalDateFormat,
};

const DatePickerComponent: React.FunctionComponent<DatePickerProps & WithStyles<typeof styles>> = ({
  classes,
  label,
  value,
  errorHelpText,
  minDate,
  maxDate,
  onBlur,
  onChange,
}) => {
  const error: any = errorHelpText ? { error: true, helperText: errorHelpText } : { error: false };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MUIDatePicker
        data-testid="datePicker-root"
        onBlur={handleBlur}
        style={{
          borderRadius: '8px',
        }}
        variant="outlined"
        autoOk
        format={dateFormat.format}
        fullWidth
        clearable
        disabled={false}
        InputLabelProps={{ shrink: true, classes }}
        label={label}
        placeholder={dateFormat.placeholder}
        value={value}
        onChange={handleChange}
        leftArrowIcon={<KeyboardArrowLeftIcon />}
        rightArrowIcon={<KeyboardArrowRightIcon />}
        cancelLabel={<LocalizedMessage id="cancel" />}
        okLabel={<LocalizedMessage id="ok" />}
        maxDate={maxDate}
        minDate={minDate}
        {...error}
      />
    </MuiPickersUtilsProvider>
  );

  function handleBlur(event: any) {
    const res = { target: { value: event.target && event.target.value ? new Date(event.target.value) : undefined } };
    if (onBlur) {
      onBlur(res);
    }
    if (onChange) {
      onChange(res);
    }
  }

  function handleChange(date: any) {
    if (onChange) {
      onChange({ target: { value: date ? date.toDate() : undefined } });
    }
  }
};

export const DatePicker = compose<DatePickerProps & WithStyles<typeof styles>, DatePickerOuterProps>(
  withStyles(styles),
  withLocalization('SupplierPortal/fields')
)(DatePickerComponent);

DatePicker.displayName = 'DatePicker';
