import React from 'react';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { default as MuiMenu } from '@material-ui/core/Menu';
import { default as MuiMenuItem } from '@material-ui/core/MenuItem';
import { default as MuiCheckbox } from '@material-ui/core/Checkbox';
import { default as MuiFormControlLabel } from '@material-ui/core/FormControlLabel';
import { SwitchOrderState } from '@provider-portal/orders/switch/models';
import styles from './styles.scss';
import { colors } from '@provider-portal/styles/colors';
import { FilterIcon } from '@provider-portal/assets/icons/FilterIcon';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';

interface IFiltersButton {
  disabled: boolean;
  filterIsChangedFromDefault: boolean;
  selectedOrderStates: SwitchOrderState[];
  onSelectedOrderStatesChange(orderStates: SwitchOrderState[]): void;
}

export const FiltersButton: React.FunctionComponent<IFiltersButton> = ({
  disabled,
  filterIsChangedFromDefault,
  selectedOrderStates,
  onSelectedOrderStatesChange,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const filtersButtonRef = React.useRef(null);

  const stateOptions = [
    { label: <LocalizedMessage id="switchOrderStateNew" />, value: SwitchOrderState.New },
    { label: <LocalizedMessage id="switchOrderStateAccepted" />, value: SwitchOrderState.Accepted },
    { label: <LocalizedMessage id="switchOrderStateRejected" />, value: SwitchOrderState.Rejected },
  ];
  const amountOfOrderStatusesChecked = selectedOrderStates.length;
  const filterChangedIndicatorText = filterIsChangedFromDefault ? (
    <span style={{ marginLeft: '4px' }}>({amountOfOrderStatusesChecked})</span>
  ) : (
    ''
  );

  const buttonLabel = (
    <span className={styles.filtersButtonLabel}>
      <FilterIcon nativeColor={colors.primaryDark} className={styles.filtersButtonFilterIcon} />
      <LocalizedMessage id="filtersButtonLabel" /> {filterChangedIndicatorText}
    </span>
  );

  return (
    <div className={styles.filtersButtonWrapper} ref={filtersButtonRef}>
      <Button color="secondary" variant="outlined" onClick={openMenu} label={buttonLabel} disabled={disabled} />
      <MuiMenu
        open={menuOpen}
        onClose={closeMenu}
        anchorEl={filtersButtonRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Overline
          className={styles.removeFocusOutline}
          style={{
            margin: '12px 12px 4px 12px',
          }}
        >
          <LocalizedMessage id="status" />
        </Overline>
        {stateOptions.map((option) => (
          <MuiMenuItem key={option.value}>
            <MuiFormControlLabel
              checked={selectedOrderStates.includes(option.value)}
              onChange={() => toggleOrderState(option.value)}
              control={<MuiCheckbox />}
              label={option.label}
              labelPlacement="end"
            />
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </div>
  );

  function toggleOrderState(state: SwitchOrderState) {
    const checked = selectedOrderStates.includes(state);
    const newSelectedOrderStates: SwitchOrderState[] = checked
      ? selectedOrderStates.filter((value) => value !== state)
      : Array.from(new Set([...selectedOrderStates, state]));

    onSelectedOrderStatesChange(newSelectedOrderStates);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function openMenu() {
    setMenuOpen(true);
  }
};
