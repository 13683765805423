import { LocalizedMessage } from '@provider-portal/internationalization';
import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import type { ITabConfig } from '@provider-portal/libs/uiFramework/components/Tabs/config';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { selectorClosedSaveDeskOrders, selectorSaveDeskOrdersToBeProcessed } from '../duck';
import type { ISaveDeskOrder } from '../models';
import { saveDeskClosedOrdersTableConfig, SaveDeskTab, saveDeskToBeProcessedTableConfig } from './config';

export interface IStateProps {
  tabsConfig: ITabConfig<SaveDeskTab>[];
  saveDeskOrdersToBeProcessed: ISaveDeskOrder[];
  closedSaveDeskOrders: ISaveDeskOrder[];
}

export function useMapStateToProps(): IStateProps {
  const saveDeskOrdersToBeProcessed = useSelector(selectorSaveDeskOrdersToBeProcessed);
  const closedSaveDeskOrders = useSelector(selectorClosedSaveDeskOrders);
  const tabsConfig: ITabConfig<SaveDeskTab>[] = [
    {
      key: SaveDeskTab.Pending,
      label: <LocalizedMessage id="pendingOrdersTabTitle" values={{ count: saveDeskOrdersToBeProcessed.length }} />,
    },
    {
      key: SaveDeskTab.Processed,
      label: <LocalizedMessage id="processedOrdersTabTitle" values={{ count: closedSaveDeskOrders.length }} />,
    },
  ];

  return {
    tabsConfig,
    saveDeskOrdersToBeProcessed,
    closedSaveDeskOrders,
  };
}

export interface IMapProps {
  tableData: ISaveDeskOrder[];
  tableConfig: ITableConfig;
  onSaveDeskOrderClick(saveDeskOrder: ISaveDeskOrder): void;
}

export function useMapProps(
  { saveDeskOrdersToBeProcessed, closedSaveDeskOrders }: IStateProps,
  selectedTab: SaveDeskTab
): IMapProps {
  const history = useHistory();
  const [tableData, tableConfig] = ((): [ISaveDeskOrder[], ITableConfig] => {
    if (selectedTab === SaveDeskTab.Pending) {
      return [saveDeskOrdersToBeProcessed, saveDeskToBeProcessedTableConfig];
    } else {
      return [closedSaveDeskOrders, saveDeskClosedOrdersTableConfig];
    }
  })();

  return {
    tableData,
    tableConfig,
    onSaveDeskOrderClick(saveDeskOrder: ISaveDeskOrder) {
      const saveDeskOrderDetailsUrl = URI(history.location.pathname).segment(saveDeskOrder.id).toString();
      history.push(saveDeskOrderDetailsUrl);
    },
  };
}
