import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { HiddenInput } from '../../fields/HiddenInput';
import { NumberInput, NumberType } from '../../fields/NumberInput';
import { RadioInput } from '../../fields/RadioInput';
import type { ISwedenPhoneCallsLimitationInputWidget } from './config';
import { SwedenPhoneCallsLimitationFieldNames, SwedenPhoneCallsLimitationType } from './config';
import styles from './styles.scss';
import { Locale } from '@provider-portal/internationalization/locale';

type ISwedenPhoneCallsLimitationInputProps = Omit<ISwedenPhoneCallsLimitationInputWidget, 'type'>;

const SwedenPhoneCallsLimitationInputInner: React.FunctionComponent<ISwedenPhoneCallsLimitationInputProps> = ({
  limitationType,
  limitedTimeInMinutes,
  limitedAmountOfCalls,
}) => {
  const [activeLimitationType, setActiveLimitationType] = React.useState(
    limitationType || SwedenPhoneCallsLimitationType.UNLIMITED_CALLS
  );
  const locale = Locale.svSE;

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <RadioInput
          label={<LocalizedMessage id="minutesOfCallPerMonthLabel" />}
          checked={activeLimitationType === SwedenPhoneCallsLimitationType.LIMITED_TIME_IN_MINUTES}
          onChange={() => setActiveLimitationType(SwedenPhoneCallsLimitationType.LIMITED_TIME_IN_MINUTES)}
        />
        <div className={styles.paddingLeft}>
          <NumberInput
            name={SwedenPhoneCallsLimitationFieldNames.LIMITED_TIME_IN_MINUTES}
            defaultValue={limitedTimeInMinutes?.toString()}
            label={<LocalizedMessage id="minutesOfCallPerMonthInputLabel" />}
            disabled={activeLimitationType !== SwedenPhoneCallsLimitationType.LIMITED_TIME_IN_MINUTES}
            required={activeLimitationType === SwedenPhoneCallsLimitationType.LIMITED_TIME_IN_MINUTES}
            numberType={NumberType.PositiveInteger}
            locale={locale}
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <RadioInput
          label={<LocalizedMessage id="callsPerMonthLabel" />}
          checked={activeLimitationType === SwedenPhoneCallsLimitationType.LIMITED_AMOUNT_OF_CALLS}
          onChange={() => setActiveLimitationType(SwedenPhoneCallsLimitationType.LIMITED_AMOUNT_OF_CALLS)}
        />
        <div className={styles.paddingLeft}>
          <NumberInput
            name={SwedenPhoneCallsLimitationFieldNames.LIMITED_AMOUNT_OF_CALLS}
            defaultValue={limitedAmountOfCalls?.toString()}
            label={<LocalizedMessage id="callsPerMonthInputLabel" />}
            disabled={activeLimitationType !== SwedenPhoneCallsLimitationType.LIMITED_AMOUNT_OF_CALLS}
            required={activeLimitationType === SwedenPhoneCallsLimitationType.LIMITED_AMOUNT_OF_CALLS}
            numberType={NumberType.PositiveInteger}
            locale={locale}
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <HiddenInput name={SwedenPhoneCallsLimitationFieldNames.LIMITATION_TYPE} value={activeLimitationType} />
        <RadioInput
          label={<LocalizedMessage id="unlimitedCalls" />}
          checked={activeLimitationType === SwedenPhoneCallsLimitationType.UNLIMITED_CALLS}
          onChange={() => setActiveLimitationType(SwedenPhoneCallsLimitationType.UNLIMITED_CALLS)}
        />
      </Grid>
    </Grid>
  );
};

export const SwedenPhoneCallsLimitationInput = withLocalization('SwedenPhoneCallsLimitationInput')(
  SwedenPhoneCallsLimitationInputInner
);
