import { Section } from '@provider-portal/libs/uiFramework/components/sections/Section';
import * as React from 'react';
import { FieldRows } from '../../fields/FieldRows';
import type { IFieldSectionConfig } from './fieldSectionConfig';

export const FieldSection: React.FunctionComponent<IFieldSectionConfig> = ({
  caption,
  captionText,
  tooltiplinkText,
  tooltiplinkUrl,
  tooltipTitle,
  tooltipText,
  components,
  columns,
  sectionNumber,
  divider,
}) => {
  return (
    <Section
      captionText={captionText}
      tooltipTitle={tooltipTitle}
      tooltipText={tooltipText}
      tooltiplinkText={tooltiplinkText}
      tooltiplinkUrl={tooltiplinkUrl}
      caption={caption}
      sectionNumber={sectionNumber}
      divider={divider}
    >
      <FieldRows fields={components} columns={columns} />
    </Section>
  );
};
