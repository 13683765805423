import set from 'lodash/set';
import { HiddenInputDataFormat } from '../HiddenInput/config';
import { NumberType } from '../NumberInput';
import { AttributeNames } from './';

function isInput(element: Element): element is HTMLInputElement {
  return element.tagName === 'INPUT';
}

function isSelect(element: Element): element is HTMLSelectElement {
  return element.tagName === 'SELECT';
}

function isTextArea(element: Element): element is HTMLTextAreaElement {
  return element.tagName === 'TEXTAREA';
}

export function parseForm(form: HTMLFormElement): any {
  const parsedFormData: any = {};
  Array.from(form.elements).forEach((element) => {
    if (isInput(element) && element.name) {
      switch (element.type) {
        case 'hidden':
          set(parsedFormData, element.name, parseHiddenInput(element));
          break;
        case 'checkbox':
          set(parsedFormData, element.name, element.checked);
          break;
        case 'file':
          set(parsedFormData, element.name, parseFileInput(element));
          break;
        case 'radio':
          if (element.checked) {
            set(parsedFormData, element.name, parseInput(element));
          }
          break;
        default: {
          const numberType = element.getAttribute(AttributeNames.DATA_FORMAT);
          if (numberType) {
            set(parsedFormData, element.name, parseNumberInput(element, numberType as NumberType));
          } else {
            set(parsedFormData, element.name, parseInput(element));
          }
        }
      }
    } else if (isSelect(element) && element.name) {
      set(parsedFormData, element.name, parseInput(element));
    } else if (isTextArea(element) && element.name) {
      set(parsedFormData, element.name, parseInput(element));
    }
  });

  return parsedFormData;
}

function parseInput(element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): string | undefined {
  const trimmedValue = element.value.trim();
  if (trimmedValue) {
    return trimmedValue;
  } else {
    return undefined;
  }
}

function parseNumberInput(element: HTMLInputElement, numberType: NumberType): number | undefined {
  const trimmedValue = element.value.replace(',', '.').trim();
  if (trimmedValue) {
    switch (numberType) {
      case NumberType.Float:
      case NumberType.FloatZeroOrGreater:
      case NumberType.PositiveFloat:
        return parseFloat(trimmedValue);
      case NumberType.Integer:
      case NumberType.IntegerZeroOrGreater:
      case NumberType.PositiveInteger:
        return parseInt(trimmedValue, 10);
      default:
        throw new Error('Number type not defined');
    }
  } else {
    return undefined;
  }
}

function parseFileInput(element: HTMLInputElement): File | undefined {
  const files: File[] = (element.files && Array.from(element.files)) || [];

  return (files.length > 0 && files[0]) || undefined;
}

type HiddenInputResult = string | Record<string, unknown> | any[] | undefined;

function parseHiddenInput(element: HTMLInputElement): HiddenInputResult {
  const value = parseInput(element);
  if (value) {
    const dataFormat = element.getAttribute(AttributeNames.DATA_FORMAT);
    if (dataFormat === HiddenInputDataFormat.JSON) {
      return JSON.parse(value);
    } else if (dataFormat === HiddenInputDataFormat.COST_WITH_CURRENCY) {
      if (value.includes('amount') && value.includes('currency')) {
        return JSON.parse(value);
      } else {
        throw new Error(`Wrong format for HiddenInputDataFormat.COST_WITH_CURRENCY for value ${value}`);
      }
    } else {
      return value;
    }
  } else {
    return value;
  }
}
