import type { ApplicationState } from '@provider-portal/store';
import type { ActionsUnion } from '@provider-portal/utils/redux';
import { createAction } from '@provider-portal/utils/redux';
import type { IChangeOrder } from './models';

enum ActionKeys {
  SET_CHANGE_DESK_ORDERS = 'CHANGE/FETCH_CHANGE_ORDERS',
  SET_CHANGE_ORDER = 'CHANGE/FETCH_CHANGE_ORDER',
}

export const ChangeActions = {
  setChangeOrders: (changeOrders: IChangeOrder[]) => createAction(ActionKeys.SET_CHANGE_DESK_ORDERS, changeOrders),
  setChangeOrder: (changeOrder: IChangeOrder) => createAction(ActionKeys.SET_CHANGE_ORDER, changeOrder),
};

export type Actions = ActionsUnion<typeof ChangeActions>;

export interface ChangeState {
  changeOrders: IChangeOrder[];
  changeOrder?: IChangeOrder;
}

const initialState: ChangeState = {
  changeOrders: [],
};

export function reducer(state: ChangeState = initialState, action: Actions): ChangeState {
  switch (action.type) {
    case ActionKeys.SET_CHANGE_ORDER:
      return {
        ...state,
        changeOrder: action.payload,
      };

    case ActionKeys.SET_CHANGE_DESK_ORDERS:
      return {
        ...state,
        changeOrders: action.payload,
      };

    default:
      return state;
  }
}

export function selectorChangeOrdersToBeProcessed(state: ApplicationState): IChangeOrder[] {
  return state.change.changeOrders;
}

export function selectorClosedChangeOrders(state: ApplicationState): IChangeOrder[] {
  return state.change.changeOrders;
}
