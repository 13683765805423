import { fetchOrderServiceIdentifiers } from '@provider-portal/PartnerSupplier/api';
import type { SupplierWithService } from '@provider-portal/PartnerSupplier/models';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import * as React from 'react';
import { fetchSubscriptionSuppliers } from '../api';
import type { ISupplierService } from '../models';

export function useFetchResources() {
  const [loadingState, setLoadingState] = React.useState(PortalLoadingState.Loading);
  const [subscriptionSuppliers, setSubscriptionSuppliers] = React.useState<SupplierWithService[]>([]);
  const [orderServices, setOrderServices] = React.useState<string[]>([]);
  const componentIsMounted = React.useRef(true);

  React.useEffect(() => {
    const suppliersPromise = fetchSubscriptionSuppliers().then((suppliers) => {
      if (componentIsMounted.current) {
        setSubscriptionSuppliers(suppliers);
      }
    });

    const orderServicesPromise = fetchOrderServiceIdentifiers().then((services) => {
      if (componentIsMounted.current) {
        setOrderServices(services);
      }
    });

    Promise.all([suppliersPromise, orderServicesPromise])
      .then(() => {
        if (componentIsMounted.current) {
          setLoadingState(PortalLoadingState.Success);
        }
      })
      .catch(() => {
        if (componentIsMounted.current) {
          setLoadingState(PortalLoadingState.Failure);
        }
      });

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return {
    loadingState,
    subscriptionSuppliers,
    orderServices,
    supplierServices: supplierServicesByCategory(subscriptionSuppliers),
  };
}

function supplierServicesByCategory(subscriptionSuppliers: SupplierWithService[]): Map<string, ISupplierService[]> {
  const services: ISupplierService[] = flatMap(
    subscriptionSuppliers.map((supplier) => {
      return supplier.services.map((service) => ({
        name: supplier.name,
        id: service.id,
        categoryName: service.category.name,
      }));
    })
  );

  const servicesGroupedByCategory = mapValues(groupBy(services, 'categoryName'));

  return new Map(Object.keys(servicesGroupedByCategory).map((key) => [key, servicesGroupedByCategory[key]]));
}
