import { LoginPage } from '@provider-portal/login/LoginPage';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import type { ApplicationState } from '../store';
import { LoggedInRoutes } from './logged-in-routes';
import { LogoutRoute } from './logout-route';
import type { Locale } from '@provider-portal/internationalization/locale';
import { useMarketFromQueryParam } from '@provider-portal/utils/useMarketFromQueryParam';

interface SupplierPortalRouteProps {
  isLoggedIn: boolean;
  hasAdminAccess: boolean;
  loggedInPartnerSupplierId?: string;
}

export const SupplierPortalRouteInner: React.FunctionComponent<SupplierPortalRouteProps> = ({
  isLoggedIn,
  hasAdminAccess,
  loggedInPartnerSupplierId,
}) => {
  return (
    <Switch>
      <Route path={'/login'} render={(props) => <LoginPage {...props} />} />
      <Route path={'/logout'} render={(props) => <LogoutRoute {...props} />} />
      <Route
        path={'/'}
        render={(props) =>
          isLoggedIn ? (
            <LoggedInRoutes
              {...props}
              hasAdminAccess={hasAdminAccess}
              loggedInPartnerSupplierId={loggedInPartnerSupplierId}
            />
          ) : (
            <RedirectToLogin />
          )
        }
      />
    </Switch>
  );
};

const RedirectToLogin: React.FunctionComponent = () => {
  const maybeMarket = useMarketFromQueryParam(false);
  const maybeQueryParam = (maybeMarket && `market=${maybeMarket}`) || '';

  return <Redirect to={{ pathname: '/login', search: maybeQueryParam }} />;
};

interface StateProps {
  isLoggedIn: boolean;
  hasAdminAccess: boolean;
  loggedInPartnerSupplierId?: string;
  userLocale?: Locale;
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    hasAdminAccess: state.user.hasAdminAccess,
    loggedInPartnerSupplierId: state.user.loggedInPartnerSupplierId,
    userLocale: state.user.userLocale,
  };
};

export const SupplierPortalRoute = compose<SupplierPortalRouteProps, Record<string, never>>(connect(mapStateToProps))(
  SupplierPortalRouteInner
);
