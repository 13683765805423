import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';

export const DocumentIcon: React.FunctionComponent = () => {
  return (
    <SvgIcon height={24} width={24}>
      <g clipPath="url(#clip0)">
        <path
          d="M6.75 10.51C6.75 10.0958 7.08579 9.76 7.5 9.76H16.5C16.9142 9.76 17.25 10.0958 17.25 10.51C17.25 10.9242 16.9142 11.26 16.5 11.26H7.5C7.08579 11.26 6.75 10.9242 6.75 10.51Z"
          fill="#00B5B9"
        />
        <path
          d="M6.75 14.26C6.75 13.8458 7.08579 13.51 7.5 13.51H16.5C16.9142 13.51 17.25 13.8458 17.25 14.26C17.25 14.6742 16.9142 15.01 16.5 15.01H7.5C7.08579 15.01 6.75 14.6742 6.75 14.26Z"
          fill="#00B5B9"
        />
        <path
          d="M7.5 17.26C7.08579 17.26 6.75 17.5958 6.75 18.01C6.75 18.4242 7.08579 18.76 7.5 18.76H16.5C16.9142 18.76 17.25 18.4242 17.25 18.01C17.25 17.5958 16.9142 17.26 16.5 17.26H7.5Z"
          fill="#00B5B9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.01C11.3788 3.01 10.875 3.51379 10.875 4.135C10.875 4.75622 11.3788 5.26 12 5.26C12.6212 5.26 13.125 4.75622 13.125 4.135C13.125 3.51379 12.6212 3.01 12 3.01ZM12.375 4.135C12.375 4.34222 12.2072 4.51 12 4.51C11.7928 4.51 11.625 4.34222 11.625 4.135C11.625 3.92779 11.7928 3.76 12 3.76C12.2072 3.76 12.375 3.92779 12.375 4.135Z"
          fill="#00B5B9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.56222 3.76C7.9193 1.63176 9.77032 0.0100021 12 0.0100021C14.2297 0.0100021 16.0807 1.63176 16.4378 3.76H19.5C20.7422 3.76 21.75 4.76779 21.75 6.01V21.76C21.75 23.0022 20.7422 24.01 19.5 24.01H4.5C3.25779 24.01 2.25 23.0022 2.25 21.76V6.01C2.25 4.76779 3.25779 3.76 4.5 3.76H7.56222ZM12 1.51C10.3432 1.51 9 2.85322 9 4.51C9 4.92422 8.66421 5.26 8.25 5.26H4.5C4.08621 5.26 3.75 5.59622 3.75 6.01V21.76C3.75 22.1738 4.08621 22.51 4.5 22.51H19.5C19.9138 22.51 20.25 22.1738 20.25 21.76V6.01C20.25 5.59622 19.9138 5.26 19.5 5.26H15.75C15.3358 5.26 15 4.92422 15 4.51C15 2.85322 13.6568 1.51 12 1.51Z"
          fill="#00B5B9"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
