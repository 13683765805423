import { LocalizedMessage } from '@provider-portal/internationalization';
import type { Locale } from '@provider-portal/internationalization/locale';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { WidgetType } from '@provider-portal/libs/uiFramework/components/widgets/config';
import type { Currency } from '@provider-portal/models/subscription';
import compact from 'lodash/compact';
import * as React from 'react';

export enum ProductFormFieldNames {
  NAME = 'name',
  EXTERNAL_PRODUCT_ID = 'externalProductId',
  BINDING_PERIOD = 'bindingPeriod',
  NOTICE_PERIOD = 'noticePeriod',
  CAMPAIGN_ID = 'campaignId',
  COMMISSION = 'commission',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
}

const makeNamingSection = (tooltipText: React.ReactNode): IFieldSectionConfig => ({
  caption: <LocalizedMessage id="productSectionHeader" />,
  columns: 2,
  divider: 'bottom',
  components: [
    TextInput({
      name: ProductFormFieldNames.NAME,
      label: <LocalizedMessage id="nameLabel" />,
      tooltipTitle: <LocalizedMessage id="nameLabel" />,
      tooltipText,
      helperText: <LocalizedMessage id="productNameHelpText" />,
      required: true,
      maxlength: 30,
    }),
    TextInput({
      name: ProductFormFieldNames.EXTERNAL_PRODUCT_ID,
      label: <LocalizedMessage id="idLabel" />,
      tooltipTitle: <LocalizedMessage id="idLabel" />,
      tooltipText: <LocalizedMessage id="productIdTooltipText" />,
    }),
  ],
});

export const mobileNamingSection: IFieldSectionConfig = makeNamingSection(
  <LocalizedMessage id="mobileProductNameTooltipText" />
);

export const electricityNamingSection: IFieldSectionConfig = makeNamingSection(
  <LocalizedMessage id="electricityProductNameTooltipText" />
);

const termsUploadSection: IFieldSectionConfig = {
  caption: <LocalizedMessage id="termsHeader" />,
  captionText: <LocalizedMessage id="termsCaptionText" />,
  columns: 2,
  divider: 'top',
  components: [
    {
      name: ProductFormFieldNames.TERMS_AND_CONDITIONS,
      type: WidgetType.TERMS_AND_CONDITIONS_UPLOAD,
    },
  ],
};

const termsSection = (locale: Locale): IFieldSectionConfig => {
  return {
    columns: 4,
    caption: <LocalizedMessage id="contractDetailsHeader" />,
    components: [
      NumberInput({
        name: ProductFormFieldNames.BINDING_PERIOD,
        label: <LocalizedMessage id="bindingPeriodLabel" />,
        numberType: NumberType.PositiveInteger,
        helperText: <LocalizedMessage id="bindingPeriodHelperText" />,
        locale: locale,
      }),
      NumberInput({
        name: ProductFormFieldNames.NOTICE_PERIOD,
        label: <LocalizedMessage id="noticePeriodLabel" />,
        numberType: NumberType.PositiveInteger,
        helperText: <LocalizedMessage id="noticePeriodHelperText" />,
        locale: locale,
      }),
    ],
  };
};

export const termsPaper = (locale: Locale, row?: 1 | 2, column?: 1 | 2): IFormPagePaper => {
  return {
    sections: [termsSection(locale), termsUploadSection],
    row: row,
    column: column,
  };
};

const campaignSection: IFieldSectionConfig = {
  caption: <LocalizedMessage id="campaignSectionHeader" />,
  tooltipTitle: <LocalizedMessage id="campaignSectionHeader" />,
  tooltipText: <LocalizedMessage id="campaignTooltipText" />,
  columns: 2,
  components: [
    {
      type: WidgetType.CAMPAIGN_ID_SELECT,
    },
  ],
};

export const campaignPaper: IFormPagePaper = {
  sections: [campaignSection],
};

const internalSections = (hasAdminAccess: boolean, currency: Currency, locale: Locale): IFieldSectionConfig[] =>
  compact([
    hasAdminAccess && {
      caption: <LocalizedMessage id="headerInternalSettings" />,
      columns: 4,
      components: [
        CostInput({
          name: ProductFormFieldNames.COMMISSION,
          label: <LocalizedMessage id="commissionLabel" />,
          required: true,
          currency: currency,
          locale: locale,
        }),
      ],
    },
  ]);

export const internalPaper = (
  hasAdminAccess: boolean,
  currency: Currency,
  locale: Locale,
  row?: 1 | 2,
  column?: 1 | 2
): IFormPagePaper => {
  return {
    sections: [...internalSections(hasAdminAccess, currency, locale)],
    row: row,
    column: column,
  };
};
