import * as React from 'react';
import { colors } from '@provider-portal/styles/colors';

export const DividerSection: React.FunctionComponent = () => {
  return (
    <hr
      style={{
        borderTop: `1px solid ${colors.grey4}`,
        borderBottom: 0,
        borderLeft: 0,
        borderRight: 0,
        margin: 0,
      }}
    />
  );
};
