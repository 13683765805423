import { Sentry } from '@provider-portal/sentry';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import { outputFileToClient } from '@provider-portal/utils/file-utils';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { exportOrders } from '../api';
import type { ISwitchOrder } from '../models';
import { switchOrdersToBeProcessedTableConfig } from './config';
import type { Market } from '@provider-portal/app-context/constants';

export interface IMapProps {
  tableData: ISwitchOrder[];
  tableConfig: ITableConfig;
  onSwitchOrderClick(switchOrder: ISwitchOrder): void;
  onExportOrders(partnerSupplierId: string, categoryName: string): (switchOrders: ISwitchOrder[]) => Promise<void>;
  ordersExportLoadingState: PortalLoadingState;
}

export function useMapProps(switchOrders: ISwitchOrder[], market: Market): IMapProps {
  const history = useHistory();
  const [ordersExportLoading, setOrdersExportLoading] = React.useState(PortalLoadingState.Success);
  const tableConfig = switchOrdersToBeProcessedTableConfig(market);

  return {
    tableData: switchOrders,
    tableConfig,
    onSwitchOrderClick(switchOrder: ISwitchOrder) {
      const saveDeskOrderDetailsUrl = URI(history.location.pathname).segment(switchOrder.id).toString();
      history.push(saveDeskOrderDetailsUrl);
    },
    onExportOrders: (partnerSupplierId: string, categoryName: string) =>
      debouncePromise(async (orders: ISwitchOrder[]) => {
        const orderIds = orders.map((o) => o.id);
        setOrdersExportLoading(PortalLoadingState.Loading);

        return exportOrders(partnerSupplierId, categoryName, orderIds)
          .then((exportResult) => {
            setOrdersExportLoading(PortalLoadingState.Success);
            outputFileToClient(exportResult.fileContent, exportResult.fileName, exportResult.contentType);
          })
          .catch((error) => {
            Sentry.captureExceptionWithMessage(error, 'Failed to export switch orders', {
              extra: { partnerSupplierId },
            });
            setOrdersExportLoading(PortalLoadingState.Failure);
          });
      }),
    ordersExportLoadingState: ordersExportLoading,
  };
}
