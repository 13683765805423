import { Market } from '@provider-portal/app-context/constants';
import { getDefaultCurrency, getDefaultLocale } from '@provider-portal/app-context/state';
import type { CategoryName } from '@provider-portal/constants/categories';
import { categories } from '@provider-portal/constants/categories';
import type { Locale } from '@provider-portal/internationalization/locale';
import type { Currency } from '@provider-portal/models/subscription';
import { verticalMarketKey } from '../init/vertical-market-key';
import type { HasAdminAccessFunc, ProductFormLayoutMap } from './productFormLayouts';

type MakeHasAdminFunc = (categoryName: CategoryName, currency: Currency, locale: Locale) => HasAdminAccessFunc;

export const createGenericProductFormLayoutMap = (
  categoryName: CategoryName,
  func: MakeHasAdminFunc
): ProductFormLayoutMap[] => {
  const markets: Market[] = Object.values(Market);
  const category = categories[categoryName];

  return markets.map((market) => [
    verticalMarketKey(category.key as CategoryName, market),
    func(categoryName, getDefaultCurrency(market), getDefaultLocale(market)),
  ]);
};
