import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import * as React from 'react';
import { Button } from '../Button';
import styles from './styles.scss';

interface IProps {
  variant?: 'left' | 'right';
  text: string | React.ReactNode;
  onClick?(): void;
}

export const NavigationButton: React.FunctionComponent<IProps> = ({ text, onClick, variant }) => {
  let label = (
    <span className={styles.buttonText}>
      <ChevronLeft />
      {text}
    </span>
  );

  if (variant === 'right') {
    label = (
      <span className={styles.buttonText}>
        {text}
        <ChevronRight />
      </span>
    );
  }

  return <Button label={label} onClick={onClick} color="secondary" variant="contained" />;
};
