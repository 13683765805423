import { LocalizedMessage } from '@provider-portal/internationalization';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import type { ISwitchOrderState } from '@provider-portal/orders/switch/models';
import { SwitchOrderState } from '@provider-portal/orders/switch/models';
import * as React from 'react';
import { OrderAccepted } from './OrderAccepted';
import { OrderRejected } from './OrderRejected';
import styles from './styles.scss';

export interface IClosedResponseCardProps {
  orderState: ISwitchOrderState;
}

export const ClosedResponseCard: React.FunctionComponent<IClosedResponseCardProps> = ({ orderState }) => {
  return (
    <Paper title={<LocalizedMessage id="supplierResponseCardTitle" />}>
      <div className={styles.container}>
        {orderState.name === SwitchOrderState.Accepted ? <OrderAccepted /> : <OrderRejected orderState={orderState} />}
      </div>
    </Paper>
  );
};
