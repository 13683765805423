import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Switch } from '@minna-technologies/minna-ui/components/Inputs/Switch';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { MinnaIcon } from '@minna-technologies/minna-ui/icons/Minna';
import { PauseIcon } from '@minna-technologies/minna-ui/icons/Pause';
import { CampaignIcon } from '@provider-portal/assets/icons/CampaignIcon';
import ChangeIcon from '@provider-portal/assets/icons/ChangeIcon.svg';
import SubscriptionIcon from '@provider-portal/assets/icons/SubscriptionIcon.svg';
import ChangePlanIllustration from '@provider-portal/assets/illustrations/ChangePlanIllustration.png';
import PauseIllustration from '@provider-portal/assets/illustrations/PauseIllustration.png';
import ResubscribeIllustration from '@provider-portal/assets/illustrations/ResubscribeIllustration.png';
import RetentionOffersIllustration from '@provider-portal/assets/illustrations/RetentionOffersIllustration.png';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  flexGap: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  pageContent: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  descriptionCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  gridCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '& .illustration': {
      width: '80px',
    },
  },
  solutionCta: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '24px 16px 1fr 30px 30px',
    gridTemplateAreas: "'solutionCtaIcon solutionCtaGap1 solutionCtaText solutionCtaGap2 solutionCtaSwitch'",
    backgroundColor: theme.colors.base.surface,
    padding: '12px',
    borderRadius: '8px',
  },
  solutionCtaIcon: {
    gridArea: 'solutionCtaIcon',
  },
  solutionCtaText: {
    gridArea: 'solutionCtaText',
  },
  solutionCtaSwitch: {
    gridArea: 'solutionCtaSwitch',
  },
}));

const RetentionSolutionsPageInner = () => {
  const { colors } = useTheme();
  const classes = useStyles();

  return (
    <Page
      appBarText={
        <div className={classes.flexGap}>
          <MinnaIcon nativeColor={colors.base.surface} />
          <Headline5 textColor="alternative">
            <LocalizedMessage id="title" />
          </Headline5>
        </div>
      }
      variant="details"
      showMenuForSmallerScreen
      showBackButton={false}
    >
      <div className={classes.pageContent}>
        <header>
          <Headline1>
            <LocalizedMessage id="header" />
          </Headline1>
        </header>
        <Card className={classes.descriptionCard}>
          <Headline3>
            <LocalizedMessage id="descriptionCardTitle" />
          </Headline3>
          <Body>
            <LocalizedMessage id="descriptionCardBody" />
          </Body>
        </Card>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={6}>
            <RetentionSolutionCard
              illustration={PauseIllustration}
              localizationSolutionTitle="pauseSolutionTitle"
              localizationSolutionDescription="pauseSolutionDescription"
              solutionCtaIcon={<PauseIcon nativeColor={colors.action.primary} />}
              localizationSolutionCtaText="pauseSolutionCtaText"
            />
          </Grid>
          <Grid item xs={6}>
            <RetentionSolutionCard
              illustration={RetentionOffersIllustration}
              localizationSolutionTitle="retentionOffersSolutionTitle"
              localizationSolutionDescription="retentionOffersSolutionDescription"
              solutionCtaIcon={<CampaignIcon nativeColor={colors.action.primary} />}
              localizationSolutionCtaText="retentionOffersSolutionCtaText"
            />
          </Grid>
          <Grid item xs={6}>
            <RetentionSolutionCard
              illustration={ChangePlanIllustration}
              localizationSolutionTitle="changePlanSolutionTitle"
              localizationSolutionDescription="changePlanSolutionDescription"
              solutionCtaIcon={<img src={ChangeIcon} />}
              localizationSolutionCtaText="changePlanSolutionCtaText"
            />
          </Grid>
          <Grid item xs={6}>
            <RetentionSolutionCard
              illustration={ResubscribeIllustration}
              localizationSolutionTitle="resubscribeSolutionTitle"
              localizationSolutionDescription="resubscribeSolutionDescription"
              solutionCtaIcon={<img src={SubscriptionIcon} />}
              localizationSolutionCtaText="resubscribeSolutionCtaText"
            />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

interface RetentionSolutionCardProps {
  illustration: string;
  localizationSolutionTitle: string;
  localizationSolutionDescription: string;
  localizationSolutionCtaText: string;
  solutionCtaIcon: JSX.Element;
}

const RetentionSolutionCard = ({
  illustration,
  localizationSolutionTitle,
  localizationSolutionDescription,
  solutionCtaIcon,
  localizationSolutionCtaText,
}: RetentionSolutionCardProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.gridCard}>
      <img className="illustration" src={illustration} />
      <Headline2>
        <LocalizedMessage id={localizationSolutionTitle} />
      </Headline2>
      <Body>
        <LocalizedMessage id={localizationSolutionDescription} />
      </Body>
      <div className={classes.solutionCta}>
        <div className={classes.solutionCtaIcon}>{solutionCtaIcon}</div>
        <Body className={classes.solutionCtaText}>
          <LocalizedMessage id={localizationSolutionCtaText} />
        </Body>
        <Switch className={classes.solutionCtaSwitch} />
      </div>
    </Card>
  );
};

export const RetentionSolutionPage = withLocalization('RetentionSolutionPage')(RetentionSolutionsPageInner);
