import type { DisplayName } from './constants';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import type { AppContext } from './index';
import { Sentry } from '@provider-portal/sentry';

export const { reducer, actions: AppContextActions } = createSlice({
  name: 'APP_CONTEXT',
  initialState: null as AppContext | null,
  reducers: {
    initialize: (state, action: PayloadAction<AppContext>) => action.payload,
    setDisplayName: (state, action: PayloadAction<DisplayName>) => {
      if (state === null) {
        throw new Error(
          'AppContext was undefined when setting new locale. AppContext should be defined as soon as the application is starting.'
        );
      }

      state.displayName = action.payload;
    },
    setFeatureToggle: {
      prepare: (featurePath: string, active: boolean | string) => ({ payload: { featurePath, active } }),
      reducer: (state, action: PayloadAction<{ featurePath: string; active: boolean | string }>) => {
        if (state === null) {
          throw new Error(
            'AppContext was undefined when setting a feature toggle. AppContext should be defined as soon as the application is starting.'
          );
        }

        set(state.features, action.payload.featurePath, action.payload.active);
      },
    },
  },
});

interface State {
  appContext: AppContext;
}

export const selectorAppContext = (state: State): AppContext => {
  const appContext = state.appContext;
  if (appContext === null || isEmpty(appContext)) {
    throw new Error('AppContext was undefined, it should be defined as soon the application is starting.');
  } else {
    // @ts-ignore property is only defined in the Admin dummy AppContext
    if (appContext.adminDummy) {
      Sentry.captureMessage(
        'Accessing AppContext in Admin. This behaviour is deprecated since AppContext is end-user specific. Please refactor the code.'
      );
    }

    return appContext;
  }
};

export const selectorAppType = (state: State) => selectorAppContext(state).appType;
export const selectorDisplayName = (state: State) => selectorAppContext(state).displayName;
export const selectorFeatures = (state: State) => selectorAppContext(state).features;
