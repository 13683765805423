import { LocalizedMessage } from '@provider-portal/internationalization';
import React from 'react';
import { PaymentOption, SupportChannel } from '../models';

export function localizePaymentOption(paymentOption: PaymentOption) {
  switch (paymentOption) {
    case PaymentOption.PaperInvoice:
      return <LocalizedMessage id="editPaymentOptionsDialogPaperInvoice" />;
    case PaymentOption.ElectronicInvoice:
      return <LocalizedMessage id="editPaymentOptionsDialogElectronicInvoice" />;
    case PaymentOption.EmailInvoice:
      return <LocalizedMessage id="editPaymentOptionsDialogEmailInvoice" />;
    case PaymentOption.DirectDebit:
      return <LocalizedMessage id="editPaymentOptionsDialogDirectDebit" />;
    case PaymentOption.Kivra:
      return <LocalizedMessage id="editPaymentOptionsDialogKivra" />;
  }
}

export function localizeSupportChannel(supportChannel: SupportChannel) {
  switch (supportChannel) {
    case SupportChannel.Email:
      return <LocalizedMessage id="editSupportChannelsDialogEmail" />;
    case SupportChannel.Phone:
      return <LocalizedMessage id="editSupportChannelsDialogPhone" />;
    case SupportChannel.Store:
      return <LocalizedMessage id="editSupportChannelsDialogStore" />;
    case SupportChannel.Facebook:
      return <LocalizedMessage id="editSupportChannelsDialogFacebook" />;
    case SupportChannel.Chat:
      return <LocalizedMessage id="editSupportChannelsDialogChat" />;
  }
}
