import forOwn from 'lodash/forOwn';
import isObject from 'lodash/isObject';

export const localizedMessagesEnglish = () => {
  const supplierPortalContext = require.context('@provider-portal/', true, /en-GB\.json$/);

  return localizedMessagesFromContext(supplierPortalContext);
};

const localizedMessagesFromContext = (context: any) => {
  let allMessages = {};
  context.keys().forEach((key: any) => {
    const namespace = context(key).namespace;
    const messages = context(key).translation || context(key).messages;
    const messagesWithTransformedKeys = {};
    forOwn(messages, (value: any, key2: any) => {
      //@ts-ignore
      const message = isObject(value) ? value.message : value;
      //@ts-ignore
      messagesWithTransformedKeys[`${namespace}.${key2}`] = message;
    });
    allMessages = { ...allMessages, ...messagesWithTransformedKeys };
  });

  return allMessages;
};
