import { createTheme } from '@material-ui/core/styles';
import type { minnaTheme } from '@minna-technologies/minna-ui/styles/themes/minna';
import { colors } from '@provider-portal/styles/colors';
import { primary, white } from '@provider-portal/styles/colors-standard';

export const supplierPortalDefaultTheme: typeof minnaTheme = createTheme({
  palette: {
    primary: {
      main: primary.main,
      contrastText: white.main,
    },
    background: {
      default: colors.background,
    },
  },
  breakpoints: {
    values: {
      //Matching our responsive-helpers
      xs: 0,
      sm: 640,
      md: 1080,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: primary.main,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: primary.main,
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: primary.main,
        },
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: primary.main,
          '& + $bar': {
            backgroundColor: primary.main,
          },
        },
      },
    },
    MuiStepLabel: {
      label: {
        '&$active': {
          fontWeight: 'normal',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiDivider: {
      root: {
        // Dividers not consistent when zooming.
        // https://github.com/mui-org/material-ui/issues/14815
        borderTop: 'thin solid rgba(0, 0, 0, 0.12)',
        backgroundColor: undefined,
        height: undefined,
      },
    },
  },
  spacing: 8,
  colors: {
    action: {
      primary: '#00B5B9',
      primaryHover: '#e9ffff',
      primaryPressed: '#d6fefe',
      primaryDisabled: '#DDDDDD',
      secondary: '#B661EA',
      secondaryHover: '#CE93F2',
      secondaryPressed: '#DEB7F7',
      secondaryDisabled: '#DDDDDD',
      info: '#7fa3ff',
      infoHover: '#7fa3ff',
      infoPressed: '#7fa3ff',
      positive: '#68c958',
      positiveHover: '#68c958',
      positivePressed: '#68c958',
      danger: '#dd2c4c',
      dangerHover: '#dd2c4c',
      dangerPressed: '#dd2c4c',
    },
    textOn: {
      surface: '#222222',
      surfaceSubdued: 'rgba(34, 34, 34, 0.8)',
      primary: '#ffffff',
      secondary: '#222222',
      disabled: '#818181',
      info: '#192133',
      positive: '#1F3D1B',
      danger: '#430D17',
    },
    element: {
      rating: '#FFD467',
      badgeSurface: '#1D82A1',
    },
    base: {
      surface: '#ffffff',
      border: '#B0B0B0',
      background: '#f8f8f8',
      info: '#D8E3FF',
      positive: '#D1EFCD',
      danger: '#F5BFC9',
    },
  },
  cardShadow: '0px 2px 5px rgba(34, 34, 34, 0.2)',
  modalShadow: '0px 4px 20px rgba(34, 34, 34, 0.25)',
  selectButtonShadowSelected: '00px 3px 3px rgba(34, 34, 34, 0.1), 0px 6px 12px rgba(34, 34, 34, 0.15)',
  selectButtonShadowDefault: '0px 2px 2px rgba(34, 34, 34, 0.2)',
  nextStepShadow:
    '0px 0px 3px rgba(34, 34, 34, 0.2), 0px 2px 5px rgba(34, 34, 34, 0.1), 0px 5px 10px rgba(34, 34, 34, 0.2)',
  floatingShadow:
    '0px 1px 1px rgba(34, 34, 34, 0.06), 0px 2px 3px rgba(34, 34, 34, 0.1), 0px 3px 10px rgba(34, 34, 34, 0.1)',
  borderRadius: '8px',
  transitionDuration: '0.2s',
  borderRadiusPrimaryButton: '8px',
  borderRadiusSecondaryButton: '8px',
  themeImages: {},
});
