import type { ICampaignIdSelectWidget } from '../CampaignIdSelect/config';
import type { IMonthlyDataCampaignWidget } from '../MonthlyDataCampaign/config';
import type { IMonthlyDiscountWidget } from '../MonthlyDiscount/config';
import type { ISwedenPhoneCallsLimitationInputWidget } from '../SwedenPhoneCallsLimitationInput/config';
import type { ITermsAndConditionsUploadWidget } from '../TermsAndConditionsUpload/config';
import type { IWidget } from './';
import { WidgetType } from './';
import type { ITranslatableFieldWidget } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/config';

export function isCampaignIdSelect(widget: IWidget): widget is ICampaignIdSelectWidget {
  return (widget as ICampaignIdSelectWidget).type === WidgetType.CAMPAIGN_ID_SELECT;
}

export function isSwedenPhoneCallsLimitationInput(widget: IWidget): widget is ISwedenPhoneCallsLimitationInputWidget {
  return (widget as ISwedenPhoneCallsLimitationInputWidget).type === WidgetType.SWEDEN_PHONE_CALLS_LIMITATION_INPUT;
}

export function isTermsAndConditionsUpload(widget: IWidget): widget is ITermsAndConditionsUploadWidget {
  return (widget as ITermsAndConditionsUploadWidget).type === WidgetType.TERMS_AND_CONDITIONS_UPLOAD;
}

export function isMonthlyDiscount(widget: IWidget): widget is IMonthlyDiscountWidget {
  return (widget as IMonthlyDiscountWidget).type === WidgetType.MONTHLY_DISCOUNT;
}

export function isMonthlyDataCampaign(widget: IWidget): widget is IMonthlyDataCampaignWidget {
  return (widget as IMonthlyDataCampaignWidget).type === WidgetType.MONTHLY_DATA_CAMPAIGN;
}

export function isTranslatableFieldWidget(widget: IWidget): widget is ITranslatableFieldWidget {
  return (widget as ITranslatableFieldWidget).type === WidgetType.TRANSLATABLE_FIELD;
}
