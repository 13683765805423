import * as React from 'react';
import { colors } from '@provider-portal/styles/colors';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { LocalizedMessage } from '@provider-portal/internationalization';

interface IExportButtonProps {
  orderCount: number;
  onClick(): void;
}

export const ExportButton: React.FunctionComponent<IExportButtonProps> = ({ orderCount, onClick }) => {
  return (
    <Body
      variant="link"
      style={{
        color: colors.primaryDark,
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      <LocalizedMessage id="exportOrdersButtonLabel" values={{ orderCount }} />
    </Body>
  );
};
