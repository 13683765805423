import { makeStyles } from '@material-ui/core/styles';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import { StarPictogram } from '@minna-technologies/minna-ui/pictograms/Star';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import * as React from 'react';
import { useState } from 'react';
import { StarRating } from '@minna-technologies/minna-ui/components/StarRating';
import { selectorCreatedAt, selectorCsatRequestedAt } from '@provider-portal/login/duck';
import { useSelector } from 'react-redux';
import { updateCsatDisplayAttempts } from '@provider-portal/UserManagement/api';
import type { Moment } from 'moment';
import moment from 'moment';
import { sendCSatCommentEvent, sendCSatRatingEvent } from '@provider-portal/tracking/mixpanel-tracking-events';

const useStyles = makeStyles(() => ({
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    boxShadow: '0 4px 20px rgba(34, 34, 34, 0.25)',
    borderRadius: '8px',
  },
  paddingTop: {
    paddingTop: '16px',
  },
  textFields: {
    // avoid overlapping with CloseIcon on small widths
    paddingRight: '18px',
  },
  closeButton: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    cursor: 'pointer',
  },
  thankTheUserGrid: {
    display: 'grid',
    gridTemplateColumns: '64px auto',
    gridTemplateRows: '32px 32px',
    gridColumnGap: '8px',
    paddingTop: '8px',
  },
  pictogram: {
    gridRow: '1 / span 1',
    gridColumn: '1 / span 2',
  },
  yourFeedbackWasSent: {
    gridRow: '1 / 1',
    gridColumn: '2 / 2',
  },
  thankYouForSharing: {
    gridRow: '2 / 2',
    gridColumn: '2 / 2',
  },
}));

export interface CSATWidgetProps {
  hasAdminAccess: boolean;
}

enum State {
  LeaveStarRating,
  LeaveComment,
  ThankTheUser,
}

const csatWidgetInitialStateClosed = (createdAt: Moment, csatLastRequestedAt: Moment[]) => {
  const now = moment();
  // reset attempts to display CSAT widget if it was more than 3 months since the user
  // dismissed it
  const resetCsatDisplayAttempts =
    csatLastRequestedAt.length >= 3 && now.subtract(3, 'month').isAfter(csatLastRequestedAt.slice(-1)[0]);
  const csatWidgetInitialStateClosed =
    (!resetCsatDisplayAttempts && csatLastRequestedAt.length >= 3) ||
    // don't display widget if user has used portal for less than a month,
    // otherwise display it
    (csatLastRequestedAt.length === 0 && now.subtract(1, 'month').isBefore(createdAt));
  return csatWidgetInitialStateClosed;
};

// Widget will autoclose after submission, after this many ms
const AUTO_CLOSE_TIMEOUT = 1000;
// Widget will move from "LeaveComment" state to calling onClose() after this many ms
const RATING_TIMEOUT = 800;

export const CSATWidgetInner: React.FunctionComponent<CSATWidgetProps> = ({ hasAdminAccess }: CSATWidgetProps) => {
  const createdAt = useSelector(selectorCreatedAt);
  const csatLastRequestedAt = useSelector(selectorCsatRequestedAt);
  const initialStateClosed = csatWidgetInitialStateClosed(createdAt, csatLastRequestedAt);
  const [csatWidgetClosed, setCsatWidgetClosed] = useState(initialStateClosed);
  const [rating, setRating] = useState(-1);
  const displayCSATWidget = !hasAdminAccess && !csatWidgetClosed;
  const [state, setState] = useState<State>(State.LeaveStarRating);

  const onRatingChange = (rated: number) => {
    setRating(rated);
    sendCSatRatingEvent(rated);
    // 3 times, to ensure the user is not asked again
    updateCsatDisplayAttempts();
    updateCsatDisplayAttempts();
    updateCsatDisplayAttempts();
    setTimeout(() => {
      setState(State.LeaveComment);
    }, RATING_TIMEOUT);
  };
  const onCommentLeft = (text: string) => {
    sendCSatCommentEvent(rating, text);
    setTimeout(() => {
      setCsatWidgetClosed(true);
    }, AUTO_CLOSE_TIMEOUT);
    setState(State.ThankTheUser);
  };
  const onClose = () => {
    updateCsatDisplayAttempts();
    setCsatWidgetClosed(true);
  };

  const classes = useStyles();
  return !displayCSATWidget ? null : (
    <Paper className={classes.paper}>
      <div className={classes.textFields}>
        {state === State.LeaveStarRating && <StarRatingPage onRatingChange={onRatingChange} />}
        {state === State.LeaveComment && <LeaveCommentPage onCommentLeft={onCommentLeft} />}
        {state === State.ThankTheUser && <ThankTheUserPage />}
      </div>
      <CloseIcon className={classes.closeButton} onClick={onClose} />
    </Paper>
  );
};

interface StarRatingPageProps {
  onRatingChange: (rating: number) => void;
}

const StarRatingPage: React.FunctionComponent<StarRatingPageProps> = ({ onRatingChange }: StarRatingPageProps) => {
  const [currentRating, setCurrentRating] = useState<number>(0);
  const classes = useStyles();

  const onRating = (rating: number) => {
    setCurrentRating(rating);
    onRatingChange(rating);
  };

  return (
    <>
      <Headline5 textColor="primary">
        <LocalizedMessage id="overallHowSatisfied" />
      </Headline5>
      <Body className={classes.paddingTop}>
        <LocalizedMessage id="yourFeedbackHelps" />
      </Body>
      <div className={classes.paddingTop}>
        <StarRating currentRating={currentRating} onRatingChange={onRating} />
      </div>
    </>
  );
};

interface LeaveCommentPageProps {
  onCommentLeft: (text: string) => void;
}

const LeaveCommentPage: React.FunctionComponent<LeaveCommentPageProps> = ({ onCommentLeft }: LeaveCommentPageProps) => {
  const [comment, setComment] = useState<string>('');
  const classes = useStyles();

  const onChangeComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  return (
    <Form onValidSubmit={() => onCommentLeft(comment)}>
      <Headline5 textColor="primary">
        <LocalizedMessage id="thankYou" />
      </Headline5>
      <Body className={classes.paddingTop}>
        <LocalizedMessage id="shareOtherFeedback" />
      </Body>
      <div className={classes.paddingTop}>
        <TextInput
          label={<LocalizedMessage id="yourComment" />}
          name="comment"
          onChange={onChangeComment}
          value={comment}
        />
      </div>
      <Button type="submit" variant="outlined" label={<LocalizedMessage id="sendButton" />} color="secondary" />
    </Form>
  );
};

const ThankTheUserPage: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.thankTheUserGrid}>
      <StarPictogram className={classes.pictogram} />
      <Headline5 textColor="primary" className={classes.yourFeedbackWasSent}>
        <LocalizedMessage id="yourFeedbackWasSent" />
      </Headline5>
      <Body className={classes.thankYouForSharing}>
        <LocalizedMessage id="thankYouForSharing" />
      </Body>
    </div>
  );
};

export const test_csatWidgetInitialStateClosed = csatWidgetInitialStateClosed;

export const CSATWidget = withLocalization('CSATWidget')(CSATWidgetInner);
