import { dispatchBeforeConnect } from '@provider-portal/containers/container-helpers';
import { handleFetchable } from '@provider-portal/fetching/handleFetchable';
import type { Actions } from '@provider-portal/PartnerSupplier/duck';
import { PartnerSupplierActions, selectorFetchablePartnerSuppliers } from '@provider-portal/PartnerSupplier/duck';
import type { ApplicationState } from '@provider-portal/store';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { SupplierListComponentProps } from './component';
import { SupplierListComponent } from './component';

const mapStateToProps = (state: ApplicationState) => {
  return {
    suppliers: selectorFetchablePartnerSuppliers(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Actions>, props: RouteComponentProps) => {
  return {
    goToEditSupplierPage(supplierId: string) {
      props.history.push(`/admin/suppliers/${supplierId}`);
    },
  };
};

export const SupplierListContainer = compose<SupplierListComponentProps, Record<string, never>>(
  withRouter,
  dispatchBeforeConnect((state: ApplicationState, dispatch: Dispatch<Actions>) =>
    dispatch(PartnerSupplierActions.fetchPartnerSuppliers())
  ),
  connect(mapStateToProps, mapDispatchToProps),
  handleFetchable('suppliers')
  //@ts-ignore
)(SupplierListComponent);
