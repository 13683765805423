import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { ChangeListOrdersPage } from './ChangeListOrdersPage';

export const ChangePortalRoutes: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={(props) => (
          <ChangeListOrdersPage
            // @ts-ignore
            partnerSupplierId={props.match.params.providerId}
          />
        )}
      />
    </Switch>
  );
};
