import type { IInputField } from '../../configs';
import { fieldConstructor, FieldType } from '../../configs';
import type { FileType } from '../models';

export interface IFileInput extends IInputField {
  type: FieldType.File;
  allowedFileTypes: FileType[];
}

export const FileInput = fieldConstructor<IFileInput>(FieldType.File);
