import * as React from 'react';
import type { Columns } from '../../fields/configs/columns';
import { ComponentsLayout } from '../../layouts/ComponentsLayout';
import type { IInfoPanel } from '../../panels/InfoPanel';
import { InfoPanel } from '../../panels/InfoPanel';
import { Section } from '../Section';

export interface IInfoPanelsSection {
  title?: React.ReactNode;
  panels: IInfoPanel[];
  columns: Columns;
}

export const InfoPanelsSection: React.FunctionComponent<IInfoPanelsSection> = ({ title, panels, columns }) => {
  const panelComponents = panels.map((panel, index) => <InfoPanel key={index} {...panel} />);

  return (
    <Section caption={title}>
      <ComponentsLayout components={panelComponents} columns={columns} />
    </Section>
  );
};
