import { withLocalization } from '@provider-portal/internationalization';
import { withAppContext } from '@provider-portal/app-context/react';
import type { SaveDeskResponseCardProps } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskResponseCard/component';
import { SaveDeskResponseCardComponent } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskResponseCard/component';
import type { ApplicationState } from '@provider-portal/store';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import type { ISaveDeskResponseCard as SaveDeskResponseCardType } from './config';

const mapStateToProps = (state: ApplicationState) => {
  return {
    saveDeskOrder: state.saveDesk.saveDeskOrder,
  };
};

export const SaveDeskResponseCard = compose<SaveDeskResponseCardProps, SaveDeskResponseCardType>(
  withAppContext,
  withLocalization('supplier-portal-save-desk'),
  connect(mapStateToProps)
)(SaveDeskResponseCardComponent);
