import { LocalizedMessage } from '@provider-portal/internationalization';
import { electricityCampaignSection } from '@provider-portal/campaigns/CampaignForm/config/common';
import { FieldType } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { FieldArrayItemComponentType } from '@provider-portal/libs/uiFramework/components/fields/FieldArray/config';
import { MeasurementUnit } from '@provider-portal/libs/uiFramework/components/fields/MeasurementUnit';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import * as React from 'react';
import type { CampaignFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createCampaignFormLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { getDefaultLocale } from '@provider-portal/app-context/state';

enum BelgiumGasCampaignFieldNames {
  FORM_TYPE = 'type',
  ONE_TIME_DISCOUNT = 'oneTimeDiscount',
  USAGE_DISCOUNTS = 'usageDiscounts',
}

enum BelgiumGasCampaignUsageDiscountsFieldNames {
  CAMPAIGN_LENGTH = 'campaignLength',
  UPPER_BOUND = 'upperBound',
  LOWER_BOUND = 'lowerBound',
  USAGE_DISCOUNT_AMOUNT = 'discount',
}

const locale = getDefaultLocale(Market.Belgium);

const belgiumGasCampaignSections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="oneTimeDiscount" />,
    divider: 'top',
    columns: 4,
    components: [
      {
        name: BelgiumGasCampaignFieldNames.ONE_TIME_DISCOUNT,
        type: FieldType.Cost,
        label: <LocalizedMessage id="welcomeDiscountLabelV2" />,
      },
      {
        name: BelgiumGasCampaignFieldNames.FORM_TYPE,
        type: FieldType.Hidden,
        defaultValue: 'BelgiumGasCampaignIncomingRequest',
      },
    ],
  },
  {
    caption: <LocalizedMessage id="usageDiscountsTitle" />,
    divider: 'top',
    columns: 1,
    components: [
      {
        name: BelgiumGasCampaignFieldNames.USAGE_DISCOUNTS,
        type: FieldType.FieldArray,
        columns: 3,
        fields: [
          {
            name: BelgiumGasCampaignUsageDiscountsFieldNames.LOWER_BOUND,
            type: FieldType.Number,
            label: <LocalizedMessage id="lowerBoundKwhLabel" />,
            required: true,
            numberType: NumberType.IntegerZeroOrGreater,
          },
          {
            name: BelgiumGasCampaignUsageDiscountsFieldNames.UPPER_BOUND,
            type: FieldType.Number,
            label: <LocalizedMessage id="upperBoundKwhLabel" />,
            required: true,
            numberType: NumberType.PositiveInteger,
          },
          {
            label: <LocalizedMessage id="gasUsageDiscountAmount" />,
            name: BelgiumGasCampaignUsageDiscountsFieldNames.USAGE_DISCOUNT_AMOUNT,
            type: FieldType.UnitFieldFactory,
            unitFieldFactoryType: 'cent',
            unit: MeasurementUnit.KWH,
            units: [MeasurementUnit.KWH, MeasurementUnit.PERCENT],
            required: true,
          },
          NumberInput({
            name: BelgiumGasCampaignUsageDiscountsFieldNames.CAMPAIGN_LENGTH,
            label: <LocalizedMessage id="campaignDurationInMonthsLabel" />,
            required: true,
            numberType: NumberType.PositiveInteger,
            locale: locale,
          }),
        ],
        addItemButtonLabel: <LocalizedMessage id="addIntervalButtonLabel" />,
        itemComponent: {
          type: FieldArrayItemComponentType.FieldGroup1,
          title: <LocalizedMessage id="usageDiscountIntervalTitle" />,
          columns: 1,
        },
      },
    ],
  },
];

const belgiumGasCampaignPapers: IFormPagePaper[] = [
  {
    sections: [electricityCampaignSection, ...belgiumGasCampaignSections],
  },
];

export const belgiumGasCampaignLayoutMap: CampaignFormLayoutMap = createCampaignFormLayout(
  categories.gas,
  Market.Belgium,
  belgiumGasCampaignPapers
);
