import * as React from 'react';
import type { Columns } from '../../fields/configs/columns';
import { GridContainer } from '../GridContainer';
import { ComponentsRow } from './ComponentsRow';

interface IProps {
  components: React.ReactNode[];
  columns: Columns;
}

export const ComponentsLayout: React.FunctionComponent<IProps> = ({ components, columns }) => {
  const rows: React.ReactNode[][] = [];
  components.forEach((_, index) => {
    if (shouldCreateRow(index, columns)) {
      rows.push(addRow(components, index, columns));
    }
  });

  return (
    <GridContainer>
      {rows.map((rowComponents, index) => (
        <ComponentsRow key={index} components={rowComponents} columns={columns} />
      ))}
    </GridContainer>
  );
};

const shouldCreateRow = (index: number, columns: number) => index % columns === 0;

// If index + columns is greater than the length of the array slice will return an array with the remaining components.
const addRow = (components: React.ReactNode[], index: number, columns: number) =>
  components.slice(index, index + columns);
