import { withLocalization } from '@provider-portal/internationalization';
import { selectorHasInternalAdminAccess } from '@provider-portal/login/duck';
import { AddSaveDeskOfferComponent } from '@provider-portal/saveDesk/AddSaveDeskOffer/component';
import { useFetchCancellationReasonFormDataOnEnter } from '@provider-portal/saveDesk/AddSaveDeskOffer/use-fetch';
import { Sentry } from '@provider-portal/sentry';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { approveSaveDeskOffer, createSaveDeskOffer } from '../api';
import type { ISaveDeskOffer2FormData, ISaveDeskOffer2FormViewModel } from '../models';
import { SaveDeskOffer2FormAction } from '../models';

interface IAddSaveDeskOfferProps {
  providerId: string;
}

const AddSaveDeskOfferInner: React.FunctionComponent<IAddSaveDeskOfferProps> = ({ providerId }) => {
  const history = useHistory();
  const [actionType, setActionType] = React.useState<SaveDeskOffer2FormAction>(SaveDeskOffer2FormAction.SAVE);
  const { loadingState, formData, setLoadingState } = useFetchCancellationReasonFormDataOnEnter(providerId);
  const hasAdminAccess = useSelector(selectorHasInternalAdminAccess);

  const onCreate: (viewModel: ISaveDeskOffer2FormViewModel) => Promise<void> = async (
    viewModel: ISaveDeskOffer2FormViewModel
  ) => {
    const disclaimerWithTranslationsOrUndefined =
      !!viewModel.disclaimer && Object.keys(viewModel.disclaimer).length > 0 ? viewModel.disclaimer : undefined;
    const formData = {
      cancellationReasons: viewModel.cancellationReasons,
      name: viewModel.name,
      description: viewModel.description,
      disclaimer: disclaimerWithTranslationsOrUndefined,
      action: actionType,
      externalOfferId: viewModel.externalOfferId,
    } as ISaveDeskOffer2FormData;
    try {
      const createdSaveDeskOffer = await createSaveDeskOffer(providerId, formData);
      if (actionType === SaveDeskOffer2FormAction.SAVE_AND_REQUEST_APPROVAL && hasAdminAccess) {
        await approveSaveDeskOffer(providerId, createdSaveDeskOffer.id);
      }
      const editSaveDeskOfferUrl = URI(history.location.pathname)
        .segment(-1, createdSaveDeskOffer.id || '')
        .toString();
      history.replace(editSaveDeskOfferUrl);
    } catch (error) {
      setLoadingState(PortalLoadingState.Failure);
      Sentry.captureExceptionWithMessage(error, 'Failed to create saveDeskOffer');
    }
  };

  return (
    <AddSaveDeskOfferComponent
      providerId={providerId}
      onGoBack={() => history.goBack()}
      setActionType={setActionType}
      loadingState={loadingState}
      formData={formData}
      hasAdminAccess={hasAdminAccess}
      onCreate={onCreate}
      setLoadingState={setLoadingState}
    />
  );
};

export const AddSaveDeskOffer = withLocalization('supplier-portal-save-desk')(AddSaveDeskOfferInner);
