import { DividerSubSection } from '@provider-portal/libs/uiFramework/components/dividers/DividerSubSection';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { RejectedReason } from '@provider-portal/models/order';
import type { ISwitchOrderState } from '@provider-portal/orders/switch/models';
import * as React from 'react';
import CancelIcon from '../../../icons/cancel.svg';
import styles from './styles.scss';

export interface IOrderRejectedProps {
  orderState: ISwitchOrderState;
}

export const OrderRejected: React.FunctionComponent<IOrderRejectedProps> = ({ orderState }) => {
  return (
    <>
      <div className={styles.orderOutcomeDescription}>
        <img src={CancelIcon} alt="cancel icon" />
        <Body1>{orderRejectedDescription(orderState)}</Body1>
      </div>
      {orderState.reason?.messageFromSupplier && (
        <div className={styles.rejectionReason}>
          <DividerSubSection />
          <Caption colorVariant="darker">
            <LocalizedMessage id="rejectionMessageLabel" />
          </Caption>
          <Body1>{orderState.reason.messageFromSupplier}</Body1>
        </div>
      )}
    </>
  );
};

function orderRejectedDescription(orderState: ISwitchOrderState) {
  switch (orderState.reason?.name || '') {
    case RejectedReason.HasBindingPeriod:
      return <LocalizedMessage id="orderRejectedWithBindingPeriod" />;
    case RejectedReason.AlreadyCustomer:
      return <LocalizedMessage id="orderRejectedAlreadyCustomer" />;
    case RejectedReason.CreditCheckFailed:
      return <LocalizedMessage id="orderRejectedCreditCheckFailed" />;
    case RejectedReason.UnknownAddress:
      return <LocalizedMessage id="orderRejectedUnknownAddress" />;
    case RejectedReason.Unknown:
      return <LocalizedMessage id="orderRejectedOtherReason" />;
    default:
      throw new Error('Reason expected for order state');
  }
}
