import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { Select } from '../../fields/Select';
import { LoadingComponent } from '../../LoadingComponent';
import type { IPartnerCampaign } from './api';
import { fetchCampaigns } from './api';
import type { ICampaignIdSelectWidget } from './config';
import { CampaignIdSelectFieldNames } from './config';

type ICampaignIdSelectProps = Omit<ICampaignIdSelectWidget, 'type'>;

const CampaignIdSelectInner: React.FunctionComponent<ICampaignIdSelectProps> = ({
  providerId,
  categoryName,
  campaignId,
}) => {
  const [campaigns, setCampaigns] = React.useState<IPartnerCampaign[]>([]);
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);

  React.useEffect(() => {
    fetchCampaigns(providerId, categoryName)
      .then((campaignsFetched) => {
        setCampaigns(campaignsFetched);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
  }, [providerId, categoryName]);

  return (
    <LoadingComponent loadingState={loadingState}>
      <Select
        label={<LocalizedMessage id="campaignLabel" />}
        name={CampaignIdSelectFieldNames.CAMPAIGN_ID}
        options={campaigns.map((campaign) => ({ label: campaign.name, value: campaign.id }))}
        defaultValue={campaignId}
      />
    </LoadingComponent>
  );
};

export const CampaignIdSelect = withLocalization('CampaignIdSelect')(CampaignIdSelectInner);
