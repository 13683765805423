import type { IProviderCategoryFormData } from '@provider-portal/provider/models';
import get from 'lodash/get';
import { ProviderFormFieldNames } from '../../config';
import type { IWidget } from '@provider-portal/libs/uiFramework/components/widgets/config';
import type { Market } from '@provider-portal/app-context/constants';

export function mapSectionWidgetConfig(
  index: number,
  categoryFormData: IProviderCategoryFormData,
  market: Market
): (config: IWidget) => IWidget {
  return (config: IWidget) => {
    if (config.name) {
      return {
        ...config,
        name: `${ProviderFormFieldNames.CATEGORIES}[${index}].${config.name}`,
        //We need to use lodash get here because we have property keys that can also be paths to object properties in the form data.
        //See for example category details that would have keys like `details.networkOperator`.
        defaultValue: get(categoryFormData, config.name),
        market,
      };
    } else {
      return {
        ...config,
        market,
      };
    }
  };
}
