import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { IUploadFile } from '../../fields/file/models';

export async function uploadTermsAndConditions(partnerSupplierId: string, file: File): Promise<IUploadFile> {
  const formData = new FormData();
  formData.append('file', file);

  return axios
    .post(`api/v1/suppliers/${partnerSupplierId}/products/terms-and-conditions`, formData)
    .then((response: AxiosResponse<IUploadFile>) => response.data);
}
