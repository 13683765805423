import { CheckedIcon } from '@provider-portal/assets/icons/CheckedIcon';
import { NotCheckedIcon } from '@provider-portal/assets/icons/NotCheckedIcon';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import * as React from 'react';
import styles from './styles.scss';

export interface IChecklistItem {
  label: React.ReactNode;
  checked: boolean;
}

export const ChecklistItem: React.FunctionComponent<IChecklistItem> = ({ label, checked }) => {
  return (
    <div className={styles.flexContainer}>
      {checked ? <CheckedIcon /> : <NotCheckedIcon />}
      <Body1>{label}</Body1>
    </div>
  );
};
