import SvgIcon from '@material-ui/core/SvgIcon';
import type { CSSProperties, ReactNode } from 'react';
import * as React from 'react';

export interface IconProps {
  children?: ReactNode;
  nativeColor?: string;
  viewBox?: string;
  size?: number;
  className?: string;
  style?: CSSProperties;
  onClick?: React.ReactEventHandler<any>;
  'data-test'?: string;
}

export const CampaignIcon: React.ComponentType<IconProps> = ({ nativeColor = '#1E1E1E', ...props }) => (
  <SvgIcon {...props}>
    <path
      fill="transparent"
      d="M4.5 1.35303H12L22.5 11.853M9.129 4.35303H3C2.60218 4.35303 2.22064 4.51106 1.93934 4.79237C1.65804 5.07367 1.5 5.4552 1.5 5.85303V11.981C1.49991 12.178 1.53863 12.3731 1.61396 12.5552C1.68928 12.7372 1.79973 12.9027 1.939 13.042L11.1 22.205C11.2775 22.3825 11.4966 22.5125 11.7374 22.5833C11.9782 22.6541 12.2329 22.6634 12.4781 22.6102C12.7234 22.5571 12.9514 22.4433 13.1413 22.2792C13.3312 22.1151 13.4769 21.906 13.565 21.671L15 17.853L18.819 16.42C19.054 16.3319 19.2631 16.1862 19.4272 15.9963C19.5913 15.8064 19.7051 15.5784 19.7582 15.3332C19.8114 15.0879 19.8021 14.8332 19.7313 14.5924C19.6605 14.3517 19.5304 14.1325 19.353 13.955L10.189 4.79203C9.9078 4.51101 9.52655 4.35311 9.129 4.35303ZM6 8.47803C5.92583 8.47803 5.85333 8.50002 5.79166 8.54123C5.72999 8.58243 5.68193 8.641 5.65355 8.70952C5.62516 8.77804 5.61774 8.85344 5.63221 8.92619C5.64668 8.99893 5.68239 9.06575 5.73484 9.11819C5.78728 9.17064 5.8541 9.20635 5.92684 9.22082C5.99958 9.23529 6.07498 9.22787 6.14351 9.19948C6.21203 9.1711 6.2706 9.12303 6.3118 9.06137C6.35301 8.9997 6.375 8.9272 6.375 8.85303C6.37513 8.80374 6.36552 8.75492 6.34672 8.70937C6.32792 8.66381 6.30031 8.62242 6.26546 8.58757C6.23061 8.55272 6.18922 8.5251 6.14366 8.5063C6.09811 8.48751 6.04928 8.4779 6 8.47803Z"
      stroke={nativeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
