import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { TabbedTableOverviewPage } from '@provider-portal/libs/uiFramework/components/pages/TabbedTableOverviewPage';
import { useTabs } from '@provider-portal/libs/hooks/use-tabs';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import type { History } from 'history';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { fetchProducts } from '../api';
import type { IPartnerProductOverViewModel } from '../models';
import { ProductState } from '../models';
import { getTabsConfig, ProductsListTab, productsListTableConfig } from './config';

export interface IProductsListProps {
  partnerSupplierId: string;
  category: string;
}

const ProductsListInner: React.FunctionComponent<IProductsListProps> = ({ partnerSupplierId, category }) => {
  const history = useHistory();
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);
  const [products, setProducts] = React.useState<IPartnerProductOverViewModel[]>([]);
  const [activeProducts, draftProducts] = productsGrouped(products);
  const tabsConfig = getTabsConfig(activeProducts.length, draftProducts.length);
  const [selectedTab, onSwitchTab] = useTabs(tabsConfig);

  React.useEffect(() => {
    fetchProducts(partnerSupplierId, category)
      .then((productsResponse) => {
        setProducts(productsResponse);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
  }, [partnerSupplierId, category]);

  return (
    <LoadingPage loadingState={loadingState}>
      <TabbedTableOverviewPage
        appBarText={<LocalizedMessage id="productsTitle" />}
        tabs={tabsConfig}
        table={productsListTableConfig}
        data={selectedTab === ProductsListTab.APPROVED ? activeProducts : draftProducts}
        selectedTab={selectedTab}
        onSwitchTab={onSwitchTab}
        onRowClick={onProductClick(history)}
        onAdd={onAdd(history)}
      />
    </LoadingPage>
  );
};

export const ProductsList: React.ComponentType<IProductsListProps> = withLocalization('EditProduct')(ProductsListInner);

function productsGrouped(
  products: IPartnerProductOverViewModel[]
): [IPartnerProductOverViewModel[], IPartnerProductOverViewModel[]] {
  return [
    products.filter((p) => p.state === ProductState.Approved),
    products.filter((p) => p.state === ProductState.Draft),
  ];
}

function onProductClick(history: History) {
  return (product: IPartnerProductOverViewModel) => {
    const productDetailsUrl = URI(history.location.pathname).segment(product.id).toString();
    history.push(productDetailsUrl);
  };
}

function onAdd(history: History) {
  return () => {
    const addProductUrl = URI(history.location.pathname).segment('add').toString();
    history.push(addProductUrl);
  };
}
