import { Checkbox } from '@provider-portal/libs/uiFramework/components/deprecated/Checkbox';
import { SelectField } from '@provider-portal/libs/uiFramework/components/deprecated/SelectField';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import compact from 'lodash/compact';
import head from 'lodash/head';
import tail from 'lodash/tail';
import * as React from 'react';
import { HiddenInput } from '../HiddenInput';
import { HiddenInputDataFormat } from '../HiddenInput/config';
import type { IOption } from '../Select/option';
import type { IMultiSelect } from './config';
import { FieldMessage } from '@provider-portal/libs/uiFramework/components/fields/FieldMessage';

export interface IMultiSelectProps extends Omit<IMultiSelect, 'type'> {
  defaultValue?: string[];
  backgroundColor?: string;
  disabled?: boolean;
}

export const MultiSelect: React.FunctionComponent<IMultiSelectProps> = (props) => {
  const [values, setValues] = React.useState(props.defaultValue || []);
  const inputStyles =
    (props.backgroundColor && { style: { background: props.backgroundColor, borderRadius: '8px' } }) || undefined;

  return (
    <>
      <SelectField
        disabled={props.disabled}
        name=""
        value={Array.from(values)}
        fullWidth
        multiple
        label={props.label}
        renderValue={(selected) => renderValue(props.options, props.allSelectedLabel, selected as string[])}
        onChange={onChange}
        SelectDisplayProps={inputStyles}
        validators={{ requiredValidator }}
      >
        {renderOptions(props.options, values)}
      </SelectField>
      <HiddenInput name={props.name} value={values} dataFormat={HiddenInputDataFormat.JSON} />
    </>
  );

  function onChange(event: any) {
    setValues(event.target.value);
  }

  function requiredValidator(value: string[]) {
    if (value.length !== 0) {
      return false;
    } else {
      return props.requiredErrorText ?? <FieldMessage id="portalRequiredField" />;
    }
  }
};

function renderOptions(options: IOption[], selected: string[]) {
  return options.map((option) => {
    const checked = selected.includes(option.value);

    return (
      <MenuItem key={option.value} value={option.value}>
        <Checkbox checked={checked} name={option.value} />
        <ListItemText primary={option.label} />
      </MenuItem>
    );
  });
}

function findOptionByValue(options: IOption[]): (value: string) => IOption | undefined {
  return (value: string) => {
    return options.find((o) => o.value === value);
  };
}

function renderValue(options: IOption[], allSelectedLabel?: React.ReactNode, selected?: string[]): React.ReactNode {
  if (selected?.length === options.length && allSelectedLabel) {
    return allSelectedLabel;
  } else {
    const optionsSelected = compact(selected?.sort().map(findOptionByValue(options)) || []);

    return (
      <>
        {head(optionsSelected)?.label}
        {tail(optionsSelected).map((option) => {
          return <React.Fragment key={option.value}>, {option.label}</React.Fragment>;
        })}
      </>
    );
  }
}
