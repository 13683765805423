import type { IImage } from '@provider-portal/libs/uiFramework/components/fields/Image/config';
import type { IWidget } from '../../widgets/config';
import { WidgetType } from '../../widgets/config';
import type { ICheckbox } from '../Checkbox/config';
import type { FactoryRequest, IField } from '../configs';
import { FieldType } from '../configs';
import type { ICentesimalCostInput } from '../cost/CentesimalCostInput/config';
import type { ICostInput } from '../cost/CostInput/config';
import type { IDateInput } from '../DateInput/config';
import type { IFieldArray } from '../FieldArray/config';
import type { IFileInput } from '../file/FileInput/config';
import type { IHiddenInput } from '../HiddenInput/config';
import type { IMultiSelect } from '../MultiSelect/config';
import type { INumberInput } from '../NumberInput/config';
import type { IRadioGroup } from '../RadioGroup/config';
import type { IRadioInput } from '../RadioInput/config';
import type { ISelect } from '../Select/config';
import type { IEmailInput, ITextInput, IUriInput } from '../TextInput/config';
import type { ITextValueArrayInput } from '../TextValueArrayInput/config';
import type { IUnitFieldFactoryInput } from '../UnitFieldFactory/config';

export function isField(factoryRequest: FactoryRequest): factoryRequest is IField {
  return Object.values(FieldType).includes((factoryRequest as IField).type);
}

export function isWidget(factoryRequest: FactoryRequest): factoryRequest is IWidget {
  return Object.values(WidgetType).includes((factoryRequest as IWidget).type);
}

export function isRadioInput(field: IField): field is IRadioInput {
  return (field as IRadioInput).type === FieldType.Radio;
}

export function isNumberInput(field: IField): field is INumberInput {
  return (field as INumberInput).type === FieldType.Number;
}

export function isMultiSelectInput(field: IField): field is IMultiSelect {
  return (field as IMultiSelect).type === FieldType.MultiSelect;
}

export function isTextInput(field: IField): field is ITextInput {
  return (field as ITextInput).type === FieldType.Text;
}

export function isFieldArray(field: IField): field is IFieldArray {
  return (field as IFieldArray).type === FieldType.FieldArray;
}

export function isSelectInput(field: IField): field is ISelect {
  return (field as ISelect).type === FieldType.Select;
}

export function isDateInput(field: IField): field is IDateInput {
  return (field as IDateInput).type === FieldType.Date;
}

export function isUriInput(field: IField): field is IUriInput {
  return (field as IUriInput).type === FieldType.Uri;
}

export function isEmailInput(field: IField): field is IEmailInput {
  return (field as IEmailInput).type === FieldType.Email;
}

export function isFileInput(field: IField): field is IFileInput {
  return (field as IFileInput).type === FieldType.File;
}

export function isHiddenInput(field: IField): field is IHiddenInput {
  return (field as IHiddenInput).type === FieldType.Hidden;
}

export function isCheckbox(field: IField): field is ICheckbox {
  return (field as ICheckbox).type === FieldType.Checkbox;
}

export function isRadioGroup(field: IField): field is IRadioGroup {
  return (field as IRadioGroup).type === FieldType.RadioGroup;
}

export function isCostInput(field: IField): field is ICostInput {
  return (field as ICostInput).type === FieldType.Cost;
}

export function isTextValueArrayInput(field: IField): field is ITextValueArrayInput {
  return (field as ITextValueArrayInput).type === FieldType.TextValueArray;
}

export function isCentesimalCostInput(field: IField): field is ICentesimalCostInput {
  return (field as ICentesimalCostInput).type === FieldType.CentesimalCost;
}

export function isUnitFieldFactory(field: IField): field is IUnitFieldFactoryInput {
  return (field as IUnitFieldFactoryInput).type === FieldType.UnitFieldFactory;
}

export function isImage(field: IField): field is IImage {
  return (field as IImage).type === FieldType.Image;
}
