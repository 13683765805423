import { LocalizedMessage } from '@provider-portal/internationalization';
import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput/config';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import * as React from 'react';

enum CampaignFormFieldNames {
  NAME = 'name',
  EXTERNAL_CAMPAIGN_ID = 'externalCampaignId',
}

export const makeCampaignSection = (tooltipText: React.ReactNode): IFieldSectionConfig => ({
  caption: <LocalizedMessage id="campaignsTitleV2" />,
  tooltipTitle: <LocalizedMessage id="campaignsTitleV2" />,
  tooltipText,
  columns: 2,
  components: [
    TextInput({
      name: CampaignFormFieldNames.NAME,
      label: <LocalizedMessage id="campaignName" />,
      tooltipTitle: <LocalizedMessage id="campaignName" />,
      tooltipText: <LocalizedMessage id="campaignNameTooltipText" />,
      required: true,
    }),
    TextInput({
      name: CampaignFormFieldNames.EXTERNAL_CAMPAIGN_ID,
      label: <LocalizedMessage id="campaignId" />,
      tooltipTitle: <LocalizedMessage id="campaignId" />,
      tooltipText: <LocalizedMessage id="campaignIdTooltipText" />,
    }),
  ],
});

export const mobileCampaignSection: IFieldSectionConfig = makeCampaignSection(
  <LocalizedMessage id="mobileCampaignTooltipText" />
);

export const electricityCampaignSection: IFieldSectionConfig = makeCampaignSection(
  <LocalizedMessage id="electricityCampaignTooltipText" />
);
