import React from 'react';
import type { EllipsisProps } from '../shared/ellipsis';
import { getEllipsisStyles } from '../shared/ellipsis';
import type { Omit, TypographyProps } from '../Typography';
import { Typography } from '../Typography';

export const Subtitle2: React.FunctionComponent<Subtitle2Props> = ({ style, ellipsis, maxWidth, ...otherProps }) => {
  /**
   * 0.875rem corresponds to 14px in Invision
   */
  const ellipsisStyles = getEllipsisStyles(maxWidth, ellipsis);
  const baseStyle = {
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '1.42em',
    ...ellipsisStyles,
    ...style,
  };

  return <Typography {...otherProps} variant="subtitle2" style={baseStyle} />;
};

type Subtitle2Props = Omit<TypographyProps, 'variant'> & EllipsisProps;
