import withStyles from '@material-ui/core/styles/withStyles';
import MUITableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import get from 'lodash/get';
import * as React from 'react';
import type { ITableCellConfig, ITableConfig } from './config';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles({
  root: { borderBottom: 'none' },
})(TableCell);

function renderTableCell(cell: ITableCellConfig, row: any): React.ReactNode {
  const columnValue: any = get(row, cell.key);
  const cellNode = cell.renderCellValue?.call(null, columnValue) || <Body1>{columnValue}</Body1>;

  if (cell.tooltip) {
    //The div element is required for the tooltip component to work.
    return (
      <Tooltip title={<Body1 textColor="alternative">{cell.tooltip(columnValue)}</Body1>}>
        <div>{cellNode}</div>
      </Tooltip>
    );
  } else {
    return cellNode;
  }
}

interface ITableBodyProps {
  config: ITableConfig;
  data: any;
  onRowClick?(values: any): void;
}

export const TableBody: React.FunctionComponent<ITableBodyProps> = ({ config, data, onRowClick }) => {
  const rowClicked = (row: any) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <MUITableBody>
      {data.map((row: any, index: number) => (
        <StyledTableRow
          key={index}
          style={onRowClick && { cursor: 'pointer' }}
          onClick={() => rowClicked(row)}
          hover={Boolean(onRowClick)}
        >
          {config.cells.map((cell) => (
            <StyledTableCell key={cell.key}>{renderTableCell(cell, row)}</StyledTableCell>
          ))}
          {onRowClick && (
            <StyledTableCell align="right">
              <ChevronRight color="primary" />
            </StyledTableCell>
          )}
        </StyledTableRow>
      ))}
    </MUITableBody>
  );
};
