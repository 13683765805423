import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { RadioGroup } from '@provider-portal/libs/uiFramework/components/deprecated/RadioButton';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { RejectedReason } from '@provider-portal/models/order';
import * as React from 'react';
import { RejectionOption } from './RejectionOption';
import styles from './styles.scss';

export interface IRejectOrderProps {
  rejectionReasons: RejectedReason[];
  updateInProgress: boolean;
}

export const RejectOrder: React.FunctionComponent<IRejectOrderProps> = ({ rejectionReasons, updateInProgress }) => {
  const [selectedReason, setSelectedReason] = React.useState(RejectedReason.HasBindingPeriod);
  const [messageToUser, setMessageToUser] = React.useState('');
  const [bindingPeriod, setBindingPeriod] = React.useState<Date | undefined>(undefined);

  return (
    <>
      <Subtitle1>
        <LocalizedMessage id="rejectOrderCardSubtitle" />
      </Subtitle1>
      <div className={styles.rejectionOptions}>
        <RadioGroup name="rejectionReason" value={selectedReason}>
          {rejectionReasons.map((reason) => (
            <RejectionOption
              key={reason}
              selected={reason === selectedReason}
              rejectionReason={reason}
              onSelected={setSelectedReason}
              messageToUser={messageToUser}
              onMessageChanged={setMessageToUser}
              bindingPeriod={(selectedReason === RejectedReason.HasBindingPeriod && bindingPeriod) || undefined}
              onBindingPeriodChanged={setBindingPeriod}
            />
          ))}
        </RadioGroup>
      </div>
      <Body1 style={{ marginBottom: '16px' }}>
        <LocalizedMessage id="submitOrderUpdateWarning" />
      </Body1>
      <Button
        label={<LocalizedMessage id="rejectOrderButtonText" />}
        color="negative"
        loading={updateInProgress}
        type="submit"
      />
    </>
  );
};
