import ErrorIconImageUrl from '@provider-portal/assets/icons/error-page-icon.svg';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { RaisedButton } from 'material-ui';
import React from 'react';
import styles from './styles.scss';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import { Locale } from '@provider-portal/internationalization/locale';
import { selectorLoggedInUserLocale } from '@provider-portal/login/duck';
import { useSelector } from 'react-redux';

export const ErrorLoading: React.FunctionComponent = () => {
  //ErrorLoading is shown if we couldn't load messages - so we need to bundle the messages
  function bundledMessages(userLocale: Locale) {
    switch (userLocale) {
      case Locale.svSE:
        return {
          somethingWentWrongTitle: 'Hmm, något gick fel',
          itIsNotYouItIsUsText: 'Var inte orolig, det är inte du, det är vi.',
          pleaseTryAgainText: 'Var god och prova igen. Funkar inte det, försök igen senare.',
          tryAgainButtonLabel: 'Prova igen',
        };
      case Locale.daDK:
        return {
          somethingWentWrongTitle: 'Hmm, noget gik galt',
          itIsNotYouItIsUsText: 'Bare rolig. Det er ikke dig, det er os.',
          pleaseTryAgainText: 'Prøv igen. Hvis det ikke virker, så prøv igen senere.',
          tryAgainButtonLabel: 'Prøv igen',
        };
      case Locale.frBE:
      case Locale.frFR:
        return {
          somethingWentWrongTitle: 'Nous avons rencontré un problème',
          itIsNotYouItIsUsText: "Pas d'inquiétude, ce n'est pas vous, c'est nous",
          pleaseTryAgainText: 'Veuillez réessayer. Si ça ne marche pas, réessayez plus tard',
          tryAgainButtonLabel: 'Veuillez réessayer plus tard',
        };
      case Locale.nlBE:
      case Locale.nlNL:
        return {
          somethingWentWrongTitle: 'Hmm, er is iets fout gegaan',
          itIsNotYouItIsUsText: 'Geen zorgen. Het ligt niet aan u, maar aan ons.',
          pleaseTryAgainText: 'Probeer het nog eens. Als het niet lukt, probeer het dan later.',
          tryAgainButtonLabel: 'Probeer het nog eens',
        };
      // TODO: add case for Locale.fiFI
      case Locale.enGB:
      default:
        return {
          somethingWentWrongTitle: 'Hmm, something went wrong',
          itIsNotYouItIsUsText: "Don't worry, it's not you, it's us.",
          pleaseTryAgainText: "Please try again. If that doesn't work, try again later.",
          tryAgainButtonLabel: 'Try again',
        };
    }
  }

  const userLocale = useSelector(selectorLoggedInUserLocale) || Locale.enGB;
  const messages = bundledMessages(userLocale);

  return (
    <Paper className={styles.container}>
      <div>
        <img src={ErrorIconImageUrl} className={styles['error-loading-image']} alt="error" />
        <Headline5 className={styles.header}>{messages.somethingWentWrongTitle}</Headline5>
        <Body1>
          {messages.itIsNotYouItIsUsText}
          <br />
          {messages.pleaseTryAgainText}
        </Body1>
      </div>
      <RaisedButton
        primary
        label={messages.tryAgainButtonLabel}
        onClick={() => window.location.reload()}
        className={styles['reload-button']}
      />
    </Paper>
  );
};
