import type { CategoryName, ICategoryProps } from '@provider-portal/constants/categories';
import type { Market } from '@provider-portal/app-context/constants';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { VerticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';
import { verticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';

export type CampaignFormLayoutMap = [VerticalMarketKey, IFormPagePaper[]];

export const createCampaignFormLayout = (
  categoryProps: ICategoryProps,
  market: Market,
  papers: IFormPagePaper[]
): CampaignFormLayoutMap => {
  return [verticalMarketKey(categoryProps.key as CategoryName, market), papers];
};
