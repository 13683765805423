import { Sentry } from '@provider-portal/sentry';
import { onEnter } from '@provider-portal/containers/onEnter';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { logoutServer } from '../login/api';
import { UserActions } from '../login/duck';

const LogoutRouteInner: React.FunctionComponent<RouteComponentProps> = (props) => {
  return <Redirect {...props} to={{ pathname: '/login' }} />;
};

export const LogoutRoute = compose<RouteComponentProps, RouteComponentProps>(
  onEnter((_: any, { store }: any) => {
    store.dispatch(UserActions.clearLogin());
    logoutServer().catch((error) => {
      Sentry.captureExceptionWithMessage(error, 'Failed to call logout api to destroy user session');
    });
  })
)(LogoutRouteInner);
