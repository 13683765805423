import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import logo from '@provider-portal/assets/minna-technologies-logo-white.svg';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { TextField } from '@provider-portal/libs/uiFramework/components/deprecated/TextField';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Headline3 } from '@provider-portal/libs/uiFramework/components/text/Headline3';
import {
  getSitekey,
  loadScriptByURL,
  RecaptchaAction,
  recaptchaScriptUrl,
} from '@provider-portal/login/LoginPage/recaptcha';
import { primary } from '@provider-portal/styles/colors-standard';
import React, { useCallback, useEffect, useState } from 'react';
import { UserActions } from '../duck';

const useStyles = makeStyles({
  centeringGrid: {
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundImage: primary.gradient,
  },
  fullWidthItem: {
    width: '100%',
  },
  minnaLogo: {
    position: 'absolute',
    bottom: '32px',
    right: '32px',
  },
});

const LoginPageInner = (): JSX.Element => {
  const classes = useStyles();

  const [usernameFieldValue, setUsernameFieldValue] = useState('');

  const [linkSent, setLinkSent] = useState<boolean>(false);

  const [recaptchaSitekey, setRecaptchaSitekey] = useState<string | undefined>();

  useEffect(() => {
    getSitekey().then((sitekey) => {
      loadScriptByURL('recaptcha-key', recaptchaScriptUrl(sitekey));
      setRecaptchaSitekey(sitekey);
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (recaptchaSitekey) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(recaptchaSitekey, { action: RecaptchaAction.Login }).then((token) => {
          setLinkSent(true);
          UserActions.requestLogin(usernameFieldValue, token);
        });
      });
    }
  }, [recaptchaSitekey, usernameFieldValue]);

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        classes={{ container: classes.centeringGrid }}
      >
        <Grid item>
          <Paper>
            <div style={{ width: '384px', padding: '24px 36px 24px 36px' }}>
              <Form onValidSubmit={handleSubmit}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Headline3>
                      <LocalizedMessage id="title" />
                    </Headline3>
                  </Grid>
                  <Grid item xs={12} classes={{ item: classes.fullWidthItem }}>
                    <TextField
                      name="username"
                      required
                      label={<LocalizedMessage id="username" />}
                      onChange={(event) => setUsernameFieldValue(event.target.value)}
                      value={usernameFieldValue}
                      fullWidth
                      disabled={linkSent}
                    />
                  </Grid>
                  {linkSent && (
                    <Grid item xs={12}>
                      <Body1 textColor="primary">
                        <LocalizedMessage id="linkSentInfo" />
                      </Body1>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button variant="contained" type="submit" color="primary" disabled={linkSent}>
                      <LocalizedMessage id="requestMagicLinkButton" />
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <img src={logo} alt="" role="presentation" className={classes.minnaLogo} />
    </div>
  );
};

export const LoginPage = withLocalization('LoginPage')(LoginPageInner);
