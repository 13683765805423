import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import * as React from 'react';
import PositiveCheckMarkIcon from '../../../icons/positive-check-mark.svg';
import styles from './styles.scss';

export const OrderAccepted: React.FunctionComponent = () => {
  return (
    <div className={styles.orderOutcomeDescription}>
      <img src={PositiveCheckMarkIcon} alt="green check mark" />
      <Body1>
        <LocalizedMessage id="orderAcceptedDescription" />
      </Body1>
    </div>
  );
};
