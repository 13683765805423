import { LocalizedMessage } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Tooltip } from '@provider-portal/libs/uiFramework/components/tooltip';
import * as React from 'react';
import { isOfferDraft, SaveDeskOffer2FormAction, SaveDeskOffer2State } from '../models';

interface IPublishButtonProps {
  onClick(submitType: SaveDeskOffer2FormAction): void;
  hasAdminAccess: boolean;
  offerState: SaveDeskOffer2State;
}

export const PublishButton: React.FunctionComponent<IPublishButtonProps> = ({
  onClick,
  hasAdminAccess,
  offerState,
}) => {
  const disableButtonWhenPendingApprovalForProvider =
    offerState === SaveDeskOffer2State.PendingApproval && !hasAdminAccess;
  const publishOrRequestApprovalButton =
    (isOfferDraft(offerState) && hasAdminAccess) || (offerState === SaveDeskOffer2State.Draft && !hasAdminAccess);
  const publishNewChangeButton = offerState === SaveDeskOffer2State.Approved;

  return (
    <>
      {disableButtonWhenPendingApprovalForProvider && (
        <Tooltip
          tooltipTitle={<LocalizedMessage id="offerUnderReview" />}
          tooltipText={<LocalizedMessage id="offerReviewingDescription" />}
        >
          <Button label={<LocalizedMessage id="saveAndRequestApprovalButton" />} color="positive" disabled />
        </Tooltip>
      )}
      {publishOrRequestApprovalButton && (
        <Button
          label={<LocalizedMessage id={hasAdminAccess ? 'publishButton' : 'saveAndRequestApprovalButton'} />}
          color="positive"
          type="submit"
          onClick={() => onClick(SaveDeskOffer2FormAction.SAVE_AND_REQUEST_APPROVAL)}
        />
      )}
      {publishNewChangeButton && (
        <Button
          label={<LocalizedMessage id={hasAdminAccess ? 'publishNewChangeButton' : 'saveAndRequestApprovalButton'} />}
          color="positive"
          type="submit"
          onClick={() => onClick(SaveDeskOffer2FormAction.SAVE_AND_REQUEST_APPROVAL)}
        />
      )}
    </>
  );
};
