//@ts-ignore
import * as uuid from 'uuid';
import type { IFieldArray } from './config';
import type { IFieldArrayItem } from './models';

export function createMapFromInitialItems(items: IFieldArrayItem[] = []) {
  return new Map(items.map((item) => [uuid.v4(), item]));
}

export function newItem(config: IFieldArray): IFieldArrayItem {
  return {
    fields: config.fields,
  };
}

export function addFieldArrayItem(
  items: Map<string, IFieldArrayItem>,
  config: IFieldArray
): Map<string, IFieldArrayItem> {
  const itemsCopy = new Map(items);
  itemsCopy.set(uuid.v4(), newItem(config));

  return itemsCopy;
}

export function removeFieldArrayItem(items: Map<string, IFieldArrayItem>, key: string): Map<string, IFieldArrayItem> {
  const itemsCopy = new Map(items);
  itemsCopy.delete(key);

  return itemsCopy;
}

export function fieldsWithIndexedNames(config: IFieldArray, index: number, item: IFieldArrayItem): IFieldArrayItem {
  return {
    fields: item.fields.map((field) => {
      return { ...field, name: `${config.name}[${index}].${field.name}` };
    }),
  };
}
