import type { InputValidationProps } from '@provider-portal/libs/uiFramework/components/deprecated/InputValidationHOC/types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import type { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { default as MuiSelectField } from '@material-ui/core/Select';
import omit from 'lodash/omit';
import values from 'lodash/values';
import type { ReactNode } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import { compose, defaultProps, mapProps } from 'recompose';
// @ts-ignore js import, remove this when the import is typed
import * as uuid from 'uuid';
import InputValidationHOC from '@provider-portal/libs/uiFramework/components/deprecated/InputValidationHOC';
// @ts-ignore js import, remove this when the import is typed
import InputLabel from './InputLabel';

interface ExtendedProps {
  transformBlurValue?(value: any, valid?: boolean, errors?: Record<string, unknown> | any[]): string;
  requiredErrorText?: ReactNode | string;
  validators?: any;
  showErrorOnBlur?(): void | boolean;
  showErrorOnChange?(): void | boolean;
  label?: string | ReactNode;
  helperText?: ReactNode | string;
}

interface SelectFieldState {
  readonly labelWidth: number;
}

class SelectFieldInner extends React.Component<SelectFieldInnerProps, SelectFieldState> {
  private InputLabelRef: any;

  public constructor(props: SelectFieldInnerProps) {
    super(props);
    this.state = { labelWidth: 0 };
  }

  public componentDidMount(): void {
    // eslint-disable-next-line react/no-find-dom-node
    const inputLabel = ReactDOM.findDOMNode(this.InputLabelRef) as any;

    if (inputLabel) {
      this.setState({
        labelWidth: inputLabel.offsetWidth,
      });
    }
  }

  public render() {
    const { errors, onChange, children, label, fullWidth, value, transformBlurValue, helperText, valid, focused } =
      this.props;
    const cleanProps: MuiSelectProps = omit(this.props, [
      'showErrorOnBlur',
      'showErrorOnChange',
      'canShowError',
      'formSubmitted',
      'defaultValue',
      'clear',
      'valid',
      'focused',
    ]);

    const outValue = transformBlurValue && !focused ? transformBlurValue(value, valid, errors) : value || '';
    const error = errors ? values(errors)[0] : null;
    const hasError = Boolean(error);
    const refId = uuid.v4();

    return (
      <div style={{ margin: '8px 0' }}>
        <FormControl variant="outlined" fullWidth={fullWidth} error={hasError}>
          <InputLabel
            ref={(ref: any) => {
              this.InputLabelRef = ref;
            }}
            htmlFor={refId}
          >
            {label}
          </InputLabel>
          <MuiSelectField
            {...cleanProps}
            value={outValue}
            onChange={onChange}
            input={<OutlinedInput labelWidth={this.state.labelWidth} name={refId} id={refId} />}
          >
            {children}
          </MuiSelectField>
          {error && <FormHelperText>{error}</FormHelperText>}
          {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </div>
    );
  }
}

const requiredToValidatorsProps = mapProps(({ requiredErrorText, validators, ...props }: SelectFieldInnerProps) => {
  if (requiredErrorText) {
    const updatedValidators = {
      required(value: boolean) {
        return value ? false : requiredErrorText;
      },
      ...validators,
    };

    return { validators: updatedValidators, ...props };
  } else {
    return { validators, ...props };
  }
});

const inputValidationProps = defaultProps({
  showErrorOnBlur: true,
});

type SelectFieldInnerProps = InputValidationProps & SelectFieldProps;
export type SelectFieldProps = ExtendedProps & MuiSelectProps;

export const SelectField = compose<SelectFieldInnerProps, SelectFieldProps>(
  requiredToValidatorsProps,
  inputValidationProps,
  InputValidationHOC
)(SelectFieldInner);
SelectField.displayName = 'SelectField';
