import ChevronRight from '@material-ui/icons/ChevronRight';
import { colors } from '@provider-portal/styles/colors';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { DividerSection } from '../dividers/DividerSection';
import { Paper } from '../panels/Paper';
import { Headline5 } from '../text/Headline5';
import styles from './styles.scss';

interface IMenuItem {
  path: string;
  menuLabel: React.ReactNode;
  active: boolean;
}

interface INavigationMenuProps {
  baseUrl: string;
  menuItems: IMenuItem[];
}

export const NavigationMenu: React.FunctionComponent<INavigationMenuProps> = ({ menuItems, baseUrl }) => {
  return (
    <Paper>
      <ul className={styles.menu}>
        {menuItems.map((menuItem, index) => {
          return (
            <li key={menuItem.path}>
              {index > 0 && <DividerSection />}
              <Link to={`${baseUrl}/${menuItem.path}`} style={{ textDecoration: 'none' }}>
                <div className={styles.menuItem}>
                  <Headline5 style={{ color: menuItem.active ? colors.black : colors.grey2 }}>
                    {menuItem.menuLabel}
                  </Headline5>
                  <ChevronRight style={{ fill: menuItem.active ? colors.black : colors.grey2 }} />
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </Paper>
  );
};
