import { Headline3 } from '@provider-portal/libs/uiFramework/components/text/Headline3';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import * as React from 'react';
import { ContentCricket } from '@provider-portal/libs/uiFramework/components/ContentCricket';

interface IStyles {
  title: React.CSSProperties;
}

const styles: IStyles = {
  title: {
    marginBottom: '8px',
  },
};

export const NoContent = withLocalization('SupplierPortal/NoContent')(() => {
  return (
    <ContentCricket>
      <Headline3 style={styles.title}>
        <LocalizedMessage id="noContentTitle" />
      </Headline3>
    </ContentCricket>
  );
});
