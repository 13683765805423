import { QuestionIcon } from '@provider-portal/assets/icons/QuestionIcon';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import { Tooltip } from '@provider-portal/libs/uiFramework/components/tooltip';
import * as React from 'react';
import { DividerSection } from '../../dividers/DividerSection';
import styles from './styles.scss';

export type SectionDivider = 'top' | 'bottom' | 'all';

interface IProps {
  caption?: string | React.ReactNode;
  captionText?: string | React.ReactNode;
  icon?: React.ReactNode;
  tooltipTitle?: React.ReactNode;
  tooltipText?: React.ReactNode;
  tooltiplinkText?: React.ReactNode;
  tooltiplinkUrl?: string;
  sectionNumber?: React.ReactNode;
  divider?: SectionDivider;
  children?: React.ReactNode;
}

export const Section: React.FunctionComponent<IProps> = ({
  caption,
  captionText,
  tooltiplinkText,
  tooltiplinkUrl,
  tooltipTitle,
  tooltipText,
  children,
  sectionNumber,
  icon,
  divider,
}) => {
  return (
    <>
      {(divider === 'top' || divider === 'all') && <DividerSection />}
      <div className={styles.section}>
        {(caption || sectionNumber || icon) && (
          <div className={styles.title}>
            {icon && <span className={styles.icon}>{icon}</span>}
            {sectionNumber}
            {caption && <Subtitle1>{caption}</Subtitle1>}
            {tooltipTitle && tooltipText && (
              <span style={{ marginTop: '6px', marginLeft: '8px' }}>
                <Tooltip
                  tooltiplinkText={tooltiplinkText}
                  tooltiplinkUrl={tooltiplinkUrl}
                  tooltipTitle={tooltipTitle}
                  tooltipText={tooltipText}
                >
                  <QuestionIcon />
                </Tooltip>
              </span>
            )}
          </div>
        )}
        {captionText && (
          <div className={styles.titleText}>
            <Body colorVariant="lighter">{captionText}</Body>
          </div>
        )}
        {children}
      </div>
      {(divider === 'bottom' || divider === 'all') && <DividerSection />}
    </>
  );
};
