import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { SettingsPage } from '.';

export const SettingsRoute: React.FunctionComponent<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <Route path={`${props.match.path}`} render={() => <SettingsPage {...props} />} />
    </Switch>
  );
};
