import { isValidEmail, isValidUri } from '@provider-portal/libs/validation';
import Chip from '@material-ui/core/Chip';
import * as React from 'react';
import { FieldMessage } from '../FieldMessage';
import { HiddenInput } from '../HiddenInput';
import { HiddenInputDataFormat } from '../HiddenInput/config';
import { TextInput, TextInputVariant } from '../TextInput';
import type { ITextValueArrayInput } from './config';
import styles from './styles.scss';

interface ITextValueArrayInputProps extends Omit<ITextValueArrayInput, 'type'> {
  defaultValue?: string[];
  backgroundColor?: string;
  disabled?: boolean;
}

const ENTER = 13;

export const TextValueArrayInput: React.FunctionComponent<ITextValueArrayInputProps> = (props) => {
  const [value, setValue] = React.useState<string>('');
  const [chips, setChips] = React.useState<string[]>(props.defaultValue || []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const addChip = (chip: string) => {
    if (chip && isValidChip(chip, props.inputVariant)) {
      const chipsAsSet = new Set(chips);
      chipsAsSet.add(chip);
      setChips(Array.from(chipsAsSet));
      setValue('');
    }
  };

  const removeChip = (chip: string) => {
    const chipsAsSet = new Set(chips);
    chipsAsSet.delete(chip);
    setChips(Array.from(chipsAsSet));
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ([ENTER].includes(event.keyCode)) {
      event.preventDefault();
      addChip(value.trim());
    }
  };

  return (
    <>
      <TextInput
        name=""
        variant={props.inputVariant}
        label={props.label}
        disabled={props.disabled}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
        helperText={<FieldMessage id="textFieldArrayHelperText" />}
      />
      <HiddenInput name={props.name} value={chips} dataFormat={HiddenInputDataFormat.JSON} />
      <div className={styles.chips}>
        {chips.map((chip) => (
          <Chip key={chip} label={chip} onDelete={() => removeChip(chip)} />
        ))}
      </div>
    </>
  );
};

function isValidChip(chip: string, variant?: TextInputVariant) {
  if (variant === TextInputVariant.EMAIL) {
    return isValidEmail(chip);
  } else if (variant === TextInputVariant.URI) {
    return isValidUri(chip);
  } else {
    return true;
  }
}
