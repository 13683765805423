import { LocalizedMessage } from '@provider-portal/internationalization';
import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import type { ITabConfig } from '@provider-portal/libs/uiFramework/components/Tabs/config';
import * as React from 'react';

export enum CampaignsListTab {
  APPROVED = 'approved',
  DRAFTS = 'drafts',
}

export const campaignsListTableConfig: ITableConfig = {
  cells: [
    {
      key: 'name',
      label: <LocalizedMessage id="campaignsTitleV2" />,
    },
    {
      key: 'externalCampaignId',
      label: <LocalizedMessage id="campaignId" />,
    },
    {
      key: 'state',
      label: <LocalizedMessage id="state" />,
    },
  ],
  defaultSortColumn: 'name',
};

export const getTabsConfig = (
  activeCampaignsCount: number,
  savedDraftsCount: number
): ITabConfig<CampaignsListTab>[] => [
  {
    key: CampaignsListTab.APPROVED,
    label: <LocalizedMessage id="activeCampaigns" values={{ activeCampaignsCount }} />,
  },
  {
    key: CampaignsListTab.DRAFTS,
    label: <LocalizedMessage id="savedDrafts" values={{ savedDraftsCount }} />,
  },
];
