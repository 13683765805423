import { DateFilterPeriod } from '@provider-portal/orders/switch/ListSwitchOrders/DateFilterButton/index';
import type { DateFilterSetting } from '@provider-portal/orders/switch/ListSwitchOrders/DateFilterButton/index';
import moment from 'moment';
import type React from 'react';

export interface CreateDateLabelTextProps {
  defaultText: React.ReactNode;
  datePeriod: DateFilterSetting;
  now?: Date;
}

export function createDateLabelText({ defaultText, datePeriod, now = new Date() }: CreateDateLabelTextProps) {
  switch (datePeriod.period) {
    case DateFilterPeriod.Last10Days:
      return dateTextBetween(moment(now).subtract(10, 'days').toDate(), now);
    case DateFilterPeriod.Last30Days:
      return dateTextBetween(moment(now).subtract(30, 'days').toDate(), now);
    case DateFilterPeriod.Custom:
      return dateTextBetween(moment(datePeriod.startDate).toDate(), moment(datePeriod.endDate).toDate());
    default:
      return defaultText;
  }
}

function dateTextBetween(startDate: Date, endDate: Date): string {
  return `${moment(startDate).format('DD/MM/YY')} - ${moment(endDate).format('DD/MM/YY')}`;
}
