import type { LocalizeCurrency } from '@provider-portal/internationalization';
import type { Currency } from '@provider-portal/models/subscription';
import { localizeCentesimal } from '@provider-portal/libs/uiFramework/components/fields/cost/localizeCentesimal';
import PropTypes from 'prop-types';
import React from 'react';
import type { IInputField } from '../configs';
import { CentesimalCostInput } from '../cost/CentesimalCostInput';
import { FieldMessage } from '../FieldMessage';
import { MeasurementUnit } from '../MeasurementUnit';
import { Select } from '../Select';
import { ValueUnitInput } from '../ValueUnitInput';
import type { IUnitFieldFactoryInput, IUnitFieldFactoryInputDefaultValue, UnitFieldFactoryType } from './config';
import { isCostUnitDefaultValue } from './config';
import type { Locale } from '@provider-portal/internationalization/locale';

type IProps = Omit<IUnitFieldFactoryInput, 'type'>;

export const UnitFieldFactory: React.FunctionComponent<IProps> = (
  { unit, units, unitFieldFactoryType, ...props },
  { localizeCurrency }
) => {
  const [selectedUnit, setSelectedUnit] = React.useState<MeasurementUnit>(props.defaultValue?.unit || unit);
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUnit(event.target.value as MeasurementUnit);
  };

  const fromUnitToOption = creatUnitOptionMapper(props.currency, unitFieldFactoryType, localizeCurrency);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flexBasis: '80px', marginRight: '16px' }}>
        <Select
          name=""
          required
          backgroundColor="white"
          defaultValue={selectedUnit}
          label={<FieldMessage id="unit" />}
          options={units.map(fromUnitToOption)}
          onChange={onChange}
        />
      </div>
      <div style={{ flex: '1' }}>{getField(selectedUnit, props, unitFieldFactoryType)}</div>
    </div>
  );
};

UnitFieldFactory.contextTypes = {
  localizeCurrency: PropTypes.func.isRequired,
};

function creatUnitOptionMapper(
  currency: Currency,
  unitFieldFactoryType: UnitFieldFactoryType,
  localizeCurrency: LocalizeCurrency
) {
  const localizedCurrency = unitFieldFactoryType === 'cent' ? localizeCentesimal(currency) : localizeCurrency(currency);

  // eslint-disable-next-line react/display-name
  return (costUnit: MeasurementUnit) => {
    switch (costUnit) {
      case MeasurementUnit.YEAR:
        return {
          label: <FieldMessage id="year" />,
          value: MeasurementUnit.YEAR,
        };

      case MeasurementUnit.KWH: {
        return {
          label: isSomeCurrency(unitFieldFactoryType)
            ? `${localizedCurrency} / ${MeasurementUnit.KWH}`
            : MeasurementUnit.KWH,
          value: MeasurementUnit.KWH,
        };
      }

      case MeasurementUnit.PERCENT: {
        return {
          label: MeasurementUnit.PERCENT,
          value: MeasurementUnit.PERCENT,
        };
      }

      case MeasurementUnit.MEGABYTE: {
        return {
          label: MeasurementUnit.MEGABYTE,
          value: MeasurementUnit.MEGABYTE,
        };
      }
    }

    throw new Error(`No option specified for unit ${costUnit}`);
  };
}

function isSomeCurrency(unitFieldFactoryType: UnitFieldFactoryType) {
  return unitFieldFactoryType === 'cost' || unitFieldFactoryType === 'cent';
}

function getDefaultValue(
  props: IInputField & { defaultValue?: IUnitFieldFactoryInputDefaultValue },
  unit: MeasurementUnit
) {
  if (!props.defaultValue || unit !== props.defaultValue.unit) return undefined;

  return props.defaultValue;
}

const getField = (
  unit: MeasurementUnit,
  props: IInputField & { defaultValue?: IUnitFieldFactoryInputDefaultValue; currency: Currency; locale: Locale },
  fieldType?: UnitFieldFactoryType
) => {
  const defaultValue = getDefaultValue(props, unit);

  if (
    unit === MeasurementUnit.KWH &&
    fieldType === 'cent' &&
    (defaultValue === undefined || isCostUnitDefaultValue(defaultValue))
  ) {
    const costInputDefaultValue = defaultValue && {
      amount: defaultValue.amount,
      currency: defaultValue.currency,
    };

    return (
      <CentesimalCostInput
        {...props}
        unit={unit}
        defaultValue={costInputDefaultValue}
        currency={props.currency}
        locale={props.locale}
      />
    );
  } else {
    const valueUnitDefaultValue = defaultValue && defaultValue.amount;

    return <ValueUnitInput {...props} unit={unit} defaultValue={valueUnitDefaultValue} locale={props.locale} />;
  }
};
