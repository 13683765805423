import { LocalizedMessage } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Dialog } from '@provider-portal/libs/uiFramework/components/dialogs/Dialog';
import * as React from 'react';
import { Market } from '@provider-portal/app-context/constants';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select';
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { useHistory } from 'react-router-dom';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const SelectMarketDialog: React.FunctionComponent<IProps> = (props) => {
  // Unable to wrap Dialog with form (button has to be inside of form), likely since dialog component is moved within the DOM.
  // Needed to manually trigger validation.
  const formRef = React.useRef<Form>(null);
  const [market, setMarket] = React.useState<Market | undefined>();
  const history = useHistory();
  const onSubmit = () => {
    if (formRef.current?.validateAsSubmitted()) {
      history.push(`/admin/suppliers/add?market=${market}`);
    }
  };

  return (
    <Dialog
      title={<LocalizedMessage id="selectMarket" />}
      onClose={props.onClose}
      open={props.open}
      topButton={
        <Button label={<LocalizedMessage id="selectMarket" />} color="positive" fullWidth onClick={onSubmit} />
      }
    >
      <Form ref={formRef}>
        <Select
          name="market"
          options={Object.values(Market).map((market) => ({ label: market, value: market }))}
          required
          value={market}
          onChange={(e) => setMarket(e.target.value as Market)}
        />
      </Form>
    </Dialog>
  );
};
