import type { Market } from '@provider-portal/app-context/constants';
import { parseForm } from '@provider-portal/libs/uiFramework/components/fields/configs/parse-fields';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import * as React from 'react';
import type { IProviderFormData } from '../models';
import { ProviderFormComponent } from './component';
import { useFetchResources } from './use-fetch-resources';

interface IProviderFormProps {
  market: Market;
  appBarTitle?: React.ReactNode;
  onSubmit(data: IProviderFormData): Promise<void>;
  formData?: IProviderFormData;
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
}

export const ProviderForm: React.FunctionComponent<IProviderFormProps> = ({
  market,
  appBarTitle,
  onSubmit,
  formData = {},
  leftSidedButtons,
  rightSidedButtons,
}) => {
  const { loadingState, subscriptionSuppliers, orderServices, supplierServices } = useFetchResources();

  return (
    <LoadingPage loadingState={loadingState}>
      <ProviderFormComponent
        market={market}
        appBarTitle={appBarTitle}
        subscriptionSuppliers={subscriptionSuppliers}
        onSubmit={async (event) => onSubmit(parseForm(event.currentTarget))}
        formData={formData}
        supplierServices={supplierServices}
        orderServices={orderServices}
        leftSidedButtons={leftSidedButtons}
        rightSidedButtons={rightSidedButtons}
      />
    </LoadingPage>
  );
};
