import { reduxMiddleware as sentryReduxMiddleware } from '@provider-portal/sentry';
import type { AppContext } from '@provider-portal/app-context';
import { reducer as appContext } from '@provider-portal/app-context/duck';
import type { ChangeState } from '@provider-portal/change/duck';
import { reducer as changeReducer } from '@provider-portal/change/duck';
import type { PartnerSupplierState } from '@provider-portal/PartnerSupplier/duck';
import type { SaveDeskState } from '@provider-portal/saveDesk/duck';
import { reducer as saveDeskReducer } from '@provider-portal/saveDesk/duck';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import type { InternationalizationState } from '@provider-portal/internationalization/duck';
import { reducer as internationalization } from '@provider-portal/internationalization/duck';
import type { IFormState } from '@provider-portal/libs/uiFramework/components/fields/Form/duck';
import { formReducer } from '@provider-portal/libs/uiFramework/components/fields/Form/duck';
import type { UserState } from './login/duck';
import { reducer as userReducer } from './login/duck';
import { reducer as partnerSupplierReducer } from './PartnerSupplier/duck';
import type { ISettingsState } from './settings/duck';
import { reducer as settingsReducer } from './settings/duck';
import { userManagementReducer } from './UserManagement';
import type { UserManagementState } from './UserManagement/duck';
import type { ChangePlanState } from './features/changePlan/duck';

const composeEnhancers = composeWithDevTools({});

export interface ApplicationState {
  user: UserState;
  appContext: AppContext;
  internationalization: InternationalizationState;
  partnerSupplier: PartnerSupplierState;
  userManagement: UserManagementState;
  saveDesk: SaveDeskState;
  changePlan: ChangePlanState;
  change: ChangeState;
  settings: ISettingsState;
  form: IFormState;
}

const reducers = {
  appContext,
  internationalization,
  user: userReducer,
  partnerSupplier: partnerSupplierReducer,
  userManagement: userManagementReducer,
  saveDesk: saveDeskReducer,
  change: changeReducer,
  settings: settingsReducer,
  form: formReducer,
};

const rootReducer = combineReducers({ ...reducers });

export const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(sentryReduxMiddleware(), thunkMiddleware, reduxPackMiddleware, promiseMiddleware()))
);
