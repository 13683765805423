import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import * as React from 'react';

interface IProps {
  onChange?(e: any): void;
  label: string | React.ReactNode;
  checked?: boolean;
  value?: string;
  disabled?: boolean;
}

export const RadioInput: React.FunctionComponent<IProps> = ({ onChange, label, checked, value, disabled }) => {
  return (
    <FormControlLabel
      onChange={onChange}
      label={label}
      checked={checked}
      value={value}
      control={<Radio disabled={disabled} />}
    />
  );
};
