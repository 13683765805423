import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { CheckCircleIcon } from '@minna-technologies/minna-ui/icons/CheckCircle';
import { MinnaIcon } from '@minna-technologies/minna-ui/icons/Minna';
import { Market } from '@provider-portal/app-context/constants';
import { ExternalLinks } from '@provider-portal/constants/externalLinks';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import { selectorMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';
import { CancellationStatistics } from '@provider-portal/statistics/CancellationStatistics';
import startsWith from 'lodash/startsWith';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  flexGap: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  pageContent: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  welcomeCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  welcomePointTextWithLink: {
    display: 'flex',
    gap: '7px',
  },
  link: {
    color: theme.colors.action.primary,
    fontWeight: 'bold',
  },
}));

interface HomePageProps {
  path: string;
  merchantId: string;
}

const HomePageInner = ({ path, merchantId }: HomePageProps) => {
  const { colors } = useTheme();
  const classes = useStyles();
  const maybePartnerSupplier = useSelector(selectorMaybePartnerSupplier);
  const maybeMarket = maybePartnerSupplier?.market;

  const pathPrefix = startsWith(path, '/admin') ? '/admin/suppliers' : '/suppliers';
  const retentionPagePath = `${pathPrefix}/${merchantId}/retention`;

  return (
    <Page
      appBarText={
        <div className={classes.flexGap}>
          <MinnaIcon nativeColor={colors.base.surface} />
          <Headline5 textColor="alternative">
            <LocalizedMessage id="title" />
          </Headline5>
        </div>
      }
      variant="details"
      showMenuForSmallerScreen
      showBackButton={false}
    >
      <div className={classes.pageContent}>
        <header>
          <Headline1>
            <LocalizedMessage id="header" />
          </Headline1>
        </header>
        <Card className={classes.welcomeCard}>
          <Headline3>
            <LocalizedMessage id="welcomeTitle" />
          </Headline3>
          <Body>
            <LocalizedMessage id="welcomeMessage" />
          </Body>
          <div className={classes.flexGap}>
            <CheckCircleIcon nativeColor={colors.action.primary} bold />
            <Body>
              <LocalizedMessage id="welcomePoint1" />
            </Body>
          </div>
          <div className={classes.flexGap}>
            <CheckCircleIcon nativeColor={colors.action.primary} bold />
            <Body className={classes.welcomePointTextWithLink}>
              <LocalizedMessage id="welcomePoint2" />
              <Link
                className={classes.link}
                to={{ pathname: ExternalLinks.ApiDocumentation }}
                target="_blank"
                rel="noreferrer"
              >
                Merchant API
              </Link>
            </Body>
          </div>
          <div className={classes.flexGap}>
            <CheckCircleIcon nativeColor={colors.action.primary} bold />
            <Body className={classes.welcomePointTextWithLink}>
              <LocalizedMessage id="welcomePoint3" />
              <Link className={classes.link} to={retentionPagePath}>
                Retention Solution
              </Link>
            </Body>
          </div>
        </Card>
        {maybeMarket === Market.UnitedKingdom && (
          <>
            <Headline2>
              <LocalizedMessage id="cancellationsSection" />
            </Headline2>
            <CancellationStatistics supplierId={merchantId} />
          </>
        )}
      </div>
    </Page>
  );
};

export const HomePage = withLocalization('HomePage')(HomePageInner);
