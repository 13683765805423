import React from 'react';
import type { EllipsisProps } from '../shared/ellipsis';
import { getEllipsisStyles } from '../shared/ellipsis';
import type { Omit, TypographyProps } from '../Typography';
import { Typography } from '../Typography';

export const Body: React.FunctionComponent<BodyProps> = ({ style, variant, ellipsis, maxWidth, ...otherProps }) => {
  /**
   * 0.875rem corresponds to 14px in Invision
   */
  const ellipsisStyles = getEllipsisStyles(maxWidth, ellipsis);

  const baseStyle = {
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: '1.25em',
    textTransform: 'none',
  };

  const linkStyle = variant === 'link' && {
    textDecoration: 'underline',
    cursor: 'pointer',
  };

  const boldStyle = variant === 'bold' && {
    fontWeight: 'bold',
  };

  const italicStyle = variant === 'italic' && {
    fontStyle: 'italic',
  };

  const strikeThroughStyle = variant === 'strikethrough' && {
    textDecorationLine: 'line-through',
  };

  const combinedStyle: React.CSSProperties = {
    ...baseStyle,
    ...linkStyle,
    ...boldStyle,
    ...italicStyle,
    ...strikeThroughStyle,
    ...ellipsisStyles,
    ...style,
  } as React.CSSProperties;

  return <Typography {...otherProps} variant="body1" style={combinedStyle} />;
};

type BodyProps = Omit<TypographyProps, 'variant'> & {
  variant?: 'link' | 'italic' | 'bold' | 'strikethrough';
} & EllipsisProps;
