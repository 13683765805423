import type { Market } from '@provider-portal/app-context/constants';
import type { Locale } from '@provider-portal/internationalization/locale';
import { WidgetType } from '@provider-portal/libs/uiFramework/components/widgets/config';
import type { EnrichMap, EnrichWithFormData, IEnrichProps } from '@provider-portal/libs/uiFramework/enrichRequest';
import { widgetConstructor } from '../../fields/configs';

export interface ITranslatableFieldWidget {
  type: WidgetType.TRANSLATABLE_FIELD;
  providerId?: string;
  market?: Market;
  description?: React.ReactNode;
  title: React.ReactNode;
  defaultValue?: TextByLanguage;
  multiline?: boolean;
  caption?: React.ReactNode;
  name: string;
  required?: boolean;
  maxlength?: number;
  tooltipTitle?: React.ReactNode;
  tooltipText?: React.ReactNode;
}

export type TextByLanguage = Map<Locale, string>;

export const MIDDLE_DIVIDING_MARGIN = '12px';
export const TranslatableFieldWidget = widgetConstructor<ITranslatableFieldWidget>(WidgetType.TRANSLATABLE_FIELD);

const translateableFieldEnricher: EnrichWithFormData = (props: IEnrichProps<any>) => {
  return {
    ...props.config,
    defaultValue: props.config.name && props.formData[props.config.name],
    providerId: props.providerId,
  };
};

export const translateableFieldEnricherMap: EnrichMap = [WidgetType.TRANSLATABLE_FIELD, translateableFieldEnricher];
