// @ts-ignore js import, remove this when the import is typed
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import { dispatchBeforeConnect } from '@provider-portal/containers/container-helpers';
import { withLocalization } from '@provider-portal/internationalization';
import { handleFetchable } from '@provider-portal/fetching/handleFetchable';
import type { ApplicationState } from '@provider-portal/store';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import { deleteUser } from '../api';
import type { Actions } from '../duck';
import { selectorFetchableUser, UserManagementActions } from '../duck';
import type { ViewUserComponentProps } from './component';
import { ViewUserComponent } from './component';

function mapStateToProps(state: ApplicationState, props: ViewUserProps & RouteComponentProps) {
  return {
    user: selectorFetchableUser(state),
    onDeleteUser: debouncePromise(async () => {
      return deleteUser(props.userId).then(() => props.history.replace('/admin/users'));
    }),
  };
}

export interface ViewUserProps {
  userId: string;
}

export const ViewUser = compose<ViewUserComponentProps, ViewUserProps>(
  dispatchBeforeConnect((_: ApplicationState, dispatch: Dispatch<Actions>, props: ViewUserProps) => {
    dispatch(UserManagementActions.fetchUser(props.userId));
  }),
  withRouter,
  connect(mapStateToProps),
  withLocalization('SupplierPortal/UserManagement'),
  handleFetchable('user')
)(ViewUserComponent);
