import Fab from '@material-ui/core/Fab';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AddIcon } from '@provider-portal/libs/uiFramework/components/deprecated/AddIcon';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Subtitle2 } from '@provider-portal/libs/uiFramework/components/text/Subtitle2';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import type { PartnerSupplier } from '@provider-portal/PartnerSupplier/models';
import { negative, positive, purple, white } from '@provider-portal/styles/colors-standard';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import * as React from 'react';
import { SelectMarketDialog } from './SelectMarketDialog';

const styles = () =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: 88,
      right: 32,
      backgroundColor: purple.main,
    },
  });

const SupplierListTableHead: React.FunctionComponent<WithStyles<typeof styles>> = () => (
  <TableRow>
    <TableCell>
      <Subtitle2>
        <LocalizedMessage id="supplierName" />
      </Subtitle2>
    </TableCell>
    <TableCell>
      <Subtitle2>
        <LocalizedMessage id="categories" />
      </Subtitle2>
    </TableCell>
    <TableCell>
      <Subtitle2>
        <LocalizedMessage id="market" />
      </Subtitle2>
    </TableCell>
    <TableCell>
      <Subtitle2>
        <LocalizedMessage id="state" />
      </Subtitle2>
    </TableCell>
  </TableRow>
);

const SupplierListRow: React.FunctionComponent<SupplierListRowProps & WithStyles<typeof styles>> = ({
  supplier,
  goToEditSupplierPage,
}) => {
  return (
    <TableRow
      style={{ cursor: 'pointer' }}
      key={supplier.id}
      onClick={() => goToEditSupplierPage(supplier.id)}
      hover
      data-test={supplier.id}
    >
      <TableCell>
        <Body ellipsis maxWidth="400px">
          {supplier.name}
        </Body>
      </TableCell>
      <TableCell>
        {!isEmpty(supplier.verticals) &&
          Object.keys(supplier.verticals).map((verticalName: string) => <Body key={verticalName}>{verticalName}</Body>)}
      </TableCell>
      <TableCell>
        <Body>{supplier.market}</Body>
      </TableCell>
      <TableCell>
        <Body style={{ color: supplier.isActive ? positive.main : negative.main }}>
          {supplier.isActive ? <LocalizedMessage id="active" /> : <LocalizedMessage id="inActive" />}
        </Body>
      </TableCell>
    </TableRow>
  );
};

const SupplierListTable: React.FunctionComponent<SupplierListTableProps & WithStyles<typeof styles>> = ({
  classes,
  goToEditSupplierPage,
  suppliers,
}) => {
  return (
    <div style={{ height: '92%' }}>
      <Paper>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="40%" />
            <col width="20%" />
          </colgroup>
          <TableHead>
            <SupplierListTableHead classes={classes} />
          </TableHead>
          <TableBody>
            {sortBy(suppliers, [(supplier) => supplier.name.toLowerCase()]).map((supplier: PartnerSupplier) => (
              <SupplierListRow
                key={supplier.id}
                supplier={supplier}
                goToEditSupplierPage={goToEditSupplierPage}
                classes={classes}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

const SupplierListInner: React.FunctionComponent<SupplierListComponentProps & WithStyles<typeof styles>> = ({
  classes,
  goToEditSupplierPage,
  suppliers,
}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <Page appBarText={<LocalizedMessage id="suppliersTitle" />} variant="overview">
        <Fab classes={{ root: classes.fab }} onClick={() => setDialogOpen(true)} data-test="add-supplier">
          <AddIcon htmlColor={white.main} />
        </Fab>
        <SupplierListTable goToEditSupplierPage={goToEditSupplierPage} suppliers={suppliers} classes={classes} />
        <SelectMarketDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
      </Page>
    </>
  );
};

interface SupplierListRowProps {
  supplier: PartnerSupplier;
  goToEditSupplierPage(supplierId: string): void;
}
export interface SupplierListTableProps {
  goToEditSupplierPage(supplierId: string): void;
  suppliers: PartnerSupplier[];
}
export interface SupplierListComponentProps {
  goToEditSupplierPage(supplierId: string): void;
  suppliers: PartnerSupplier[];
}

export const SupplierListComponent = withStyles(styles)(
  withLocalization('SupplierPortal/SupplierList')(SupplierListInner)
);
