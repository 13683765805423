import { selectorLocaleFetchable, selectorMessagesFetchable } from '@provider-portal/internationalization/duck';
import { LoadingState } from '@provider-portal/models/LoadingState';
import moment from 'moment';
import type * as React from 'react';
// @ts-ignore js import, remove this when the import is typed
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { withPropsOnChange } from 'recompose';
import { compose } from 'redux';

import { errorPageOnFailure } from '../containers/container-helpers';
import { onEnter } from '../containers/onEnter';
import { extractSuccess, fetchableIsLoading } from '../models/Fetchable';
import { loadingPageWhileLoading } from '../fetching/loadingPageWhileLoading';
import type { Locale } from '@provider-portal/internationalization/locale';

const mapStateToProps = (state: any) => ({
  fetchableLocale: selectorLocaleFetchable(state),
  fetchableMessages: selectorMessagesFetchable(state),
  formats: {
    date: {
      dayAndMonth: { day: 'numeric', month: 'long' },
      dayAndShortMonth: { day: 'numeric', month: 'short' },
      long: { day: 'numeric', month: 'long', year: 'numeric' },
      monthAndYear: { month: 'short', year: 'numeric' },
      shortMonth: { month: 'short' },
      shortMonthAndShortYear: { month: 'short', year: '2-digit' },
      fullYear: { year: 'numeric' },
    },
  },
});

//@ts-ignore
export const LocalizationProvider: React.ComponentType<{ children?: React.ReactNode }> = compose(
  connect(mapStateToProps),
  loadingPageWhileLoading(
    ({ fetchableLocale, fetchableMessages }: ReturnType<typeof mapStateToProps>) =>
      fetchableIsLoading(fetchableLocale) || fetchableIsLoading(fetchableMessages)
  ),
  errorPageOnFailure(
    ({ fetchableLocale, fetchableMessages }: ReturnType<typeof mapStateToProps>) =>
      fetchableLocale.type === LoadingState.Failure || fetchableMessages.type === LoadingState.Failure
  ),
  withPropsOnChange(
    ['fetchableLocale', 'fetchableMessages'],
    ({ fetchableLocale, fetchableMessages }: ReturnType<typeof mapStateToProps>) => ({
      locale: extractSuccess(fetchableLocale),
      messages: extractSuccess(fetchableMessages),
      key: Math.random(), //A key needs to be passed to IntlProvider so the context (for messages) refreshes. https://github.com/yahoo/react-intl/issues/1097#issuecomment-363114792
    })
  ),
  onEnter(async ({ locale }: { locale: Locale }) => {
    moment.locale(locale);
  })
)(IntlProvider);
