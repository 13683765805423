import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { fetchSaveDeskCancellationReasonSelectionFormData } from '@provider-portal/saveDesk/api';
import type { ISaveDeskOfferCancellationReasonsSelectionForm } from '@provider-portal/saveDesk/models';

interface IReturnValue {
  loadingState: PortalLoadingState;
  formData?: ISaveDeskOfferCancellationReasonsSelectionForm;
  setLoadingState(state: PortalLoadingState): void;
}

export function useFetchCancellationReasonFormDataOnEnter(partnerSupplierId: string): IReturnValue {
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);
  const [formData, setFormData] = React.useState<ISaveDeskOfferCancellationReasonsSelectionForm | undefined>(undefined);

  React.useEffect(() => {
    let mounted = true;

    fetchSaveDeskCancellationReasonSelectionFormData(partnerSupplierId)
      .then((offers) => {
        if (mounted) {
          setFormData(offers);
          setLoadingState(PortalLoadingState.Success);
        }
      })
      .catch(() => {
        setTimeout(() => {
          if (mounted) {
            setLoadingState(PortalLoadingState.Failure);
          }
        });
      });

    return () => {
      mounted = false;
    };
  }, [partnerSupplierId]);

  return { loadingState, formData, setLoadingState };
}
