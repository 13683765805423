import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { PartnerProviderSettings, SaveDeskSettings } from './models';

export async function fetchSettings(providerId: string): Promise<PartnerProviderSettings> {
  return axios
    .get(`/api/v1/suppliers/${providerId}/settings`)
    .then((response: AxiosResponse<PartnerProviderSettings>) => response.data);
}

export async function updateSaveDeskSettings(providerId: string, settings: SaveDeskSettings): Promise<void> {
  return axios.put(`/api/v1/suppliers/${providerId}/settings/save-desk`, settings);
}
