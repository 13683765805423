export interface ICampaignFormData {
  id?: string;
  externalCampaignId?: string;
  changesApproved?: boolean;
}

export interface ICampaignViewModel {
  id?: string;
  name?: string;
  state?: { type: CampaignState };
  [key: string]: any;
}

export enum CampaignState {
  Draft = 'Draft',
  Approved = 'Approved',
}

export interface IPartnerCampaignOverViewModel {
  name: string;
  id: string;
  state: CampaignState;
  externalCampaignId?: string;
}

export enum SubmitType {
  SAVE_DRAFT = 'saveDraft',
  PUBLISH = 'publish',
}
