import type { ITabConfig } from '@provider-portal/libs/uiFramework/components/Tabs/config';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';

export function useTabs<T extends string>(tabs: ITabConfig<T>[]): [T, (selectedTab: T) => void] {
  const history = useHistory();
  const hasPreviousSelectedTab = URI(history.location.search).hasQuery('display', (value: T) =>
    tabs.map((t) => t.key).includes(value)
  );
  const previousSelectedTabFromQuery: T | undefined =
    (hasPreviousSelectedTab && (URI(history.location.search).query(true) as any).display) || undefined;
  const [selectedTab, setSelectedTab] = React.useState<T>(previousSelectedTabFromQuery || tabs[0].key);

  function onSwitchTab(tabKey: T) {
    setSelectedTab(tabKey);
    const newUrl = new URI({
      path: history.location.pathname,
      query: history.location.search,
    })
      .setQuery('display', tabKey)
      .toString();
    history.replace(newUrl);
  }

  return [selectedTab, onSwitchTab];
}
