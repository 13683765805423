import { LocalizedMessage } from '@provider-portal/internationalization';
import { Checkbox } from '@provider-portal/libs/uiFramework/components/fields/Checkbox/config';
import { CentesimalCostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CentesimalCostInput/config';
import {
  FieldArray,
  FieldArrayItemComponentType,
} from '@provider-portal/libs/uiFramework/components/fields/FieldArray/config';
import { MeasurementUnit } from '@provider-portal/libs/uiFramework/components/fields/MeasurementUnit';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select/config';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import React from 'react';
import type { MarketCategoryMap } from '@provider-portal/libs/uiFramework';
import { createCategoryLayout } from '@provider-portal/libs/uiFramework';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import { Currency } from '@provider-portal/models/subscription';
import { getDefaultLocale } from '@provider-portal/app-context/state';
import { MultiSelect } from '@provider-portal/libs/uiFramework/components/fields/MultiSelect/config';

enum BelgiumProviderFormFieldNames {
  COVERED_REGIONS = 'details.coveredRegions',
  GAS_COVERED_REGIONS = 'details.coveredRegionNames',
  REGION = 'region',
  GREEN_ELECTRICITY_FEE = 'greenElectricityFee',
}

enum BelgiumProviderMobileFormFieldNames {
  NETWORK_OPERATOR = 'details.networkOperator',
  E_SIM_AVAILABLE = 'details.eSimAvailable',
}

enum BelgiumRegions {
  WALLONIA = 'Wallonia',
  FLANDERS = 'Flanders',
  BRUSSELS = 'Brussels',
}

const belgiumElectricityCategorySection: IFieldSectionConfig = {
  columns: 1,
  components: [
    FieldArray({
      name: BelgiumProviderFormFieldNames.COVERED_REGIONS,
      label: <LocalizedMessage id="coveredRegionsLabel" />,
      columns: 2,
      required: true,
      fields: [
        Select({
          name: BelgiumProviderFormFieldNames.REGION,
          label: <LocalizedMessage id="regionLabel" />,
          required: true,
          options: [
            { label: <LocalizedMessage id="wallonia" />, value: BelgiumRegions.WALLONIA },
            { label: <LocalizedMessage id="flanders" />, value: BelgiumRegions.FLANDERS },
            { label: <LocalizedMessage id="brussels" />, value: BelgiumRegions.BRUSSELS },
          ],
        }),
        CentesimalCostInput({
          name: BelgiumProviderFormFieldNames.GREEN_ELECTRICITY_FEE,
          label: <LocalizedMessage id="greenContributionFeeLabel" />,
          unit: MeasurementUnit.KWH,
          required: true,
          currency: Currency.EUR,
          locale: getDefaultLocale(Market.Belgium),
        }),
      ],
      addItemButtonLabel: <LocalizedMessage id="addRegionButtonLabel" />,
      itemComponent: {
        type: FieldArrayItemComponentType.FieldGroup1,
        title: <LocalizedMessage id="regionLabel" />,
        columns: 2,
      },
    }),
  ],
};

export const belgiumElectricityCategoryLayoutMap: MarketCategoryMap = createCategoryLayout(
  categories.electricity,
  Market.Belgium,
  belgiumElectricityCategorySection
);

const belgiumGasCategorySection: IFieldSectionConfig = {
  columns: 3,
  components: [
    MultiSelect({
      name: BelgiumProviderFormFieldNames.GAS_COVERED_REGIONS,
      label: <LocalizedMessage id="coveredRegionsLabel" />,
      required: true,
      options: [
        { label: <LocalizedMessage id="wallonia" />, value: BelgiumRegions.WALLONIA },
        { label: <LocalizedMessage id="flanders" />, value: BelgiumRegions.FLANDERS },
        { label: <LocalizedMessage id="brussels" />, value: BelgiumRegions.BRUSSELS },
      ],
      allSelectedLabel: <LocalizedMessage id="all" />,
    }),
  ],
};

export const belgiumGasCategoryLayoutMap: MarketCategoryMap = createCategoryLayout(
  categories.gas,
  Market.Belgium,
  belgiumGasCategorySection
);

const belgiumMobileCategorySection: IFieldSectionConfig = {
  columns: 3,
  components: [
    Select({
      name: BelgiumProviderMobileFormFieldNames.NETWORK_OPERATOR,
      label: <LocalizedMessage id="networkOperator" />,
      required: true,
      options: [
        { label: 'Proximus', value: 'Proximus' },
        { label: 'Orange', value: 'Orange' },
        { label: 'Telenet', value: 'Telenet' },
      ],
    }),
    Checkbox({
      name: BelgiumProviderMobileFormFieldNames.E_SIM_AVAILABLE,
      label: <LocalizedMessage id="eSim" />,
    }),
  ],
};

export const belgiumMobileCategoryLayoutMap: MarketCategoryMap = createCategoryLayout(
  categories.mobile,
  Market.Belgium,
  belgiumMobileCategorySection
);
