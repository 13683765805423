import type { ApplicationState } from '@provider-portal/store';
import type { ActionsUnion } from '@provider-portal/utils/redux';
import { createAction } from '@provider-portal/utils/redux';
import type { ISaveDeskOffer2ListViewModel, ISaveDeskOrder } from './models';

enum ActionKeys {
  SET_SAVE_DESK_ORDERS = 'SAVE_DESK/FETCH_SAVE_DESK_ORDERS',
  SET_SAVE_DESK_ORDER = 'SAVE_DESK/FETCH_SAVE_DESK_ORDER',
  SET_SAVE_DESK_OFFERS = 'SAVE_DESK/SET_SAVE_DESK_OFFERS',
}

export const SaveDeskActions = {
  setSaveDeskOrders: (saveDeskOrders: ISaveDeskOrder[]) =>
    createAction(ActionKeys.SET_SAVE_DESK_ORDERS, saveDeskOrders),
  setSaveDeskOrder: (saveDeskOrder: ISaveDeskOrder) => createAction(ActionKeys.SET_SAVE_DESK_ORDER, saveDeskOrder),
  setSaveDeskOffers: (saveDeskOffers: ISaveDeskOffer2ListViewModel[]) =>
    createAction(ActionKeys.SET_SAVE_DESK_OFFERS, saveDeskOffers),
};

export type Actions = ActionsUnion<typeof SaveDeskActions>;

export interface SaveDeskState {
  saveDeskOrders: ISaveDeskOrder[];
  saveDeskOrder?: ISaveDeskOrder;
  saveDeskOffers: ISaveDeskOffer2ListViewModel[];
}

const initialState: SaveDeskState = {
  saveDeskOrders: [],
  saveDeskOffers: [],
};

export function reducer(state: SaveDeskState = initialState, action: Actions): SaveDeskState {
  switch (action.type) {
    case ActionKeys.SET_SAVE_DESK_ORDER:
      return {
        ...state,
        saveDeskOrder: action.payload,
      };

    case ActionKeys.SET_SAVE_DESK_ORDERS:
      return {
        ...state,
        saveDeskOrders: action.payload,
      };

    case ActionKeys.SET_SAVE_DESK_OFFERS:
      return {
        ...state,
        saveDeskOffers: action.payload,
      };

    default:
      return state;
  }
}

export function selectorSaveDeskOrdersToBeProcessed(state: ApplicationState) {
  return state.saveDesk.saveDeskOrders.filter((o) => !o.dateCompleted);
}

export function selectorClosedSaveDeskOrders(state: ApplicationState) {
  return state.saveDesk.saveDeskOrders.filter((o) => Boolean(o.dateCompleted));
}
