import * as React from 'react';
import type { FactoryRequest } from '../../fields/configs';
import { FieldType } from '../../fields/configs';
import type { Columns } from '../../fields/configs/columns';
import { ComponentsLayout } from '../../layouts/ComponentsLayout';
import { componentFactory } from '../configs/resolve-config';

interface IFieldRowsProps {
  columns: Columns;
  fields: FactoryRequest[];
}

export const FieldRows: React.FunctionComponent<IFieldRowsProps> = ({ fields, columns }) => {
  const hiddenFields: FactoryRequest[] = [];
  const otherFields: FactoryRequest[] = [];

  fields.forEach((field) => {
    (field.type === FieldType.Hidden ? hiddenFields : otherFields).push(field);
  });

  return (
    <>
      <ComponentsLayout components={otherFields.map(componentFactory)} columns={columns} />
      {hiddenFields.map((hidden, index) => {
        return <React.Fragment key={index}>{componentFactory(hidden)}</React.Fragment>;
      })}
    </>
  );
};
