import React from 'react';
import type { EllipsisProps } from '../shared/ellipsis';
import { getEllipsisStyles } from '../shared/ellipsis';
import type { Omit, TypographyProps } from '../Typography';
import { Typography } from '../Typography';

export const Caption: React.FunctionComponent<CaptionProps> = ({ style, ellipsis, maxWidth, ...otherProps }) => {
  /**
   * 0.75rem corresponds to 12px in Invision
   */
  const ellipsisStyles = getEllipsisStyles(maxWidth, ellipsis);
  const baseStyle = {
    fontSize: '0.75rem',
    letterSpacing: '0.4px',
    lineHeight: '1.33em',
    ...ellipsisStyles,
    ...style,
  };

  return <Typography {...otherProps} variant="caption" style={baseStyle} />;
};

type CaptionProps = Omit<TypographyProps, 'variant'> & EllipsisProps;
