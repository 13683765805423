import { light, primary } from '@provider-portal/styles/colors-standard';
import * as React from 'react';

interface IProps {
  disabled?: boolean;
}

export const DividerSubSection: React.FunctionComponent<IProps> = ({ disabled }) => {
  const inlineStyles = {
    borderTop: disabled ? `solid 2px ${light.main}` : `solid 2px ${primary.main}`,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  };

  return <hr style={inlineStyles} />;
};
