import { Locale } from '@provider-portal/internationalization/locale';
import * as PropTypes from 'prop-types';
import { Currency } from '../models/subscription';
import type { DisplayName, DisplayNameIncoming } from './constants';
import { AppType, Market } from './constants';

// When updating models below also update a copy in'/integration-tests/cypress/models/index.ts'
export enum CancellationFeature {
  ENABLED = 'Enabled',
  ENABLED_WITH_TERMS = 'EnabledWithTerms',
  DISABLED = 'Disabled',
  PLACEHOLDER = 'Placeholder',
}

export const isEmbeddedView = (appContext: AppContext) => {
  return appContext.embeddedView.iframe || appContext.embeddedView.webView;
};

export enum NavigationType {
  FULL = 'Full', // Full header and bottom-navigation
  MOBILE_ONLY = 'MobileOnly', // Simple header and bottom-navigation (on mobile only)
  PROFILE = 'Profile', // No bottom navigation, "profile" menu instead of normal header
}

export enum OnboardingType {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  CANCELPRODUCT = 'CancelProduct',
}

export interface NavigationFeatures {
  navigationType: NavigationType;
  feedback: boolean;
  userMenuIcon?: boolean;
}

export enum SigningFeature {
  BANKID = 'Bankid',
  NEMID = 'Nemid',
  CHECKBOX = 'Checkbox',
  CHECKBOX_WITH_PROOF = 'CheckboxWithProof',
}

export enum PersonalInformationType {
  STANDALONE = 'Standalone',
  BANK = 'Bank',
  DISABLED = 'Disabled',
}

export enum NotificationType {
  STANDALONE = 'Standalone',
  SWEDBANK = 'Swedbank',
  DISABLED = 'Disabled',
}

export enum SyncFlowType {
  PSD2 = 'Psd2',
}

export interface SyncFlowFeature {
  syncFlowType: SyncFlowType;
  onboarding: boolean;
  showRetryButton: boolean;
}

export interface AppFeatureToggles {
  helpCenter: HelpCenter;
  insights: boolean;
  logout?: LogoutFeatures;
  identify?: IdentifyFeatures;
  settings?: Settings;
  conditionsAndPolicies?: ConditionsAndPoliciesFeature;
  navigation?: NavigationFeatures;
  signing: SigningFeature;
  onboarding: OnboardingType;
  syncFlow?: SyncFlowFeature;
  subscriptionFeatures: SubscriptionFeatures;
}
export interface SubscriptionFeatures {
  cancellation: CancellationFeature;
  deletingSubscriptions: boolean;
  saveDeskEnabled: boolean;
  subscriptionFeedback: boolean;
  cancellationGuide: boolean;
}
interface Settings {
  profiling: boolean;
  notification: {
    notificationType: NotificationType;
    pushEnabled: boolean;
  };
  /**
   * Whether to show the "change email" card.
   * Note: Users in bank apps can still change their email through the bank version of the personalInformation settings!
   */
  changeEmailCard: boolean;
  deleteAccount: boolean;
  personalInformation: {
    personalInformationType: PersonalInformationType;
    enabledAddressForm: boolean;
    enabledPersonalNumber: boolean;
    enabledNameChange: boolean;
  };
}

interface HelpCenter {
  enabled: boolean;
  chatEnabled: boolean;
  callUsEnabled: boolean;
  callBackEnabled: boolean;
  emailUsEnabled: boolean;
  zendeskBaseUrl: string;
  supportEmail: string;
}

interface ConditionsAndPoliciesFeature {
  publicConditionsAndPolicies?: boolean;
  userTermsPage?: boolean;
  privacyPolicyPage?: boolean;
  securityPage?: boolean;
  cookiesPage?: boolean;
  saveUserConsent?: boolean;
}

interface IdentifyFeatures {
  overview?: OverviewFeatures;
  identify: boolean;
  identifyOptIn: boolean;
}

interface OverviewFeatures {
  manuallyAddedSubscriptions: boolean;
  bankIdLogo: boolean;
  sslCertifiedIcon: boolean;
  complexContractGuide: boolean;
  waysToSave: boolean;
}
export interface LogoutFeatures {
  enabled: boolean;
  logoutConfirmationFlow?: boolean;
}

export interface AppContext {
  appType: AppType;
  displayName: DisplayName;
  features: AppFeatureToggles;
  embeddedView: EmbeddedViewConfig;
}

export interface AppContextIncoming {
  appType: AppType;
  displayName: DisplayNameIncoming;
  market: Market;
  features: AppFeatureToggles;
  locale: Locale;
  currency: Currency;
  embeddedView: EmbeddedViewConfig;
}

export interface EmbeddedViewProducts {
  subscriptions: boolean;
  cancellations: boolean;
}

export interface EmbeddedViewConfig {
  iframe: boolean;
  webView: boolean;
  embeddedViewProducts: EmbeddedViewProducts;
}

export const namePropType = PropTypes.oneOf(Object.values(AppType));
export const marketPropType = PropTypes.oneOf(Object.values(Market));
const localePropType = PropTypes.oneOf(Object.values(Locale));
const currencyPropType = PropTypes.oneOf(Object.values(Currency));
const cancellationType = PropTypes.oneOf(Object.values(CancellationFeature));
export const navigationTypePropType = PropTypes.oneOf(Object.values(NavigationType));
const signingFeature = PropTypes.oneOf(Object.values(SigningFeature));
const personalInformationTypePropType = PropTypes.oneOf(Object.values(PersonalInformationType));
const notificationTypePropType = PropTypes.oneOf(Object.values(NotificationType));
const onboardingType = PropTypes.oneOf(Object.values(OnboardingType));
const syncFlowType = PropTypes.oneOf(Object.values(SyncFlowType));

export const featuresPropType = PropTypes.shape({
  insights: PropTypes.bool.isRequired,
  helpCenter: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    chatEnabled: PropTypes.bool.isRequired,
    callUsEnabled: PropTypes.bool.isRequired,
    callBackEnabled: PropTypes.bool.isRequired,
    emailUsEnabled: PropTypes.bool.isRequired,
  }),
  logout: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    logoutConfirmationFlow: PropTypes.bool,
  }),
  overview: PropTypes.shape({
    manuallyAddedSubscriptions: PropTypes.bool.isRequired,
    bankIdLogo: PropTypes.bool.isRequired,
    sslCertifiedIcon: PropTypes.bool.isRequired,
  }),
  settings: PropTypes.shape({
    personalInformation: PropTypes.shape({
      personalInformationType: personalInformationTypePropType.isRequired,
      enabledAddressForm: PropTypes.bool.isRequired,
      enabledPersonalNumber: PropTypes.bool.isRequired,
    }),
    profiling: PropTypes.bool.isRequired,
    notification: PropTypes.shape({
      notificationType: notificationTypePropType.isRequired,
      pushEnabled: PropTypes.bool.isRequired,
    }),
    /* *
     * Whether to show the "change email" card.
     * Note: Users in bank apps can still change their email through the bank version of the personalInformation settings!
     * */
    changeEmailCard: PropTypes.bool.isRequired,
    deleteAccount: PropTypes.bool.isRequired,
  }),
  conditionsAndPolicies: PropTypes.shape({
    publicConditionsAndPolicies: PropTypes.bool,
    userTermsPage: PropTypes.bool,
    privacyPolicyPage: PropTypes.bool,
    securityPage: PropTypes.bool,
    cookiesPage: PropTypes.bool,
  }),
  navigation: PropTypes.shape({
    navigationType: navigationTypePropType.isRequired,
    feedback: PropTypes.bool.isRequired,
    userMenuIcon: PropTypes.bool,
  }),
  signing: signingFeature.isRequired,
  onboarding: onboardingType.isRequired,
  syncFlow: PropTypes.shape({
    syncFlowType: syncFlowType.isRequired,
    onboarding: PropTypes.bool,
  }),
  subscriptionFeatures: PropTypes.shape({
    cancellation: cancellationType.isRequired,
    changeDataplan: PropTypes.bool.isRequired,
    deletingSubscriptions: PropTypes.bool.isRequired,
    saveDeskEnabled: PropTypes.bool.isRequired,
    subscriptionFeedback: PropTypes.bool.isRequired,
  }),
});

export const appContextPropType = PropTypes.shape({
  appType: namePropType.isRequired,
  displayName: PropTypes.string.isRequired,
  market: marketPropType.isRequired,
  locale: localePropType.isRequired,
  currency: currencyPropType.isRequired,
  features: featuresPropType.isRequired,
});

export const disabledEmbeddedView: EmbeddedViewConfig = {
  iframe: false,
  webView: false,
  embeddedViewProducts: {
    cancellations: false,
    subscriptions: false,
  },
};
