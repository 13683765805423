import PropTypes from 'prop-types';
import React from 'react';
import without from 'lodash/without';
import keys from 'lodash/keys';
import head from 'lodash/head';
import every from 'lodash/every';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import _ from 'lodash';
import noop from 'lodash/noop';
import ReactDOM from 'react-dom';

export const validationContextPropType = PropTypes.shape({
  register: PropTypes.func,
  unregister: PropTypes.func,
  addFormSubmittedListener: PropTypes.func,
  removeFormSubmittedListener: PropTypes.func,
});

// eslint-disable-next-line import/no-default-export
export default class Form extends React.Component {
  public static propTypes = {
    onValidSubmit: PropTypes.func,
    onValidSubmitWithEvent: PropTypes.func,
    onInvalidSubmit: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    noValidate: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  public static defaultProps = {
    noValidate: true,
    onValidSubmit: noop,
    onInvalidSubmit: noop,
  };

  public static childContextTypes = {
    validation: validationContextPropType,
  };

  public nodes = [];
  public formSubmittedListeners = [];

  public childContext = {
    validation: {
      register: (node: any) => {
        //@ts-ignore
        this.nodes = [...this.nodes, node];
      },
      unregister: (node: any) => {
        //@ts-ignore
        this.nodes = without(this.nodes, node);
      },
      addFormSubmittedListener: (func: any) => {
        //@ts-ignore
        this.formSubmittedListeners = [...this.formSubmittedListeners, func];
      },
      removeFormSubmittedListener: (func: any) => {
        //@ts-ignore
        this.formSubmittedListeners = without(this.formSubmittedListeners, func);
      },
    },
  };

  public getChildContext() {
    return this.childContext;
  }

  public onSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    //@ts-ignore
    if (this.props.disabled) {
      return;
    }

    this.setFormSubmitted();

    const values = _(this.nodes).keyBy('props.name').mapValues('props.value').value();

    //@ts-ignore
    if (this.isValid() && this.props.onValidSubmitWithEvent) {
      //@ts-ignore
      this.props.onValidSubmitWithEvent(event, values);
    } else if (this.isValid()) {
      //@ts-ignore
      this.props.onValidSubmit(values);
    } else {
      const errors = _(this.nodes).keyBy('props.name').mapValues('state.errors').value();
      this.scrollToFirstError(errors);
      //@ts-ignore
      this.props.onInvalidSubmit(values, errors);
    }
  };

  public validateAsSubmitted = () => {
    this.setFormSubmitted();

    if (!this.isValid()) {
      const errors = _(this.nodes).keyBy('props.name').mapValues('state.errors').value();
      this.scrollToFirstError(errors);
    }

    return this.isValid();
  };

  public scrollToFirstError(errors: any) {
    const firstInvalidInputName = head(keys(errors));
    const firstInvalidNode = find(this.nodes, { props: { name: firstInvalidInputName } });
    //@ts-ignore
    ReactDOM.findDOMNode(firstInvalidNode).scrollIntoView(); // eslint-disable-line react/no-find-dom-node
  }

  public isValid = () => {
    return every(this.nodes, 'state.valid');
  };

  public setFormSubmitted = () => {
    forEach(this.formSubmittedListeners, (func: any) => func(true));
  };

  public render() {
    //@ts-ignore
    const { noValidate, children, className } = this.props;

    return (
      <form onSubmit={this.onSubmit} noValidate={noValidate} className={className}>
        {children}
      </form>
    );
  }
}
