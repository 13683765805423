import { Market } from '@provider-portal/app-context/constants';

// When updating this model also update the copy in '/integration-tests/cypress/models/index.ts'
export enum Locale {
  enGB = 'en-GB',
  enUS = 'en-US',
  daDK = 'da-DK',
  fiFI = 'fi-FI',
  frFR = 'fr-FR',
  svSE = 'sv-SE',
  nbNO = 'nb-NO',
  skSK = 'sk-SK',
  nlBE = 'nl-BE',
  nlNL = 'nl-NL',
  frBE = 'fr-BE',
  esES = 'es-ES',
}

export const allLocales = Object.values(Locale);

export function languageNameFromLocale(locale: string) {
  switch (locale) {
    case 'sv-SE':
      return 'Swedish';
    case 'da-DK':
      return 'Danish';
    case 'fi-FI':
      return 'Finnish';
    case 'en-GB':
      return 'English';
    case 'en-US':
      return 'American English';
    case 'fr-FR':
      return 'French';
    case 'fr-BE':
      return 'French';
    case 'nl-BE':
      return 'Flemish';
    case 'nl-NL':
      return 'Dutch';
    case 'nb-NO':
      return 'Norwegian';
    case 'es-ES':
      return 'Spanish';
    default:
      throw new Error(`Unexpected locale ${locale}`);
  }
}

export function languageShorthandFromLocale(locale: string) {
  switch (locale) {
    case 'sv-SE':
      return 'SE';
    case 'da-DK':
      return 'DK';
    case 'fi-FI':
      return 'FI';
    case 'en-GB':
      return 'UK';
    case 'en-US':
      return 'US';
    case 'fr-FR':
      return 'FR';
    case 'fr-BE':
      return 'BE';
    case 'nl-BE':
      return 'BE';
    case 'nl-NL':
      return 'NL';
    case 'nb-NO':
      return 'NO';
    case 'es-ES':
      return 'ES';
    default:
      throw new Error(`Unexpected locale ${locale}`);
  }
}

export function languageCodeFromLocale(locale: Locale): string {
  return locale.substr(0, 2);
}

export function defaultLocaleForMarket(market: Market): Locale {
  switch (market) {
    case Market.Sweden:
      return Locale.svSE;
    case Market.Finland:
      return Locale.fiFI;
    case Market.Denmark:
      return Locale.daDK;
    case Market.Norway:
      return Locale.nbNO;
    case Market.Belgium:
      return Locale.enGB;
    case Market.Slovakia:
      return Locale.enGB;
    case Market.UnitedKingdom:
      return Locale.enGB;
    case Market.Spain:
      return Locale.esES;
    case Market.UnitedStates:
      return Locale.enUS;
    case Market.Australia:
      return Locale.enGB;
  }
}
