import { Currency } from '@provider-portal/models/subscription';
import { Market } from '@provider-portal/app-context/constants';
import { allLocales, Locale } from '@provider-portal/internationalization/locale';

export function getDefaultLocale(market: Market) {
  switch (market) {
    case Market.Sweden:
      return Locale.svSE;
    case Market.Denmark:
      return Locale.daDK;
    case Market.Finland:
      return Locale.fiFI;
    case Market.Belgium:
      return Locale.enGB;
    case Market.UnitedKingdom:
      return Locale.enGB;
    case Market.Norway:
      // TODO: Default Locale should be changed to NO?
      return Locale.enGB;
    case Market.Slovakia:
      return Locale.enGB;
    case Market.Spain:
      return Locale.enGB;
    case Market.UnitedStates:
      return Locale.enGB;
    case Market.Australia:
      return Locale.enGB;
    default:
      return Locale.svSE;
  }
}

/**
 * Get the locale from a query parameter of the URL.
 */
export function getLocaleFromUrl() {
  const query = new URLSearchParams(window.location.search);
  const maybeLocaleFromQuery = query.get('locale');
  if (allLocales.includes(maybeLocaleFromQuery as Locale)) {
    return maybeLocaleFromQuery as Locale;
  }

  return null;
}

export function getDefaultCurrency(market: Market) {
  switch (market) {
    case Market.Sweden:
      return Currency.SEK;
    case Market.Denmark:
      return Currency.DKK;
    case Market.Finland:
      return Currency.EUR;
    case Market.Belgium:
      return Currency.EUR;
    case Market.UnitedKingdom:
      return Currency.GBP;
    case Market.Norway:
      return Currency.NOK;
    case Market.Slovakia:
      return Currency.EUR;
    case Market.Spain:
      return Currency.EUR;
    case Market.UnitedStates:
      return Currency.USD;
    case Market.Australia:
      return Currency.AUD;
    default:
      return Currency.SEK;
  }
}
