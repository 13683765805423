import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { ButtonText } from '@minna-technologies/minna-ui/components/Typography/ButtonText';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import { MinnaIcon } from '@minna-technologies/minna-ui/icons/Minna';
import { BookIcon } from '@provider-portal/assets/icons/BookIcon';
import { CodeIcon } from '@provider-portal/assets/icons/CodeIcon';
import { ExternalLinks } from '@provider-portal/constants/externalLinks';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import startsWith from 'lodash/startsWith';
import * as React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  flexGap: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  pageContent: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  introductionMessage: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  },
  usefulLinksCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  usefulLinks: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  stepTextWithLink: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
  },
  usefulLink: {
    display: 'flex',
    textDecoration: 'none',
    gap: theme.spacing(1),
    alignItems: 'center',
    '& .text': {
      flex: 1,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bookIcon: {
    width: '16px',
    height: '16px',
  },
  link: {
    color: theme.colors.action.primary,
    fontWeight: 'bold',
  },
  stepNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    height: '24px',
    width: '24px',
    backgroundColor: theme.colors.action.primary,
  },
}));

interface GetStartedPageProps {
  path: string;
  merchantId: string;
}

const GetStartedPageInner = ({ path, merchantId }: GetStartedPageProps) => {
  const { colors } = useTheme();
  const classes = useStyles();

  const pathPrefix = startsWith(path, '/admin') ? '/admin/suppliers' : '/suppliers';
  const retentionPagePath = `${pathPrefix}/${merchantId}/retention`;
  const merchantApiKeyPagePath = `${pathPrefix}/${merchantId}/developer-tools/api-key`;
  const testingPagePath = `${pathPrefix}/${merchantId}/developer-tools/testing`;
  const contactUsPagePath = `/contact-us`;

  return (
    <Page
      appBarText={
        <div className={classes.flexGap}>
          <MinnaIcon nativeColor={colors.base.surface} />
          <Headline5 textColor="alternative">
            <LocalizedMessage id="title" />
          </Headline5>
        </div>
      }
      variant="details"
      showMenuForSmallerScreen
      showBackButton={false}
    >
      <div className={classes.pageContent}>
        <header>
          <Headline1>
            <LocalizedMessage id="header" />
          </Headline1>
        </header>
        <Card className={classes.card}>
          <Headline3>
            <LocalizedMessage id="introductionTitle" />
          </Headline3>
          <div className={classes.introductionMessage}>
            <Body>
              <LocalizedMessage id="introductionMessage1" />
            </Body>
            <Body>
              <LocalizedMessage id="introductionMessage2" />
            </Body>
            <Body className={classes.stepTextWithLink}>
              <LocalizedMessage id="introductionMessage3" />
              <Link className={classes.link} to={retentionPagePath}>
                Retention Solution
              </Link>
              .
            </Body>
          </div>
          <br />
          <Headline3>
            <LocalizedMessage id="stepsTitle" />
          </Headline3>
          <div className={classes.flexGap}>
            <ButtonText color={colors.base.surface} className={classes.stepNumber}>
              1
            </ButtonText>
            <Body className={classes.stepTextWithLink}>
              <LocalizedMessage id="step1" />
              <BookIcon className={classes.bookIcon} nativeColor={colors.action.primary} />
              <Link
                className={classes.link}
                to={{ pathname: ExternalLinks.ApiDocumentation }}
                target="_blank"
                rel="noreferrer"
              >
                Merchant API documentation
              </Link>
            </Body>
          </div>
          <div className={classes.flexGap}>
            <ButtonText color={colors.base.surface} className={classes.stepNumber}>
              2
            </ButtonText>
            <Body className={classes.stepTextWithLink}>
              <LocalizedMessage id="step2Part1" />
              <Link className={classes.link} to={merchantApiKeyPagePath}>
                Merchant API key
              </Link>
              <LocalizedMessage id="step2Part2" />
            </Body>
          </div>
          <div className={classes.flexGap}>
            <ButtonText color={colors.base.surface} className={classes.stepNumber}>
              3
            </ButtonText>
            <Body className={classes.stepTextWithLink}>
              <LocalizedMessage id="step3" />
              <Link className={classes.link} to={testingPagePath}>
                Testing
              </Link>
            </Body>
          </div>
          <div className={classes.flexGap}>
            <ButtonText color={colors.base.surface} className={classes.stepNumber}>
              4
            </ButtonText>
            <Body className={classes.stepTextWithLink}>
              <LocalizedMessage id="step4" />
              <Link className={classes.link} to={contactUsPagePath}>
                Go live!
              </Link>
            </Body>
          </div>
        </Card>
        <Card className={classes.usefulLinksCard}>
          <Headline3>
            <LocalizedMessage id="usefulLinksTitle" />
          </Headline3>
          <div className={classes.usefulLinks}>
            <Link
              className={classes.usefulLink}
              to={{ pathname: ExternalLinks.ApiAuthentication }}
              target="_blank"
              rel="noreferrer"
            >
              <BookIcon nativeColor={colors.action.primary} />
              <Body className="text">
                <LocalizedMessage id="authentication" />
              </Body>
              <ChevronRightIcon />
            </Link>
            <Link
              className={classes.usefulLink}
              to={{ pathname: ExternalLinks.ApiReference }}
              target="_blank"
              rel="noreferrer"
            >
              <CodeIcon nativeColor={colors.action.primary} />
              <Body className="text">
                <LocalizedMessage id="apiReference" />
              </Body>
              <ChevronRightIcon />
            </Link>
          </div>
          <Divider fullWidth />
          <Link
            className={classes.usefulLink}
            to={{ pathname: ExternalLinks.ApiDocumentation }}
            target="_blank"
            rel="noreferrer"
          >
            <Body color={colors.action.primary} className="text">
              Go to docs.minna.tech
            </Body>
            <ChevronRightIcon />
          </Link>
        </Card>
      </div>
    </Page>
  );
};

export const GetStartedPage = withLocalization('GetStartedPage')(GetStartedPageInner);
