import type { ISwitchOrder } from '@provider-portal/orders/switch/models';
import { SwitchOrderState } from '@provider-portal/orders/switch/models';
import type { DateFilterSetting } from '@provider-portal/orders/switch/ListSwitchOrders/DateFilterButton';
import { DateFilterPeriod } from '@provider-portal/orders/switch/ListSwitchOrders/DateFilterButton';
import moment from 'moment';
import { useState } from 'react';

export interface ISwitchOrderFilter {
  filterOrders(orders: ISwitchOrder[]): ISwitchOrder[];
  ordersFilteredOutCount(allOrders: ISwitchOrder[]): number;
  clearFilters(): void;
  setDateFilter(dateFilterPeriod: DateFilterSetting): void;
  getActiveDateFilter(): DateFilterPeriod;
  setStateFilter(orderStates: SwitchOrderState[]): void;
  getSelectedOrderStates(): SwitchOrderState[];
  orderFilterIsChangedFromDefault(): boolean;
}

const DEFAULT_DATE_FILTER_SETTING: DateFilterSetting = { period: DateFilterPeriod.AllTime };
const ALL_ORDER_STATES = [SwitchOrderState.New, SwitchOrderState.Accepted, SwitchOrderState.Rejected];

export function useSwitchOrderFilter(): ISwitchOrderFilter {
  const [selectedDateFilterSetting, setSelectedDateFilterSetting] = useState(DEFAULT_DATE_FILTER_SETTING);
  const [selectedOrderStates, setSelectedOrderStates] = useState(ALL_ORDER_STATES);

  return {
    filterOrders,
    ordersFilteredOutCount,
    clearFilters,
    setDateFilter,
    getActiveDateFilter: () => selectedDateFilterSetting.period,
    setStateFilter,
    getSelectedOrderStates: () => selectedOrderStates,
    orderFilterIsChangedFromDefault,
  };

  function filterOrders(orders: ISwitchOrder[]) {
    return filterSwitchOrders(orders, selectedDateFilterSetting, selectedOrderStates);
  }

  function ordersFilteredOutCount(allOrders: ISwitchOrder[]) {
    return allOrders.length - filterOrders(allOrders).length;
  }

  function clearFilters() {
    setSelectedDateFilterSetting(DEFAULT_DATE_FILTER_SETTING);
    setSelectedOrderStates(ALL_ORDER_STATES);
  }

  function setDateFilter(dateFilterSetting: DateFilterSetting) {
    setSelectedDateFilterSetting(dateFilterSetting);
  }

  function setStateFilter(orderStates: SwitchOrderState[]) {
    setSelectedOrderStates(orderStates);
  }

  function orderFilterIsChangedFromDefault() {
    return ALL_ORDER_STATES.length !== selectedOrderStates.length;
  }
}

function filterSwitchOrders(
  orders: ISwitchOrder[],
  selectedDateFilterSetting: DateFilterSetting,
  selectedOrderStates: SwitchOrderState[]
) {
  return orders
    .filter(filterOnDateFilterSetting(selectedDateFilterSetting))
    .filter(filterOnOrderState(selectedOrderStates));
}

export function filterOnDateFilterSetting(selectedDateFilterSetting: DateFilterSetting) {
  return (order: ISwitchOrder) => {
    switch (selectedDateFilterSetting.period) {
      case DateFilterPeriod.AllTime:
        return true;
      case DateFilterPeriod.Last30Days:
        return moment().subtract(30, 'days').isBefore(order.sentToSupplierAt);
      case DateFilterPeriod.Last10Days:
        return moment().subtract(10, 'days').isBefore(order.sentToSupplierAt);
      case DateFilterPeriod.Custom: {
        if (!selectedDateFilterSetting.endDate || !selectedDateFilterSetting.startDate) {
          throw new Error('No dates set for custom date range.');
        }

        const daysBack = moment(selectedDateFilterSetting.endDate).diff(selectedDateFilterSetting.startDate, 'day');
        const { startDate, endDate } = dateRange(selectedDateFilterSetting.endDate, daysBack);

        return (
          moment(order.sentToSupplierAt).isBefore(endDate) && moment(order.sentToSupplierAt).isSameOrAfter(startDate)
        );
      }
      default:
        throw new Error(`No filter implemented for date filter period: ${selectedDateFilterSetting.period}`);
    }
  };
}

function dateRange(endDate: Date, daysBack: number) {
  return {
    endDate: moment.utc(endDate).startOf('day').add(1, 'day'),
    startDate: moment.utc(endDate).startOf('day').subtract(daysBack, 'day'),
  };
}

export function filterOnOrderState(filterOnOrderStates: SwitchOrderState[]) {
  return (order: ISwitchOrder) => {
    return filterOnOrderStates.includes(order.state.name);
  };
}
