import { DividerSection } from '@provider-portal/libs/uiFramework/components/dividers/DividerSection';
import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import * as React from 'react';
import { SettingsMenuItem } from './SettingsMenuItem';
import { SignOutMenuItem } from './SignOutMenuItem';
import styles from './styles.scss';

interface IBottomSectionProps {
  hasAdminAccess: boolean;
  loggedInUsername?: string;
  providerId?: string;
  pathPrefix: string;
  path: string;
}

export const BottomSection: React.FunctionComponent<IBottomSectionProps> = ({
  hasAdminAccess,
  loggedInUsername,
  providerId,
  pathPrefix,
  path,
}) => {
  return (
    <div className={styles.container}>
      {loggedInUsername && (
        <Caption className={styles.userName} textColor="primary">
          {loggedInUsername}
        </Caption>
      )}
      {providerId && hasAdminAccess && (
        <>
          <DividerSection />
          <div className={styles.menuItem}>
            <SettingsMenuItem pathPrefix={pathPrefix} providerId={providerId} path={path} />
          </div>
        </>
      )}
      {!hasAdminAccess && (
        <>
          <DividerSection />
          <div className={styles.menuItem}>
            <SignOutMenuItem />
          </div>
        </>
      )}
    </div>
  );
};
