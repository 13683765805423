import type { ITooltipProps } from '@provider-portal/libs/uiFramework/components/tooltip';
import type * as React from 'react';
import type { IInputField } from '../configs';
import { fieldConstructor, FieldType } from '../configs';

export interface ITextInputNew extends IInputField, ITooltipProps {
  type: FieldType.Text;
  defaultValue?: string;
  multiline?: boolean;
  helperText?: React.ReactNode;
  maxlength?: number;
  label?: React.ReactNode;
  description?: React.ReactNode;
}
export interface ITextInput extends IInputField, ITooltipProps {
  type: FieldType.Text;
  defaultValue?: string;
  multiline?: boolean;
  helperText?: React.ReactNode;
  maxlength?: number;
}

export interface IUriInput extends Omit<ITextInput, 'type'> {
  type: FieldType.Uri;
}

export interface IEmailInput extends Omit<ITextInput, 'type'> {
  type: FieldType.Email;
}

export const TextInput = fieldConstructor<ITextInput>(FieldType.Text);
export const TextInputNew = fieldConstructor<ITextInputNew>(FieldType.Text);
export const UriInput = fieldConstructor<IUriInput>(FieldType.Uri);
export const EmailInput = fieldConstructor<IEmailInput>(FieldType.Email);
