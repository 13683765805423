import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { TabbedTableOverviewPage } from '@provider-portal/libs/uiFramework/components/pages/TabbedTableOverviewPage';
import { useTabs } from '@provider-portal/libs/hooks/use-tabs';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import type { History } from 'history';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { fetchCampaigns } from '../api';
import type { IPartnerCampaignOverViewModel } from '../models';
import { CampaignState } from '../models';
import { CampaignsListTab, campaignsListTableConfig, getTabsConfig } from './config';

export interface ICampaignsListProps {
  partnerSupplierId: string;
  category: string;
}

const CampaignsListInner: React.FunctionComponent<ICampaignsListProps> = ({ partnerSupplierId, category }) => {
  const history = useHistory();
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);
  const [campaigns, setCampaigns] = React.useState<IPartnerCampaignOverViewModel[]>([]);
  const [activeCampaigns, draftCampaigns] = campaignsGrouped(campaigns);
  const tabsConfig = getTabsConfig(activeCampaigns.length, draftCampaigns.length);
  const [selectedTab, onSwitchTab] = useTabs(tabsConfig);

  React.useEffect(() => {
    fetchCampaigns(partnerSupplierId, category)
      .then((campaignsResponse) => {
        setCampaigns(campaignsResponse);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
  }, [partnerSupplierId, category]);

  return (
    <LoadingPage loadingState={loadingState}>
      <TabbedTableOverviewPage
        appBarText={<LocalizedMessage id="campaignsTitleV2" />}
        tabs={tabsConfig}
        table={campaignsListTableConfig}
        data={selectedTab === CampaignsListTab.APPROVED ? activeCampaigns : draftCampaigns}
        selectedTab={selectedTab}
        onSwitchTab={onSwitchTab}
        onRowClick={onCampaignClick(history)}
        onAdd={onAdd(history)}
      />
    </LoadingPage>
  );
};

export const CampaignsList: React.ComponentType<ICampaignsListProps> = withLocalization(
  'SupplierPortal/PartnerCampaign'
)(CampaignsListInner);

function campaignsGrouped(
  campaigns: IPartnerCampaignOverViewModel[]
): [IPartnerCampaignOverViewModel[], IPartnerCampaignOverViewModel[]] {
  return [
    campaigns.filter((p) => p.state === CampaignState.Approved),
    campaigns.filter((p) => p.state === CampaignState.Draft),
  ];
}

function onCampaignClick(history: History) {
  return (campaign: IPartnerCampaignOverViewModel) => {
    const campaignDetailsUrl = URI(history.location.pathname).segment(campaign.id).toString();
    history.push(campaignDetailsUrl);
  };
}

function onAdd(history: History) {
  return () => {
    const addCampaignUrl = URI(history.location.pathname).segment('add').toString();
    history.push(addCampaignUrl);
  };
}
