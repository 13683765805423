import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import { LocalizedMessage } from '@provider-portal/internationalization';
import * as React from 'react';
import { Surface } from '../Surface';
import styles from './styles.scss';
import userIcon from './user.svg';

interface IRequestProps {
  orderDate: string;
  userName: string;
  category: string;
  pains: (string | React.ReactNode)[];
  comment?: string;
}

export const SaveDeskRequestCard: React.FunctionComponent<IRequestProps> = ({
  orderDate,
  userName,
  category,
  pains,
  comment,
}) => {
  return (
    <Paper title={<LocalizedMessage id="saveDeskOrderPageRequestTitle" />}>
      <div className={styles.container}>
        <div className={styles.header}>
          <img className={styles.icon} src={userIcon} alt="user" />
          <div>
            <Caption colorVariant="darker">{orderDate}</Caption>
            <Body1>
              <LocalizedMessage id="saveDeskOrderPageRequestDescription" values={{ userName, category }} />
            </Body1>
          </div>
        </div>
        <Surface>
          <div>
            <Caption colorVariant="darker">
              <LocalizedMessage id="saveDeskOrderPageRequestPainsTitle2" />
            </Caption>
            <Body1>
              {pains.map((p, i) => {
                if (i === pains.length - 1) return p;

                return <>{p}, </>;
              })}
            </Body1>
          </div>
          {comment && (
            <div className={styles.otherCaption}>
              <Caption colorVariant="darker">
                <LocalizedMessage id="saveDeskOrderPageRequestCommentTitle" values={{ userName }} />
              </Caption>
              <Body1>{comment}</Body1>
            </div>
          )}
        </Surface>
      </div>
    </Paper>
  );
};
