export interface IChangeOrder {
  id: string;
  partnerSupplierId: string;
  productName: string;
  orderType: string;
  customerId: string;
  state: IChangeOrderState;
  partnerOrderId: string;
  createdAt: string;
}

export interface IChangeOrderState {
  createdAt: Date;
  stateType: ChangeOrderState;
}

export enum ChangeOrderState {
  New = 'New',
  WaitingForUserInput = 'WaitingForUserInput',
  AcceptedByUser = 'AcceptedByUser',
  RejectedByUser = 'RejectedByUser',
  RejectedByProvider = 'RejectedByProvider',
  Success = 'Success',
}
