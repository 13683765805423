import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import { TableChip } from '@provider-portal/libs/uiFramework/components/Table/TableChip';
import { TableIconCell } from '@provider-portal/libs/uiFramework/components/Table/TableIconCell';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import moment from 'moment';
import * as React from 'react';
import type { ISaveDeskState } from '../models';
import { SaveDeskState } from '../models';
import { renderAssigned } from '@provider-portal/utils/orderOverviewUtils';

export enum SaveDeskTab {
  Pending = 'pending',
  Processed = 'processed',
}

export const saveDeskToBeProcessedTableConfig: ITableConfig = {
  cells: [
    {
      key: 'customerId',
      label: <LocalizedMessage id="saveDeskCustomerIdColumn" />,
    },
    {
      key: 'category',
      label: <LocalizedMessage id="saveDeskCategoryColumn" />,
    },
    {
      key: 'daysLeft',
      label: <LocalizedMessage id="saveDeskDaysLeftColumn" />,
      renderCellValue(daysLeft: number | undefined) {
        if (!daysLeft) return '-';

        if (daysLeft <= 1) {
          return (
            <TableIconCell
              value={
                <>
                  {daysLeft} <LocalizedMessage id="day" />
                </>
              }
              variant="warning"
            />
          );
        } else {
          return (
            <>
              {daysLeft} <LocalizedMessage id="days" />
            </>
          );
        }
      },
    },
    {
      key: 'state',
      label: <LocalizedMessage id="saveDeskStatusColumn" />,
      renderCellValue: renderSaveDeskState,
      tooltip: saveDeskStateTooltip,
      sort: 'state.stateType',
    },
    {
      key: 'assignee',
      label: <LocalizedMessage id="assignee" />,
      renderCellValue: renderAssigned,
      sort: 'assignee',
    },
  ],
  defaultSortColumn: 'daysLeft',
};

export const saveDeskClosedOrdersTableConfig: ITableConfig = {
  cells: [
    {
      key: 'customerId',
      label: <LocalizedMessage id="saveDeskCustomerIdColumn" />,
    },
    {
      key: 'category',
      label: <LocalizedMessage id="saveDeskCategoryColumn" />,
    },
    {
      key: 'dateCompleted',
      label: <LocalizedMessage id="saveDeskDateCompletedColumn" />,
      renderCellValue(dateCompleted: Date) {
        return <Body1>{moment(dateCompleted).format('DD MMM YYYY')}</Body1>;
      },
    },
    {
      key: 'state',
      label: <LocalizedMessage id="saveDeskStatusColumn" />,
      renderCellValue: renderSaveDeskState,
      tooltip: saveDeskStateTooltip,
      sort: 'state.stateType',
    },
  ],
  defaultSortColumn: 'dateCompleted',
};

export function renderSaveDeskState(state: ISaveDeskState) {
  switch (state.stateType) {
    case SaveDeskState.New:
      return <TableChip label="New" color="information" />;
    case SaveDeskState.WaitingForUserInput:
      return <TableChip label="Waiting for customer" color="aquamarine" />;
    case SaveDeskState.AcceptedByUser:
      return <TableChip label="Accepted by customer" color="positive" />;
    case SaveDeskState.RejectedByUser:
      return <TableChip label="Rejected by customer" color="grey" />;
    case SaveDeskState.RejectedByProvider:
      return <TableChip label="Rejected by provider" color="grey" />;
    case SaveDeskState.Success:
      return <TableChip label="Success" color="positive" />;
  }
}

function saveDeskStateTooltip(state: ISaveDeskState): React.ReactNode {
  switch (state.stateType) {
    case SaveDeskState.New:
      return <LocalizedMessage id="new" />;
    case SaveDeskState.WaitingForUserInput:
      return <LocalizedMessage id="waitingForCustomer" />;
    case SaveDeskState.AcceptedByUser:
      return <LocalizedMessage id="acceptedByCustomer" />;
    case SaveDeskState.RejectedByUser:
      return <LocalizedMessage id="rejectedByCustomer" />;
    case SaveDeskState.RejectedByProvider:
      return <LocalizedMessage id="rejectedByProvider" />;
    case SaveDeskState.Success:
      return <LocalizedMessage id="success" />;
  }
}
