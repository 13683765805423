import { primary } from '@provider-portal/styles/colors-standard';
import MUIDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import type { WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import Close from '@material-ui/icons/Close';
import React from 'react';
import { DividerSection } from '../../dividers/DividerSection';

const styles = createStyles({
  dialogBody: {
    minWidth: '420px',
  },
  icon: {
    position: 'absolute',
    top: '19px',
    right: '19px',
    padding: 0,
  },
  content: {
    paddingBottom: '69px',
  },
  title: {
    color: primary.dark,
    textAlign: 'center',
    letterSpacing: '0.25px',
    lineHeight: '1.2em',
  },
});

interface IProps {
  open?: boolean;
  title: React.ReactNode;
  topButton?: React.ReactNode;
  bottomButton?: React.ReactNode;
  onClose(): void;
  children?: React.ReactNode;
}

const DialogComponent: React.FunctionComponent<IProps & WithStyles<typeof styles>> = ({
  open = false,
  classes,
  title,
  onClose,
  children,
  topButton,
  bottomButton,
}) => {
  return (
    <MUIDialog classes={{ paper: classes.dialogBody }} open={open} onClose={onClose}>
      <header style={{ position: 'relative' }}>
        <DialogTitle>
          <div className={classes.title}>{title}</div>
        </DialogTitle>
        <IconButton classes={{ root: classes.icon }} onClick={onClose}>
          <Close />
        </IconButton>
      </header>
      <DialogContent classes={{ root: classes.content }}>{children}</DialogContent>
      {(topButton || bottomButton) && (
        <footer>
          <DividerSection />
          <div style={{ padding: '16px' }}>
            {topButton}
            {bottomButton && <div style={{ margin: topButton ? '8px 0 0' : '' }}>{bottomButton}</div>}
          </div>
        </footer>
      )}
    </MUIDialog>
  );
};

export const Dialog = withStyles(styles)(DialogComponent);
