import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { ICampaignFormData, ICampaignViewModel, IPartnerCampaignOverViewModel } from './models';

export async function fetchCampaigns(
  partnerSupplierId: string,
  verticalCategory: string
): Promise<IPartnerCampaignOverViewModel[]> {
  return axios
    .get(`api/v1/suppliers/${partnerSupplierId}/campaigns/${verticalCategory}`)
    .then((response: AxiosResponse<IPartnerCampaignOverViewModel[]>) => response.data);
}

export async function createPartnerCampaign(
  providerId: string,
  formData: ICampaignFormData
): Promise<ICampaignViewModel> {
  return axios
    .post(`/api/v1/suppliers/${providerId}/campaigns`, formData)
    .then((response: AxiosResponse<ICampaignViewModel>) => response.data);
}

export async function fetchCampaign(providerId: string, campaignId: string): Promise<ICampaignViewModel> {
  return axios
    .get(`/api/v1/suppliers/${providerId}/campaigns/${campaignId}`)
    .then((response: AxiosResponse<ICampaignViewModel>) => response.data);
}

export async function updatePartnerCampaign(
  providerId: string,
  campaignId: string,
  campaignFormData: ICampaignFormData
): Promise<ICampaignViewModel> {
  return axios
    .put(`/api/v1/suppliers/${providerId}/campaigns/${campaignId}`, campaignFormData)
    .then((response: AxiosResponse<ICampaignViewModel>) => response.data);
}

export async function unpublishCampaign(providerId: string, campaignId: string): Promise<ICampaignViewModel> {
  return axios
    .put(`api/v1/suppliers/${providerId}/campaigns/${campaignId}/unpublish`)
    .then((response: AxiosResponse<ICampaignViewModel>) => response.data);
}

export async function archiveCampaign(partnerSupplierId: string, partnerCampaignId: string): Promise<void> {
  return axios.delete(`api/v1/suppliers/${partnerSupplierId}/campaigns/${partnerCampaignId}`).then(() => void 0);
}
