import PropTypes from 'prop-types';
import React from 'react';
import { ReactReduxContext } from 'react-redux';
import { wrapDisplayName } from 'recompose';
//@ts-ignore
import { __RouterContext as RouterContext } from 'react-router-dom';
/**
 * @deprecated `useEffect` can be used instead
 *
 * HOC for componentWillMount
 * onEnterFunc has access to the props as well as the store and router
 */
export const onEnter = (onEnterFunc: any) => (WrappedComponent: any) => {
  // eslint-disable-next-line react/no-unsafe
  class OnEnter extends React.Component {
    public static displayName = wrapDisplayName(WrappedComponent, 'OnEnter');

    public static propTypes = {
      __store: PropTypes.object,
      __router: PropTypes.object,
    };

    public UNSAFE_componentWillMount() {
      //@ts-ignore
      onEnterFunc(this.props, { store: this.props.__store, router: this.props.__router });
    }

    public render() {
      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __store, __router, ...rest } = this.props;
      return <WrappedComponent {...rest} />;
    }
  }

  return function withReduxStore(props: any) {
    return (
      <RouterContext.Consumer>
        {(router: any) => (
          <ReactReduxContext.Consumer>
            {({ store }: any) => <OnEnter {...props} __store={store} __router={router} />}
          </ReactReduxContext.Consumer>
        )}
      </RouterContext.Consumer>
    );
  };
};
