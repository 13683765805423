import type { IChangeOrder } from './models';
import { ChangeOrderState } from './models';

const MockOrder = (): IChangeOrder => {
  const randomYear = Math.floor(Math.random() * 10000);
  const randomId = Math.floor(Math.random() * 10000);

  return {
    id: 'foo',
    partnerSupplierId: 'foo',
    productName: `foo-${randomId}`,
    orderType: 'foo',
    customerId: 'foo',
    state: {
      stateType: ChangeOrderState.New,
      createdAt: new Date(),
    },
    partnerOrderId: 'foo',
    createdAt: `${randomYear}-01-01`,
  };
};

const mockOrders = [MockOrder(), MockOrder(), MockOrder()];
export async function fetchChangeOrders(): Promise<IChangeOrder[]> {
  return Promise.resolve(mockOrders);
  //TODO: uncomment and fetch real data
  /*return axios.get(`/api/v1/suppliers/${partnerSupplierId}/change`)
    .then((response: AxiosResponse<IChangeOrder[]>) => response.data);*/
}
