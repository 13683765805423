import type { TextByLanguage } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/config';
import type { CostWithCurrency } from '@provider-portal/models/currency';

export interface IChangePlanState {
  createdAt: Date;
  stateType: ChangePlanState;
}

export enum ChangePlanState {
  New = 'New',
  WaitingForUserInput = 'WaitingForUserInput',
  AcceptedByUser = 'AcceptedByUser',
  RejectedByUser = 'RejectedByUser',
  RejectedByProvider = 'RejectedByProvider',
  Success = 'Success',
}

export enum ChangePlanOfferType {
  CHANGE_PLAN_OFFER = 'ChangePlanOfferViewModel',
  NO_CHANGE_PLAN_OFFER = 'NoChangePlanOfferViewModel',
  CHANGE_PLAN_OFFER_BY_PHONE = 'ChangePlanOfferByPhoneViewModel',
  CHANGE_PLAN_PREDEFINED_OFFER = 'PredefinedChangePlanOfferViewModel',
  CHANGE_PLAN_OFFER_2 = 'ChangePlanOffer2OutcomeViewModel',
}

export interface IChangePlanOffer {
  type: ChangePlanOfferType.CHANGE_PLAN_OFFER;
  currentProductName?: string;
  currentPrice?: CostWithCurrency;
  contractBindingTime?: string;
  newProductName: string;
  newPrice: CostWithCurrency;
  newBindingTimeInMonths: number;
  campaignName?: string;
  priceWithCampaignApplied?: CostWithCurrency;
  campaignDurationInMonths?: number;
  description?: string;
  createdAt: string;
}

// RETURNED from API when creating, updating, publishing etc.
export interface IChangePlanOffer2 {
  id: string;
  name: TextByLanguage;
  description?: TextByLanguage;
  price: CostWithCurrency;
  paymentInterval: PaymentInterval;
  planId: string;
  offerState: ChangePlanOffer2State;
}

// SENT to server when creating, updating, publishing etc.
export interface IChangePlanOffer2FormData {
  name: TextByLanguage;
  description?: TextByLanguage;
  price: CostWithCurrency;
  paymentInterval: PaymentInterval;
  planId: string;
  action: ChangePlanOffer2FormAction;
}

// Data emitted from ChangePlan form. Not sent to the server without additional fields added.
export interface IChangePlan2FormDataInner {
  name: TextByLanguage;
  description?: TextByLanguage;
  price: number;
  paymentInterval: number;
  planId: string;
}

// Default ChangePlan form data
export interface IChangePlan2FormViewModelDefault {
  id?: string;
  name?: TextByLanguage;
  description?: TextByLanguage;
  price?: CostWithCurrency;
  paymentInterval?: PaymentInterval;
  planId?: string;
  offerState?: ChangePlanOffer2State;
}

// Returned from API when fetching a single offer
export interface IChangePlan2FormViewModel {
  id?: string;
  name: TextByLanguage;
  description?: TextByLanguage;
  price: CostWithCurrency;
  paymentInterval: PaymentInterval;
  planId: string;
  offerState: ChangePlanOffer2State;
}

// Returned from API when fetching a list of offers
export interface IChangePlanOffer2ListViewModel {
  id: string;
  name: string;
  description?: string;
  price: CostWithCurrency;
  paymentInterval: PaymentInterval;
  planId: string;
  offerState: ChangePlanOffer2State;
}

export enum ChangePlanOffer2State {
  Draft = 'Draft',
  PendingApproval = 'PendingApproval',
  Approved = 'Approved',
}

export enum ChangePlanOffer2FormAction {
  SAVE = 'Save',
  SAVE_AND_REQUEST_APPROVAL = 'SaveAndRequestApproval',
}

export const isOfferDraft = (offerState: ChangePlanOffer2State) =>
  [ChangePlanOffer2State.Draft, ChangePlanOffer2State.PendingApproval].includes(offerState);
export const isOfferActive = (offerState: ChangePlanOffer2State) => offerState === ChangePlanOffer2State.Approved;

export interface PaymentInterval {
  amount: number;
  unit: IntervalUnit;
}

export type IntervalUnit = 'day' | 'week' | 'month' | 'year';

export const paymentIntervalsPer: { interval: PaymentInterval }[] = [
  { interval: { amount: 1, unit: 'day' } },
  { interval: { amount: 1, unit: 'week' } },
  { interval: { amount: 2, unit: 'week' } },
  { interval: { amount: 1, unit: 'month' } },
  { interval: { amount: 2, unit: 'month' } },
  { interval: { amount: 3, unit: 'month' } },
  { interval: { amount: 4, unit: 'month' } },
  { interval: { amount: 6, unit: 'month' } },
  { interval: { amount: 1, unit: 'year' } },
];
export const paymentIntervalUnits: IntervalUnit[] = ['day', 'week', 'month', 'year'];

export const intervalToString =
  (localizeMessage: any) =>
  (interval: PaymentInterval): string => {
    const { unit, amount: count } = interval;

    switch (unit) {
      case 'day':
        return localizeMessage('perDay', { count });
      case 'week':
        return localizeMessage('perWeek', { count });
      case 'month':
        return localizeMessage('perMonth', { count });
      case 'year':
        return localizeMessage('perYear', { count });
      default:
        throw Error('This could never happen, if PaymentInterval is typed correctly');
    }
  };
