import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import * as React from 'react';
import type { IChecklistItem } from './ChecklistItem';
import { ChecklistItem } from './ChecklistItem';

export interface IChecklist {
  caption?: React.ReactNode;
  items: IChecklistItem[];
}

export const Checklist: React.FunctionComponent<IChecklist> = ({ caption, items }) => {
  return (
    <>
      {caption && <Caption colorVariant="darker">{caption}</Caption>}
      {items.map((item, index) => (
        <ChecklistItem key={index} {...item} />
      ))}
    </>
  );
};
