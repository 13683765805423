import type { Market } from '@provider-portal/app-context/constants';
import type { CategoryName, ICategoryProps } from '@provider-portal/constants/categories';
import type { CategoryDetailsFormDataType } from '@provider-portal/provider/models';
import type { VerticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';
import { verticalMarketKey } from '@provider-portal/libs/uiFramework/init/vertical-market-key';

export type CategoryDetailsMap = [VerticalMarketKey, CategoryDetailsFormDataType];

export const createCategoryDetailsFormDataMap = (
  categoryProps: ICategoryProps,
  market: Market,
  type: CategoryDetailsFormDataType
): CategoryDetailsMap => {
  return [verticalMarketKey(categoryProps.key as CategoryName, market), type];
};
