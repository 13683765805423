// @ts-ignore

import { Sentry } from '@provider-portal/sentry';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { createPartnerCampaign } from '../api';
import { CampaignForm } from '../CampaignForm';
import type { ICampaignFormData } from '../models';
import { SubmitType } from '../models';

interface IAddCampaignProps {
  providerId: string;
  categoryName: string;
}

const AddCampaignInner: React.FunctionComponent<IAddCampaignProps> = ({ providerId, categoryName }) => {
  const [submitType, setSubmitType] = React.useState<SubmitType | undefined>();
  const history = useHistory();
  const [loadingState, setLoadingState] = React.useState(PortalLoadingState.Success);

  const createCampaign = debouncePromise(async (formData: ICampaignFormData) => {
    if (submitType === SubmitType.PUBLISH) {
      formData.changesApproved = true;
    }

    try {
      const createdCampaign = await createPartnerCampaign(providerId, formData);
      const editCampaignUrl = URI(history.location.pathname)
        .segment(-1, createdCampaign.id || '')
        .toString();
      history.replace(editCampaignUrl);
    } catch (error) {
      setLoadingState(PortalLoadingState.Failure);
      Sentry.captureExceptionWithMessage(error, 'Failed to create campaign');
    }
  });

  return (
    <LoadingPage loadingState={loadingState}>
      <CampaignForm
        appBarTitle={<LocalizedMessage id="addCampaignTitleBar" />}
        leftSidedButtons={[
          <Button
            key="delete"
            label={<LocalizedMessage id="deleteButtonText" />}
            color="negative"
            onClick={() => history.goBack()}
          />,
        ]}
        rightSidedButtons={[
          <Button
            key="save-draft"
            label={<LocalizedMessage id="saveDraftButtonText" />}
            color="secondary"
            type="submit"
            onClick={() => setSubmitType(SubmitType.SAVE_DRAFT)}
          />,
          <Button
            key="publish"
            label={<LocalizedMessage id="publishButtonText" />}
            color="positive"
            type="submit"
            onClick={() => setSubmitType(SubmitType.PUBLISH)}
          />,
        ]}
        providerId={providerId}
        categoryName={categoryName}
        onSubmit={createCampaign}
        formData={{}}
      />
    </LoadingPage>
  );
};

export const AddCampaign = withLocalization('SupplierPortal/PartnerCampaign')(AddCampaignInner);
