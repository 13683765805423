import { dispatchBeforeConnect, errorPageOnFailure } from '@provider-portal/containers/container-helpers';
import type { ApplicationState } from '@provider-portal/store';
import React from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import { UserActions } from '../login/duck';
import { loadingPageWhileLoading } from '@provider-portal/fetching/loadingPageWhileLoading';

const TokenRouteInner: React.FunctionComponent<RouteComponentProps> = (props) => (
  <Redirect {...props} to={{ pathname: '/' }} />
);

interface TokenStateProps {
  loginWithTokenLoading: boolean;
  loginWithTokenFailed: boolean;
}

const mapTokenStateToProps = (state: ApplicationState): TokenStateProps => {
  return {
    loginWithTokenLoading: state.user.loginWithTokenLoading,
    loginWithTokenFailed: state.user.loginWithTokenFailed,
  };
};

export const TokenRoute = compose<RouteComponentProps, RouteComponentProps & { token: string }>(
  dispatchBeforeConnect((_: ApplicationState, dispatch: Dispatch, props: { token: string }) => {
    dispatch(UserActions.loginWithToken(props.token));
  }),
  connect(mapTokenStateToProps),
  loadingPageWhileLoading((p: TokenStateProps) => p.loginWithTokenLoading),
  errorPageOnFailure((p: TokenStateProps) => p.loginWithTokenFailed)
)(TokenRouteInner);
