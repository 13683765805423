import { SaveDeskListOffersPage } from '@provider-portal/saveDesk/SaveDeskListOffersPage';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { SaveDeskListOrdersPage } from './SaveDeskListOrdersPage';
import { SaveDeskOrderPage } from './SaveDeskOrderPage';
import { AddSaveDeskOffer } from '@provider-portal/saveDesk/AddSaveDeskOffer';
import { EditSaveDeskOffer } from '@provider-portal/saveDesk/EditSaveDeskOffer';
import { getDefaultCurrency } from '@provider-portal/app-context/state';
import { selectorMarketFromMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';
import { useSelector } from 'react-redux';

export const SaveDeskPortalRoutes: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  const market = useSelector(selectorMarketFromMaybePartnerSupplier);
  const currency = getDefaultCurrency(market);
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/orders`}
        // @ts-ignore
        render={(props) => <SaveDeskListOrdersPage partnerSupplierId={props.match.params.providerId} />}
      />
      <Route
        path={`${match.path}/orders/:saveDeskOrderId`}
        render={(props) => (
          <SaveDeskOrderPage
            // @ts-ignore
            partnerSupplierId={props.match.params.providerId}
            saveDeskOrderId={props.match.params.saveDeskOrderId}
            currency={currency}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/offers`}
        // @ts-ignore
        render={(props) => <SaveDeskListOffersPage partnerSupplierId={props.match.params.providerId} />}
      />
      <Route
        path={`${match.path}/offers/add`}
        // @ts-ignore
        render={(props) => <AddSaveDeskOffer providerId={props.match.params.providerId} />}
      />
      <Route
        path={`${match.path}/offers/:saveDeskOfferId`}
        render={(props) => (
          <EditSaveDeskOffer
            // @ts-ignore
            providerId={props.match.params.providerId}
            saveDeskOfferId={props.match.params.saveDeskOfferId}
          />
        )}
      />
    </Switch>
  );
};
