import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type {
  IChangePlanOffer2,
  IChangePlanOffer2FormData,
  IChangePlan2FormViewModel,
  IChangePlanOffer2ListViewModel,
} from './models';

export async function fetchChangePlanOffers(partnerSupplierId: string): Promise<IChangePlanOffer2ListViewModel[]> {
  return axios
    .get(`/api/v1/suppliers/${partnerSupplierId}/change-plan/offers`)
    .then((response: AxiosResponse<IChangePlanOffer2ListViewModel[]>) => response.data);
}

export async function fetchChangePlanOffer(
  partnerSupplierId: string,
  changePlanOfferId: string
): Promise<IChangePlan2FormViewModel> {
  return axios
    .get(`/api/v1/suppliers/${partnerSupplierId}/change-plan/offers/${changePlanOfferId}`)
    .then((response: AxiosResponse<IChangePlan2FormViewModel>) => response.data);
}

export async function createChangePlanOffer(
  partnerSupplierId: string,
  offerFormData: IChangePlanOffer2FormData
): Promise<IChangePlanOffer2> {
  return axios
    .post(`/api/v1/suppliers/${partnerSupplierId}/change-plan/offers`, offerFormData)
    .then((response: AxiosResponse<IChangePlanOffer2>) => response.data);
}

export async function updateChangePlanOffer(
  partnerSupplierId: string,
  changePlanOfferId: string,
  offerFormData: IChangePlanOffer2FormData
): Promise<IChangePlanOffer2> {
  return axios
    .post(`/api/v1/suppliers/${partnerSupplierId}/change-plan/offers/${changePlanOfferId}`, offerFormData)
    .then((response: AxiosResponse<IChangePlanOffer2>) => response.data);
}

export async function approveChangePlanOffer(
  partnerSupplierId: string,
  saveDeskOfferId: string
): Promise<IChangePlanOffer2> {
  return axios
    .put(`/api/v1/suppliers/${partnerSupplierId}/change-plan/offers/${saveDeskOfferId}`)
    .then((response: AxiosResponse<IChangePlanOffer2>) => response.data);
}

export async function unpublishChangePlanOffer(
  partnerSupplierId: string,
  saveDeskOfferId: string
): Promise<IChangePlanOffer2> {
  return axios
    .put(`/api/v1/suppliers/${partnerSupplierId}/change-plan/offers/${saveDeskOfferId}/unpublish`)
    .then((response: AxiosResponse<IChangePlanOffer2>) => response.data);
}

export async function archiveChangePlanOffer(
  partnerSupplierId: string,
  saveDeskOfferId: string
): Promise<IChangePlanOffer2> {
  return axios
    .delete(`/api/v1/suppliers/${partnerSupplierId}/change-plan/offers/${saveDeskOfferId}`)
    .then((response: AxiosResponse<IChangePlanOffer2>) => response.data);
}
