import { LocalizedMessage } from '@provider-portal/internationalization';
import type { PageComponent } from '@provider-portal/libs/uiFramework/components/layouts/config';
import { PageComponentType } from '@provider-portal/libs/uiFramework/components/layouts/config';
import React from 'react';
import type { ISaveDeskState } from '../models';
import { renderSaveDeskState } from '../SaveDeskListOrdersPage/config';
import type { ISaveDeskResponseCard } from './components/SaveDeskResponseCard/config';

export enum FieldNames {
  CURRENT_PRODUCT_NAME = 'currentProductName',
  CURRENT_PRICE = 'currentPrice',
  CONTRACT_BINDING_TIME = 'contractBindingTime',
  NEW_PRODUCT_NAME = 'newProductName',
  NEW_PRICE = 'newPrice',
  NEW_BINDING_TIME = 'newBindingTime',
  ANOTHER_IMPROVEMENT = 'anotherImprovement',
  CAMPAIGN_NAME = 'campaignName',
  PRICE_WITH_CAMPAIGN_APPLIED = 'priceWithCampaignApplied',
  CAMPAIGN_DURATION = 'campaignDuration',
  REASON = 'reason',
}

const getResponseCardConfig = (): ISaveDeskResponseCard => ({
  type: PageComponentType.SAVEDESK_RESPONSE_CARD,
  column: 1,
  actionsSection: {
    caption: <LocalizedMessage id="saveDeskActionsSectionCaption" />,
    columns: 1,
    components: [],
  },
});

export interface IGetComponentsProps {
  providerId: string;
  orderId: string;
  customerId: string;
  userName: string;
  createdAt: string;
  category: string;
  currency: string;
  email: string;
  phone: string;
  address: string;
  assignee?: string;
  state: ISaveDeskState;
  offerTitle: string;
}

export const getComponents = ({
  providerId,
  orderId,
  customerId,
  userName,
  createdAt,
  category,
  email,
  phone,
  address,
  assignee,
  state,
  offerTitle,
}: IGetComponentsProps): PageComponent[] => [
  {
    type: PageComponentType.SAVEDESK_REQUESTED_DETAILS_CARD,
    column: 1,
    orderDate: createdAt,
    category,
    offerTitle,
  },
  getResponseCardConfig(),
  {
    type: PageComponentType.ORDER_STATUS_CARD,
    column: 2,
    providerId,
    orderId,
    assignee,
    orderState: renderSaveDeskState(state),
  },
  {
    type: PageComponentType.DETAILS_CARD,
    column: 2,
    caption: <LocalizedMessage id="saveDeskOrderPageCustomerDetailsTitle" />,
    details: [
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsCustomerIdLabel" />,
        value: customerId,
      },
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsNameLabel" />,
        value: userName,
      },
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsEmailLabel" />,
        value: email,
      },
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsPhoneLabel" />,
        value: phone,
      },
      {
        caption: <LocalizedMessage id="saveDeskOrderDetailsAddressLabel" />,
        value: address,
      },
    ],
  },
];
