import { GetStartedPage } from '@provider-portal/developerTools/GetStartedPage';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

export const DeveloperToolsRoute: React.ComponentType<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/get-started`}
        render={(props) => (
          <GetStartedPage
            path={props.match.path}
            // @ts-ignore
            merchantId={props.match.params.providerId}
          />
        )}
      />
    </Switch>
  );
};
