import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const drawerWidth = 280;

const styles = (theme: any) => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },
  drawerHolder: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth + 10,
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'relative',
      height: '100%',
    },
    position: 'fixed !important',
    overflowY: 'auto',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
});

/**
 * Shows props.menu as a drawer on a page. Wraps the entire page.
 *
 * The drawer will always be open on big screens. On mobile it will be hidden, but can be opened through a toggle (which needs to be added on each page).
 * Adds `adminDrawerToggle` as a context in order to show a button for toggling the menu when for example using <AdminNavBar />.
 */
class AdminDrawerPage extends React.Component {
  public state = {
    mobileOpen: false,
  };

  public static childContextTypes = {
    adminDrawerToggle: PropTypes.func,
  };

  public handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  public getChildContext() {
    return { adminDrawerToggle: this.handleDrawerToggle };
  }

  public render() {
    //@ts-ignore
    const { classes, theme, children, menu } = this.props;

    return (
      <div className={classes.appFrame}>
        <>
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={this.state.mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {menu}
          </Drawer>
        </>
        {/*  @ts-ignore */}
        <Hidden mdDown implementation="css">
          <div className={classes.drawerHolder}>
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {menu}
            </Drawer>
          </div>
        </Hidden>

        <main className={classes.content}>{children}</main>
      </div>
    );
  }
}

//@ts-ignore
AdminDrawerPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.node,
  menu: PropTypes.node.isRequired,
};

// @ts-ignore
// eslint-disable-next-line import/no-default-export
export default withStyles(styles, { withTheme: true })(AdminDrawerPage);
