import { isFloatZeroOrGreater } from '@provider-portal/libs/validation';
import * as React from 'react';
import { HiddenInput } from '../HiddenInput';
import { HiddenInputDataFormat } from '../HiddenInput/config';
import { NumberInput, NumberType } from '../NumberInput';
import type { IValueUnitInput } from './config';

interface IValueUnitInputProps extends Omit<IValueUnitInput, 'type'> {
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const ValueUnitInput: React.FunctionComponent<IValueUnitInputProps> = (props) => {
  const [value, setValue] = React.useState<string>(props.defaultValue?.toString() || '');
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isFloatZeroOrGreater(event.target.value)) {
      setValue(parseValue(event.target.value));
    } else {
      setValue('');
    }
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <>
      <NumberInput
        {...props}
        name=""
        label={props.label}
        numberType={NumberType.FloatZeroOrGreater}
        defaultValue={value}
        onChange={onChange}
        locale={props.locale}
      />
      <HiddenInput
        name={props.name}
        dataFormat={HiddenInputDataFormat.JSON}
        value={(value && { amount: parseFloat(value), unit: props.unit }) || undefined}
      />
    </>
  );
};

function parseValue(cost: string = ''): string {
  return cost.replace(',', '.');
}
