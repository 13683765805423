import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import { ValidationFaCC } from '../ValidationHOC';
import { validationContextPropType } from '../Form';
import getDisplayName from 'recompose/getDisplayName';

function callIfFunction(maybeFunction: any, ...args: any) {
  if (isFunction(maybeFunction)) {
    return maybeFunction(...args);
  } else {
    return maybeFunction;
  }
}

export const WrappedProps = {
  name: PropTypes.string,
  value: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  canShowError: PropTypes.bool,
  formSubmitted: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  focused: PropTypes.bool,
  valid: PropTypes.bool,
};

// eslint-disable-next-line import/no-default-export
export default function (WrappedComponent: any) {
  // eslint-disable-next-line react/no-unsafe
  class InputValidationHOC extends React.Component {
    public static displayName = `InputValidationHOC(${getDisplayName(WrappedComponent)})`;

    public static propTypes = {
      ...omit(ValidationFaCC.propTypes, ['children']),
      showErrorOnBlur: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      showErrorOnChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      value: PropTypes.any,
      defaultValue: PropTypes.any,
      clear: PropTypes.bool,
    };

    public static defaultProps = {
      ...ValidationFaCC.defaultProps,
      showErrorOnBlur: false,
      showErrorOnChange: false,
      clear: false,
    };

    public static contextTypes = {
      validation: validationContextPropType.isRequired,
    };

    public state = {
      value: undefined,
      canShowError: false,
      formSubmitted: false,
      focused: false,
    };

    public UNSAFE_componentWillMount() {
      // @ts-ignore
      this.context.validation.addFormSubmittedListener(this.setFormSubmitted);
      //@ts-ignore
      this.setState({ ...this.state, value: this.props.defaultValue });
    }

    public componentWillUnmount() {
      // @ts-ignore
      this.context.validation.removeFormSubmittedListener(this.setFormSubmitted);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: any) {
      if (this.state.value !== undefined && nextProps.clear) {
        this.setState({ ...this.state, value: undefined, canShowError: false });
      }
      if (this.state.value === undefined && nextProps.defaultValue !== undefined) {
        this.setState({ ...this.state, value: nextProps.defaultValue });
      }
    }

    public setFormSubmitted = (formSubmitted: any) => {
      this.setState({
        ...this.state,
        canShowError: true,
        formSubmitted,
      });
    };

    public handleChange = (event: any) => {
      //@ts-ignore
      const { onChange, showErrorOnChange } = this.props;

      const value = event.target.value;
      const canShowError = this.state.canShowError || callIfFunction(showErrorOnChange, value);

      this.setState({ ...this.state, value, canShowError });

      if (onChange) {
        onChange(event);
      }
    };

    public handleFocus = () => {
      this.setState({ ...this.state, focused: true });
    };

    public handleBlur = (event: any) => {
      //@ts-ignore
      const { onBlur, showErrorOnBlur } = this.props;

      if (callIfFunction(showErrorOnBlur, this.state.value)) {
        this.setState({ ...this.state, canShowError: true, focused: false });
      } else {
        this.setState({ ...this.state, focused: false });
      }

      if (onBlur) {
        onBlur(event);
      }
    };

    public render() {
      //@ts-ignore
      const { name, validators, ...props } = this.props;
      const { canShowError, formSubmitted, focused } = this.state;

      //@ts-ignore
      const value = this.props.value != undefined ? this.props.value : this.state.value;

      return (
        <ValidationFaCC name={name} value={value} validators={validators}>
          {({ valid, errors }: any) => {
            const canBeShownErrors = (canShowError || formSubmitted) && !valid ? errors : null;

            const wrappedProps = {
              ...props,
              name,
              value,
              onFocus: this.handleFocus,
              onBlur: this.handleBlur,
              onChange: this.handleChange,
              canShowError,
              formSubmitted,
              errors: canBeShownErrors,
              focused,
              valid,
            };

            return <WrappedComponent {...wrappedProps} />;
          }}
        </ValidationFaCC>
      );
    }
  }

  return InputValidationHOC;
}
