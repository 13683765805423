import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { fetchSaveDeskOrder } from '../api';
import { SaveDeskActions } from '../duck';

export function useFetchOrderOnEnter(partnerSupplierId: string, saveDeskOrderId: string): PortalLoadingState {
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);

  React.useEffect(() => {
    fetchSaveDeskOrder(partnerSupplierId, saveDeskOrderId)
      .then((order) => {
        dispatch(SaveDeskActions.setSaveDeskOrder(order));
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
  }, [dispatch, partnerSupplierId, saveDeskOrderId]);

  return loadingState;
}
