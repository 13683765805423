import Grid from '@material-ui/core/Grid';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Subtitle2 } from '@provider-portal/libs/uiFramework/components/text/Subtitle2';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { parseMoment } from '@provider-portal/utils/date';
import * as React from 'react';
import type { SupplierPortalUser } from '../models';
import { Page } from '@provider-portal/libs/uiFramework/components/pages/Page';
import { Section } from '@provider-portal/libs/uiFramework/components/sections/Section';

export interface ViewUserComponentProps {
  user: SupplierPortalUser;
  onDeleteUser(): void;
}

export const ViewUserComponent: React.FunctionComponent<ViewUserComponentProps> = ({ user, onDeleteUser }) => {
  return (
    <Page appBarText={user.emailAddress} variant="details">
      <Paper>
        <Section>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Subtitle2>
                <LocalizedMessage id="username" />
              </Subtitle2>
              <Body textColor="default" colorVariant="dark">
                {user.username}
              </Body>
            </Grid>
            <Grid item xs={4}>
              <Subtitle2>
                <LocalizedMessage id="email" />
              </Subtitle2>
              <Body textColor="default" colorVariant="dark">
                {user.emailAddress}
              </Body>
            </Grid>
            <Grid item xs={4}>
              <Subtitle2>
                <LocalizedMessage id="firstName" />
              </Subtitle2>
              <Body textColor="default" colorVariant="dark">
                {user.name.first}
              </Body>
            </Grid>
            <Grid item xs={4}>
              <Subtitle2>
                <LocalizedMessage id="lastName" />
              </Subtitle2>
              <Body textColor="default" colorVariant="dark">
                {user.name.last}
              </Body>
            </Grid>
            <Grid item xs={4}>
              <Subtitle2>
                <LocalizedMessage id="locale" />
              </Subtitle2>
              <Body textColor="default" colorVariant="dark">
                {user.locale}
              </Body>
            </Grid>
            <Grid item xs={4}>
              <Subtitle2>
                <LocalizedMessage id="supplier" />
              </Subtitle2>
              <Body textColor="default" colorVariant="dark">
                {user.providerName}
              </Body>
            </Grid>
            <Grid item xs={4}>
              <Subtitle2>
                <LocalizedMessage id="createdAt" />
              </Subtitle2>
              <Body textColor="default" colorVariant="dark">
                {parseMoment(user.createdAt).format('YYYY-MM-DD')}
              </Body>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                label={<LocalizedMessage id="deleteUser" />}
                color="negative"
                variant="contained"
                onClick={onDeleteUser}
              />
            </Grid>
          </Grid>
        </Section>
      </Paper>
    </Page>
  );
};
