import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Dialog } from '@provider-portal/libs/uiFramework/components/dialogs/Dialog';
import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import { Section } from '@provider-portal/libs/uiFramework/components/sections/Section';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import * as React from 'react';
import { FieldNames } from '../../config';
import styles from './styles.scss';

interface IResponseProps {
  userName: string;
  dialogOpen: boolean;
  closeDialog(): void;
  onSendResponse(): void;
  loading?: boolean;
  sendResponseButtonLabel: React.ReactNode;
  replySent?: boolean;
  comment: string;
}

export const NoOfferSection: React.FunctionComponent<IResponseProps> = ({
  loading,
  dialogOpen,
  closeDialog,
  onSendResponse,
  userName,
  sendResponseButtonLabel,
  replySent,
  comment,
}) => {
  return (
    <Section caption={<LocalizedMessage id="saveDeskOrderPageActionNothingTitle" values={{ userName }} />}>
      <div>
        <Body textColor="primary" style={{ padding: '16px 0' }}>
          <LocalizedMessage id="saveDeskOrderPageActionNothingNote" values={{ userName }} />
        </Body>
        <TextInput
          disabled={replySent}
          defaultValue={comment}
          multiline
          name={FieldNames.REASON}
          label={<LocalizedMessage id="saveDeskOrderPageActionNothingReasonLabel" />}
        />
        <div className={styles.noResponsePadding}>
          <Panel1
            footer={
              !replySent && (
                <Button
                  type="submit"
                  disabled={replySent}
                  label={<LocalizedMessage id="saveDeskOrderPageActionNothingSendResponseLabel" />}
                  color="negative"
                />
              )
            }
            title={
              <Subtitle1>
                <LocalizedMessage id="saveDeskOrderPageWhatToExpectNextLabel" />
              </Subtitle1>
            }
          >
            <Body1>
              <LocalizedMessage id="saveDeskOrderPageActionNothingDescription" values={{ userName }} />
            </Body1>
          </Panel1>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title={<LocalizedMessage id="saveDeskOfferDialogTitle" values={{ userName }} />}
        topButton={
          <Button
            disabled={replySent}
            loading={loading}
            onClick={onSendResponse}
            fullWidth
            variant="contained"
            color="negative"
            label={sendResponseButtonLabel}
          />
        }
      >
        <DialogContent comment={comment} userName={userName} />
      </Dialog>
    </Section>
  );
};

interface IContentProps {
  comment: string;
  userName: string;
}

const DialogContent: React.FunctionComponent<IContentProps> = ({ comment, userName }) => {
  return (
    <>
      <Body1 style={{ margin: '0 0 16px' }}>
        <LocalizedMessage id="saveDeskRejectDescription" values={{ userName }} />
      </Body1>
      <Panel1>
        <Body1 style={{ fontStyle: 'italic' }}>{comment}</Body1>
      </Panel1>
    </>
  );
};
