import { TextField } from '@provider-portal/libs/uiFramework/components/deprecated/TextField';
import * as React from 'react';
import { HiddenInput } from '../../HiddenInput';
import { HiddenInputDataFormat } from '../../HiddenInput/config';
import { fileIsValid, fileValidator } from '../file-validation';
import type { FileType, IUploadFile } from '../models';
import { FileRow } from './FileRow';

interface IMultipleFileInputProps {
  name: string;
  defaultValue?: IUploadFile[];
  helperText?: React.ReactNode;
  onUpload(file: File): Promise<IUploadFile>;
  label?: React.ReactNode;
  allowedFileTypes: FileType[];
}

export const MultipleFileInput: React.FunctionComponent<IMultipleFileInputProps> = (props) => {
  const [files, setFiles] = React.useState(props.defaultValue || []);

  return (
    <>
      <TextField
        name=""
        fullWidth
        helperText={props.helperText}
        type="file"
        validators={{ fileIsValid: fileValidator(props.allowedFileTypes) }}
        onChange={async (e: any) => {
          if (e.target.files.length < 1 || !fileIsValid(props.allowedFileTypes, e.target.files[0].name)) return;

          const uploadedFile = await props.onUpload(e.target.files[0]);
          setFiles([...files, uploadedFile]);
        }}
        InputLabelProps={{ shrink: true }}
        label={props.label}
      />
      <HiddenInput name={props.name} value={files} dataFormat={HiddenInputDataFormat.JSON} />
      {files.map((file) => {
        return (
          <FileRow
            key={file.storageName}
            file={file}
            onDelete={() => setFiles(files.filter((f) => f.id !== file.id))}
          />
        );
      })}
    </>
  );
};
