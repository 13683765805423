import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import transform from 'lodash/transform';
import type { Middleware } from 'redux';
// @ts-ignore js import, remove this when the import is typed
import { hidePotentialEmailAddresses } from './utils/email';
// @ts-ignore js import, remove this when the import is typed
import { cleanState } from './utils/state';

// Error reporting to https://sentry.io
// https://docs.sentry.io/clients/javascript/usage/

let sentryEnabled: boolean = true;
if (!isTestEnvironment() && !isProductionEnvironment()) {
  disableSentry();
}

if (__VERSION__ === 'storybook') {
  disableSentry();
}

export function disableSentry() {
  if (sentryEnabled) {
    // eslint-disable-next-line no-console
    console.info('Sentry disabled. Errors and events will be logged to console instead.');
  }
  sentryEnabled = false;
}

declare global {
  const __VERSION__: string;
}

export function isProductionEnvironment() {
  const hostName = window.location.host;
  //There are other environments hosted under minatjanster.se (e.g. staging.minatjanster.se) so we need to be specific.
  const productionHosts = [
    'swedbank-frontend.minatjanster.se',
    'uppsagning.minatjanster.se',
    'admin2.minatjanster.se',
    'admin.minatjanster.se',
    'minatjanster.se',
  ];

  return includes(productionHosts, hostName) || includes(window.location.host, 'minna.tech');
}

function isTestEnvironment() {
  if (isProductionEnvironment()) {
    return false;
  }

  return (
    includes(window.location.host, 'minatjanster.nu') ||
    includes(window.location.host, 'minatjanster.se') ||
    includes(window.location.host, 'minna.io')
  );
}

export function getEnvironment(): string {
  if (isProductionEnvironment()) {
    return 'production-europe';
  }
  if (isTestEnvironment()) {
    if (includes(window.location.host, 'staging-europe.minna.io')) {
      return 'staging-europe';
    } else {
      return 'internal';
    }
  }
  return 'development';
}

function getDsn(): string | undefined {
  if (isProductionEnvironment()) {
    return 'https://3937486f4db3453b9e69d2cef40635ed@sentry.io/99342';
  }

  return 'https://9b826fba95dd40568060b2f17bba93d6@sentry.io/271789';
}

Sentry.init({
  dsn: getDsn(),
  release: `frontend@${__VERSION__ || 'development'}`,
  environment: getEnvironment(),
  integrations: [new Integrations.Dedupe(), new Integrations.ExtraErrorData()],
  // Error generated by a bug in auto-fill library from browser
  // https://github.com/getsentry/sentry/issues/5267
  ignoreErrors: ['/Blocked a frame with origin/'],
  beforeBreadcrumb(breadcrumb: any) {
    breadcrumb.message = hidePotentialEmailAddresses(breadcrumb.message);

    return breadcrumb;
  },
  beforeSend(event): Sentry.Event | null {
    const extra = event.extra || {};

    //Sentry has/had a bug that adds an unnecessary property String to the extra, remove this workaround when fixed
    // https://github.com/getsentry/sentry-javascript/issues/1805
    delete extra.String;

    event.extra = scrubPotentialPIIFromObject(
      maybeTruncateData(
        {
          ...extra,
          ...getReduxContext(),
        },
        extra
      )
    );

    const contexts = event.contexts || {};

    event.contexts = mapValues(contexts, (contextValue) =>
      contextValue ? scrubPotentialPIIFromObject(contextValue) : undefined
    );

    if (!sentryEnabled) {
      const maybeException: Sentry.Exception | undefined = get(event, 'exception.values[0]');

      if (__VERSION__ !== 'storybook') {
        if (maybeException) {
          // eslint-disable-next-line no-console
          console.warn(
            'Sentry event: %s\n Stacktrace: %o, Entire Sentry event: %o',
            event.message || maybeException.value,
            maybeException.stacktrace,
            event
          );
        } else {
          // eslint-disable-next-line no-console
          console.warn('Sentry event: %s\n Entire Sentry event %o', event.message, event);
        }
      }

      return null;
    }

    return event;
  },
});

const client = {
  ...Sentry,
  captureException(error: any, event?: any): void {
    Sentry.withScope((scope) => {
      scope.addEventProcessor(async (event2) => {
        return merge(event2, event);
      });
      Sentry.captureException(error);
    });
  },
  captureMessage(message: string, event?: any): void {
    Sentry.withScope((scope) => {
      scope.addEventProcessor(async (event2) => {
        return merge(event2, event);
      });
      Sentry.captureMessage(message);
    });
  },
  captureExceptionWithMessage(error: any, message: string, event: any = {}): void {
    Sentry.withScope((scope) => {
      scope.addEventProcessor(async (event2) => {
        event2.message = message;
        // Group events into one sentry issue if they have the same message
        event.fingerprint = ['{{ default }}', message];

        return merge(event2, event);
      });
      const messageError = new Error(message, { cause: error });
      // Remove sentry.ts from stacktrace
      Error.captureStackTrace?.(messageError, client.captureExceptionWithMessage);
      Sentry.captureException(messageError);
    });
  },
  setGlobalTag(key: string, value: string) {
    Sentry.configureScope((scope) => {
      scope.setTag(key, value);
    });
  },
};

// eslint-disable-next-line import/no-default-export
export default client;
export { client as Sentry };

// Keep track of the latest state / action that happened. Is sent to sentry when error occurs
let lastReduxState: any;

/**
 * Returns latest redux state
 */
function getReduxContext(): Record<string, unknown> {
  return {
    state: lastReduxState && cleanState(lastReduxState),
  };
}

export function reduxMiddleware(): Middleware {
  return ({ getState }) =>
    (next) =>
    (action) => {
      lastReduxState = getState();
      try {
        client.addBreadcrumb({
          category: 'redux',
          message: action.type,
        });

        return next(action);
      } catch (err) {
        client.captureException(err);
      }
    };
}

//The total limit for data sent to Sentry is 100 kb, an arbitrary limit could be half for each part of the data
const MAX_SIZE = 50 * 1024;

function maybeTruncateData(data: any, fallback: any): any {
  const sizeOfData = sizeOf(data);
  if (sizeOfData < MAX_SIZE) {
    return data;
  } else {
    const sizesAction: { [key: string]: number } = {};
    forEach(get(data, 'action', {}), (value, key) => {
      return (sizesAction[key] = sizeOf(value));
    });
    const sizesState: { [key: string]: number } = {};
    forEach(get(data, 'state', {}), (value, key) => {
      return (sizesState[key] = sizeOf(value));
    });
    if (sizeOf(fallback) < MAX_SIZE) {
      return { message: `Only including fallback since context is too big`, ...fallback, sizesAction, sizesState };
    } else {
      return { message: `Extra was too big: ${sizeOfData} bytes (approximately)`, sizesAction, sizesState };
    }
  }
}

function sizeOf(value: any, maxIterations: number = 20): number {
  if (isNil(value)) {
    return 0;
  }
  if (maxIterations <= 0) {
    // make sure we don't cycle infinitely
    return Infinity;
  }
  switch (typeof value) {
    case 'number':
      return 8;

    case 'string':
      return value.length * 2;

    case 'boolean':
      return 4;

    case 'object': {
      const objClass = Object.prototype.toString.call(value).slice(8, -1);
      if (objClass === 'Object' || objClass === 'Array') {
        let bytes = 0;
        for (const key in value) {
          // eslint-disable-next-line no-prototype-builtins
          if (!value.hasOwnProperty(key)) continue;
          bytes += sizeOf(value[key], maxIterations - 1);
        }

        return bytes;
      } else {
        return value.toString().length * 2;
      }
    }

    default:
      return 0;
  }
}

/** Keys with these names should always have their values scrubbed.
 * This is a last effort safety net, always consciously scrub any data that is sent to Sentry.
 */
const scrubbedKeys = [
  'city',
  'street',
  'email',
  'emailAddress',
  'name',
  'firstName',
  'lastName',
  'personalNumber',
  'personalIdentityNumber',
  'bankAccount',
  'bankAccountNumber',
  'password',
  'passwd',
  'apikey',
  'accessToken',
  'credentials',
  'card',
  'cardNumber',
  'number',
  'phoneNumber',
  '$email',
];

function scrubPotentialPIIFromObject(value: Record<string, unknown>): Record<string, unknown> {
  return transform(value, (result: any, val: any, key: string) => {
    result[key] = scrubbedKeys.includes(key) ? '*OMITTED*' : scrubPotentialPII(val);
  });
}

function scrubPotentialPII(value: Record<string, unknown> | string): Record<string, unknown> | string {
  if (isObject(value)) {
    return scrubPotentialPIIFromObject(value);
  }
  try {
    const jsonParsedValue = JSON.parse(value);
    if (isPlainObject(jsonParsedValue)) {
      const scrubbedObject = scrubPotentialPIIFromObject(jsonParsedValue);
      return JSON.stringify(scrubbedObject);
    }
    return value;
  } catch {
    return value;
  }
}
