import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import MUITabs from '@material-ui/core/Tabs';
import * as React from 'react';
import type { ITabConfig } from './config';
import { colors } from '@provider-portal/styles/colors';

const styles = createStyles({
  activeTab: {
    backgroundColor: colors.primary,
    color: colors.primaryLight,
    textTransform: 'none',
    borderRadius: '8px 8px 0 0',
  },
  inactiveTab: {
    backgroundColor: colors.primaryLight,
    color: colors.black,
    opacity: 1,
    textTransform: 'none',
    borderRadius: '8px 8px 0 0',
  },
});

interface ITabsProps {
  tabs: ITabConfig[];
  selectedTab?: string;
  onSwitchTab?(selectedTab: string): void;
}

export const TabsInner: React.FunctionComponent<ITabsProps & WithStyles<typeof styles>> = ({
  tabs,
  selectedTab,
  onSwitchTab,
  classes,
}) => {
  const currentTab = selectedTab || tabs[0].key;

  return (
    <MUITabs value={currentTab} onChange={(_, v) => onSwitchTab && onSwitchTab(v)} variant="fullWidth">
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.key}
            value={tab.key}
            label={tab.label}
            classes={{ root: currentTab === tab.key ? classes.activeTab : classes.inactiveTab }}
          />
        );
      })}
    </MUITabs>
  );
};

export const Tabs: React.ComponentType<ITabsProps> = withStyles(styles)(TabsInner);
