import CircularProgress from '@material-ui/core/CircularProgress';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import React from 'react';
import styles from './styles.scss';

interface ILoadingPageProps {
  loadingText?: React.ReactNode;
}

export const LoadingPage: React.FunctionComponent<ILoadingPageProps> = ({ loadingText }) => {
  return (
    <div className={styles.progressContainer}>
      <CircularProgress />
      <Subtitle1 style={{ marginTop: '32px' }}>{loadingText}</Subtitle1>
    </div>
  );
};
