import { Market } from '@provider-portal/app-context/constants';
import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import { TableColumnSortDirection } from '@provider-portal/libs/uiFramework/components/Table/config';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { LocalizedMessage } from '@provider-portal/internationalization';
import moment from 'moment';
import * as React from 'react';
import type { OrderType } from '../models';
import { getOrderType, renderOrderState } from '../utils';
import { renderAssigned } from '@provider-portal/utils/orderOverviewUtils';

export const switchOrdersToBeProcessedTableConfig = (market: Market): ITableConfig => ({
  cells: [
    {
      key: 'orderType',
      label: <LocalizedMessage id="orderType" />,
      renderCellValue: renderOrderType,
    },
    {
      key: 'personalIdentifier',
      label: personalIdentifierColumnTitle(market),
    },
    {
      key: 'productName',
      label: <LocalizedMessage id="productNameTableColumnTitle" />,
    },
    {
      key: 'createdAt',
      label: <LocalizedMessage id="createdTableColumnTitle" />,
      renderCellValue(createdAt: Date) {
        const daysSinceCreation = moment().diff(moment(createdAt), 'days');

        return (
          <Body1>
            {daysSinceCreation === 0 ? (
              <LocalizedMessage id="today" />
            ) : (
              (daysSinceCreation && (
                <LocalizedMessage id="daysAgoColumnValue" values={{ daysAgo: daysSinceCreation }} />
              )) ||
              '-'
            )}
          </Body1>
        );
      },
    },
    {
      key: 'sentToSupplierAt',
      label: <LocalizedMessage id="sentToSupplierTableColumnTitle" />,
      renderCellValue(sentToSupplierAt?: Date) {
        const daysSinceSending = sentToSupplierAt && moment().diff(moment(sentToSupplierAt), 'days');

        return (
          <Body1>
            {daysSinceSending === 0 ? (
              <LocalizedMessage id="today" />
            ) : (
              (daysSinceSending && (
                <LocalizedMessage id="daysAgoColumnValue" values={{ daysAgo: daysSinceSending }} />
              )) ||
              '-'
            )}
          </Body1>
        );
      },
    },
    {
      key: 'state',
      label: <LocalizedMessage id="statusTableColumnTitle" />,
      renderCellValue: renderOrderState,
      sort: 'state.name',
    },
    {
      key: 'assignee',
      label: <LocalizedMessage id="assignee" />,
      renderCellValue: renderAssigned,
      sort: 'assignee',
    },
  ],
  defaultSortColumn: 'sentToSupplierAt',
  defaultSortDirection: TableColumnSortDirection.Descending,
});

const renderOrderType = (orderType: OrderType) => {
  return (
    <Body1>
      <LocalizedMessage id={getOrderType(orderType)} />
    </Body1>
  );
};

function personalIdentifierColumnTitle(market: Market) {
  switch (market) {
    case Market.Sweden:
    case Market.Finland:
      return <LocalizedMessage id="personalIdentifierTableColumnTitle" />;
    default:
      return <LocalizedMessage id="customerIdTitle" />;
  }
}
