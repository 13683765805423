import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import { SwitchSupplierResponseCard } from '@provider-portal/orders/switch/SwitchOrderDetails/SwitchSupplierResponseCard';
import { SaveDeskRequestCard } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskRequestCard';
import { SaveDeskRequestedDetailsCard } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskRequestedDetailsCard';
import { SaveDeskResponseCard } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskResponseCard';
import { SaveDeskResponseCardOld } from '@provider-portal/saveDesk/SaveDeskOrderPage/components/SaveDeskResponseCardOld';
import React from 'react';
import { DetailsCard } from '../panels/DetailsCard';
import { InfoPapers } from '../panels/InfoPaper/InfoPapers';
import { OrderStatusCard } from '../panels/OrderStatusCard';
import type { PageComponent } from './config';
import { PageComponentType } from './config';

export const pageConfigToComponent = (c: PageComponent, i: number) => {
  switch (c.type) {
    case PageComponentType.DETAILS_CARD:
      return <DetailsCard key={i} title={c.caption} details={c.details} />;

    case PageComponentType.SAVEDESK_RESPONSE_CARD_OLD:
      return <SaveDeskResponseCardOld key={i} {...c} />;

    case PageComponentType.SAVEDESK_RESPONSE_CARD:
      return <SaveDeskResponseCard key={i} {...c} />;

    case PageComponentType.SAVEDESK_REQUESTED_DETAILS_CARD:
      return <SaveDeskRequestedDetailsCard key={i} {...c} />;

    case PageComponentType.SAVEDESK_REQUEST_CARD:
      return <SaveDeskRequestCard key={i} {...c} />;

    case PageComponentType.SWITCH_SUPPLIER_RESPONSE_CARD:
      return <SwitchSupplierResponseCard key={i} {...c} />;

    case PageComponentType.ORDER_STATUS_CARD:
      return <OrderStatusCard key={i} {...c} />;

    case PageComponentType.INFO_PAPERS:
      return <InfoPapers key={i} {...c} />;

    case PageComponentType.PRODUCT_DETAILS:
      return <Paper key={i}>{c.papers}</Paper>;
  }
};
