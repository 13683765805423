import type { Market } from '@provider-portal/app-context/constants';
import { ProductForm as UiFrameworkProductForm } from '@provider-portal/libs/uiFramework/components/ProductForm';
import * as React from 'react';
import type { IProductFormData, IProductViewModel } from '../models';

interface IProductFormProps {
  appBarTitle: React.ReactNode;
  formData: IProductViewModel;
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
  categoryName: string;
  onSubmit(formData: IProductFormData): Promise<void>;
  providerId: string;
  market: Market;
}

/**
 * The ProductForm is a wrapper for the ui-framework standard component.
 *
 * The ProductForm component is used like a wrapper to provide a external
 * component where the associated config files used by the ui-framework
 * can be found.
 *
 * Changes to the ProductForm should be put inside the config directory
 * found inside the ProductForm directory.
 */
export const ProductForm: React.FunctionComponent<IProductFormProps> = ({
  appBarTitle,
  formData,
  leftSidedButtons,
  rightSidedButtons,
  categoryName,
  onSubmit,
  providerId,
  market,
}) => {
  return (
    <UiFrameworkProductForm
      appBarTitle={appBarTitle}
      formData={formData}
      leftSidedButtons={leftSidedButtons}
      rightSidedButtons={rightSidedButtons}
      categoryName={categoryName}
      onSubmit={onSubmit}
      providerId={providerId}
      market={market}
    />
  );
};
