import type { WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import MuiToolTip from '@material-ui/core/Tooltip';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import * as React from 'react';
import { TooltipLink } from './TooltipLink';
import type { CSSProperties } from '@material-ui/core/styles/withStyles';

const htmlTooltip: CSSProperties = {
  maxWidth: '529px',
  boxShadow: '0px 4px 20px rgba(34, 34, 34, 0.25)',
  borderRadius: '8px',
  padding: '24px 16px',
  backgroundColor: '#fff',
  border: 'none',
  pointerEvents: 'all',
};

const popper: CSSProperties = {
  opacity: 1,
};

const muiStyles = {
  popper,
  htmlTooltip,
};

export interface ITooltipProps {
  tooltipTitle?: React.ReactNode;
  tooltipText?: React.ReactNode;
  tooltiplinkText?: React.ReactNode;
  tooltiplinkUrl?: string;
  children?: React.ReactNode;
}

const TooltipInner: React.FunctionComponent<ITooltipProps & WithStyles<typeof muiStyles>> = ({
  classes,
  tooltipTitle,
  tooltipText,
  tooltiplinkText,
  tooltiplinkUrl,
  children,
}) => {
  return (
    <MuiToolTip
      interactive
      placement="bottom-start"
      classes={{
        popper: classes.popper,
        tooltip: classes.htmlTooltip,
      }}
      title={
        <div>
          <Headline5 style={{ marginBottom: '16px' }} textColor="primary">
            {tooltipTitle}
          </Headline5>
          <Body>
            {tooltipText}
            {tooltiplinkText && tooltiplinkUrl && <TooltipLink linkText={tooltiplinkText} linkUrl={tooltiplinkUrl} />}
          </Body>
        </div>
      }
    >
      <span style={{ cursor: 'pointer' }}>{children}</span>
    </MuiToolTip>
  );
};

export const Tooltip = withStyles(muiStyles)(TooltipInner);
