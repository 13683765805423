import { LocalizedMessage } from '@provider-portal/internationalization';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { WidgetType } from '@provider-portal/libs/uiFramework/components/widgets/config';
import * as React from 'react';
import { mobileCampaignSection } from '../common';
import { categories } from '@provider-portal/constants/categories';
import { Market } from '@provider-portal/app-context/constants';
import type { CampaignFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createCampaignFormLayout } from '@provider-portal/libs/uiFramework';
import type { Currency } from '@provider-portal/models/subscription';
import type { Locale } from '@provider-portal/internationalization/locale';
import { getDefaultCurrency, getDefaultLocale } from '@provider-portal/app-context/state';

enum MobileCampaignFieldNames {
  ONE_TIME_DATA = 'oneTimeExtraDataAmountInGb',
  ONE_TIME_DISCOUNT = 'oneTimeDiscount',
  FORM_TYPE = 'type',
}

function mobileCampaignSections(currency: Currency, locale: Locale): IFieldSectionConfig[] {
  return [
    {
      caption: <LocalizedMessage id="campaignsTitleV2" />,
      captionText: <LocalizedMessage id="campaignMonthlyDiscountCaptionText" />,
      columns: 1,
      divider: 'top',
      components: [
        {
          type: WidgetType.MONTHLY_DISCOUNT,
        },
        HiddenInput({
          name: MobileCampaignFieldNames.FORM_TYPE,
          defaultValue: 'MobileCampaignIncomingRequest',
        }),
      ],
    },
    {
      caption: <LocalizedMessage id="campaignMonthlyDataSectionCaption" />,
      captionText: <LocalizedMessage id="campaignMonthlyDataSectionCaptionText" />,
      columns: 1,
      divider: 'top',
      components: [
        {
          type: WidgetType.MONTHLY_DATA_CAMPAIGN,
        },
      ],
    },
    {
      caption: <LocalizedMessage id="oneTimeCampaignsTitleV2" />,
      captionText: <LocalizedMessage id="oneTimeCampaignsCaptionText" />,
      columns: 2,
      divider: 'top',
      components: [
        NumberInput({
          name: MobileCampaignFieldNames.ONE_TIME_DATA,
          label: <LocalizedMessage id="oneTimeDataAmountLabel" />,
          numberType: NumberType.PositiveFloat,
          helperText: <LocalizedMessage id="oneTimeDataAmountHelperText" />,
          locale: locale,
        }),
        CostInput({
          name: MobileCampaignFieldNames.ONE_TIME_DISCOUNT,
          label: <LocalizedMessage id="welcomeDiscountLabelV2" />,
          currency: currency,
          locale: locale,
        }),
      ],
    },
  ];
}

function mobileCampaignPapers(market: Market): IFormPagePaper[] {
  const currency = getDefaultCurrency(market);
  const locale = getDefaultLocale(market);
  return [
    {
      sections: [mobileCampaignSection, ...mobileCampaignSections(currency, locale)],
    },
  ];
}

export const swedenMobileCampaignLayoutMap: CampaignFormLayoutMap = createCampaignFormLayout(
  categories.mobile,
  Market.Sweden,
  mobileCampaignPapers(Market.Sweden)
);
export const denmarkMobileCampaignLayoutMap: CampaignFormLayoutMap = createCampaignFormLayout(
  categories.mobile,
  Market.Denmark,
  mobileCampaignPapers(Market.Denmark)
);
export const belgiumMobileCampaignLayoutMap: CampaignFormLayoutMap = createCampaignFormLayout(
  categories.mobile,
  Market.Belgium,
  mobileCampaignPapers(Market.Belgium)
);
