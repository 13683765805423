import type { IImage } from '@provider-portal/libs/uiFramework/components/fields/Image/config';
import * as React from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () =>
  createStyles({
    image: {
      margin: '32px auto 64px auto',
      display: 'block',
    },
  });

interface IImageProps extends Omit<IImage, 'type'> {
  src: string;
  alt: string;
}

export const ImageFieldInner: React.FunctionComponent<IImageProps & WithStyles<typeof styles>> = ({
  src,
  alt,
  classes,
}) => {
  return <img src={src} alt={alt} className={classes.image} />;
};

export const ImageField = withStyles(styles)(ImageFieldInner);
