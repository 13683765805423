import { Market } from '@provider-portal/app-context/constants';
import { OrderType } from '@provider-portal/orders/switch/models';
import includes from 'lodash/includes';
import type { Moment } from 'moment';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

export interface Order {
  id: string;
  customer: any;
  userId: string;
  productCommission?: number;
  data: any;
  productName: string;
  note?: string;
  createdAt: string;
  state: any;
}

export const swedishHolidaysDate2021: string[] = [
  '2020-12-24',
  '2020-12-25',
  '2020-12-26',
  '2021-01-01',
  '2021-01-06',
  '2021-04-02',
  '2021-04-04',
  '2021-04-05',
  '2021-05-01',
  '2021-05-13',
  '2021-05-23',
  '2021-06-06',
  '2021-06-26',
  '2021-11-06',
  '2021-12-25',
  '2021-12-26',
];

export const isUnavailablePortingDateMoment =
  (orderType?: OrderType) =>
  (date: Moment, market: Market): boolean => {
    if (orderType === OrderType.MobileIndependent || orderType === OrderType.MobileSwitch) {
      const isWeekend = date.day() === 0 || date.day() === 6;
      const isSwedishHoliday = includes(swedishHolidaysDate2021, moment(date).format(dateFormat));

      return isWeekend || (market === Market.Sweden ? isSwedishHoliday : false);
    }
    return false;
  };

export enum OrderState {
  RejectedBySupplier = 'RejectedBySupplier',
  CancelledByUser = 'CancelledByUser',
  TerminationFailed = 'TerminationFailed',
  ChangeDataPlanRejected = 'ChangeDataPlanRejected',
  New = 'New',
  WaitForTerminationInformation = 'WaitForTerminationInformation',
  SentToSupplier = 'SentToSupplier',
  PendingSendToSupplier = 'PendingSendToSupplier',
  PendingWebhookSendToSupplier = 'PendingWebhookSendToSupplier',
  PendingWithSupplier = 'PendingWithSupplier',
  TechnicalError = 'TechnicalError',
  AcceptedBySupplier = 'AcceptedBySupplier',
  PaidBySupplier = 'PaidBySupplier',
  ChangeDataPlanAccepted = 'ChangeDataPlanAccepted',
  InvoiceSentToSupplier = 'InvoiceSentToSupplier',
}

export const successfulOrderStates = [
  OrderState.AcceptedBySupplier,
  OrderState.PaidBySupplier,
  OrderState.ChangeDataPlanAccepted,
  OrderState.InvoiceSentToSupplier,
];

export const failedOrderStates = [
  OrderState.RejectedBySupplier,
  OrderState.CancelledByUser,
  OrderState.TerminationFailed,
  OrderState.ChangeDataPlanRejected,
];

export enum RejectedReason {
  AlreadyCustomer = 'AlreadyCustomer',
  CreditCheckFailed = 'CreditCheckFailed',
  UnknownAddress = 'UnknownAddress',
  HasBindingPeriod = 'HasBindingPeriod',
  Unknown = 'Unknown',
}

export enum AcceptationRequirementType {
  PortingDate = 'AcceptationPortingDateRequirement',
}

export type AcceptationRequirement = AcceptationPortingDateRequirement;

export interface AcceptationPortingDateRequirement {
  type: string;
  portingDate: PortingDate;
  currentProviderName: string;
}

export enum PortingDateType {
  SpecificPortingDate = 'SpecificPortingDate',
  AsSoonAsPossible = 'AsSoonAsPossible',
}

export interface PortingDate {
  type: PortingDateType;
  date?: string;
}
