import { Sentry } from '@provider-portal/sentry';
import { InternationalizationActions } from '@provider-portal/internationalization/duck';
import { LoadingState } from '@provider-portal/models/LoadingState';
import { LoadingPage } from '@provider-portal/fetching/LoadingPage';
import { useDispatch } from '@provider-portal/utils/redux';
import * as React from 'react';
import { fetchLoggedInFromServer } from './api';
import { UserActions } from './duck';
import { LoginErrorPage } from './LoginErrorPage';
import { useMarketFromQueryParam } from '@provider-portal/utils/useMarketFromQueryParam';
import { Market } from '@provider-portal/app-context/constants';
import { defaultLocaleForMarket } from '@provider-portal/internationalization/locale';

const useFetchLoggedIn = () => {
  const dispatch = useDispatch();
  const maybeMarket = useMarketFromQueryParam(false);
  const [loadingState, setLoadingState] = React.useState<LoadingState>(LoadingState.NotStarted);

  React.useEffect(() => {
    async function fetchLoggedInAndTranslations() {
      const maybeLoggedIn = await fetchLoggedInFromServer();
      if (maybeLoggedIn) {
        await dispatch(
          InternationalizationActions.fetchMessagesAndLocaleProviderPortal(maybeLoggedIn.locale, maybeLoggedIn.market)
        );
        dispatch(UserActions.setLogin(maybeLoggedIn));
      } else {
        dispatch(UserActions.clearLogin());
        const market = maybeMarket || Market.UnitedKingdom;
        const locale = defaultLocaleForMarket(market);
        await dispatch(InternationalizationActions.fetchMessagesAndLocaleProviderPortal(locale, market));
      }
      setLoadingState(LoadingState.Success);
    }

    dispatch(UserActions.clearLogin());
    setLoadingState(LoadingState.Loading);
    fetchLoggedInAndTranslations().catch((error) => {
      Sentry.captureExceptionWithMessage(
        error,
        'Failed to fetch logged in user and set translations in supplier portal'
      );
      setLoadingState(LoadingState.Failure);
    });
  }, [dispatch, maybeMarket]);

  return loadingState;
};

interface Props {
  children?: React.ReactNode;
}

export const FetchLoggedIn: React.FunctionComponent<Props> = ({ children }) => {
  const loadingState = useFetchLoggedIn();

  if (loadingState === LoadingState.Success) {
    return <>{children}</>;
  } else if (loadingState === LoadingState.Failure) {
    return <LoginErrorPage />;
  } else {
    return <LoadingPage />;
  }
};
