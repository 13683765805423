import { selectorHasInternalAdminAccess } from '@provider-portal/login/duck';
import { Sentry } from '@provider-portal/sentry';
import { withLocalization } from '@provider-portal/internationalization';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { fetchChangePlanOffer } from '../api';
import type { IChangePlan2FormViewModel } from '../models';
import { ChangePlanOffer2FormAction } from '../models';
import { ChangePlanOfferSnackbarState, snackbarContent } from '../snackbar-state';
import { Snackbar } from '@provider-portal/libs/uiFramework/components/dialogs/Snackbar';
import { EditChangePlanOfferComponent } from '@provider-portal/features/changePlan/EditChangePlanOffer/component';

interface IEditChangePlanOfferProps {
  providerId: string;
  changePlanOfferId: string;
}

const EditChangePlanOfferInner: React.FunctionComponent<IEditChangePlanOfferProps> = ({
  providerId,
  changePlanOfferId,
}) => {
  const [loadingState, setLoadingState] = React.useState(PortalLoadingState.Loading);
  const [actionType, setActionType] = React.useState<ChangePlanOffer2FormAction>(ChangePlanOffer2FormAction.SAVE);
  const [changePlanOfferViewModel, setChangePlanOfferViewModel] = React.useState<
    IChangePlan2FormViewModel | undefined
  >();
  const [snackbarState, setSnackbarState] = React.useState<ChangePlanOfferSnackbarState>(
    ChangePlanOfferSnackbarState.CLOSED
  );
  const hasAdminAccess = useSelector(selectorHasInternalAdminAccess);

  React.useEffect(() => {
    fetchChangePlanOffer(providerId, changePlanOfferId)
      .then((formData) => {
        setChangePlanOfferViewModel(formData);
        setLoadingState(PortalLoadingState.Success);
      })
      .catch((error) => {
        setLoadingState(PortalLoadingState.Failure);
        Sentry.captureExceptionWithMessage(error, 'Failed to fetch change plan offer view model');
      });
  }, [providerId, changePlanOfferId]);

  return (
    <LoadingPage loadingState={loadingState}>
      {changePlanOfferViewModel && (
        <EditChangePlanOfferComponent
          providerId={providerId}
          changePlanOfferId={changePlanOfferId}
          viewModel={changePlanOfferViewModel}
          setLoadingState={setLoadingState}
          setSnackbarState={setSnackbarState}
          setViewModel={setChangePlanOfferViewModel}
          hasAdminAccess={hasAdminAccess}
          actionType={actionType}
          setActionType={setActionType}
        />
      )}
      <Snackbar
        autoClose
        variant={snackbarState === ChangePlanOfferSnackbarState.OFFER_UPDATED ? 'success' : 'info'}
        open={snackbarState !== ChangePlanOfferSnackbarState.CLOSED}
        content={snackbarContent(snackbarState)}
        onClose={() => setSnackbarState(ChangePlanOfferSnackbarState.CLOSED)}
      />
    </LoadingPage>
  );
};

export const EditChangePlanOffer = withLocalization('ChangePlan')(EditChangePlanOfferInner);
