import { LocalizedMessage } from '@provider-portal/internationalization';
import type { ITableConfig } from '@provider-portal/libs/uiFramework/components/Table/config';
import type { ITabConfig } from '@provider-portal/libs/uiFramework/components/Tabs/config';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { selectorChangeOrdersToBeProcessed, selectorClosedChangeOrders } from '../duck';
import type { IChangeOrder } from '../models';
import { ChangeTab, ordersTableConfig } from './config';

export interface IStateProps {
  tabsConfig: ITabConfig<ChangeTab>[];
  changeOrdersToBeProcessed: IChangeOrder[];
  closedChangeOrders: IChangeOrder[];
}

export function useMapStateToProps(): IStateProps {
  const changeOrdersToBeProcessed: IChangeOrder[] = useSelector(selectorChangeOrdersToBeProcessed);
  const closedChangeOrders: IChangeOrder[] = useSelector(selectorClosedChangeOrders);
  const tabsConfig: ITabConfig<ChangeTab>[] = [
    {
      key: ChangeTab.Pending,
      label: <LocalizedMessage id="pendingOrdersTabTitle" values={{ count: changeOrdersToBeProcessed.length }} />,
    },
    {
      key: ChangeTab.Processed,
      label: <LocalizedMessage id="processedOrdersTabTitle" values={{ count: closedChangeOrders.length }} />,
    },
  ];

  return {
    tabsConfig,
    changeOrdersToBeProcessed,
    closedChangeOrders,
  };
}

export interface IMapProps {
  tableData: IChangeOrder[];
  tableConfig: ITableConfig;
  onChangeOrderClick(changeOrder: IChangeOrder): void;
}

export function useMapProps(
  { changeOrdersToBeProcessed, closedChangeOrders }: IStateProps,
  selectedTab: ChangeTab
): IMapProps {
  const history = useHistory();
  const [tableData, tableConfig] = ((): [IChangeOrder[], ITableConfig] => {
    if (selectedTab === ChangeTab.Pending) {
      return [changeOrdersToBeProcessed, ordersTableConfig];
    } else {
      return [closedChangeOrders, ordersTableConfig];
    }
  })();

  return {
    tableData,
    tableConfig,
    onChangeOrderClick(changeOrder: IChangeOrder) {
      const changeOrderDetailsUrl = URI(history.location.pathname).segment(changeOrder.id).toString();
      history.push(changeOrderDetailsUrl);
    },
  };
}
