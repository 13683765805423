import type { CostWithCurrency, Currency } from '@provider-portal/models/currency';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import isNaN from 'lodash/isNaN';
import moment from 'moment';
import type {
  ISaveDeskOffer2,
  ISaveDeskOffer2FormData,
  ISaveDeskOffer2FormViewModel,
  ISaveDeskOffer2ListViewModel,
  ISaveDeskOfferCancellationReasonsSelectionForm,
  ISaveDeskOrder,
} from './models';
import { FieldNames } from './SaveDeskOrderPage/config';

export async function fetchSaveDeskOrders(partnerSupplierId: string): Promise<ISaveDeskOrder[]> {
  return axios
    .get(`/api/v1/suppliers/${partnerSupplierId}/save-desk`)
    .then((response: AxiosResponse<ISaveDeskOrder[]>) => response.data);
}

export async function fetchSaveDeskOrder(partnerSupplierId: string, partnerOrderId: string): Promise<ISaveDeskOrder> {
  return axios
    .get(`/api/v1/suppliers/${partnerSupplierId}/save-desk/${partnerOrderId}`)
    .then((response: AxiosResponse<ISaveDeskOrder>) => response.data);
}

export async function completeSaveDeskOrder(partnerSupplierId: string, partnerOrderId: string): Promise<void> {
  return axios.post(`/api/v1/suppliers/${partnerSupplierId}/save-desk/${partnerOrderId}/order-completed`);
}

export async function rescindSaveDeskOffer(
  partnerSupplierId: string,
  partnerOrderId: string,
  reason: string
): Promise<void> {
  return axios.post(`/api/v1/suppliers/${partnerSupplierId}/save-desk/${partnerOrderId}/rescind-offer`, {
    reason: reason,
  });
}

export async function fetchSaveDeskOffers(partnerSupplierId: string): Promise<ISaveDeskOffer2ListViewModel[]> {
  return axios
    .get(`/api/v1/suppliers/${partnerSupplierId}/save-desk/offers`)
    .then((response: AxiosResponse<ISaveDeskOffer2ListViewModel[]>) => response.data);
}

export async function fetchSaveDeskOffer(
  partnerSupplierId: string,
  saveDeskOfferId: string
): Promise<ISaveDeskOffer2FormViewModel> {
  return axios
    .get(`/api/v1/suppliers/${partnerSupplierId}/save-desk/offers/${saveDeskOfferId}`)
    .then((response: AxiosResponse<ISaveDeskOffer2FormViewModel>) => response.data);
}

export async function createSaveDeskOffer(
  partnerSupplierId: string,
  offerFormData: ISaveDeskOffer2FormData
): Promise<ISaveDeskOffer2> {
  return axios
    .post(`/api/v1/suppliers/${partnerSupplierId}/save-desk/offers`, offerFormData)
    .then((response: AxiosResponse<ISaveDeskOffer2>) => response.data);
}

export async function updateSaveDeskOffer(
  partnerSupplierId: string,
  saveDeskOfferId: string,
  offerFormData: ISaveDeskOffer2FormData
): Promise<ISaveDeskOffer2> {
  return axios
    .post(`/api/v1/suppliers/${partnerSupplierId}/save-desk/offers/${saveDeskOfferId}`, offerFormData)
    .then((response: AxiosResponse<ISaveDeskOffer2>) => response.data);
}

export async function approveSaveDeskOffer(
  partnerSupplierId: string,
  saveDeskOfferId: string
): Promise<ISaveDeskOffer2> {
  return axios
    .put(`/api/v1/suppliers/${partnerSupplierId}/save-desk/offers/${saveDeskOfferId}`)
    .then((response: AxiosResponse<ISaveDeskOffer2>) => response.data);
}

export async function unpublishSaveDeskOffer(
  partnerSupplierId: string,
  saveDeskOfferId: string
): Promise<ISaveDeskOffer2> {
  return axios
    .put(`/api/v1/suppliers/${partnerSupplierId}/save-desk/offers/${saveDeskOfferId}/unpublish`)
    .then((response: AxiosResponse<ISaveDeskOffer2>) => response.data);
}

export async function archiveSaveDeskOffer(
  partnerSupplierId: string,
  saveDeskOfferId: string
): Promise<ISaveDeskOffer2> {
  return axios
    .delete(`/api/v1/suppliers/${partnerSupplierId}/save-desk/offers/${saveDeskOfferId}`)
    .then((response: AxiosResponse<ISaveDeskOffer2>) => response.data);
}

export async function fetchSaveDeskCancellationReasonSelectionFormData(
  partnerSupplierId: string
): Promise<ISaveDeskOfferCancellationReasonsSelectionForm> {
  return axios
    .get(`/api/v1/suppliers/${partnerSupplierId}/save-desk/cancellation-reasons`)
    .then((response: AxiosResponse<ISaveDeskOfferCancellationReasonsSelectionForm>) => response.data);
}

// Remove below in DISCOVER-97
interface ISaveDeskOfferRequest {
  currentProductName?: string;
  currentPrice?: CostWithCurrency;
  contractBindingTime?: string;
  newProductName: string;
  newPrice: CostWithCurrency;
  newBindingTimeInMonths: number;
  campaignName?: string;
  priceWithCampaignApplied?: CostWithCurrency;
  campaignDurationInMonths?: number;
  description?: string;
}

interface ISaveDeskRejectionRequest {
  currentProductName?: string;
  currentPrice?: CostWithCurrency;
  contractBindingTime?: string;
  reason?: string;
}

interface ISaveDeskOfferByPhoneRequest {
  currentProductName?: string;
  currentPrice?: CostWithCurrency;
  contractBindingTime?: string;
}

export const SaveDeskOfferRequest = (formData: any, currency: Currency): ISaveDeskOfferRequest => {
  const currentPrice = parseFloat(formData[FieldNames.CURRENT_PRICE]);
  if (isNaN(currentPrice) && formData[FieldNames.CURRENT_PRICE].length > 0) throw new Error('currentPrice is NaN');

  const priceWithCampaign = parseFloat(formData[FieldNames.PRICE_WITH_CAMPAIGN_APPLIED]);
  if (isNaN(priceWithCampaign) && formData[FieldNames.PRICE_WITH_CAMPAIGN_APPLIED].length > 0)
    throw new Error('priceWithCampaign is NaN');

  const contractBindingTime = formData[FieldNames.CONTRACT_BINDING_TIME]
    ? moment(formData[FieldNames.CONTRACT_BINDING_TIME]).toISOString()
    : undefined;

  return {
    currentProductName: formData[FieldNames.CURRENT_PRODUCT_NAME] || undefined,
    currentPrice: currentPrice ? { amount: currentPrice, currency: currency } : undefined,
    contractBindingTime,
    newProductName: formData[FieldNames.NEW_PRODUCT_NAME],
    newPrice: { amount: parseFloat(formData[FieldNames.NEW_PRICE]), currency: currency },
    newBindingTimeInMonths: parseInt(formData[FieldNames.NEW_BINDING_TIME], 10),
    campaignName: formData[FieldNames.CAMPAIGN_NAME] || undefined,
    priceWithCampaignApplied: priceWithCampaign ? { amount: priceWithCampaign, currency: currency } : undefined,
    campaignDurationInMonths: parseInt(formData[FieldNames.CAMPAIGN_DURATION], 10) || undefined,
    description: formData[FieldNames.ANOTHER_IMPROVEMENT] || undefined,
  };
};

export const SaveDeskOfferByPhoneRequest = (formData: any, currency: Currency): ISaveDeskOfferByPhoneRequest => {
  const currentPrice = parseFloat(formData[FieldNames.CURRENT_PRICE]);
  if (isNaN(currentPrice) && formData[FieldNames.CURRENT_PRICE].length > 0) throw new Error('currentPrice is NaN');

  const contractBindingTime = formData[FieldNames.CONTRACT_BINDING_TIME]
    ? moment(formData[FieldNames.CONTRACT_BINDING_TIME]).toISOString()
    : undefined;

  return {
    currentProductName: formData[FieldNames.CURRENT_PRODUCT_NAME] || undefined,
    currentPrice: currentPrice ? { amount: currentPrice, currency: currency } : undefined,
    contractBindingTime,
  };
};

export const SaveDeskRejectionRequest = (formData: any, currency: Currency): ISaveDeskRejectionRequest => {
  const currentPrice = parseFloat(formData[FieldNames.CURRENT_PRICE]);
  if (isNaN(currentPrice) && formData[FieldNames.CURRENT_PRICE].length > 0) throw new Error('currentPrice is NaN');

  const contractBindingTime = formData[FieldNames.CONTRACT_BINDING_TIME]
    ? moment(formData[FieldNames.CONTRACT_BINDING_TIME]).toISOString()
    : undefined;
  const reason =
    formData[FieldNames.REASON] && formData[FieldNames.REASON].trim().length > 0
      ? formData[FieldNames.REASON]
      : undefined;

  return {
    currentProductName: formData[FieldNames.CURRENT_PRODUCT_NAME] || undefined,
    currentPrice: currentPrice ? { amount: currentPrice, currency: currency } : undefined,
    contractBindingTime,
    reason,
  };
};

export async function sendSaveDeskRejectionRequest(
  partnerSupplierId: string,
  partnerOrderId: string,
  formData: ISaveDeskRejectionRequest
): Promise<ISaveDeskOrder> {
  return axios
    .post(`/api/v1/suppliers/${partnerSupplierId}/save-desk/${partnerOrderId}/reject-offer`, formData)
    .then((response: AxiosResponse<ISaveDeskOrder>) => response.data);
}

export async function sendSaveDeskOfferRequest(
  partnerSupplierId: string,
  partnerOrderId: string,
  formData: ISaveDeskOfferRequest
): Promise<ISaveDeskOrder> {
  return axios
    .post(`/api/v1/suppliers/${partnerSupplierId}/save-desk/${partnerOrderId}/send-offer`, formData)
    .then((response: AxiosResponse<ISaveDeskOrder>) => response.data);
}

export async function saveDeskPhoneCallOrderAccepted(
  partnerSupplierId: string,
  partnerOrderId: string,
  formData: ISaveDeskOfferByPhoneRequest
): Promise<void> {
  return axios.post(
    `/api/v1/suppliers/${partnerSupplierId}/save-desk/${partnerOrderId}/order-completed-by-phone-accepted`,
    formData
  );
}

export async function saveDeskPhoneCallOrderRejected(
  partnerSupplierId: string,
  partnerOrderId: string,
  formData: ISaveDeskOfferByPhoneRequest
): Promise<void> {
  return axios.post(
    `/api/v1/suppliers/${partnerSupplierId}/save-desk/${partnerOrderId}/order-completed-by-phone-rejected`,
    formData
  );
}
