import * as React from 'react';
import styles from './styles.scss';

interface IHorizontalButtonGroupProps {
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
}

export const HorizontalButtonGroup: React.FunctionComponent<IHorizontalButtonGroupProps> = ({
  leftSidedButtons = [],
  rightSidedButtons = [],
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSided}>{leftSidedButtons}</div>
      <div className={styles.rightSided}>{rightSidedButtons}</div>
    </div>
  );
};
