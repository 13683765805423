import type { AmountWithCurrency } from '@provider-portal/models/subscription';
import { localizeCentesimal } from '@provider-portal/libs/uiFramework/components/fields/cost/localizeCentesimal';
import * as React from 'react';
import { HiddenInput } from '../../HiddenInput';
import { HiddenInputDataFormat } from '../../HiddenInput/config';
import { localizeCostUnit } from '../../MeasurementUnit';
import { NumberInput, NumberType } from '../../NumberInput';
import type { ICentesimalCostInput } from './config';

interface ICentesimalInput extends Omit<ICentesimalCostInput, 'type'> {
  backgroundColor?: string;
  disabled?: boolean;
}

export const CentesimalCostInput: React.FunctionComponent<ICentesimalInput> = (props) => {
  let defaultValue: number | undefined;
  if (props.defaultValue) {
    defaultValue = wholeCurrencyUnitToCentesimal(props.defaultValue);
  }
  const [costValue, setCostValue] = React.useState<string>(
    centesimalInputToWholeCurrencyUnit(defaultValue?.toString())
  );
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCostValue(centesimalInputToWholeCurrencyUnit(event.target.value));
  };

  return (
    <>
      <NumberInput
        {...props}
        name=""
        label={
          <>
            {props.label} ({localizeCentesimal(props.currency)} {props.unit && <>/ {localizeCostUnit(props.unit)}</>})
          </>
        }
        numberType={NumberType.FloatZeroOrGreater}
        defaultValue={defaultValue}
        onChange={onChange}
        locale={props.locale}
      />
      <HiddenInput
        name={props.name}
        dataFormat={HiddenInputDataFormat.JSON}
        value={
          (costValue && { amount: parseFloat(costValue), currency: props.currency, unit: props.unit }) || undefined
        }
      />
    </>
  );
};

function centesimalInputToWholeCurrencyUnit(value: string = ''): string {
  const parsedValue = parseFloat(value.replace(',', '.'));

  return isNaN(parsedValue) ? '' : (parsedValue / 100).toString();
}

function wholeCurrencyUnitToCentesimal(amountWithCurrency: AmountWithCurrency): number {
  return amountWithCurrency.amount * 100;
}
