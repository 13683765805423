import type { LocalizeCurrency } from '@provider-portal/internationalization';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { Dialog } from '@provider-portal/libs/uiFramework/components/dialogs/Dialog';
import { mapConfigsToFieldsOrWidgets } from '@provider-portal/libs/uiFramework/components/fields/configs';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import { FieldSection } from '@provider-portal/libs/uiFramework/components/sections/FieldSection';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import type { Currency } from '@provider-portal/models/currency';
import type {
  ISaveDeskOffer,
  ISaveDeskOffer2Outcome,
  ISaveDeskPredefinedOffer,
} from '@provider-portal/saveDesk/models';
import { SaveDeskOfferType, SaveDeskState } from '@provider-portal/saveDesk/models';
import { colors } from '@provider-portal/styles/colors';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FieldNames } from '../../config';
import styles from './styles.scss';

interface IResponseProps {
  userName: string;
  offerSection: IFieldSectionConfig;
  campaignSection: IFieldSectionConfig;
  anotherImprovementSection: IFieldSectionConfig;
  dialogOpen: boolean;
  closeDialog(): void;
  onSendOffer(): void;
  loading?: boolean;
  makeOfferButtonLabel: React.ReactNode;
  replySent?: boolean;
  supplierName: string;
  outcome?: ISaveDeskOffer | ISaveDeskPredefinedOffer | ISaveDeskOffer2Outcome;
  state: SaveDeskState;
  formData: any;
  currency: Currency;
}

const hideFooterStates = [
  SaveDeskState.AcceptedByUser,
  SaveDeskState.RejectedByProvider,
  SaveDeskState.RejectedByUser,
  SaveDeskState.Success,
];

export const SendOfferSection: React.FunctionComponent<IResponseProps> = (
  props,
  { localizeCurrency, localizeMessage }
) => {
  const description = () => {
    if (props.outcome?.type && props.outcome.type === SaveDeskOfferType.SAVE_DESK_PREDEFINED_OFFER) {
      return localizeMessage(`${props.outcome?.description}Title`);
    } else if (props.outcome?.type && props.outcome.type === SaveDeskOfferType.SAVE_DESK_OFFER_2) {
      if (props.supplierName === 'Storytel') {
        return localizeMessage('freeDaysTitle');
      } else if (props.supplierName === 'Fitness24seven') {
        return localizeMessage('discountTitle');
      } else {
        return undefined;
      }
    } else {
      return props.outcome?.description;
    }
  };

  return (
    <>
      <FieldSection
        columns={props.offerSection.columns}
        caption={props.offerSection.caption}
        components={mapConfigsToFieldsOrWidgets(props.offerSection.components, (f) => {
          let defaultValue;

          if (
            props.outcome?.type &&
            (props.outcome.type === SaveDeskOfferType.SAVE_DESK_PREDEFINED_OFFER ||
              props.outcome.type === SaveDeskOfferType.SAVE_DESK_OFFER)
          ) {
            if (f.name === FieldNames.NEW_PRODUCT_NAME) defaultValue = props.outcome?.newProductName;
            if (f.name === FieldNames.NEW_PRICE) defaultValue = props.outcome?.newPrice?.amount.toString();
            if (f.name === FieldNames.NEW_BINDING_TIME)
              defaultValue = props.outcome?.newBindingTimeInMonths?.toString();
          }

          return { ...f, disabled: props.replySent, defaultValue };
        })}
      />
      <FieldSection
        columns={props.campaignSection.columns}
        caption={props.campaignSection.caption}
        components={mapConfigsToFieldsOrWidgets(props.campaignSection.components, (f) => {
          let defaultValue;

          if (
            props.outcome?.type &&
            (props.outcome.type === SaveDeskOfferType.SAVE_DESK_PREDEFINED_OFFER ||
              props.outcome.type === SaveDeskOfferType.SAVE_DESK_OFFER)
          ) {
            if (f.name === FieldNames.CAMPAIGN_NAME) defaultValue = props.outcome?.campaignName;
            if (f.name === FieldNames.PRICE_WITH_CAMPAIGN_APPLIED)
              defaultValue = props.outcome?.priceWithCampaignApplied?.amount.toString();
            if (f.name === FieldNames.CAMPAIGN_DURATION)
              defaultValue = props.outcome?.campaignDurationInMonths?.toString();
          }

          return { ...f, disabled: props.replySent, defaultValue };
        })}
      />
      <FieldSection
        columns={props.anotherImprovementSection.columns}
        caption={props.anotherImprovementSection.caption}
        components={mapConfigsToFieldsOrWidgets(props.anotherImprovementSection.components, (f) => ({
          ...f,
          disabled: props.replySent,
          defaultValue: description(),
        }))}
      />
      {!hideFooterStates.includes(props.state) && (
        <div className={styles.footerPadding}>
          <Panel1
            footer={
              !props.replySent && (
                <Button
                  type="submit"
                  label={<LocalizedMessage id="saveDeskOrderPageMakeOfferLabel" />}
                  color="positive"
                />
              )
            }
            title={
              <Caption>
                <LocalizedMessage id="saveDeskOrderPageWhatToExpectNextLabel" />
              </Caption>
            }
          >
            <Body1>
              <LocalizedMessage
                id="saveDeskOrderPageWhatToExpectNextDescription"
                values={{ userName: props.userName }}
              />
            </Body1>
          </Panel1>
        </div>
      )}
      <Dialog
        open={props.dialogOpen}
        onClose={props.closeDialog}
        title={<LocalizedMessage id="saveDeskOfferDialogTitle" values={{ userName: props.userName }} />}
        topButton={
          <Button
            disabled={props.replySent}
            loading={props.loading}
            onClick={props.onSendOffer}
            fullWidth
            variant="contained"
            color="positive"
            label={props.makeOfferButtonLabel}
          />
        }
      >
        <DialogContent
          currentPrice={props.formData[FieldNames.CURRENT_PRICE]}
          contractBindingTimeDate={props.formData[FieldNames.CONTRACT_BINDING_TIME]}
          localizeCurrency={localizeCurrency}
          currency={props.currency}
          userName={props.userName}
          supplierName={props.supplierName}
          productPrice={props.formData[FieldNames.NEW_PRICE]}
          bindingTimeInMonths={props.formData[FieldNames.NEW_BINDING_TIME]}
          campaignPrice={props.formData[FieldNames.PRICE_WITH_CAMPAIGN_APPLIED]}
          campaignDurationInMonths={props.formData[FieldNames.CAMPAIGN_DURATION]}
          comment={props.formData[FieldNames.ANOTHER_IMPROVEMENT]}
        />
      </Dialog>
    </>
  );
};

SendOfferSection.contextTypes = {
  localizeCurrency: PropTypes.func.isRequired,
  localizeMessage: PropTypes.func.isRequired,
};

interface IContentProps {
  userName: string;
  supplierName: string;
  productPrice: string;
  bindingTimeInMonths: string;
  campaignPrice?: string;
  campaignDurationInMonths?: string;
  comment?: string;
  currency: Currency;
  currentPrice?: string;
  contractBindingTimeDate?: string;
  localizeCurrency: LocalizeCurrency;
}

const DialogContent: React.FunctionComponent<IContentProps> = ({
  currentPrice,
  contractBindingTimeDate,
  supplierName,
  productPrice,
  bindingTimeInMonths,
  campaignPrice,
  campaignDurationInMonths,
  comment,
  currency,
  localizeCurrency,
}) => {
  const contractBindingTimeInMonths = contractBindingTimeDate
    ? moment(contractBindingTimeDate).diff(Date.now(), 'month').toString()
    : '0';

  return (
    <>
      {currentPrice && (
        <Panel1
          title={
            <Caption style={{ color: colors.grey1 }}>
              <LocalizedMessage id="todayYouHave" />
            </Caption>
          }
        >
          <Body1>
            <LocalizedMessage
              id="pricePerMonth"
              values={{ pricePerMonth: currentPrice, currency: localizeCurrency(currency) }}
            />
          </Body1>
          <BindingtimeSection bindingTimeInMonths={contractBindingTimeInMonths} />
        </Panel1>
      )}
      <div style={{ marginTop: '16px' }}>
        <Panel1
          title={
            <Caption style={{ color: colors.grey1 }}>
              <LocalizedMessage id="offerFrom" values={{ supplierName }} />
            </Caption>
          }
        >
          {!campaignPrice && (
            <OfferSection localizeCurrency={localizeCurrency} productPrice={productPrice} currency={currency} />
          )}
          {campaignPrice && (
            <OfferWithCampaignSection
              campaignMonths={campaignDurationInMonths}
              localizeCurrency={localizeCurrency}
              productPrice={productPrice}
              campaignPrice={campaignPrice}
              currency={currency}
            />
          )}
          <BindingtimeSection bindingTimeInMonths={bindingTimeInMonths} />
          {comment && <Body1 style={{ paddingTop: '16px', fontStyle: 'italic' }}>{comment}</Body1>}
        </Panel1>
      </div>
    </>
  );
};

interface IOfferSectionProps {
  localizeCurrency: LocalizeCurrency;
  productPrice?: string;
  currency: Currency;
}

const OfferSection: React.FunctionComponent<IOfferSectionProps> = ({ productPrice, localizeCurrency, currency }) => {
  return (
    <>
      <Body1>
        <LocalizedMessage
          id="pricePerMonth"
          values={{ pricePerMonth: productPrice, currency: localizeCurrency(currency) }}
        />
      </Body1>
    </>
  );
};

interface IOfferWithCampaignSectionProps {
  localizeCurrency: LocalizeCurrency;
  campaignPrice?: string;
  productPrice?: string;
  currency: Currency;
  campaignMonths?: string;
}

const OfferWithCampaignSection: React.FunctionComponent<IOfferWithCampaignSectionProps> = ({
  campaignMonths,
  campaignPrice,
  localizeCurrency,
  currency,
  productPrice,
}) => {
  const localCurrency = localizeCurrency(currency);
  const values = {
    pricePerMonth: campaignPrice,
    localCurrency,
    months: campaignMonths || 0,
  };

  return (
    <>
      <Body1>
        <LocalizedMessage id="campaignPricePerMonth" values={values} />
      </Body1>
      <Body1>
        <LocalizedMessage id="thenPricePerMonth" values={{ pricePerMonth: productPrice, localCurrency }} />
      </Body1>
    </>
  );
};

interface IBindingtimeSectionProps {
  bindingTimeInMonths: string;
}

const BindingtimeSection: React.FunctionComponent<IBindingtimeSectionProps> = ({ bindingTimeInMonths }) => {
  const hasBindingTime = bindingTimeInMonths > '0';

  return (
    <>
      {hasBindingTime && (
        <Body1>
          <LocalizedMessage id="contractBindingTime" values={{ bindingTimeInMonths }} />
        </Body1>
      )}
      {!hasBindingTime && (
        <Body1>
          <LocalizedMessage id="noBindingTime" />
        </Body1>
      )}
    </>
  );
};
