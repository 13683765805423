import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';

const styles = {
  reduceIconButtonMargin: { margin: '-8px -8px 0 0' },
};

interface IPanel1IconProps {
  icon: React.ReactNode;
  onClick?(): void;
}

export const Panel1Icon: React.FunctionComponent<IPanel1IconProps> = ({ icon, onClick }) => {
  return (
    <>
      {onClick ? (
        <IconButton onClick={onClick} style={styles.reduceIconButtonMargin}>
          {icon}
        </IconButton>
      ) : (
        icon
      )}
    </>
  );
};
