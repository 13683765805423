import * as React from 'react';
import { NoContent } from '../NoContent';
import { TableComponent } from './component';
import type { ITableConfig } from './config';
import { useSort, useUpdateQueryWithSortParameters } from './use-sort';

interface ITableProps {
  config: ITableConfig;
  data: any[];
  onRowClick?(value: any): void;
}

export const Table: React.FunctionComponent<ITableProps> = ({ config, data, onRowClick }) => {
  const { maybeColumnSorted, maybeSortDirection, dataSorted } = useSort(config, data);
  const updateQuery = useUpdateQueryWithSortParameters();
  const changeSort = (column: string) => {
    updateQuery(maybeColumnSorted, maybeSortDirection, column);
  };

  if (dataSorted.length === 0) {
    return <NoContent />;
  } else {
    return (
      <TableComponent
        config={config}
        data={dataSorted}
        columnSorted={maybeColumnSorted}
        sortDirection={maybeSortDirection}
        onSortChanged={changeSort}
        onRowClick={onRowClick}
      />
    );
  }
};
