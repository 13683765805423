import { PortalLoadingState } from '@provider-portal/types/loading-state';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { fetchSaveDeskOrders } from '../api';
import { SaveDeskActions } from '../duck';

export function useFetchOrdersOnEnter(partnerSupplierId: string): PortalLoadingState {
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = React.useState<PortalLoadingState>(PortalLoadingState.Loading);

  React.useEffect(() => {
    fetchSaveDeskOrders(partnerSupplierId)
      .then((orders) => {
        dispatch(SaveDeskActions.setSaveDeskOrders(orders));
        setLoadingState(PortalLoadingState.Success);
      })
      .catch(() => {
        setLoadingState(PortalLoadingState.Failure);
      });
  }, [dispatch, partnerSupplierId]);

  return loadingState;
}
