import { DividerSection } from '@provider-portal/libs/uiFramework/components/dividers/DividerSection';
import { isField } from '@provider-portal/libs/uiFramework/components/fields/configs/type-guards';
import { SectionNumber } from '@provider-portal/libs/uiFramework/components/SectionNumber';
import { FieldSection } from '@provider-portal/libs/uiFramework/components/sections/FieldSection';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import moment from 'moment';
import * as React from 'react';
import type { ISaveDeskOrder, SaveDeskOfferOld } from '../../../models';
import { FieldNames } from '../../config';
import type { SaveDeskActions } from './actions';

interface IProps {
  currentDetailsSection: IFieldSectionConfig;
  actionsSection: IFieldSectionConfig;
  saveDeskOrder: ISaveDeskOrder;
  offerSent?: boolean;
  action: SaveDeskActions;
  onSetAction(action: SaveDeskActions): void;
}

export const SharedFormFields: React.FunctionComponent<IProps> = ({
  currentDetailsSection,
  actionsSection,
  offerSent,
  saveDeskOrder,
  action,
  onSetAction,
}) => {
  const outcome = saveDeskOrder.outcome ? (saveDeskOrder.outcome as SaveDeskOfferOld) : undefined;

  return (
    <>
      <FieldSection
        sectionNumber={<SectionNumber disabled={offerSent} number={1} />}
        columns={currentDetailsSection.columns}
        caption={currentDetailsSection.caption}
        components={currentDetailsSection.components.map((f) => {
          let defaultValue;

          if (f.name === FieldNames.CURRENT_PRODUCT_NAME) defaultValue = outcome?.currentProductName;
          if (f.name === FieldNames.CONTRACT_BINDING_TIME)
            defaultValue = outcome?.contractBindingTime ? moment(outcome?.contractBindingTime).toDate() : undefined;
          if (f.name === FieldNames.CURRENT_PRICE) defaultValue = outcome?.currentPrice?.amount.toString();

          return {
            ...f,
            disabled: offerSent,
            defaultValue,
          };
        })}
      />
      <DividerSection />
      <FieldSection
        sectionNumber={<SectionNumber disabled={offerSent} number={2} />}
        columns={actionsSection.columns}
        caption={actionsSection.caption}
        components={actionsSection.components.map((f) => {
          return {
            ...f,
            disabled: offerSent,
            checked: isField(f) && f.value === action,
            onChange: (e) => onSetAction(e.target.value),
          };
        })}
      />
    </>
  );
};
