import type { LocalizeMessage } from '@provider-portal/internationalization';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { LoadingPage } from '@provider-portal/libs/uiFramework/components/pages/LoadingPage';
import * as React from 'react';
import { useFetchOrdersOnEnter } from './use-fetch';
import { useMapProps } from './use-props';
import { useSort } from '@provider-portal/libs/uiFramework/components/Table';
import { TableOverviewPage } from '@provider-portal/libs/uiFramework/components/pages/TableOverviewPage';
import { TableControls } from '@provider-portal/orders/switch/ListSwitchOrders/TableControls';
import { useSwitchOrderFilter } from '@provider-portal/orders/switch/ListSwitchOrders/SwitchOrderFilter';
import { ContentFilteredOut } from '@provider-portal/libs/uiFramework/components/ContentFilteredOut';
import PropTypes from 'prop-types';
import type { Market } from '@provider-portal/app-context/constants';

export interface IListSwitchOrdersPageProps {
  partnerSupplierId: string;
  categoryName: string;
  market: Market;
}

const ListSwitchOrdersPageInner: React.FunctionComponent<IListSwitchOrdersPageProps> = (
  { partnerSupplierId, categoryName, market },
  { localizeMessage }
) => {
  const { loadingState, switchOrders } = useFetchOrdersOnEnter(partnerSupplierId, categoryName);
  const mappedProps = useMapProps(switchOrders, market);
  const { dataSorted } = useSort(mappedProps.tableConfig, mappedProps.tableData);
  const switchOrderFilter = useSwitchOrderFilter();

  const allOrders = mappedProps.tableData;
  const orderCount = allOrders.length;
  const exportOrders = mappedProps.onExportOrders(partnerSupplierId, categoryName);
  const contentFilteredOutMessage = createContentFilteredOutMessage(
    switchOrderFilter.ordersFilteredOutCount(allOrders),
    switchOrderFilter.clearFilters,
    localizeMessage
  );

  return (
    <LoadingPage loadingState={loadingState}>
      <LoadingPage
        loadingState={mappedProps.ordersExportLoadingState}
        loadingText={<LocalizedMessage id="exportingOrdersLoadingText" />}
      >
        <TableOverviewPage
          appBarText={<LocalizedMessage id="listOrdersPageTitle" />}
          table={mappedProps.tableConfig}
          data={switchOrderFilter.filterOrders(allOrders)}
          onRowClick={mappedProps.onSwitchOrderClick}
          aboveTableElement={
            <TableControls switchOrderFilter={switchOrderFilter} allOrders={allOrders} onExport={onExport} />
          }
          noContentMessage={orderCount > 0 && contentFilteredOutMessage}
        ></TableOverviewPage>
      </LoadingPage>
    </LoadingPage>
  );

  async function onExport() {
    await exportOrders(switchOrderFilter.filterOrders(dataSorted));
  }
};

function createContentFilteredOutMessage(
  ordersFilteredOutCount: number,
  onClearFilters: () => void,
  localizeMessage: LocalizeMessage
) {
  return (
    <ContentFilteredOut
      onClearFilters={onClearFilters}
      contentFilteredOutMessage={localizeMessage('numberOfOrdersFilteredOutMessage', {
        ordersFilteredOutCount: ordersFilteredOutCount.toString(),
      })}
    />
  );
}

ListSwitchOrdersPageInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const ListSwitchOrdersPage: React.ComponentType<IListSwitchOrdersPageProps> =
  withLocalization('SupplierPortal/orders')(ListSwitchOrdersPageInner);
