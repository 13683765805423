// When updating this model also update the copy in '/integration-tests/cypress/models/index.ts'
export enum AppType {
  Standalone = 'Standalone',
  Swedbank = 'Swedbank',
  OP = 'OpBank',
  DanskeBank = 'DanskeBank',
  ING = 'Ing',
  CancellationPortal = 'CancellationPortal',
  SupplierPortal = 'SupplierPortal',
  Admin = 'Admin',
  SB1 = 'Sb1',
  Tatra = 'Tatra',
}

// When updating this model also update the copy in '/integration-tests/cypress/models/index.ts'
export interface DisplayNameIncoming {
  default: string;
  [key: string]: string;
}

export type DisplayName = string;

export enum Market {
  Sweden = 'Sweden',
  Finland = 'Finland',
  Denmark = 'Denmark',
  Belgium = 'Belgium',
  UnitedKingdom = 'UnitedKingdom',
  Norway = 'Norway',
  Slovakia = 'Slovakia',
  Spain = 'Spain',
  UnitedStates = 'UnitedStates',
  Australia = 'Australia',
}
