import React from 'react';
import { CampaignIdSelect } from '../CampaignIdSelect';
import { MonthlyDataCampaign } from '../MonthlyDataCampaign';
import { MonthlyDiscount } from '../MonthlyDiscount';
import { SwedenPhoneCallsLimitationInput } from '../SwedenPhoneCallsLimitationInput';
import { TermsAndConditionsUpload } from '../TermsAndConditionsUpload';
import type { IWidget } from './';
import * as TypeGuards from './type-guards';
import { TranslatableField } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField';

export function widgetFactory(widgetRequest: IWidget): React.ReactElement {
  if (TypeGuards.isCampaignIdSelect(widgetRequest)) {
    return <CampaignIdSelect {...widgetRequest} />;
  } else if (TypeGuards.isSwedenPhoneCallsLimitationInput(widgetRequest)) {
    return <SwedenPhoneCallsLimitationInput {...widgetRequest} />;
  } else if (TypeGuards.isTermsAndConditionsUpload(widgetRequest)) {
    return <TermsAndConditionsUpload {...widgetRequest} />;
  } else if (TypeGuards.isMonthlyDiscount(widgetRequest)) {
    return <MonthlyDiscount {...widgetRequest} />;
  } else if (TypeGuards.isMonthlyDataCampaign(widgetRequest)) {
    return <MonthlyDataCampaign {...widgetRequest} />;
  } else if (TypeGuards.isTranslatableFieldWidget(widgetRequest)) {
    return <TranslatableField {...widgetRequest} />;
  } else {
    throw new Error('Widget is missing implementation');
  }
}
