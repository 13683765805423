import { LocalizedMessage } from '@provider-portal/internationalization';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { selectorHasInternalAdminAccess } from '@provider-portal/login/duck';
import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IProductViewModel } from '../models';
import { ProductState, SubmitType } from '../models';

interface IPublishButtonProps {
  productFormData: IProductViewModel;
  onClick(submitType: SubmitType): void;
}

export const PublishButton: React.FunctionComponent<IPublishButtonProps> = ({ productFormData, onClick }) => {
  const hasAdminAccess = useSelector(selectorHasInternalAdminAccess);

  if (hasAdminAccess || Boolean(productFormData.commission)) {
    return (
      <Button
        label={
          productFormData.state?.type === ProductState.Approved ? (
            <LocalizedMessage id="saveAndApproveNewChangesButton" />
          ) : (
            <LocalizedMessage id="publishButton" />
          )
        }
        color="positive"
        type="submit"
        onClick={() => onClick(SubmitType.PUBLISH)}
      />
    );
  } else {
    return (
      <Button
        label={<LocalizedMessage id="requestApprovalButton" />}
        color="positive"
        type="submit"
        onClick={() => onClick(SubmitType.REQUEST_APPROVAL)}
      />
    );
  }
};
