import React from 'react';
import type { IChecklist } from './ChecklistItem';
import { Checklist } from './ChecklistItem';
import type { ISimpleValueItem } from './SimpleValueItem';
import { SimpleValueItem } from './SimpleValueItem';

export type IInfoItem = ISimpleValueItem | IChecklist;

export const InfoItem: React.FunctionComponent<IInfoItem> = (props) => {
  if (isSimpleValueItem(props)) {
    return <SimpleValueItem {...props} />;
  } else if (isChecklist(props)) {
    return <Checklist {...props} />;
  } else {
    return null;
  }
};

function isSimpleValueItem(infoItem: IInfoItem): infoItem is ISimpleValueItem {
  return (infoItem as ISimpleValueItem).value !== undefined;
}

function isChecklist(infoItem: IInfoItem): infoItem is IChecklist {
  return (infoItem as IChecklist).items !== undefined;
}
