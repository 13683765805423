// @ts-ignore js import, remove this when the import is typed
import { dispatchBeforeConnect } from '@provider-portal/containers/container-helpers';
import { handleFetchable } from '@provider-portal/fetching/handleFetchable';
import { withLocalization } from '@provider-portal/internationalization';
import type { Actions as SupplierActions } from '@provider-portal/PartnerSupplier/duck';
import { PartnerSupplierActions, selectorFetchablePartnerSuppliers } from '@provider-portal/PartnerSupplier/duck';
import type { ApplicationState } from '@provider-portal/store';
import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import { createUser } from '../api';
import type { Actions as UserActions } from '../duck';
import type { SupplierPortalUserRequest } from '../models';
import type { AddUserComponentProps } from './component';
import { AddUserComponent } from './component';

function mapStateToProps(state: ApplicationState, props: RouteComponentProps) {
  return {
    partnerSuppliers: selectorFetchablePartnerSuppliers(state),
    onCreateUser: debouncePromise(async (incomingUser: SupplierPortalUserRequest) => {
      return createUser(incomingUser).then(() => props.history.replace('/admin/users'));
    }),
  };
}

export const AddUser = compose<AddUserComponentProps, Record<string, never>>(
  dispatchBeforeConnect((applicationState: ApplicationState, dispatch: Dispatch<UserActions | SupplierActions>) => {
    dispatch(PartnerSupplierActions.fetchPartnerSuppliers());
  }),
  withRouter,
  connect(mapStateToProps),
  withLocalization('SupplierPortal/UserManagement'),
  handleFetchable('partnerSuppliers')
)(AddUserComponent);
