import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApplicationState } from '../store';
import type { PartnerProviderSettings } from './models';

export type ISettingsState = PartnerProviderSettings;

const initialState: ISettingsState = {};

export const { reducer, actions: PartnerProviderSettingsActions } = createSlice({
  name: 'PARTNER_PROVIDER_SETTINGS',
  initialState,
  reducers: {
    initialize: (_, action: PayloadAction<ISettingsState>) => action.payload,
  },
});

export const selectorSettings = (state: ApplicationState) => state.settings;
