import type { ActionsUnion } from '@provider-portal/utils/redux';
import { createAction } from '@provider-portal/utils/redux';
import type React from 'react';

enum ActionKeys {
  SET_VALIDATOR = 'SUPPLIER_PORTAL_FORM/SET_VALIDATOR',
  SET_FORM_ERRORS = 'SUPPLIER_PORTAL_FORM/SET_FORM_ERRORS',
  CLEAR_ERRORS = 'SUPPLIER_PORTAL_FORM/CLEAR_ERRORS',
}

export interface FieldError {
  errorMessage: React.ReactNode;
}

export type Validator = (form: any) => undefined | FieldError;

export interface IValidators {
  [key: string]: Validator;
}

export const validateFields = (form: any, validators: IValidators): FormErrors | undefined => {
  const errors = Object.keys(validators).reduce((acc, funcName) => {
    const error = validators[funcName](form);

    if (error) {
      return { ...acc, [funcName]: error };
    } else {
      return { ...acc, [funcName]: undefined };
    }
  }, {});

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export interface FormErrors {
  [key: string]: FieldError;
}

export const FormActions = {
  setValidator: (name: string, validator: Validator) => createAction(ActionKeys.SET_VALIDATOR, { name, validator }),
  setFormErrors: (errors: FormErrors) => createAction(ActionKeys.SET_FORM_ERRORS, errors),
  clearErrors: () => createAction(ActionKeys.CLEAR_ERRORS),
};

export type Actions = ActionsUnion<typeof FormActions>;

export interface IFormState {
  validators: IValidators;
  formErrors: FormErrors;
}

const initState: IFormState = {
  validators: {},
  formErrors: {},
};

export const formReducer = (state: IFormState = initState, action: Actions): IFormState => {
  switch (action.type) {
    case ActionKeys.SET_VALIDATOR:
      return { ...state, validators: { ...state.validators, [action.payload.name]: action.payload.validator } };

    case ActionKeys.SET_FORM_ERRORS:
      return { ...state, formErrors: action.payload };

    case ActionKeys.CLEAR_ERRORS:
      return { ...state, formErrors: {} };

    default:
      return state;
  }
};
