import type { Locale } from '@provider-portal/internationalization/locale';
import type { AmountWithCurrency, Currency } from '@provider-portal/models/subscription';
import type { IInputField } from '../../configs';
import { fieldConstructor, FieldType } from '../../configs';
import type { MeasurementUnit } from '../../MeasurementUnit';

export interface ICentesimalCostInput extends Omit<IInputField, 'currency' | 'locale'> {
  type: FieldType.CentesimalCost;
  defaultValue?: AmountWithCurrency;
  unit?: MeasurementUnit;
  currency: Currency;
  locale: Locale;
}

export const CentesimalCostInput = fieldConstructor<ICentesimalCostInput>(FieldType.CentesimalCost);
