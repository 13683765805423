import type { Market } from '@provider-portal/app-context/constants';
import type { Locale } from '@provider-portal/internationalization/locale';
import type { RecaptchaToken } from '@provider-portal/login/LoginPage/recaptcha';
import type { OffsetDateTime } from '@provider-portal/models/OffsetDateTime';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';

export enum LoggedInUserType {
  LoggedInInternalAdmin = 'LoggedInInternalAdmin',
  LoggedInPartnerUser = 'LoggedInPartnerUser',
}
export interface LoggedInUser {
  type: LoggedInUserType;
  id: string;
  username?: string;
  emailAddress: string;
  loggedInPartnerSupplierId?: string;
  createdAt: OffsetDateTime;
  csatRequestedAt: OffsetDateTime[];
  locale: Locale;
  market: Market;
}

export async function fetchLoggedInFromServer(): Promise<LoggedInUser | undefined> {
  return axios
    .get('api/frontend/v1/logged-in-user')
    .then((response: AxiosResponse<LoggedInUser>) => response.data)
    .catch((error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        return undefined;
      } else {
        throw error;
      }
    });
}

export async function logoutServer(): Promise<void> {
  return axios.post('api/frontend/v1/auth/logout').then((response: AxiosResponse<void>) => response.data);
}

export async function requestLoginServer(username: string, recaptchaToken: RecaptchaToken): Promise<void> {
  return axios
    .post('api/frontend/v1/auth/request-login', { username, recaptchaToken })
    .then((response: AxiosResponse<void>) => response.data);
}

export async function loginWithTokenServer(token: string): Promise<LoggedInUser> {
  return axios
    .post(`api/frontend/v1/auth/login/${token}`)
    .then((response: AxiosResponse<LoggedInUser>) => response.data);
}
