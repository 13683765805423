import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import type { WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { ArrowBackIcon } from '@provider-portal/libs/uiFramework/components/deprecated/ArrowBackIcon';
import { colors } from '@provider-portal/styles/colors';
import { white } from '@provider-portal/styles/colors-standard';
import PropTypes from 'prop-types';
import * as React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Headline5 } from '../../text/Headline5';

const styles = createStyles({
  colorSecondary: {
    backgroundColor: colors.grey1,
  },
});

interface IDetailsPageAppBarProps {
  colorVariant?: 'primary' | 'grey';
  showMenuForSmallerScreen?: boolean;
  showBackButton?: boolean;
  children?: React.ReactNode;
}

type DetailsPageAppBarComponentProps = IDetailsPageAppBarProps & RouteComponentProps & WithStyles<typeof styles>;

export const DetailsPageAppBarComponent: React.FunctionComponent<DetailsPageAppBarComponentProps> = (
  { colorVariant = 'primary', children, history, showMenuForSmallerScreen = false, showBackButton = true, classes },
  { adminDrawerToggle }
) => {
  function onBackButtonClicked() {
    history.goBack();
  }

  return (
    <AppBar
      position="static"
      classes={{ colorSecondary: classes.colorSecondary }}
      color={(colorVariant === 'primary' && 'primary') || 'secondary'}
    >
      <Toolbar>
        {/* @ts-ignore */}
        <Hidden lgUp>
          {showMenuForSmallerScreen ? (
            <IconButton onClick={adminDrawerToggle} style={{ marginRight: '16px' }}>
              <MenuIcon htmlColor={white.main} />
            </IconButton>
          ) : (
            <IconButton onClick={onBackButtonClicked} style={{ marginRight: '16px' }}>
              <ArrowBackIcon htmlColor={white.main} />
            </IconButton>
          )}
        </Hidden>
        {showBackButton ? (
          <>
            {/* @ts-ignore */}
            <Hidden mdDown>
              <IconButton onClick={onBackButtonClicked} style={{ marginRight: '16px' }}>
                <ArrowBackIcon htmlColor={white.main} />
              </IconButton>
            </Hidden>
          </>
        ) : null}
        <Headline5 ellipsis textColor="alternative" maxWidth="800px">
          {children}
        </Headline5>
      </Toolbar>
    </AppBar>
  );
};

export const DetailsPageAppBar = withStyles(styles)(withRouter(DetailsPageAppBarComponent));

DetailsPageAppBarComponent.contextTypes = {
  adminDrawerToggle: PropTypes.func,
};
