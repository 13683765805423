import type { AxiosResponse } from 'axios';
import axios from 'axios';

export interface IPartnerCampaign {
  id: string;
  name: string;
}

export async function fetchCampaigns(providerId: string, categoryName: string): Promise<IPartnerCampaign[]> {
  return axios
    .get(`/api/v1/suppliers/${providerId}/campaigns/${categoryName}/approved`)
    .then((response: AxiosResponse<IPartnerCampaign[]>) => response.data);
}
