import PropTypes from 'prop-types';
import React from 'react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import { validationContextPropType } from '../Form';
import getDisplayName from 'recompose/getDisplayName';
// eslint-disable-next-line react/no-unsafe
export class ValidationFaCC extends React.Component {
  public static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    validators: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.func), PropTypes.arrayOf(PropTypes.func)]).isRequired,
    children: PropTypes.func.isRequired,
  };

  public static defaultProps = {
    validators: [],
  };

  public static contextTypes = {
    validation: validationContextPropType.isRequired,
  };

  public state = {
    valid: true,
    errors: null,
  };

  public UNSAFE_componentWillMount() {
    // @ts-ignore
    this.context.validation.register(this);

    //@ts-ignore
    const { value, validators } = this.props;
    this.setState(this.computeState(value, validators));
  }

  public componentWillUnmount() {
    // @ts-ignore
    this.context.validation.unregister(this);
  }

  public UNSAFE_componentWillReceiveProps({ value, validators }: any) {
    this.setState(this.computeState(value, validators));
  }

  public computeState = (value: any, validators: any) => {
    const errors = this.validate(value, validators);
    const valid = isEmpty(errors);

    return {
      valid,
      errors,
    };
  };

  public validate = (value: any, validators: any) => {
    if (isArray(validators)) {
      const evaluated = map(validators, (validator) => validator(value));
      return compact(evaluated);
    } else {
      const evaluated = mapValues(validators, (validator) => validator(value));
      return pickBy(evaluated, (result) => Boolean(result));
    }
  };

  public render() {
    //@ts-ignore
    return this.props.children(this.state);
  }
}

// eslint-disable-next-line import/no-default-export
export default function (WrappedComponent: any) {
  const ValidationHOC = (props: any) => (
    <ValidationFaCC {...props}>
      {(state: any) => {
        const childProps = omit(props, ['validators']);
        return <WrappedComponent {...childProps} {...state} />;
      }}
    </ValidationFaCC>
  );

  ValidationHOC.displayName = `ValidationHOC(${getDisplayName(WrappedComponent)})`;

  ValidationHOC.propTypes = omit(ValidationFaCC.propTypes, ['children']);

  return ValidationHOC;
}
