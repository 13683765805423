import type { IntlShape } from '@formatjs/intl';
import type { FormatNumberOptions } from '@formatjs/intl/lib/src/types';
import { Market } from '@provider-portal/app-context/constants';
import type { CostWithCurrency } from '@provider-portal/models/currency';
import { Currency } from '@provider-portal/models/subscription';
import Sentry from '@provider-portal/sentry';
import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import tail from 'lodash/tail';
import PropTypes from 'prop-types';
import React from 'react';
// @ts-ignore
import { useIntl } from 'react-intl';
import * as uuid from 'uuid';

export const withLocalization =
  (localizationNamespace: any) =>
  (WrappedComponent: any): any => {
    class WrappedWithLocalization extends React.Component {
      public getChildContext() {
        const namespace = isFunction(localizationNamespace) ? localizationNamespace(this.props) : localizationNamespace;

        return {
          //@ts-ignore
          localizeMessage: localizeMessageFromNamespace(namespace, this.props.intl.formatMessage),
          //@ts-ignore
          localizeHTMLMessage: localizeMessageFromNamespace(namespace, this.props.intl.formatHTMLMessage),
          //@ts-ignore
          localizeCostWithCurrency: localizeCostWithCurrency(this.props.intl.formatNumber),
          //@ts-ignore
          localizeCostWithCentesimalCurrency: localizeCostWithCentesimalCurrency(this.props.intl.formatNumber),
          //@ts-ignore
          localizeCurrency: localizeCurrency(this.props.intl.formatNumber),
          //@ts-ignore
          localizeNumber: localizeNumber(this.props.intl.formatNumber),
          //@ts-ignore
          localizeDate: (date: any, format: any) => this.props.intl.formatDate(date, { format }),
          //@ts-ignore
          locale: this.props.intl.locale,
          localizationNamespace,
        };
      }

      public render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    //@ts-ignore
    WrappedWithLocalization.childContextTypes = {
      localizeMessage: PropTypes.func.isRequired,
      localizeHTMLMessage: PropTypes.func.isRequired,
      localizeCostWithCurrency: PropTypes.func.isRequired,
      localizeCostWithCentesimalCurrency: PropTypes.func.isRequired,
      localizeCurrency: PropTypes.func.isRequired,
      localizeNumber: PropTypes.func.isRequired,
      localizeDate: PropTypes.func.isRequired,
      locale: PropTypes.string.isRequired,
      localizationNamespace: PropTypes.string,
    };

    return withIntl(WrappedWithLocalization);
  };

function withIntl(Component: any) {
  return function WrappedComponent(props: any) {
    const intl = useIntl();

    return <Component {...props} intl={intl} />;
  };
}

export const useLocalization = (localizationNamespace: string): WithLocalizationContextType => {
  return localizationFunctions(useIntl(), localizationNamespace);
};

const localizationFunctions = (intl: IntlShape<any>, localizationNamespace: string) => {
  return {
    localizeCostWithCurrency: localizeCostWithCurrency(intl.formatNumber),
    localizeMessage: localizeMessageFromNamespace(localizationNamespace, intl.formatMessage),
    localizeCostWithCentesimalCurrency: localizeCostWithCentesimalCurrency(intl.formatNumber),
    localizeCurrency: localizeCurrency(intl.formatNumber),
    isCurrencyAfterNumber: isCurrencyAfterNumber(intl.formatNumber),
    localizeNumber: localizeNumber(intl.formatNumber),
    localizeDate: (date: string | Date, format?: string) => intl.formatDate(date, { format }),
    locale: intl.locale,
    localizationNamespace,
  };
};

/**
 * Creates a HOC which injects a localized version of `fn` as a property
 *
 * @param namespace - the translation namespace used by the function
 * @param func - e.g. function(localizeMessage) {...}
 * @param propName - will inject the new localized function under this name
 *
 * @returns a higher-order component
 *
 * @usage
 * This is useful if you're creating a function that requires translations.
 * This gives you a wrapper, which you can use to inject a "localized" version of your function into your components.
 *
 * @example
 *  // en-GB.json
 *  "isEven": "{x} is not even at all!!!"
 *  "isOdd": "{x} is super even :)"
 *
 *  // Function that uses translation
 *  const checkEven = localizeMessage => x => {
 *    if (x % 2 === 0) {
 *      return localizeMessage('isEven', {x})
 *    } else {
 *      return localizeMessage('isOdd', {x})
 *    }
 *  }
 *
 *  // HOC that injects function
 *  const injectCheckEven = withLocalizedFunctionProp('check-even-namespace', checkEven, 'checkEven')
 *
 *  // Component that wants to use function
 *  const MyComponent = injectCheckEven(({some, props, checkEven}) => {
 *    const isEven = checkEven(1); // "1 is not even at all!!!"
 *    return <div>{ isEven.toUpperCase() }</div>
 *  });
 */
export const withLocalizedFunctionProp = (namespace: any, func: any, propName: any) => (WrappedComponent: any) =>
  function InjectedWithFunction(props: any) {
    const InjectedWithContext = (
      translationProps: any,
      { localizeMessage, localizeDate, localizeNumber, localizeCurrency }: any
    ) => {
      const fnProp = {
        [propName]: func(localizeMessage, localizeDate, localizeNumber, localizeCurrency),
      };
      return <WrappedComponent {...props} {...fnProp} />;
    };
    InjectedWithContext.contextTypes = {
      localizeMessage: PropTypes.func.isRequired,
      localizeDate: PropTypes.func.isRequired,
      localizeNumber: PropTypes.func.isRequired,
      localizeCurrency: PropTypes.func.isRequired,
    };
    const InjectedWithEverything = withLocalization(namespace)(InjectedWithContext);
    return <InjectedWithEverything />;
  };

const localizeMessageFromNamespace = (localizationNamespace: any, formatMessage: any) => (id: any, values: any) => {
  const idNamespaceIncluded = localizationNamespace ? `${localizationNamespace}.${id}` : id;
  const message = formatMessage({ id: idNamespaceIncluded }, values);
  // If we have missed a translation we want to show a short default text instead of
  // the long translation id that can break the page layout.
  if (message === idNamespaceIncluded) {
    Sentry.captureException(new Error(`Missing translation for ${idNamespaceIncluded}`));
    return 'MISSING';
  }

  return message;
};

const localizeCostWithCurrency =
  (formatNumber: any) =>
  (cost: any, optionalDecimals = 2) => {
    if (optionalDecimals > 0) {
      const decimals = Math.abs(Math.round((cost.amount * 100) % 100));
      if (decimals !== 0 && decimals !== 100) {
        return formatNumber(cost.amount, {
          style: 'currency',
          currency: cost.currency,
          minimumFractionDigits: optionalDecimals,
          maximumFractionDigits: optionalDecimals,
        });
      }
    }

    return formatNumber(cost.amount, {
      style: 'currency',
      currency: cost.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

const localizeCostWithCentesimalCurrency = (formatNumber: any) => (cost: any, market: Market) => {
  const amount = formatNumber(cost.amount * 100, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const centesimalCurrency = centesimalCurrencyFromCurrency(cost.currency, market);

  return `${amount} ${centesimalCurrency}`;
};

const centesimalCurrencyFromCurrency = (currency: any, market: Market): any => {
  switch (currency) {
    case Currency.SEK:
      return 'öre';
    case Currency.EUR:
      if (market === Market.Finland) {
        return 'snt';
      } else {
        return 'cents';
      }
  }
};

const localizeNumber =
  (formatNumber: any) =>
  (number: any, optionalDecimals = 0) => {
    if (optionalDecimals > 0) {
      const decimals = Math.abs(Math.round((number * 100) % 100));
      if (decimals !== 0 && decimals !== 100) {
        return formatNumber(number, {
          style: 'decimal',
          minimumFractionDigits: optionalDecimals,
          maximumFractionDigits: optionalDecimals,
        });
      }
    }

    return formatNumber(number, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

const isCurrencyAfterNumber =
  (formatNumber: (value: number, options?: FormatNumberOptions) => string) => (currency: Currency) => {
    const zeroFormattedWithCurrency = formatNumber(0, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return zeroFormattedWithCurrency[0] === '0';
  };

const localizeCurrency = (formatNumber: any) => (currency: any) => {
  const zeroFormattedWithCurrency = formatNumber(0, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return zeroFormattedWithCurrency.replace('0', '').trim();
};

export const LocalizedMessage = ({ id, values }: any, { localizeMessage }: any) => localizeMessage(id, values);

LocalizedMessage.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

LocalizedMessage.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export const LocalizedMessageWithCurrency = (
  { id, values, currency }: any,
  { localizeMessage, localizeCurrency }: any
) => localizeMessage(id, { ...values, currency: localizeCurrency(currency) });

LocalizedMessageWithCurrency.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
  localizeCurrency: PropTypes.func.isRequired,
};

LocalizedMessageWithCurrency.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  currency: PropTypes.string.isRequired,
};

export const LocalizedMessageWithElementsInjected = ({ id, values, elements }: any, { localizeMessage }: any) => {
  const uniqueIdElementMap = new Map();
  const allValuesForLocalization = cloneDeep(values || {});
  forOwn(elements, (element, elementKeyInLocalizationString) => {
    const uniqueIdForSplit = uuid.v4();
    allValuesForLocalization[elementKeyInLocalizationString] = uniqueIdForSplit;
    uniqueIdElementMap.set(uniqueIdForSplit, element);
  });
  const localizedMessage = localizeMessage(id, allValuesForLocalization);
  const uniqueIdsForSplit = Array.from(uniqueIdElementMap.keys());
  // create a regex that groups every text part and every unique id
  const splitRegexFromUniqueIds = new RegExp(`(.*)${uniqueIdsForSplit.map((id) => `(${id})`).join('(.*)')}(.*)`);
  // get all groups matched by the regex
  const localizedMessageSplitted = tail(splitRegexFromUniqueIds.exec(localizedMessage));
  const arrayWithContent = map(localizedMessageSplitted, (contentPiece) => {
    const contentPieceIsElement = uniqueIdElementMap.has(contentPiece);
    if (contentPieceIsElement) {
      const elementKey = contentPiece;
      const elementWithAddedKey = React.cloneElement(uniqueIdElementMap.get(elementKey), { key: elementKey });
      return elementWithAddedKey;
    } else {
      return contentPiece;
    }
  });
  return <>{arrayWithContent}</>;
};

LocalizedMessageWithElementsInjected.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  elements: PropTypes.object.isRequired,
};

LocalizedMessageWithElementsInjected.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export type LocalizeCostWithCurrency = (cost: CostWithCurrency, optionalDecimals?: number) => string;

export type LocalizeCostWithCentesimalCurrency = (
  cost: CostWithCurrency,
  market: Market,
  optionalDecimals?: number
) => string;

export interface ILocalizedMessageProps {
  id: string;
  values?: { [variableName: string]: any };
}

export interface ILocalizedMessageWithCurrencyProps {
  id: string;
  values?: { [variableName: string]: any };
  currency: Currency;
}

export type LocalizeMessage = (id: string, objects?: { [key: string]: string }) => string;

export type LocalizeCurrency = (currency: string) => string;

export type IsCurrencyAfterNumber = (currency: Currency) => boolean;

export type LocalizeNumber = (number: number, optionalDecimals?: number) => string;

export type LocalizeDate = (date: string | Date, format?: string) => string;

export interface WithLocalizationContextType {
  localizeCostWithCurrency: LocalizeCostWithCurrency;
  localizeMessage: LocalizeMessage;
  localizeCurrency: LocalizeCurrency;
  isCurrencyAfterNumber: IsCurrencyAfterNumber;
  localizeCostWithCentesimalCurrency: LocalizeCostWithCentesimalCurrency;
  localizeNumber: LocalizeNumber;
  localizeDate: LocalizeDate;
  locale: string;
  localizationNamespace: string;
}
