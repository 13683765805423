import type { Market } from '@provider-portal/app-context/constants';
import { FormPage } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { SupplierWithService } from '@provider-portal/PartnerSupplier/models';
import * as React from 'react';
import type { IProviderFormData, ISupplierService } from '../models';
import { CategoriesPaper } from './CategoriesPaper';
import { formPapers } from './form-papers';

interface IProviderFormComponentProps {
  market: Market;
  appBarTitle: React.ReactNode;
  subscriptionSuppliers: SupplierWithService[];
  onSubmit(event: any): Promise<void>;
  formData: IProviderFormData;
  supplierServices: Map<string, ISupplierService[]>;
  orderServices: string[];
  leftSidedButtons?: React.ReactNode[];
  rightSidedButtons?: React.ReactNode[];
}

export const ProviderFormComponent: React.FunctionComponent<IProviderFormComponentProps> = ({
  market,
  appBarTitle,
  subscriptionSuppliers,
  onSubmit,
  formData,
  supplierServices,
  orderServices,
  leftSidedButtons,
  rightSidedButtons,
}) => {
  return (
    <FormPage
      appBarText={appBarTitle}
      papers={formPapers(subscriptionSuppliers, formData, market)}
      onSubmit={onSubmit}
      leftSidedButtons={leftSidedButtons}
      rightSidedButtons={rightSidedButtons}
      showMenuForSmallerScreen
    >
      <CategoriesPaper
        formData={formData}
        market={market}
        supplierServices={supplierServices}
        orderServices={orderServices}
      />
    </FormPage>
  );
};
