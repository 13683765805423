import { TextField } from '@provider-portal/libs/uiFramework/components/deprecated/TextField';
import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import * as React from 'react';
import type { IFileValue } from '../../configs';
import { FieldMessage } from '../../FieldMessage';
import { fileValidator } from '../file-validation';
import type { IFileInput } from './config';

interface IFileInputProps extends Omit<IFileInput, 'type'> {
  disabled?: boolean;
  fileValue?: IFileValue;
}

export const FileInput: React.FunctionComponent<IFileInputProps> = (props) => {
  return (
    <>
      <TextField
        name={props.name}
        fullWidth
        type="file"
        disabled={props.disabled}
        required={props.required}
        requiredErrorText={
          props.required && !props.fileValue && (props.requiredErrorText || <FieldMessage id="portalRequiredField" />)
        }
        InputLabelProps={{ shrink: true }}
        label={props.label}
        validators={{ fileIsValid: fileValidator(props.allowedFileTypes) }}
      />
      {props.fileValue && <Body>{props.fileValue.fileName}</Body>}
    </>
  );
};
