import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { LowerCostIcon } from '@minna-technologies/minna-ui/icons/LowerCost';
import { Market } from '@provider-portal/app-context/constants';
import { CampaignIcon } from '@provider-portal/assets/icons/CampaignIcon';
import EnvelopeIcon from '@provider-portal/assets/icons/envelopeIcon.svg';
import type { CategoryName } from '@provider-portal/constants/categories';
import { categoryIcon, localizeCategoryLabel } from '@provider-portal/constants/categories';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import type { MenuModule } from '@provider-portal/libs/uiFramework/components/Menu/models';
import { Headline5 } from '@provider-portal/libs/uiFramework/components/text/Headline5';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';
import { selectorMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';
import { showCampaignsMenuItem } from '@provider-portal/provider/ProviderForm/CategoriesPaper/available-categories';
import { colors } from '@provider-portal/styles/colors';
import startsWith from 'lodash/startsWith';
import React from 'react';
import { useSelector } from 'react-redux';
import { BottomSection } from './components/BottomSection';
import { MenuItem } from './components/MenuItem';
import { MinnaAdminMenu } from './components/MinnaAdminMenu';

const styles = () =>
  createStyles({
    topItem: {
      margin: '16px',
    },
    list: {
      padding: '0px 0px 8px 0px',
      margin: '0px 8px 0px 8px',
    },
    overline: {
      margin: '16px 0px 16px 16px',
    },
    supplierLink: {
      '&:hover': { backgroundColor: colors.background },
    },
    selected: {
      backgroundColor: 'rgba(0, 181, 185, 0.25);',
    },
    menuDiv: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  });

export interface SupplierVertical {
  partnerSupplierId: string;
  categoryName: CategoryName;
}

export interface MenuComponentProps {
  path: string;
  hasAdminAccess: boolean;
  supplier?: { logoUrl: string; id: string };
  supplierVerticals: SupplierVertical[];
  loggedInUsername?: string;
  menuModules?: MenuModule[];
}

type MenuInnerComponentProps = MenuComponentProps & WithStyles<typeof styles>;

const MenuInnerComponent: React.FunctionComponent<MenuInnerComponentProps> = ({
  path,
  hasAdminAccess,
  supplier,
  supplierVerticals,
  loggedInUsername,
  classes,
  menuModules,
}) => {
  const pathPrefix = startsWith(path, '/admin') ? '/admin/suppliers' : '/suppliers';
  const providerCategoryPath = (subPath: string) => (vertical: SupplierVertical) =>
    `${pathPrefix}/${vertical.partnerSupplierId}/${vertical.categoryName}/${subPath}`;
  const [productPathFunc, campaignPathFunc, ordersSwitchPathFunc] = ['products', 'campaigns', 'orders/switch'].map(
    providerCategoryPath
  );
  const providerDetailsPath = `${pathPrefix}/${supplier?.id}`;
  const statisticsPath = `${providerDetailsPath}/statistics/cancellations`;

  const maybePartnerSupplier = useSelector(selectorMaybePartnerSupplier);
  const maybeMarket = maybePartnerSupplier?.market;

  const verticalMenuItems = supplierVerticals.map((vertical) => {
    return (
      <React.Fragment key={vertical.categoryName}>
        <Overline className={classes.overline}>{localizeCategoryLabel(vertical.categoryName)}</Overline>
        <MenuItem
          label={<LocalizedMessage id="products" />}
          icon={categoryIcon(vertical.categoryName)}
          to={productPathFunc(vertical)}
          subItem
          selected={startsWith(path, productPathFunc(vertical))}
        />
        {maybeMarket && showCampaignsMenuItem(vertical.categoryName, maybeMarket) && (
          <MenuItem
            label={<LocalizedMessage id="campaigns" />}
            icon={<CampaignIcon />}
            to={campaignPathFunc(vertical)}
            subItem
            selected={startsWith(path, campaignPathFunc(vertical))}
          />
        )}
        <MenuItem
          label={<LocalizedMessage id="orders" />}
          icon={<img src={EnvelopeIcon} alt="offers" />}
          to={ordersSwitchPathFunc(vertical)}
          subItem
          selected={startsWith(path, ordersSwitchPathFunc(vertical))}
        />
      </React.Fragment>
    );
  });

  return (
    <div className={classes.menuDiv}>
      <Headline5 textColor="primary" classes={{ root: classes.topItem }}>
        <LocalizedMessage id="title" />
      </Headline5>
      {hasAdminAccess && <MinnaAdminMenu path={path} />}
      <Divider />
      {supplier && supplier.id && (
        <>
          <List classes={{ root: classes.list }}>
            {/* TODO: uncomment when starting on change feature

                appContext.market === Market.Sweden && <MenuItem
                  label={ <LocalizedMessage id="change" /> }
                  icon={ <img src={ SaveDeskIcon } alt="Change" /> }
                  to={ changePath }
                  subItem
                  selected={ startsWith(path, changePath) }
              /> */}
            {menuModules &&
              menuModules.map((Module, index) => (
                <Module key={index} path={path} hasAdminAccess={hasAdminAccess} providerId={supplier?.id} />
              ))}
            {maybeMarket === Market.UnitedKingdom && (
              <>
                <Overline className={classes.overline}>
                  <LocalizedMessage id="insights" />
                </Overline>
                <MenuItem
                  label={<LocalizedMessage id="dashboard" />}
                  icon={<LowerCostIcon />}
                  to={statisticsPath}
                  subItem
                  selected={startsWith(path, statisticsPath)}
                />
              </>
            )}
          </List>
        </>
      )}
      <List classes={{ root: classes.list }}>{verticalMenuItems}</List>
      <BottomSection
        hasAdminAccess={hasAdminAccess}
        loggedInUsername={loggedInUsername}
        pathPrefix={pathPrefix}
        providerId={supplier?.id}
        path={path}
      />
    </div>
  );
};

//@ts-ignore
export const MenuComponent: React.ComponentType<MenuComponentProps> = withStyles(styles)(
  withLocalization('Menu')(MenuInnerComponent)
);
