import * as React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { AddUser } from './AddUser';
import { UserList } from './UserList';
import { ViewUser } from './ViewUser';

export const UserManagementRoute: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/add`} render={() => <AddUser />} />
      <Route path={`${match.path}/:userId`} render={(props) => <ViewUser userId={props.match.params.userId} />} />
      <Route path={match.path} render={() => <UserList />} />
    </Switch>
  );
};
