import { LocalizedMessage } from '@provider-portal/internationalization';
import CogIcon from '@provider-portal/assets/icons/cog.svg';
import startsWith from 'lodash/startsWith';
import * as React from 'react';
import { MenuItem } from '../MenuItem';

interface ISettingsMenuItemProps {
  pathPrefix: string;
  providerId: string;
  path: string;
}

export const SettingsMenuItem: React.FunctionComponent<ISettingsMenuItemProps> = ({ pathPrefix, providerId, path }) => {
  const settingsUrl = `${pathPrefix}/${providerId}/settings`;

  return (
    <MenuItem
      label={<LocalizedMessage id="settings" />}
      to={settingsUrl}
      icon={<img src={CogIcon} alt="settings" />}
      selected={startsWith(path, settingsUrl)}
    />
  );
};
