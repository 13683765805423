import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import moment from 'moment';

export function debouncePromise<P, A extends any[]>(func: (...args: A) => Promise<P>): (...args: A) => Promise<P> {
  let pending: Promise<P> | null = null;

  const clear = () => {
    pending = null;
  };

  return async (...args: A) => {
    if (pending) return pending;

    const result = func(...args);

    if (process.env.NODE_ENV === 'development' && result === undefined) {
      // eslint-disable-next-line no-console
      console.warn('Got undefined as result to debouncePromise with function:', func);
    }

    if (isFunction(get(result, 'then'))) {
      pending = result;
      result.then(clear, clear);
    }

    return result;
  };
}

export function debouncePromiseWithDelay<P, A extends any[]>(
  delayInMilliseconds: number,
  func: (...args: A) => Promise<P>
): (...args: A) => Promise<P> {
  let promise: Promise<P> | null = null;
  let lastRequestedAt: moment.Moment | null = null;

  const clear = () => {
    promise = null;
    lastRequestedAt = null;
  };

  return async (...args: A) => {
    const isCurrentlyDelayed = lastRequestedAt && moment().diff(lastRequestedAt, 'milliseconds') < delayInMilliseconds;
    if (promise && isCurrentlyDelayed) return promise;

    const result = func(...args);

    if (isFunction(get(result, 'then'))) {
      promise = result;
      lastRequestedAt = moment();
      result.then(noop, clear);
    }

    return result;
  };
}
