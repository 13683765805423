import { getMixpanel } from './mixpanel';
import { EventNames } from './mixpanel-constants';

export interface MixPanelEventDetails {
  Event: string;
  Feature: string;
}

export function sendCSatRatingEvent(rating: number): void {
  getMixpanel().track(EventNames.CSAT_SCORE, {
    rating,
  });
}

export function sendCSatCommentEvent(rating: number, comment: string): void {
  getMixpanel().track(EventNames.CSAT_COMMENT, {
    rating,
    comment,
  });
}
