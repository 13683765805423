import isDate from 'lodash/isDate';
import isString from 'lodash/isString';
import moment from 'moment';

// TODO move to formatters?

function cleanDateString(dateString: string): string {
  return isString(dateString) ? dateString.split(/\[/)[0] : dateString;
}

export function parseMoment(dateString: string): moment.Moment {
  return moment(cleanDateString(dateString));
}

export function parseDate(dateString: string): Date {
  return parseMoment(dateString).toDate();
}

export function parseTime(dateString: string) {
  return parseMoment(dateString).format('HH:mm:ss');
}

export function formatDateTime(dateString: string, format = 'YYYY-MM-DD HH:mm:ss'): string {
  return parseMoment(dateString).format(format);
}

export function formatToIsoDate(date: string | Date): string {
  const format = 'YYYY-MM-DD';
  if (isDate(date)) {
    return moment(date).format(format);
  } else {
    return moment(cleanDateString(date)).format(format);
  }
}

export function firstDayOfCurrentMonth() {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();

  return new Date(y, m, 1);
}

export function lastDayOfCurrentMonth() {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();

  return new Date(y, m + 1, 0);
}
