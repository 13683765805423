import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import { LocalizedMessage } from '@provider-portal/internationalization';
//@ts-ignore
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { DividerSection } from '@provider-portal/libs/uiFramework/components/dividers/DividerSection';
import { DividerSubSection } from '@provider-portal/libs/uiFramework/components/dividers/DividerSubSection';
import { Paper } from '@provider-portal/libs/uiFramework/components/panels/Paper';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { wait } from '@provider-portal/libs/wait';
import {
  fetchSaveDeskOrder,
  SaveDeskOfferByPhoneRequest,
  SaveDeskOfferRequest,
  saveDeskPhoneCallOrderAccepted,
  saveDeskPhoneCallOrderRejected,
  SaveDeskRejectionRequest,
  sendSaveDeskOfferRequest,
  sendSaveDeskRejectionRequest,
} from '@provider-portal/saveDesk/api';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveDeskActions as SaveDeskActionsDispatch } from '../../../duck';
import type { ISaveDeskOrder } from '../../../models';
import { SaveDeskOfferType } from '../../../models';
import { FieldNames } from '../../config';
import { SaveDeskActions } from './actions';
import { CALL_FIELD_NAME, CALL_OFFER_ACCEPTED, CALL_OFFER_REJECTED, CallSection } from './CallSection';
import { Header } from './Header';
import { NoOfferSection } from './NoOfferSection';
import { SendOfferSection } from './SendOfferSection';
import { SharedFormFields } from './SharedFormFields';
import { selectorMarketFromMaybePartnerSupplier } from '@provider-portal/PartnerSupplier/duck';
import { getDefaultCurrency } from '@provider-portal/app-context/state';

export interface IResponseProps {
  userName: string;
  currentDetailsSection: IFieldSectionConfig;
  actionsSection: IFieldSectionConfig;
  offerSection: IFieldSectionConfig;
  campaignSection: IFieldSectionConfig;
  anotherImprovementSection: IFieldSectionConfig;
  daysLeft?: number;
  saveDeskOrder: ISaveDeskOrder;
}

const getOutcomeAction = (type?: SaveDeskOfferType) => {
  switch (type) {
    case SaveDeskOfferType.NO_SAVE_DESK_OFFER:
      return SaveDeskActions.NO_OFFER;

    case SaveDeskOfferType.SAVE_DESK_OFFER_BY_PHONE:
      return SaveDeskActions.CALL;

    default:
      return SaveDeskActions.SEND_OFFER;
  }
};

export const SaveDeskResponseCardOld: React.FunctionComponent<IResponseProps> = ({
  userName,
  currentDetailsSection,
  actionsSection,
  offerSection,
  campaignSection,
  anotherImprovementSection,
  saveDeskOrder,
}) => {
  const dispatch = useDispatch();
  const initialAction = getOutcomeAction(saveDeskOrder.outcome?.type);
  const [action, setAction] = React.useState(initialAction);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [formData, setFormData] = React.useState<any>({});
  const [sendingOffer, setSendingOffer] = React.useState(false);
  const [offerSent, setOfferSent] = React.useState(!!saveDeskOrder.outcome);
  const [sendResponseMessage, setSendResponseMessage] = React.useState<React.ReactNode>(
    <LocalizedMessage id="saveDeskSendResponse" />
  );
  const market = useSelector(selectorMarketFromMaybePartnerSupplier);
  const currency = getDefaultCurrency(market);

  const onSubmit = debouncePromise(async (data: any) => {
    setFormData(data);

    if (data[CALL_FIELD_NAME] === CALL_OFFER_ACCEPTED) {
      const { partnerSupplierId, partnerOrderId } = saveDeskOrder;
      const offer = SaveDeskOfferByPhoneRequest(data, currency);
      await saveDeskPhoneCallOrderAccepted(partnerSupplierId, partnerOrderId, offer);
      const order = await fetchSaveDeskOrder(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId);
      dispatch(SaveDeskActionsDispatch.setSaveDeskOrder(order));
      setOfferSent(true);
    } else if (data[CALL_FIELD_NAME] === CALL_OFFER_REJECTED) {
      const { partnerSupplierId, partnerOrderId } = saveDeskOrder;
      const offer = SaveDeskOfferByPhoneRequest(data, currency);
      await saveDeskPhoneCallOrderRejected(partnerSupplierId, partnerOrderId, offer);
      const order = await fetchSaveDeskOrder(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId);
      dispatch(SaveDeskActionsDispatch.setSaveDeskOrder(order));
      setOfferSent(true);
    } else {
      setDialogOpen(true);
    }
  });
  const onSendOffer = async () => {
    setSendingOffer(true);

    const offer = SaveDeskOfferRequest(formData, currency);
    await sendSaveDeskOfferRequest(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId, offer);
    const order = await fetchSaveDeskOrder(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId);
    dispatch(SaveDeskActionsDispatch.setSaveDeskOrder(order));
    setSendingOffer(false);
    setSendResponseMessage(<LocalizedMessage id="saveDeskSent" />);
    setOfferSent(true);
    await wait(1000, () => setDialogOpen(false));
  };
  const onSendRejection = async () => {
    setSendingOffer(true);

    const rejection = SaveDeskRejectionRequest(formData, currency);
    await sendSaveDeskRejectionRequest(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId, rejection);
    setSendingOffer(false);
    setSendResponseMessage(<LocalizedMessage id="saveDeskSent" />);
    setOfferSent(true);
    const order = await fetchSaveDeskOrder(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId);
    dispatch(SaveDeskActionsDispatch.setSaveDeskOrder(order));
    await wait(1000, () => setDialogOpen(false));
  };

  return (
    <Paper title={<LocalizedMessage id="saveDeskOrderPageResponseTitle" />}>
      <Header saveDeskOrder={saveDeskOrder} userName={userName} />
      <DividerSection />
      <Form onValidSubmit={onSubmit}>
        <SharedFormFields
          currentDetailsSection={currentDetailsSection}
          actionsSection={actionsSection}
          saveDeskOrder={saveDeskOrder}
          offerSent={offerSent}
          action={action}
          onSetAction={setAction}
        />
        {action === SaveDeskActions.SEND_OFFER && (
          <>
            <div style={{ padding: '0 16px' }}>
              <DividerSubSection disabled={offerSent} />
            </div>
            <SendOfferSection
              state={saveDeskOrder.state.stateType}
              outcome={
                saveDeskOrder.outcome?.type === SaveDeskOfferType.SAVE_DESK_OFFER ||
                saveDeskOrder.outcome?.type === SaveDeskOfferType.SAVE_DESK_PREDEFINED_OFFER ||
                saveDeskOrder.outcome?.type === SaveDeskOfferType.SAVE_DESK_OFFER_2
                  ? saveDeskOrder.outcome
                  : undefined
              }
              loading={sendingOffer}
              onSendOffer={onSendOffer}
              makeOfferButtonLabel={sendResponseMessage}
              replySent={offerSent}
              dialogOpen={dialogOpen}
              closeDialog={() => setDialogOpen(false)}
              userName={userName}
              supplierName={saveDeskOrder.supplierName}
              offerSection={offerSection}
              campaignSection={campaignSection}
              anotherImprovementSection={anotherImprovementSection}
              formData={formData}
              currency={currency}
            />
          </>
        )}
        {action === SaveDeskActions.CALL && !offerSent && (
          <>
            <div style={{ padding: '0 16px' }}>
              <DividerSubSection />
            </div>
            <CallSection />
          </>
        )}
        {action === SaveDeskActions.NO_OFFER && (
          <>
            <div style={{ padding: '0 16px' }}>
              <DividerSubSection disabled={offerSent} />
            </div>
            <NoOfferSection
              loading={sendingOffer}
              onSendResponse={onSendRejection}
              sendResponseButtonLabel={sendResponseMessage}
              replySent={offerSent}
              dialogOpen={dialogOpen}
              closeDialog={() => setDialogOpen(false)}
              userName={userName}
              comment={
                saveDeskOrder.outcome?.type === SaveDeskOfferType.NO_SAVE_DESK_OFFER
                  ? saveDeskOrder.outcome.reason
                  : formData[FieldNames.REASON]
              }
            />
          </>
        )}
      </Form>
    </Paper>
  );
};
