import { formatNumber } from '@provider-portal/libs/format/number';
import {
  floatValidatorHelper,
  floatZeroOrGreaterValidatorHelper,
  integerValidatorHelper,
  integerZeroOrGreaterValidatorHelper,
  positiveFloatValidatorHelper,
  positiveIntegerValidatorHelper,
} from '@provider-portal/libs/validation';
import * as React from 'react';
import { AttributeNames } from '../configs';
import { FieldMessage } from '../FieldMessage';
import { TextInput } from '../TextInput';
import type { INumberInput } from './config';
import type { Locale } from '@provider-portal/internationalization/locale';

export enum NumberType {
  Integer = 'integer',
  PositiveInteger = 'positiveInteger',
  IntegerZeroOrGreater = 'integerZeroOrGreater',
  Float = 'float',
  PositiveFloat = 'positiveFloat',
  FloatZeroOrGreater = 'floatZeroOrGreater',
}

export interface INumberInputProps extends Omit<INumberInput, 'type'> {
  validators?: any;
  backgroundColor?: string;
  disabled?: boolean;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const NumberInput: React.FunctionComponent<INumberInputProps> = (props) => {
  return (
    <TextInput
      {...props}
      defaultValue={props.defaultValue != null ? formatDefaultValue(props.defaultValue, props.locale) : undefined}
      validators={getValidators(props.numberType)}
      attributes={{ [AttributeNames.DATA_FORMAT]: props.numberType }}
    />
  );
};

function formatDefaultValue(value: string | number, locale: Locale): string {
  if (value === '') {
    return '';
  } else if (typeof value === 'string') {
    return formatNumber(parseFloat(value), locale, 3);
  } else {
    return formatNumber(value, locale, 3);
  }
}

interface IGetValidatorsFunc {
  [key: string]: (value: string) => boolean | React.ReactNode;
}

function getValidators(numberType: NumberType): IGetValidatorsFunc {
  switch (numberType) {
    case NumberType.PositiveInteger:
      return {
        positiveIntegerValidator: positiveIntegerValidatorHelper(<FieldMessage id="portalPositiveIntegerRequiredV2" />),
      };
    case NumberType.IntegerZeroOrGreater:
      return {
        integerZeroOrGreaterValidator: integerZeroOrGreaterValidatorHelper(
          <FieldMessage id="portalIntegerZeroOrGreaterRequiredV2" />
        ),
      };
    case NumberType.PositiveFloat:
      return {
        positiveFloatValidator: positiveFloatValidatorHelper(<FieldMessage id="portalPositiveFloatRequiredV2" />),
      };
    case NumberType.Integer:
      return {
        integerValidator: integerValidatorHelper(<FieldMessage id="portalIntegerRequiredV2" />),
      };
    case NumberType.Float:
      return {
        floatValidator: floatValidatorHelper(<FieldMessage id="portalFloatRequiredV2" />),
      };
    case NumberType.FloatZeroOrGreater:
      return {
        floatZeroOrGreaterValidator: floatZeroOrGreaterValidatorHelper(
          <FieldMessage id="portalFloatZeroOrGreaterRequiredV2" />
        ),
      };
  }
}
