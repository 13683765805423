import { makeStyles } from '@material-ui/core/styles';
import { withAppContext } from '@provider-portal/app-context/react';
import LockShieldIcon from '@provider-portal/assets/icons/LockShieldIcon.svg';
import TelevisionIcon from '@provider-portal/assets/icons/TelevisionIcon.svg';
import { LocalizedMessage, withLocalization } from '@provider-portal/internationalization';
import { MenuItem } from '@provider-portal/libs/uiFramework/components/Menu/components/MenuItem';
import type { MenuModuleProps } from '@provider-portal/libs/uiFramework/components/Menu/models';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';
import { useFetchProviderSettingsOnEnter } from '@provider-portal/saveDesk/SaveDeskMenuModule/use-fetch';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import startsWith from 'lodash/startsWith';
import React from 'react';

const useStyles = makeStyles(() => ({
  overline: {
    margin: '16px 0px 16px 16px',
  },
}));

const DeveloperToolsMenuInnerComponent = ({ path, providerId }: Omit<MenuModuleProps, 'hasAdminAccess'>) => {
  const { loadingState } = useFetchProviderSettingsOnEnter(providerId);
  const pathPrefix = startsWith(path, '/admin') ? '/admin/suppliers' : '/suppliers';
  const developerToolsGetStartedPagePath = `${pathPrefix}/${providerId}/developer-tools/get-started`;
  const developerToolsApiKeysPagePath = `${pathPrefix}/${providerId}/developer-tools/api-keys`;
  const classes = useStyles();

  if (loadingState === PortalLoadingState.Success) {
    return (
      <>
        <Overline className={classes.overline}>
          <LocalizedMessage id="developerToolsSection" />
        </Overline>
        <MenuItem
          label={<LocalizedMessage id="getStarted" />}
          icon={<img src={TelevisionIcon} alt="developer-tools-get-started" />}
          to={developerToolsGetStartedPagePath}
          subItem
          selected={startsWith(path, developerToolsGetStartedPagePath)}
        />
        <MenuItem
          label={<LocalizedMessage id="apiKeys" />}
          icon={<img src={LockShieldIcon} alt="api-keys" />}
          to={developerToolsApiKeysPagePath}
          subItem
          selected={startsWith(path, developerToolsApiKeysPagePath)}
        />
      </>
    );
  } else {
    return <div data-testid="developerToolsMenuComponent-loading" />;
  }
};

export const DeveloperToolsMenuComponent = withLocalization('Menu')(withAppContext(DeveloperToolsMenuInnerComponent));
