import { Headline1 } from '@provider-portal/libs/uiFramework/components/text/Headline1';
import * as React from 'react';
import { NavigationButton } from '../../buttons/NavigationButton';
import { Page } from '../Page';
import styles from './styles.scss';

interface IProps {
  appBarText: string | React.ReactNode;
  headerText: string | React.ReactNode;
  leftButtonText: string | React.ReactNode;
  rightButtonText: string | React.ReactNode;
  onLeftButtonClick?(): void;
  onRightButtonClick?(): void;
  children?: React.ReactNode;
}

//TODO: remove display none when the pre/next feature is added
export const OrderPage: React.FunctionComponent<IProps> = ({
  children,
  appBarText,
  headerText,
  leftButtonText,
  rightButtonText,
  onLeftButtonClick,
  onRightButtonClick,
}) => {
  return (
    <Page appBarText={appBarText} variant="details">
      <header className={styles.header}>
        <Headline1>{headerText}</Headline1>
        <div style={{ display: 'none' }}>
          <NavigationButton text={leftButtonText} onClick={onLeftButtonClick} />
          <span className={styles.nextButton}>
            <NavigationButton variant="right" text={rightButtonText} onClick={onRightButtonClick} />
          </span>
        </div>
      </header>
      {children}
    </Page>
  );
};
