import CancelIcon from '@material-ui/icons/Cancel';
import { Button } from '@provider-portal/libs/uiFramework/components/buttons/Button';
import { CheckCircleIcon } from '@provider-portal/libs/uiFramework/components/deprecated/CheckCircleIcon';
import Form from '@provider-portal/libs/uiFramework/components/deprecated/Form';
import { TextInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput';
import { HorizontalButtonGroup } from '@provider-portal/libs/uiFramework/components/layouts/HorizontalButtonGroup';
import { Panel1 } from '@provider-portal/libs/uiFramework/components/panels/Panel1';
import { Section } from '@provider-portal/libs/uiFramework/components/sections/Section';
import { Body1 } from '@provider-portal/libs/uiFramework/components/text/Body1';
import { Subtitle1 } from '@provider-portal/libs/uiFramework/components/text/Subtitle1';
import { Subtitle2 } from '@provider-portal/libs/uiFramework/components/text/Subtitle2';
import type { LocalizeMessage } from '@provider-portal/internationalization';
import { LocalizedMessage } from '@provider-portal/internationalization';
import { completeSaveDeskOrder, fetchSaveDeskOrder, rescindSaveDeskOffer } from '@provider-portal/saveDesk/api';
import { SaveDeskActions as SaveDeskActionsDispatch } from '@provider-portal/saveDesk/duck';
import type { ISaveDeskOrder } from '@provider-portal/saveDesk/models';
import { SaveDeskOfferType, SaveDeskState } from '@provider-portal/saveDesk/models';
import { colors } from '@provider-portal/styles/colors';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch } from 'react-redux';

export interface IProps {
  saveDeskOrder: ISaveDeskOrder;
}

const getCaption = (saveDeskOrder: ISaveDeskOrder, localizeMessage: LocalizeMessage) => {
  const userName = saveDeskOrder.userName.length > 0 ? saveDeskOrder.userName : localizeMessage('customer');
  const date = saveDeskOrder.state.createdAt;

  switch (saveDeskOrder.state.stateType) {
    case SaveDeskState.RejectedByUser:
    case SaveDeskState.RejectedByProvider:
      return <LocalizedMessage id="rejectedOutcomeTitle" values={{ userName, date }} />;

    case SaveDeskState.Success:
      return <LocalizedMessage id="successOutcomeTitleV2" values={{ userName, date }} />;

    case SaveDeskState.AcceptedByUser:
      return <LocalizedMessage id="acceptedByUserOutcomeTitle" values={{ userName }} />;

    default:
      return undefined;
  }
};

export const ConfirmContractUpdateSection: React.FunctionComponent<IProps> = (
  { saveDeskOrder },
  { localizeMessage }
) => {
  const dispatch = useDispatch();
  const finished =
    saveDeskOrder.state.stateType === SaveDeskState.Success ||
    saveDeskOrder.state.stateType === SaveDeskState.RejectedByUser;
  const [loading, setLoading] = React.useState(false);
  const [finishedOrder, setFinishedOrder] = React.useState(finished);
  const [updateNotPossibleResponseDisplayed, setUpdateNotPossibleResponseDisplayed] = React.useState(false);
  const [updateNotPossibleResponseSent, setUpdateNotPossibleResponseSent] = React.useState(false);
  const [reasonForRescinding, setReasonForRescinding] = React.useState('');
  const caption = getCaption(saveDeskOrder, localizeMessage);
  const showRescindButton =
    saveDeskOrder.outcome?.type === SaveDeskOfferType.SAVE_DESK_PREDEFINED_OFFER ||
    saveDeskOrder.outcome?.type === SaveDeskOfferType.SAVE_DESK_OFFER_2;

  const onChangeNegativeResponseExplanation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReasonForRescinding(e.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);
    await completeSaveDeskOrder(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId);
    setFinishedOrder(true);
  };

  const onUpdateNotPossible = async () => {
    setLoading(true);
    setUpdateNotPossibleResponseSent(true);
    await rescindSaveDeskOffer(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId, reasonForRescinding);
    const order = await fetchSaveDeskOrder(saveDeskOrder.partnerSupplierId, saveDeskOrder.partnerOrderId);
    dispatch(SaveDeskActionsDispatch.setSaveDeskOrder(order));
    setFinishedOrder(true);
  };

  const icon =
    saveDeskOrder.state.stateType === SaveDeskState.RejectedByUser ||
    saveDeskOrder.state.stateType === SaveDeskState.RejectedByProvider ? (
      <CancelIcon style={{ color: colors.grey2 }} />
    ) : (
      <CheckCircleIcon htmlColor={colors.accent.positive} />
    );

  return (
    <Form onValidSubmit={onSubmit}>
      <Section icon={icon} caption={caption}>
        {!finishedOrder && !updateNotPossibleResponseDisplayed && (
          <Panel1
            title={
              <Subtitle1>
                <LocalizedMessage id="whatToDoNow" />
              </Subtitle1>
            }
            footer={
              <HorizontalButtonGroup
                leftSidedButtons={[
                  <Button
                    loading={loading}
                    type="submit"
                    label={<LocalizedMessage id="contractUpdated" />}
                    color="positive"
                    key={1}
                  />,
                  showRescindButton && (
                    <Button
                      onClick={() => setUpdateNotPossibleResponseDisplayed(true)}
                      label={<LocalizedMessage id="updateNotPossible" />}
                      color="secondary"
                      key={2}
                    />
                  ),
                ]}
              />
            }
          >
            <Body1>
              <LocalizedMessage id="updateContractDescription" />
            </Body1>
            <Body1 style={{ marginTop: '16px' }}>
              <LocalizedMessage id="updateContractDescriptionSectionTwo" />
            </Body1>
          </Panel1>
        )}
        {!finishedOrder && updateNotPossibleResponseDisplayed && (
          <Panel1
            footer={
              <Button
                loading={loading}
                onClick={onUpdateNotPossible}
                label={<LocalizedMessage id="saveDeskSendResponse" />}
                color="negative"
                disabled={updateNotPossibleResponseSent || reasonForRescinding === ''}
              />
            }
            title={
              <Subtitle1>
                <LocalizedMessage id="pleaseExplainWhyNot" />
              </Subtitle1>
            }
          >
            <Body1>
              <LocalizedMessage
                id="negativeResponseWhatWillHappenExplainer"
                values={{ userName: saveDeskOrder.userName }}
              />
            </Body1>
            <TextInput
              multiline
              name={'negativeResponseExplanation'}
              label={<LocalizedMessage id="reason" />}
              required
              onChange={onChangeNegativeResponseExplanation}
              disabled={updateNotPossibleResponseSent}
            />
            <Subtitle2 textColor="default" colorVariant="dark">
              <LocalizedMessage id="noteForReason" />
            </Subtitle2>
          </Panel1>
        )}
      </Section>
    </Form>
  );
};

ConfirmContractUpdateSection.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
