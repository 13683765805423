/** IMPORTANT NOTE!!
 * If you are working on a new design, you probably want to use the colors in @provider-portal/styles/colors instead.
 */

export interface Color {
  main: string;
  dark?: string;
  light?: string;
  gradient?: string;
}

export const green: Color = {
  dark: '#43A047',
  main: '#81C784',
  light: '#E8F5E9',
  gradient: 'linear-gradient(135deg, #43A047 0%, #E8F5E9 100%)',
};

export const purple: Color = {
  dark: '#5E35B1',
  main: '#9575CD',
  light: '#EDE7F6',
  gradient: 'linear-gradient(135deg, #5E35B1 0%, #EDE7F6 100%)',
};

export const primary: Color = {
  dark: '#008487',
  main: '#00B5B9',
  gradient: 'linear-gradient(147.99deg, #009093 0%, #00b5b9 50.41%, #86d4b4 100%)',
};

export const yellow: Color = {
  dark: '#FDD835',
  main: '#FFF176',
  light: '#FFFDE7',
  gradient: 'linear-gradient(135deg, #FDD835 0%, #FFFDE7 100%)',
};

export const orange: Color = {
  dark: '#FB8C00',
  main: '#FFB74D',
  light: '#FFF3E0',
  gradient: 'linear-gradient(135deg, #FB8C00 0%, #FFF3E0 100%)',
};

export const red: Color = {
  dark: '#E53935',
  main: '#E57373',
  light: '#FFEBEE',
  gradient: 'linear-gradient(135deg, #E53935 0%, #FFEBEE 100%)',
};

export const gray: Color = {
  main: '#4A4A4A',
  light: '#777777',
  dark: '#797979',
};

export const ink: Color = {
  main: '#1e1e1e',
};

export const white: Color = {
  main: '#fff',
};

export const darker: Color = {
  main: '#434a54',
};

export const dark: Color = {
  main: '#707985',
};

export const light: Color = {
  main: '#aab2bd',
};

export const lighter: Color = {
  main: '#ccd1d9',
};

export const info: Color = {
  main: '#0059B9',
};

export const positive: Color = {
  main: '#50B83C',
};

export const negative: Color = {
  main: '#B90300',
};

export const warning: Color = {
  main: '#f6ae1e',
};
