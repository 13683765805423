// @ts-nocheck

import { PausePage } from '@provider-portal/features/PausePage';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { ChangePlanRoutes } from './changePlan/changePlanRoutes';

export const FeaturesRoute: React.ComponentType<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/pause`}
        render={(props) => (
          <PausePage
            // @†s-ignore
            merchantId={props.match.params.providerId}
          />
        )}
      />
      <Route path={`${props.match.path}/change-plan`} render={(props) => <ChangePlanRoutes {...props} />} />
    </Switch>
  );
};
