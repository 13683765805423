import * as React from 'react';
import type { ITableConfig } from '../../Table/config';
import { TableOverviewPageComponent } from './component';

export interface ITableOverviewPageProps {
  appBarText: React.ReactNode;
  table: ITableConfig;
  data: any;
  onRowClick?(values: any): void;
  onAdd?(): void;
  aboveTableElement?: React.ReactNode;
  noContentMessage?: React.ReactNode;
}

export const TableOverviewPage: React.FunctionComponent<ITableOverviewPageProps> = (props) => {
  return <TableOverviewPageComponent {...props} />;
};
