import { LocalizedMessage } from '@provider-portal/internationalization';
import type { IPageConfig } from '@provider-portal/libs/uiFramework/components/layouts/config';
import { TwoColumnLayout } from '@provider-portal/libs/uiFramework/components/layouts/TwoColumnLayout';
import { OrderPage } from '@provider-portal/libs/uiFramework/components/pages/OrderPage';
import * as React from 'react';

export interface ISaveDeskOrderPageProps {
  partnerSupplierId: string;
  saveDeskOrderId: string;
  config: IPageConfig;
}

export const SaveDeskOrderPage: React.FunctionComponent<ISaveDeskOrderPageProps> = ({ config, saveDeskOrderId }) => {
  return (
    <OrderPage
      appBarText={<LocalizedMessage id="saveDeskOrderPageTitle" />}
      headerText={
        <>
          <LocalizedMessage id="saveDeskOrderPageHeaderText" /> {saveDeskOrderId}{' '}
        </>
      }
      leftButtonText={<LocalizedMessage id="saveDeskOrderPagePreviousOrderButtonText" />}
      rightButtonText={<LocalizedMessage id="saveDeskOrderPageNextOrderButtonText" />}
    >
      <TwoColumnLayout components={config.components} />
    </OrderPage>
  );
};
