import MuiPaper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Overline } from '@provider-portal/libs/uiFramework/components/text/Overline';
import classNames from 'classnames';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: '0px 2px 5px rgba(34, 34, 34, 0.2)',
    overflow: 'hidden',
  },
  container: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

interface IProps {
  title?: string | React.ReactNode;
  rightElement?: React.ReactNode;
}

export const Paper: React.FunctionComponent<IProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>> = ({
  title,
  children,
  rightElement,
  className,
}) => {
  const { paper, container } = useStyles();
  return (
    <MuiPaper className={classNames(paper, className)}>
      {(title || rightElement) && (
        <div className={container}>
          {title && <Overline>{title}</Overline>}
          {rightElement}
        </div>
      )}
      {children}
    </MuiPaper>
  );
};
