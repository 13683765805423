import type * as React from 'react';
import type { IInputField } from '../configs';
import { fieldConstructor, FieldType } from '../configs';
import type { IOption } from '../Select/option';

export interface IMultiSelect extends IInputField {
  type: FieldType.MultiSelect;
  options: IOption[];
  allSelectedLabel?: React.ReactNode;
  defaultValue?: string[];
}

export const MultiSelect = fieldConstructor<IMultiSelect>(FieldType.MultiSelect);
