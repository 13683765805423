import { LocalizedMessage } from '@provider-portal/internationalization';
import { CentesimalCostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CentesimalCostInput/config';
import { CostInput } from '@provider-portal/libs/uiFramework/components/fields/cost/CostInput/config';
import {
  FieldArray,
  FieldArrayItemComponentType,
} from '@provider-portal/libs/uiFramework/components/fields/FieldArray/config';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { NumberType } from '@provider-portal/libs/uiFramework/components/fields/NumberInput';
import { NumberInput } from '@provider-portal/libs/uiFramework/components/fields/NumberInput/config';
import { RadioGroup } from '@provider-portal/libs/uiFramework/components/fields/RadioGroup/config';
import type { IFormPagePaper } from '@provider-portal/libs/uiFramework/components/pages/FormPage';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { WidgetType } from '@provider-portal/libs/uiFramework/components/widgets/config';
import { AccommodationType } from '@provider-portal/products/models';
import * as React from 'react';
import { electricityCampaignSection } from '../common';
import type { CampaignFormLayoutMap } from '@provider-portal/libs/uiFramework';
import { createCampaignFormLayout } from '@provider-portal/libs/uiFramework';
import { Market } from '@provider-portal/app-context/constants';
import { categories } from '@provider-portal/constants/categories';
import { Currency } from '@provider-portal/models/subscription';
import { Locale } from '@provider-portal/internationalization/locale';

enum SpainElectricityCampaignFieldNames {
  ONE_TIME_DISCOUNT = 'oneTimeDiscount',
  ACCOMMODATION_TYPE = 'accommodationType',
  USAGE_DISCOUNTS = 'usageDiscounts',
  LOWER_BOUND = 'lowerBound',
  UPPER_BOUND = 'upperBound',
  DISCOUNT_PER_KWH = 'discountPerKwh',
  CAMPAIGN_LENGTH = 'campaignLength',
  FORM_TYPE = 'type',
}

const currency = Currency.EUR;
const locale = Locale.esES;

const spainElectricityCampaignSections: IFieldSectionConfig[] = [
  {
    caption: <LocalizedMessage id="monthlyDiscountsTitle" />,
    columns: 1,
    components: [
      {
        type: WidgetType.MONTHLY_DISCOUNT,
      },
      HiddenInput({
        name: SpainElectricityCampaignFieldNames.FORM_TYPE,
        defaultValue: 'SpainElectricityCampaignIncomingRequest',
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="usageDiscountsTitle" />,
    columns: 1,
    components: [
      FieldArray({
        name: SpainElectricityCampaignFieldNames.USAGE_DISCOUNTS,
        columns: 2,
        fields: [
          NumberInput({
            name: SpainElectricityCampaignFieldNames.LOWER_BOUND,
            label: <LocalizedMessage id="lowerBoundKwhLabel" />,
            required: true,
            numberType: NumberType.IntegerZeroOrGreater,
            locale: locale,
          }),
          NumberInput({
            name: SpainElectricityCampaignFieldNames.UPPER_BOUND,
            label: <LocalizedMessage id="upperBoundKwhLabel" />,
            required: true,
            numberType: NumberType.PositiveInteger,
            locale: locale,
          }),
          CentesimalCostInput({
            name: SpainElectricityCampaignFieldNames.DISCOUNT_PER_KWH,
            label: <LocalizedMessage id="discountLabelV2" />,
            required: true,
            currency: currency,
            locale: locale,
          }),
          NumberInput({
            name: SpainElectricityCampaignFieldNames.CAMPAIGN_LENGTH,
            label: <LocalizedMessage id="campaignDurationInMonthsLabel" />,
            required: true,
            numberType: NumberType.PositiveInteger,
            locale: locale,
          }),
        ],
        addItemButtonLabel: <LocalizedMessage id="addIntervalButtonLabel" />,
        itemComponent: {
          type: FieldArrayItemComponentType.FieldGroup1,
          title: <LocalizedMessage id="usageDiscountIntervalTitle" />,
          columns: 2,
        },
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="accommodationTypeHeader" />,
    columns: 1,
    components: [
      RadioGroup({
        name: SpainElectricityCampaignFieldNames.ACCOMMODATION_TYPE,
        options: [
          { value: AccommodationType.All, label: <LocalizedMessage id="accommodationTypeAllLabel" /> },
          { value: AccommodationType.Apartment, label: <LocalizedMessage id="accommodationTypeApartmentLabel" /> },
          { value: AccommodationType.Villa, label: <LocalizedMessage id="accommodationTypeVillaLabel" /> },
        ],
        required: true,
      }),
    ],
  },
  {
    caption: <LocalizedMessage id="oneTimeCampaignsTitleV2" />,
    captionText: <LocalizedMessage id="oneTimeCampaignsCaptionText" />,
    columns: 2,
    components: [
      CostInput({
        name: SpainElectricityCampaignFieldNames.ONE_TIME_DISCOUNT,
        label: <LocalizedMessage id="welcomeDiscountLabelV2" />,
        currency: currency,
        locale: locale,
      }),
    ],
  },
];

const spainElectricityCampaignPapers: IFormPagePaper[] = [
  {
    sections: [electricityCampaignSection, ...spainElectricityCampaignSections],
  },
];

export const spainElectricityCampaignLayoutMap: CampaignFormLayoutMap = createCampaignFormLayout(
  categories.electricity,
  Market.Spain,
  spainElectricityCampaignPapers
);
