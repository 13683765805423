import type { Locale } from '@provider-portal/internationalization/locale';
import type { AmountWithCurrency, Currency } from '@provider-portal/models/subscription';
import type { FieldType, IInputField } from '../configs';
import type { MeasurementUnit } from '../MeasurementUnit';

type CostUnitInputDefault = { unit: MeasurementUnit } & AmountWithCurrency;
interface ValueUnitInputDefault {
  unit: MeasurementUnit;
  amount: number;
}
export type IUnitFieldFactoryInputDefaultValue = CostUnitInputDefault | ValueUnitInputDefault;

export interface IUnitFieldFactoryInput extends IInputField {
  type: FieldType.UnitFieldFactory;
  unit: MeasurementUnit;
  defaultValue?: IUnitFieldFactoryInputDefaultValue;
  units: MeasurementUnit[];
  unitFieldFactoryType: UnitFieldFactoryType;
  locale: Locale;
  currency: Currency;
}

export type UnitFieldFactoryType = 'cent' | 'cost';

export function isCostUnitDefaultValue(
  defaultValue: IUnitFieldFactoryInputDefaultValue
): defaultValue is CostUnitInputDefault {
  return Boolean((defaultValue as CostUnitInputDefault).currency);
}
