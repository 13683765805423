import { Body } from '@provider-portal/libs/uiFramework/components/text/Body';
import * as React from 'react';
import styles from './styles.scss';

interface ISectionNumberProps {
  disabled?: boolean;
  number: number;
}

export const SectionNumber: React.FunctionComponent<ISectionNumberProps> = ({ number, disabled }) => {
  const className = disabled ? styles.disabled : styles.number;

  return <Body className={className}>{number}</Body>;
};
