import { debouncePromise } from '@provider-portal/utils/debounce-promise';
import { PortalLoadingState } from '@provider-portal/types/loading-state';
import type * as React from 'react';
import { useHistory } from 'react-router-dom';
import URI from 'urijs';
import { activateProvider, deletePartnerProvider, inactivateProvider, updatePartnerProvider } from '../api';
import type { IProviderFormData } from '../models';

export enum SnackbarState {
  PROVIDER_UPDATED = 'updated',
  PROVIDER_ACTIVATED = 'activated',
  PROVIDER_DISABLED = 'disabled',
}

export function useProps(
  providerId: string,
  setProviderFormData: React.Dispatch<React.SetStateAction<IProviderFormData>>,
  setLoadingState: React.Dispatch<React.SetStateAction<PortalLoadingState>>,
  setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>,
  setShowSnackBar: React.Dispatch<React.SetStateAction<boolean>>,
  setIsActiveProvider: React.Dispatch<React.SetStateAction<boolean>>
) {
  const history = useHistory();

  return {
    onUpdateProvider: async (formData: IProviderFormData) => {
      return updatePartnerProvider(providerId, formData)
        .then(() => {
          setSnackbarState(SnackbarState.PROVIDER_UPDATED);
          setShowSnackBar(true);
        })
        .catch(() => setLoadingState(PortalLoadingState.Failure));
    },
    onDeleteProvider: debouncePromise(async () => {
      return deletePartnerProvider(providerId)
        .then(() => {
          const listProvidersUrl = URI(history.location.pathname).segment(-1, '').toString();
          history.replace(listProvidersUrl);
        })
        .catch(() => setLoadingState(PortalLoadingState.Failure));
    }),
    onActivateProvider: debouncePromise(async () => {
      return activateProvider(providerId)
        .then((formData) => {
          setProviderFormData(formData);
          setIsActiveProvider(true);
          setSnackbarState(SnackbarState.PROVIDER_ACTIVATED);
          setShowSnackBar(true);
        })
        .catch(() => setLoadingState(PortalLoadingState.Failure));
    }),
    onDeactivateProvider: debouncePromise(async () => {
      return inactivateProvider(providerId)
        .then((formData) => {
          setProviderFormData(formData);
          setIsActiveProvider(false);
          setSnackbarState(SnackbarState.PROVIDER_DISABLED);
          setShowSnackBar(true);
        })
        .catch(() => setLoadingState(PortalLoadingState.Failure));
    }),
  };
}
