import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { SupplierPortalUser, SupplierPortalUserRequest } from './models';

export async function fetchUsers(): Promise<SupplierPortalUser[]> {
  return axios.get('/api/frontend/v1/users').then((response: AxiosResponse<SupplierPortalUser[]>) => response.data);
}

export async function fetchUser(userId: string): Promise<SupplierPortalUser> {
  return axios
    .get(`/api/frontend/v1/users/${userId}`)
    .then((response: AxiosResponse<SupplierPortalUser>) => response.data);
}

export async function createUser(incomingUser: SupplierPortalUserRequest): Promise<void> {
  return axios.post('/api/frontend/v1/users', incomingUser).then((response: AxiosResponse<void>) => response.data);
}

export async function deleteUser(userId: string): Promise<void> {
  return axios.delete(`/api/frontend/v1/users/${userId}`).then((response: AxiosResponse<void>) => response.data);
}

export async function updateCsatDisplayAttempts(): Promise<void> {
  return axios
    .post(`/api/frontend/v1/users/update-csat-display-attempts`)
    .then((response: AxiosResponse<void>) => response.data);
}
