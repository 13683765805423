import { Market } from '@provider-portal/app-context/constants';
import { categories } from '@provider-portal/constants/categories';
import { LocalizedMessage } from '@provider-portal/internationalization';
import type { CategoryDetailsMap } from '@provider-portal/libs/uiFramework';
import { createCategoryDetailsFormDataMap } from '@provider-portal/libs/uiFramework';
import { FileInput } from '@provider-portal/libs/uiFramework/components/fields/file/FileInput/config';
import { FileType } from '@provider-portal/libs/uiFramework/components/fields/file/models';
import { HiddenInput } from '@provider-portal/libs/uiFramework/components/fields/HiddenInput/config';
import { MultiSelect } from '@provider-portal/libs/uiFramework/components/fields/MultiSelect/config';
import { Select } from '@provider-portal/libs/uiFramework/components/fields/Select/config';
import { TextInput, UriInput } from '@provider-portal/libs/uiFramework/components/fields/TextInput/config';
import type { IFieldSectionConfig } from '@provider-portal/libs/uiFramework/components/sections/FieldSection/fieldSectionConfig';
import { TranslatableFieldWidget } from '@provider-portal/libs/uiFramework/components/widgets/TranslatableField/config';
import React from 'react';
import { CategoryDetailsFormDataType, PaymentOption, SupportChannel } from '../models';
import { localizePaymentOption, localizeSupportChannel } from './translation';

export enum ProviderFormFieldNames {
  LOGOTYPE = 'logotype',
  NAME = 'name',
  WEBSITE_URL = 'websiteUrl',
  DESCRIPTION = 'description',
  SUPPLIER_ID = 'supplierId',
  PAYMENT_OPTIONS = 'paymentOptions',
  SUPPORT_CHANNELS = 'supportChannels',
  CATEGORIES = 'categories',
  TERMS_URL = 'termsUrl',
  ORDER_REGRET_EMAIL = 'orderRegretEmail',
  ORDER_SERVICE_IDENTIFIER = 'orderServiceIdentifier',
  SERVICE_ID = 'serviceId',
  SELLING_POINT_1 = 'sellingPoint1',
  SELLING_POINT_2 = 'sellingPoint2',
  SELLING_POINT_3 = 'sellingPoint3',
  CATEGORY_NAME = 'categoryName',
  DETAILS_TYPE = 'details.type',
  ORDER_NOTIFICATION_EMAIL = 'orderNotificationEmail',
  MARKET = 'market',
}

export const companyProperties1: IFieldSectionConfig = {
  caption: <LocalizedMessage id="companyProperties" />,
  columns: 3,
  components: [
    FileInput({
      name: ProviderFormFieldNames.LOGOTYPE,
      label: <LocalizedMessage id="logotype" />,
      required: true,
      allowedFileTypes: [FileType.PNG],
    }),
    TextInput({
      name: ProviderFormFieldNames.NAME,
      label: <LocalizedMessage id="providerName" />,
      required: true,
    }),
    UriInput({
      name: ProviderFormFieldNames.WEBSITE_URL,
      label: <LocalizedMessage id="website" />,
    }),
  ],
};

export const companyProperties2: IFieldSectionConfig = {
  columns: 3,
  components: [
    Select({
      name: ProviderFormFieldNames.SUPPLIER_ID,
      label: <LocalizedMessage id="supplierId" />,
    }),
    MultiSelect({
      name: ProviderFormFieldNames.PAYMENT_OPTIONS,
      required: true,
      requiredErrorText: <LocalizedMessage id="paymentOptionsRequired" />,
      label: <LocalizedMessage id="paymentOptions" />,
      options: Object.values(PaymentOption)
        .sort()
        .map((value) => ({ label: localizePaymentOption(value), value })),
      allSelectedLabel: <LocalizedMessage id="all" />,
    }),
    MultiSelect({
      name: ProviderFormFieldNames.SUPPORT_CHANNELS,
      required: true,
      requiredErrorText: <LocalizedMessage id="supportChannelsRequired" />,
      label: <LocalizedMessage id="supportChannels" />,
      options: Object.values(SupportChannel)
        .sort()
        .map((value) => ({ label: localizeSupportChannel(value), value })),
      allSelectedLabel: <LocalizedMessage id="all" />,
    }),
  ],
};

export const companyProperties3: IFieldSectionConfig = {
  columns: 1,
  components: [
    HiddenInput({ name: ProviderFormFieldNames.MARKET }),
    TranslatableFieldWidget({
      title: <LocalizedMessage id="description" />,
      description: <LocalizedMessage id="companyDescription" />,
      name: ProviderFormFieldNames.DESCRIPTION,
      required: true,
      multiline: true,
      tooltipTitle: <LocalizedMessage id="translationTooltipTitle" />,
      tooltipText: <LocalizedMessage id="translationTooltipText" />,
    }),
  ],
};

export const swedenMobileFormDataDetailTypeMap: CategoryDetailsMap = createCategoryDetailsFormDataMap(
  categories.mobile,
  Market.Sweden,
  CategoryDetailsFormDataType.SwedenMobileFormData
);

export const swedenElectricityFormDataDetailsTypeMap: CategoryDetailsMap = createCategoryDetailsFormDataMap(
  categories.electricity,
  Market.Sweden,
  CategoryDetailsFormDataType.SwedenElectricityFormData
);

export const denmarkMobileFormDataDetailsTypeMap: CategoryDetailsMap = createCategoryDetailsFormDataMap(
  categories.mobile,
  Market.Denmark,
  CategoryDetailsFormDataType.DenmarkMobileFormData
);

export const belgiumElectricityFormDataDetailsTypeMap: CategoryDetailsMap = createCategoryDetailsFormDataMap(
  categories.electricity,
  Market.Belgium,
  CategoryDetailsFormDataType.BelgiumElectricityFormData
);

export const belgiumGasFormDataDetailsTypeMap: CategoryDetailsMap = createCategoryDetailsFormDataMap(
  categories.gas,
  Market.Belgium,
  CategoryDetailsFormDataType.BelgiumGasFormData
);

export const belgiumMobileFormDataDetailsTypeMap: CategoryDetailsMap = createCategoryDetailsFormDataMap(
  categories.mobile,
  Market.Belgium,
  CategoryDetailsFormDataType.BelgiumMobileFormData
);
