import React from 'react';
import type { EllipsisProps } from '../shared/ellipsis';
import { getEllipsisStyles } from '../shared/ellipsis';
import type { Omit, TypographyProps } from '../Typography';
import { Typography } from '../Typography';

export const Subtitle1: React.FunctionComponent<Subtitle1Props> = ({ style, ellipsis, maxWidth, ...otherProps }) => {
  /**
   * 1rem corresponds to 16px in Invision
   */
  const ellipsisStyles = getEllipsisStyles(maxWidth, ellipsis);
  const baseStyle = {
    fontSize: '1rem',
    fontWeight: 500,
    letterSpacing: '0.15px',
    lineHeight: '1.375em',
    ...ellipsisStyles,
    ...style,
  };

  return <Typography {...otherProps} variant="subtitle1" style={baseStyle} />;
};

type Subtitle1Props = Omit<TypographyProps, 'variant'> & EllipsisProps;
