import { Caption } from '@provider-portal/libs/uiFramework/components/text/Caption';
import React from 'react';
import { Body } from '../text/Body';

export interface ISimpleValueItem {
  caption: React.ReactNode;
  value: React.ReactNode;
}

export const SimpleValueItem: React.FunctionComponent<ISimpleValueItem> = ({ caption, value }) => {
  return (
    <div style={{ overflowWrap: 'break-word' }}>
      <Caption colorVariant="darker">{caption}</Caption>
      <Body>{value}</Body>
    </div>
  );
};
